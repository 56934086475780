import { useEffect, useState, type FC } from "react";
import { generalClient } from "../App";
import {
  GetMutationLogRequest,
  GetMutationLogResponseData,
  Pagination,
} from "../grpc/service/general_pb";
import { getToken, groupDataLog, money, randomStr } from "../utils/helper";
import { errorToast } from "../utils/helper-ui";
import Moment from "react-moment";
import moment from "moment";
import NoData from "./no_data";

interface MutationProps {
  isAffiliator?: boolean;
  isCommentator?: boolean;
  isMarketing?: boolean;
}

const Mutation: FC<MutationProps> = ({
  isAffiliator,
  isCommentator,
  isMarketing,
}) => {
  const [mounted, setMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [pagination, setPagination] = useState<Pagination.AsObject | null>(
    null
  );
  const [data, setData] = useState<GetMutationLogResponseData.AsObject[]>([]);
  const [today, setToday] = useState<GetMutationLogResponseData.AsObject[]>([]);
  const [yesterday, setYesterday] = useState<
    GetMutationLogResponseData.AsObject[]
  >([]);
  const [others, setOthers] = useState<
    { date: string; data: GetMutationLogResponseData.AsObject[] }[]
  >([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    let req = new GetMutationLogRequest();
    req.setPage(page);
    req.setLimit(limit);

    generalClient.getMutationLog(req, getToken(), (err, resp) => {
      if (err) {
        setIsLoading(false);
        errorToast(err.message);
        return;
      }
      setPagination(resp.getPagination()?.toObject() ?? null);
      setData([...data, ...resp.getDataList().map((e) => e.toObject())]);
      setIsLoading(false);
    });
  }, [mounted, page, limit]);

  useEffect(() => {
    let grouped = groupDataLog(data);
    setToday(grouped.today);
    setYesterday(grouped.yesterday);
    let _others: {
      date: string;
      data: GetMutationLogResponseData.AsObject[];
    }[] = [];
    for (const other in grouped.others) {
      _others.push({
        date: other,
        data: grouped.others[other] as GetMutationLogResponseData.AsObject[],
      });
    }

    setOthers(_others);
  }, [data]);

  const renderList = (
    title: string,
    logData: GetMutationLogResponseData.AsObject[],
    showDate: boolean
  ) => (
    <div key={title}>
      <div className="p-2 bg-gray-100  text-base">{title}</div>
      <div className="bg-white px-4">
        {logData.length == 0 ? <NoData height={100} /> :
        <ul className="pb-0 mb-0">
          {logData.map((e) => (
            <li key={randomStr(20)} className="py-4 border-b last:border-b-0">
              <div className="flex justify-between">
                <p>{e.type == "fee" ? "Fee" : "Penarikan"}</p>
                <div>
                
                  <p  className="text-sm ml-2">
                    {moment.utc(e.createdAt).format("HH:mm")}
                  </p>
                </div>
              </div>
              <p className="text-sm font-[600]">{e.type == "fee" ? '+' : '-'}{money(e.amount)}</p>
            </li>
          ))}
        </ul>
}
      </div>
    </div>
  );
  return (
    <div>
      {renderList("Hari Ini", today, false)}
      {renderList("Kemarin", yesterday, false)}
      {others.map((e) => renderList(moment(e.date).format("DD MMM YYYY"), e.data, true))}

      <div className=" justify-center items-start flex">
        {(pagination?.totalPages ?? 0) > 0 &&
          (pagination?.next ?? 0) > page && (
            <button
              className="btn-white w-48"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              More
            </button>
          )}
      </div>
    </div>
  );
};
export default Mutation;
