import { useContext, useEffect, useState, type FC } from "react";
import CommentatorMid from "../../middlewares/CommentatorMid";
import { GetProfileResponseData } from "../../grpc/service/general_pb";
import { LoadingContext } from "../../objects/loading_context";
import { RiMenu3Fill } from "react-icons/ri";
import { Avatar, Drawer } from "rsuite";
import { HiHome, HiOutlineClock, HiXMark } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";
import {
  commentatorAccountClient,
  commentatorGeneralClient,
  commentatorPackageClient,
} from "../../App";
import {
  GetListCommentatorAccountRequest,
  GetListCommentatorAccountResponseData,
} from "../../grpc/commentator/commentator_account_pb";
import {
  getToken,
  initials,
  money,
  numberToDuration,
} from "../../utils/helper";
import { errorToast } from "../../utils/helper-ui";
import { PiPlusBold } from "react-icons/pi";
import {
  GetCommissionRequest,
  GetCommissionResponseData,
} from "../../grpc/commentator/commentator_general_pb";
import HeroCommision from "../../components/affiliator/HeroCommision";
import {
  PENDING_COMMENTATOR,
  PENDING_COMMENTATOR_OFFER,
} from "../../utils/contants";
import { GetListUserPackageRequest } from "../../grpc/client/client_user_package_pb";
import {
  AcceptUserPackageRequest,
  GetListUserPackageResponseData,
} from "../../grpc/commentator/commentator_user_package_pb";
import moment from "moment";
import { SwipeableButton } from "react-swipeable-button";
import Sidebar from "../../components/commentator/Sidebar";
import { SidebarContext } from "../../objects/SidebarContext";

interface CommentatorHomeProps {}

const CommentatorHome: FC<CommentatorHomeProps> = ({}) => {
  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [modalSidebar, setModalSidebar] = useState(false);
  const nav = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [accounts, setAccounts] = useState<
    GetListCommentatorAccountResponseData.AsObject[]
  >([]);
  const [commision, setCommision] =
    useState<GetCommissionResponseData.AsObject | null>(null);
  const [orderPackages, setOrderPackages] = useState<
    GetListUserPackageResponseData.AsObject[]
  >([]);
  const { isOpen, setOpen } = useContext(SidebarContext);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getAccounts();
    getCommision();
    getOffers();
  }, [mounted]);

  const getOffers = () => {
    setIsLoading(true);
    let req = new GetListUserPackageRequest();
    req.setPage(page);
    req.setLimit(limit);
    req.setPaymentStatus(PENDING_COMMENTATOR_OFFER);

    commentatorPackageClient.getListUserPackage(
      req,
      getToken(),
      (err, resp) => {
        setIsLoading(false);
        if (err) {
          errorToast(err.message);
          return;
        }
        setOrderPackages(resp.getDataList().map((e) => e.toObject()));
      }
    );
  };

  const getCommision = () => {
    let req = new GetCommissionRequest();
    commentatorGeneralClient.getCommission(req, getToken(), (err, resp) => {
      setIsLoading(false);
      if (err) {
        errorToast(err.message);
        return;
      }
      setCommision(resp.getData()!.toObject());
    });
  };
  const getAccounts = () => {
    setIsLoading(true);
    let req = new GetListCommentatorAccountRequest();
    commentatorAccountClient.getListCommentatorAccount(
      req,
      getToken(),
      (err, resp) => {
        setIsLoading(false);
        if (err) {
          errorToast(err.message);
          return;
        }
        setAccounts(resp.getDataList().map((e) => e.toObject()));
      }
    );
  };

  const takeOrderPackages = (e: GetListUserPackageResponseData.AsObject) => {
    // setIsLoading(true)
    // let req = new AcceptUserPackageRequest();
    // req.setUserPackageId(e.id)
    // commentatorPackageClient.acceptUserPackage(req, getToken(), (err, resp) => {
    //     setIsLoading(false)
    //     if (err) {
    //         errorToast(err.message)
    //         return
    //     }
    //     getOffers()
    // })
    nav(`/commentator/package/${e.id}`);
  };

  const onWithdrawClick = () => {
    nav("/commentator/withdraw");
  };

  return (
    <CommentatorMid
      noPadding
      onLoadProfile={setProfile}
      header={
        <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white">
          <div
            className=" cursor-pointer "
            onClick={() => {
              setOpen(!isOpen);
            }}
          >
            <RiMenu3Fill size={16} />
          </div>
          <h6 className="text-center">Beranda</h6>
          <div></div>
        </div>
      }
    >
      <div className="p-4">
        <HeroCommision data={commision} onWithdrawClick={onWithdrawClick} />
        {orderPackages.length > 0 && (
          <>
            <div className="flex justify-between">
              <h5 className="mb-2">Penawaran Terbaru</h5>
              <Link to={"/commentator/package"} className=" text-sky-400">
                Lihat Semua
              </Link>
            </div>
            <div className="bg-white p-4 rounded-xl">
              {orderPackages.map((e) => (
                <div className=" my-4 flex flex-col" key={e.id}>
                  <div className="flex justify-between mb-2">
                    <span>
                      <span className="font-bold"> Fee</span>{" "}
                      <span className=" text-sky-400 font-semibold">
                        {" "}
                        Rp. {money(e.commentatorFee)}
                      </span>
                    </span>
                    <div>
                      <span className="flex text-orange-400 items-center">
                        <HiOutlineClock size={18} className="mr-2" />{" "}
                        {numberToDuration(
                          moment
                            .utc(e?.expiryTime)
                            .diff(moment.utc(), "minutes")
                        )}
                      </span>
                    </div>
                  </div>
                  <SwipeableButton
                    onSuccess={() => {
                      takeOrderPackages(e);
                    }}
                    onFailure={() => {}}
                    text={`${money(e.commentPerDay)} Komen / Hari`}
                    text_unlocked="Sukses"
                    color="#1084c2"
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </CommentatorMid>
  );
};
export default CommentatorHome;
