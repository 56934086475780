import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../pages/NotFound";
import AffiliatorHome from "../pages/affiliator/AffiliatorHome";
import AffiliatorProfile from "../pages/affiliator/AffiliatorProfile";
import AffiliatorOrderDetail from "../pages/affiliator/AffiliatorOrderDetail";
import AffiliatorOrder from "../pages/affiliator/AffiliatorOrder";
import Wip from "../pages/Wip";
import AffiliatorCommentator from "../pages/affiliator/AffiliatorCommentator";
import AffiliatorPackage from "../pages/affiliator/AffiliatorPackage";
import AffiliatorClient from "../pages/affiliator/AffiliatorClient";
import AffiliatorPackageDetail from "../pages/affiliator/AffiliatorPackageDetail";
import WithdrawalRequest from "../pages/WithdrawalRequest";
import HistoryPage from "../pages/History";
import WithdrawalRequestDetail from "../pages/WithdrawalRequestDetail";

interface AffiliatorRouteProps { }

const AffiliatorRoute: FC<AffiliatorRouteProps> = ({ }) => {
    return (
        <Routes>
            <Route path='/' element={<AffiliatorHome />} />
            <Route path='/order' element={<AffiliatorOrder />} />
            <Route path='/client' element={<AffiliatorClient />} />
            <Route path='/commentator' element={<AffiliatorCommentator />} />
            <Route path='/package' element={<AffiliatorPackage />} />
            <Route path='/package/:packageId' element={<AffiliatorPackageDetail />} />
            <Route path='/order/:orderId' element={<AffiliatorOrderDetail />} />
            <Route path='/profile' element={<AffiliatorProfile />} />
            <Route path='/withdraw' element={<WithdrawalRequest isAffiliator />} />
            <Route path='/withdraw/:withdrawId' element={<WithdrawalRequestDetail isAffiliator />} />
            <Route path='/history' element={<HistoryPage isAffiliator />} />
            
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}
export default AffiliatorRoute;