export const PERMISSIONS = "permissions"
export const TOKEN = "token"
export const REFRESH_TOKEN = "refresh-token"
export const USER_ROLE = "user-role"
export const PROFILE = "profile"
export const ACTIVE = "active"
export const PASSIVE = "passive"
export const FREE = "free"
export const USER_PACKAGE = "user-package"
export const MARKETER = "marketer";


export const ORDER_REVIEWED = "order_reviewed"
export const ORDER_PROCESSING = "order_processing"
export const ORDER_DONE = "order_done"
export const ORDER_REJECTED = "order_rejected"
export const ORDER_UNPAID = "order_unpaid"
export const ORDER_FAILED = "order_failed"
export const ORDER_CONFIRMATION = "order_confirmation"
export const ORDER_AFFILIATOR = "order_affiliator"
export const ORDER_COMMENTATOR = "order_commentator"
export const MUTATION = "mutation"
export const PROCESSING = "processing"
export const CONFIRM = "confirm"


export const COMMENTATOR = "commentator";
export const AFFILIATOR = "affiliator";
export const SUPER_ADMIN = "admin";
export const CLIENT = "user";
export const PREMIUM = "Premium";
export const DATA_USER = "dataUser";
export const DATA_REVENUE = "dataRevenue";
export const TO_DO = "to_do";
export const IN_PROGRESS = "in_progress";
export const DONE = "done";
export const PENDING_WITHDRAW = 'pending_withdraw';
export const PENDING_AFFILIATOR = "pending_affiliator";
export const PENDING_COMMENTATOR = "pending_commentator";
export const PENDING_COMMENTATOR_OFFER = "pending_commentator_offer";
export const PENDING_PAYMENT = "pending_payment";
export const PENDING_CONFIRMATION = "pending_confirmation";
export const PENDING_REQUEST = "pending_request";
export const NOT_REQUEST = "not_request";
export const CANCELED = "canceled";
export const PAID = "paid";
export const DECLINED = "declined";
export const FAILED = "failed";
export const REJECTED = "rejected";
export const SETTLEMENT = "settlement";
export const ONE_WEEK = "1W";
export const ONE_MONTH = "1M";
export const THREE_MONTH = "3M";
export const FIVE_MONTH = "5M";
export const ONE_YEAR = "1Y";
export const FIVE_YEAR = "5Y";
export const TIKTOK = "tiktok";
export const INSTAGRAM = "instagram";
export const FACEBOOK = "facebook";
export const TWITTER = "twitter";
export const YOUTUBE = "youtube";
export const DETIK = "detik";
export const THREADS = "threads";
export const AFFILIATOR_ADMIN = "affiliator_admin";
export const AFFILIATOR_CLIENT = "affiliator_client";
export const AFFILIATOR_COMMENTATOR = "affiliator_commentator";
export const DURATION_DAY = "day";
export const DURATION_WEEK = "week";
export const DURATION_MONTH = "month";
export const DURATION_YEAR = "year";
export const SORT_CREATED_AT = "created_at";
export const USER_SORT_CREATED_AT = "u.created_at";
export const SORT_NAME = "name";
export const USER_SORT_NAME = "u.name";
export const SORT_PAYMENT = "user_package_total_price";



export const WA_INVITATION = `
Hai @name Kami mengundang anda sebagai @role di Grosir Komen, ini adalah identitas login Satu Kali dan expire dalam 24 jam segera lakukan langkah-langkah dibawah ini 

1. Download aplikasi Grosir Komen di :
App Store @urlAppStore
Google Store @urlPlayStore

2. Beri Rating 5 dan Komen positif Wajib Banget, bukan Sunnah

3. Login dengan akun sementara 
Username : @username
Pass : @password

4. Ikuti petunjuk selanjutnya

Selesai, anda sudah resmi menjadi @role di Grosir Komen, Terima kasih @name!
`;

export const ACCOUNT_HOLDER_NAME = "BCA A.n PT. Fokus Pada Indonesia";
export const ACCOUNT_NUMBER = "2820553821";

export const premiumFeatures = ["Tambah kuota harian",
    "Komen positif, negatif, tag teman",
    "Multi Konten & 1 hari selesai",
    "Bonus likes, shares",]