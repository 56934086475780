import { createContext } from "react";

export const SidebarContext = createContext<SidebarContextType>({
    isOpen: true,
    setOpen: (val) => false
});

export type SidebarContextType = {
    isOpen: boolean;
    setOpen: (val: boolean) => void;
};