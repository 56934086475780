// source: affiliator_order.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.affiliator_order.CreateOrderRequest', null, global);
goog.exportSymbol('proto.affiliator_order.CreateOrderResponse', null, global);
goog.exportSymbol('proto.affiliator_order.CreateOrderResponseData', null, global);
goog.exportSymbol('proto.affiliator_order.DeclineDraftRequest', null, global);
goog.exportSymbol('proto.affiliator_order.DeclineDraftResponse', null, global);
goog.exportSymbol('proto.affiliator_order.DeclineDraftResponseData', null, global);
goog.exportSymbol('proto.affiliator_order.GetDoneCommentatorRequest', null, global);
goog.exportSymbol('proto.affiliator_order.GetDoneCommentatorResponse', null, global);
goog.exportSymbol('proto.affiliator_order.GetDoneCommentatorResponseData', null, global);
goog.exportSymbol('proto.affiliator_order.GetDoneCommentatorResponseDataCommentator', null, global);
goog.exportSymbol('proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderDetailRequest', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderDetailResponse', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderDetailResponseData', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderDetailResponseDataCommentator', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderDetailResponseDataUser', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderDiscussionRequest', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderDiscussionResponse', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderDiscussionResponseData', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderDiscussionResponseDataReply', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderDiscussionResponseDataUser', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderDraftListRequest', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderDraftListResponse', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderDraftListResponseData', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderDraftListResponseDataUser', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderListRequest', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderListResponse', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderListResponseData', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderListResponseDataUser', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderSuspiciousActivityRequest', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderSuspiciousActivityResponse', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderSuspiciousActivityResponseData', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator', null, global);
goog.exportSymbol('proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount', null, global);
goog.exportSymbol('proto.affiliator_order.Meta', null, global);
goog.exportSymbol('proto.affiliator_order.Pagination', null, global);
goog.exportSymbol('proto.affiliator_order.SendDiscussionRequest', null, global);
goog.exportSymbol('proto.affiliator_order.SendDiscussionResponse', null, global);
goog.exportSymbol('proto.affiliator_order.SendDiscussionResponseData', null, global);
goog.exportSymbol('proto.affiliator_order.SetAsFinishedRequest', null, global);
goog.exportSymbol('proto.affiliator_order.SetAsFinishedResponse', null, global);
goog.exportSymbol('proto.affiliator_order.SetAsFinishedResponseData', null, global);
goog.exportSymbol('proto.affiliator_order.SetSuspiciousActivityAsDoneRequest', null, global);
goog.exportSymbol('proto.affiliator_order.SetSuspiciousActivityAsDoneResponse', null, global);
goog.exportSymbol('proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.Meta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.Meta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.Meta.displayName = 'proto.affiliator_order.Meta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.Pagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.Pagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.Pagination.displayName = 'proto.affiliator_order.Pagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.CreateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.CreateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.CreateOrderRequest.displayName = 'proto.affiliator_order.CreateOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.CreateOrderResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.CreateOrderResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.CreateOrderResponseData.displayName = 'proto.affiliator_order.CreateOrderResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.CreateOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.CreateOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.CreateOrderResponse.displayName = 'proto.affiliator_order.CreateOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderDraftListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetOrderDraftListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderDraftListRequest.displayName = 'proto.affiliator_order.GetOrderDraftListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderDraftListResponseDataUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetOrderDraftListResponseDataUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderDraftListResponseDataUser.displayName = 'proto.affiliator_order.GetOrderDraftListResponseDataUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderDraftListResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetOrderDraftListResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderDraftListResponseData.displayName = 'proto.affiliator_order.GetOrderDraftListResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderDraftListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_order.GetOrderDraftListResponse.repeatedFields_, null);
};
goog.inherits(proto.affiliator_order.GetOrderDraftListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderDraftListResponse.displayName = 'proto.affiliator_order.GetOrderDraftListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.DeclineDraftRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.DeclineDraftRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.DeclineDraftRequest.displayName = 'proto.affiliator_order.DeclineDraftRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.DeclineDraftResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.DeclineDraftResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.DeclineDraftResponseData.displayName = 'proto.affiliator_order.DeclineDraftResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.DeclineDraftResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.DeclineDraftResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.DeclineDraftResponse.displayName = 'proto.affiliator_order.DeclineDraftResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetOrderListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderListRequest.displayName = 'proto.affiliator_order.GetOrderListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderListResponseDataUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetOrderListResponseDataUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderListResponseDataUser.displayName = 'proto.affiliator_order.GetOrderListResponseDataUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderListResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetOrderListResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderListResponseData.displayName = 'proto.affiliator_order.GetOrderListResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_order.GetOrderListResponse.repeatedFields_, null);
};
goog.inherits(proto.affiliator_order.GetOrderListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderListResponse.displayName = 'proto.affiliator_order.GetOrderListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetOrderDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderDetailRequest.displayName = 'proto.affiliator_order.GetOrderDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderDetailResponseDataUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetOrderDetailResponseDataUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderDetailResponseDataUser.displayName = 'proto.affiliator_order.GetOrderDetailResponseDataUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.displayName = 'proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_order.GetOrderDetailResponseDataCommentator.repeatedFields_, null);
};
goog.inherits(proto.affiliator_order.GetOrderDetailResponseDataCommentator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderDetailResponseDataCommentator.displayName = 'proto.affiliator_order.GetOrderDetailResponseDataCommentator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderDetailResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_order.GetOrderDetailResponseData.repeatedFields_, null);
};
goog.inherits(proto.affiliator_order.GetOrderDetailResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderDetailResponseData.displayName = 'proto.affiliator_order.GetOrderDetailResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetOrderDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderDetailResponse.displayName = 'proto.affiliator_order.GetOrderDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderDiscussionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetOrderDiscussionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderDiscussionRequest.displayName = 'proto.affiliator_order.GetOrderDiscussionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderDiscussionResponseDataUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetOrderDiscussionResponseDataUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderDiscussionResponseDataUser.displayName = 'proto.affiliator_order.GetOrderDiscussionResponseDataUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetOrderDiscussionResponseDataReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderDiscussionResponseDataReply.displayName = 'proto.affiliator_order.GetOrderDiscussionResponseDataReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderDiscussionResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_order.GetOrderDiscussionResponseData.repeatedFields_, null);
};
goog.inherits(proto.affiliator_order.GetOrderDiscussionResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderDiscussionResponseData.displayName = 'proto.affiliator_order.GetOrderDiscussionResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderDiscussionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_order.GetOrderDiscussionResponse.repeatedFields_, null);
};
goog.inherits(proto.affiliator_order.GetOrderDiscussionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderDiscussionResponse.displayName = 'proto.affiliator_order.GetOrderDiscussionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.SendDiscussionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.SendDiscussionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.SendDiscussionRequest.displayName = 'proto.affiliator_order.SendDiscussionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.SendDiscussionResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.SendDiscussionResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.SendDiscussionResponseData.displayName = 'proto.affiliator_order.SendDiscussionResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.SendDiscussionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.SendDiscussionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.SendDiscussionResponse.displayName = 'proto.affiliator_order.SendDiscussionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderSuspiciousActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetOrderSuspiciousActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderSuspiciousActivityRequest.displayName = 'proto.affiliator_order.GetOrderSuspiciousActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.displayName = 'proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.repeatedFields_, null);
};
goog.inherits(proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.displayName = 'proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_order.GetOrderSuspiciousActivityResponseData.repeatedFields_, null);
};
goog.inherits(proto.affiliator_order.GetOrderSuspiciousActivityResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderSuspiciousActivityResponseData.displayName = 'proto.affiliator_order.GetOrderSuspiciousActivityResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetOrderSuspiciousActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetOrderSuspiciousActivityResponse.displayName = 'proto.affiliator_order.GetOrderSuspiciousActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetDoneCommentatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetDoneCommentatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetDoneCommentatorRequest.displayName = 'proto.affiliator_order.GetDoneCommentatorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.displayName = 'proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.repeatedFields_, null);
};
goog.inherits(proto.affiliator_order.GetDoneCommentatorResponseDataCommentator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.displayName = 'proto.affiliator_order.GetDoneCommentatorResponseDataCommentator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetDoneCommentatorResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_order.GetDoneCommentatorResponseData.repeatedFields_, null);
};
goog.inherits(proto.affiliator_order.GetDoneCommentatorResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetDoneCommentatorResponseData.displayName = 'proto.affiliator_order.GetDoneCommentatorResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.GetDoneCommentatorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.GetDoneCommentatorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.GetDoneCommentatorResponse.displayName = 'proto.affiliator_order.GetDoneCommentatorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.repeatedFields_, null);
};
goog.inherits(proto.affiliator_order.SetSuspiciousActivityAsDoneRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.displayName = 'proto.affiliator_order.SetSuspiciousActivityAsDoneRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData.displayName = 'proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.SetSuspiciousActivityAsDoneResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.displayName = 'proto.affiliator_order.SetSuspiciousActivityAsDoneResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.SetAsFinishedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.SetAsFinishedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.SetAsFinishedRequest.displayName = 'proto.affiliator_order.SetAsFinishedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.SetAsFinishedResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.SetAsFinishedResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.SetAsFinishedResponseData.displayName = 'proto.affiliator_order.SetAsFinishedResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_order.SetAsFinishedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_order.SetAsFinishedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_order.SetAsFinishedResponse.displayName = 'proto.affiliator_order.SetAsFinishedResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.Meta.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.Meta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.Meta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.Meta.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.Meta}
 */
proto.affiliator_order.Meta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.Meta;
  return proto.affiliator_order.Meta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.Meta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.Meta}
 */
proto.affiliator_order.Meta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.Meta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.Meta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.Meta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.Meta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.affiliator_order.Meta.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.Meta} returns this
 */
proto.affiliator_order.Meta.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 status = 2;
 * @return {number}
 */
proto.affiliator_order.Meta.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.Meta} returns this
 */
proto.affiliator_order.Meta.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.affiliator_order.Meta.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.Meta} returns this
 */
proto.affiliator_order.Meta.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.Pagination.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.Pagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.Pagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.Pagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    prev: jspb.Message.getFieldWithDefault(msg, 3, 0),
    next: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.Pagination}
 */
proto.affiliator_order.Pagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.Pagination;
  return proto.affiliator_order.Pagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.Pagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.Pagination}
 */
proto.affiliator_order.Pagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrev(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNext(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPages(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.Pagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.Pagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.Pagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.Pagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPrev();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNext();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.affiliator_order.Pagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.Pagination} returns this
 */
proto.affiliator_order.Pagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.affiliator_order.Pagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.Pagination} returns this
 */
proto.affiliator_order.Pagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 prev = 3;
 * @return {number}
 */
proto.affiliator_order.Pagination.prototype.getPrev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.Pagination} returns this
 */
proto.affiliator_order.Pagination.prototype.setPrev = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 next = 4;
 * @return {number}
 */
proto.affiliator_order.Pagination.prototype.getNext = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.Pagination} returns this
 */
proto.affiliator_order.Pagination.prototype.setNext = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 total_pages = 5;
 * @return {number}
 */
proto.affiliator_order.Pagination.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.Pagination} returns this
 */
proto.affiliator_order.Pagination.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 total_records = 6;
 * @return {number}
 */
proto.affiliator_order.Pagination.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.Pagination} returns this
 */
proto.affiliator_order.Pagination.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.CreateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.CreateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.CreateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.CreateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderDraftId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initialImagePath: jspb.Message.getFieldWithDefault(msg, 2, ""),
    initialImagePathType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    remarks: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.CreateOrderRequest}
 */
proto.affiliator_order.CreateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.CreateOrderRequest;
  return proto.affiliator_order.CreateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.CreateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.CreateOrderRequest}
 */
proto.affiliator_order.CreateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderDraftId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialImagePath(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialImagePathType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.CreateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.CreateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.CreateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.CreateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderDraftId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitialImagePath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInitialImagePathType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string order_draft_id = 1;
 * @return {string}
 */
proto.affiliator_order.CreateOrderRequest.prototype.getOrderDraftId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.CreateOrderRequest} returns this
 */
proto.affiliator_order.CreateOrderRequest.prototype.setOrderDraftId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initial_image_path = 2;
 * @return {string}
 */
proto.affiliator_order.CreateOrderRequest.prototype.getInitialImagePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.CreateOrderRequest} returns this
 */
proto.affiliator_order.CreateOrderRequest.prototype.setInitialImagePath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string initial_image_path_type = 3;
 * @return {string}
 */
proto.affiliator_order.CreateOrderRequest.prototype.getInitialImagePathType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.CreateOrderRequest} returns this
 */
proto.affiliator_order.CreateOrderRequest.prototype.setInitialImagePathType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string remarks = 4;
 * @return {string}
 */
proto.affiliator_order.CreateOrderRequest.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.CreateOrderRequest} returns this
 */
proto.affiliator_order.CreateOrderRequest.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.CreateOrderResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.CreateOrderResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.CreateOrderResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.CreateOrderResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderWorkDayDuration: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.CreateOrderResponseData}
 */
proto.affiliator_order.CreateOrderResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.CreateOrderResponseData;
  return proto.affiliator_order.CreateOrderResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.CreateOrderResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.CreateOrderResponseData}
 */
proto.affiliator_order.CreateOrderResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrderWorkDayDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.CreateOrderResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.CreateOrderResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.CreateOrderResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.CreateOrderResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderWorkDayDuration();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.affiliator_order.CreateOrderResponseData.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.CreateOrderResponseData} returns this
 */
proto.affiliator_order.CreateOrderResponseData.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 order_work_day_duration = 2;
 * @return {number}
 */
proto.affiliator_order.CreateOrderResponseData.prototype.getOrderWorkDayDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.CreateOrderResponseData} returns this
 */
proto.affiliator_order.CreateOrderResponseData.prototype.setOrderWorkDayDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.CreateOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.CreateOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.CreateOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.CreateOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_order.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.affiliator_order.CreateOrderResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.CreateOrderResponse}
 */
proto.affiliator_order.CreateOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.CreateOrderResponse;
  return proto.affiliator_order.CreateOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.CreateOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.CreateOrderResponse}
 */
proto.affiliator_order.CreateOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_order.Meta;
      reader.readMessage(value,proto.affiliator_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_order.CreateOrderResponseData;
      reader.readMessage(value,proto.affiliator_order.CreateOrderResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.CreateOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.CreateOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.CreateOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.CreateOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_order.CreateOrderResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_order.Meta}
 */
proto.affiliator_order.CreateOrderResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.Meta, 1));
};


/**
 * @param {?proto.affiliator_order.Meta|undefined} value
 * @return {!proto.affiliator_order.CreateOrderResponse} returns this
*/
proto.affiliator_order.CreateOrderResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.CreateOrderResponse} returns this
 */
proto.affiliator_order.CreateOrderResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.CreateOrderResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateOrderResponseData data = 2;
 * @return {?proto.affiliator_order.CreateOrderResponseData}
 */
proto.affiliator_order.CreateOrderResponse.prototype.getData = function() {
  return /** @type{?proto.affiliator_order.CreateOrderResponseData} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.CreateOrderResponseData, 2));
};


/**
 * @param {?proto.affiliator_order.CreateOrderResponseData|undefined} value
 * @return {!proto.affiliator_order.CreateOrderResponse} returns this
*/
proto.affiliator_order.CreateOrderResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.CreateOrderResponse} returns this
 */
proto.affiliator_order.CreateOrderResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.CreateOrderResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderDraftListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderDraftListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderDraftListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDraftListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isDeclined: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderDraftListRequest}
 */
proto.affiliator_order.GetOrderDraftListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderDraftListRequest;
  return proto.affiliator_order.GetOrderDraftListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderDraftListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderDraftListRequest}
 */
proto.affiliator_order.GetOrderDraftListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsConfirmed(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeclined(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderDraftListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderDraftListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderDraftListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDraftListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getIsConfirmed();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsDeclined();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.affiliator_order.GetOrderDraftListRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderDraftListRequest} returns this
 */
proto.affiliator_order.GetOrderDraftListRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.affiliator_order.GetOrderDraftListRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderDraftListRequest} returns this
 */
proto.affiliator_order.GetOrderDraftListRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_confirmed = 3;
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDraftListRequest.prototype.getIsConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_order.GetOrderDraftListRequest} returns this
 */
proto.affiliator_order.GetOrderDraftListRequest.prototype.setIsConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_declined = 4;
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDraftListRequest.prototype.getIsDeclined = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_order.GetOrderDraftListRequest} returns this
 */
proto.affiliator_order.GetOrderDraftListRequest.prototype.setIsDeclined = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderDraftListResponseDataUser.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderDraftListResponseDataUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderDraftListResponseDataUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDraftListResponseDataUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderDraftListResponseDataUser}
 */
proto.affiliator_order.GetOrderDraftListResponseDataUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderDraftListResponseDataUser;
  return proto.affiliator_order.GetOrderDraftListResponseDataUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderDraftListResponseDataUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderDraftListResponseDataUser}
 */
proto.affiliator_order.GetOrderDraftListResponseDataUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderDraftListResponseDataUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderDraftListResponseDataUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderDraftListResponseDataUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDraftListResponseDataUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_order.GetOrderDraftListResponseDataUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseDataUser} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseDataUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.affiliator_order.GetOrderDraftListResponseDataUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseDataUser} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseDataUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.affiliator_order.GetOrderDraftListResponseDataUser.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseDataUser} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseDataUser.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderDraftListResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderDraftListResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDraftListResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    thumbnailUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    url: jspb.Message.getFieldWithDefault(msg, 5, ""),
    isConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    positiveComment: jspb.Message.getFieldWithDefault(msg, 7, 0),
    negativeComment: jspb.Message.getFieldWithDefault(msg, 8, 0),
    tagFriend: jspb.Message.getFieldWithDefault(msg, 9, 0),
    confirmedAt: jspb.Message.getFieldWithDefault(msg, 10, ""),
    user: (f = msg.getUser()) && proto.affiliator_order.GetOrderDraftListResponseDataUser.toObject(includeInstance, f),
    remarks: jspb.Message.getFieldWithDefault(msg, 12, ""),
    declinalRemarks: jspb.Message.getFieldWithDefault(msg, 13, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData}
 */
proto.affiliator_order.GetOrderDraftListResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderDraftListResponseData;
  return proto.affiliator_order.GetOrderDraftListResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderDraftListResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData}
 */
proto.affiliator_order.GetOrderDraftListResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsConfirmed(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositiveComment(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNegativeComment(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTagFriend(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmedAt(value);
      break;
    case 11:
      var value = new proto.affiliator_order.GetOrderDraftListResponseDataUser;
      reader.readMessage(value,proto.affiliator_order.GetOrderDraftListResponseDataUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeclinalRemarks(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderDraftListResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderDraftListResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDraftListResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThumbnailUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIsConfirmed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPositiveComment();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getNegativeComment();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getTagFriend();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getConfirmedAt();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.affiliator_order.GetOrderDraftListResponseDataUser.serializeBinaryToWriter
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDeclinalRemarks();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string thumbnail_url = 4;
 * @return {string}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.getThumbnailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.setThumbnailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string url = 5;
 * @return {string}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool is_confirmed = 6;
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.getIsConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.setIsConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional uint32 positive_comment = 7;
 * @return {number}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.getPositiveComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.setPositiveComment = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 negative_comment = 8;
 * @return {number}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.getNegativeComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.setNegativeComment = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 tag_friend = 9;
 * @return {number}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.getTagFriend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.setTagFriend = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string confirmed_at = 10;
 * @return {string}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.getConfirmedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.setConfirmedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional GetOrderDraftListResponseDataUser user = 11;
 * @return {?proto.affiliator_order.GetOrderDraftListResponseDataUser}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.getUser = function() {
  return /** @type{?proto.affiliator_order.GetOrderDraftListResponseDataUser} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.GetOrderDraftListResponseDataUser, 11));
};


/**
 * @param {?proto.affiliator_order.GetOrderDraftListResponseDataUser|undefined} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData} returns this
*/
proto.affiliator_order.GetOrderDraftListResponseData.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.hasUser = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string remarks = 12;
 * @return {string}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string declinal_remarks = 13;
 * @return {string}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.getDeclinalRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.setDeclinalRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string created_at = 14;
 * @return {string}
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData} returns this
 */
proto.affiliator_order.GetOrderDraftListResponseData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_order.GetOrderDraftListResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderDraftListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderDraftListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderDraftListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDraftListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_order.Meta.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.affiliator_order.Pagination.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.affiliator_order.GetOrderDraftListResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderDraftListResponse}
 */
proto.affiliator_order.GetOrderDraftListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderDraftListResponse;
  return proto.affiliator_order.GetOrderDraftListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderDraftListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderDraftListResponse}
 */
proto.affiliator_order.GetOrderDraftListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_order.Meta;
      reader.readMessage(value,proto.affiliator_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_order.Pagination;
      reader.readMessage(value,proto.affiliator_order.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.affiliator_order.GetOrderDraftListResponseData;
      reader.readMessage(value,proto.affiliator_order.GetOrderDraftListResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderDraftListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderDraftListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderDraftListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDraftListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_order.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.affiliator_order.GetOrderDraftListResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_order.Meta}
 */
proto.affiliator_order.GetOrderDraftListResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.Meta, 1));
};


/**
 * @param {?proto.affiliator_order.Meta|undefined} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponse} returns this
*/
proto.affiliator_order.GetOrderDraftListResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.GetOrderDraftListResponse} returns this
 */
proto.affiliator_order.GetOrderDraftListResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDraftListResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.affiliator_order.Pagination}
 */
proto.affiliator_order.GetOrderDraftListResponse.prototype.getPagination = function() {
  return /** @type{?proto.affiliator_order.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.Pagination, 2));
};


/**
 * @param {?proto.affiliator_order.Pagination|undefined} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponse} returns this
*/
proto.affiliator_order.GetOrderDraftListResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.GetOrderDraftListResponse} returns this
 */
proto.affiliator_order.GetOrderDraftListResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDraftListResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated GetOrderDraftListResponseData data = 3;
 * @return {!Array<!proto.affiliator_order.GetOrderDraftListResponseData>}
 */
proto.affiliator_order.GetOrderDraftListResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.affiliator_order.GetOrderDraftListResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_order.GetOrderDraftListResponseData, 3));
};


/**
 * @param {!Array<!proto.affiliator_order.GetOrderDraftListResponseData>} value
 * @return {!proto.affiliator_order.GetOrderDraftListResponse} returns this
*/
proto.affiliator_order.GetOrderDraftListResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.affiliator_order.GetOrderDraftListResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_order.GetOrderDraftListResponseData}
 */
proto.affiliator_order.GetOrderDraftListResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.affiliator_order.GetOrderDraftListResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_order.GetOrderDraftListResponse} returns this
 */
proto.affiliator_order.GetOrderDraftListResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.DeclineDraftRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.DeclineDraftRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.DeclineDraftRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.DeclineDraftRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderDraftId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    remarks: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.DeclineDraftRequest}
 */
proto.affiliator_order.DeclineDraftRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.DeclineDraftRequest;
  return proto.affiliator_order.DeclineDraftRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.DeclineDraftRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.DeclineDraftRequest}
 */
proto.affiliator_order.DeclineDraftRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderDraftId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.DeclineDraftRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.DeclineDraftRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.DeclineDraftRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.DeclineDraftRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderDraftId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string order_draft_id = 1;
 * @return {string}
 */
proto.affiliator_order.DeclineDraftRequest.prototype.getOrderDraftId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.DeclineDraftRequest} returns this
 */
proto.affiliator_order.DeclineDraftRequest.prototype.setOrderDraftId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string remarks = 2;
 * @return {string}
 */
proto.affiliator_order.DeclineDraftRequest.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.DeclineDraftRequest} returns this
 */
proto.affiliator_order.DeclineDraftRequest.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.DeclineDraftResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.DeclineDraftResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.DeclineDraftResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.DeclineDraftResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.DeclineDraftResponseData}
 */
proto.affiliator_order.DeclineDraftResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.DeclineDraftResponseData;
  return proto.affiliator_order.DeclineDraftResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.DeclineDraftResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.DeclineDraftResponseData}
 */
proto.affiliator_order.DeclineDraftResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.DeclineDraftResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.DeclineDraftResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.DeclineDraftResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.DeclineDraftResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.DeclineDraftResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.DeclineDraftResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.DeclineDraftResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.DeclineDraftResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_order.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.affiliator_order.DeclineDraftResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.DeclineDraftResponse}
 */
proto.affiliator_order.DeclineDraftResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.DeclineDraftResponse;
  return proto.affiliator_order.DeclineDraftResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.DeclineDraftResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.DeclineDraftResponse}
 */
proto.affiliator_order.DeclineDraftResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_order.Meta;
      reader.readMessage(value,proto.affiliator_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_order.DeclineDraftResponseData;
      reader.readMessage(value,proto.affiliator_order.DeclineDraftResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.DeclineDraftResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.DeclineDraftResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.DeclineDraftResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.DeclineDraftResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_order.DeclineDraftResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_order.Meta}
 */
proto.affiliator_order.DeclineDraftResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.Meta, 1));
};


/**
 * @param {?proto.affiliator_order.Meta|undefined} value
 * @return {!proto.affiliator_order.DeclineDraftResponse} returns this
*/
proto.affiliator_order.DeclineDraftResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.DeclineDraftResponse} returns this
 */
proto.affiliator_order.DeclineDraftResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.DeclineDraftResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeclineDraftResponseData data = 2;
 * @return {?proto.affiliator_order.DeclineDraftResponseData}
 */
proto.affiliator_order.DeclineDraftResponse.prototype.getData = function() {
  return /** @type{?proto.affiliator_order.DeclineDraftResponseData} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.DeclineDraftResponseData, 2));
};


/**
 * @param {?proto.affiliator_order.DeclineDraftResponseData|undefined} value
 * @return {!proto.affiliator_order.DeclineDraftResponse} returns this
*/
proto.affiliator_order.DeclineDraftResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.DeclineDraftResponse} returns this
 */
proto.affiliator_order.DeclineDraftResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.DeclineDraftResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isFinished: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderListRequest}
 */
proto.affiliator_order.GetOrderListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderListRequest;
  return proto.affiliator_order.GetOrderListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderListRequest}
 */
proto.affiliator_order.GetOrderListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFinished(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getIsFinished();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.affiliator_order.GetOrderListRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderListRequest} returns this
 */
proto.affiliator_order.GetOrderListRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.affiliator_order.GetOrderListRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderListRequest} returns this
 */
proto.affiliator_order.GetOrderListRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_finished = 3;
 * @return {boolean}
 */
proto.affiliator_order.GetOrderListRequest.prototype.getIsFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_order.GetOrderListRequest} returns this
 */
proto.affiliator_order.GetOrderListRequest.prototype.setIsFinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderListResponseDataUser.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderListResponseDataUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderListResponseDataUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderListResponseDataUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderListResponseDataUser}
 */
proto.affiliator_order.GetOrderListResponseDataUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderListResponseDataUser;
  return proto.affiliator_order.GetOrderListResponseDataUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderListResponseDataUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderListResponseDataUser}
 */
proto.affiliator_order.GetOrderListResponseDataUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderListResponseDataUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderListResponseDataUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderListResponseDataUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderListResponseDataUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_order.GetOrderListResponseDataUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderListResponseDataUser} returns this
 */
proto.affiliator_order.GetOrderListResponseDataUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.affiliator_order.GetOrderListResponseDataUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderListResponseDataUser} returns this
 */
proto.affiliator_order.GetOrderListResponseDataUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.affiliator_order.GetOrderListResponseDataUser.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderListResponseDataUser} returns this
 */
proto.affiliator_order.GetOrderListResponseDataUser.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderListResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderListResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderListResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dueDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    thumbnailUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    url: jspb.Message.getFieldWithDefault(msg, 6, ""),
    hasUnreadMessage: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    hasSuspiciousActivity: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isFinished: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    positiveComment: jspb.Message.getFieldWithDefault(msg, 10, 0),
    negativeComment: jspb.Message.getFieldWithDefault(msg, 11, 0),
    tagFriend: jspb.Message.getFieldWithDefault(msg, 12, 0),
    like: jspb.Message.getFieldWithDefault(msg, 13, 0),
    share: jspb.Message.getFieldWithDefault(msg, 14, 0),
    positiveCommentDone: jspb.Message.getFieldWithDefault(msg, 15, 0),
    negativeCommentDone: jspb.Message.getFieldWithDefault(msg, 16, 0),
    tagFriendDone: jspb.Message.getFieldWithDefault(msg, 17, 0),
    likeDone: jspb.Message.getFieldWithDefault(msg, 18, 0),
    shareDone: jspb.Message.getFieldWithDefault(msg, 19, 0),
    finishedAt: jspb.Message.getFieldWithDefault(msg, 20, ""),
    user: (f = msg.getUser()) && proto.affiliator_order.GetOrderListResponseDataUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderListResponseData}
 */
proto.affiliator_order.GetOrderListResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderListResponseData;
  return proto.affiliator_order.GetOrderListResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderListResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderListResponseData}
 */
proto.affiliator_order.GetOrderListResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDueDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasUnreadMessage(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSuspiciousActivity(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFinished(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositiveComment(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNegativeComment(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTagFriend(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLike(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShare(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositiveCommentDone(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNegativeCommentDone(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTagFriendDone(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLikeDone(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShareDone(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinishedAt(value);
      break;
    case 21:
      var value = new proto.affiliator_order.GetOrderListResponseDataUser;
      reader.readMessage(value,proto.affiliator_order.GetOrderListResponseDataUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderListResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderListResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderListResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDueDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getThumbnailUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHasUnreadMessage();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getHasSuspiciousActivity();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsFinished();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getPositiveComment();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getNegativeComment();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getTagFriend();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getLike();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getShare();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getPositiveCommentDone();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getNegativeCommentDone();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getTagFriendDone();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getLikeDone();
  if (f !== 0) {
    writer.writeUint32(
      18,
      f
    );
  }
  f = message.getShareDone();
  if (f !== 0) {
    writer.writeUint32(
      19,
      f
    );
  }
  f = message.getFinishedAt();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.affiliator_order.GetOrderListResponseDataUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string due_date = 2;
 * @return {string}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getDueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string thumbnail_url = 5;
 * @return {string}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getThumbnailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setThumbnailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string url = 6;
 * @return {string}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool has_unread_message = 7;
 * @return {boolean}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getHasUnreadMessage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setHasUnreadMessage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool has_suspicious_activity = 8;
 * @return {boolean}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getHasSuspiciousActivity = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setHasSuspiciousActivity = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool is_finished = 9;
 * @return {boolean}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getIsFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setIsFinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional uint32 positive_comment = 10;
 * @return {number}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getPositiveComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setPositiveComment = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 negative_comment = 11;
 * @return {number}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getNegativeComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setNegativeComment = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 tag_friend = 12;
 * @return {number}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getTagFriend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setTagFriend = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 like = 13;
 * @return {number}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getLike = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setLike = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint32 share = 14;
 * @return {number}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getShare = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setShare = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint32 positive_comment_done = 15;
 * @return {number}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getPositiveCommentDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setPositiveCommentDone = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint32 negative_comment_done = 16;
 * @return {number}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getNegativeCommentDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setNegativeCommentDone = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint32 tag_friend_done = 17;
 * @return {number}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getTagFriendDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setTagFriendDone = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional uint32 like_done = 18;
 * @return {number}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getLikeDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setLikeDone = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional uint32 share_done = 19;
 * @return {number}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getShareDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setShareDone = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string finished_at = 20;
 * @return {string}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getFinishedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.setFinishedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional GetOrderListResponseDataUser user = 21;
 * @return {?proto.affiliator_order.GetOrderListResponseDataUser}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.getUser = function() {
  return /** @type{?proto.affiliator_order.GetOrderListResponseDataUser} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.GetOrderListResponseDataUser, 21));
};


/**
 * @param {?proto.affiliator_order.GetOrderListResponseDataUser|undefined} value
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
*/
proto.affiliator_order.GetOrderListResponseData.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.GetOrderListResponseData} returns this
 */
proto.affiliator_order.GetOrderListResponseData.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.GetOrderListResponseData.prototype.hasUser = function() {
  return jspb.Message.getField(this, 21) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_order.GetOrderListResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_order.Meta.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.affiliator_order.Pagination.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.affiliator_order.GetOrderListResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderListResponse}
 */
proto.affiliator_order.GetOrderListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderListResponse;
  return proto.affiliator_order.GetOrderListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderListResponse}
 */
proto.affiliator_order.GetOrderListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_order.Meta;
      reader.readMessage(value,proto.affiliator_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_order.Pagination;
      reader.readMessage(value,proto.affiliator_order.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.affiliator_order.GetOrderListResponseData;
      reader.readMessage(value,proto.affiliator_order.GetOrderListResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_order.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.affiliator_order.GetOrderListResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_order.Meta}
 */
proto.affiliator_order.GetOrderListResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.Meta, 1));
};


/**
 * @param {?proto.affiliator_order.Meta|undefined} value
 * @return {!proto.affiliator_order.GetOrderListResponse} returns this
*/
proto.affiliator_order.GetOrderListResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.GetOrderListResponse} returns this
 */
proto.affiliator_order.GetOrderListResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.GetOrderListResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.affiliator_order.Pagination}
 */
proto.affiliator_order.GetOrderListResponse.prototype.getPagination = function() {
  return /** @type{?proto.affiliator_order.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.Pagination, 2));
};


/**
 * @param {?proto.affiliator_order.Pagination|undefined} value
 * @return {!proto.affiliator_order.GetOrderListResponse} returns this
*/
proto.affiliator_order.GetOrderListResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.GetOrderListResponse} returns this
 */
proto.affiliator_order.GetOrderListResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.GetOrderListResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated GetOrderListResponseData data = 3;
 * @return {!Array<!proto.affiliator_order.GetOrderListResponseData>}
 */
proto.affiliator_order.GetOrderListResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.affiliator_order.GetOrderListResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_order.GetOrderListResponseData, 3));
};


/**
 * @param {!Array<!proto.affiliator_order.GetOrderListResponseData>} value
 * @return {!proto.affiliator_order.GetOrderListResponse} returns this
*/
proto.affiliator_order.GetOrderListResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.affiliator_order.GetOrderListResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_order.GetOrderListResponseData}
 */
proto.affiliator_order.GetOrderListResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.affiliator_order.GetOrderListResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_order.GetOrderListResponse} returns this
 */
proto.affiliator_order.GetOrderListResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderDetailRequest}
 */
proto.affiliator_order.GetOrderDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderDetailRequest;
  return proto.affiliator_order.GetOrderDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderDetailRequest}
 */
proto.affiliator_order.GetOrderDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailRequest} returns this
 */
proto.affiliator_order.GetOrderDetailRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderDetailResponseDataUser.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderDetailResponseDataUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderDetailResponseDataUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDetailResponseDataUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataUser}
 */
proto.affiliator_order.GetOrderDetailResponseDataUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderDetailResponseDataUser;
  return proto.affiliator_order.GetOrderDetailResponseDataUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderDetailResponseDataUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataUser}
 */
proto.affiliator_order.GetOrderDetailResponseDataUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderDetailResponseDataUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderDetailResponseDataUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderDetailResponseDataUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDetailResponseDataUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseDataUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataUser} returns this
 */
proto.affiliator_order.GetOrderDetailResponseDataUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseDataUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataUser} returns this
 */
proto.affiliator_order.GetOrderDetailResponseDataUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseDataUser.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataUser} returns this
 */
proto.affiliator_order.GetOrderDetailResponseDataUser.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    finishedAt: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount;
  return proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinishedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFinishedAt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount} returns this
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount} returns this
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount} returns this
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string finished_at = 4;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.prototype.getFinishedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount} returns this
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.prototype.setFinishedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderDetailResponseDataCommentator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderDetailResponseDataCommentator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataCommentator}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderDetailResponseDataCommentator;
  return proto.affiliator_order.GetOrderDetailResponseDataCommentator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderDetailResponseDataCommentator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataCommentator}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    case 5:
      var value = new proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount;
      reader.readMessage(value,proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderDetailResponseDataCommentator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderDetailResponseDataCommentator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataCommentator} returns this
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataCommentator} returns this
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataCommentator} returns this
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated GetOrderDetailResponseDataCommentatorAccount accounts = 5;
 * @return {!Array<!proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount>}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount, 5));
};


/**
 * @param {!Array<!proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount>} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataCommentator} returns this
*/
proto.affiliator_order.GetOrderDetailResponseDataCommentator.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount}
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.affiliator_order.GetOrderDetailResponseDataCommentatorAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataCommentator} returns this
 */
proto.affiliator_order.GetOrderDetailResponseDataCommentator.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_order.GetOrderDetailResponseData.repeatedFields_ = [29];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderDetailResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderDetailResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDetailResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dueDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    platform: jspb.Message.getFieldWithDefault(msg, 3, ""),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    thumbnailUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    url: jspb.Message.getFieldWithDefault(msg, 7, ""),
    positiveComment: jspb.Message.getFieldWithDefault(msg, 8, 0),
    negativeComment: jspb.Message.getFieldWithDefault(msg, 9, 0),
    tagFriend: jspb.Message.getFieldWithDefault(msg, 10, 0),
    like: jspb.Message.getFieldWithDefault(msg, 11, 0),
    share: jspb.Message.getFieldWithDefault(msg, 12, 0),
    positiveCommentDone: jspb.Message.getFieldWithDefault(msg, 13, 0),
    negativeCommentDone: jspb.Message.getFieldWithDefault(msg, 14, 0),
    tagFriendDone: jspb.Message.getFieldWithDefault(msg, 15, 0),
    likeDone: jspb.Message.getFieldWithDefault(msg, 16, 0),
    shareDone: jspb.Message.getFieldWithDefault(msg, 17, 0),
    remarks: jspb.Message.getFieldWithDefault(msg, 18, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 19, ""),
    finishedAt: jspb.Message.getFieldWithDefault(msg, 20, ""),
    reportImageUrl: jspb.Message.getFieldWithDefault(msg, 21, ""),
    feedbackRating: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    feedbackComment: jspb.Message.getFieldWithDefault(msg, 23, ""),
    hasUnreadClientMessage: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    hasUnreadAdminMessage: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    hasUnreadCommentatorMessage: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    hasSuspiciousActivity: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    user: (f = msg.getUser()) && proto.affiliator_order.GetOrderDetailResponseDataUser.toObject(includeInstance, f),
    commentatorsList: jspb.Message.toObjectList(msg.getCommentatorsList(),
    proto.affiliator_order.GetOrderDetailResponseDataCommentator.toObject, includeInstance),
    initialImageUrl: jspb.Message.getFieldWithDefault(msg, 30, ""),
    feedbackTime: jspb.Message.getFieldWithDefault(msg, 31, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderDetailResponseData}
 */
proto.affiliator_order.GetOrderDetailResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderDetailResponseData;
  return proto.affiliator_order.GetOrderDetailResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderDetailResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderDetailResponseData}
 */
proto.affiliator_order.GetOrderDetailResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDueDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatform(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositiveComment(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNegativeComment(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTagFriend(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLike(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShare(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositiveCommentDone(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNegativeCommentDone(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTagFriendDone(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLikeDone(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShareDone(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinishedAt(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportImageUrl(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFeedbackRating(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedbackComment(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasUnreadClientMessage(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasUnreadAdminMessage(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasUnreadCommentatorMessage(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSuspiciousActivity(value);
      break;
    case 28:
      var value = new proto.affiliator_order.GetOrderDetailResponseDataUser;
      reader.readMessage(value,proto.affiliator_order.GetOrderDetailResponseDataUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 29:
      var value = new proto.affiliator_order.GetOrderDetailResponseDataCommentator;
      reader.readMessage(value,proto.affiliator_order.GetOrderDetailResponseDataCommentator.deserializeBinaryFromReader);
      msg.addCommentators(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialImageUrl(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedbackTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderDetailResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderDetailResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDetailResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDueDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlatform();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getThumbnailUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPositiveComment();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getNegativeComment();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getTagFriend();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getLike();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getShare();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getPositiveCommentDone();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getNegativeCommentDone();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getTagFriendDone();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getLikeDone();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getShareDone();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getFinishedAt();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getReportImageUrl();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getFeedbackRating();
  if (f !== 0.0) {
    writer.writeFloat(
      22,
      f
    );
  }
  f = message.getFeedbackComment();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getHasUnreadClientMessage();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getHasUnreadAdminMessage();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getHasUnreadCommentatorMessage();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getHasSuspiciousActivity();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.affiliator_order.GetOrderDetailResponseDataUser.serializeBinaryToWriter
    );
  }
  f = message.getCommentatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      29,
      f,
      proto.affiliator_order.GetOrderDetailResponseDataCommentator.serializeBinaryToWriter
    );
  }
  f = message.getInitialImageUrl();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getFeedbackTime();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string due_date = 2;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getDueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string platform = 3;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getPlatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string thumbnail_url = 6;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getThumbnailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setThumbnailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string url = 7;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 positive_comment = 8;
 * @return {number}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getPositiveComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setPositiveComment = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 negative_comment = 9;
 * @return {number}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getNegativeComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setNegativeComment = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 tag_friend = 10;
 * @return {number}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getTagFriend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setTagFriend = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 like = 11;
 * @return {number}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getLike = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setLike = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 share = 12;
 * @return {number}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getShare = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setShare = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 positive_comment_done = 13;
 * @return {number}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getPositiveCommentDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setPositiveCommentDone = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint32 negative_comment_done = 14;
 * @return {number}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getNegativeCommentDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setNegativeCommentDone = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint32 tag_friend_done = 15;
 * @return {number}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getTagFriendDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setTagFriendDone = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint32 like_done = 16;
 * @return {number}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getLikeDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setLikeDone = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint32 share_done = 17;
 * @return {number}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getShareDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setShareDone = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string remarks = 18;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string created_at = 19;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string finished_at = 20;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getFinishedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setFinishedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string report_image_url = 21;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getReportImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setReportImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional float feedback_rating = 22;
 * @return {number}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getFeedbackRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setFeedbackRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional string feedback_comment = 23;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getFeedbackComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setFeedbackComment = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional bool has_unread_client_message = 24;
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getHasUnreadClientMessage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setHasUnreadClientMessage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional bool has_unread_admin_message = 25;
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getHasUnreadAdminMessage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setHasUnreadAdminMessage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional bool has_unread_commentator_message = 26;
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getHasUnreadCommentatorMessage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setHasUnreadCommentatorMessage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool has_suspicious_activity = 27;
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getHasSuspiciousActivity = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setHasSuspiciousActivity = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional GetOrderDetailResponseDataUser user = 28;
 * @return {?proto.affiliator_order.GetOrderDetailResponseDataUser}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getUser = function() {
  return /** @type{?proto.affiliator_order.GetOrderDetailResponseDataUser} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.GetOrderDetailResponseDataUser, 28));
};


/**
 * @param {?proto.affiliator_order.GetOrderDetailResponseDataUser|undefined} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
*/
proto.affiliator_order.GetOrderDetailResponseData.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.hasUser = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * repeated GetOrderDetailResponseDataCommentator commentators = 29;
 * @return {!Array<!proto.affiliator_order.GetOrderDetailResponseDataCommentator>}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getCommentatorsList = function() {
  return /** @type{!Array<!proto.affiliator_order.GetOrderDetailResponseDataCommentator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_order.GetOrderDetailResponseDataCommentator, 29));
};


/**
 * @param {!Array<!proto.affiliator_order.GetOrderDetailResponseDataCommentator>} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
*/
proto.affiliator_order.GetOrderDetailResponseData.prototype.setCommentatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 29, value);
};


/**
 * @param {!proto.affiliator_order.GetOrderDetailResponseDataCommentator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_order.GetOrderDetailResponseDataCommentator}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.addCommentators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 29, opt_value, proto.affiliator_order.GetOrderDetailResponseDataCommentator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.clearCommentatorsList = function() {
  return this.setCommentatorsList([]);
};


/**
 * optional string initial_image_url = 30;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getInitialImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setInitialImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string feedback_time = 31;
 * @return {string}
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.getFeedbackTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDetailResponseData} returns this
 */
proto.affiliator_order.GetOrderDetailResponseData.prototype.setFeedbackTime = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_order.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.affiliator_order.GetOrderDetailResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderDetailResponse}
 */
proto.affiliator_order.GetOrderDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderDetailResponse;
  return proto.affiliator_order.GetOrderDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderDetailResponse}
 */
proto.affiliator_order.GetOrderDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_order.Meta;
      reader.readMessage(value,proto.affiliator_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_order.GetOrderDetailResponseData;
      reader.readMessage(value,proto.affiliator_order.GetOrderDetailResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_order.GetOrderDetailResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_order.Meta}
 */
proto.affiliator_order.GetOrderDetailResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.Meta, 1));
};


/**
 * @param {?proto.affiliator_order.Meta|undefined} value
 * @return {!proto.affiliator_order.GetOrderDetailResponse} returns this
*/
proto.affiliator_order.GetOrderDetailResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.GetOrderDetailResponse} returns this
 */
proto.affiliator_order.GetOrderDetailResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDetailResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetOrderDetailResponseData data = 2;
 * @return {?proto.affiliator_order.GetOrderDetailResponseData}
 */
proto.affiliator_order.GetOrderDetailResponse.prototype.getData = function() {
  return /** @type{?proto.affiliator_order.GetOrderDetailResponseData} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.GetOrderDetailResponseData, 2));
};


/**
 * @param {?proto.affiliator_order.GetOrderDetailResponseData|undefined} value
 * @return {!proto.affiliator_order.GetOrderDetailResponse} returns this
*/
proto.affiliator_order.GetOrderDetailResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.GetOrderDetailResponse} returns this
 */
proto.affiliator_order.GetOrderDetailResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDetailResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderDiscussionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderDiscussionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderDiscussionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDiscussionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderDiscussionRequest}
 */
proto.affiliator_order.GetOrderDiscussionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderDiscussionRequest;
  return proto.affiliator_order.GetOrderDiscussionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderDiscussionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderDiscussionRequest}
 */
proto.affiliator_order.GetOrderDiscussionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderDiscussionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderDiscussionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderDiscussionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDiscussionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.affiliator_order.GetOrderDiscussionRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDiscussionRequest} returns this
 */
proto.affiliator_order.GetOrderDiscussionRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.affiliator_order.GetOrderDiscussionRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDiscussionRequest} returns this
 */
proto.affiliator_order.GetOrderDiscussionRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataUser.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderDiscussionResponseDataUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderDiscussionResponseDataUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDiscussionResponseDataUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseDataUser}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderDiscussionResponseDataUser;
  return proto.affiliator_order.GetOrderDiscussionResponseDataUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderDiscussionResponseDataUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseDataUser}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderDiscussionResponseDataUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderDiscussionResponseDataUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDiscussionResponseDataUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseDataUser} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponseDataUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseDataUser} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponseDataUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataUser.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseDataUser} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponseDataUser.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderDiscussionResponseDataReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderDiscussionResponseDataReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attachmentUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    selfComment: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    user: (f = msg.getUser()) && proto.affiliator_order.GetOrderDiscussionResponseDataUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseDataReply}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderDiscussionResponseDataReply;
  return proto.affiliator_order.GetOrderDiscussionResponseDataReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderDiscussionResponseDataReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseDataReply}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentUrl(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelfComment(value);
      break;
    case 6:
      var value = new proto.affiliator_order.GetOrderDiscussionResponseDataUser;
      reader.readMessage(value,proto.affiliator_order.GetOrderDiscussionResponseDataUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderDiscussionResponseDataReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderDiscussionResponseDataReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttachmentUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSelfComment();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.affiliator_order.GetOrderDiscussionResponseDataUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string attachment_url = 4;
 * @return {string}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.prototype.getAttachmentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.prototype.setAttachmentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool self_comment = 5;
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.prototype.getSelfComment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.prototype.setSelfComment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional GetOrderDiscussionResponseDataUser user = 6;
 * @return {?proto.affiliator_order.GetOrderDiscussionResponseDataUser}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.prototype.getUser = function() {
  return /** @type{?proto.affiliator_order.GetOrderDiscussionResponseDataUser} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.GetOrderDiscussionResponseDataUser, 6));
};


/**
 * @param {?proto.affiliator_order.GetOrderDiscussionResponseDataUser|undefined} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseDataReply} returns this
*/
proto.affiliator_order.GetOrderDiscussionResponseDataReply.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDiscussionResponseDataReply.prototype.hasUser = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_order.GetOrderDiscussionResponseData.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderDiscussionResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderDiscussionResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDiscussionResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attachmentUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    selfComment: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    user: (f = msg.getUser()) && proto.affiliator_order.GetOrderDiscussionResponseDataUser.toObject(includeInstance, f),
    repliesList: jspb.Message.toObjectList(msg.getRepliesList(),
    proto.affiliator_order.GetOrderDiscussionResponseDataReply.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseData}
 */
proto.affiliator_order.GetOrderDiscussionResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderDiscussionResponseData;
  return proto.affiliator_order.GetOrderDiscussionResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderDiscussionResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseData}
 */
proto.affiliator_order.GetOrderDiscussionResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentUrl(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelfComment(value);
      break;
    case 6:
      var value = new proto.affiliator_order.GetOrderDiscussionResponseDataUser;
      reader.readMessage(value,proto.affiliator_order.GetOrderDiscussionResponseDataUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 7:
      var value = new proto.affiliator_order.GetOrderDiscussionResponseDataReply;
      reader.readMessage(value,proto.affiliator_order.GetOrderDiscussionResponseDataReply.deserializeBinaryFromReader);
      msg.addReplies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderDiscussionResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderDiscussionResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDiscussionResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttachmentUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSelfComment();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.affiliator_order.GetOrderDiscussionResponseDataUser.serializeBinaryToWriter
    );
  }
  f = message.getRepliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.affiliator_order.GetOrderDiscussionResponseDataReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseData} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseData} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseData} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string attachment_url = 4;
 * @return {string}
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.getAttachmentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseData} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.setAttachmentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool self_comment = 5;
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.getSelfComment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseData} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.setSelfComment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional GetOrderDiscussionResponseDataUser user = 6;
 * @return {?proto.affiliator_order.GetOrderDiscussionResponseDataUser}
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.getUser = function() {
  return /** @type{?proto.affiliator_order.GetOrderDiscussionResponseDataUser} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.GetOrderDiscussionResponseDataUser, 6));
};


/**
 * @param {?proto.affiliator_order.GetOrderDiscussionResponseDataUser|undefined} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseData} returns this
*/
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseData} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.hasUser = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated GetOrderDiscussionResponseDataReply replies = 7;
 * @return {!Array<!proto.affiliator_order.GetOrderDiscussionResponseDataReply>}
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.getRepliesList = function() {
  return /** @type{!Array<!proto.affiliator_order.GetOrderDiscussionResponseDataReply>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_order.GetOrderDiscussionResponseDataReply, 7));
};


/**
 * @param {!Array<!proto.affiliator_order.GetOrderDiscussionResponseDataReply>} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseData} returns this
*/
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.setRepliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.affiliator_order.GetOrderDiscussionResponseDataReply=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseDataReply}
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.addReplies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.affiliator_order.GetOrderDiscussionResponseDataReply, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseData} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponseData.prototype.clearRepliesList = function() {
  return this.setRepliesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_order.GetOrderDiscussionResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderDiscussionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderDiscussionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderDiscussionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDiscussionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_order.Meta.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.affiliator_order.GetOrderDiscussionResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderDiscussionResponse}
 */
proto.affiliator_order.GetOrderDiscussionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderDiscussionResponse;
  return proto.affiliator_order.GetOrderDiscussionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderDiscussionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderDiscussionResponse}
 */
proto.affiliator_order.GetOrderDiscussionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_order.Meta;
      reader.readMessage(value,proto.affiliator_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_order.GetOrderDiscussionResponseData;
      reader.readMessage(value,proto.affiliator_order.GetOrderDiscussionResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderDiscussionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderDiscussionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderDiscussionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderDiscussionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.affiliator_order.GetOrderDiscussionResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_order.Meta}
 */
proto.affiliator_order.GetOrderDiscussionResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.Meta, 1));
};


/**
 * @param {?proto.affiliator_order.Meta|undefined} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponse} returns this
*/
proto.affiliator_order.GetOrderDiscussionResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.GetOrderDiscussionResponse} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.GetOrderDiscussionResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated GetOrderDiscussionResponseData data = 2;
 * @return {!Array<!proto.affiliator_order.GetOrderDiscussionResponseData>}
 */
proto.affiliator_order.GetOrderDiscussionResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.affiliator_order.GetOrderDiscussionResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_order.GetOrderDiscussionResponseData, 2));
};


/**
 * @param {!Array<!proto.affiliator_order.GetOrderDiscussionResponseData>} value
 * @return {!proto.affiliator_order.GetOrderDiscussionResponse} returns this
*/
proto.affiliator_order.GetOrderDiscussionResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.affiliator_order.GetOrderDiscussionResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_order.GetOrderDiscussionResponseData}
 */
proto.affiliator_order.GetOrderDiscussionResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.affiliator_order.GetOrderDiscussionResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_order.GetOrderDiscussionResponse} returns this
 */
proto.affiliator_order.GetOrderDiscussionResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.SendDiscussionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.SendDiscussionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.SendDiscussionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SendDiscussionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parentOrderDiscussionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 4, ""),
    attachmentPath: jspb.Message.getFieldWithDefault(msg, 5, ""),
    attachmentPathType: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.SendDiscussionRequest}
 */
proto.affiliator_order.SendDiscussionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.SendDiscussionRequest;
  return proto.affiliator_order.SendDiscussionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.SendDiscussionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.SendDiscussionRequest}
 */
proto.affiliator_order.SendDiscussionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentOrderDiscussionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentPath(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentPathType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.SendDiscussionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.SendDiscussionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.SendDiscussionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SendDiscussionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParentOrderDiscussionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAttachmentPath();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAttachmentPathType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.affiliator_order.SendDiscussionRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.SendDiscussionRequest} returns this
 */
proto.affiliator_order.SendDiscussionRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.affiliator_order.SendDiscussionRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.SendDiscussionRequest} returns this
 */
proto.affiliator_order.SendDiscussionRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string parent_order_discussion_id = 3;
 * @return {string}
 */
proto.affiliator_order.SendDiscussionRequest.prototype.getParentOrderDiscussionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.SendDiscussionRequest} returns this
 */
proto.affiliator_order.SendDiscussionRequest.prototype.setParentOrderDiscussionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string comment = 4;
 * @return {string}
 */
proto.affiliator_order.SendDiscussionRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.SendDiscussionRequest} returns this
 */
proto.affiliator_order.SendDiscussionRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string attachment_path = 5;
 * @return {string}
 */
proto.affiliator_order.SendDiscussionRequest.prototype.getAttachmentPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.SendDiscussionRequest} returns this
 */
proto.affiliator_order.SendDiscussionRequest.prototype.setAttachmentPath = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string attachment_path_type = 6;
 * @return {string}
 */
proto.affiliator_order.SendDiscussionRequest.prototype.getAttachmentPathType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.SendDiscussionRequest} returns this
 */
proto.affiliator_order.SendDiscussionRequest.prototype.setAttachmentPathType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.SendDiscussionResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.SendDiscussionResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.SendDiscussionResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SendDiscussionResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.SendDiscussionResponseData}
 */
proto.affiliator_order.SendDiscussionResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.SendDiscussionResponseData;
  return proto.affiliator_order.SendDiscussionResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.SendDiscussionResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.SendDiscussionResponseData}
 */
proto.affiliator_order.SendDiscussionResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.SendDiscussionResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.SendDiscussionResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.SendDiscussionResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SendDiscussionResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.SendDiscussionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.SendDiscussionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.SendDiscussionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SendDiscussionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_order.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.affiliator_order.SendDiscussionResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.SendDiscussionResponse}
 */
proto.affiliator_order.SendDiscussionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.SendDiscussionResponse;
  return proto.affiliator_order.SendDiscussionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.SendDiscussionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.SendDiscussionResponse}
 */
proto.affiliator_order.SendDiscussionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_order.Meta;
      reader.readMessage(value,proto.affiliator_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_order.SendDiscussionResponseData;
      reader.readMessage(value,proto.affiliator_order.SendDiscussionResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.SendDiscussionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.SendDiscussionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.SendDiscussionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SendDiscussionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_order.SendDiscussionResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_order.Meta}
 */
proto.affiliator_order.SendDiscussionResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.Meta, 1));
};


/**
 * @param {?proto.affiliator_order.Meta|undefined} value
 * @return {!proto.affiliator_order.SendDiscussionResponse} returns this
*/
proto.affiliator_order.SendDiscussionResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.SendDiscussionResponse} returns this
 */
proto.affiliator_order.SendDiscussionResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.SendDiscussionResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SendDiscussionResponseData data = 2;
 * @return {?proto.affiliator_order.SendDiscussionResponseData}
 */
proto.affiliator_order.SendDiscussionResponse.prototype.getData = function() {
  return /** @type{?proto.affiliator_order.SendDiscussionResponseData} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.SendDiscussionResponseData, 2));
};


/**
 * @param {?proto.affiliator_order.SendDiscussionResponseData|undefined} value
 * @return {!proto.affiliator_order.SendDiscussionResponse} returns this
*/
proto.affiliator_order.SendDiscussionResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.SendDiscussionResponse} returns this
 */
proto.affiliator_order.SendDiscussionResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.SendDiscussionResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderSuspiciousActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderSuspiciousActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderSuspiciousActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityRequest}
 */
proto.affiliator_order.GetOrderSuspiciousActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderSuspiciousActivityRequest;
  return proto.affiliator_order.GetOrderSuspiciousActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityRequest}
 */
proto.affiliator_order.GetOrderSuspiciousActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderSuspiciousActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderSuspiciousActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderSuspiciousActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.affiliator_order.GetOrderSuspiciousActivityRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityRequest} returns this
 */
proto.affiliator_order.GetOrderSuspiciousActivityRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    remarks: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount;
  return proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount} returns this
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount} returns this
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount} returns this
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string remarks = 4;
 * @return {string}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount} returns this
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    offenseCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator;
  return proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOffenseCount(value);
      break;
    case 5:
      var value = new proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount;
      reader.readMessage(value,proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOffenseCount();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator} returns this
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator} returns this
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator} returns this
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 offense_count = 4;
 * @return {number}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.prototype.getOffenseCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator} returns this
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.prototype.setOffenseCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated GetOrderSuspiciousActivityResponseDataCommentatorAccount accounts = 5;
 * @return {!Array<!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount>}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount, 5));
};


/**
 * @param {!Array<!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount>} value
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator} returns this
*/
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentatorAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator} returns this
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderSuspiciousActivityResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0),
    commentatorsList: jspb.Message.toObjectList(msg.getCommentatorsList(),
    proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseData}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderSuspiciousActivityResponseData;
  return proto.affiliator_order.GetOrderSuspiciousActivityResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseData}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCount(value);
      break;
    case 2:
      var value = new proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator;
      reader.readMessage(value,proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.deserializeBinaryFromReader);
      msg.addCommentators(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderSuspiciousActivityResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCommentatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 count = 1;
 * @return {number}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseData.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseData} returns this
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseData.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated GetOrderSuspiciousActivityResponseDataCommentator commentators = 2;
 * @return {!Array<!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator>}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseData.prototype.getCommentatorsList = function() {
  return /** @type{!Array<!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator, 2));
};


/**
 * @param {!Array<!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator>} value
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseData} returns this
*/
proto.affiliator_order.GetOrderSuspiciousActivityResponseData.prototype.setCommentatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseData.prototype.addCommentators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.affiliator_order.GetOrderSuspiciousActivityResponseDataCommentator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponseData} returns this
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponseData.prototype.clearCommentatorsList = function() {
  return this.setCommentatorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetOrderSuspiciousActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_order.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.affiliator_order.GetOrderSuspiciousActivityResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponse}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetOrderSuspiciousActivityResponse;
  return proto.affiliator_order.GetOrderSuspiciousActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponse}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_order.Meta;
      reader.readMessage(value,proto.affiliator_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_order.GetOrderSuspiciousActivityResponseData;
      reader.readMessage(value,proto.affiliator_order.GetOrderSuspiciousActivityResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetOrderSuspiciousActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_order.GetOrderSuspiciousActivityResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_order.Meta}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.Meta, 1));
};


/**
 * @param {?proto.affiliator_order.Meta|undefined} value
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponse} returns this
*/
proto.affiliator_order.GetOrderSuspiciousActivityResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponse} returns this
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetOrderSuspiciousActivityResponseData data = 2;
 * @return {?proto.affiliator_order.GetOrderSuspiciousActivityResponseData}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponse.prototype.getData = function() {
  return /** @type{?proto.affiliator_order.GetOrderSuspiciousActivityResponseData} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.GetOrderSuspiciousActivityResponseData, 2));
};


/**
 * @param {?proto.affiliator_order.GetOrderSuspiciousActivityResponseData|undefined} value
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponse} returns this
*/
proto.affiliator_order.GetOrderSuspiciousActivityResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.GetOrderSuspiciousActivityResponse} returns this
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.GetOrderSuspiciousActivityResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetDoneCommentatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetDoneCommentatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetDoneCommentatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetDoneCommentatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetDoneCommentatorRequest}
 */
proto.affiliator_order.GetDoneCommentatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetDoneCommentatorRequest;
  return proto.affiliator_order.GetDoneCommentatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetDoneCommentatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetDoneCommentatorRequest}
 */
proto.affiliator_order.GetDoneCommentatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetDoneCommentatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetDoneCommentatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetDoneCommentatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetDoneCommentatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.affiliator_order.GetDoneCommentatorRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetDoneCommentatorRequest} returns this
 */
proto.affiliator_order.GetDoneCommentatorRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    finishedAt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    positiveComment: jspb.Message.getFieldWithDefault(msg, 5, 0),
    negativeComment: jspb.Message.getFieldWithDefault(msg, 6, 0),
    tagFriend: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount;
  return proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinishedAt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositiveComment(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNegativeComment(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTagFriend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFinishedAt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPositiveComment();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getNegativeComment();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTagFriend();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount} returns this
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount} returns this
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount} returns this
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string finished_at = 4;
 * @return {string}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.prototype.getFinishedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount} returns this
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.prototype.setFinishedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 positive_comment = 5;
 * @return {number}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.prototype.getPositiveComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount} returns this
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.prototype.setPositiveComment = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 negative_comment = 6;
 * @return {number}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.prototype.getNegativeComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount} returns this
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.prototype.setNegativeComment = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 tag_friend = 7;
 * @return {number}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.prototype.getTagFriend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount} returns this
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.prototype.setTagFriend = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentator}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetDoneCommentatorResponseDataCommentator;
  return proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentator}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    case 5:
      var value = new proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount;
      reader.readMessage(value,proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentator} returns this
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentator} returns this
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentator} returns this
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated GetDoneCommentatorResponseDataCommentatorAccount accounts = 5;
 * @return {!Array<!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount>}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount, 5));
};


/**
 * @param {!Array<!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount>} value
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentator} returns this
*/
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount}
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.affiliator_order.GetDoneCommentatorResponseDataCommentatorAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentator} returns this
 */
proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_order.GetDoneCommentatorResponseData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetDoneCommentatorResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetDoneCommentatorResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetDoneCommentatorResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetDoneCommentatorResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0),
    commentatorsList: jspb.Message.toObjectList(msg.getCommentatorsList(),
    proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseData}
 */
proto.affiliator_order.GetDoneCommentatorResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetDoneCommentatorResponseData;
  return proto.affiliator_order.GetDoneCommentatorResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetDoneCommentatorResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseData}
 */
proto.affiliator_order.GetDoneCommentatorResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCount(value);
      break;
    case 2:
      var value = new proto.affiliator_order.GetDoneCommentatorResponseDataCommentator;
      reader.readMessage(value,proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.deserializeBinaryFromReader);
      msg.addCommentators(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetDoneCommentatorResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetDoneCommentatorResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetDoneCommentatorResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetDoneCommentatorResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCommentatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.affiliator_order.GetDoneCommentatorResponseDataCommentator.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 count = 1;
 * @return {number}
 */
proto.affiliator_order.GetDoneCommentatorResponseData.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseData} returns this
 */
proto.affiliator_order.GetDoneCommentatorResponseData.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated GetDoneCommentatorResponseDataCommentator commentators = 2;
 * @return {!Array<!proto.affiliator_order.GetDoneCommentatorResponseDataCommentator>}
 */
proto.affiliator_order.GetDoneCommentatorResponseData.prototype.getCommentatorsList = function() {
  return /** @type{!Array<!proto.affiliator_order.GetDoneCommentatorResponseDataCommentator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_order.GetDoneCommentatorResponseDataCommentator, 2));
};


/**
 * @param {!Array<!proto.affiliator_order.GetDoneCommentatorResponseDataCommentator>} value
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseData} returns this
*/
proto.affiliator_order.GetDoneCommentatorResponseData.prototype.setCommentatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseDataCommentator}
 */
proto.affiliator_order.GetDoneCommentatorResponseData.prototype.addCommentators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.affiliator_order.GetDoneCommentatorResponseDataCommentator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_order.GetDoneCommentatorResponseData} returns this
 */
proto.affiliator_order.GetDoneCommentatorResponseData.prototype.clearCommentatorsList = function() {
  return this.setCommentatorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.GetDoneCommentatorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.GetDoneCommentatorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.GetDoneCommentatorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetDoneCommentatorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_order.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.affiliator_order.GetDoneCommentatorResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.GetDoneCommentatorResponse}
 */
proto.affiliator_order.GetDoneCommentatorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.GetDoneCommentatorResponse;
  return proto.affiliator_order.GetDoneCommentatorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.GetDoneCommentatorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.GetDoneCommentatorResponse}
 */
proto.affiliator_order.GetDoneCommentatorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_order.Meta;
      reader.readMessage(value,proto.affiliator_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_order.GetDoneCommentatorResponseData;
      reader.readMessage(value,proto.affiliator_order.GetDoneCommentatorResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.GetDoneCommentatorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.GetDoneCommentatorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.GetDoneCommentatorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.GetDoneCommentatorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_order.GetDoneCommentatorResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_order.Meta}
 */
proto.affiliator_order.GetDoneCommentatorResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.Meta, 1));
};


/**
 * @param {?proto.affiliator_order.Meta|undefined} value
 * @return {!proto.affiliator_order.GetDoneCommentatorResponse} returns this
*/
proto.affiliator_order.GetDoneCommentatorResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.GetDoneCommentatorResponse} returns this
 */
proto.affiliator_order.GetDoneCommentatorResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.GetDoneCommentatorResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetDoneCommentatorResponseData data = 2;
 * @return {?proto.affiliator_order.GetDoneCommentatorResponseData}
 */
proto.affiliator_order.GetDoneCommentatorResponse.prototype.getData = function() {
  return /** @type{?proto.affiliator_order.GetDoneCommentatorResponseData} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.GetDoneCommentatorResponseData, 2));
};


/**
 * @param {?proto.affiliator_order.GetDoneCommentatorResponseData|undefined} value
 * @return {!proto.affiliator_order.GetDoneCommentatorResponse} returns this
*/
proto.affiliator_order.GetDoneCommentatorResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.GetDoneCommentatorResponse} returns this
 */
proto.affiliator_order.GetDoneCommentatorResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.GetDoneCommentatorResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.SetSuspiciousActivityAsDoneRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    commentatorAccountIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.SetSuspiciousActivityAsDoneRequest}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.SetSuspiciousActivityAsDoneRequest;
  return proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.SetSuspiciousActivityAsDoneRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.SetSuspiciousActivityAsDoneRequest}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCommentatorAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.SetSuspiciousActivityAsDoneRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommentatorAccountIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.SetSuspiciousActivityAsDoneRequest} returns this
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string commentator_account_id = 2;
 * @return {!Array<string>}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.prototype.getCommentatorAccountIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.affiliator_order.SetSuspiciousActivityAsDoneRequest} returns this
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.prototype.setCommentatorAccountIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.affiliator_order.SetSuspiciousActivityAsDoneRequest} returns this
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.prototype.addCommentatorAccountId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_order.SetSuspiciousActivityAsDoneRequest} returns this
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneRequest.prototype.clearCommentatorAccountIdList = function() {
  return this.setCommentatorAccountIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData;
  return proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.SetSuspiciousActivityAsDoneResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_order.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.SetSuspiciousActivityAsDoneResponse}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.SetSuspiciousActivityAsDoneResponse;
  return proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.SetSuspiciousActivityAsDoneResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.SetSuspiciousActivityAsDoneResponse}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_order.Meta;
      reader.readMessage(value,proto.affiliator_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData;
      reader.readMessage(value,proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.SetSuspiciousActivityAsDoneResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_order.Meta}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.Meta, 1));
};


/**
 * @param {?proto.affiliator_order.Meta|undefined} value
 * @return {!proto.affiliator_order.SetSuspiciousActivityAsDoneResponse} returns this
*/
proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.SetSuspiciousActivityAsDoneResponse} returns this
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SetSuspiciousActivityAsDoneResponseData data = 2;
 * @return {?proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.prototype.getData = function() {
  return /** @type{?proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData, 2));
};


/**
 * @param {?proto.affiliator_order.SetSuspiciousActivityAsDoneResponseData|undefined} value
 * @return {!proto.affiliator_order.SetSuspiciousActivityAsDoneResponse} returns this
*/
proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.SetSuspiciousActivityAsDoneResponse} returns this
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.SetAsFinishedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.SetAsFinishedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.SetAsFinishedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SetAsFinishedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reportImagePath: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reportImagePathType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.SetAsFinishedRequest}
 */
proto.affiliator_order.SetAsFinishedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.SetAsFinishedRequest;
  return proto.affiliator_order.SetAsFinishedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.SetAsFinishedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.SetAsFinishedRequest}
 */
proto.affiliator_order.SetAsFinishedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportImagePath(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportImagePathType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.SetAsFinishedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.SetAsFinishedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.SetAsFinishedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SetAsFinishedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportImagePath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReportImagePathType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.affiliator_order.SetAsFinishedRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.SetAsFinishedRequest} returns this
 */
proto.affiliator_order.SetAsFinishedRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string report_image_path = 2;
 * @return {string}
 */
proto.affiliator_order.SetAsFinishedRequest.prototype.getReportImagePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.SetAsFinishedRequest} returns this
 */
proto.affiliator_order.SetAsFinishedRequest.prototype.setReportImagePath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string report_image_path_type = 3;
 * @return {string}
 */
proto.affiliator_order.SetAsFinishedRequest.prototype.getReportImagePathType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_order.SetAsFinishedRequest} returns this
 */
proto.affiliator_order.SetAsFinishedRequest.prototype.setReportImagePathType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.SetAsFinishedResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.SetAsFinishedResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.SetAsFinishedResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SetAsFinishedResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.SetAsFinishedResponseData}
 */
proto.affiliator_order.SetAsFinishedResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.SetAsFinishedResponseData;
  return proto.affiliator_order.SetAsFinishedResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.SetAsFinishedResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.SetAsFinishedResponseData}
 */
proto.affiliator_order.SetAsFinishedResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.SetAsFinishedResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.SetAsFinishedResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.SetAsFinishedResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SetAsFinishedResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_order.SetAsFinishedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_order.SetAsFinishedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_order.SetAsFinishedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SetAsFinishedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_order.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.affiliator_order.SetAsFinishedResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_order.SetAsFinishedResponse}
 */
proto.affiliator_order.SetAsFinishedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_order.SetAsFinishedResponse;
  return proto.affiliator_order.SetAsFinishedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_order.SetAsFinishedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_order.SetAsFinishedResponse}
 */
proto.affiliator_order.SetAsFinishedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_order.Meta;
      reader.readMessage(value,proto.affiliator_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_order.SetAsFinishedResponseData;
      reader.readMessage(value,proto.affiliator_order.SetAsFinishedResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_order.SetAsFinishedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_order.SetAsFinishedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_order.SetAsFinishedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_order.SetAsFinishedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_order.SetAsFinishedResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_order.Meta}
 */
proto.affiliator_order.SetAsFinishedResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.Meta, 1));
};


/**
 * @param {?proto.affiliator_order.Meta|undefined} value
 * @return {!proto.affiliator_order.SetAsFinishedResponse} returns this
*/
proto.affiliator_order.SetAsFinishedResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.SetAsFinishedResponse} returns this
 */
proto.affiliator_order.SetAsFinishedResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.SetAsFinishedResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SetAsFinishedResponseData data = 2;
 * @return {?proto.affiliator_order.SetAsFinishedResponseData}
 */
proto.affiliator_order.SetAsFinishedResponse.prototype.getData = function() {
  return /** @type{?proto.affiliator_order.SetAsFinishedResponseData} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_order.SetAsFinishedResponseData, 2));
};


/**
 * @param {?proto.affiliator_order.SetAsFinishedResponseData|undefined} value
 * @return {!proto.affiliator_order.SetAsFinishedResponse} returns this
*/
proto.affiliator_order.SetAsFinishedResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_order.SetAsFinishedResponse} returns this
 */
proto.affiliator_order.SetAsFinishedResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_order.SetAsFinishedResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.affiliator_order);
