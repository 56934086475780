import type { FC } from 'react';
import { HiChevronLeft } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';

interface BackProps {
    target?: string
    onClick?: () => void
}

const Back: FC<BackProps> = ({ 
    target, 
    onClick }) => {
    const nav = useNavigate()
    return (
        <div className='p-2 border rounded-lg border-gray-200 w-8 h-8' onClick={ () => {
            if (onClick) {
                onClick()
                return
            }
            if (target) {
                nav(target!)
            } else {
                nav(-1)
            }
        }}>
            <HiChevronLeft />
        </div>
    );
}
export default Back;