import { useContext, useState, type FC } from 'react';
import Loading from '../components/loading';
import { LoadingContext } from '../objects/loading_context';
import { useNavigate } from 'react-router-dom';
import { authClient } from '../App';
import { ForgotPasswordRequest } from '../grpc/auth/auth_pb';
import { getToken } from '../utils/helper';
import { errorToast, successToast } from '../utils/helper-ui';

interface ForgotProps { }

const Forgot: FC<ForgotProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const nav = useNavigate()
    const [mounted, setMounted] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className='h-screen w-full'>
            <Loading />
            <div className='flex flex-col p-4  w-full'>
                <img src={"/assets/logo.svg"} alt="" className=' w-24 mx-auto mt-12 ' />
                <h3 className='text-center font-bold text-xl mb-8'><span className=' text-sky-500 mr-1 mb-4'>Grosir</span><span>Komen</span></h3>
                <h3 className='font-bold mb-0 text-sky-500' >Lupa Kata Sandi</h3>
                <p>Silahkan masukan email terdaftar</p>
                <label className='font-bold mb-1 px-1 mt-4' htmlFor="">Email</label>
                <input value={email} placeholder="Alamat Email" className='mb-4 form-control' onChange={(el) => setEmail(el.currentTarget.value)} />
                <button onClick={() => {
                    setIsLoading(true)
                    let req = new ForgotPasswordRequest()
                    req.setEmail(email)
                    authClient.forgotPassword(req, getToken(), (err, resp) => {
                        if (err) {
                            errorToast(err.message)
                            setIsLoading(false)
                            return
                        }
                        setIsLoading(false)
                        successToast("Silahkan cek email anda")
                        setTimeout(() => {
                            nav(`/send-new-password/${resp.getData()?.toObject().userId}`)
                        }, 1000);
                    } )
                }} type="button" className="btn">Kirim</button>

                <div className='mt-8 text-center'>
                    Kembali Ke Halaman <span className=' font-semibold text-sky-500' onClick={() => nav('/login')}>Login</span>
                </div>
            </div>
        </div>
    );
}
export default Forgot;