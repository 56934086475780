import { useContext, useState, type FC } from "react";
import CommentatorMid from "../../middlewares/CommentatorMid";
import Back from "../../components/back";
import { GetProfileResponseData } from "../../grpc/client/client_general_pb";
import { LoadingContext } from "../../objects/loading_context";
import { useNavigate, useParams } from "react-router-dom";
import { CreateCommentatorAccountRequest, GetDetailCommentatorAccountResponseData } from "../../grpc/commentator/commentator_account_pb";
import AccountForm from "../../components/commentator/AccountForm";
import { commentatorAccountClient } from "../../App";
import { getToken } from "../../utils/helper";
import { errorToast, successToast } from "../../utils/helper-ui";
import { Modal } from "rsuite";

interface CommentatorAccountNewProps {}

const CommentatorAccountNew: FC<CommentatorAccountNewProps> = ({}) => {
  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { accountId } = useParams();
  const [account, setAccount] =
    useState<GetDetailCommentatorAccountResponseData.AsObject | null>(null);
  const nav = useNavigate();
  const [open, setOpen] = useState(false);

  const save = (acc: GetDetailCommentatorAccountResponseData.AsObject) => {
    setIsLoading(true);
    let req = new CreateCommentatorAccountRequest();
    req.setName(acc.name ?? "");
    req.setProfilePicturePath(acc.profilePicturePath ?? "");
    req.setProfilePicturePathType(acc.profilePicturePathType ?? "");
    req.setIdInstagram(acc.idInstagram ?? "");
    req.setIdTiktok(acc.idTiktok ?? "");
    req.setIdTwitter(acc.idTwitter ?? "");
    req.setIdFacebook(acc.idFacebook ?? "");
    req.setIdYoutube(acc.idYoutube ?? "");
    req.setIdDetik(acc.idDetik ?? "");
    req.setIdThreads(acc.idThreads ?? "");
    commentatorAccountClient.createCommentatorAccount(
      req,
      getToken(),
      (err, resp) => {
        setIsLoading(false);
        if (err) {
          errorToast(err.message);
          return;
        }
        successToast("Akun berhasil dibuat");
        nav(-1);
      }
    );
  };
  return (
    <CommentatorMid
      noPadding
      hideMenu
      header={
        <div className="flex justify-between items-center p-4">
          <Back onClick={() => nav(-1)} />
          <h5>Akun</h5>
          <img
            src="/assets/carbon_idea.png"
            alt=""
            className="w-6 h-6 cursor-pointer"
            onClick={() => setOpen(true)}
          />
        </div>
      }
      onLoadProfile={setProfile}
    >
      <AccountForm
      isNew
        account={account}
        onUpdate={save}
        onChange={(acc) => {
            console.log("acc", acc)
            setAccount(acc)
        }}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Header>
          <Modal.Title>Tips</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="text-sm text-center my-2">
            Buat semua profil socmed mu sama dengan akun Grosir Komen mu
          </p>
          <img src="/assets/create_account.png" alt="" />
        </Modal.Body>
      </Modal>
    </CommentatorMid>
  );
};
export default CommentatorAccountNew;
