import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/client/Home';
import CommentOrder from '../pages/client/CommentOrder';
import NotFound from '../pages/NotFound';
import PackageOrder from '../pages/client/PackageOrder';
import Notif from '../pages/client/Notif';
import Profile from '../pages/client/Profile';
import PackageOrderDetail from '../pages/client/PackageOrderDetail';
import BuyPackage from '../pages/client/BuyPackage';
import PackageOrderConfirmationDetail from '../pages/client/PackageOrderConfirmationDetail';
import OrderProcessing from '../pages/client/OrderProcessing';
import OrderDetail from '../pages/client/OrderDetail';
import OrderDeclined from '../pages/client/OrderDeclined';
import AccountSecurity from '../pages/client/AccountSecurity';
import OrderDraftDetail from '../pages/client/OrderDraftDetail';

interface ClientRoutesProps { }

const ClientRoutes: FC<ClientRoutesProps> = ({ }) => {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/buy-package' element={<BuyPackage />} />
            <Route path='/package-order' element={<PackageOrder />} />
            <Route path='/package-order/:userPackageId' element={<PackageOrderDetail />} />
            <Route path='/comment-order' element={<CommentOrder />} />
            <Route path='/notif' element={<Notif />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/profile/account-security' element={<AccountSecurity />} />
            <Route path='/user-package-confirmation/:userPackageId' element={<PackageOrderConfirmationDetail />} />
            <Route path='/order/processing/:userPackageId' element={<OrderProcessing />} />
            <Route path='/order/:orderId' element={<OrderDetail />} />
            <Route path='/order/draft/:orderId' element={<OrderDraftDetail />} />
            <Route path='/order/declined/:orderId' element={<OrderDeclined />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}
export default ClientRoutes;