// source: affiliator_withdraw_request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest', null, global);
goog.exportSymbol('proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse', null, global);
goog.exportSymbol('proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData', null, global);
goog.exportSymbol('proto.affiliator_withdraw_request.Meta', null, global);
goog.exportSymbol('proto.affiliator_withdraw_request.Pagination', null, global);
goog.exportSymbol('proto.affiliator_withdraw_request.RequestWithdrawRequest', null, global);
goog.exportSymbol('proto.affiliator_withdraw_request.RequestWithdrawResponse', null, global);
goog.exportSymbol('proto.affiliator_withdraw_request.RequestWithdrawResponseData', null, global);
goog.exportSymbol('proto.affiliator_withdraw_request.WithdrawRequestDetailRequest', null, global);
goog.exportSymbol('proto.affiliator_withdraw_request.WithdrawRequestDetailResponse', null, global);
goog.exportSymbol('proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData', null, global);
goog.exportSymbol('proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog', null, global);
goog.exportSymbol('proto.affiliator_withdraw_request.WithdrawRequestListRequest', null, global);
goog.exportSymbol('proto.affiliator_withdraw_request.WithdrawRequestListResponse', null, global);
goog.exportSymbol('proto.affiliator_withdraw_request.WithdrawRequestListResponseData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_withdraw_request.Meta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_withdraw_request.Meta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_withdraw_request.Meta.displayName = 'proto.affiliator_withdraw_request.Meta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_withdraw_request.Pagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_withdraw_request.Pagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_withdraw_request.Pagination.displayName = 'proto.affiliator_withdraw_request.Pagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_withdraw_request.RequestWithdrawRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_withdraw_request.RequestWithdrawRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_withdraw_request.RequestWithdrawRequest.displayName = 'proto.affiliator_withdraw_request.RequestWithdrawRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_withdraw_request.RequestWithdrawResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_withdraw_request.RequestWithdrawResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_withdraw_request.RequestWithdrawResponseData.displayName = 'proto.affiliator_withdraw_request.RequestWithdrawResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_withdraw_request.RequestWithdrawResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_withdraw_request.RequestWithdrawResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_withdraw_request.RequestWithdrawResponse.displayName = 'proto.affiliator_withdraw_request.RequestWithdrawResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_withdraw_request.WithdrawRequestListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_withdraw_request.WithdrawRequestListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_withdraw_request.WithdrawRequestListRequest.displayName = 'proto.affiliator_withdraw_request.WithdrawRequestListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_withdraw_request.WithdrawRequestListResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_withdraw_request.WithdrawRequestListResponseData.displayName = 'proto.affiliator_withdraw_request.WithdrawRequestListResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_withdraw_request.WithdrawRequestListResponse.repeatedFields_, null);
};
goog.inherits(proto.affiliator_withdraw_request.WithdrawRequestListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_withdraw_request.WithdrawRequestListResponse.displayName = 'proto.affiliator_withdraw_request.WithdrawRequestListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_withdraw_request.WithdrawRequestDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_withdraw_request.WithdrawRequestDetailRequest.displayName = 'proto.affiliator_withdraw_request.WithdrawRequestDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.displayName = 'proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.repeatedFields_, null);
};
goog.inherits(proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.displayName = 'proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_withdraw_request.WithdrawRequestDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.displayName = 'proto.affiliator_withdraw_request.WithdrawRequestDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest.displayName = 'proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData.displayName = 'proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.displayName = 'proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_withdraw_request.Meta.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_withdraw_request.Meta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_withdraw_request.Meta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.Meta.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_withdraw_request.Meta}
 */
proto.affiliator_withdraw_request.Meta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_withdraw_request.Meta;
  return proto.affiliator_withdraw_request.Meta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_withdraw_request.Meta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_withdraw_request.Meta}
 */
proto.affiliator_withdraw_request.Meta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_withdraw_request.Meta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_withdraw_request.Meta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_withdraw_request.Meta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.Meta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.affiliator_withdraw_request.Meta.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.Meta} returns this
 */
proto.affiliator_withdraw_request.Meta.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 status = 2;
 * @return {number}
 */
proto.affiliator_withdraw_request.Meta.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_withdraw_request.Meta} returns this
 */
proto.affiliator_withdraw_request.Meta.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.affiliator_withdraw_request.Meta.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.Meta} returns this
 */
proto.affiliator_withdraw_request.Meta.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_withdraw_request.Pagination.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_withdraw_request.Pagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_withdraw_request.Pagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.Pagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    prev: jspb.Message.getFieldWithDefault(msg, 3, 0),
    next: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_withdraw_request.Pagination}
 */
proto.affiliator_withdraw_request.Pagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_withdraw_request.Pagination;
  return proto.affiliator_withdraw_request.Pagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_withdraw_request.Pagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_withdraw_request.Pagination}
 */
proto.affiliator_withdraw_request.Pagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrev(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNext(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPages(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_withdraw_request.Pagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_withdraw_request.Pagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_withdraw_request.Pagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.Pagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPrev();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNext();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.affiliator_withdraw_request.Pagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_withdraw_request.Pagination} returns this
 */
proto.affiliator_withdraw_request.Pagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.affiliator_withdraw_request.Pagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_withdraw_request.Pagination} returns this
 */
proto.affiliator_withdraw_request.Pagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 prev = 3;
 * @return {number}
 */
proto.affiliator_withdraw_request.Pagination.prototype.getPrev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_withdraw_request.Pagination} returns this
 */
proto.affiliator_withdraw_request.Pagination.prototype.setPrev = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 next = 4;
 * @return {number}
 */
proto.affiliator_withdraw_request.Pagination.prototype.getNext = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_withdraw_request.Pagination} returns this
 */
proto.affiliator_withdraw_request.Pagination.prototype.setNext = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 total_pages = 5;
 * @return {number}
 */
proto.affiliator_withdraw_request.Pagination.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_withdraw_request.Pagination} returns this
 */
proto.affiliator_withdraw_request.Pagination.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 total_records = 6;
 * @return {number}
 */
proto.affiliator_withdraw_request.Pagination.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_withdraw_request.Pagination} returns this
 */
proto.affiliator_withdraw_request.Pagination.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_withdraw_request.RequestWithdrawRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_withdraw_request.RequestWithdrawRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_withdraw_request.RequestWithdrawRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.RequestWithdrawRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    userFinancialAccountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userPackageId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_withdraw_request.RequestWithdrawRequest}
 */
proto.affiliator_withdraw_request.RequestWithdrawRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_withdraw_request.RequestWithdrawRequest;
  return proto.affiliator_withdraw_request.RequestWithdrawRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_withdraw_request.RequestWithdrawRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_withdraw_request.RequestWithdrawRequest}
 */
proto.affiliator_withdraw_request.RequestWithdrawRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserFinancialAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPackageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_withdraw_request.RequestWithdrawRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_withdraw_request.RequestWithdrawRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_withdraw_request.RequestWithdrawRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.RequestWithdrawRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getUserFinancialAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserPackageId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional double amount = 1;
 * @return {number}
 */
proto.affiliator_withdraw_request.RequestWithdrawRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_withdraw_request.RequestWithdrawRequest} returns this
 */
proto.affiliator_withdraw_request.RequestWithdrawRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string user_financial_account_id = 2;
 * @return {string}
 */
proto.affiliator_withdraw_request.RequestWithdrawRequest.prototype.getUserFinancialAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.RequestWithdrawRequest} returns this
 */
proto.affiliator_withdraw_request.RequestWithdrawRequest.prototype.setUserFinancialAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_package_id = 3;
 * @return {string}
 */
proto.affiliator_withdraw_request.RequestWithdrawRequest.prototype.getUserPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.RequestWithdrawRequest} returns this
 */
proto.affiliator_withdraw_request.RequestWithdrawRequest.prototype.setUserPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_withdraw_request.RequestWithdrawResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_withdraw_request.RequestWithdrawResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_withdraw_request.RequestWithdrawResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.RequestWithdrawResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_withdraw_request.RequestWithdrawResponseData}
 */
proto.affiliator_withdraw_request.RequestWithdrawResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_withdraw_request.RequestWithdrawResponseData;
  return proto.affiliator_withdraw_request.RequestWithdrawResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_withdraw_request.RequestWithdrawResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_withdraw_request.RequestWithdrawResponseData}
 */
proto.affiliator_withdraw_request.RequestWithdrawResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_withdraw_request.RequestWithdrawResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_withdraw_request.RequestWithdrawResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_withdraw_request.RequestWithdrawResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.RequestWithdrawResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_withdraw_request.RequestWithdrawResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_withdraw_request.RequestWithdrawResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_withdraw_request.RequestWithdrawResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.RequestWithdrawResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_withdraw_request.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.affiliator_withdraw_request.RequestWithdrawResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_withdraw_request.RequestWithdrawResponse}
 */
proto.affiliator_withdraw_request.RequestWithdrawResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_withdraw_request.RequestWithdrawResponse;
  return proto.affiliator_withdraw_request.RequestWithdrawResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_withdraw_request.RequestWithdrawResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_withdraw_request.RequestWithdrawResponse}
 */
proto.affiliator_withdraw_request.RequestWithdrawResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_withdraw_request.Meta;
      reader.readMessage(value,proto.affiliator_withdraw_request.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_withdraw_request.RequestWithdrawResponseData;
      reader.readMessage(value,proto.affiliator_withdraw_request.RequestWithdrawResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_withdraw_request.RequestWithdrawResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_withdraw_request.RequestWithdrawResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_withdraw_request.RequestWithdrawResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.RequestWithdrawResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_withdraw_request.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_withdraw_request.RequestWithdrawResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_withdraw_request.Meta}
 */
proto.affiliator_withdraw_request.RequestWithdrawResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_withdraw_request.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_withdraw_request.Meta, 1));
};


/**
 * @param {?proto.affiliator_withdraw_request.Meta|undefined} value
 * @return {!proto.affiliator_withdraw_request.RequestWithdrawResponse} returns this
*/
proto.affiliator_withdraw_request.RequestWithdrawResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_withdraw_request.RequestWithdrawResponse} returns this
 */
proto.affiliator_withdraw_request.RequestWithdrawResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_withdraw_request.RequestWithdrawResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RequestWithdrawResponseData data = 2;
 * @return {?proto.affiliator_withdraw_request.RequestWithdrawResponseData}
 */
proto.affiliator_withdraw_request.RequestWithdrawResponse.prototype.getData = function() {
  return /** @type{?proto.affiliator_withdraw_request.RequestWithdrawResponseData} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_withdraw_request.RequestWithdrawResponseData, 2));
};


/**
 * @param {?proto.affiliator_withdraw_request.RequestWithdrawResponseData|undefined} value
 * @return {!proto.affiliator_withdraw_request.RequestWithdrawResponse} returns this
*/
proto.affiliator_withdraw_request.RequestWithdrawResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_withdraw_request.RequestWithdrawResponse} returns this
 */
proto.affiliator_withdraw_request.RequestWithdrawResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_withdraw_request.RequestWithdrawResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_withdraw_request.WithdrawRequestListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_withdraw_request.WithdrawRequestListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.WithdrawRequestListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isMarketing: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListRequest}
 */
proto.affiliator_withdraw_request.WithdrawRequestListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_withdraw_request.WithdrawRequestListRequest;
  return proto.affiliator_withdraw_request.WithdrawRequestListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListRequest}
 */
proto.affiliator_withdraw_request.WithdrawRequestListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMarketing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_withdraw_request.WithdrawRequestListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_withdraw_request.WithdrawRequestListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.WithdrawRequestListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsMarketing();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.affiliator_withdraw_request.WithdrawRequestListRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListRequest} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestListRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.affiliator_withdraw_request.WithdrawRequestListRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListRequest} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestListRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestListRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListRequest} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestListRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_marketing = 4;
 * @return {boolean}
 */
proto.affiliator_withdraw_request.WithdrawRequestListRequest.prototype.getIsMarketing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListRequest} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestListRequest.prototype.setIsMarketing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_withdraw_request.WithdrawRequestListResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestListResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    userFinancialAccountType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userFinancialAccountNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    processedDueDate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    confirmationDueDate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    declinedAt: jspb.Message.getFieldWithDefault(msg, 7, ""),
    finishedAt: jspb.Message.getFieldWithDefault(msg, 8, ""),
    statusRemarks: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponseData}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_withdraw_request.WithdrawRequestListResponseData;
  return proto.affiliator_withdraw_request.WithdrawRequestListResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestListResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponseData}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserFinancialAccountType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserFinancialAccountNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessedDueDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationDueDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeclinedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinishedAt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusRemarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_withdraw_request.WithdrawRequestListResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestListResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getUserFinancialAccountType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserFinancialAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProcessedDueDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getConfirmationDueDate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDeclinedAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFinishedAt();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatusRemarks();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string user_financial_account_type = 3;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.getUserFinancialAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.setUserFinancialAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_financial_account_number = 4;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.getUserFinancialAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.setUserFinancialAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string processed_due_date = 5;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.getProcessedDueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.setProcessedDueDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string confirmation_due_date = 6;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.getConfirmationDueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.setConfirmationDueDate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string declined_at = 7;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.getDeclinedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.setDeclinedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string finished_at = 8;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.getFinishedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.setFinishedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string status_remarks = 9;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.getStatusRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponseData.prototype.setStatusRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_withdraw_request.WithdrawRequestListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_withdraw_request.Meta.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.affiliator_withdraw_request.Pagination.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.affiliator_withdraw_request.WithdrawRequestListResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponse}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_withdraw_request.WithdrawRequestListResponse;
  return proto.affiliator_withdraw_request.WithdrawRequestListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponse}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_withdraw_request.Meta;
      reader.readMessage(value,proto.affiliator_withdraw_request.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_withdraw_request.Pagination;
      reader.readMessage(value,proto.affiliator_withdraw_request.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.affiliator_withdraw_request.WithdrawRequestListResponseData;
      reader.readMessage(value,proto.affiliator_withdraw_request.WithdrawRequestListResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_withdraw_request.WithdrawRequestListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_withdraw_request.Meta.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_withdraw_request.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.affiliator_withdraw_request.WithdrawRequestListResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_withdraw_request.Meta}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_withdraw_request.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_withdraw_request.Meta, 1));
};


/**
 * @param {?proto.affiliator_withdraw_request.Meta|undefined} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponse} returns this
*/
proto.affiliator_withdraw_request.WithdrawRequestListResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponse} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.affiliator_withdraw_request.Pagination}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse.prototype.getPagination = function() {
  return /** @type{?proto.affiliator_withdraw_request.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_withdraw_request.Pagination, 2));
};


/**
 * @param {?proto.affiliator_withdraw_request.Pagination|undefined} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponse} returns this
*/
proto.affiliator_withdraw_request.WithdrawRequestListResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponse} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated WithdrawRequestListResponseData data = 3;
 * @return {!Array<!proto.affiliator_withdraw_request.WithdrawRequestListResponseData>}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.affiliator_withdraw_request.WithdrawRequestListResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_withdraw_request.WithdrawRequestListResponseData, 3));
};


/**
 * @param {!Array<!proto.affiliator_withdraw_request.WithdrawRequestListResponseData>} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponse} returns this
*/
proto.affiliator_withdraw_request.WithdrawRequestListResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestListResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponseData}
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.affiliator_withdraw_request.WithdrawRequestListResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestListResponse} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestListResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_withdraw_request.WithdrawRequestDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailRequest}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_withdraw_request.WithdrawRequestDetailRequest;
  return proto.affiliator_withdraw_request.WithdrawRequestDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailRequest}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_withdraw_request.WithdrawRequestDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailRequest} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    remarks: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog;
  return proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string remarks = 3;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    userFinancialAccountType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userFinancialAccountName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userFinancialAccountNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    receiptUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    logsList: jspb.Message.toObjectList(msg.getLogsList(),
    proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData;
  return proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserFinancialAccountType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserFinancialAccountName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserFinancialAccountNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiptUrl(value);
      break;
    case 9:
      var value = new proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog;
      reader.readMessage(value,proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.deserializeBinaryFromReader);
      msg.addLogs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getUserFinancialAccountType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserFinancialAccountName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserFinancialAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getReceiptUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string created_at = 3;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double amount = 4;
 * @return {number}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string user_financial_account_type = 5;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.getUserFinancialAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.setUserFinancialAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string user_financial_account_name = 6;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.getUserFinancialAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.setUserFinancialAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string user_financial_account_number = 7;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.getUserFinancialAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.setUserFinancialAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string receipt_url = 8;
 * @return {string}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.getReceiptUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.setReceiptUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated WithdrawRequestDetailResponseDataLog logs = 9;
 * @return {!Array<!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog>}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.getLogsList = function() {
  return /** @type{!Array<!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog, 9));
};


/**
 * @param {!Array<!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog>} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData} returns this
*/
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.setLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.addLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.affiliator_withdraw_request.WithdrawRequestDetailResponseDataLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.prototype.clearLogsList = function() {
  return this.setLogsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_withdraw_request.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponse}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_withdraw_request.WithdrawRequestDetailResponse;
  return proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponse}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_withdraw_request.Meta;
      reader.readMessage(value,proto.affiliator_withdraw_request.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData;
      reader.readMessage(value,proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_withdraw_request.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_withdraw_request.Meta}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_withdraw_request.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_withdraw_request.Meta, 1));
};


/**
 * @param {?proto.affiliator_withdraw_request.Meta|undefined} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponse} returns this
*/
proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponse} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional WithdrawRequestDetailResponseData data = 2;
 * @return {?proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.prototype.getData = function() {
  return /** @type{?proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData, 2));
};


/**
 * @param {?proto.affiliator_withdraw_request.WithdrawRequestDetailResponseData|undefined} value
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponse} returns this
*/
proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_withdraw_request.WithdrawRequestDetailResponse} returns this
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_withdraw_request.WithdrawRequestDetailResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest;
  return proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest} returns this
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData;
  return proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_withdraw_request.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse;
  return proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_withdraw_request.Meta;
      reader.readMessage(value,proto.affiliator_withdraw_request.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData;
      reader.readMessage(value,proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_withdraw_request.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_withdraw_request.Meta}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_withdraw_request.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_withdraw_request.Meta, 1));
};


/**
 * @param {?proto.affiliator_withdraw_request.Meta|undefined} value
 * @return {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse} returns this
*/
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse} returns this
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ConfirmWithdrawRequestResponseData data = 2;
 * @return {?proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.prototype.getData = function() {
  return /** @type{?proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData, 2));
};


/**
 * @param {?proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponseData|undefined} value
 * @return {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse} returns this
*/
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse} returns this
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_withdraw_request.ConfirmWithdrawRequestResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.affiliator_withdraw_request);
