import { useContext, useState, type FC } from 'react';
import ClientMid from '../../middlewares/ClientMid';
import Back from '../../components/back';
import { LoadingContext } from '../../objects/loading_context';
import { GetProfileResponseData } from '../../grpc/client/client_general_pb';
import { GetUserPackageStatusResponseData } from '../../grpc/client/client_user_package_pb';
import { OrderContext } from '../../objects/order_context';
import { money, statusLabel } from '../../utils/helper';

interface OrderDeclinedProps { }

const OrderDeclined: FC<OrderDeclinedProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const [userPackage, setUserPackage] = useState<GetUserPackageStatusResponseData.AsObject>();
    const { orderCtx, setOrderCtx } = useContext(OrderContext);


    return (
        <ClientMid onLoadProfile={setProfile} onLoad={setUserPackage} noPadding
            header={<div className='w-full px-4 py-2 justify-between items-center flex pt-6 bg-white'>
                <Back />
                <h6>Detail Pesanan</h6>
                <div className='p-2 '></div>
            </div>} >
            <div className='p-4 mb-2 bg-white text-center'>
                <div className=' text-red-500 flex items-center justify-center mb-2'>
                    <img src="/assets/alert.png" alt="" className='w-7 mr-2' />
                    <h5>Pesanan Ditolak</h5>
                </div>
                <p>Pesanan gagal dikarenakan {orderCtx?.dataDraft?.declinalRemarks}</p>
            </div>
            <div className='bg-white px-4 py-8 mb-2'>
            <div className='flex border-b pb-4'>
                <img onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/assets/logo.svg";
                }} src={orderCtx?.dataDraft?.thumbnailUrl} alt="" className=" object-cover w-10 h-10 rounded-lg bg-gray-50 mr-2" />
                <div className='flex flex-col' onClick={() => {

                }}>
                    <h6>{orderCtx?.dataDraft?.title}</h6>
                    <p className=' text-nowrap text-ellipsis cursor-pointer'>{orderCtx?.dataDraft?.url}</p>
                </div>
            </div>
         
            <h5 className=' font-bold text-lg mb-4'>Detail Pesanan</h5>
            <div className=' justify-between flex mb-2'>
                <span>Komentar Positif</span>
                <span>{money(orderCtx?.dataDraft?.positiveComment)}</span>
            </div>
            <div className=' justify-between flex mb-2'>
                <span>Komentar Negatif</span>
                <span>{money(orderCtx?.dataDraft?.negativeComment)}</span>
            </div>
            <div className=' justify-between flex mb-2'>
                <span>Tag Teman</span>
                <span>{money(orderCtx?.dataDraft?.tagFriend)}</span>
            </div>
           
            <div className=' justify-between flex mb-2'>
                <span>Catatan</span>
            </div>
            <div className=' border border-gray-150 px-12 py-4 rounded-xl text-center'>
                {orderCtx?.dataDraft?.remarks}
            </div>
        </div>
        </ClientMid>
    );
}
export default OrderDeclined;