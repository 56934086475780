/**
 * @fileoverview gRPC-Web generated client stub for affiliator_order
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: affiliator_order.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.affiliator_order = require('./affiliator_order_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.affiliator_order.AffiliatorOrderHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.affiliator_order.AffiliatorOrderHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_order.CreateOrderRequest,
 *   !proto.affiliator_order.CreateOrderResponse>}
 */
const methodDescriptor_AffiliatorOrderHandler_CreateOrder = new grpc.web.MethodDescriptor(
  '/affiliator_order.AffiliatorOrderHandler/CreateOrder',
  grpc.web.MethodType.UNARY,
  proto.affiliator_order.CreateOrderRequest,
  proto.affiliator_order.CreateOrderResponse,
  /**
   * @param {!proto.affiliator_order.CreateOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_order.CreateOrderResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_order.CreateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_order.CreateOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_order.CreateOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_order.AffiliatorOrderHandlerClient.prototype.createOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/CreateOrder',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_CreateOrder,
      callback);
};


/**
 * @param {!proto.affiliator_order.CreateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_order.CreateOrderResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_order.AffiliatorOrderHandlerPromiseClient.prototype.createOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/CreateOrder',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_CreateOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_order.DeclineDraftRequest,
 *   !proto.affiliator_order.DeclineDraftResponse>}
 */
const methodDescriptor_AffiliatorOrderHandler_DeclineDraft = new grpc.web.MethodDescriptor(
  '/affiliator_order.AffiliatorOrderHandler/DeclineDraft',
  grpc.web.MethodType.UNARY,
  proto.affiliator_order.DeclineDraftRequest,
  proto.affiliator_order.DeclineDraftResponse,
  /**
   * @param {!proto.affiliator_order.DeclineDraftRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_order.DeclineDraftResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_order.DeclineDraftRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_order.DeclineDraftResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_order.DeclineDraftResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_order.AffiliatorOrderHandlerClient.prototype.declineDraft =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/DeclineDraft',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_DeclineDraft,
      callback);
};


/**
 * @param {!proto.affiliator_order.DeclineDraftRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_order.DeclineDraftResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_order.AffiliatorOrderHandlerPromiseClient.prototype.declineDraft =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/DeclineDraft',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_DeclineDraft);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_order.GetOrderDraftListRequest,
 *   !proto.affiliator_order.GetOrderDraftListResponse>}
 */
const methodDescriptor_AffiliatorOrderHandler_GetOrderDraftList = new grpc.web.MethodDescriptor(
  '/affiliator_order.AffiliatorOrderHandler/GetOrderDraftList',
  grpc.web.MethodType.UNARY,
  proto.affiliator_order.GetOrderDraftListRequest,
  proto.affiliator_order.GetOrderDraftListResponse,
  /**
   * @param {!proto.affiliator_order.GetOrderDraftListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_order.GetOrderDraftListResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_order.GetOrderDraftListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_order.GetOrderDraftListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_order.GetOrderDraftListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_order.AffiliatorOrderHandlerClient.prototype.getOrderDraftList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/GetOrderDraftList',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_GetOrderDraftList,
      callback);
};


/**
 * @param {!proto.affiliator_order.GetOrderDraftListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_order.GetOrderDraftListResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_order.AffiliatorOrderHandlerPromiseClient.prototype.getOrderDraftList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/GetOrderDraftList',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_GetOrderDraftList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_order.GetOrderListRequest,
 *   !proto.affiliator_order.GetOrderListResponse>}
 */
const methodDescriptor_AffiliatorOrderHandler_GetOrderList = new grpc.web.MethodDescriptor(
  '/affiliator_order.AffiliatorOrderHandler/GetOrderList',
  grpc.web.MethodType.UNARY,
  proto.affiliator_order.GetOrderListRequest,
  proto.affiliator_order.GetOrderListResponse,
  /**
   * @param {!proto.affiliator_order.GetOrderListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_order.GetOrderListResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_order.GetOrderListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_order.GetOrderListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_order.GetOrderListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_order.AffiliatorOrderHandlerClient.prototype.getOrderList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/GetOrderList',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_GetOrderList,
      callback);
};


/**
 * @param {!proto.affiliator_order.GetOrderListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_order.GetOrderListResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_order.AffiliatorOrderHandlerPromiseClient.prototype.getOrderList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/GetOrderList',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_GetOrderList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_order.GetOrderDetailRequest,
 *   !proto.affiliator_order.GetOrderDetailResponse>}
 */
const methodDescriptor_AffiliatorOrderHandler_GetOrderDetail = new grpc.web.MethodDescriptor(
  '/affiliator_order.AffiliatorOrderHandler/GetOrderDetail',
  grpc.web.MethodType.UNARY,
  proto.affiliator_order.GetOrderDetailRequest,
  proto.affiliator_order.GetOrderDetailResponse,
  /**
   * @param {!proto.affiliator_order.GetOrderDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_order.GetOrderDetailResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_order.GetOrderDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_order.GetOrderDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_order.GetOrderDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_order.AffiliatorOrderHandlerClient.prototype.getOrderDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/GetOrderDetail',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_GetOrderDetail,
      callback);
};


/**
 * @param {!proto.affiliator_order.GetOrderDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_order.GetOrderDetailResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_order.AffiliatorOrderHandlerPromiseClient.prototype.getOrderDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/GetOrderDetail',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_GetOrderDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_order.GetOrderDiscussionRequest,
 *   !proto.affiliator_order.GetOrderDiscussionResponse>}
 */
const methodDescriptor_AffiliatorOrderHandler_GetOrderDiscussion = new grpc.web.MethodDescriptor(
  '/affiliator_order.AffiliatorOrderHandler/GetOrderDiscussion',
  grpc.web.MethodType.UNARY,
  proto.affiliator_order.GetOrderDiscussionRequest,
  proto.affiliator_order.GetOrderDiscussionResponse,
  /**
   * @param {!proto.affiliator_order.GetOrderDiscussionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_order.GetOrderDiscussionResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_order.GetOrderDiscussionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_order.GetOrderDiscussionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_order.GetOrderDiscussionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_order.AffiliatorOrderHandlerClient.prototype.getOrderDiscussion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/GetOrderDiscussion',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_GetOrderDiscussion,
      callback);
};


/**
 * @param {!proto.affiliator_order.GetOrderDiscussionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_order.GetOrderDiscussionResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_order.AffiliatorOrderHandlerPromiseClient.prototype.getOrderDiscussion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/GetOrderDiscussion',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_GetOrderDiscussion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_order.SendDiscussionRequest,
 *   !proto.affiliator_order.SendDiscussionResponse>}
 */
const methodDescriptor_AffiliatorOrderHandler_SendDiscussion = new grpc.web.MethodDescriptor(
  '/affiliator_order.AffiliatorOrderHandler/SendDiscussion',
  grpc.web.MethodType.UNARY,
  proto.affiliator_order.SendDiscussionRequest,
  proto.affiliator_order.SendDiscussionResponse,
  /**
   * @param {!proto.affiliator_order.SendDiscussionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_order.SendDiscussionResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_order.SendDiscussionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_order.SendDiscussionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_order.SendDiscussionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_order.AffiliatorOrderHandlerClient.prototype.sendDiscussion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/SendDiscussion',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_SendDiscussion,
      callback);
};


/**
 * @param {!proto.affiliator_order.SendDiscussionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_order.SendDiscussionResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_order.AffiliatorOrderHandlerPromiseClient.prototype.sendDiscussion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/SendDiscussion',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_SendDiscussion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_order.GetOrderSuspiciousActivityRequest,
 *   !proto.affiliator_order.GetOrderSuspiciousActivityResponse>}
 */
const methodDescriptor_AffiliatorOrderHandler_GetOrderSuspiciousActivity = new grpc.web.MethodDescriptor(
  '/affiliator_order.AffiliatorOrderHandler/GetOrderSuspiciousActivity',
  grpc.web.MethodType.UNARY,
  proto.affiliator_order.GetOrderSuspiciousActivityRequest,
  proto.affiliator_order.GetOrderSuspiciousActivityResponse,
  /**
   * @param {!proto.affiliator_order.GetOrderSuspiciousActivityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_order.GetOrderSuspiciousActivityResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_order.GetOrderSuspiciousActivityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_order.GetOrderSuspiciousActivityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_order.AffiliatorOrderHandlerClient.prototype.getOrderSuspiciousActivity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/GetOrderSuspiciousActivity',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_GetOrderSuspiciousActivity,
      callback);
};


/**
 * @param {!proto.affiliator_order.GetOrderSuspiciousActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_order.GetOrderSuspiciousActivityResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_order.AffiliatorOrderHandlerPromiseClient.prototype.getOrderSuspiciousActivity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/GetOrderSuspiciousActivity',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_GetOrderSuspiciousActivity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_order.GetDoneCommentatorRequest,
 *   !proto.affiliator_order.GetDoneCommentatorResponse>}
 */
const methodDescriptor_AffiliatorOrderHandler_GetDoneCommentator = new grpc.web.MethodDescriptor(
  '/affiliator_order.AffiliatorOrderHandler/GetDoneCommentator',
  grpc.web.MethodType.UNARY,
  proto.affiliator_order.GetDoneCommentatorRequest,
  proto.affiliator_order.GetDoneCommentatorResponse,
  /**
   * @param {!proto.affiliator_order.GetDoneCommentatorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_order.GetDoneCommentatorResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_order.GetDoneCommentatorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_order.GetDoneCommentatorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_order.GetDoneCommentatorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_order.AffiliatorOrderHandlerClient.prototype.getDoneCommentator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/GetDoneCommentator',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_GetDoneCommentator,
      callback);
};


/**
 * @param {!proto.affiliator_order.GetDoneCommentatorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_order.GetDoneCommentatorResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_order.AffiliatorOrderHandlerPromiseClient.prototype.getDoneCommentator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/GetDoneCommentator',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_GetDoneCommentator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_order.SetSuspiciousActivityAsDoneRequest,
 *   !proto.affiliator_order.SetSuspiciousActivityAsDoneResponse>}
 */
const methodDescriptor_AffiliatorOrderHandler_SetSuspiciousActivityAsDone = new grpc.web.MethodDescriptor(
  '/affiliator_order.AffiliatorOrderHandler/SetSuspiciousActivityAsDone',
  grpc.web.MethodType.UNARY,
  proto.affiliator_order.SetSuspiciousActivityAsDoneRequest,
  proto.affiliator_order.SetSuspiciousActivityAsDoneResponse,
  /**
   * @param {!proto.affiliator_order.SetSuspiciousActivityAsDoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_order.SetSuspiciousActivityAsDoneResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_order.SetSuspiciousActivityAsDoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_order.SetSuspiciousActivityAsDoneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_order.SetSuspiciousActivityAsDoneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_order.AffiliatorOrderHandlerClient.prototype.setSuspiciousActivityAsDone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/SetSuspiciousActivityAsDone',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_SetSuspiciousActivityAsDone,
      callback);
};


/**
 * @param {!proto.affiliator_order.SetSuspiciousActivityAsDoneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_order.SetSuspiciousActivityAsDoneResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_order.AffiliatorOrderHandlerPromiseClient.prototype.setSuspiciousActivityAsDone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/SetSuspiciousActivityAsDone',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_SetSuspiciousActivityAsDone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_order.SetAsFinishedRequest,
 *   !proto.affiliator_order.SetAsFinishedResponse>}
 */
const methodDescriptor_AffiliatorOrderHandler_SetAsFinished = new grpc.web.MethodDescriptor(
  '/affiliator_order.AffiliatorOrderHandler/SetAsFinished',
  grpc.web.MethodType.UNARY,
  proto.affiliator_order.SetAsFinishedRequest,
  proto.affiliator_order.SetAsFinishedResponse,
  /**
   * @param {!proto.affiliator_order.SetAsFinishedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_order.SetAsFinishedResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_order.SetAsFinishedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_order.SetAsFinishedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_order.SetAsFinishedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_order.AffiliatorOrderHandlerClient.prototype.setAsFinished =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/SetAsFinished',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_SetAsFinished,
      callback);
};


/**
 * @param {!proto.affiliator_order.SetAsFinishedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_order.SetAsFinishedResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_order.AffiliatorOrderHandlerPromiseClient.prototype.setAsFinished =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_order.AffiliatorOrderHandler/SetAsFinished',
      request,
      metadata || {},
      methodDescriptor_AffiliatorOrderHandler_SetAsFinished);
};


module.exports = proto.affiliator_order;

