/**
 * @fileoverview gRPC-Web generated client stub for admin_affiliator
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: admin_affiliator.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.admin_affiliator = require('./admin_affiliator_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.admin_affiliator.AdminAffiliatorHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.admin_affiliator.AdminAffiliatorHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_affiliator.GetAffiliatorListRequest,
 *   !proto.admin_affiliator.GetAffiliatorListResponse>}
 */
const methodDescriptor_AdminAffiliatorHandler_GetAffiliatorList = new grpc.web.MethodDescriptor(
  '/admin_affiliator.AdminAffiliatorHandler/GetAffiliatorList',
  grpc.web.MethodType.UNARY,
  proto.admin_affiliator.GetAffiliatorListRequest,
  proto.admin_affiliator.GetAffiliatorListResponse,
  /**
   * @param {!proto.admin_affiliator.GetAffiliatorListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_affiliator.GetAffiliatorListResponse.deserializeBinary
);


/**
 * @param {!proto.admin_affiliator.GetAffiliatorListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_affiliator.GetAffiliatorListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_affiliator.GetAffiliatorListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_affiliator.AdminAffiliatorHandlerClient.prototype.getAffiliatorList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_affiliator.AdminAffiliatorHandler/GetAffiliatorList',
      request,
      metadata || {},
      methodDescriptor_AdminAffiliatorHandler_GetAffiliatorList,
      callback);
};


/**
 * @param {!proto.admin_affiliator.GetAffiliatorListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_affiliator.GetAffiliatorListResponse>}
 *     Promise that resolves to the response
 */
proto.admin_affiliator.AdminAffiliatorHandlerPromiseClient.prototype.getAffiliatorList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_affiliator.AdminAffiliatorHandler/GetAffiliatorList',
      request,
      metadata || {},
      methodDescriptor_AdminAffiliatorHandler_GetAffiliatorList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_affiliator.BlacklistAffiliatorRequest,
 *   !proto.admin_affiliator.BlacklistAffiliatorResponse>}
 */
const methodDescriptor_AdminAffiliatorHandler_BlacklistAffiliator = new grpc.web.MethodDescriptor(
  '/admin_affiliator.AdminAffiliatorHandler/BlacklistAffiliator',
  grpc.web.MethodType.UNARY,
  proto.admin_affiliator.BlacklistAffiliatorRequest,
  proto.admin_affiliator.BlacklistAffiliatorResponse,
  /**
   * @param {!proto.admin_affiliator.BlacklistAffiliatorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_affiliator.BlacklistAffiliatorResponse.deserializeBinary
);


/**
 * @param {!proto.admin_affiliator.BlacklistAffiliatorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_affiliator.BlacklistAffiliatorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_affiliator.BlacklistAffiliatorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_affiliator.AdminAffiliatorHandlerClient.prototype.blacklistAffiliator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_affiliator.AdminAffiliatorHandler/BlacklistAffiliator',
      request,
      metadata || {},
      methodDescriptor_AdminAffiliatorHandler_BlacklistAffiliator,
      callback);
};


/**
 * @param {!proto.admin_affiliator.BlacklistAffiliatorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_affiliator.BlacklistAffiliatorResponse>}
 *     Promise that resolves to the response
 */
proto.admin_affiliator.AdminAffiliatorHandlerPromiseClient.prototype.blacklistAffiliator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_affiliator.AdminAffiliatorHandler/BlacklistAffiliator',
      request,
      metadata || {},
      methodDescriptor_AdminAffiliatorHandler_BlacklistAffiliator);
};


module.exports = proto.admin_affiliator;

