import { useContext, useEffect, useRef, useState, type FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommentatorMid from "../../middlewares/CommentatorMid";
import { GetProfileResponseData } from "../../grpc/client/client_general_pb";
import {
  GetDetailCommentatorAccountRequest,
  GetDetailCommentatorAccountResponseData,
  UpdateCommentatorAccountRequest,
} from "../../grpc/commentator/commentator_account_pb";
import { clientFile, commentatorAccountClient } from "../../App";
import { errorToast, successToast } from "../../utils/helper-ui";
import { getToken, initials } from "../../utils/helper";
import Back from "../../components/back";
import { Avatar, Modal } from "rsuite";
import { LoadingContext } from "../../objects/loading_context";
import { FileBase64Request } from "../../grpc/service/file_pb";
import AccountForm from "../../components/commentator/AccountForm";
import { HiXMark } from "react-icons/hi2";

interface CommentatorAccountEditProps {}

const CommentatorAccountEdit: FC<CommentatorAccountEditProps> = ({}) => {
  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { accountId } = useParams();
  const [open, setOpen] = useState(false);
  const [account, setAccount] =
    useState<GetDetailCommentatorAccountResponseData.AsObject | null>(null);
  const nav = useNavigate();
  const fileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    getAccountDetail();
  }, [accountId]);

  const getAccountDetail = () => {
    if (!accountId) return;
    let req = new GetDetailCommentatorAccountRequest();
    req.setId(accountId!);
    commentatorAccountClient.getDetailCommentatorAccount(
      req,
      getToken(),
      (err, resp) => {
        if (err) {
          errorToast(err.message);
          return;
        }
        setAccount(resp.getData()?.toObject() ?? null);
        //   setProfile(resp);
      }
    );
  };

  useEffect(() => {}, [account]);

  const update = (acc: GetDetailCommentatorAccountResponseData.AsObject) => {
    setIsLoading(true);
    let req = new UpdateCommentatorAccountRequest();
    req.setId(accountId!);
    req.setName(acc.name ?? "");
    req.setProfilePicturePath(acc.profilePicturePath ?? "");
    req.setProfilePicturePathType(acc.profilePicturePathType ?? "");
    req.setIdInstagram(acc.idInstagram ?? "");
    req.setIdTiktok(acc.idTiktok ?? "");
    req.setIdTwitter(acc.idTwitter ?? "");
    req.setIdFacebook(acc.idFacebook ?? "");
    req.setIdYoutube(acc.idYoutube ?? "");
    req.setIdDetik(acc.idDetik ?? "");
    req.setIdThreads(acc.idThreads ?? "");
    commentatorAccountClient.updateCommentatorAccount(
      req,
      getToken(),
      (err, resp) => {
        setIsLoading(false);
        if (err) {
          errorToast(err.message);
          return;
        }
        successToast("Akun berhasil diperbarui");
        getAccountDetail();

        //   setProfile(resp);
      }
    );
  };

  return (
    <CommentatorMid
      noPadding
      hideMenu
      header={
        <div className="flex justify-between items-center p-4">
          <Back onClick={() => nav(-1)} />
          <h5>Akun</h5>
          <img
            src="/assets/carbon_idea.png"
            alt=""
            className="w-6 h-6 cursor-pointer"
            onClick={() => setOpen(true)}
          />
        </div>
      }
      onLoadProfile={setProfile}
    >
      <AccountForm
        account={account}
        onUpdate={update}
        onChange={(acc) => setAccount(acc)}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Header>
          <Modal.Title>Tips</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="text-sm text-center my-2">
            Buat semua profil socmed mu sama dengan akun Grosir Komen mu
          </p>
          <img src="/assets/create_account.png" alt="" />
        </Modal.Body>
      </Modal>
    </CommentatorMid>
  );
};
export default CommentatorAccountEdit;
