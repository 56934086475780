import { useContext, useState, type FC } from 'react';
import AdminMid from '../../middlewares/AdminMid';
import { LoadingContext } from '../../objects/loading_context';
import { ACTIVE, ORDER_AFFILIATOR, ORDER_COMMENTATOR, ORDER_CONFIRMATION, ORDER_DONE, ORDER_FAILED } from '../../utils/contants';
import { GetProfileResponseData } from '../../grpc/client/client_general_pb';
import AdminOrderList from '../../components/admin/AdminOrderList';

interface AdminOrderProps { }

const AdminOrder: FC<AdminOrderProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);

    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const [clientOrderActive, setClientOrderActive] = useState(ORDER_CONFIRMATION);

    return (
        <AdminMid onLoadProfile={setProfile} noPadding>
            <div className=''>
                <div className="scrollmenu bg-white">
                    <div onClick={() => setClientOrderActive(ORDER_CONFIRMATION)} className={`${clientOrderActive == ORDER_CONFIRMATION && 'active'}`}>Konfirmasi</div>
                    <div onClick={() => setClientOrderActive(ORDER_AFFILIATOR)} className={`${clientOrderActive == ORDER_AFFILIATOR && 'active'}`} >Affiliator</div>
                    <div onClick={() => setClientOrderActive(ORDER_COMMENTATOR)} className={`${clientOrderActive == ORDER_COMMENTATOR && 'active'}`} >Komentator</div>
                    <div onClick={() => setClientOrderActive(ORDER_DONE)} className={`${clientOrderActive == ORDER_DONE && 'active'}`} >Berhasil</div>
                    <div onClick={() => setClientOrderActive(ORDER_FAILED)} className={`${clientOrderActive == ORDER_FAILED && 'active'}`} >Gagal</div>
                </div>
                {clientOrderActive == ORDER_CONFIRMATION && <AdminOrderList mode={ORDER_CONFIRMATION} />}
                {clientOrderActive == ORDER_AFFILIATOR && <AdminOrderList mode={ORDER_AFFILIATOR} />}
                {clientOrderActive == ORDER_COMMENTATOR && <AdminOrderList mode={ORDER_COMMENTATOR} />}
                {clientOrderActive == ORDER_DONE && <AdminOrderList mode={ORDER_DONE} />}
                {clientOrderActive == ORDER_FAILED && <AdminOrderList mode={ORDER_FAILED} />}
            </div>
        </AdminMid>
    );
}
export default AdminOrder;