import { useContext, useEffect, useState, type FC } from 'react';
import { FaStar } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, AvatarGroup, Checkbox, Drawer } from 'rsuite';
import Back from '../../components/back';
import { AcceptUserPackageRequest, GetDetailUserPackageRequest, GetDetailUserPackageResponseData, GetDetailUserPackageResponseDataCommentatorOffer, Pagination } from '../../grpc/commentator/commentator_user_package_pb';
import { GetProfileResponseData } from '../../grpc/service/general_pb';
import CommentatorMid from '../../middlewares/CommentatorMid';
import { LoadingContext } from '../../objects/loading_context';
import { PAID, PENDING_AFFILIATOR, PENDING_COMMENTATOR } from '../../utils/contants';
import { getToken, initials, money } from '../../utils/helper';
import { errorToast, successToast } from '../../utils/helper-ui';
import { commentatorAccountClient, commentatorPackageClient } from '../../App';
import { GetListCommentatorAccountRequest, GetListCommentatorAccountResponseData } from '../../grpc/commentator/commentator_account_pb';

interface CommentatorPackageDetailProps { }

const CommentatorPackageDetail: FC<CommentatorPackageDetailProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [mounted, setMounted] = useState(false);

    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const { packageId } = useParams()
    const [data, setData] = useState<GetDetailUserPackageResponseData.AsObject | null | undefined>(null);
    const nav = useNavigate()

    const [modalOfferingOpen, setModalOfferingOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [pagination, setPagination] = useState<Pagination.AsObject | null>(null);
    const [accounts, setAccounts] = useState<GetListCommentatorAccountResponseData.AsObject[]>([]);

    const [selectedAccounts, setSelectedAccounts] = useState<GetListCommentatorAccountResponseData.AsObject[]>([]);
    const [totalSlot, setTotalSlot] = useState(0);
    const [totalAcceptedAccount, setTotalAcceptedAccount] = useState(0);
    const [selectedCommentator, setSelectedCommentator] = useState<GetDetailUserPackageResponseDataCommentatorOffer.AsObject | null>(null);


    useEffect(() => {
        if (mounted) {
            getAccounts()
        }
    }, [mounted, page, limit]);

    // useEffect(() => {
    //     setTotalSlot(selectedAccounts.map(e => e.totalAccounts).reduce((a, b) => a + b, 0))
    // }, [selectedAccounts]);

    useEffect(() => {
        if (data) {

            let selectedCommentator = (data?.commentatorOffersList ?? []).find(e => e.id == profile?.id)
            if (selectedCommentator) {

                setSelectedCommentator(selectedCommentator)
                setTotalAcceptedAccount(selectedCommentator.totalAcceptedAccount != 0 ? selectedCommentator.totalAcceptedAccount : selectedCommentator.totalAccount)
            }
        }
    }, [data]);
    const getAccounts = () => {
        setIsLoading(true)
        let req = new GetListCommentatorAccountRequest()
        commentatorAccountClient.getListCommentatorAccount(req, getToken(), (err, resp) => {
            setIsLoading(false)
            if (err) {
                errorToast(err.message)
                return
            }
            setAccounts(resp.getDataList().map(e => e.toObject()))
        })
    }
    const renderDetail = () => (
        <>
            <div className='p-4 border-b border-b-gray-100'>
                <h5>Detail Pesanan</h5>
            </div>
            <div className='p-4 border-b border-b-gray-100'>
                <div className='flex justify-between py-2'>
                    <span className=''>Komentar per hari</span>
                    <span>{money(data?.commentPerDay)} / Hari</span>
                </div>
                <div className='flex justify-between py-2'>
                    <span className=''>Jumlah Hari</span>
                    <span>{money(data?.durationDay)} Hari</span>
                </div>
                <div className='flex justify-between py-2'>
                    <span className=''>Slot yang dibutuhkan</span>
                    <span>{money(data?.commentatorAccountSlot)}</span>
                </div>
                <div className='flex justify-between py-2'>
                    <span className=''>Sisa Slot</span>
                    <span>{money(data?.commentatorAccountRemainingSlot)}</span>
                </div>
                <div className='flex justify-between py-2'>
                    <span className=''>Komisi</span>
                    <span className=' text-orange-400 font-bold'>@Rp. {money(data?.commentatorFee)}</span>
                </div>

            </div></>)


    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return
        getDetail()
    }, [mounted]);


    const getDetail = async () => {
        try {
            setIsLoading(false)

            let resp: GetDetailUserPackageResponseData.AsObject = await new Promise((resolve, reject) => {
                let req = new GetDetailUserPackageRequest()
                req.setId(packageId!)
                commentatorPackageClient.getDetailUserPackage(req, getToken(), (err, resp) => {
                    if (err) {
                        reject(err.message)
                        return
                    }
                    resolve(resp.getData()?.toObject()!)
                })
            })
            setData(resp)
        } catch (error) {
            errorToast(`${error}`)
        } finally {
            setIsLoading(false)
        }
    }


    return (<CommentatorMid header={<div className='w-full px-4 py-2 justify-between items-center flex pt-6 bg-white'>
        <Back />
        <h6>Detail Pesanan</h6>
        <div className='p-2 '></div>
    </div>} onLoadProfile={setProfile} noPadding>

        <div className='w-full  bg-slate-200 pt-9 pb-12 px-6 flex justify-between text-white items-center' style={{ backgroundImage: 'url(/assets/bg.png)', backgroundRepeat: 'no-repeat', backgroundPositionX: 0, backgroundPositionY: -100, backgroundSize: 'cover' }}>
            <div>
                <h5 className='mb-2'>
                    {data?.status == PAID ? 'Berhasil!' : 'Menunggu Komentator'}

                </h5>
                <p className=' text-xs'>
                    {data?.status == PAID ? 'Pesanan Berhasil diambil' : `Menunggu Komentator ${selectedCommentator?.isAccepted && 'lain'}  Mengambil Pesanan Klien`}

                </p>

            </div>
            <div className='ml-4'>
                <img src={`/assets/payment_paid.png`} alt="" className='w-10' />
            </div>
        </div>
        <div className=' rounded-t-xl -mt-6 bg-white mb-1'>

            {renderDetail()}

        </div>




        <div className=' bg-white mb-1'>
            <div className='p-4 border-b flex justify-between'>
                <span className=' font-bold'>Affiliator</span>

            </div>
            <div className='p-4 flex items-center'>
                <Avatar size={'sm'} bordered circle src={data?.affiliator?.profilePictureUrl} alt={data?.affiliator?.profilePictureUrl ? data?.affiliator?.name : initials(data?.affiliator?.name)} />
                <span className='ml-2'>{data?.affiliator?.name}</span>

            </div>
        </div>

        <div className=' bg-white mb-1'>
            <div className='p-4 border-b flex justify-between'>
                <span className=' font-bold'>Komentator</span>

            </div>
            <div className='p-4'>
                <ul>
                    <AvatarGroup stack>
                        {(data?.commentatorsList ?? [])
                            .filter((user, i) => i < 5)
                            .map(e => (
                                <Avatar key={e.id} size={'sm'} bordered circle src={e.profilePictureUrl} alt={e.profilePictureUrl ? e.name : initials(e.name)} />
                            ))}
                        {(data?.commentatorsList ?? []).length > 5 &&
                            <Avatar size={'sm'} bordered circle style={{ background: '#111' }}>
                                +{(data?.commentatorsList ?? []).length - 5}
                            </Avatar>
                        }
                    </AvatarGroup>
                </ul>
            </div>
        </div>
        {!selectedCommentator?.isAccepted &&
            <div className='p-4'>

                <button className="btn w-full" onClick={() => {
                    setModalOfferingOpen(true)
                }}>Terima Tawaran</button>
            </div>
        }


        <Drawer className='drawer-rounded' size={"full"} closeButton={null} placement={"bottom"} open={modalOfferingOpen} onClose={() => setModalOfferingOpen(false)}>
            <Drawer.Body style={{ padding: 0 }}>
                <div className='flex flex-col h-full'>
                    <div className='w-full px-4 py-2 justify-between items-center flex pt-6 bg-white border-b'>
                        <Back onClick={() => setModalOfferingOpen(false)} />
                        <h6>Pilih Akun</h6>
                        <div className='p-2 '></div>
                    </div>
                    <div className='flex flex-1 flex-col'>
                        {(data?.commentatorAccountRemainingSlot ?? 0) - selectedAccounts.length !== 0 &&
                            <div className=' bg-orange-200 p-4 flex justify-between'>

                                <span>Sisa Slot</span>
                                <span className=' font-semibold'>{money(data?.commentatorAccountRemainingSlot)}</span>
                            </div>
                        }
                        <div className='flex-1  overflow-y-auto p-4'>
                            <div className='bg-white px-4  mb-2'>
                                <div className='grid grid-cols-12 gap-4'>
                                    <div className='py-4 font-semibold col-span-10'>Nama Akun</div>

                                    <div className='py-2 font-semibold text-sky-400 col-span-2 flex justify-end'><Checkbox className='p-0' checked={selectedAccounts.length == accounts.length} onClick={() => {
                                        if (selectedAccounts.length == accounts.length) {
                                            setSelectedAccounts([])
                                        } else {
                                            setSelectedAccounts([...accounts])
                                        }
                                    }} /></div>
                                </div>
                                {accounts.map(e => <div key={e.id}>
                                    <div className='grid grid-cols-12 gap-4'>
                                        <div className='py-4  col-span-10 flex  items-center'>
                                            <Avatar size='sm' circle src={e.profilePictureUrl} alt={initials(e.name)} />
                                            <span className='ml-4'>
                                                {e.name}

                                            </span>
                                        </div>
                                        <div className='py-2  text-sky-400 col-span-2 flex justify-end'><Checkbox disabled={data?.commentatorOffersList.map(e => e.id).includes(e.id)} className='p-0' checked={selectedAccounts.map(e => e.id).includes(e.id)} onClick={() => {
                                            if (selectedAccounts.map(e => e.id).includes(e.id)) {
                                                setSelectedAccounts([...selectedAccounts.filter(s => s.id != e.id)])
                                            } else {
                                                setSelectedAccounts([...selectedAccounts, e])
                                            }
                                        }} /></div>
                                    </div>
                                </div>)}

                                <div className=' justify-center items-start flex'>
                                    {(pagination?.totalPages ?? 0) > 0 && (pagination?.next ?? 0) > page && <button className='btn-white w-48' onClick={() => {
                                        setPage(page + 1)
                                    }}>More</button>}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-4'>
                    {((data?.commentatorAccountRemainingSlot ?? 0) - selectedAccounts.length < 0) && <div className='text-red-500 text-center bg-yellow-50 p-4 rounded-lg'> Slot yang anda pilih melebihi sisa slot, silakan kurangi {Math.abs((data?.commentatorAccountRemainingSlot ?? 0) - selectedAccounts.length)} slot terlebih dahulu</div>}
                        {((data?.commentatorAccountRemainingSlot ?? 0) - selectedAccounts.length >= 0) && selectedAccounts.length > 0 &&
                            <button onClick={() => {
                                setIsLoading(true)
                                let req = new AcceptUserPackageRequest()
                                req.setUserPackageId(data!.id)
                                req.setCommentatorAccountIdsList(selectedAccounts.map(e => e.id))
                                commentatorPackageClient.acceptUserPackage(req, getToken(), (err, resp) => {
                                    setIsLoading(false)
                                    if (err) {
                                        errorToast(err.message)
                                        return
                                    }
                                    successToast(`Penawaran telah diterima`)
                                    nav(`/commentator/package?tab=commentator`)
                                })
                            }} type="button" className="btn w-full">Kirim</button>
                        }
                    </div>
                </div>
            </Drawer.Body>
        </Drawer>

    </CommentatorMid>);
}
export default CommentatorPackageDetail;