import { useContext, useEffect, useState, type FC } from 'react';
import Back from '../../components/back';
import { clientUserPackage } from '../../App';
import { CreateUserPackageRequest, GetListPackageRequest, GetListPackageResponseData } from '../../grpc/client/client_user_package_pb';
import { getToken, money, printLog } from '../../utils/helper';
import { HiCheck, HiCheckCircle, HiOutlineCheckCircle, HiOutlineXMark } from 'react-icons/hi2';
import { Button, Divider, Drawer, Placeholder, Slider } from 'rsuite';
import { errorToast, successToast } from '../../utils/helper-ui';
import { LoadingContext } from '../../objects/loading_context';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/loading';

interface BuyPackageProps { }

const BuyPackage: FC<BuyPackageProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);

    const [packages, setPackages] = useState<GetListPackageResponseData.AsObject[]>([]);
    const [selected, setSelected] = useState<GetListPackageResponseData.AsObject | null>(null);
    const [mounted, setMounted] = useState(false);
    const [open, setOpen] = useState(false);
    const [commentPerDay, setCommentPerDay] = useState(0);
    const nav = useNavigate()
    useEffect(() => {
        setMounted(true)

    }, []);

    useEffect(() => {
        if (!mounted) return
        clientUserPackage.getListPackage(new GetListPackageRequest(), getToken(), (err, resp) => {
            if (err) {
                errorToast(err.message)
                setIsLoading(false)
                return
            }
            setPackages(resp.getDataList().map(e => e.toObject()))
            setSelected(resp.getDataList()[0].toObject())
        })
    }, [mounted]);

    useEffect(() => {

        // console.log(packages)
    }, [packages]);
    useEffect(() => {

        // console.log(selected)
    }, [selected]);

    return (
        <div className='p-4 flex flex-col w-full h-full'>
            <Back />
            <Loading />
            {selected &&
                <div className=' flex-1'>
                    <div className={` grid gap-4 grid-cols-2 mt-8 `}>
                        {packages.map(e => (<div onClick={() => {
                            setSelected(e)
                            setCommentPerDay(e.minimumCommentPerDay)
                            if (e.pricePerComment > 0) {
                                setOpen(true)
                            }
                        }} key={e.id} className={`border-2 rounded-lg p-4  flex flex-col ${e.id == selected.id && 'border-2 border-blue-500 shadow-lg '} cursor-pointer`}>
                            <div className=' bg-sky-50 flex justify-center items-center rounded-lg p-2 w-fit'>
                                <img src={e.logoUrl} alt="" className='w-8' />
                            </div>
                            <h4 className='font-semibold'>{e.name}</h4>
                            <div className='mt-4 flex  items-end'>
                                <div className=' flex justify-start'>
                                    <span>Rp.</span>
                                    <span className=' text-3xl font-bold'>{e.pricePerComment}</span>
                                </div>
                                <span>/Komentar</span>
                            </div>
                            <ul className='mt-4'>
                                <li className='flex items-center'><HiCheck className='mr-1' />{e.minimumCommentPerDay} Komentar / Hari</li>
                                <li className='flex items-center'><HiCheck className='mr-1' />{e.durationDay} Hari</li>
                                {e.pricePerComment ?
                                    <li className='flex items-center'><HiCheck className='mr-1' />Bukan Bot</li> : null}
                            </ul>
                        </div>))}
                    </div>
                    <div className='mt-8'>
                        <table className='table w-full text-sm'>
                            <thead className=''>
                                <tr className=''>
                                    <th className='pb-2 text-left'>Fitur</th>
                                    <th className='pb-4 '>Gratis</th>
                                    <th className='pb-4 '>Premium</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Jumlah Komentar</td>
                                    <td className='text-center py-1'>10 Komentar</td>
                                    <td className='text-center py-1'>Tidak Terbatas</td>
                                </tr>
                                <tr>
                                    <td>Jangka Waktu</td>
                                    <td className='text-center py-1'>7 Hari</td>
                                    <td className='text-center py-1'>30 Hari</td>
                                </tr>
                                <tr>
                                    <td>Bukan Bot</td>
                                    <td className='text-center py-1'><HiOutlineCheckCircle size={16} className=' text-green-400 m-auto ' /></td>
                                    <td className='text-center py-1'><HiOutlineCheckCircle size={16} className=' text-green-400 m-auto ' /></td>
                                </tr>
                                <tr>
                                    <td>Customer Service</td>
                                    <td className='text-center py-1'><HiOutlineCheckCircle size={16} className=' text-green-400 m-auto ' /></td>
                                    <td className='text-center py-1'><HiOutlineCheckCircle size={16} className=' text-green-400 m-auto ' /></td>
                                </tr>
                                <tr>
                                    <td>Komen Positif</td>
                                    <td className='text-center py-1'><HiOutlineCheckCircle size={16} className=' text-green-400 m-auto ' /></td>
                                    <td className='text-center py-1'><HiOutlineCheckCircle size={16} className=' text-green-400 m-auto ' /></td>
                                </tr>
                                <tr>
                                    <td>Komen Negatif</td>
                                    <td className='text-center py-1'><HiOutlineXMark size={16} className=' text-red-400 m-auto ' /></td>
                                    <td className='text-center py-1'><HiOutlineCheckCircle size={16} className=' text-green-400 m-auto ' /></td>
                                </tr>
                                <tr>
                                    <td>Komen Tag Teman</td>
                                    <td className='text-center py-1'><HiOutlineXMark size={16} className=' text-red-400 m-auto ' /></td>
                                    <td className='text-center py-1'><HiOutlineCheckCircle size={16} className=' text-green-400 m-auto ' /></td>
                                </tr>
                                <tr>
                                    <td>1 Hari Selesai</td>
                                    <td className='text-center py-1'><HiOutlineXMark size={16} className=' text-red-400 m-auto ' /></td>
                                    <td className='text-center py-1'><HiOutlineCheckCircle size={16} className=' text-green-400 m-auto ' /></td>
                                </tr>
                                <tr>
                                    <td>Multi Komen</td>
                                    <td className='text-center py-1'><HiOutlineXMark size={16} className=' text-red-400 m-auto ' /></td>
                                    <td className='text-center py-1'><HiOutlineCheckCircle size={16} className=' text-green-400 m-auto ' /></td>
                                </tr>
                                <tr>
                                    <td>Bonus Likes</td>
                                    <td className='text-center py-1'><HiOutlineXMark size={16} className=' text-red-400 m-auto ' /></td>
                                    <td className='text-center py-1'><HiOutlineCheckCircle size={16} className=' text-green-400 m-auto ' /></td>
                                </tr>
                                <tr>
                                    <td>Bonus Shares</td>
                                    <td className='text-center py-1'><HiOutlineXMark size={16} className=' text-red-400 m-auto ' /></td>
                                    <td className='text-center py-1'><HiOutlineCheckCircle size={16} className=' text-green-400 m-auto ' /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            }

            <button onClick={() => { 
                setIsLoading(true)
                let req = new CreateUserPackageRequest()
                req.setPackageId(selected!.id)
                req.setCommentPerDay(10)
                // console.log(selected, commentPerDay)
                clientUserPackage.createUserPackage(req, getToken(), (err, resp) => {
                    if (err) {
                        errorToast(err.message)
                        setIsLoading(false)
                        return
                    }
                    successToast(`Selamat mencoba aplikasi Grosir Komen`)
                    setIsLoading(false)
                    setTimeout(() => {
                        nav(`/`)
                    }, 3000);
                })
            }} type="button" className="btn">Coba 7 Hari Gratis</button>

            <Drawer className='package drawer-rounded' placement={"bottom"} open={open && (selected?.pricePerComment ?? 0) > 0} onClose={() => {
                setOpen(false)
                if (packages.length)
                    setSelected(packages[0])
            }}>
                <Drawer.Header>
                    <Drawer.Title>
                        <div className='h-6'></div>
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body className=' flex flex-col' style={{ padding: 20 }}>
                    <div className='flex-1'>
                        <div className=' text-base text-center'>
                            Kuota Komentar Perhari
                            <div className='w-32 m-auto flex items-end'>
                                <div className='w-10'></div>
                                <input min={selected?.minimumCommentPerDay ?? 0} max={selected?.maximumCommentPerDay ?? 2000} type="number" className=' form-control text-center ' value={commentPerDay} onChange={(val) => {

                                    setCommentPerDay(parseInt(val.target.value) <= (selected?.maximumCommentPerDay ?? 0) ? parseInt(val.target.value) : (selected?.maximumCommentPerDay ?? 2000))
                                }} />
                                <span className='font-semibold text-sm'>/Hari</span>
                            </div>
                        </div>

                        <div className=' px-4 mb-4'>
                            <Slider className='mt-4' value={commentPerDay} onChange={(val) => setCommentPerDay(val)} min={selected?.minimumCommentPerDay ?? 0} max={selected?.maximumCommentPerDay ?? 2000} />
                        </div>
                        <h5 className='font-semibold mt-8'>Rencanamu</h5>
                        <Divider className='' style={{ margin: '10px 0' }} />
                        <div className='py-1 flex justify-between'>
                            <span>Komentar Per Hari</span>
                            <span>{commentPerDay}</span>
                        </div>
                        <div className='py-1 flex justify-between'>
                            <span>Jumlah Hari</span>
                            <span>{selected?.durationDay}</span>
                        </div>
                        <Divider className='' style={{ margin: '10px 0' }} />
                        <div className='py-1 flex justify-between'>
                            <span className=' font-semibold'>Total Komentar</span>
                            <span>{money((selected?.durationDay ?? 30) * commentPerDay)}</span>
                        </div>
                        <div className='py-1 flex justify-between'>
                            <span className=' text-blue-400'>+ Bonus Like</span>
                            <span className=' text-blue-400'>{money(commentPerDay * (selected?.durationDay ?? 30) * ((selected?.likesToCommentPercentage ?? 0) / 100))}</span>
                        </div>
                        <div className='py-1 flex justify-between'>
                            <span className=' text-blue-400'>+ Bonus Share</span>
                            <span className=' text-blue-400'>{money(commentPerDay * (selected?.durationDay ?? 30) * ((selected?.likesToCommentPercentage ?? 0) / 100))}</span>
                        </div>
                        <div className='py-1 flex justify-between'>
                            <span className=' font-semibold'>Harga per Komentar</span>
                            <span>{money((selected?.pricePerComment ?? 30))}</span>
                        </div>
                        <Divider className='' style={{ margin: '10px 0' }} />
                        <div className='py-1 flex justify-between'>
                            <span className=' font-semibold text-xl'>Total</span>
                            <span className=' font-semibold text-xl'>{money((selected?.pricePerComment ?? 500) * (selected?.durationDay ?? 30) * commentPerDay)}</span>
                        </div>

                        
                    
                    </div>
                    <button onClick={() => { 
                        setIsLoading(true)
                        let req = new CreateUserPackageRequest()
                        req.setPackageId(selected!.id)
                        req.setCommentPerDay(commentPerDay)
                        clientUserPackage.createUserPackage(req, getToken(), (err, resp) => {
                            if (err) {
                                errorToast(err.message)
                                setIsLoading(false)
                                return
                            }
                            successToast(`Pembelian Paket berhasil, silakan lakukan pembayaran sebesar Rp. ${money( resp.getData()?.toObject().totalPrice)}, sebelum ${moment.utc( resp.getData()?.toObject().paymentExpiryTime).format("DD/MM/YYYY HH:mm")}`)
                            setIsLoading(false)
                            setTimeout(() => {
                                nav(`/user-package-confirmation/${resp.getData()?.toObject().userPackageId}`)
                            }, 3000);
                        })
                    }} type="button" className="btn">Lanjutkan</button>
                </Drawer.Body>

            </Drawer>
        </div>
    );
}
export default BuyPackage;