import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// import * as  firebase from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

export const initializeFirebase = () => {
    firebase.initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    });
};

export const initializePushNotification = async (registration) => {
    try {
        // Send permission request
        const messaging = getMessaging();
        // console.log("messaging", messaging)

        // Get the registration token and save it to localStorage
        const token = await getToken(messaging, {
            serviceWorkerRegistration: registration
        });
        localStorage.setItem("notification-token", token);

        onMessage(payload => {
            // Push notification listener when apps is in focus mode
            console.log("Notification received", payload);
        });

        return token;
    } catch (error) {
        console.log(error);
    }
};