import { useContext, useEffect, useState, type FC } from "react";
import ProfileMid from "../middlewares/ProfileMid";
import Back from "../components/back";
import { getToken, money, sepNumber } from "../utils/helper";
import { LoadingContext } from "../objects/loading_context";
import { GetProfileResponseData } from "../grpc/service/general_pb";
import {
  GetCommissionRequest,
  GetCommissionResponseData,
} from "../grpc/affiliator/affiliator_general_pb";
import {
  affiliatorGeneralClient,
  affiliatorWithdrawClient,
  commentatorGeneralClient,
  commentatorWithdrawClient,
} from "../App";
import { errorToast, successToast } from "../utils/helper-ui";
import CurrencyInput from "react-currency-input-field";
import { FaInfoCircle } from "react-icons/fa";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { RequestWithdrawRequest } from "../grpc/commentator/commentator_withdraw_request_pb";
import { useNavigate } from "react-router-dom";

interface WithdrawalRequestProps {
  isAffiliator?: boolean;
  isCommentator?: boolean;
  isMarketing?: boolean;
}

const WithdrawalRequest: FC<WithdrawalRequestProps> = ({
  isAffiliator,
  isCommentator,
  isMarketing,
}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const nav = useNavigate();
  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const [data, setData] = useState<
    GetCommissionResponseData.AsObject | null | undefined
  >(null);
  const [amount, setAmount] = useState(0);
  const [mountedAmount, setMountedAmount] = useState(true);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;

    getComission();
  }, [mounted]);
  const getComission = () => {
    setIsLoading(true);

    let req = new GetCommissionRequest();
    if (isAffiliator) {
      affiliatorGeneralClient.getCommission(req, getToken(), (err, resp) => {
        setIsLoading(false);
        if (err) {
          errorToast(err.message);
          return;
        }
        setData(resp.getData()?.toObject());
      });
    }
    if (isCommentator) {
      commentatorGeneralClient.getCommission(req, getToken(), (err, resp) => {
        setIsLoading(false);
        if (err) {
          errorToast(err.message);
          return;
        }
        setData(resp.getData()?.toObject());
      });
    }
  };

  const sendRequest = async () => {
    if (amount == 0) {
      errorToast("Jumlah penarikan tidak boleh 0");
      return;
    }
    if (amount > (data?.activeCommission ?? 0)) {
      errorToast("Jumlah penarikan tidak boleh lebih dari fee aktif");
      return;
    }
    let req = new RequestWithdrawRequest();
    req.setAmount(amount);
    req.setUserFinancialAccountId(data?.financialAccountsList![0].id!);
    setIsLoading(true);
    if (isCommentator) {
      commentatorWithdrawClient.requestWithdraw(
        req,
        getToken(),
        (err, resp) => {
          if (err) {
            errorToast(err.message);
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          successToast(
            "Permintaan penarikan ditambahkan, silahkan tunggu review admin"
          );
          nav(-1);
        }
      );
    }
    if (isAffiliator) {
      affiliatorWithdrawClient.requestWithdraw(req, getToken(), (err, resp) => {
        if (err) {
          errorToast(err.message);
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
        successToast(
          "Permintaan penarikan ditambahkan, silahkan tunggu review admin"
        );
        nav(-1);
      });
    }
  };

  return (
    <ProfileMid onLoad={(v) => setProfile(v)}>
      <div className="flex flex-col h-full">
        <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white">
          <Back />
          <h6>Penarikan</h6>
          <div className="p-2"></div>
        </div>
        <div className=" bg-orange-200 p-4 flex justify-between">
          <span>Fee Aktif</span>
          <span className=" font-[600]">{money(data?.activeCommission)}</span>
        </div>
        <div className=" flex-1 overflow-y-auto mt-2">
          <div className="bg-white p-4 mb-2">
            <p className="font-[600] text-lg">Jumlah Penarikan</p>

            {mountedAmount && (
              <div className=" relative">
                <CurrencyInput
                  className="w-full  border-0 rounded-lg p-2 mt-2 bg-[#e5e5e5] font-[600] text-2xl"
                  groupSeparator="."
                  decimalSeparator=","
                  value={amount}
                  prefix="Rp. "
                  onValueChange={(value, _, values) => {
                    setAmount(values?.float ?? 0);
                  }}
                />
                <p
                  className=" right-3 top-6 text-blue-400 absolute cursor-pointer"
                  onClick={() => {
                    setAmount(data?.activeCommission ?? 0);
                    setMountedAmount(false);
                    setTimeout(() => {
                      setMountedAmount(true);
                    }, 100);
                  }}
                >
                  Semua
                </p>
              </div>
            )}
            <div className="flex gap-2 flex-row items-center mt-2">
              <HiOutlineInformationCircle />
              <span className="text-xs">
                Fee di transfer ke DANA, estimasi 2x24 jam
              </span>
            </div>
          </div>
          {(profile?.financialAccountsList.length ?? 0) > 0 && (
            <div className="bg-white p-4 mb-2">
              <p className="font-[600] text-lg">Informasi Penerima</p>
              <div className="flex flex-row justify-between items-center">
                <p className="mb-2 font-[500]">Nama Penerima</p>
                <p className="mb-2 ">
                  {profile?.financialAccountsList[0].name}
                </p>
              </div>
              <div className="flex flex-row justify-between items-center">
                <p className="mb-2 font-[500]">No. Data</p>
                <p className="mb-2 ">
                  {sepNumber(profile?.financialAccountsList[0].accountNumber)}
                </p>
              </div>
            </div>
          )}
          <div className="flex justify-center mt-4 p-4">
            <button onClick={sendRequest} type="button" className="btn w-full">
              Konfirmasi Penarikan
            </button>
          </div>
        </div>
      </div>
    </ProfileMid>
  );
};
export default WithdrawalRequest;
