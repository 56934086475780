/**
 * @fileoverview gRPC-Web generated client stub for client_user_package
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: client_user_package.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.client_user_package = require('./client_user_package_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.client_user_package.ClientUserPackageHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.client_user_package.ClientUserPackageHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_user_package.GetListPackageRequest,
 *   !proto.client_user_package.GetListPackageResponse>}
 */
const methodDescriptor_ClientUserPackageHandler_GetListPackage = new grpc.web.MethodDescriptor(
  '/client_user_package.ClientUserPackageHandler/GetListPackage',
  grpc.web.MethodType.UNARY,
  proto.client_user_package.GetListPackageRequest,
  proto.client_user_package.GetListPackageResponse,
  /**
   * @param {!proto.client_user_package.GetListPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_user_package.GetListPackageResponse.deserializeBinary
);


/**
 * @param {!proto.client_user_package.GetListPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_user_package.GetListPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_user_package.GetListPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_user_package.ClientUserPackageHandlerClient.prototype.getListPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_user_package.ClientUserPackageHandler/GetListPackage',
      request,
      metadata || {},
      methodDescriptor_ClientUserPackageHandler_GetListPackage,
      callback);
};


/**
 * @param {!proto.client_user_package.GetListPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_user_package.GetListPackageResponse>}
 *     Promise that resolves to the response
 */
proto.client_user_package.ClientUserPackageHandlerPromiseClient.prototype.getListPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_user_package.ClientUserPackageHandler/GetListPackage',
      request,
      metadata || {},
      methodDescriptor_ClientUserPackageHandler_GetListPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_user_package.CreateUserPackageRequest,
 *   !proto.client_user_package.CreateUserPackageResponse>}
 */
const methodDescriptor_ClientUserPackageHandler_CreateUserPackage = new grpc.web.MethodDescriptor(
  '/client_user_package.ClientUserPackageHandler/CreateUserPackage',
  grpc.web.MethodType.UNARY,
  proto.client_user_package.CreateUserPackageRequest,
  proto.client_user_package.CreateUserPackageResponse,
  /**
   * @param {!proto.client_user_package.CreateUserPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_user_package.CreateUserPackageResponse.deserializeBinary
);


/**
 * @param {!proto.client_user_package.CreateUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_user_package.CreateUserPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_user_package.CreateUserPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_user_package.ClientUserPackageHandlerClient.prototype.createUserPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_user_package.ClientUserPackageHandler/CreateUserPackage',
      request,
      metadata || {},
      methodDescriptor_ClientUserPackageHandler_CreateUserPackage,
      callback);
};


/**
 * @param {!proto.client_user_package.CreateUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_user_package.CreateUserPackageResponse>}
 *     Promise that resolves to the response
 */
proto.client_user_package.ClientUserPackageHandlerPromiseClient.prototype.createUserPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_user_package.ClientUserPackageHandler/CreateUserPackage',
      request,
      metadata || {},
      methodDescriptor_ClientUserPackageHandler_CreateUserPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_user_package.ConfirmPaymentUserPackageRequest,
 *   !proto.client_user_package.ConfirmPaymentUserPackageResponse>}
 */
const methodDescriptor_ClientUserPackageHandler_ConfirmPaymentUserPackage = new grpc.web.MethodDescriptor(
  '/client_user_package.ClientUserPackageHandler/ConfirmPaymentUserPackage',
  grpc.web.MethodType.UNARY,
  proto.client_user_package.ConfirmPaymentUserPackageRequest,
  proto.client_user_package.ConfirmPaymentUserPackageResponse,
  /**
   * @param {!proto.client_user_package.ConfirmPaymentUserPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_user_package.ConfirmPaymentUserPackageResponse.deserializeBinary
);


/**
 * @param {!proto.client_user_package.ConfirmPaymentUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_user_package.ConfirmPaymentUserPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_user_package.ConfirmPaymentUserPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_user_package.ClientUserPackageHandlerClient.prototype.confirmPaymentUserPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_user_package.ClientUserPackageHandler/ConfirmPaymentUserPackage',
      request,
      metadata || {},
      methodDescriptor_ClientUserPackageHandler_ConfirmPaymentUserPackage,
      callback);
};


/**
 * @param {!proto.client_user_package.ConfirmPaymentUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_user_package.ConfirmPaymentUserPackageResponse>}
 *     Promise that resolves to the response
 */
proto.client_user_package.ClientUserPackageHandlerPromiseClient.prototype.confirmPaymentUserPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_user_package.ClientUserPackageHandler/ConfirmPaymentUserPackage',
      request,
      metadata || {},
      methodDescriptor_ClientUserPackageHandler_ConfirmPaymentUserPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_user_package.GetListUserPackageRequest,
 *   !proto.client_user_package.GetListUserPackageResponse>}
 */
const methodDescriptor_ClientUserPackageHandler_GetListUserPackage = new grpc.web.MethodDescriptor(
  '/client_user_package.ClientUserPackageHandler/GetListUserPackage',
  grpc.web.MethodType.UNARY,
  proto.client_user_package.GetListUserPackageRequest,
  proto.client_user_package.GetListUserPackageResponse,
  /**
   * @param {!proto.client_user_package.GetListUserPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_user_package.GetListUserPackageResponse.deserializeBinary
);


/**
 * @param {!proto.client_user_package.GetListUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_user_package.GetListUserPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_user_package.GetListUserPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_user_package.ClientUserPackageHandlerClient.prototype.getListUserPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_user_package.ClientUserPackageHandler/GetListUserPackage',
      request,
      metadata || {},
      methodDescriptor_ClientUserPackageHandler_GetListUserPackage,
      callback);
};


/**
 * @param {!proto.client_user_package.GetListUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_user_package.GetListUserPackageResponse>}
 *     Promise that resolves to the response
 */
proto.client_user_package.ClientUserPackageHandlerPromiseClient.prototype.getListUserPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_user_package.ClientUserPackageHandler/GetListUserPackage',
      request,
      metadata || {},
      methodDescriptor_ClientUserPackageHandler_GetListUserPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_user_package.GetDetailUserPackageRequest,
 *   !proto.client_user_package.GetDetailUserPackageResponse>}
 */
const methodDescriptor_ClientUserPackageHandler_GetDetailUserPackage = new grpc.web.MethodDescriptor(
  '/client_user_package.ClientUserPackageHandler/GetDetailUserPackage',
  grpc.web.MethodType.UNARY,
  proto.client_user_package.GetDetailUserPackageRequest,
  proto.client_user_package.GetDetailUserPackageResponse,
  /**
   * @param {!proto.client_user_package.GetDetailUserPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_user_package.GetDetailUserPackageResponse.deserializeBinary
);


/**
 * @param {!proto.client_user_package.GetDetailUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_user_package.GetDetailUserPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_user_package.GetDetailUserPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_user_package.ClientUserPackageHandlerClient.prototype.getDetailUserPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_user_package.ClientUserPackageHandler/GetDetailUserPackage',
      request,
      metadata || {},
      methodDescriptor_ClientUserPackageHandler_GetDetailUserPackage,
      callback);
};


/**
 * @param {!proto.client_user_package.GetDetailUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_user_package.GetDetailUserPackageResponse>}
 *     Promise that resolves to the response
 */
proto.client_user_package.ClientUserPackageHandlerPromiseClient.prototype.getDetailUserPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_user_package.ClientUserPackageHandler/GetDetailUserPackage',
      request,
      metadata || {},
      methodDescriptor_ClientUserPackageHandler_GetDetailUserPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_user_package.GetUserPackageStatusRequest,
 *   !proto.client_user_package.GetUserPackageStatusResponse>}
 */
const methodDescriptor_ClientUserPackageHandler_GetUserPackageStatus = new grpc.web.MethodDescriptor(
  '/client_user_package.ClientUserPackageHandler/GetUserPackageStatus',
  grpc.web.MethodType.UNARY,
  proto.client_user_package.GetUserPackageStatusRequest,
  proto.client_user_package.GetUserPackageStatusResponse,
  /**
   * @param {!proto.client_user_package.GetUserPackageStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_user_package.GetUserPackageStatusResponse.deserializeBinary
);


/**
 * @param {!proto.client_user_package.GetUserPackageStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_user_package.GetUserPackageStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_user_package.GetUserPackageStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_user_package.ClientUserPackageHandlerClient.prototype.getUserPackageStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_user_package.ClientUserPackageHandler/GetUserPackageStatus',
      request,
      metadata || {},
      methodDescriptor_ClientUserPackageHandler_GetUserPackageStatus,
      callback);
};


/**
 * @param {!proto.client_user_package.GetUserPackageStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_user_package.GetUserPackageStatusResponse>}
 *     Promise that resolves to the response
 */
proto.client_user_package.ClientUserPackageHandlerPromiseClient.prototype.getUserPackageStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_user_package.ClientUserPackageHandler/GetUserPackageStatus',
      request,
      metadata || {},
      methodDescriptor_ClientUserPackageHandler_GetUserPackageStatus);
};


module.exports = proto.client_user_package;

