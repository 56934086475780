import { useContext, useEffect, useRef, useState, type FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/loading";
import Back from "../../components/back";
import { HiOutlineEye } from "react-icons/hi2";
import { LoadingContext } from "../../objects/loading_context";
import { errorToast, successToast } from "../../utils/helper-ui";
import { clientAuth, clientFile } from "../../App";
import {
  RegistrationRequest,
  RegistrationRequestFinancialAccount,
  RegistrationResponseData,
} from "../../grpc/auth/auth_pb";
import { getNullToken, getToken, validateEmail } from "../../utils/helper";
import { FileBase64Request } from "../../grpc/service/file_pb";

interface RegisterPartnerProps {}

const RegisterPartner: FC<RegisterPartnerProps> = ({}) => {
  const { token } = useParams();
  const [picUrl, setPicUrl] = useState<string>("/assets/add_photo.png");
  const [picPath, setPicPath] = useState<string>("");
  const fileRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [confirm, setConfirm] = useState("");
  const [username, setUsername] = useState("");
  //   const toaster = useToaster();
  const [phone, setPhone] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberName, setAccountNumberName] = useState("");
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [regResponse, setRegResponse] =
    useState<RegistrationResponseData.AsObject | null>(null);
  const nav = useNavigate();

  const signup = async () => {
    try {
      if (password.length == 0) {
        throw Error("Password tidak boleh kosong");
      }
      if (password.length < 6) {
        throw Error("Password minimal 6 karakter");
      }
      if (password != confirm && password.length > 0) {
        throw Error("Password konfirmasi tidak sama");
      }
      if (accountNumber.length == 0) {
        throw Error("Nomor rekening tidak boleh kosong");
      }
      if (accountNumberName.length == 0) {
        throw Error("Nama Rekening tidak boleh kosong");
      }
      if (picPath.length == 0) {
        throw Error("Foto profil tidak boleh kosong");
      }
      if (name.length == 0) {
        throw Error("Nama tidak boleh kosong");
      }
      if (email.length == 0) {
        throw Error("Email tidak boleh kosong");
      }
      if (validateEmail(email) == false) {
        throw Error("Email tidak valid");
      }
      setIsLoading(true);
      let req = new RegistrationRequest();
      req.setName(name);
      req.setPhoneNumber(phone);
      req.setEmail(email);
      req.setPassword(password);
      req.setUsername(username);
      req.setDevice("web");
      req.setValidationKey(token ?? "");
      let acc = new RegistrationRequestFinancialAccount();
      acc.setAccountNumber(accountNumber);
      acc.setName(accountNumberName);
      acc.setType("DANA");
      req.addFinancialAccounts(acc);

      req.setProfilePicturePath(picPath);
      clientAuth.registration(req, {}, (err, resp) => {
        if (err) {
          errorToast(err.message);
          setIsLoading(false);
          return;
        }

        setIsLoading(false);
        setRegResponse(resp.getData()?.toObject() ?? null);
        successToast("Akun anda telah terdaftar, silahkan login");
      });
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (regResponse) {
      nav("/login");
    }
  }, [regResponse]);

  return (
    <div className="h-screen w-full">
      <input
        accept="image/*"
        type="file"
        className="hidden"
        ref={fileRef}
        onChange={(el) => {
          let files = fileRef.current?.files;
          if (files) {
            let reader = new FileReader();

            reader.onload = function () {
              setPicUrl(reader?.result?.toString() ?? "/assets/add_photo.png");
              setIsLoading(true);
              let req = new FileBase64Request();
              req.setFile(`${reader?.result}`.split("base64,")[1]);
              // imgUpload.split()
              // console.log()
              clientFile.uploadBase64Temp(req, getNullToken(), (err, resp) => {
                setIsLoading(false);
                if (err) {
                  errorToast(err.message);
                  
                  return;
                }
                let path = resp.getData()?.toObject();
                setPicPath(path?.path ?? "");
                setPicUrl(path?.url ?? "/assets/add_photo.png");
              });
            };
            reader.readAsDataURL(files[0]);
          }
        }}
      />
      <Loading />
      <div className="p-4 w-full">
        <Back />
        <h3 className=" text-sky-500 mt-4">Lengkapi Akunmu</h3>
        <small>Halo! Sebelum bergabung Silahkan isi form dibawah ini</small>
        <h4 className="mt-4">I. Data Diri</h4>
        <h5 className="">Upload foto diri</h5>
        <div className="grid grid-cols-2 gap-8 mt-4 mb-4">
          <div>
            <img
              className="object-fill  aspect-[4/3]  w-full rounded-lg"
              src={picUrl}
              alt=""
              onClick={() => fileRef.current?.click()}
            />
          </div>
          <div>
            <h6>Note :</h6>
            <ul className="list-disc">
              <li>Wajah harus terlihat jelas</li>
              <li>Foto setengah badan</li>
            </ul>
          </div>
        </div>
        <label className="font-bold mb-1 px-1" htmlFor="">
          Nama Lengkap
        </label>
        <div className=" relative">
          <input
            value={name}
            placeholder="Masukan Nama Lengkap"
            className="form-control mb-4"
            onChange={(el) => setName(el.currentTarget.value)}
          />
        </div>
        <label className="font-bold mb-1 px-1" htmlFor="">
          Username
        </label>
        <div className=" relative">
          <input
            value={username}
            placeholder="Masukan Username"
            className="form-control mb-4"
            onChange={(el) => setUsername(el.currentTarget.value)}
          />
        </div>
        <label className="font-bold mb-1 px-1" htmlFor="">
          Telepon
        </label>
        <div className=" relative">
          <input
            value={phone}
            placeholder="Masukan no telp"
            className="form-control mb-4"
            onChange={(el) => setPhone(el.currentTarget.value)}
          />
        </div>
        <label className="font-bold mb-1 px-1" htmlFor="">
          Email
        </label>
        <div className=" relative">
          <input
            value={email}
            type="email"
            placeholder="Email"
            className="form-control mb-4"
            onChange={(el) => setEmail(el.currentTarget.value)}
          />
        </div>

        <label className="font-bold mb-1 px-1" htmlFor="">
          Password
        </label>
        <div className=" relative">
          <input
            value={password}
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            className="form-control mb-4"
            onChange={(el) => setPassword(el.currentTarget.value)}
          />
          <HiOutlineEye
            onClick={() => setShowPassword(!showPassword)}
            size={20}
            className={`${
              showPassword ? "text-gray-600" : "text-gray-300"
            }  absolute top-2 right-2`}
          />
        </div>
        <label className="font-bold mb-1 px-1" htmlFor="">
          Ulangi Password
        </label>
        <div className=" relative">
          <input
            value={confirm}
            type={showPassword ? "text" : "password"}
            placeholder="Ulangi Password"
            className="form-control mb-4"
            onChange={(el) => setConfirm(el.currentTarget.value)}
          />
          <HiOutlineEye
            onClick={() => setShowPassword(!showPassword)}
            size={20}
            className={`${
              showPassword ? "text-gray-600" : "text-gray-300"
            }  absolute top-2 right-2`}
          />
        </div>
        <h4 className="mt-4 mb-4">II. Informasi Penerimaan Fee</h4>
        <label className="font-bold mb-1 px-1" htmlFor="">
          Nomor DANA
        </label>
        <div className=" relative">
          <input
            value={accountNumber}
            type="text"
            placeholder="Nomor DANA"
            className="form-control mb-4"
            onChange={(el) => {
              setAccountNumber(el.target.value);
            }}
          />
        </div>
        <label className="font-bold mb-1 px-1" htmlFor="">
          Nama Penerima DANA
        </label>
        <div className=" relative">
          <input
            value={accountNumberName}
            type="text"
            placeholder="Nama Penerima DANA"
            className="form-control mb-4"
            onChange={(el) => {
              setAccountNumberName(el.target.value);
            }}
          />
        </div>

        <button onClick={signup} type="button" className="btn w-full mt-8">
          Daftar
        </button>
      </div>
    </div>
  );
};
export default RegisterPartner;
