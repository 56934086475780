import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface RefTermProps {
    children?: ReactNode | null
}

const RefTerm: FC<RefTermProps> = ({ children}) => {
    return (
        <div className={`flex-1  p-4  overflow-y-auto bg-white rounded-xl border border-orange-400 min-h-[400px]`}>
            <img src="/assets/referal_tnc.png" className=' mx-auto -mt-5' alt="" />
            <div className=' mt-8'>
                {/* dari sini */}
                <div className=' flex mb-4'>
                    <div className='flex mr-4'>
                        <div className=' rounded-full bg-orange-400 text-white justify-center items-center p-0.5 w-6 h-6 text-center'>1</div>
                    </div>
                    <div className='flex flex-col'>
                        <div>Fee yang akan kamu terima yaitu 6% (dari nominal Temanmu ambil paket premium)</div>
                        <div className=' bg-orange-100 p-4 rounded-lg text-xs flex'>
                            <img src="/assets/lamp.png" alt="" className='w-4 h-4 mr-2' /> Tips: Semakin besar paket premium yang diambil temanmu, maka semakin besar nominal yang kamu dapatkan.
                        </div>
                    </div>
                </div>
                {/* sampai sini */}
                {/* dari sini */}
                <div className=' flex mb-4'>
                    <div className='flex mr-4'>
                        <div className=' rounded-full bg-orange-400 text-white justify-center items-center p-0.5 w-6 h-6 text-center'>2</div>
                    </div>
                    <div className='flex flex-col'>
                        <div>Setelah mendapatkan Fee Referral, kamu bisa ajukan withdraw saat kontrak pekerjaan selesai.</div>
                        <img src="/assets/referal_tnc2.png" alt="" sizes="" className='mt-4' />
                    </div>
                </div>
                {/* sampai sini */}
                <div className=' flex mb-4'>
                    <div className='flex mr-4'>
                        <div className=' rounded-full bg-orange-400 text-white justify-center items-center p-0.5 w-6 h-6 text-center'>3</div>
                    </div>
                    <div className='flex flex-col'>
                        <div>Informasi tambahan :</div>
                        <ol className="list-decimal mt-2">
                            <li>Periode pencairan dan besaran fee menyesuaikan termin kontrak premium temanmu (30 hari)</li>
                            <li>Fee 6% yang didapatkan oleh pengundang setelah teman membuat kontrak premium, tapi apabila teman tidak menggunakan kode unik pengundang, maka pengundang tidak mendapatkan fee.</li>
                            <li>Pengajuan penarikan fee tunai harus melalui rekening DANA</li>
                            <li>Jika Admin menemukan kecurangan dalam bentuk apapun (termasuk namun tidak terbatas pada serangan sistem yang disengaja, tindakan manipulasi untuk mendapatkan fee, tindakan pemalsuan) GK dapat sewaktu-waktu membatalkan hak penerimaan fee.</li>
                            <li>Untuk pertanyaan lebih laniut, silakan hubungi Customer Service GK di <br /><Link target='_blank' to="https://wa.me/6287824185785?text=Halo, min boleh saya tanya tanya dulu?">0878-2418-5785</Link> </li>
                        </ol>
                    </div>
                </div>
                {/* sampai sini */}
            </div>
            {children}
            
        </div>
    );
}
export default RefTerm;