import { useEffect, useState, type FC } from 'react';
import { clientUserPackage } from '../App';
import { GetListUserPackageRequest, GetListUserPackageResponseData } from '../grpc/client/client_user_package_pb';
import { FAILED, ORDER_DONE, ORDER_FAILED, ORDER_PROCESSING, ORDER_UNPAID, PAID, PENDING_CONFIRMATION, PENDING_PAYMENT } from '../utils/contants';
import { getToken, money } from '../utils/helper';
import { errorToast } from '../utils/helper-ui';
import { useNavigate } from 'react-router-dom';
import LoadingComponent from './loading_component';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/id';
import NoData from './no_data';

interface ListPackageOrderProps {
    mode: string
}

const ListPackageOrder: FC<ListPackageOrderProps> = ({ mode }) => {
    const nav = useNavigate()
    const [mounted, setMounted] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [hasNext, setHasNext] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataOrders, setDataOrders] = useState<GetListUserPackageResponseData.AsObject[]>([]);


    useEffect(() => {
        setMounted(true)

    }, []);


    useEffect(() => {
        getData()
    }, [page, limit, mounted, mode]);



    const getData = () => {
        setIsLoading(true)
        let paymentStatus = ""
        switch (mode) {
            case ORDER_UNPAID:
                paymentStatus = PENDING_PAYMENT
                break;
            case ORDER_PROCESSING:
                paymentStatus = PENDING_CONFIRMATION
                break;
            case ORDER_DONE:
                paymentStatus = PAID
                break;
            case ORDER_FAILED:
                paymentStatus = FAILED
                break;
            default:
                break;
        }
        let req = new GetListUserPackageRequest()
        req.setPage(page)
        req.setLimit(limit)

        req.setPaymentStatus(paymentStatus)
        clientUserPackage.getListUserPackage(req, getToken(), (err, resp) => {
            if (err) {
                errorToast(err.message)
                return
            }
            setHasNext(resp.getPagination()?.toObject().next != page)
            setDataOrders(resp.getDataList().map(e => e.toObject()))
            setIsLoading(false)
        })
    }

    const renderCard = (e: GetListUserPackageResponseData.AsObject) => (
        <div className=' bg-white mb-4 last:mb-4'>
            <div className='flex justify-between py-4 px-4 items-center border-b border-b-gray-200'>
                <Moment className='font-semibold text-xs' format='dddd, DD MMMM YYYY'>{e.createdAt}</Moment>
                {mode == ORDER_UNPAID && <span className='text-sm text-red-500'>Belum dibayar</span>}
                {mode == ORDER_PROCESSING && <span className='text-sm text-sky-500'>Sedang diproses</span>}
                {mode == ORDER_DONE && <span className='text-sm text-sky-500'>Berhasil</span>}
                {mode == ORDER_FAILED && <span className='text-sm text-red-500'>Gagal</span>}
            </div>
            <div className='p-4 grid grid-cols-5 gap-4 border-b border-b-gray-200'>
                <div className='flex flex-col'>
                    <div className=' bg-sky-50 flex justify-center items-center rounded-lg p-2'>
                        <img src={e.packageName.toLocaleLowerCase() == 'premium' ? `/assets/business_logo.png` : `/assets/freemium_logo.png`} alt="" className='w-10 h-10' />
                    </div>
                </div>
                <div className=' col-span-4'>
                    <h6>{e.packageName}</h6>
                    <div className='flex justify-between text-sm'>
                        <span className=''>Total Komentar</span>
                        <span>{money(e.commentPerDay)} / Hari</span>
                    </div>
                    <div className='flex justify-between'>
                        <span className=''>Total Sebulan</span>
                        <span>{money(e.totalComment)}</span>
                    </div>
                    <div className='flex justify-between'>
                        <span className=' text-sky-500'>+ Bonus Likes</span>
                        <span className='text-sky-500'>{money(e.totalLikes)}</span>
                    </div>
                    <div className='flex justify-between'>
                        <span className=' text-sky-500'>+ Bonus Shares</span>
                        <span className='text-sky-500'>{money(e.totalShares)}</span>
                    </div>
                </div>
            </div>
            <div className='p-4 justify-end flex border-b border-b-gray-200 '>
                {mode == ORDER_UNPAID || mode == ORDER_FAILED ? `Jumlah yang harus dibayar` : `Jumlah yang sudah dibayar`} <span className=' text-sky-500 font-bold ml-4'>Rp. {money(e.totalPrice)}</span>
            </div>
            <div className='p-4 justify-between flex border-b border-b-gray-200 items-center '>
                {mode == ORDER_UNPAID && <span className='w-1/2 mr-2 text-sm'>{`Bayar sebelum ${moment.utc(e.paymentExpiryTime).format("DD-MM-YYYY HH:mm")} dengan transfer Bank (Manual)`}</span>}
                {mode == ORDER_PROCESSING && <span className='w-1/2 mr-2 text-sm'>{`Pesanan dikonfirmasi sebelum ${moment.utc(e.confirmationExpiryTime).format("DD-MM-YYYY HH:mm")} `}</span>}
                {/* {mode == ORDER_DONE && <span className='w-1/2 mr-2 text-sm'>{"Pesanan sudah dibayar"}</span>} */}
                {mode == ORDER_DONE && <span className='w-1/2 mr-2 text-sm'>{e.updatedAt != "" ? `Selesai pada ${moment.utc(e.updatedAt).format("DD-MM-YYYY HH:mm")} `: "Pesanan sudah dibayar"}</span>}
                {mode == ORDER_UNPAID && <button onClick={() => goToDetail(e.id)} type="button" className="btn">Bayar Sekarang</button>}
                {(mode == ORDER_PROCESSING || mode == ORDER_DONE) && <button onClick={() => goToDetail(e.id)} type="button" className="btn">Lihat Detail</button>}
            </div>
        </div>
    )

    const goToDetail = (id: string) => {
        nav(`/package-order/${id}`)
    }

    const noData = () => (<NoData />)

    return (
        <div className='py-1'>
            <LoadingComponent isLoading={isLoading} />
            {!isLoading &&
                <ul>
                    {dataOrders.map(e => <li key={e.id}>{renderCard(e)}</li>)}
                </ul>
            }
            {dataOrders.length == 0 && !isLoading && noData()}
        </div>

    );
}
export default ListPackageOrder;


