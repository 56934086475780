import { useContext, type FC } from 'react';
import { HiHome, HiXMark } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import { Avatar, Drawer } from 'rsuite';
import { GetListCommentatorAccountResponseData } from '../../grpc/commentator/commentator_account_pb';
import { initials } from '../../utils/helper';
import { PiPlusBold } from 'react-icons/pi';
import { SidebarContext } from '../../objects/SidebarContext';

interface SidebarProps {
    accounts: GetListCommentatorAccountResponseData.AsObject[]
}

const Sidebar: FC<SidebarProps> = ({
    accounts
}) => {
    const {isOpen, setOpen} = useContext(SidebarContext)

    const nav = useNavigate();
        return (
            <Drawer className='drawer-rounded' size={"full"} closeButton={null} placement={"left"} open={isOpen} onClose={() => setOpen(false)}>
            <Drawer.Body style={{ padding: 0 }}>
                <div className='flex flex-col h-full'>
                    <div className='w-full px-4 py-2 justify-between items-center flex pt-6 bg-white border-b'>
                        <div className='flex items-center '>
                            <img src={"/assets/logo.svg"} alt="" className=' w-6  mr-2 ' />
                            <h3 className='font-bold text-lg'><span className=' text-sky-500 mr-1 mb-4'>Grosir</span><span>Komen</span></h3>
                        </div>
                        <div className='p-2 '>
                            <HiXMark onClick={() => setOpen(false)} />
                        </div>
                    </div>
                    <div className='flex-1 overflow-y-auto'>
                        <div className='flex  items-center px-4 py-4 border-b'  onClick={() => {
                                nav('/commentator/')
                                setOpen(false)
                            }}>
                            <HiHome size={24} className='mr-4 text-xl' /> <span className='text-base'>Beranda</span>
                        </div>
                        <div className='flex text-xl items-center px-4 py-4 border-b'>
                            <ul className='w-full'>
                                {accounts.map(e => <li className='mb-4 last:mb-0  ' key={e.id}>
                                    <div className='text-base  flex items-center justify-between ' onClick={() => {
                                        nav(`/commentator/account/${e.id}`)
                                        setOpen(false)
                                    }}>
                                        <div className='flex items-center'>
                                            <Avatar size='sm' circle src={e.profilePictureUrl} alt={initials(e.name)} />
                                            <span className='ml-4'>{e.name}</span>
                                        </div>
                                        {e.notificationCount > 0 && <span className=' text-orange-400'>{e.notificationCount} Notifikasi</span>}
                                    </div>
                                </li>)}
                            </ul>
                        </div>
                        <div className='flex text-xl items-center px-4 py-4 '  onClick={() => {
                                nav('/commentator/account/new')
                                setOpen(false)
                            }}>
                            <PiPlusBold />
                            <span className='ml-2 text-base'>
                                Tambah Akun
                            </span>

                        </div>
                    </div>
                </div>
            </Drawer.Body>
        </Drawer>
        );
}
export default Sidebar;