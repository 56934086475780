import { createContext } from "react";
import { GetOrderDraftListResponseData } from "../grpc/client/client_order_pb";

export const OrderContext = createContext<OrderContextType>({
    orderCtx: null,
    setOrderCtx: (val) => false
});

export type OrderContextType = {
    orderCtx: orderDataContext | null;
    setOrderCtx: (val: orderDataContext) => void;
};

export interface orderDataContext {
    id: string
    status: string
    dataDraft?: GetOrderDraftListResponseData.AsObject | undefined | null
}