// source: client_order.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.client_order.CreateBulkOrderDraftRequest', null, global);
goog.exportSymbol('proto.client_order.CreateBulkOrderDraftRequestData', null, global);
goog.exportSymbol('proto.client_order.CreateBulkOrderDraftResponse', null, global);
goog.exportSymbol('proto.client_order.CreateBulkOrderDraftResponseData', null, global);
goog.exportSymbol('proto.client_order.GetOrderDetailRequest', null, global);
goog.exportSymbol('proto.client_order.GetOrderDetailResponse', null, global);
goog.exportSymbol('proto.client_order.GetOrderDetailResponseData', null, global);
goog.exportSymbol('proto.client_order.GetOrderDiscussionRequest', null, global);
goog.exportSymbol('proto.client_order.GetOrderDiscussionResponse', null, global);
goog.exportSymbol('proto.client_order.GetOrderDiscussionResponseData', null, global);
goog.exportSymbol('proto.client_order.GetOrderDiscussionResponseDataReply', null, global);
goog.exportSymbol('proto.client_order.GetOrderDiscussionResponseDataUser', null, global);
goog.exportSymbol('proto.client_order.GetOrderDraftDetailResponse', null, global);
goog.exportSymbol('proto.client_order.GetOrderDraftDetailResponseData', null, global);
goog.exportSymbol('proto.client_order.GetOrderDraftListRequest', null, global);
goog.exportSymbol('proto.client_order.GetOrderDraftListResponse', null, global);
goog.exportSymbol('proto.client_order.GetOrderDraftListResponseData', null, global);
goog.exportSymbol('proto.client_order.GetOrderDraftListResponseDataUser', null, global);
goog.exportSymbol('proto.client_order.GetOrderListRequest', null, global);
goog.exportSymbol('proto.client_order.GetOrderListResponse', null, global);
goog.exportSymbol('proto.client_order.GetOrderListResponseData', null, global);
goog.exportSymbol('proto.client_order.GetOrderListResponseDataUser', null, global);
goog.exportSymbol('proto.client_order.GetSocialMediaPreviewRequest', null, global);
goog.exportSymbol('proto.client_order.GetSocialMediaPreviewResponse', null, global);
goog.exportSymbol('proto.client_order.GetSocialMediaPreviewResponseData', null, global);
goog.exportSymbol('proto.client_order.GiveOrderFeedbackRequest', null, global);
goog.exportSymbol('proto.client_order.GiveOrderFeedbackResponse', null, global);
goog.exportSymbol('proto.client_order.GiveOrderFeedbackResponseData', null, global);
goog.exportSymbol('proto.client_order.Meta', null, global);
goog.exportSymbol('proto.client_order.Pagination', null, global);
goog.exportSymbol('proto.client_order.SendDiscussionRequest', null, global);
goog.exportSymbol('proto.client_order.SendDiscussionResponse', null, global);
goog.exportSymbol('proto.client_order.SendDiscussionResponseData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.Meta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.Meta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.Meta.displayName = 'proto.client_order.Meta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.Pagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.Pagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.Pagination.displayName = 'proto.client_order.Pagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderDraftListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetOrderDraftListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderDraftListRequest.displayName = 'proto.client_order.GetOrderDraftListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderDraftListResponseDataUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetOrderDraftListResponseDataUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderDraftListResponseDataUser.displayName = 'proto.client_order.GetOrderDraftListResponseDataUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderDraftListResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetOrderDraftListResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderDraftListResponseData.displayName = 'proto.client_order.GetOrderDraftListResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderDraftListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.client_order.GetOrderDraftListResponse.repeatedFields_, null);
};
goog.inherits(proto.client_order.GetOrderDraftListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderDraftListResponse.displayName = 'proto.client_order.GetOrderDraftListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetOrderListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderListRequest.displayName = 'proto.client_order.GetOrderListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderListResponseDataUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetOrderListResponseDataUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderListResponseDataUser.displayName = 'proto.client_order.GetOrderListResponseDataUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderListResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetOrderListResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderListResponseData.displayName = 'proto.client_order.GetOrderListResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.client_order.GetOrderListResponse.repeatedFields_, null);
};
goog.inherits(proto.client_order.GetOrderListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderListResponse.displayName = 'proto.client_order.GetOrderListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.CreateBulkOrderDraftRequestData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.CreateBulkOrderDraftRequestData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.CreateBulkOrderDraftRequestData.displayName = 'proto.client_order.CreateBulkOrderDraftRequestData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.CreateBulkOrderDraftRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.client_order.CreateBulkOrderDraftRequest.repeatedFields_, null);
};
goog.inherits(proto.client_order.CreateBulkOrderDraftRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.CreateBulkOrderDraftRequest.displayName = 'proto.client_order.CreateBulkOrderDraftRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.CreateBulkOrderDraftResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.CreateBulkOrderDraftResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.CreateBulkOrderDraftResponseData.displayName = 'proto.client_order.CreateBulkOrderDraftResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.CreateBulkOrderDraftResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.client_order.CreateBulkOrderDraftResponse.repeatedFields_, null);
};
goog.inherits(proto.client_order.CreateBulkOrderDraftResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.CreateBulkOrderDraftResponse.displayName = 'proto.client_order.CreateBulkOrderDraftResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetOrderDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderDetailRequest.displayName = 'proto.client_order.GetOrderDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderDetailResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetOrderDetailResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderDetailResponseData.displayName = 'proto.client_order.GetOrderDetailResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetOrderDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderDetailResponse.displayName = 'proto.client_order.GetOrderDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderDraftDetailResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetOrderDraftDetailResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderDraftDetailResponseData.displayName = 'proto.client_order.GetOrderDraftDetailResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderDraftDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetOrderDraftDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderDraftDetailResponse.displayName = 'proto.client_order.GetOrderDraftDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderDiscussionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetOrderDiscussionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderDiscussionRequest.displayName = 'proto.client_order.GetOrderDiscussionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderDiscussionResponseDataUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetOrderDiscussionResponseDataUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderDiscussionResponseDataUser.displayName = 'proto.client_order.GetOrderDiscussionResponseDataUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderDiscussionResponseDataReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetOrderDiscussionResponseDataReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderDiscussionResponseDataReply.displayName = 'proto.client_order.GetOrderDiscussionResponseDataReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderDiscussionResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.client_order.GetOrderDiscussionResponseData.repeatedFields_, null);
};
goog.inherits(proto.client_order.GetOrderDiscussionResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderDiscussionResponseData.displayName = 'proto.client_order.GetOrderDiscussionResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetOrderDiscussionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.client_order.GetOrderDiscussionResponse.repeatedFields_, null);
};
goog.inherits(proto.client_order.GetOrderDiscussionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetOrderDiscussionResponse.displayName = 'proto.client_order.GetOrderDiscussionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GiveOrderFeedbackRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GiveOrderFeedbackRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GiveOrderFeedbackRequest.displayName = 'proto.client_order.GiveOrderFeedbackRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GiveOrderFeedbackResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GiveOrderFeedbackResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GiveOrderFeedbackResponseData.displayName = 'proto.client_order.GiveOrderFeedbackResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GiveOrderFeedbackResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GiveOrderFeedbackResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GiveOrderFeedbackResponse.displayName = 'proto.client_order.GiveOrderFeedbackResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.SendDiscussionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.SendDiscussionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.SendDiscussionRequest.displayName = 'proto.client_order.SendDiscussionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.SendDiscussionResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.SendDiscussionResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.SendDiscussionResponseData.displayName = 'proto.client_order.SendDiscussionResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.SendDiscussionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.SendDiscussionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.SendDiscussionResponse.displayName = 'proto.client_order.SendDiscussionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetSocialMediaPreviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetSocialMediaPreviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetSocialMediaPreviewRequest.displayName = 'proto.client_order.GetSocialMediaPreviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetSocialMediaPreviewResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetSocialMediaPreviewResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetSocialMediaPreviewResponseData.displayName = 'proto.client_order.GetSocialMediaPreviewResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_order.GetSocialMediaPreviewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_order.GetSocialMediaPreviewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_order.GetSocialMediaPreviewResponse.displayName = 'proto.client_order.GetSocialMediaPreviewResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.Meta.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.Meta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.Meta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.Meta.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.Meta}
 */
proto.client_order.Meta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.Meta;
  return proto.client_order.Meta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.Meta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.Meta}
 */
proto.client_order.Meta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.Meta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.Meta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.Meta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.Meta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.client_order.Meta.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.Meta} returns this
 */
proto.client_order.Meta.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 status = 2;
 * @return {number}
 */
proto.client_order.Meta.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.Meta} returns this
 */
proto.client_order.Meta.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.client_order.Meta.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.Meta} returns this
 */
proto.client_order.Meta.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.Pagination.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.Pagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.Pagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.Pagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    prev: jspb.Message.getFieldWithDefault(msg, 3, 0),
    next: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.Pagination}
 */
proto.client_order.Pagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.Pagination;
  return proto.client_order.Pagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.Pagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.Pagination}
 */
proto.client_order.Pagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrev(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNext(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPages(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.Pagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.Pagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.Pagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.Pagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPrev();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNext();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.client_order.Pagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.Pagination} returns this
 */
proto.client_order.Pagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.client_order.Pagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.Pagination} returns this
 */
proto.client_order.Pagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 prev = 3;
 * @return {number}
 */
proto.client_order.Pagination.prototype.getPrev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.Pagination} returns this
 */
proto.client_order.Pagination.prototype.setPrev = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 next = 4;
 * @return {number}
 */
proto.client_order.Pagination.prototype.getNext = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.Pagination} returns this
 */
proto.client_order.Pagination.prototype.setNext = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 total_pages = 5;
 * @return {number}
 */
proto.client_order.Pagination.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.Pagination} returns this
 */
proto.client_order.Pagination.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 total_records = 6;
 * @return {number}
 */
proto.client_order.Pagination.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.Pagination} returns this
 */
proto.client_order.Pagination.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderDraftListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderDraftListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderDraftListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDraftListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isDeclined: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderDraftListRequest}
 */
proto.client_order.GetOrderDraftListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderDraftListRequest;
  return proto.client_order.GetOrderDraftListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderDraftListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderDraftListRequest}
 */
proto.client_order.GetOrderDraftListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsConfirmed(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeclined(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderDraftListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderDraftListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderDraftListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDraftListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getIsConfirmed();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsDeclined();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.client_order.GetOrderDraftListRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderDraftListRequest} returns this
 */
proto.client_order.GetOrderDraftListRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.client_order.GetOrderDraftListRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderDraftListRequest} returns this
 */
proto.client_order.GetOrderDraftListRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_confirmed = 3;
 * @return {boolean}
 */
proto.client_order.GetOrderDraftListRequest.prototype.getIsConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.client_order.GetOrderDraftListRequest} returns this
 */
proto.client_order.GetOrderDraftListRequest.prototype.setIsConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_declined = 4;
 * @return {boolean}
 */
proto.client_order.GetOrderDraftListRequest.prototype.getIsDeclined = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.client_order.GetOrderDraftListRequest} returns this
 */
proto.client_order.GetOrderDraftListRequest.prototype.setIsDeclined = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderDraftListResponseDataUser.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderDraftListResponseDataUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderDraftListResponseDataUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDraftListResponseDataUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderDraftListResponseDataUser}
 */
proto.client_order.GetOrderDraftListResponseDataUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderDraftListResponseDataUser;
  return proto.client_order.GetOrderDraftListResponseDataUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderDraftListResponseDataUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderDraftListResponseDataUser}
 */
proto.client_order.GetOrderDraftListResponseDataUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderDraftListResponseDataUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderDraftListResponseDataUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderDraftListResponseDataUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDraftListResponseDataUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_order.GetOrderDraftListResponseDataUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftListResponseDataUser} returns this
 */
proto.client_order.GetOrderDraftListResponseDataUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.client_order.GetOrderDraftListResponseDataUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftListResponseDataUser} returns this
 */
proto.client_order.GetOrderDraftListResponseDataUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.client_order.GetOrderDraftListResponseDataUser.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftListResponseDataUser} returns this
 */
proto.client_order.GetOrderDraftListResponseDataUser.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderDraftListResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderDraftListResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderDraftListResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDraftListResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    thumbnailUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    url: jspb.Message.getFieldWithDefault(msg, 5, ""),
    isConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    positiveComment: jspb.Message.getFieldWithDefault(msg, 7, 0),
    negativeComment: jspb.Message.getFieldWithDefault(msg, 8, 0),
    tagFriend: jspb.Message.getFieldWithDefault(msg, 9, 0),
    confirmedAt: jspb.Message.getFieldWithDefault(msg, 10, ""),
    user: (f = msg.getUser()) && proto.client_order.GetOrderDraftListResponseDataUser.toObject(includeInstance, f),
    remarks: jspb.Message.getFieldWithDefault(msg, 12, ""),
    declinalRemarks: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderDraftListResponseData}
 */
proto.client_order.GetOrderDraftListResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderDraftListResponseData;
  return proto.client_order.GetOrderDraftListResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderDraftListResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderDraftListResponseData}
 */
proto.client_order.GetOrderDraftListResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsConfirmed(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositiveComment(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNegativeComment(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTagFriend(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmedAt(value);
      break;
    case 11:
      var value = new proto.client_order.GetOrderDraftListResponseDataUser;
      reader.readMessage(value,proto.client_order.GetOrderDraftListResponseDataUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeclinalRemarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderDraftListResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderDraftListResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderDraftListResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDraftListResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThumbnailUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIsConfirmed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPositiveComment();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getNegativeComment();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getTagFriend();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getConfirmedAt();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.client_order.GetOrderDraftListResponseDataUser.serializeBinaryToWriter
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDeclinalRemarks();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_order.GetOrderDraftListResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftListResponseData} returns this
 */
proto.client_order.GetOrderDraftListResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.client_order.GetOrderDraftListResponseData.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftListResponseData} returns this
 */
proto.client_order.GetOrderDraftListResponseData.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.client_order.GetOrderDraftListResponseData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftListResponseData} returns this
 */
proto.client_order.GetOrderDraftListResponseData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string thumbnail_url = 4;
 * @return {string}
 */
proto.client_order.GetOrderDraftListResponseData.prototype.getThumbnailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftListResponseData} returns this
 */
proto.client_order.GetOrderDraftListResponseData.prototype.setThumbnailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string url = 5;
 * @return {string}
 */
proto.client_order.GetOrderDraftListResponseData.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftListResponseData} returns this
 */
proto.client_order.GetOrderDraftListResponseData.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool is_confirmed = 6;
 * @return {boolean}
 */
proto.client_order.GetOrderDraftListResponseData.prototype.getIsConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.client_order.GetOrderDraftListResponseData} returns this
 */
proto.client_order.GetOrderDraftListResponseData.prototype.setIsConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional uint32 positive_comment = 7;
 * @return {number}
 */
proto.client_order.GetOrderDraftListResponseData.prototype.getPositiveComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderDraftListResponseData} returns this
 */
proto.client_order.GetOrderDraftListResponseData.prototype.setPositiveComment = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 negative_comment = 8;
 * @return {number}
 */
proto.client_order.GetOrderDraftListResponseData.prototype.getNegativeComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderDraftListResponseData} returns this
 */
proto.client_order.GetOrderDraftListResponseData.prototype.setNegativeComment = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 tag_friend = 9;
 * @return {number}
 */
proto.client_order.GetOrderDraftListResponseData.prototype.getTagFriend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderDraftListResponseData} returns this
 */
proto.client_order.GetOrderDraftListResponseData.prototype.setTagFriend = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string confirmed_at = 10;
 * @return {string}
 */
proto.client_order.GetOrderDraftListResponseData.prototype.getConfirmedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftListResponseData} returns this
 */
proto.client_order.GetOrderDraftListResponseData.prototype.setConfirmedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional GetOrderDraftListResponseDataUser user = 11;
 * @return {?proto.client_order.GetOrderDraftListResponseDataUser}
 */
proto.client_order.GetOrderDraftListResponseData.prototype.getUser = function() {
  return /** @type{?proto.client_order.GetOrderDraftListResponseDataUser} */ (
    jspb.Message.getWrapperField(this, proto.client_order.GetOrderDraftListResponseDataUser, 11));
};


/**
 * @param {?proto.client_order.GetOrderDraftListResponseDataUser|undefined} value
 * @return {!proto.client_order.GetOrderDraftListResponseData} returns this
*/
proto.client_order.GetOrderDraftListResponseData.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GetOrderDraftListResponseData} returns this
 */
proto.client_order.GetOrderDraftListResponseData.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GetOrderDraftListResponseData.prototype.hasUser = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string remarks = 12;
 * @return {string}
 */
proto.client_order.GetOrderDraftListResponseData.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftListResponseData} returns this
 */
proto.client_order.GetOrderDraftListResponseData.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string declinal_remarks = 13;
 * @return {string}
 */
proto.client_order.GetOrderDraftListResponseData.prototype.getDeclinalRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftListResponseData} returns this
 */
proto.client_order.GetOrderDraftListResponseData.prototype.setDeclinalRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.client_order.GetOrderDraftListResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderDraftListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderDraftListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderDraftListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDraftListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_order.Meta.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.client_order.Pagination.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.client_order.GetOrderDraftListResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderDraftListResponse}
 */
proto.client_order.GetOrderDraftListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderDraftListResponse;
  return proto.client_order.GetOrderDraftListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderDraftListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderDraftListResponse}
 */
proto.client_order.GetOrderDraftListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_order.Meta;
      reader.readMessage(value,proto.client_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_order.Pagination;
      reader.readMessage(value,proto.client_order.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.client_order.GetOrderDraftListResponseData;
      reader.readMessage(value,proto.client_order.GetOrderDraftListResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderDraftListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderDraftListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderDraftListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDraftListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.client_order.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.client_order.GetOrderDraftListResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_order.Meta}
 */
proto.client_order.GetOrderDraftListResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_order.Meta, 1));
};


/**
 * @param {?proto.client_order.Meta|undefined} value
 * @return {!proto.client_order.GetOrderDraftListResponse} returns this
*/
proto.client_order.GetOrderDraftListResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GetOrderDraftListResponse} returns this
 */
proto.client_order.GetOrderDraftListResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GetOrderDraftListResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.client_order.Pagination}
 */
proto.client_order.GetOrderDraftListResponse.prototype.getPagination = function() {
  return /** @type{?proto.client_order.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.client_order.Pagination, 2));
};


/**
 * @param {?proto.client_order.Pagination|undefined} value
 * @return {!proto.client_order.GetOrderDraftListResponse} returns this
*/
proto.client_order.GetOrderDraftListResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GetOrderDraftListResponse} returns this
 */
proto.client_order.GetOrderDraftListResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GetOrderDraftListResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated GetOrderDraftListResponseData data = 3;
 * @return {!Array<!proto.client_order.GetOrderDraftListResponseData>}
 */
proto.client_order.GetOrderDraftListResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.client_order.GetOrderDraftListResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.client_order.GetOrderDraftListResponseData, 3));
};


/**
 * @param {!Array<!proto.client_order.GetOrderDraftListResponseData>} value
 * @return {!proto.client_order.GetOrderDraftListResponse} returns this
*/
proto.client_order.GetOrderDraftListResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.client_order.GetOrderDraftListResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.client_order.GetOrderDraftListResponseData}
 */
proto.client_order.GetOrderDraftListResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.client_order.GetOrderDraftListResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.client_order.GetOrderDraftListResponse} returns this
 */
proto.client_order.GetOrderDraftListResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isFinished: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderListRequest}
 */
proto.client_order.GetOrderListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderListRequest;
  return proto.client_order.GetOrderListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderListRequest}
 */
proto.client_order.GetOrderListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFinished(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getIsFinished();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.client_order.GetOrderListRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderListRequest} returns this
 */
proto.client_order.GetOrderListRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.client_order.GetOrderListRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderListRequest} returns this
 */
proto.client_order.GetOrderListRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_finished = 3;
 * @return {boolean}
 */
proto.client_order.GetOrderListRequest.prototype.getIsFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.client_order.GetOrderListRequest} returns this
 */
proto.client_order.GetOrderListRequest.prototype.setIsFinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderListResponseDataUser.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderListResponseDataUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderListResponseDataUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderListResponseDataUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderListResponseDataUser}
 */
proto.client_order.GetOrderListResponseDataUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderListResponseDataUser;
  return proto.client_order.GetOrderListResponseDataUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderListResponseDataUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderListResponseDataUser}
 */
proto.client_order.GetOrderListResponseDataUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderListResponseDataUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderListResponseDataUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderListResponseDataUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderListResponseDataUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_order.GetOrderListResponseDataUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderListResponseDataUser} returns this
 */
proto.client_order.GetOrderListResponseDataUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.client_order.GetOrderListResponseDataUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderListResponseDataUser} returns this
 */
proto.client_order.GetOrderListResponseDataUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.client_order.GetOrderListResponseDataUser.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderListResponseDataUser} returns this
 */
proto.client_order.GetOrderListResponseDataUser.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderListResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderListResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderListResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderListResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dueDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    thumbnailUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    url: jspb.Message.getFieldWithDefault(msg, 6, ""),
    hasUnreadMessage: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    finishedAt: jspb.Message.getFieldWithDefault(msg, 8, ""),
    feedbackRating: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    feedbackComment: jspb.Message.getFieldWithDefault(msg, 10, ""),
    reportImageUrl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    user: (f = msg.getUser()) && proto.client_order.GetOrderListResponseDataUser.toObject(includeInstance, f),
    initialImageUrl: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderListResponseData}
 */
proto.client_order.GetOrderListResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderListResponseData;
  return proto.client_order.GetOrderListResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderListResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderListResponseData}
 */
proto.client_order.GetOrderListResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDueDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasUnreadMessage(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinishedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFeedbackRating(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedbackComment(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportImageUrl(value);
      break;
    case 12:
      var value = new proto.client_order.GetOrderListResponseDataUser;
      reader.readMessage(value,proto.client_order.GetOrderListResponseDataUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderListResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderListResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderListResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderListResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDueDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getThumbnailUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHasUnreadMessage();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getFinishedAt();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFeedbackRating();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getFeedbackComment();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getReportImageUrl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.client_order.GetOrderListResponseDataUser.serializeBinaryToWriter
    );
  }
  f = message.getInitialImageUrl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_order.GetOrderListResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderListResponseData} returns this
 */
proto.client_order.GetOrderListResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string due_date = 2;
 * @return {string}
 */
proto.client_order.GetOrderListResponseData.prototype.getDueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderListResponseData} returns this
 */
proto.client_order.GetOrderListResponseData.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.client_order.GetOrderListResponseData.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderListResponseData} returns this
 */
proto.client_order.GetOrderListResponseData.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.client_order.GetOrderListResponseData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderListResponseData} returns this
 */
proto.client_order.GetOrderListResponseData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string thumbnail_url = 5;
 * @return {string}
 */
proto.client_order.GetOrderListResponseData.prototype.getThumbnailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderListResponseData} returns this
 */
proto.client_order.GetOrderListResponseData.prototype.setThumbnailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string url = 6;
 * @return {string}
 */
proto.client_order.GetOrderListResponseData.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderListResponseData} returns this
 */
proto.client_order.GetOrderListResponseData.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool has_unread_message = 7;
 * @return {boolean}
 */
proto.client_order.GetOrderListResponseData.prototype.getHasUnreadMessage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.client_order.GetOrderListResponseData} returns this
 */
proto.client_order.GetOrderListResponseData.prototype.setHasUnreadMessage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string finished_at = 8;
 * @return {string}
 */
proto.client_order.GetOrderListResponseData.prototype.getFinishedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderListResponseData} returns this
 */
proto.client_order.GetOrderListResponseData.prototype.setFinishedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional float feedback_rating = 9;
 * @return {number}
 */
proto.client_order.GetOrderListResponseData.prototype.getFeedbackRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderListResponseData} returns this
 */
proto.client_order.GetOrderListResponseData.prototype.setFeedbackRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string feedback_comment = 10;
 * @return {string}
 */
proto.client_order.GetOrderListResponseData.prototype.getFeedbackComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderListResponseData} returns this
 */
proto.client_order.GetOrderListResponseData.prototype.setFeedbackComment = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string report_image_url = 11;
 * @return {string}
 */
proto.client_order.GetOrderListResponseData.prototype.getReportImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderListResponseData} returns this
 */
proto.client_order.GetOrderListResponseData.prototype.setReportImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional GetOrderListResponseDataUser user = 12;
 * @return {?proto.client_order.GetOrderListResponseDataUser}
 */
proto.client_order.GetOrderListResponseData.prototype.getUser = function() {
  return /** @type{?proto.client_order.GetOrderListResponseDataUser} */ (
    jspb.Message.getWrapperField(this, proto.client_order.GetOrderListResponseDataUser, 12));
};


/**
 * @param {?proto.client_order.GetOrderListResponseDataUser|undefined} value
 * @return {!proto.client_order.GetOrderListResponseData} returns this
*/
proto.client_order.GetOrderListResponseData.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GetOrderListResponseData} returns this
 */
proto.client_order.GetOrderListResponseData.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GetOrderListResponseData.prototype.hasUser = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string initial_image_url = 13;
 * @return {string}
 */
proto.client_order.GetOrderListResponseData.prototype.getInitialImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderListResponseData} returns this
 */
proto.client_order.GetOrderListResponseData.prototype.setInitialImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.client_order.GetOrderListResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_order.Meta.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.client_order.Pagination.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.client_order.GetOrderListResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderListResponse}
 */
proto.client_order.GetOrderListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderListResponse;
  return proto.client_order.GetOrderListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderListResponse}
 */
proto.client_order.GetOrderListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_order.Meta;
      reader.readMessage(value,proto.client_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_order.Pagination;
      reader.readMessage(value,proto.client_order.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.client_order.GetOrderListResponseData;
      reader.readMessage(value,proto.client_order.GetOrderListResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.client_order.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.client_order.GetOrderListResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_order.Meta}
 */
proto.client_order.GetOrderListResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_order.Meta, 1));
};


/**
 * @param {?proto.client_order.Meta|undefined} value
 * @return {!proto.client_order.GetOrderListResponse} returns this
*/
proto.client_order.GetOrderListResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GetOrderListResponse} returns this
 */
proto.client_order.GetOrderListResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GetOrderListResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.client_order.Pagination}
 */
proto.client_order.GetOrderListResponse.prototype.getPagination = function() {
  return /** @type{?proto.client_order.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.client_order.Pagination, 2));
};


/**
 * @param {?proto.client_order.Pagination|undefined} value
 * @return {!proto.client_order.GetOrderListResponse} returns this
*/
proto.client_order.GetOrderListResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GetOrderListResponse} returns this
 */
proto.client_order.GetOrderListResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GetOrderListResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated GetOrderListResponseData data = 3;
 * @return {!Array<!proto.client_order.GetOrderListResponseData>}
 */
proto.client_order.GetOrderListResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.client_order.GetOrderListResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.client_order.GetOrderListResponseData, 3));
};


/**
 * @param {!Array<!proto.client_order.GetOrderListResponseData>} value
 * @return {!proto.client_order.GetOrderListResponse} returns this
*/
proto.client_order.GetOrderListResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.client_order.GetOrderListResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.client_order.GetOrderListResponseData}
 */
proto.client_order.GetOrderListResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.client_order.GetOrderListResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.client_order.GetOrderListResponse} returns this
 */
proto.client_order.GetOrderListResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.CreateBulkOrderDraftRequestData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.CreateBulkOrderDraftRequestData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.CreateBulkOrderDraftRequestData.toObject = function(includeInstance, msg) {
  var f, obj = {
    platform: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    positiveComment: jspb.Message.getFieldWithDefault(msg, 3, 0),
    negativeComment: jspb.Message.getFieldWithDefault(msg, 4, 0),
    tagFriend: jspb.Message.getFieldWithDefault(msg, 5, 0),
    remarks: jspb.Message.getFieldWithDefault(msg, 6, ""),
    id: jspb.Message.getFieldWithDefault(msg, 7, ""),
    bonus: jspb.Message.getFieldWithDefault(msg, 8, 0),
    imagePath: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.CreateBulkOrderDraftRequestData}
 */
proto.client_order.CreateBulkOrderDraftRequestData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.CreateBulkOrderDraftRequestData;
  return proto.client_order.CreateBulkOrderDraftRequestData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.CreateBulkOrderDraftRequestData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.CreateBulkOrderDraftRequestData}
 */
proto.client_order.CreateBulkOrderDraftRequestData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatform(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositiveComment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNegativeComment(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTagFriend(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBonus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setImagePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.CreateBulkOrderDraftRequestData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.CreateBulkOrderDraftRequestData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.CreateBulkOrderDraftRequestData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlatform();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPositiveComment();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNegativeComment();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTagFriend();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBonus();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getImagePath();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string platform = 1;
 * @return {string}
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.getPlatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.CreateBulkOrderDraftRequestData} returns this
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.CreateBulkOrderDraftRequestData} returns this
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 positive_comment = 3;
 * @return {number}
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.getPositiveComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.CreateBulkOrderDraftRequestData} returns this
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.setPositiveComment = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 negative_comment = 4;
 * @return {number}
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.getNegativeComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.CreateBulkOrderDraftRequestData} returns this
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.setNegativeComment = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 tag_friend = 5;
 * @return {number}
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.getTagFriend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.CreateBulkOrderDraftRequestData} returns this
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.setTagFriend = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string remarks = 6;
 * @return {string}
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.CreateBulkOrderDraftRequestData} returns this
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string id = 7;
 * @return {string}
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.CreateBulkOrderDraftRequestData} returns this
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 bonus = 8;
 * @return {number}
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.getBonus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.CreateBulkOrderDraftRequestData} returns this
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.setBonus = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string image_path = 9;
 * @return {string}
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.getImagePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.CreateBulkOrderDraftRequestData} returns this
 */
proto.client_order.CreateBulkOrderDraftRequestData.prototype.setImagePath = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.client_order.CreateBulkOrderDraftRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.CreateBulkOrderDraftRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.CreateBulkOrderDraftRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.CreateBulkOrderDraftRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.CreateBulkOrderDraftRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.client_order.CreateBulkOrderDraftRequestData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.CreateBulkOrderDraftRequest}
 */
proto.client_order.CreateBulkOrderDraftRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.CreateBulkOrderDraftRequest;
  return proto.client_order.CreateBulkOrderDraftRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.CreateBulkOrderDraftRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.CreateBulkOrderDraftRequest}
 */
proto.client_order.CreateBulkOrderDraftRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_order.CreateBulkOrderDraftRequestData;
      reader.readMessage(value,proto.client_order.CreateBulkOrderDraftRequestData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.CreateBulkOrderDraftRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.CreateBulkOrderDraftRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.CreateBulkOrderDraftRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.CreateBulkOrderDraftRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.client_order.CreateBulkOrderDraftRequestData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CreateBulkOrderDraftRequestData data = 1;
 * @return {!Array<!proto.client_order.CreateBulkOrderDraftRequestData>}
 */
proto.client_order.CreateBulkOrderDraftRequest.prototype.getDataList = function() {
  return /** @type{!Array<!proto.client_order.CreateBulkOrderDraftRequestData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.client_order.CreateBulkOrderDraftRequestData, 1));
};


/**
 * @param {!Array<!proto.client_order.CreateBulkOrderDraftRequestData>} value
 * @return {!proto.client_order.CreateBulkOrderDraftRequest} returns this
*/
proto.client_order.CreateBulkOrderDraftRequest.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.client_order.CreateBulkOrderDraftRequestData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.client_order.CreateBulkOrderDraftRequestData}
 */
proto.client_order.CreateBulkOrderDraftRequest.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.client_order.CreateBulkOrderDraftRequestData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.client_order.CreateBulkOrderDraftRequest} returns this
 */
proto.client_order.CreateBulkOrderDraftRequest.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.CreateBulkOrderDraftResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.CreateBulkOrderDraftResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.CreateBulkOrderDraftResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.CreateBulkOrderDraftResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderWorkDayDuration: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.CreateBulkOrderDraftResponseData}
 */
proto.client_order.CreateBulkOrderDraftResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.CreateBulkOrderDraftResponseData;
  return proto.client_order.CreateBulkOrderDraftResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.CreateBulkOrderDraftResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.CreateBulkOrderDraftResponseData}
 */
proto.client_order.CreateBulkOrderDraftResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrderWorkDayDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.CreateBulkOrderDraftResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.CreateBulkOrderDraftResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.CreateBulkOrderDraftResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.CreateBulkOrderDraftResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderWorkDayDuration();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.client_order.CreateBulkOrderDraftResponseData.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.CreateBulkOrderDraftResponseData} returns this
 */
proto.client_order.CreateBulkOrderDraftResponseData.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 order_work_day_duration = 2;
 * @return {number}
 */
proto.client_order.CreateBulkOrderDraftResponseData.prototype.getOrderWorkDayDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.CreateBulkOrderDraftResponseData} returns this
 */
proto.client_order.CreateBulkOrderDraftResponseData.prototype.setOrderWorkDayDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.client_order.CreateBulkOrderDraftResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.CreateBulkOrderDraftResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.CreateBulkOrderDraftResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.CreateBulkOrderDraftResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.CreateBulkOrderDraftResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_order.Meta.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.client_order.CreateBulkOrderDraftResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.CreateBulkOrderDraftResponse}
 */
proto.client_order.CreateBulkOrderDraftResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.CreateBulkOrderDraftResponse;
  return proto.client_order.CreateBulkOrderDraftResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.CreateBulkOrderDraftResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.CreateBulkOrderDraftResponse}
 */
proto.client_order.CreateBulkOrderDraftResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_order.Meta;
      reader.readMessage(value,proto.client_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_order.CreateBulkOrderDraftResponseData;
      reader.readMessage(value,proto.client_order.CreateBulkOrderDraftResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.CreateBulkOrderDraftResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.CreateBulkOrderDraftResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.CreateBulkOrderDraftResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.CreateBulkOrderDraftResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.client_order.CreateBulkOrderDraftResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_order.Meta}
 */
proto.client_order.CreateBulkOrderDraftResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_order.Meta, 1));
};


/**
 * @param {?proto.client_order.Meta|undefined} value
 * @return {!proto.client_order.CreateBulkOrderDraftResponse} returns this
*/
proto.client_order.CreateBulkOrderDraftResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.CreateBulkOrderDraftResponse} returns this
 */
proto.client_order.CreateBulkOrderDraftResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.CreateBulkOrderDraftResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated CreateBulkOrderDraftResponseData data = 2;
 * @return {!Array<!proto.client_order.CreateBulkOrderDraftResponseData>}
 */
proto.client_order.CreateBulkOrderDraftResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.client_order.CreateBulkOrderDraftResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.client_order.CreateBulkOrderDraftResponseData, 2));
};


/**
 * @param {!Array<!proto.client_order.CreateBulkOrderDraftResponseData>} value
 * @return {!proto.client_order.CreateBulkOrderDraftResponse} returns this
*/
proto.client_order.CreateBulkOrderDraftResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.client_order.CreateBulkOrderDraftResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.client_order.CreateBulkOrderDraftResponseData}
 */
proto.client_order.CreateBulkOrderDraftResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.client_order.CreateBulkOrderDraftResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.client_order.CreateBulkOrderDraftResponse} returns this
 */
proto.client_order.CreateBulkOrderDraftResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderDetailRequest}
 */
proto.client_order.GetOrderDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderDetailRequest;
  return proto.client_order.GetOrderDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderDetailRequest}
 */
proto.client_order.GetOrderDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_order.GetOrderDetailRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDetailRequest} returns this
 */
proto.client_order.GetOrderDetailRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderDetailResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderDetailResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderDetailResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDetailResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    platform: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    thumbnailUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    url: jspb.Message.getFieldWithDefault(msg, 6, ""),
    positiveComment: jspb.Message.getFieldWithDefault(msg, 7, 0),
    negativeComment: jspb.Message.getFieldWithDefault(msg, 8, 0),
    tagFriend: jspb.Message.getFieldWithDefault(msg, 9, 0),
    like: jspb.Message.getFieldWithDefault(msg, 10, 0),
    share: jspb.Message.getFieldWithDefault(msg, 11, 0),
    remarks: jspb.Message.getFieldWithDefault(msg, 12, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 13, ""),
    finishedAt: jspb.Message.getFieldWithDefault(msg, 14, ""),
    reportImageUrl: jspb.Message.getFieldWithDefault(msg, 15, ""),
    feedbackRating: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    feedbackComment: jspb.Message.getFieldWithDefault(msg, 17, ""),
    initialImageUrl: jspb.Message.getFieldWithDefault(msg, 18, ""),
    feedbackTime: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderDetailResponseData}
 */
proto.client_order.GetOrderDetailResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderDetailResponseData;
  return proto.client_order.GetOrderDetailResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderDetailResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderDetailResponseData}
 */
proto.client_order.GetOrderDetailResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatform(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositiveComment(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNegativeComment(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTagFriend(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLike(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShare(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinishedAt(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportImageUrl(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFeedbackRating(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedbackComment(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialImageUrl(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedbackTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderDetailResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderDetailResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderDetailResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDetailResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlatform();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getThumbnailUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPositiveComment();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getNegativeComment();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getTagFriend();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getLike();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getShare();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getFinishedAt();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getReportImageUrl();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getFeedbackRating();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getFeedbackComment();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getInitialImageUrl();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getFeedbackTime();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string platform = 2;
 * @return {string}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getPlatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string thumbnail_url = 5;
 * @return {string}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getThumbnailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setThumbnailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string url = 6;
 * @return {string}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 positive_comment = 7;
 * @return {number}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getPositiveComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setPositiveComment = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 negative_comment = 8;
 * @return {number}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getNegativeComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setNegativeComment = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 tag_friend = 9;
 * @return {number}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getTagFriend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setTagFriend = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 like = 10;
 * @return {number}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getLike = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setLike = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 share = 11;
 * @return {number}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getShare = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setShare = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string remarks = 12;
 * @return {string}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string created_at = 13;
 * @return {string}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string finished_at = 14;
 * @return {string}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getFinishedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setFinishedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string report_image_url = 15;
 * @return {string}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getReportImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setReportImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional float feedback_rating = 16;
 * @return {number}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getFeedbackRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setFeedbackRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional string feedback_comment = 17;
 * @return {string}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getFeedbackComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setFeedbackComment = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string initial_image_url = 18;
 * @return {string}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getInitialImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setInitialImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string feedback_time = 19;
 * @return {string}
 */
proto.client_order.GetOrderDetailResponseData.prototype.getFeedbackTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDetailResponseData} returns this
 */
proto.client_order.GetOrderDetailResponseData.prototype.setFeedbackTime = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_order.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.client_order.GetOrderDetailResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderDetailResponse}
 */
proto.client_order.GetOrderDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderDetailResponse;
  return proto.client_order.GetOrderDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderDetailResponse}
 */
proto.client_order.GetOrderDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_order.Meta;
      reader.readMessage(value,proto.client_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_order.GetOrderDetailResponseData;
      reader.readMessage(value,proto.client_order.GetOrderDetailResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.client_order.GetOrderDetailResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_order.Meta}
 */
proto.client_order.GetOrderDetailResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_order.Meta, 1));
};


/**
 * @param {?proto.client_order.Meta|undefined} value
 * @return {!proto.client_order.GetOrderDetailResponse} returns this
*/
proto.client_order.GetOrderDetailResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GetOrderDetailResponse} returns this
 */
proto.client_order.GetOrderDetailResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GetOrderDetailResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetOrderDetailResponseData data = 2;
 * @return {?proto.client_order.GetOrderDetailResponseData}
 */
proto.client_order.GetOrderDetailResponse.prototype.getData = function() {
  return /** @type{?proto.client_order.GetOrderDetailResponseData} */ (
    jspb.Message.getWrapperField(this, proto.client_order.GetOrderDetailResponseData, 2));
};


/**
 * @param {?proto.client_order.GetOrderDetailResponseData|undefined} value
 * @return {!proto.client_order.GetOrderDetailResponse} returns this
*/
proto.client_order.GetOrderDetailResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GetOrderDetailResponse} returns this
 */
proto.client_order.GetOrderDetailResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GetOrderDetailResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderDraftDetailResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderDraftDetailResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDraftDetailResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    platform: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    thumbnailUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    url: jspb.Message.getFieldWithDefault(msg, 6, ""),
    positiveComment: jspb.Message.getFieldWithDefault(msg, 7, 0),
    negativeComment: jspb.Message.getFieldWithDefault(msg, 8, 0),
    tagFriend: jspb.Message.getFieldWithDefault(msg, 9, 0),
    like: jspb.Message.getFieldWithDefault(msg, 10, 0),
    share: jspb.Message.getFieldWithDefault(msg, 11, 0),
    remarks: jspb.Message.getFieldWithDefault(msg, 12, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 13, ""),
    finishedAt: jspb.Message.getFieldWithDefault(msg, 14, ""),
    feedbackTime: jspb.Message.getFieldWithDefault(msg, 15, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderDraftDetailResponseData}
 */
proto.client_order.GetOrderDraftDetailResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderDraftDetailResponseData;
  return proto.client_order.GetOrderDraftDetailResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderDraftDetailResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderDraftDetailResponseData}
 */
proto.client_order.GetOrderDraftDetailResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatform(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositiveComment(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNegativeComment(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTagFriend(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLike(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShare(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinishedAt(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedbackTime(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderDraftDetailResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderDraftDetailResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDraftDetailResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlatform();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getThumbnailUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPositiveComment();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getNegativeComment();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getTagFriend();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getLike();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getShare();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getFinishedAt();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getFeedbackTime();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftDetailResponseData} returns this
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string platform = 2;
 * @return {string}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.getPlatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftDetailResponseData} returns this
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftDetailResponseData} returns this
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftDetailResponseData} returns this
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string thumbnail_url = 5;
 * @return {string}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.getThumbnailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftDetailResponseData} returns this
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.setThumbnailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string url = 6;
 * @return {string}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftDetailResponseData} returns this
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 positive_comment = 7;
 * @return {number}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.getPositiveComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderDraftDetailResponseData} returns this
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.setPositiveComment = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 negative_comment = 8;
 * @return {number}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.getNegativeComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderDraftDetailResponseData} returns this
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.setNegativeComment = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 tag_friend = 9;
 * @return {number}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.getTagFriend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderDraftDetailResponseData} returns this
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.setTagFriend = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 like = 10;
 * @return {number}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.getLike = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderDraftDetailResponseData} returns this
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.setLike = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 share = 11;
 * @return {number}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.getShare = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GetOrderDraftDetailResponseData} returns this
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.setShare = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string remarks = 12;
 * @return {string}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftDetailResponseData} returns this
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string created_at = 13;
 * @return {string}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftDetailResponseData} returns this
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string finished_at = 14;
 * @return {string}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.getFinishedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftDetailResponseData} returns this
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.setFinishedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string feedback_time = 15;
 * @return {string}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.getFeedbackTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftDetailResponseData} returns this
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.setFeedbackTime = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string profile_picture_url = 16;
 * @return {string}
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDraftDetailResponseData} returns this
 */
proto.client_order.GetOrderDraftDetailResponseData.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderDraftDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderDraftDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderDraftDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDraftDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_order.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.client_order.GetOrderDraftDetailResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderDraftDetailResponse}
 */
proto.client_order.GetOrderDraftDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderDraftDetailResponse;
  return proto.client_order.GetOrderDraftDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderDraftDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderDraftDetailResponse}
 */
proto.client_order.GetOrderDraftDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_order.Meta;
      reader.readMessage(value,proto.client_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_order.GetOrderDraftDetailResponseData;
      reader.readMessage(value,proto.client_order.GetOrderDraftDetailResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderDraftDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderDraftDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderDraftDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDraftDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.client_order.GetOrderDraftDetailResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_order.Meta}
 */
proto.client_order.GetOrderDraftDetailResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_order.Meta, 1));
};


/**
 * @param {?proto.client_order.Meta|undefined} value
 * @return {!proto.client_order.GetOrderDraftDetailResponse} returns this
*/
proto.client_order.GetOrderDraftDetailResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GetOrderDraftDetailResponse} returns this
 */
proto.client_order.GetOrderDraftDetailResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GetOrderDraftDetailResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetOrderDraftDetailResponseData data = 2;
 * @return {?proto.client_order.GetOrderDraftDetailResponseData}
 */
proto.client_order.GetOrderDraftDetailResponse.prototype.getData = function() {
  return /** @type{?proto.client_order.GetOrderDraftDetailResponseData} */ (
    jspb.Message.getWrapperField(this, proto.client_order.GetOrderDraftDetailResponseData, 2));
};


/**
 * @param {?proto.client_order.GetOrderDraftDetailResponseData|undefined} value
 * @return {!proto.client_order.GetOrderDraftDetailResponse} returns this
*/
proto.client_order.GetOrderDraftDetailResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GetOrderDraftDetailResponse} returns this
 */
proto.client_order.GetOrderDraftDetailResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GetOrderDraftDetailResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderDiscussionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderDiscussionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderDiscussionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDiscussionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderDiscussionRequest}
 */
proto.client_order.GetOrderDiscussionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderDiscussionRequest;
  return proto.client_order.GetOrderDiscussionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderDiscussionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderDiscussionRequest}
 */
proto.client_order.GetOrderDiscussionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderDiscussionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderDiscussionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderDiscussionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDiscussionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.client_order.GetOrderDiscussionRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDiscussionRequest} returns this
 */
proto.client_order.GetOrderDiscussionRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderDiscussionResponseDataUser.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderDiscussionResponseDataUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderDiscussionResponseDataUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDiscussionResponseDataUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderDiscussionResponseDataUser}
 */
proto.client_order.GetOrderDiscussionResponseDataUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderDiscussionResponseDataUser;
  return proto.client_order.GetOrderDiscussionResponseDataUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderDiscussionResponseDataUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderDiscussionResponseDataUser}
 */
proto.client_order.GetOrderDiscussionResponseDataUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderDiscussionResponseDataUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderDiscussionResponseDataUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderDiscussionResponseDataUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDiscussionResponseDataUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_order.GetOrderDiscussionResponseDataUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDiscussionResponseDataUser} returns this
 */
proto.client_order.GetOrderDiscussionResponseDataUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.client_order.GetOrderDiscussionResponseDataUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDiscussionResponseDataUser} returns this
 */
proto.client_order.GetOrderDiscussionResponseDataUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.client_order.GetOrderDiscussionResponseDataUser.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDiscussionResponseDataUser} returns this
 */
proto.client_order.GetOrderDiscussionResponseDataUser.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderDiscussionResponseDataReply.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderDiscussionResponseDataReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderDiscussionResponseDataReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDiscussionResponseDataReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attachmentUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    selfComment: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    user: (f = msg.getUser()) && proto.client_order.GetOrderDiscussionResponseDataUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderDiscussionResponseDataReply}
 */
proto.client_order.GetOrderDiscussionResponseDataReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderDiscussionResponseDataReply;
  return proto.client_order.GetOrderDiscussionResponseDataReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderDiscussionResponseDataReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderDiscussionResponseDataReply}
 */
proto.client_order.GetOrderDiscussionResponseDataReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentUrl(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelfComment(value);
      break;
    case 6:
      var value = new proto.client_order.GetOrderDiscussionResponseDataUser;
      reader.readMessage(value,proto.client_order.GetOrderDiscussionResponseDataUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderDiscussionResponseDataReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderDiscussionResponseDataReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderDiscussionResponseDataReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDiscussionResponseDataReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttachmentUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSelfComment();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.client_order.GetOrderDiscussionResponseDataUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_order.GetOrderDiscussionResponseDataReply.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.client_order.GetOrderDiscussionResponseDataReply.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.client_order.GetOrderDiscussionResponseDataReply.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.client_order.GetOrderDiscussionResponseDataReply.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.client_order.GetOrderDiscussionResponseDataReply.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.client_order.GetOrderDiscussionResponseDataReply.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string attachment_url = 4;
 * @return {string}
 */
proto.client_order.GetOrderDiscussionResponseDataReply.prototype.getAttachmentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.client_order.GetOrderDiscussionResponseDataReply.prototype.setAttachmentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool self_comment = 5;
 * @return {boolean}
 */
proto.client_order.GetOrderDiscussionResponseDataReply.prototype.getSelfComment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.client_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.client_order.GetOrderDiscussionResponseDataReply.prototype.setSelfComment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional GetOrderDiscussionResponseDataUser user = 6;
 * @return {?proto.client_order.GetOrderDiscussionResponseDataUser}
 */
proto.client_order.GetOrderDiscussionResponseDataReply.prototype.getUser = function() {
  return /** @type{?proto.client_order.GetOrderDiscussionResponseDataUser} */ (
    jspb.Message.getWrapperField(this, proto.client_order.GetOrderDiscussionResponseDataUser, 6));
};


/**
 * @param {?proto.client_order.GetOrderDiscussionResponseDataUser|undefined} value
 * @return {!proto.client_order.GetOrderDiscussionResponseDataReply} returns this
*/
proto.client_order.GetOrderDiscussionResponseDataReply.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.client_order.GetOrderDiscussionResponseDataReply.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GetOrderDiscussionResponseDataReply.prototype.hasUser = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.client_order.GetOrderDiscussionResponseData.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderDiscussionResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderDiscussionResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDiscussionResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attachmentUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    selfComment: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    user: (f = msg.getUser()) && proto.client_order.GetOrderDiscussionResponseDataUser.toObject(includeInstance, f),
    repliesList: jspb.Message.toObjectList(msg.getRepliesList(),
    proto.client_order.GetOrderDiscussionResponseDataReply.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderDiscussionResponseData}
 */
proto.client_order.GetOrderDiscussionResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderDiscussionResponseData;
  return proto.client_order.GetOrderDiscussionResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderDiscussionResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderDiscussionResponseData}
 */
proto.client_order.GetOrderDiscussionResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentUrl(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelfComment(value);
      break;
    case 6:
      var value = new proto.client_order.GetOrderDiscussionResponseDataUser;
      reader.readMessage(value,proto.client_order.GetOrderDiscussionResponseDataUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 7:
      var value = new proto.client_order.GetOrderDiscussionResponseDataReply;
      reader.readMessage(value,proto.client_order.GetOrderDiscussionResponseDataReply.deserializeBinaryFromReader);
      msg.addReplies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderDiscussionResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderDiscussionResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDiscussionResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttachmentUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSelfComment();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.client_order.GetOrderDiscussionResponseDataUser.serializeBinaryToWriter
    );
  }
  f = message.getRepliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.client_order.GetOrderDiscussionResponseDataReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDiscussionResponseData} returns this
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDiscussionResponseData} returns this
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDiscussionResponseData} returns this
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string attachment_url = 4;
 * @return {string}
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.getAttachmentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetOrderDiscussionResponseData} returns this
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.setAttachmentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool self_comment = 5;
 * @return {boolean}
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.getSelfComment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.client_order.GetOrderDiscussionResponseData} returns this
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.setSelfComment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional GetOrderDiscussionResponseDataUser user = 6;
 * @return {?proto.client_order.GetOrderDiscussionResponseDataUser}
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.getUser = function() {
  return /** @type{?proto.client_order.GetOrderDiscussionResponseDataUser} */ (
    jspb.Message.getWrapperField(this, proto.client_order.GetOrderDiscussionResponseDataUser, 6));
};


/**
 * @param {?proto.client_order.GetOrderDiscussionResponseDataUser|undefined} value
 * @return {!proto.client_order.GetOrderDiscussionResponseData} returns this
*/
proto.client_order.GetOrderDiscussionResponseData.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GetOrderDiscussionResponseData} returns this
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.hasUser = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated GetOrderDiscussionResponseDataReply replies = 7;
 * @return {!Array<!proto.client_order.GetOrderDiscussionResponseDataReply>}
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.getRepliesList = function() {
  return /** @type{!Array<!proto.client_order.GetOrderDiscussionResponseDataReply>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.client_order.GetOrderDiscussionResponseDataReply, 7));
};


/**
 * @param {!Array<!proto.client_order.GetOrderDiscussionResponseDataReply>} value
 * @return {!proto.client_order.GetOrderDiscussionResponseData} returns this
*/
proto.client_order.GetOrderDiscussionResponseData.prototype.setRepliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.client_order.GetOrderDiscussionResponseDataReply=} opt_value
 * @param {number=} opt_index
 * @return {!proto.client_order.GetOrderDiscussionResponseDataReply}
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.addReplies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.client_order.GetOrderDiscussionResponseDataReply, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.client_order.GetOrderDiscussionResponseData} returns this
 */
proto.client_order.GetOrderDiscussionResponseData.prototype.clearRepliesList = function() {
  return this.setRepliesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.client_order.GetOrderDiscussionResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetOrderDiscussionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetOrderDiscussionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetOrderDiscussionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDiscussionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_order.Meta.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.client_order.GetOrderDiscussionResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetOrderDiscussionResponse}
 */
proto.client_order.GetOrderDiscussionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetOrderDiscussionResponse;
  return proto.client_order.GetOrderDiscussionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetOrderDiscussionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetOrderDiscussionResponse}
 */
proto.client_order.GetOrderDiscussionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_order.Meta;
      reader.readMessage(value,proto.client_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_order.GetOrderDiscussionResponseData;
      reader.readMessage(value,proto.client_order.GetOrderDiscussionResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetOrderDiscussionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetOrderDiscussionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetOrderDiscussionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetOrderDiscussionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.client_order.GetOrderDiscussionResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_order.Meta}
 */
proto.client_order.GetOrderDiscussionResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_order.Meta, 1));
};


/**
 * @param {?proto.client_order.Meta|undefined} value
 * @return {!proto.client_order.GetOrderDiscussionResponse} returns this
*/
proto.client_order.GetOrderDiscussionResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GetOrderDiscussionResponse} returns this
 */
proto.client_order.GetOrderDiscussionResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GetOrderDiscussionResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated GetOrderDiscussionResponseData data = 2;
 * @return {!Array<!proto.client_order.GetOrderDiscussionResponseData>}
 */
proto.client_order.GetOrderDiscussionResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.client_order.GetOrderDiscussionResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.client_order.GetOrderDiscussionResponseData, 2));
};


/**
 * @param {!Array<!proto.client_order.GetOrderDiscussionResponseData>} value
 * @return {!proto.client_order.GetOrderDiscussionResponse} returns this
*/
proto.client_order.GetOrderDiscussionResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.client_order.GetOrderDiscussionResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.client_order.GetOrderDiscussionResponseData}
 */
proto.client_order.GetOrderDiscussionResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.client_order.GetOrderDiscussionResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.client_order.GetOrderDiscussionResponse} returns this
 */
proto.client_order.GetOrderDiscussionResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GiveOrderFeedbackRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GiveOrderFeedbackRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GiveOrderFeedbackRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GiveOrderFeedbackRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rating: jspb.Message.getFieldWithDefault(msg, 2, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GiveOrderFeedbackRequest}
 */
proto.client_order.GiveOrderFeedbackRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GiveOrderFeedbackRequest;
  return proto.client_order.GiveOrderFeedbackRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GiveOrderFeedbackRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GiveOrderFeedbackRequest}
 */
proto.client_order.GiveOrderFeedbackRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRating(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GiveOrderFeedbackRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GiveOrderFeedbackRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GiveOrderFeedbackRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GiveOrderFeedbackRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRating();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_order.GiveOrderFeedbackRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GiveOrderFeedbackRequest} returns this
 */
proto.client_order.GiveOrderFeedbackRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 rating = 2;
 * @return {number}
 */
proto.client_order.GiveOrderFeedbackRequest.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_order.GiveOrderFeedbackRequest} returns this
 */
proto.client_order.GiveOrderFeedbackRequest.prototype.setRating = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.client_order.GiveOrderFeedbackRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GiveOrderFeedbackRequest} returns this
 */
proto.client_order.GiveOrderFeedbackRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GiveOrderFeedbackResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GiveOrderFeedbackResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GiveOrderFeedbackResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GiveOrderFeedbackResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GiveOrderFeedbackResponseData}
 */
proto.client_order.GiveOrderFeedbackResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GiveOrderFeedbackResponseData;
  return proto.client_order.GiveOrderFeedbackResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GiveOrderFeedbackResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GiveOrderFeedbackResponseData}
 */
proto.client_order.GiveOrderFeedbackResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GiveOrderFeedbackResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GiveOrderFeedbackResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GiveOrderFeedbackResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GiveOrderFeedbackResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GiveOrderFeedbackResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GiveOrderFeedbackResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GiveOrderFeedbackResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GiveOrderFeedbackResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_order.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.client_order.GiveOrderFeedbackResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GiveOrderFeedbackResponse}
 */
proto.client_order.GiveOrderFeedbackResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GiveOrderFeedbackResponse;
  return proto.client_order.GiveOrderFeedbackResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GiveOrderFeedbackResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GiveOrderFeedbackResponse}
 */
proto.client_order.GiveOrderFeedbackResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_order.Meta;
      reader.readMessage(value,proto.client_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_order.GiveOrderFeedbackResponseData;
      reader.readMessage(value,proto.client_order.GiveOrderFeedbackResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GiveOrderFeedbackResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GiveOrderFeedbackResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GiveOrderFeedbackResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GiveOrderFeedbackResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.client_order.GiveOrderFeedbackResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_order.Meta}
 */
proto.client_order.GiveOrderFeedbackResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_order.Meta, 1));
};


/**
 * @param {?proto.client_order.Meta|undefined} value
 * @return {!proto.client_order.GiveOrderFeedbackResponse} returns this
*/
proto.client_order.GiveOrderFeedbackResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GiveOrderFeedbackResponse} returns this
 */
proto.client_order.GiveOrderFeedbackResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GiveOrderFeedbackResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GiveOrderFeedbackResponseData data = 2;
 * @return {?proto.client_order.GiveOrderFeedbackResponseData}
 */
proto.client_order.GiveOrderFeedbackResponse.prototype.getData = function() {
  return /** @type{?proto.client_order.GiveOrderFeedbackResponseData} */ (
    jspb.Message.getWrapperField(this, proto.client_order.GiveOrderFeedbackResponseData, 2));
};


/**
 * @param {?proto.client_order.GiveOrderFeedbackResponseData|undefined} value
 * @return {!proto.client_order.GiveOrderFeedbackResponse} returns this
*/
proto.client_order.GiveOrderFeedbackResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GiveOrderFeedbackResponse} returns this
 */
proto.client_order.GiveOrderFeedbackResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GiveOrderFeedbackResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.SendDiscussionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.SendDiscussionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.SendDiscussionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.SendDiscussionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    parentOrderDiscussionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attachmentPath: jspb.Message.getFieldWithDefault(msg, 4, ""),
    attachmentPathType: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.SendDiscussionRequest}
 */
proto.client_order.SendDiscussionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.SendDiscussionRequest;
  return proto.client_order.SendDiscussionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.SendDiscussionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.SendDiscussionRequest}
 */
proto.client_order.SendDiscussionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentOrderDiscussionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentPath(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentPathType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.SendDiscussionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.SendDiscussionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.SendDiscussionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.SendDiscussionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParentOrderDiscussionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttachmentPath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAttachmentPathType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.client_order.SendDiscussionRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.SendDiscussionRequest} returns this
 */
proto.client_order.SendDiscussionRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string parent_order_discussion_id = 2;
 * @return {string}
 */
proto.client_order.SendDiscussionRequest.prototype.getParentOrderDiscussionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.SendDiscussionRequest} returns this
 */
proto.client_order.SendDiscussionRequest.prototype.setParentOrderDiscussionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.client_order.SendDiscussionRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.SendDiscussionRequest} returns this
 */
proto.client_order.SendDiscussionRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string attachment_path = 4;
 * @return {string}
 */
proto.client_order.SendDiscussionRequest.prototype.getAttachmentPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.SendDiscussionRequest} returns this
 */
proto.client_order.SendDiscussionRequest.prototype.setAttachmentPath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string attachment_path_type = 5;
 * @return {string}
 */
proto.client_order.SendDiscussionRequest.prototype.getAttachmentPathType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.SendDiscussionRequest} returns this
 */
proto.client_order.SendDiscussionRequest.prototype.setAttachmentPathType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.SendDiscussionResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.SendDiscussionResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.SendDiscussionResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.SendDiscussionResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.SendDiscussionResponseData}
 */
proto.client_order.SendDiscussionResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.SendDiscussionResponseData;
  return proto.client_order.SendDiscussionResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.SendDiscussionResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.SendDiscussionResponseData}
 */
proto.client_order.SendDiscussionResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.SendDiscussionResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.SendDiscussionResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.SendDiscussionResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.SendDiscussionResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.SendDiscussionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.SendDiscussionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.SendDiscussionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.SendDiscussionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_order.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.client_order.SendDiscussionResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.SendDiscussionResponse}
 */
proto.client_order.SendDiscussionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.SendDiscussionResponse;
  return proto.client_order.SendDiscussionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.SendDiscussionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.SendDiscussionResponse}
 */
proto.client_order.SendDiscussionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_order.Meta;
      reader.readMessage(value,proto.client_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_order.SendDiscussionResponseData;
      reader.readMessage(value,proto.client_order.SendDiscussionResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.SendDiscussionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.SendDiscussionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.SendDiscussionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.SendDiscussionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.client_order.SendDiscussionResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_order.Meta}
 */
proto.client_order.SendDiscussionResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_order.Meta, 1));
};


/**
 * @param {?proto.client_order.Meta|undefined} value
 * @return {!proto.client_order.SendDiscussionResponse} returns this
*/
proto.client_order.SendDiscussionResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.SendDiscussionResponse} returns this
 */
proto.client_order.SendDiscussionResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.SendDiscussionResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SendDiscussionResponseData data = 2;
 * @return {?proto.client_order.SendDiscussionResponseData}
 */
proto.client_order.SendDiscussionResponse.prototype.getData = function() {
  return /** @type{?proto.client_order.SendDiscussionResponseData} */ (
    jspb.Message.getWrapperField(this, proto.client_order.SendDiscussionResponseData, 2));
};


/**
 * @param {?proto.client_order.SendDiscussionResponseData|undefined} value
 * @return {!proto.client_order.SendDiscussionResponse} returns this
*/
proto.client_order.SendDiscussionResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.SendDiscussionResponse} returns this
 */
proto.client_order.SendDiscussionResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.SendDiscussionResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetSocialMediaPreviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetSocialMediaPreviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetSocialMediaPreviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetSocialMediaPreviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    platform: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetSocialMediaPreviewRequest}
 */
proto.client_order.GetSocialMediaPreviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetSocialMediaPreviewRequest;
  return proto.client_order.GetSocialMediaPreviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetSocialMediaPreviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetSocialMediaPreviewRequest}
 */
proto.client_order.GetSocialMediaPreviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatform(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetSocialMediaPreviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetSocialMediaPreviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetSocialMediaPreviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetSocialMediaPreviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlatform();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.client_order.GetSocialMediaPreviewRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetSocialMediaPreviewRequest} returns this
 */
proto.client_order.GetSocialMediaPreviewRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string platform = 2;
 * @return {string}
 */
proto.client_order.GetSocialMediaPreviewRequest.prototype.getPlatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetSocialMediaPreviewRequest} returns this
 */
proto.client_order.GetSocialMediaPreviewRequest.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetSocialMediaPreviewResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetSocialMediaPreviewResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetSocialMediaPreviewResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetSocialMediaPreviewResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    image: jspb.Message.getFieldWithDefault(msg, 3, ""),
    platform: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetSocialMediaPreviewResponseData}
 */
proto.client_order.GetSocialMediaPreviewResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetSocialMediaPreviewResponseData;
  return proto.client_order.GetSocialMediaPreviewResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetSocialMediaPreviewResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetSocialMediaPreviewResponseData}
 */
proto.client_order.GetSocialMediaPreviewResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatform(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetSocialMediaPreviewResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetSocialMediaPreviewResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetSocialMediaPreviewResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetSocialMediaPreviewResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlatform();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.client_order.GetSocialMediaPreviewResponseData.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetSocialMediaPreviewResponseData} returns this
 */
proto.client_order.GetSocialMediaPreviewResponseData.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.client_order.GetSocialMediaPreviewResponseData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetSocialMediaPreviewResponseData} returns this
 */
proto.client_order.GetSocialMediaPreviewResponseData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image = 3;
 * @return {string}
 */
proto.client_order.GetSocialMediaPreviewResponseData.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetSocialMediaPreviewResponseData} returns this
 */
proto.client_order.GetSocialMediaPreviewResponseData.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string platform = 4;
 * @return {string}
 */
proto.client_order.GetSocialMediaPreviewResponseData.prototype.getPlatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_order.GetSocialMediaPreviewResponseData} returns this
 */
proto.client_order.GetSocialMediaPreviewResponseData.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_order.GetSocialMediaPreviewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_order.GetSocialMediaPreviewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_order.GetSocialMediaPreviewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetSocialMediaPreviewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_order.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.client_order.GetSocialMediaPreviewResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_order.GetSocialMediaPreviewResponse}
 */
proto.client_order.GetSocialMediaPreviewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_order.GetSocialMediaPreviewResponse;
  return proto.client_order.GetSocialMediaPreviewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_order.GetSocialMediaPreviewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_order.GetSocialMediaPreviewResponse}
 */
proto.client_order.GetSocialMediaPreviewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_order.Meta;
      reader.readMessage(value,proto.client_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_order.GetSocialMediaPreviewResponseData;
      reader.readMessage(value,proto.client_order.GetSocialMediaPreviewResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_order.GetSocialMediaPreviewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_order.GetSocialMediaPreviewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_order.GetSocialMediaPreviewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_order.GetSocialMediaPreviewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.client_order.GetSocialMediaPreviewResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_order.Meta}
 */
proto.client_order.GetSocialMediaPreviewResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_order.Meta, 1));
};


/**
 * @param {?proto.client_order.Meta|undefined} value
 * @return {!proto.client_order.GetSocialMediaPreviewResponse} returns this
*/
proto.client_order.GetSocialMediaPreviewResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GetSocialMediaPreviewResponse} returns this
 */
proto.client_order.GetSocialMediaPreviewResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GetSocialMediaPreviewResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetSocialMediaPreviewResponseData data = 2;
 * @return {?proto.client_order.GetSocialMediaPreviewResponseData}
 */
proto.client_order.GetSocialMediaPreviewResponse.prototype.getData = function() {
  return /** @type{?proto.client_order.GetSocialMediaPreviewResponseData} */ (
    jspb.Message.getWrapperField(this, proto.client_order.GetSocialMediaPreviewResponseData, 2));
};


/**
 * @param {?proto.client_order.GetSocialMediaPreviewResponseData|undefined} value
 * @return {!proto.client_order.GetSocialMediaPreviewResponse} returns this
*/
proto.client_order.GetSocialMediaPreviewResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_order.GetSocialMediaPreviewResponse} returns this
 */
proto.client_order.GetSocialMediaPreviewResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_order.GetSocialMediaPreviewResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.client_order);
