import { useContext, useEffect, useState, type FC } from 'react';
import AdminMid from '../../middlewares/AdminMid';
import Back from '../../components/back';
import { LoadingContext } from '../../objects/loading_context';
import { GetProfileResponseData } from '../../grpc/client/client_general_pb';
import { adminUserPackageClient } from '../../App';
import { ApprovePaymentUserPackageRequest, DeclinePaymentUserPackageRequest, GetDetailUserPackageRequest, GetDetailUserPackageResponseData } from '../../grpc/admin/admin_user_package_pb';
import { getToken, initials, money, randomStr } from '../../utils/helper';
import { errorToast, successToast } from '../../utils/helper-ui';
import { useNavigate, useParams } from 'react-router-dom';
import { CANCELED, DECLINED, FAILED, PAID, PENDING_AFFILIATOR, PENDING_COMMENTATOR, PENDING_CONFIRMATION, PENDING_PAYMENT } from '../../utils/contants';
import { Avatar, Drawer, Timeline } from 'rsuite';
import CheckIcon from '@rsuite/icons/legacy/Check';
import moment from 'moment';
import { HiXMark } from 'react-icons/hi2';
import { PiCheckCircle, PiCheckCircleFill, PiCircleBold } from 'react-icons/pi';

interface AdminOrderDetailProps { }

const AdminOrderDetail: FC<AdminOrderDetailProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const [mounted, setMounted] = useState(false);
    const { packageId } = useParams()
    const [data, setData] = useState<GetDetailUserPackageResponseData.AsObject | null | undefined>(null);
    const nav = useNavigate()
    const [modalRejectOpen, setModalRejectOpen] = useState(false);
    const [selectedRemarks, setSelectedRemarks] = useState<string[]>([]);
    const [otherRemarks, setOtherRemarks] = useState("");
    const [modalRejectProcess, setModalRejectProcess] = useState(false);
    const [modalApproveOpen, setModalApproveOpen] = useState(false);


    const remarkList = [
        "Jumlah pembayaran tidak sesuai",
        "Bukti pembayaran tidak terlihat  jelas",
        "Pembayaran tidak diterima",
        "Lainnya"
    ]


    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return
        getDetail()
    }, [mounted]);


    const getDetail = async () => {
        try {
            setIsLoading(false)

            let resp: GetDetailUserPackageResponseData.AsObject = await new Promise((resolve, reject) => {
                let req = new GetDetailUserPackageRequest()
                req.setId(packageId!)
                adminUserPackageClient.getDetailUserPackage(req, getToken(), (err, resp) => {
                    if (err) {
                        reject(err.message)
                        return
                    }
                    resolve(resp.getData()?.toObject()!)
                })
            })
            setData(resp)
        } catch (error) {
            errorToast(`${error}`)
        } finally {
            setIsLoading(false)
        }
    }

    const renderDetail = () => (
        <>
            <div className='p-4 border-b border-b-gray-100'>
                <h5>Detail Pesanan</h5>
            </div>
            <div className='p-4 border-b border-b-gray-100'>
                <div className='flex justify-between py-2'>
                    <span className=''>Komentar per hari</span>
                    <span>{money(data?.commentPerDay)} / Hari</span>
                </div>
                <div className='flex justify-between py-2'>
                    <span className=''>Jumlah Hari</span>
                    <span>{money(30)}</span>
                </div>
                <div className='flex justify-between py-2 border-t'>
                    <span className=' font-bold'>Total Komentar</span>
                    <span>{money(data?.totalComment)}</span>
                </div>
                <div className='flex justify-between py-2'>
                    <span className=' text-sky-500'>+ Bonus Likes</span>
                    <span className='text-sky-500'>{money(data?.totalLikes)}</span>
                </div>
                <div className='flex justify-between py-2'>
                    <span className=' text-sky-500'>+ Bonus Shares</span>
                    <span className='text-sky-500'>{money(data?.totalShares)}</span>
                </div>
                <div className='flex justify-between py-2 border-b'>
                    <span className=' font-bold'>Harga per Komentar</span>
                    <span className=' font-semibold'>Rp. {money(data?.pricePerComment)}</span>
                </div>
                {(data?.adminStatus == PAID || data?.adminStatus == PENDING_CONFIRMATION || (data?.adminStatus == CANCELED || data?.adminStatus == DECLINED || data?.adminStatus == FAILED)) && data.totalPrice ?
                    <div className='flex justify-between py-2'>
                        <span className=' font-bold'>Total </span>
                        <span className=' font-semibold'>Rp. {money(data.totalPrice).split("").slice(0, -3)}<span className=' text-orange-400'>{money(data.totalPrice).split("").slice(Math.max(money(data.totalPrice).split("").length - 3, 1))}</span></span>
                    </div>
                    : null}
                {(data?.adminStatus == PENDING_AFFILIATOR || data?.adminStatus == PENDING_COMMENTATOR) &&
                    <>
                        <div className='flex justify-between py-2 '>
                            <span className=''>Penerimaan</span>
                            <span className=' font-semibold'>Rp. {money(data.totalPrice).split("").slice(0, -3)}<span className=' text-orange-400'>{money(data.totalPrice).split("").slice(Math.max(money(data.totalPrice).split("").length - 3, 1))}</span></span>
                        </div>
                        <div className='flex justify-between py-2 '>
                            <span className=''>Komisi Affiliator</span>
                            <span className=' font-semibold'>Rp. {money(data.affiliatorFee)}</span>
                        </div>
                        <div className='flex justify-between py-2 border-b'>
                            <span className=''>Komisi Komentator</span>
                            <span className=' font-semibold'>Rp. {money(data.commentatorFee)}</span>
                        </div>
                        <div className='flex justify-between py-2 '>
                            <span className=' font-bold'>Sisa</span>
                            <span className=' font-semibold'>Rp. {money(data.adminFee)}</span>
                        </div>
                    </>
                }
            </div></>)

    return (
        <AdminMid header={<div className='w-full px-4 py-2 justify-between items-center flex pt-6 bg-white'>
            <Back />
            <h6>Detail Pesanan</h6>
            <div className='p-2 '></div>
        </div>} onLoadProfile={setProfile} noPadding>

            {(data?.adminStatus == PAID || data?.adminStatus == PENDING_CONFIRMATION || data?.adminStatus == PENDING_AFFILIATOR || data?.adminStatus == PENDING_COMMENTATOR) && <>
                <div className='w-full  bg-slate-200 pt-9 pb-12 px-6 flex justify-between text-white items-center' style={{ backgroundImage: 'url(/assets/bg.png)', backgroundRepeat: 'no-repeat', backgroundPositionX: 0, backgroundPositionY: -100, backgroundSize: 'cover' }}>
                    <div>
                        <h5 className='mb-2'>
                            {data?.adminStatus == PAID && "Pembayaran Berhasil!"}
                            {data?.adminStatus == PENDING_CONFIRMATION && "Pesanan perlu dikonfirmasi"}
                            {data?.adminStatus == PENDING_AFFILIATOR && "Menunggu Affiliator"}
                            {data?.adminStatus == PENDING_COMMENTATOR && "Menunggu Komentator"}
                        </h5>
                        <p className=' text-xs'>
                            {data?.adminStatus == PAID && `Pesanan telah berhasil status akun saat ini Premium selama 30 hari ke depan`}
                            {data?.adminStatus == PENDING_AFFILIATOR && `Menunggu Affiliator Mengambil Pesanan Klien`}
                            {data?.adminStatus == PENDING_COMMENTATOR && `Menunggu Komentator Mengambil Pesanan Klien`}
                            {data?.adminStatus == PENDING_CONFIRMATION && `Mohon konfirmasi sebelum ${moment.utc(data.paymentExpiryTime).format("DD MMMM YYYY")}`}
                        </p>
                    </div>
                    <div className='ml-4'>
                        <img src={data?.adminStatus == PAID ? `/assets/payment_paid.png` : `/assets/payment_pending.png`} alt="" className='w-10' />
                    </div>
                </div>
                <div className=' rounded-t-xl -mt-6 bg-white mb-1'>

                    {renderDetail()}

                </div>
                {data?.adminStatus == PENDING_CONFIRMATION &&
                    <div className=' bg-white mb-1'>
                        <div className='p-4 border-b border-b-gray-100'>
                            <h5>Bukti Pembayaran</h5>
                            <div className='p-8'>
                                <img src={data.receiptUrl} alt="" className='m-auto' />

                            </div>
                        </div>
                    </div>
                }
                {(data?.adminStatus == PENDING_AFFILIATOR || data?.adminStatus == PENDING_COMMENTATOR) &&
                    <>
                        <div className=' bg-white mb-1'>
                            <div className='p-4 border-b'>
                                <span className=' font-bold'>Affiliator</span>
                            </div>
                            <div className='p-4'>
                                {data.affiliator?.id ?
                                    <div className='flex items-center'>
                                        <Avatar size={'sm'} circle src={data.affiliator?.profilePictureUrl} alt={data.affiliator?.profilePictureUrl ? data.affiliator.name : initials(data.affiliator?.name)} />
                                        <p className=' font-semibold ml-2'>{data.affiliator?.name}</p>
                                    </div> : <p className='text-center'>Belum diambil</p>}
                            </div>
                        </div>
                        <div className=' bg-white mb-1'>
                            <div className='p-4 border-b'>
                                <span className=' font-bold'>Komentator</span>
                            </div>
                            <div className='p-4'>
                                <ul>
                                    {data.commentatorsList.map(e => <div className='flex items-center mb-4' key={e.id}>
                                        <Avatar size={'sm'} circle src={e.profilePictureUrl} alt={e.profilePictureUrl ? e.name : initials(e.name)} />
                                        <p className=' font-semibold ml-2'>{e.name}</p>
                                    </div>)}
                                </ul>
                                {data.commentatorsList.length == 0 && <p className='text-center'>Belum diambil</p>}
                            </div>
                        </div>
                    </>
                }
            </>
            }
            {(data?.adminStatus == CANCELED || data?.adminStatus == DECLINED || data?.adminStatus == FAILED) &&
                <div className='p-4 mb-2 bg-white text-center'>
                    <div className=' text-red-500 flex items-center justify-center mb-2'>
                        <img src="/assets/alert.png" alt="" className='w-7 mr-2' />
                        <h5>Pesanan Batal</h5>
                    </div>
                    <p>Pesanan gagal dikarenakan {data?.statusRemarks}</p>
                </div>}
            {(data?.adminStatus == CANCELED || data?.adminStatus == DECLINED || data?.adminStatus == FAILED) &&
                <div className='bg-white mb-2' >{renderDetail()}</div>
            }
            
            <div className=' bg-white mb-1'>

            </div>
            {data?.adminStatus == PENDING_CONFIRMATION &&
                <div className='p-4 grid  grid-cols-2 gap-4'>
                    <button onClick={() => {
                        setModalRejectOpen(true)
                    }} type="button" className="btn-pills ">Tolak</button>
                    <button onClick={() => {
                        setModalApproveOpen(true)
                    }} type="button" className="btn ml-2">Terima</button>
                </div>
            }
            <Drawer className='drawer-rounded' size={"md"} closeButton={null} placement={"bottom"} open={modalRejectOpen} onClose={() => setModalRejectOpen(false)}>
                <Drawer.Title>
                    <div className='flex p-4  justify-between items-center'>
                        <div className='flex   items-center'>
                            <p> Pilih Alasan Penolakan</p>
                        </div>
                        <HiXMark onClick={() => setModalRejectOpen(false)} />
                    </div>
                </Drawer.Title>
                <Drawer.Body style={{ padding: 20 }}>
                    <div className='flex flex-col '>
                        <div className='flex-1'>
                            <ul className='text-base'>
                                {remarkList.map(e => <li className='flex items-center mb-2' key={randomStr(30)}>
                                    <div onClick={() => {
                                        setSelectedRemarks([e])
                                    }}>
                                        {selectedRemarks.includes(e) ? <PiCheckCircleFill className=' text-green-400 mr-2' size={16} /> : <PiCircleBold className=' mr-2' size={16} />}
                                    </div>
                                    {e}

                                </li>)}
                                {selectedRemarks.includes("Lainnya") &&
                                    <li className='pr-4'> <input placeholder='Alasan lainnya ....' className='form-control ml-4' value={otherRemarks} onChange={(el) => {
                                        setOtherRemarks(el.target.value)
                                    }} /></li>
                                }
                            </ul>
                        </div>

                        <div className='mt-8'>
                            <button onClick={() => {
                                setModalRejectOpen(false)
                                setModalRejectProcess(true)
                            }} type="button" className="btn w-full">Konfirmasi</button>
                        </div>
                    </div>
                </Drawer.Body>
            </Drawer>
            <Drawer className='drawer-rounded' size={"md"} closeButton={null} placement={"bottom"} open={modalApproveOpen} onClose={() => setModalApproveOpen(false)}>
                <Drawer.Body style={{ padding: 20 }}>
                    <div className=' rounded-t-xl -mt-6 bg-white mb-1'>
                        <div className='p-4 border-b border-b-gray-100'>
                            <h5>Detail Pesanan</h5>
                        </div>
                        <div className='p-4 border-b border-b-gray-100'>
                            <div className='flex justify-between py-2'>
                                <span className=''>Komentar per hari</span>
                                <span>{money(data?.commentPerDay)} / Hari</span>
                            </div>
                            <div className='flex justify-between py-2'>
                                <span className=''>Jumlah Hari</span>
                                <span>{money(30)}</span>
                            </div>
                            <div className='flex justify-between py-2 border-t'>
                                <span className=' font-bold'>Total Komentar</span>
                                <span>{money(data?.totalComment)}</span>
                            </div>
                            <div className='flex justify-between py-2'>
                                <span className=' text-sky-500'>+ Bonus Likes</span>
                                <span className='text-sky-500'>{money(data?.totalLikes)}</span>
                            </div>
                            <div className='flex justify-between py-2'>
                                <span className=' text-sky-500'>+ Bonus Shares</span>
                                <span className='text-sky-500'>{money(data?.totalShares)}</span>
                            </div>
                            <div className='flex justify-between py-2 border-b'>
                                <span className=' font-bold'>Harga per Komentar</span>
                                <span className=' font-semibold'>Rp. {money(data?.pricePerComment)}</span>
                            </div>
                            <div className='flex justify-between py-2 border-t'>
                                <span className=' font-bold'>Total Slot</span>
                                <span className='font-bold text-xl'>{money(data?.commentatorAccountSlot)}</span>
                            </div>
                            <div className='flex justify-between py-2'>

                            </div>

                            <button className="btn w-full" onClick={() => {
                                let req = new ApprovePaymentUserPackageRequest()
                                req.setUserPackageId(packageId!)

                                adminUserPackageClient.approvePaymentUserPackage(req, getToken(), (err, resp) => {
                                    setIsLoading(false)
                                    if (err) {
                                        errorToast(err.message)
                                        return
                                    }
                                    successToast(`berhasil diterima`)
                                    setModalApproveOpen(false)
                                    getDetail()
                                    nav(`/admin/package/${packageId}/offering`)
                                })
                            }}>Tawarkan Ke Operator</button>
                        </div>
                    </div>
                </Drawer.Body>
            </Drawer>
            <Drawer className='drawer-rounded' size={"md"} closeButton={null} placement={"bottom"} open={modalRejectProcess} onClose={() => setModalRejectProcess(false)}>

                <Drawer.Body style={{ padding: 20 }}>
                    <div className='flex flex-col h-full'>
                        <div className='flex flex-1 flex-col justify-center items-center'>
                            <img src="/assets/alert.png" alt="" className='w-12 mb-8 mt-8' />
                            <p className='text-center text-xl  font-semibold'>Apakah anda sudah yakin?</p>
                            <p className='px-24 text-center'>Mohon cek kembali untuk menghindari kesalahan</p>


                        </div>
                        <div className='flex justify-between mt-4'>
                            <button className='btn-pills' onClick={() => setModalRejectProcess(false)}>Batal</button>
                            <button className='btn-orange' onClick={() => {
                                if (selectedRemarks.length == 0) {
                                    errorToast("Silahkan Pilih Alasan Penolakan")
                                    return
                                }

                                setIsLoading(true)
                                let req = new DeclinePaymentUserPackageRequest()
                                req.setUserPackageId(packageId!)
                                if (selectedRemarks[0] == "Lainnya") {
                                    req.setRemarks(otherRemarks)
                                } else {
                                    req.setRemarks(selectedRemarks[0])
                                }
                                adminUserPackageClient.declinePaymentUserPackage(req, getToken(), (err, resp) => {
                                    setIsLoading(false)
                                    if (err) {
                                        errorToast(err.message)
                                        return
                                    }
                                    successToast(` berhasil ditolak`)
                                    setModalRejectProcess(false)
                                    getDetail()
                                })
                            }}>Ya, Yakin</button>
                        </div>
                    </div>
                </Drawer.Body>
            </Drawer>
        </AdminMid>
    );
}
export default AdminOrderDetail;