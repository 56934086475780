// source: client_general.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.client_general.DeleteUserRequest', null, global);
goog.exportSymbol('proto.client_general.DeleteUserResponse', null, global);
goog.exportSymbol('proto.client_general.DeleteUserResponseData', null, global);
goog.exportSymbol('proto.client_general.GetProfileRequest', null, global);
goog.exportSymbol('proto.client_general.GetProfileResponse', null, global);
goog.exportSymbol('proto.client_general.GetProfileResponseData', null, global);
goog.exportSymbol('proto.client_general.GetProfileResponseDataFinancialAccount', null, global);
goog.exportSymbol('proto.client_general.GetProfileResponseDataToplineUser', null, global);
goog.exportSymbol('proto.client_general.Meta', null, global);
goog.exportSymbol('proto.client_general.Pagination', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_general.Meta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_general.Meta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_general.Meta.displayName = 'proto.client_general.Meta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_general.Pagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_general.Pagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_general.Pagination.displayName = 'proto.client_general.Pagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_general.GetProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_general.GetProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_general.GetProfileRequest.displayName = 'proto.client_general.GetProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_general.GetProfileResponseDataFinancialAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_general.GetProfileResponseDataFinancialAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_general.GetProfileResponseDataFinancialAccount.displayName = 'proto.client_general.GetProfileResponseDataFinancialAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_general.GetProfileResponseDataToplineUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_general.GetProfileResponseDataToplineUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_general.GetProfileResponseDataToplineUser.displayName = 'proto.client_general.GetProfileResponseDataToplineUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_general.GetProfileResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.client_general.GetProfileResponseData.repeatedFields_, null);
};
goog.inherits(proto.client_general.GetProfileResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_general.GetProfileResponseData.displayName = 'proto.client_general.GetProfileResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_general.GetProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_general.GetProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_general.GetProfileResponse.displayName = 'proto.client_general.GetProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_general.DeleteUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_general.DeleteUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_general.DeleteUserRequest.displayName = 'proto.client_general.DeleteUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_general.DeleteUserResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_general.DeleteUserResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_general.DeleteUserResponseData.displayName = 'proto.client_general.DeleteUserResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_general.DeleteUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_general.DeleteUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_general.DeleteUserResponse.displayName = 'proto.client_general.DeleteUserResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_general.Meta.prototype.toObject = function(opt_includeInstance) {
  return proto.client_general.Meta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_general.Meta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.Meta.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_general.Meta}
 */
proto.client_general.Meta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_general.Meta;
  return proto.client_general.Meta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_general.Meta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_general.Meta}
 */
proto.client_general.Meta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_general.Meta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_general.Meta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_general.Meta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.Meta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.client_general.Meta.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.Meta} returns this
 */
proto.client_general.Meta.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 status = 2;
 * @return {number}
 */
proto.client_general.Meta.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_general.Meta} returns this
 */
proto.client_general.Meta.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.client_general.Meta.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.Meta} returns this
 */
proto.client_general.Meta.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_general.Pagination.prototype.toObject = function(opt_includeInstance) {
  return proto.client_general.Pagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_general.Pagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.Pagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    prev: jspb.Message.getFieldWithDefault(msg, 3, 0),
    next: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_general.Pagination}
 */
proto.client_general.Pagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_general.Pagination;
  return proto.client_general.Pagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_general.Pagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_general.Pagination}
 */
proto.client_general.Pagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrev(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNext(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPages(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_general.Pagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_general.Pagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_general.Pagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.Pagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPrev();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNext();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.client_general.Pagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_general.Pagination} returns this
 */
proto.client_general.Pagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.client_general.Pagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_general.Pagination} returns this
 */
proto.client_general.Pagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 prev = 3;
 * @return {number}
 */
proto.client_general.Pagination.prototype.getPrev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_general.Pagination} returns this
 */
proto.client_general.Pagination.prototype.setPrev = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 next = 4;
 * @return {number}
 */
proto.client_general.Pagination.prototype.getNext = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_general.Pagination} returns this
 */
proto.client_general.Pagination.prototype.setNext = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 total_pages = 5;
 * @return {number}
 */
proto.client_general.Pagination.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_general.Pagination} returns this
 */
proto.client_general.Pagination.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 total_records = 6;
 * @return {number}
 */
proto.client_general.Pagination.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_general.Pagination} returns this
 */
proto.client_general.Pagination.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_general.GetProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.client_general.GetProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_general.GetProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.GetProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_general.GetProfileRequest}
 */
proto.client_general.GetProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_general.GetProfileRequest;
  return proto.client_general.GetProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_general.GetProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_general.GetProfileRequest}
 */
proto.client_general.GetProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_general.GetProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_general.GetProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_general.GetProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.GetProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_general.GetProfileResponseDataFinancialAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.client_general.GetProfileResponseDataFinancialAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_general.GetProfileResponseDataFinancialAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.GetProfileResponseDataFinancialAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_general.GetProfileResponseDataFinancialAccount}
 */
proto.client_general.GetProfileResponseDataFinancialAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_general.GetProfileResponseDataFinancialAccount;
  return proto.client_general.GetProfileResponseDataFinancialAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_general.GetProfileResponseDataFinancialAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_general.GetProfileResponseDataFinancialAccount}
 */
proto.client_general.GetProfileResponseDataFinancialAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_general.GetProfileResponseDataFinancialAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_general.GetProfileResponseDataFinancialAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_general.GetProfileResponseDataFinancialAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.GetProfileResponseDataFinancialAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_general.GetProfileResponseDataFinancialAccount.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.GetProfileResponseDataFinancialAccount} returns this
 */
proto.client_general.GetProfileResponseDataFinancialAccount.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.client_general.GetProfileResponseDataFinancialAccount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.GetProfileResponseDataFinancialAccount} returns this
 */
proto.client_general.GetProfileResponseDataFinancialAccount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.client_general.GetProfileResponseDataFinancialAccount.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.GetProfileResponseDataFinancialAccount} returns this
 */
proto.client_general.GetProfileResponseDataFinancialAccount.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_number = 4;
 * @return {string}
 */
proto.client_general.GetProfileResponseDataFinancialAccount.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.GetProfileResponseDataFinancialAccount} returns this
 */
proto.client_general.GetProfileResponseDataFinancialAccount.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_general.GetProfileResponseDataToplineUser.prototype.toObject = function(opt_includeInstance) {
  return proto.client_general.GetProfileResponseDataToplineUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_general.GetProfileResponseDataToplineUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.GetProfileResponseDataToplineUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_general.GetProfileResponseDataToplineUser}
 */
proto.client_general.GetProfileResponseDataToplineUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_general.GetProfileResponseDataToplineUser;
  return proto.client_general.GetProfileResponseDataToplineUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_general.GetProfileResponseDataToplineUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_general.GetProfileResponseDataToplineUser}
 */
proto.client_general.GetProfileResponseDataToplineUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_general.GetProfileResponseDataToplineUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_general.GetProfileResponseDataToplineUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_general.GetProfileResponseDataToplineUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.GetProfileResponseDataToplineUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_general.GetProfileResponseDataToplineUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.GetProfileResponseDataToplineUser} returns this
 */
proto.client_general.GetProfileResponseDataToplineUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.client_general.GetProfileResponseDataToplineUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.GetProfileResponseDataToplineUser} returns this
 */
proto.client_general.GetProfileResponseDataToplineUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.client_general.GetProfileResponseDataToplineUser.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.GetProfileResponseDataToplineUser} returns this
 */
proto.client_general.GetProfileResponseDataToplineUser.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.client_general.GetProfileResponseData.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_general.GetProfileResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_general.GetProfileResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_general.GetProfileResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.GetProfileResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userPackageStatus: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    profilePicturePath: jspb.Message.getFieldWithDefault(msg, 6, ""),
    profilePicturePathType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    pendingWithdrawRequestCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    financialAccountsList: jspb.Message.toObjectList(msg.getFinancialAccountsList(),
    proto.client_general.GetProfileResponseDataFinancialAccount.toObject, includeInstance),
    referralCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    toplineUser: (f = msg.getToplineUser()) && proto.client_general.GetProfileResponseDataToplineUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_general.GetProfileResponseData}
 */
proto.client_general.GetProfileResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_general.GetProfileResponseData;
  return proto.client_general.GetProfileResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_general.GetProfileResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_general.GetProfileResponseData}
 */
proto.client_general.GetProfileResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPackageStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicturePath(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicturePathType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPendingWithdrawRequestCount(value);
      break;
    case 10:
      var value = new proto.client_general.GetProfileResponseDataFinancialAccount;
      reader.readMessage(value,proto.client_general.GetProfileResponseDataFinancialAccount.deserializeBinaryFromReader);
      msg.addFinancialAccounts(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferralCode(value);
      break;
    case 12:
      var value = new proto.client_general.GetProfileResponseDataToplineUser;
      reader.readMessage(value,proto.client_general.GetProfileResponseDataToplineUser.deserializeBinaryFromReader);
      msg.setToplineUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_general.GetProfileResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_general.GetProfileResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_general.GetProfileResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.GetProfileResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserPackageStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProfilePicturePath();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProfilePicturePathType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPendingWithdrawRequestCount();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getFinancialAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.client_general.GetProfileResponseDataFinancialAccount.serializeBinaryToWriter
    );
  }
  f = message.getReferralCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getToplineUser();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.client_general.GetProfileResponseDataToplineUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_general.GetProfileResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.GetProfileResponseData} returns this
 */
proto.client_general.GetProfileResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_package_status = 2;
 * @return {string}
 */
proto.client_general.GetProfileResponseData.prototype.getUserPackageStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.GetProfileResponseData} returns this
 */
proto.client_general.GetProfileResponseData.prototype.setUserPackageStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.client_general.GetProfileResponseData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.GetProfileResponseData} returns this
 */
proto.client_general.GetProfileResponseData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.client_general.GetProfileResponseData.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.GetProfileResponseData} returns this
 */
proto.client_general.GetProfileResponseData.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number = 5;
 * @return {string}
 */
proto.client_general.GetProfileResponseData.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.GetProfileResponseData} returns this
 */
proto.client_general.GetProfileResponseData.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string profile_picture_path = 6;
 * @return {string}
 */
proto.client_general.GetProfileResponseData.prototype.getProfilePicturePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.GetProfileResponseData} returns this
 */
proto.client_general.GetProfileResponseData.prototype.setProfilePicturePath = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string profile_picture_path_type = 7;
 * @return {string}
 */
proto.client_general.GetProfileResponseData.prototype.getProfilePicturePathType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.GetProfileResponseData} returns this
 */
proto.client_general.GetProfileResponseData.prototype.setProfilePicturePathType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string profile_picture_url = 8;
 * @return {string}
 */
proto.client_general.GetProfileResponseData.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.GetProfileResponseData} returns this
 */
proto.client_general.GetProfileResponseData.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional uint32 pending_withdraw_request_count = 9;
 * @return {number}
 */
proto.client_general.GetProfileResponseData.prototype.getPendingWithdrawRequestCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_general.GetProfileResponseData} returns this
 */
proto.client_general.GetProfileResponseData.prototype.setPendingWithdrawRequestCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated GetProfileResponseDataFinancialAccount financial_accounts = 10;
 * @return {!Array<!proto.client_general.GetProfileResponseDataFinancialAccount>}
 */
proto.client_general.GetProfileResponseData.prototype.getFinancialAccountsList = function() {
  return /** @type{!Array<!proto.client_general.GetProfileResponseDataFinancialAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.client_general.GetProfileResponseDataFinancialAccount, 10));
};


/**
 * @param {!Array<!proto.client_general.GetProfileResponseDataFinancialAccount>} value
 * @return {!proto.client_general.GetProfileResponseData} returns this
*/
proto.client_general.GetProfileResponseData.prototype.setFinancialAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.client_general.GetProfileResponseDataFinancialAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.client_general.GetProfileResponseDataFinancialAccount}
 */
proto.client_general.GetProfileResponseData.prototype.addFinancialAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.client_general.GetProfileResponseDataFinancialAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.client_general.GetProfileResponseData} returns this
 */
proto.client_general.GetProfileResponseData.prototype.clearFinancialAccountsList = function() {
  return this.setFinancialAccountsList([]);
};


/**
 * optional string referral_code = 11;
 * @return {string}
 */
proto.client_general.GetProfileResponseData.prototype.getReferralCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_general.GetProfileResponseData} returns this
 */
proto.client_general.GetProfileResponseData.prototype.setReferralCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional GetProfileResponseDataToplineUser topline_user = 12;
 * @return {?proto.client_general.GetProfileResponseDataToplineUser}
 */
proto.client_general.GetProfileResponseData.prototype.getToplineUser = function() {
  return /** @type{?proto.client_general.GetProfileResponseDataToplineUser} */ (
    jspb.Message.getWrapperField(this, proto.client_general.GetProfileResponseDataToplineUser, 12));
};


/**
 * @param {?proto.client_general.GetProfileResponseDataToplineUser|undefined} value
 * @return {!proto.client_general.GetProfileResponseData} returns this
*/
proto.client_general.GetProfileResponseData.prototype.setToplineUser = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_general.GetProfileResponseData} returns this
 */
proto.client_general.GetProfileResponseData.prototype.clearToplineUser = function() {
  return this.setToplineUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_general.GetProfileResponseData.prototype.hasToplineUser = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_general.GetProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_general.GetProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_general.GetProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.GetProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_general.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.client_general.GetProfileResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_general.GetProfileResponse}
 */
proto.client_general.GetProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_general.GetProfileResponse;
  return proto.client_general.GetProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_general.GetProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_general.GetProfileResponse}
 */
proto.client_general.GetProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_general.Meta;
      reader.readMessage(value,proto.client_general.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_general.GetProfileResponseData;
      reader.readMessage(value,proto.client_general.GetProfileResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_general.GetProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_general.GetProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_general.GetProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.GetProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_general.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.client_general.GetProfileResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_general.Meta}
 */
proto.client_general.GetProfileResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_general.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_general.Meta, 1));
};


/**
 * @param {?proto.client_general.Meta|undefined} value
 * @return {!proto.client_general.GetProfileResponse} returns this
*/
proto.client_general.GetProfileResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_general.GetProfileResponse} returns this
 */
proto.client_general.GetProfileResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_general.GetProfileResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetProfileResponseData data = 2;
 * @return {?proto.client_general.GetProfileResponseData}
 */
proto.client_general.GetProfileResponse.prototype.getData = function() {
  return /** @type{?proto.client_general.GetProfileResponseData} */ (
    jspb.Message.getWrapperField(this, proto.client_general.GetProfileResponseData, 2));
};


/**
 * @param {?proto.client_general.GetProfileResponseData|undefined} value
 * @return {!proto.client_general.GetProfileResponse} returns this
*/
proto.client_general.GetProfileResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_general.GetProfileResponse} returns this
 */
proto.client_general.GetProfileResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_general.GetProfileResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_general.DeleteUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.client_general.DeleteUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_general.DeleteUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.DeleteUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_general.DeleteUserRequest}
 */
proto.client_general.DeleteUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_general.DeleteUserRequest;
  return proto.client_general.DeleteUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_general.DeleteUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_general.DeleteUserRequest}
 */
proto.client_general.DeleteUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_general.DeleteUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_general.DeleteUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_general.DeleteUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.DeleteUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_general.DeleteUserResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_general.DeleteUserResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_general.DeleteUserResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.DeleteUserResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_general.DeleteUserResponseData}
 */
proto.client_general.DeleteUserResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_general.DeleteUserResponseData;
  return proto.client_general.DeleteUserResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_general.DeleteUserResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_general.DeleteUserResponseData}
 */
proto.client_general.DeleteUserResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_general.DeleteUserResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_general.DeleteUserResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_general.DeleteUserResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.DeleteUserResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_general.DeleteUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_general.DeleteUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_general.DeleteUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.DeleteUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_general.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.client_general.DeleteUserResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_general.DeleteUserResponse}
 */
proto.client_general.DeleteUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_general.DeleteUserResponse;
  return proto.client_general.DeleteUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_general.DeleteUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_general.DeleteUserResponse}
 */
proto.client_general.DeleteUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_general.Meta;
      reader.readMessage(value,proto.client_general.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_general.DeleteUserResponseData;
      reader.readMessage(value,proto.client_general.DeleteUserResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_general.DeleteUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_general.DeleteUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_general.DeleteUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_general.DeleteUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_general.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.client_general.DeleteUserResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_general.Meta}
 */
proto.client_general.DeleteUserResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_general.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_general.Meta, 1));
};


/**
 * @param {?proto.client_general.Meta|undefined} value
 * @return {!proto.client_general.DeleteUserResponse} returns this
*/
proto.client_general.DeleteUserResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_general.DeleteUserResponse} returns this
 */
proto.client_general.DeleteUserResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_general.DeleteUserResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeleteUserResponseData data = 2;
 * @return {?proto.client_general.DeleteUserResponseData}
 */
proto.client_general.DeleteUserResponse.prototype.getData = function() {
  return /** @type{?proto.client_general.DeleteUserResponseData} */ (
    jspb.Message.getWrapperField(this, proto.client_general.DeleteUserResponseData, 2));
};


/**
 * @param {?proto.client_general.DeleteUserResponseData|undefined} value
 * @return {!proto.client_general.DeleteUserResponse} returns this
*/
proto.client_general.DeleteUserResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_general.DeleteUserResponse} returns this
 */
proto.client_general.DeleteUserResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_general.DeleteUserResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.client_general);
