import { useContext, useEffect, useState, type FC } from 'react';
import { ORDER_DONE, ORDER_PROCESSING, ORDER_REJECTED, ORDER_REVIEWED } from '../../utils/contants';
import { affiliatorOrderClient } from '../../App';
import { LoadingContext } from '../../objects/loading_context';
import { GetOrderDraftListRequest, GetOrderDraftListResponseData, GetOrderListRequest, GetOrderListResponseData, Pagination } from '../../grpc/affiliator/affiliator_order_pb';
import { errorToast } from '../../utils/helper-ui';
import { getToken, numberToDuration } from '../../utils/helper';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { HiOutlineClock } from 'react-icons/hi2';
import NoData from '../no_data';

interface AffiliatorOrderListProps {
    mode: string
    onClickDetailDraft?: (data: GetOrderDraftListResponseData.AsObject | null) => void
}

const AffiliatorOrderList: FC<AffiliatorOrderListProps> = ({
    mode,
    onClickDetailDraft,
}) => {
    const [mounted, setMounted] = useState(false);
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const nav = useNavigate()
    const [pagination, setPagination] = useState<Pagination.AsObject | null>(null);
    const [data, setData] = useState<GetOrderListResponseData.AsObject[]>([]);
    const [dataDraft, setDataDraft] = useState<GetOrderDraftListResponseData.AsObject[]>([]);
    const [dataDraftRejected, setDataDraftRejected] = useState<GetOrderDraftListResponseData.AsObject[]>([]);


    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return
    }, [mounted]);

    useEffect(() => {
        if (!mounted) return
        getOrder()
    }, [page, limit, mounted]);

    



    const getOrder = () => {
        if (mode == ORDER_REVIEWED) {
            setIsLoading(true)
            let req = new GetOrderDraftListRequest()
            req.setPage(page)
            req.setLimit(limit)
            req.setIsConfirmed(false)

            affiliatorOrderClient.getOrderDraftList(req, getToken(), (err, resp) => {
                setIsLoading(false)
                if (err) {
                    errorToast(err.message)
                    return
                }
                setPagination(resp.getPagination()?.toObject() ?? null)
                setDataDraft([...dataDraft, ...resp.getDataList().map(e => e.toObject())])
            })
        } else if (mode == ORDER_REJECTED) {
            setIsLoading(true)
            let req = new GetOrderDraftListRequest()
            req.setPage(page)
            req.setLimit(limit)
            req.setIsDeclined(true)
            req.setIsConfirmed(true)
            affiliatorOrderClient.getOrderDraftList(req, getToken(), (err, resp) => {
                setIsLoading(false)
                if (err) {
                    errorToast(err.message)
                    return
                }
                setPagination(resp.getPagination()?.toObject() ?? null)
                setDataDraftRejected([...dataDraftRejected, ...resp.getDataList().map(e => e.toObject())])
            })
        } else {
            let req = new GetOrderListRequest()
            req.setPage(page)
            req.setLimit(limit)
            req.setIsFinished(mode == ORDER_DONE)
            
            affiliatorOrderClient.getOrderList(req, getToken(), (err, resp) => {
                setIsLoading(false)
                if (err) {
                    errorToast(err.message)
                    return
                }
                setPagination(resp.getPagination()?.toObject() ?? null)
                setData([...data, ...resp.getDataList().map(e => e.toObject())])
            })

            
        }
    }
    const renderDraft = () => {
        if (dataDraft.length == 0) return <NoData />
        return (
            <div className='  px-4 mt-2'>
                {dataDraft.map(e => {
                    let gradient = ""
                    return (<div className={`p-4 rounded-lg bg-white shadow-md shadow-gray-100 last:mb-0 mb-4 ${gradient}`} key={e.id}>
                        <div className='flex justify-between mb-4'>
                            <div className='flex'>
                                <img onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/assets/logo.svg";
                                }} src={e.user?.profilePictureUrl} alt="" className=" object-cover w-8 h-8 rounded-lg bg-gray-50 mr-2" />
                                <span className=' font-semibold'>{e.user?.name}</span>
                            </div>
                            <p className='text-sky-400'>Perlu Ditinjau</p>
                        </div>
                        <div className='flex'>
                            <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/assets/logo.svg";
                            }} src={e.thumbnailUrl} alt="" className=" object-cover w-16 h-16 rounded-lg bg-gray-50 mr-2 flex" />
                            <div className='flex flex-col '>
                                <h6 className='overflow-hidden w-64 truncate'> {e.title} </h6>
                                <p className='overflow-hidden w-64 truncate'>
                                    {e.description}
                                </p>
                            </div>
                        </div>
                        <hr />
                        <div className='flex justify-end items-center'>
                            <button className='btn' onClick={() => {
                                if (onClickDetailDraft) onClickDetailDraft(e)
                            }}>Lihat</button>
                        </div>

                    </div>)
                })}
            </div>
        );
    }
    const renderDraftRejected = () => {
        if (dataDraftRejected.length == 0) return <NoData />
        return (
            <div className='  px-4 mt-2'>
                {dataDraftRejected.map(e => {
                    let gradient = ""
                    return (<div className={`p-4 rounded-lg bg-white shadow-md shadow-gray-100 last:mb-0 mb-4 ${gradient}`} key={e.id}>
                        <div className='flex justify-between mb-4'>
                            <div className='flex'>
                                <img onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/assets/logo.svg";
                                }} src={e.user?.profilePictureUrl} alt="" className=" object-cover w-8 h-8 rounded-lg bg-gray-50 mr-2" />
                                <span className=' font-semibold'>{e.user?.name}</span>
                            </div>
                            <span className='flex text-orange-400 items-center'>
                                 Ditolak
                            </span>
                        </div>
                        <div className='flex'>
                            <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/assets/logo.svg";
                            }} src={e.thumbnailUrl} alt="" className=" object-cover w-16 h-16 rounded-lg bg-gray-50 mr-2 flex" />
                            <div className='flex flex-col '>
                                <h6 className='overflow-hidden w-64 truncate'> {e.title} </h6>
                                <p className='overflow-hidden w-64 truncate'>
                                    {e.description}
                                </p>
                            </div>
                        </div>
                        <hr />
                        <div className='flex justify-end items-center'>

                            <button className='btn' onClick={() => {
                                if (onClickDetailDraft) onClickDetailDraft(e)
                            }}>Lihat</button>
                        </div>

                    </div>)
                })}
            </div>
        );
    }
    const renderData = () => {
        if (data.length == 0) return <NoData />
        
        return (
            <div className='  px-4 mt-2'>
                {data.map(e => {
                    let hours = moment.utc(e.dueDate).diff(moment.utc(), 'hours', false)
                    let gradient = ""
                    if (mode != ORDER_DONE) {

                        if (hours < 4) {
                            gradient = "from-yellow-100 via-white to-white bg-gradient-to-b"
                        }
                        if (hours < 0) {
                            gradient = "from-red-200 via-white to-white bg-gradient-to-b"
                        }

                    }
                    return (<div className={`p-4 rounded-lg bg-white shadow-md shadow-gray-100 last:mb-0 mb-4 ${gradient}`} key={e.id}>
                        <div className='flex justify-between mb-4'>
                            <div className='flex'>
                                <img onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/assets/logo.svg";
                                }} src={e.user?.profilePictureUrl} alt="" className=" object-cover w-8 h-8 rounded-lg bg-gray-50 mr-2" />
                                <span className=' font-semibold'>{e.user?.name}</span>
                            </div>
                            {mode == ORDER_PROCESSING && <span className='flex text-orange-400 items-center'><HiOutlineClock size={18} className='mr-2' />   {numberToDuration(moment.utc().diff(moment.utc(e.dueDate), 'minutes'))} </span>}
                            {mode == ORDER_DONE && e.isFinished && <span className='flex text-green-400 items-center'>
                                Selesai
                            </span>}
                        </div>
                        <div className='flex'>
                            <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/assets/logo.svg";
                            }} src={e.thumbnailUrl} alt="" className=" object-cover w-16 h-16 rounded-lg bg-gray-50 mr-2 flex" />
                            <div className='flex flex-col '>
                                <h6 className='overflow-hidden w-64 truncate'> {e.title} </h6>
                                <p className='overflow-hidden w-64 truncate'>
                                    {e.description}
                                </p>
                            </div>
                        </div>
                        <hr />
                        <div className='flex justify-end items-center'>

                            <button className='btn' onClick={() => {
                                nav(`/affiliator/order/${e.id}`)
                            }}>Lihat</button>
                        </div>

                    </div>)
                })}
            </div>
        );
    }

    return (
        <div className='min-h-[300px] py-4'>
            {mode == ORDER_REVIEWED && renderDraft() }
            {(mode == ORDER_PROCESSING || mode == ORDER_DONE) && renderData() }
            {mode == ORDER_REJECTED && renderDraftRejected() }

            <div className=' justify-center items-start flex mt-4'>
                {(pagination?.totalPages ?? 0) > 0 && (pagination?.next ?? 0) > page && <button className='btn-white w-48' onClick={() => {
                    setPage(page + 1)
                }}>More</button>}

            </div>

        </div>
    );
}
export default AffiliatorOrderList;