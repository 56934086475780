/**
 * @fileoverview gRPC-Web generated client stub for admin_user_package
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: admin_user_package.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.admin_user_package = require('./admin_user_package_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.admin_user_package.AdminUserPackageHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.admin_user_package.AdminUserPackageHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_user_package.GetListUserPackageRequest,
 *   !proto.admin_user_package.GetListUserPackageResponse>}
 */
const methodDescriptor_AdminUserPackageHandler_GetListUserPackage = new grpc.web.MethodDescriptor(
  '/admin_user_package.AdminUserPackageHandler/GetListUserPackage',
  grpc.web.MethodType.UNARY,
  proto.admin_user_package.GetListUserPackageRequest,
  proto.admin_user_package.GetListUserPackageResponse,
  /**
   * @param {!proto.admin_user_package.GetListUserPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_user_package.GetListUserPackageResponse.deserializeBinary
);


/**
 * @param {!proto.admin_user_package.GetListUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_user_package.GetListUserPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_user_package.GetListUserPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_user_package.AdminUserPackageHandlerClient.prototype.getListUserPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_user_package.AdminUserPackageHandler/GetListUserPackage',
      request,
      metadata || {},
      methodDescriptor_AdminUserPackageHandler_GetListUserPackage,
      callback);
};


/**
 * @param {!proto.admin_user_package.GetListUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_user_package.GetListUserPackageResponse>}
 *     Promise that resolves to the response
 */
proto.admin_user_package.AdminUserPackageHandlerPromiseClient.prototype.getListUserPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_user_package.AdminUserPackageHandler/GetListUserPackage',
      request,
      metadata || {},
      methodDescriptor_AdminUserPackageHandler_GetListUserPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_user_package.ApprovePaymentUserPackageRequest,
 *   !proto.admin_user_package.ApprovePaymentUserPackageResponse>}
 */
const methodDescriptor_AdminUserPackageHandler_ApprovePaymentUserPackage = new grpc.web.MethodDescriptor(
  '/admin_user_package.AdminUserPackageHandler/ApprovePaymentUserPackage',
  grpc.web.MethodType.UNARY,
  proto.admin_user_package.ApprovePaymentUserPackageRequest,
  proto.admin_user_package.ApprovePaymentUserPackageResponse,
  /**
   * @param {!proto.admin_user_package.ApprovePaymentUserPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_user_package.ApprovePaymentUserPackageResponse.deserializeBinary
);


/**
 * @param {!proto.admin_user_package.ApprovePaymentUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_user_package.ApprovePaymentUserPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_user_package.ApprovePaymentUserPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_user_package.AdminUserPackageHandlerClient.prototype.approvePaymentUserPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_user_package.AdminUserPackageHandler/ApprovePaymentUserPackage',
      request,
      metadata || {},
      methodDescriptor_AdminUserPackageHandler_ApprovePaymentUserPackage,
      callback);
};


/**
 * @param {!proto.admin_user_package.ApprovePaymentUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_user_package.ApprovePaymentUserPackageResponse>}
 *     Promise that resolves to the response
 */
proto.admin_user_package.AdminUserPackageHandlerPromiseClient.prototype.approvePaymentUserPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_user_package.AdminUserPackageHandler/ApprovePaymentUserPackage',
      request,
      metadata || {},
      methodDescriptor_AdminUserPackageHandler_ApprovePaymentUserPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_user_package.DeclinePaymentUserPackageRequest,
 *   !proto.admin_user_package.DeclinePaymentUserPackageResponse>}
 */
const methodDescriptor_AdminUserPackageHandler_DeclinePaymentUserPackage = new grpc.web.MethodDescriptor(
  '/admin_user_package.AdminUserPackageHandler/DeclinePaymentUserPackage',
  grpc.web.MethodType.UNARY,
  proto.admin_user_package.DeclinePaymentUserPackageRequest,
  proto.admin_user_package.DeclinePaymentUserPackageResponse,
  /**
   * @param {!proto.admin_user_package.DeclinePaymentUserPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_user_package.DeclinePaymentUserPackageResponse.deserializeBinary
);


/**
 * @param {!proto.admin_user_package.DeclinePaymentUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_user_package.DeclinePaymentUserPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_user_package.DeclinePaymentUserPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_user_package.AdminUserPackageHandlerClient.prototype.declinePaymentUserPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_user_package.AdminUserPackageHandler/DeclinePaymentUserPackage',
      request,
      metadata || {},
      methodDescriptor_AdminUserPackageHandler_DeclinePaymentUserPackage,
      callback);
};


/**
 * @param {!proto.admin_user_package.DeclinePaymentUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_user_package.DeclinePaymentUserPackageResponse>}
 *     Promise that resolves to the response
 */
proto.admin_user_package.AdminUserPackageHandlerPromiseClient.prototype.declinePaymentUserPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_user_package.AdminUserPackageHandler/DeclinePaymentUserPackage',
      request,
      metadata || {},
      methodDescriptor_AdminUserPackageHandler_DeclinePaymentUserPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_user_package.GetDetailUserPackageRequest,
 *   !proto.admin_user_package.GetDetailUserPackageResponse>}
 */
const methodDescriptor_AdminUserPackageHandler_GetDetailUserPackage = new grpc.web.MethodDescriptor(
  '/admin_user_package.AdminUserPackageHandler/GetDetailUserPackage',
  grpc.web.MethodType.UNARY,
  proto.admin_user_package.GetDetailUserPackageRequest,
  proto.admin_user_package.GetDetailUserPackageResponse,
  /**
   * @param {!proto.admin_user_package.GetDetailUserPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_user_package.GetDetailUserPackageResponse.deserializeBinary
);


/**
 * @param {!proto.admin_user_package.GetDetailUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_user_package.GetDetailUserPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_user_package.GetDetailUserPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_user_package.AdminUserPackageHandlerClient.prototype.getDetailUserPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_user_package.AdminUserPackageHandler/GetDetailUserPackage',
      request,
      metadata || {},
      methodDescriptor_AdminUserPackageHandler_GetDetailUserPackage,
      callback);
};


/**
 * @param {!proto.admin_user_package.GetDetailUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_user_package.GetDetailUserPackageResponse>}
 *     Promise that resolves to the response
 */
proto.admin_user_package.AdminUserPackageHandlerPromiseClient.prototype.getDetailUserPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_user_package.AdminUserPackageHandler/GetDetailUserPackage',
      request,
      metadata || {},
      methodDescriptor_AdminUserPackageHandler_GetDetailUserPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_user_package.UserPackageOfferToAffiliatorRequest,
 *   !proto.admin_user_package.UserPackageOfferToAffiliatorResponse>}
 */
const methodDescriptor_AdminUserPackageHandler_UserPackageOfferToAffiliator = new grpc.web.MethodDescriptor(
  '/admin_user_package.AdminUserPackageHandler/UserPackageOfferToAffiliator',
  grpc.web.MethodType.UNARY,
  proto.admin_user_package.UserPackageOfferToAffiliatorRequest,
  proto.admin_user_package.UserPackageOfferToAffiliatorResponse,
  /**
   * @param {!proto.admin_user_package.UserPackageOfferToAffiliatorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_user_package.UserPackageOfferToAffiliatorResponse.deserializeBinary
);


/**
 * @param {!proto.admin_user_package.UserPackageOfferToAffiliatorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_user_package.UserPackageOfferToAffiliatorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_user_package.UserPackageOfferToAffiliatorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_user_package.AdminUserPackageHandlerClient.prototype.userPackageOfferToAffiliator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_user_package.AdminUserPackageHandler/UserPackageOfferToAffiliator',
      request,
      metadata || {},
      methodDescriptor_AdminUserPackageHandler_UserPackageOfferToAffiliator,
      callback);
};


/**
 * @param {!proto.admin_user_package.UserPackageOfferToAffiliatorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_user_package.UserPackageOfferToAffiliatorResponse>}
 *     Promise that resolves to the response
 */
proto.admin_user_package.AdminUserPackageHandlerPromiseClient.prototype.userPackageOfferToAffiliator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_user_package.AdminUserPackageHandler/UserPackageOfferToAffiliator',
      request,
      metadata || {},
      methodDescriptor_AdminUserPackageHandler_UserPackageOfferToAffiliator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_user_package.InactivateUserPackageRequest,
 *   !proto.admin_user_package.InactivateUserPackageResponse>}
 */
const methodDescriptor_AdminUserPackageHandler_InactivateUserPackage = new grpc.web.MethodDescriptor(
  '/admin_user_package.AdminUserPackageHandler/InactivateUserPackage',
  grpc.web.MethodType.UNARY,
  proto.admin_user_package.InactivateUserPackageRequest,
  proto.admin_user_package.InactivateUserPackageResponse,
  /**
   * @param {!proto.admin_user_package.InactivateUserPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_user_package.InactivateUserPackageResponse.deserializeBinary
);


/**
 * @param {!proto.admin_user_package.InactivateUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_user_package.InactivateUserPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_user_package.InactivateUserPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_user_package.AdminUserPackageHandlerClient.prototype.inactivateUserPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_user_package.AdminUserPackageHandler/InactivateUserPackage',
      request,
      metadata || {},
      methodDescriptor_AdminUserPackageHandler_InactivateUserPackage,
      callback);
};


/**
 * @param {!proto.admin_user_package.InactivateUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_user_package.InactivateUserPackageResponse>}
 *     Promise that resolves to the response
 */
proto.admin_user_package.AdminUserPackageHandlerPromiseClient.prototype.inactivateUserPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_user_package.AdminUserPackageHandler/InactivateUserPackage',
      request,
      metadata || {},
      methodDescriptor_AdminUserPackageHandler_InactivateUserPackage);
};


module.exports = proto.admin_user_package;

