/**
 * @fileoverview gRPC-Web generated client stub for admin_order
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: admin_order.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.admin_order = require('./admin_order_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.admin_order.AdminOrderHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.admin_order.AdminOrderHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_order.GetOrderListRequest,
 *   !proto.admin_order.GetOrderListResponse>}
 */
const methodDescriptor_AdminOrderHandler_GetOrderList = new grpc.web.MethodDescriptor(
  '/admin_order.AdminOrderHandler/GetOrderList',
  grpc.web.MethodType.UNARY,
  proto.admin_order.GetOrderListRequest,
  proto.admin_order.GetOrderListResponse,
  /**
   * @param {!proto.admin_order.GetOrderListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_order.GetOrderListResponse.deserializeBinary
);


/**
 * @param {!proto.admin_order.GetOrderListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_order.GetOrderListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_order.GetOrderListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_order.AdminOrderHandlerClient.prototype.getOrderList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_order.AdminOrderHandler/GetOrderList',
      request,
      metadata || {},
      methodDescriptor_AdminOrderHandler_GetOrderList,
      callback);
};


/**
 * @param {!proto.admin_order.GetOrderListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_order.GetOrderListResponse>}
 *     Promise that resolves to the response
 */
proto.admin_order.AdminOrderHandlerPromiseClient.prototype.getOrderList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_order.AdminOrderHandler/GetOrderList',
      request,
      metadata || {},
      methodDescriptor_AdminOrderHandler_GetOrderList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_order.GetOrderDetailRequest,
 *   !proto.admin_order.GetOrderDetailResponse>}
 */
const methodDescriptor_AdminOrderHandler_GetOrderDetail = new grpc.web.MethodDescriptor(
  '/admin_order.AdminOrderHandler/GetOrderDetail',
  grpc.web.MethodType.UNARY,
  proto.admin_order.GetOrderDetailRequest,
  proto.admin_order.GetOrderDetailResponse,
  /**
   * @param {!proto.admin_order.GetOrderDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_order.GetOrderDetailResponse.deserializeBinary
);


/**
 * @param {!proto.admin_order.GetOrderDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_order.GetOrderDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_order.GetOrderDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_order.AdminOrderHandlerClient.prototype.getOrderDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_order.AdminOrderHandler/GetOrderDetail',
      request,
      metadata || {},
      methodDescriptor_AdminOrderHandler_GetOrderDetail,
      callback);
};


/**
 * @param {!proto.admin_order.GetOrderDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_order.GetOrderDetailResponse>}
 *     Promise that resolves to the response
 */
proto.admin_order.AdminOrderHandlerPromiseClient.prototype.getOrderDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_order.AdminOrderHandler/GetOrderDetail',
      request,
      metadata || {},
      methodDescriptor_AdminOrderHandler_GetOrderDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_order.GetOrderDiscussionRequest,
 *   !proto.admin_order.GetOrderDiscussionResponse>}
 */
const methodDescriptor_AdminOrderHandler_GetOrderDiscussion = new grpc.web.MethodDescriptor(
  '/admin_order.AdminOrderHandler/GetOrderDiscussion',
  grpc.web.MethodType.UNARY,
  proto.admin_order.GetOrderDiscussionRequest,
  proto.admin_order.GetOrderDiscussionResponse,
  /**
   * @param {!proto.admin_order.GetOrderDiscussionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_order.GetOrderDiscussionResponse.deserializeBinary
);


/**
 * @param {!proto.admin_order.GetOrderDiscussionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_order.GetOrderDiscussionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_order.GetOrderDiscussionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_order.AdminOrderHandlerClient.prototype.getOrderDiscussion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_order.AdminOrderHandler/GetOrderDiscussion',
      request,
      metadata || {},
      methodDescriptor_AdminOrderHandler_GetOrderDiscussion,
      callback);
};


/**
 * @param {!proto.admin_order.GetOrderDiscussionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_order.GetOrderDiscussionResponse>}
 *     Promise that resolves to the response
 */
proto.admin_order.AdminOrderHandlerPromiseClient.prototype.getOrderDiscussion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_order.AdminOrderHandler/GetOrderDiscussion',
      request,
      metadata || {},
      methodDescriptor_AdminOrderHandler_GetOrderDiscussion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_order.SendDiscussionRequest,
 *   !proto.admin_order.SendDiscussionResponse>}
 */
const methodDescriptor_AdminOrderHandler_SendDiscussion = new grpc.web.MethodDescriptor(
  '/admin_order.AdminOrderHandler/SendDiscussion',
  grpc.web.MethodType.UNARY,
  proto.admin_order.SendDiscussionRequest,
  proto.admin_order.SendDiscussionResponse,
  /**
   * @param {!proto.admin_order.SendDiscussionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_order.SendDiscussionResponse.deserializeBinary
);


/**
 * @param {!proto.admin_order.SendDiscussionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_order.SendDiscussionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_order.SendDiscussionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_order.AdminOrderHandlerClient.prototype.sendDiscussion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_order.AdminOrderHandler/SendDiscussion',
      request,
      metadata || {},
      methodDescriptor_AdminOrderHandler_SendDiscussion,
      callback);
};


/**
 * @param {!proto.admin_order.SendDiscussionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_order.SendDiscussionResponse>}
 *     Promise that resolves to the response
 */
proto.admin_order.AdminOrderHandlerPromiseClient.prototype.sendDiscussion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_order.AdminOrderHandler/SendDiscussion',
      request,
      metadata || {},
      methodDescriptor_AdminOrderHandler_SendDiscussion);
};


module.exports = proto.admin_order;

