/**
 * @fileoverview gRPC-Web generated client stub for commentator_withdraw_request
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: commentator_withdraw_request.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.commentator_withdraw_request = require('./commentator_withdraw_request_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.commentator_withdraw_request.CommentatorWithdrawRequestHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.commentator_withdraw_request.CommentatorWithdrawRequestHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_withdraw_request.RequestWithdrawRequest,
 *   !proto.commentator_withdraw_request.RequestWithdrawResponse>}
 */
const methodDescriptor_CommentatorWithdrawRequestHandler_RequestWithdraw = new grpc.web.MethodDescriptor(
  '/commentator_withdraw_request.CommentatorWithdrawRequestHandler/RequestWithdraw',
  grpc.web.MethodType.UNARY,
  proto.commentator_withdraw_request.RequestWithdrawRequest,
  proto.commentator_withdraw_request.RequestWithdrawResponse,
  /**
   * @param {!proto.commentator_withdraw_request.RequestWithdrawRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_withdraw_request.RequestWithdrawResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_withdraw_request.RequestWithdrawRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_withdraw_request.RequestWithdrawResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_withdraw_request.RequestWithdrawResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_withdraw_request.CommentatorWithdrawRequestHandlerClient.prototype.requestWithdraw =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_withdraw_request.CommentatorWithdrawRequestHandler/RequestWithdraw',
      request,
      metadata || {},
      methodDescriptor_CommentatorWithdrawRequestHandler_RequestWithdraw,
      callback);
};


/**
 * @param {!proto.commentator_withdraw_request.RequestWithdrawRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_withdraw_request.RequestWithdrawResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_withdraw_request.CommentatorWithdrawRequestHandlerPromiseClient.prototype.requestWithdraw =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_withdraw_request.CommentatorWithdrawRequestHandler/RequestWithdraw',
      request,
      metadata || {},
      methodDescriptor_CommentatorWithdrawRequestHandler_RequestWithdraw);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_withdraw_request.WithdrawRequestListRequest,
 *   !proto.commentator_withdraw_request.WithdrawRequestListResponse>}
 */
const methodDescriptor_CommentatorWithdrawRequestHandler_WithdrawRequestList = new grpc.web.MethodDescriptor(
  '/commentator_withdraw_request.CommentatorWithdrawRequestHandler/WithdrawRequestList',
  grpc.web.MethodType.UNARY,
  proto.commentator_withdraw_request.WithdrawRequestListRequest,
  proto.commentator_withdraw_request.WithdrawRequestListResponse,
  /**
   * @param {!proto.commentator_withdraw_request.WithdrawRequestListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_withdraw_request.WithdrawRequestListResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_withdraw_request.WithdrawRequestListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_withdraw_request.WithdrawRequestListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_withdraw_request.WithdrawRequestListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_withdraw_request.CommentatorWithdrawRequestHandlerClient.prototype.withdrawRequestList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_withdraw_request.CommentatorWithdrawRequestHandler/WithdrawRequestList',
      request,
      metadata || {},
      methodDescriptor_CommentatorWithdrawRequestHandler_WithdrawRequestList,
      callback);
};


/**
 * @param {!proto.commentator_withdraw_request.WithdrawRequestListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_withdraw_request.WithdrawRequestListResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_withdraw_request.CommentatorWithdrawRequestHandlerPromiseClient.prototype.withdrawRequestList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_withdraw_request.CommentatorWithdrawRequestHandler/WithdrawRequestList',
      request,
      metadata || {},
      methodDescriptor_CommentatorWithdrawRequestHandler_WithdrawRequestList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_withdraw_request.WithdrawRequestDetailRequest,
 *   !proto.commentator_withdraw_request.WithdrawRequestDetailResponse>}
 */
const methodDescriptor_CommentatorWithdrawRequestHandler_WithdrawRequestDetail = new grpc.web.MethodDescriptor(
  '/commentator_withdraw_request.CommentatorWithdrawRequestHandler/WithdrawRequestDetail',
  grpc.web.MethodType.UNARY,
  proto.commentator_withdraw_request.WithdrawRequestDetailRequest,
  proto.commentator_withdraw_request.WithdrawRequestDetailResponse,
  /**
   * @param {!proto.commentator_withdraw_request.WithdrawRequestDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_withdraw_request.WithdrawRequestDetailResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_withdraw_request.WithdrawRequestDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_withdraw_request.WithdrawRequestDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_withdraw_request.WithdrawRequestDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_withdraw_request.CommentatorWithdrawRequestHandlerClient.prototype.withdrawRequestDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_withdraw_request.CommentatorWithdrawRequestHandler/WithdrawRequestDetail',
      request,
      metadata || {},
      methodDescriptor_CommentatorWithdrawRequestHandler_WithdrawRequestDetail,
      callback);
};


/**
 * @param {!proto.commentator_withdraw_request.WithdrawRequestDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_withdraw_request.WithdrawRequestDetailResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_withdraw_request.CommentatorWithdrawRequestHandlerPromiseClient.prototype.withdrawRequestDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_withdraw_request.CommentatorWithdrawRequestHandler/WithdrawRequestDetail',
      request,
      metadata || {},
      methodDescriptor_CommentatorWithdrawRequestHandler_WithdrawRequestDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_withdraw_request.ConfirmWithdrawRequestRequest,
 *   !proto.commentator_withdraw_request.ConfirmWithdrawRequestResponse>}
 */
const methodDescriptor_CommentatorWithdrawRequestHandler_ConfirmWithdrawRequest = new grpc.web.MethodDescriptor(
  '/commentator_withdraw_request.CommentatorWithdrawRequestHandler/ConfirmWithdrawRequest',
  grpc.web.MethodType.UNARY,
  proto.commentator_withdraw_request.ConfirmWithdrawRequestRequest,
  proto.commentator_withdraw_request.ConfirmWithdrawRequestResponse,
  /**
   * @param {!proto.commentator_withdraw_request.ConfirmWithdrawRequestRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_withdraw_request.ConfirmWithdrawRequestResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_withdraw_request.ConfirmWithdrawRequestRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_withdraw_request.ConfirmWithdrawRequestResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_withdraw_request.ConfirmWithdrawRequestResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_withdraw_request.CommentatorWithdrawRequestHandlerClient.prototype.confirmWithdrawRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_withdraw_request.CommentatorWithdrawRequestHandler/ConfirmWithdrawRequest',
      request,
      metadata || {},
      methodDescriptor_CommentatorWithdrawRequestHandler_ConfirmWithdrawRequest,
      callback);
};


/**
 * @param {!proto.commentator_withdraw_request.ConfirmWithdrawRequestRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_withdraw_request.ConfirmWithdrawRequestResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_withdraw_request.CommentatorWithdrawRequestHandlerPromiseClient.prototype.confirmWithdrawRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_withdraw_request.CommentatorWithdrawRequestHandler/ConfirmWithdrawRequest',
      request,
      metadata || {},
      methodDescriptor_CommentatorWithdrawRequestHandler_ConfirmWithdrawRequest);
};


module.exports = proto.commentator_withdraw_request;

