/**
 * @fileoverview gRPC-Web generated client stub for affiliator_user_package
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: affiliator_user_package.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.affiliator_user_package = require('./affiliator_user_package_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.affiliator_user_package.AffiliatorUserPackageHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.affiliator_user_package.AffiliatorUserPackageHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_user_package.GetListUserPackageRequest,
 *   !proto.affiliator_user_package.GetListUserPackageResponse>}
 */
const methodDescriptor_AffiliatorUserPackageHandler_GetListUserPackage = new grpc.web.MethodDescriptor(
  '/affiliator_user_package.AffiliatorUserPackageHandler/GetListUserPackage',
  grpc.web.MethodType.UNARY,
  proto.affiliator_user_package.GetListUserPackageRequest,
  proto.affiliator_user_package.GetListUserPackageResponse,
  /**
   * @param {!proto.affiliator_user_package.GetListUserPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_user_package.GetListUserPackageResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_user_package.GetListUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_user_package.GetListUserPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_user_package.GetListUserPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_user_package.AffiliatorUserPackageHandlerClient.prototype.getListUserPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_user_package.AffiliatorUserPackageHandler/GetListUserPackage',
      request,
      metadata || {},
      methodDescriptor_AffiliatorUserPackageHandler_GetListUserPackage,
      callback);
};


/**
 * @param {!proto.affiliator_user_package.GetListUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_user_package.GetListUserPackageResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_user_package.AffiliatorUserPackageHandlerPromiseClient.prototype.getListUserPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_user_package.AffiliatorUserPackageHandler/GetListUserPackage',
      request,
      metadata || {},
      methodDescriptor_AffiliatorUserPackageHandler_GetListUserPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_user_package.AcceptUserPackageRequest,
 *   !proto.affiliator_user_package.AcceptUserPackageResponse>}
 */
const methodDescriptor_AffiliatorUserPackageHandler_AcceptUserPackage = new grpc.web.MethodDescriptor(
  '/affiliator_user_package.AffiliatorUserPackageHandler/AcceptUserPackage',
  grpc.web.MethodType.UNARY,
  proto.affiliator_user_package.AcceptUserPackageRequest,
  proto.affiliator_user_package.AcceptUserPackageResponse,
  /**
   * @param {!proto.affiliator_user_package.AcceptUserPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_user_package.AcceptUserPackageResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_user_package.AcceptUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_user_package.AcceptUserPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_user_package.AcceptUserPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_user_package.AffiliatorUserPackageHandlerClient.prototype.acceptUserPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_user_package.AffiliatorUserPackageHandler/AcceptUserPackage',
      request,
      metadata || {},
      methodDescriptor_AffiliatorUserPackageHandler_AcceptUserPackage,
      callback);
};


/**
 * @param {!proto.affiliator_user_package.AcceptUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_user_package.AcceptUserPackageResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_user_package.AffiliatorUserPackageHandlerPromiseClient.prototype.acceptUserPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_user_package.AffiliatorUserPackageHandler/AcceptUserPackage',
      request,
      metadata || {},
      methodDescriptor_AffiliatorUserPackageHandler_AcceptUserPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_user_package.GetDetailUserPackageRequest,
 *   !proto.affiliator_user_package.GetDetailUserPackageResponse>}
 */
const methodDescriptor_AffiliatorUserPackageHandler_GetDetailUserPackage = new grpc.web.MethodDescriptor(
  '/affiliator_user_package.AffiliatorUserPackageHandler/GetDetailUserPackage',
  grpc.web.MethodType.UNARY,
  proto.affiliator_user_package.GetDetailUserPackageRequest,
  proto.affiliator_user_package.GetDetailUserPackageResponse,
  /**
   * @param {!proto.affiliator_user_package.GetDetailUserPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_user_package.GetDetailUserPackageResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_user_package.GetDetailUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_user_package.GetDetailUserPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_user_package.GetDetailUserPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_user_package.AffiliatorUserPackageHandlerClient.prototype.getDetailUserPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_user_package.AffiliatorUserPackageHandler/GetDetailUserPackage',
      request,
      metadata || {},
      methodDescriptor_AffiliatorUserPackageHandler_GetDetailUserPackage,
      callback);
};


/**
 * @param {!proto.affiliator_user_package.GetDetailUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_user_package.GetDetailUserPackageResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_user_package.AffiliatorUserPackageHandlerPromiseClient.prototype.getDetailUserPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_user_package.AffiliatorUserPackageHandler/GetDetailUserPackage',
      request,
      metadata || {},
      methodDescriptor_AffiliatorUserPackageHandler_GetDetailUserPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_user_package.UserPackageOfferToCommentatorRequest,
 *   !proto.affiliator_user_package.UserPackageOfferToCommentatorResponse>}
 */
const methodDescriptor_AffiliatorUserPackageHandler_UserPackageOfferToCommentator = new grpc.web.MethodDescriptor(
  '/affiliator_user_package.AffiliatorUserPackageHandler/UserPackageOfferToCommentator',
  grpc.web.MethodType.UNARY,
  proto.affiliator_user_package.UserPackageOfferToCommentatorRequest,
  proto.affiliator_user_package.UserPackageOfferToCommentatorResponse,
  /**
   * @param {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_user_package.UserPackageOfferToCommentatorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_user_package.UserPackageOfferToCommentatorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_user_package.AffiliatorUserPackageHandlerClient.prototype.userPackageOfferToCommentator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_user_package.AffiliatorUserPackageHandler/UserPackageOfferToCommentator',
      request,
      metadata || {},
      methodDescriptor_AffiliatorUserPackageHandler_UserPackageOfferToCommentator,
      callback);
};


/**
 * @param {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_user_package.UserPackageOfferToCommentatorResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_user_package.AffiliatorUserPackageHandlerPromiseClient.prototype.userPackageOfferToCommentator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_user_package.AffiliatorUserPackageHandler/UserPackageOfferToCommentator',
      request,
      metadata || {},
      methodDescriptor_AffiliatorUserPackageHandler_UserPackageOfferToCommentator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_user_package.CancelUserPackageCommentatorRequest,
 *   !proto.affiliator_user_package.CancelUserPackageCommentatorResponse>}
 */
const methodDescriptor_AffiliatorUserPackageHandler_CancelUserPackageCommentator = new grpc.web.MethodDescriptor(
  '/affiliator_user_package.AffiliatorUserPackageHandler/CancelUserPackageCommentator',
  grpc.web.MethodType.UNARY,
  proto.affiliator_user_package.CancelUserPackageCommentatorRequest,
  proto.affiliator_user_package.CancelUserPackageCommentatorResponse,
  /**
   * @param {!proto.affiliator_user_package.CancelUserPackageCommentatorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_user_package.CancelUserPackageCommentatorResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_user_package.CancelUserPackageCommentatorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_user_package.CancelUserPackageCommentatorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_user_package.CancelUserPackageCommentatorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_user_package.AffiliatorUserPackageHandlerClient.prototype.cancelUserPackageCommentator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_user_package.AffiliatorUserPackageHandler/CancelUserPackageCommentator',
      request,
      metadata || {},
      methodDescriptor_AffiliatorUserPackageHandler_CancelUserPackageCommentator,
      callback);
};


/**
 * @param {!proto.affiliator_user_package.CancelUserPackageCommentatorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_user_package.CancelUserPackageCommentatorResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_user_package.AffiliatorUserPackageHandlerPromiseClient.prototype.cancelUserPackageCommentator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_user_package.AffiliatorUserPackageHandler/CancelUserPackageCommentator',
      request,
      metadata || {},
      methodDescriptor_AffiliatorUserPackageHandler_CancelUserPackageCommentator);
};


module.exports = proto.affiliator_user_package;

