import { useContext, type FC } from 'react';
import { LoadingContext } from '../objects/loading_context';

interface LoadingProps {}

const Loading: FC<LoadingProps> = ({}) => {
        const { isLoading, setIsLoading } = useContext(LoadingContext);

        return ( isLoading ?
            <div className="loading" >
                <div className=" h-20 w-20" >
                    <img src="/assets/loading.gif" alt=""  className='w-16 h-16'/>
                </div>
            </div>
       : null );
}
export default Loading;