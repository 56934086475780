import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../pages/NotFound';
import Home from '../pages/client/Home';
import AdminHome from '../pages/admin/AdminHome';
import AdminOrderCommentDetail from '../pages/admin/AdminOrderCommentDetail';
import AdminClient from '../pages/admin/AdminClient';
import AdminAffiliator from '../pages/admin/AdminAffiliator';
import AdminOrder from '../pages/admin/AdminOrder';
import AdminOrderDetail from '../pages/admin/AdminOrderDetail';
import AdminOrderOffering from '../pages/admin/AdminOrderOffering';
import AdminProfile from '../pages/admin/AdminProfile';
import AdminWidthdraw from '../pages/admin/AdminWidthdraw';

interface AdminRoutesProps { }

const AdminRoutes: FC<AdminRoutesProps> = ({ }) => {
    return (
        <Routes>
            <Route path='/' element={<AdminHome />} />
            <Route path='/client' element={<AdminClient />} />
            <Route path='/affiliator' element={<AdminAffiliator />} />
            <Route path='/package' element={<AdminOrder />} />
            <Route path='/package/:packageId' element={<AdminOrderDetail />} />
            <Route path='/package/:packageId/offering' element={<AdminOrderOffering />} />
            <Route path='/order/:orderId' element={<AdminOrderCommentDetail />} />
            <Route path='/profile' element={<AdminProfile />} />
            <Route path='/withdraw' element={<AdminWidthdraw />} />
            
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}
export default AdminRoutes;