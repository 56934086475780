import { useEffect, useState, type FC } from "react";
import { Pagination } from "../grpc/service/general_pb";
import { affiliatorWithdrawClient, commentatorWithdrawClient } from "../App";
import {
  WithdrawRequestListRequest,
  WithdrawRequestListResponseData,
} from "../grpc/commentator/commentator_withdraw_request_pb";
import { errorToast } from "../utils/helper-ui";
import { getToken, money } from "../utils/helper";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import NoData from "./no_data";
import { CONFIRM, DONE, IN_PROGRESS } from "../utils/contants";

interface WithdrawRequestProps {
  isAffiliator?: boolean;
  isCommentator?: boolean;
  isMarketing?: boolean;
  status: string;
}

const WithdrawRequest: FC<WithdrawRequestProps> = ({
  isAffiliator,
  isCommentator,
  isMarketing,
  status,
}) => {
  const [mounted, setMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const nav = useNavigate();
  const [pagination, setPagination] = useState<Pagination.AsObject | null>(
    null
  );
  const [data, setData] = useState<WithdrawRequestListResponseData.AsObject[]>(
    []
  );
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;

    let req = new WithdrawRequestListRequest();
    req.setPage(page);
    req.setLimit(limit);
    req.setStatus(status);
    req.setIsMarketing(isMarketing ?? false);

    if (isCommentator) {
      commentatorWithdrawClient.withdrawRequestList(
        req,
        getToken(),
        (err, resp) => {
          if (err) {
            setIsLoading(false);
            errorToast(err.message);
            return;
          }
          setPagination(resp.getPagination()?.toObject() ?? null);
          setData([...data, ...resp.getDataList().map((e) => e.toObject())]);
          setIsLoading(false);
        }
      );
    }
    if (isAffiliator) {
      affiliatorWithdrawClient.withdrawRequestList(
        req,
        getToken(),
        (err, resp) => {
          if (err) {
            setIsLoading(false);
            errorToast(err.message);
            return;
          }
          setPagination(resp.getPagination()?.toObject() ?? null);
          setData([...data, ...resp.getDataList().map((e) => e.toObject())]);
          setIsLoading(false);
        }
      );
    }
  }, [mounted, page, limit]);

  const renderCard = (e: WithdrawRequestListResponseData.AsObject) => {
    return (
      <div className=" bg-white mb-4 rounded-lg" key={e.id}>
        <div className="p-4">
          <div className="flex justify-between items-center">
            <p className="text-base">Penarikan </p>
            {status == IN_PROGRESS && (
              <p className=" text-sky-400 text-xs">Diproses Admin</p>
            )}
            {status == CONFIRM && (
              <p className=" text-sky-400 text-xs">Konfirmasi fee diterima</p>
            )}
            {status == DONE && (
              <p className=" text-green-400 text-xs">Berhasil</p>
            )}
          </div>
          <div>
            <p className="font-bold">Rp. - {money(e.amount)}</p>
            <p className="text-sm text-gray-400">
              {e.userFinancialAccountType} {e.userFinancialAccountNumber}
            </p>
          </div>
        </div>
        <div className="flex justify-between  px-4 items-center border-b border-b-gray-200"></div>
        <div className="px-4 py-2  flex justify-between gap-4">
          {status == IN_PROGRESS && (
            <p className="text-xs">
              Penarikan ditransfer sebelum{" "}
              <Moment format="DD MMM YYYY, HH:mm">{e.processedDueDate}</Moment>
            </p>
          )}
          {status == CONFIRM && (
            <p className="text-xs">
              Konfirmasi sebelum{" "}
              <Moment format="DD MMM YYYY, HH:mm">{e.confirmationDueDate}</Moment>
            </p>
          )}
          {status == DONE && (
            <p className="text-xs">
              Penarikan fee selesai pada{" "}
              <Moment format="DD MMM YYYY, HH:mm">{e.finishedAt}</Moment>
            </p>
          )}
          <button
            onClick={() => {
              if (isAffiliator) {
                nav(`/affiliator/withdraw/${e.id}`)
              }
              if (isCommentator) {
                nav(`/commentator/withdraw/${e.id}`)
              }
            }}
            type="button"
            className="btn"
          >
            Lihat Detail
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="p-4 overflow-y-auto">
      {data.length == 0 && <NoData />}
      {data.map(renderCard)}
      <div className=" justify-center items-start flex">
        {(pagination?.totalPages ?? 0) > 0 &&
          (pagination?.next ?? 0) > page && (
            <button
              className="btn-white w-48"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              More
            </button>
          )}
      </div>
    </div>
  );
};
export default WithdrawRequest;
