/**
 * @fileoverview gRPC-Web generated client stub for admin_withdraw_request
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: admin_withdraw_request.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.admin_withdraw_request = require('./admin_withdraw_request_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.admin_withdraw_request.AdminWithdrawRequestHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.admin_withdraw_request.AdminWithdrawRequestHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_withdraw_request.WithdrawRequestListRequest,
 *   !proto.admin_withdraw_request.WithdrawRequestListResponse>}
 */
const methodDescriptor_AdminWithdrawRequestHandler_WithdrawRequestList = new grpc.web.MethodDescriptor(
  '/admin_withdraw_request.AdminWithdrawRequestHandler/WithdrawRequestList',
  grpc.web.MethodType.UNARY,
  proto.admin_withdraw_request.WithdrawRequestListRequest,
  proto.admin_withdraw_request.WithdrawRequestListResponse,
  /**
   * @param {!proto.admin_withdraw_request.WithdrawRequestListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_withdraw_request.WithdrawRequestListResponse.deserializeBinary
);


/**
 * @param {!proto.admin_withdraw_request.WithdrawRequestListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_withdraw_request.WithdrawRequestListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_withdraw_request.WithdrawRequestListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_withdraw_request.AdminWithdrawRequestHandlerClient.prototype.withdrawRequestList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_withdraw_request.AdminWithdrawRequestHandler/WithdrawRequestList',
      request,
      metadata || {},
      methodDescriptor_AdminWithdrawRequestHandler_WithdrawRequestList,
      callback);
};


/**
 * @param {!proto.admin_withdraw_request.WithdrawRequestListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_withdraw_request.WithdrawRequestListResponse>}
 *     Promise that resolves to the response
 */
proto.admin_withdraw_request.AdminWithdrawRequestHandlerPromiseClient.prototype.withdrawRequestList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_withdraw_request.AdminWithdrawRequestHandler/WithdrawRequestList',
      request,
      metadata || {},
      methodDescriptor_AdminWithdrawRequestHandler_WithdrawRequestList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_withdraw_request.WithdrawRequestCountRequest,
 *   !proto.admin_withdraw_request.WithdrawRequestCountResponse>}
 */
const methodDescriptor_AdminWithdrawRequestHandler_WithdrawRequestCount = new grpc.web.MethodDescriptor(
  '/admin_withdraw_request.AdminWithdrawRequestHandler/WithdrawRequestCount',
  grpc.web.MethodType.UNARY,
  proto.admin_withdraw_request.WithdrawRequestCountRequest,
  proto.admin_withdraw_request.WithdrawRequestCountResponse,
  /**
   * @param {!proto.admin_withdraw_request.WithdrawRequestCountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_withdraw_request.WithdrawRequestCountResponse.deserializeBinary
);


/**
 * @param {!proto.admin_withdraw_request.WithdrawRequestCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_withdraw_request.WithdrawRequestCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_withdraw_request.WithdrawRequestCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_withdraw_request.AdminWithdrawRequestHandlerClient.prototype.withdrawRequestCount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_withdraw_request.AdminWithdrawRequestHandler/WithdrawRequestCount',
      request,
      metadata || {},
      methodDescriptor_AdminWithdrawRequestHandler_WithdrawRequestCount,
      callback);
};


/**
 * @param {!proto.admin_withdraw_request.WithdrawRequestCountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_withdraw_request.WithdrawRequestCountResponse>}
 *     Promise that resolves to the response
 */
proto.admin_withdraw_request.AdminWithdrawRequestHandlerPromiseClient.prototype.withdrawRequestCount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_withdraw_request.AdminWithdrawRequestHandler/WithdrawRequestCount',
      request,
      metadata || {},
      methodDescriptor_AdminWithdrawRequestHandler_WithdrawRequestCount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_withdraw_request.HasInProgressWithdrawRequest,
 *   !proto.admin_withdraw_request.HasInProgressWithdrawResponse>}
 */
const methodDescriptor_AdminWithdrawRequestHandler_HasInProgressWithdraw = new grpc.web.MethodDescriptor(
  '/admin_withdraw_request.AdminWithdrawRequestHandler/HasInProgressWithdraw',
  grpc.web.MethodType.UNARY,
  proto.admin_withdraw_request.HasInProgressWithdrawRequest,
  proto.admin_withdraw_request.HasInProgressWithdrawResponse,
  /**
   * @param {!proto.admin_withdraw_request.HasInProgressWithdrawRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_withdraw_request.HasInProgressWithdrawResponse.deserializeBinary
);


/**
 * @param {!proto.admin_withdraw_request.HasInProgressWithdrawRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_withdraw_request.HasInProgressWithdrawResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_withdraw_request.HasInProgressWithdrawResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_withdraw_request.AdminWithdrawRequestHandlerClient.prototype.hasInProgressWithdraw =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_withdraw_request.AdminWithdrawRequestHandler/HasInProgressWithdraw',
      request,
      metadata || {},
      methodDescriptor_AdminWithdrawRequestHandler_HasInProgressWithdraw,
      callback);
};


/**
 * @param {!proto.admin_withdraw_request.HasInProgressWithdrawRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_withdraw_request.HasInProgressWithdrawResponse>}
 *     Promise that resolves to the response
 */
proto.admin_withdraw_request.AdminWithdrawRequestHandlerPromiseClient.prototype.hasInProgressWithdraw =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_withdraw_request.AdminWithdrawRequestHandler/HasInProgressWithdraw',
      request,
      metadata || {},
      methodDescriptor_AdminWithdrawRequestHandler_HasInProgressWithdraw);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_withdraw_request.ApproveWithdrawRequestRequest,
 *   !proto.admin_withdraw_request.ApproveWithdrawRequestResponse>}
 */
const methodDescriptor_AdminWithdrawRequestHandler_ApproveWithdrawRequest = new grpc.web.MethodDescriptor(
  '/admin_withdraw_request.AdminWithdrawRequestHandler/ApproveWithdrawRequest',
  grpc.web.MethodType.UNARY,
  proto.admin_withdraw_request.ApproveWithdrawRequestRequest,
  proto.admin_withdraw_request.ApproveWithdrawRequestResponse,
  /**
   * @param {!proto.admin_withdraw_request.ApproveWithdrawRequestRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_withdraw_request.ApproveWithdrawRequestResponse.deserializeBinary
);


/**
 * @param {!proto.admin_withdraw_request.ApproveWithdrawRequestRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_withdraw_request.ApproveWithdrawRequestResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_withdraw_request.ApproveWithdrawRequestResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_withdraw_request.AdminWithdrawRequestHandlerClient.prototype.approveWithdrawRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_withdraw_request.AdminWithdrawRequestHandler/ApproveWithdrawRequest',
      request,
      metadata || {},
      methodDescriptor_AdminWithdrawRequestHandler_ApproveWithdrawRequest,
      callback);
};


/**
 * @param {!proto.admin_withdraw_request.ApproveWithdrawRequestRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_withdraw_request.ApproveWithdrawRequestResponse>}
 *     Promise that resolves to the response
 */
proto.admin_withdraw_request.AdminWithdrawRequestHandlerPromiseClient.prototype.approveWithdrawRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_withdraw_request.AdminWithdrawRequestHandler/ApproveWithdrawRequest',
      request,
      metadata || {},
      methodDescriptor_AdminWithdrawRequestHandler_ApproveWithdrawRequest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_withdraw_request.DeclineWithdrawRequestRequest,
 *   !proto.admin_withdraw_request.DeclineWithdrawRequestResponse>}
 */
const methodDescriptor_AdminWithdrawRequestHandler_DeclineWithdrawRequest = new grpc.web.MethodDescriptor(
  '/admin_withdraw_request.AdminWithdrawRequestHandler/DeclineWithdrawRequest',
  grpc.web.MethodType.UNARY,
  proto.admin_withdraw_request.DeclineWithdrawRequestRequest,
  proto.admin_withdraw_request.DeclineWithdrawRequestResponse,
  /**
   * @param {!proto.admin_withdraw_request.DeclineWithdrawRequestRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_withdraw_request.DeclineWithdrawRequestResponse.deserializeBinary
);


/**
 * @param {!proto.admin_withdraw_request.DeclineWithdrawRequestRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_withdraw_request.DeclineWithdrawRequestResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_withdraw_request.DeclineWithdrawRequestResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_withdraw_request.AdminWithdrawRequestHandlerClient.prototype.declineWithdrawRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_withdraw_request.AdminWithdrawRequestHandler/DeclineWithdrawRequest',
      request,
      metadata || {},
      methodDescriptor_AdminWithdrawRequestHandler_DeclineWithdrawRequest,
      callback);
};


/**
 * @param {!proto.admin_withdraw_request.DeclineWithdrawRequestRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_withdraw_request.DeclineWithdrawRequestResponse>}
 *     Promise that resolves to the response
 */
proto.admin_withdraw_request.AdminWithdrawRequestHandlerPromiseClient.prototype.declineWithdrawRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_withdraw_request.AdminWithdrawRequestHandler/DeclineWithdrawRequest',
      request,
      metadata || {},
      methodDescriptor_AdminWithdrawRequestHandler_DeclineWithdrawRequest);
};


module.exports = proto.admin_withdraw_request;

