// source: affiliator_commentator.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.affiliator_commentator.GetCommentatorAccountListRequest', null, global);
goog.exportSymbol('proto.affiliator_commentator.GetCommentatorAccountListResponse', null, global);
goog.exportSymbol('proto.affiliator_commentator.GetCommentatorAccountListResponseData', null, global);
goog.exportSymbol('proto.affiliator_commentator.GetCommentatorListRequest', null, global);
goog.exportSymbol('proto.affiliator_commentator.GetCommentatorListResponse', null, global);
goog.exportSymbol('proto.affiliator_commentator.GetCommentatorListResponseData', null, global);
goog.exportSymbol('proto.affiliator_commentator.GetCommentatorTotalRequest', null, global);
goog.exportSymbol('proto.affiliator_commentator.GetCommentatorTotalResponse', null, global);
goog.exportSymbol('proto.affiliator_commentator.GetCommentatorTotalResponseData', null, global);
goog.exportSymbol('proto.affiliator_commentator.Meta', null, global);
goog.exportSymbol('proto.affiliator_commentator.Pagination', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_commentator.Meta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_commentator.Meta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_commentator.Meta.displayName = 'proto.affiliator_commentator.Meta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_commentator.Pagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_commentator.Pagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_commentator.Pagination.displayName = 'proto.affiliator_commentator.Pagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_commentator.GetCommentatorListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_commentator.GetCommentatorListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_commentator.GetCommentatorListRequest.displayName = 'proto.affiliator_commentator.GetCommentatorListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_commentator.GetCommentatorListResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_commentator.GetCommentatorListResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_commentator.GetCommentatorListResponseData.displayName = 'proto.affiliator_commentator.GetCommentatorListResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_commentator.GetCommentatorListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_commentator.GetCommentatorListResponse.repeatedFields_, null);
};
goog.inherits(proto.affiliator_commentator.GetCommentatorListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_commentator.GetCommentatorListResponse.displayName = 'proto.affiliator_commentator.GetCommentatorListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_commentator.GetCommentatorAccountListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_commentator.GetCommentatorAccountListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_commentator.GetCommentatorAccountListRequest.displayName = 'proto.affiliator_commentator.GetCommentatorAccountListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_commentator.GetCommentatorAccountListResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_commentator.GetCommentatorAccountListResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_commentator.GetCommentatorAccountListResponseData.displayName = 'proto.affiliator_commentator.GetCommentatorAccountListResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_commentator.GetCommentatorAccountListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_commentator.GetCommentatorAccountListResponse.repeatedFields_, null);
};
goog.inherits(proto.affiliator_commentator.GetCommentatorAccountListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_commentator.GetCommentatorAccountListResponse.displayName = 'proto.affiliator_commentator.GetCommentatorAccountListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_commentator.GetCommentatorTotalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_commentator.GetCommentatorTotalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_commentator.GetCommentatorTotalRequest.displayName = 'proto.affiliator_commentator.GetCommentatorTotalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_commentator.GetCommentatorTotalResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_commentator.GetCommentatorTotalResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_commentator.GetCommentatorTotalResponseData.displayName = 'proto.affiliator_commentator.GetCommentatorTotalResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_commentator.GetCommentatorTotalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_commentator.GetCommentatorTotalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_commentator.GetCommentatorTotalResponse.displayName = 'proto.affiliator_commentator.GetCommentatorTotalResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_commentator.Meta.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_commentator.Meta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_commentator.Meta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.Meta.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_commentator.Meta}
 */
proto.affiliator_commentator.Meta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_commentator.Meta;
  return proto.affiliator_commentator.Meta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_commentator.Meta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_commentator.Meta}
 */
proto.affiliator_commentator.Meta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_commentator.Meta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_commentator.Meta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_commentator.Meta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.Meta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.affiliator_commentator.Meta.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_commentator.Meta} returns this
 */
proto.affiliator_commentator.Meta.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 status = 2;
 * @return {number}
 */
proto.affiliator_commentator.Meta.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_commentator.Meta} returns this
 */
proto.affiliator_commentator.Meta.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.affiliator_commentator.Meta.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_commentator.Meta} returns this
 */
proto.affiliator_commentator.Meta.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_commentator.Pagination.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_commentator.Pagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_commentator.Pagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.Pagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    prev: jspb.Message.getFieldWithDefault(msg, 3, 0),
    next: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_commentator.Pagination}
 */
proto.affiliator_commentator.Pagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_commentator.Pagination;
  return proto.affiliator_commentator.Pagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_commentator.Pagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_commentator.Pagination}
 */
proto.affiliator_commentator.Pagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrev(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNext(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPages(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_commentator.Pagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_commentator.Pagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_commentator.Pagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.Pagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPrev();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNext();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.affiliator_commentator.Pagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_commentator.Pagination} returns this
 */
proto.affiliator_commentator.Pagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.affiliator_commentator.Pagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_commentator.Pagination} returns this
 */
proto.affiliator_commentator.Pagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 prev = 3;
 * @return {number}
 */
proto.affiliator_commentator.Pagination.prototype.getPrev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_commentator.Pagination} returns this
 */
proto.affiliator_commentator.Pagination.prototype.setPrev = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 next = 4;
 * @return {number}
 */
proto.affiliator_commentator.Pagination.prototype.getNext = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_commentator.Pagination} returns this
 */
proto.affiliator_commentator.Pagination.prototype.setNext = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 total_pages = 5;
 * @return {number}
 */
proto.affiliator_commentator.Pagination.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_commentator.Pagination} returns this
 */
proto.affiliator_commentator.Pagination.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 total_records = 6;
 * @return {number}
 */
proto.affiliator_commentator.Pagination.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_commentator.Pagination} returns this
 */
proto.affiliator_commentator.Pagination.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_commentator.GetCommentatorListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_commentator.GetCommentatorListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_commentator.GetCommentatorListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    includeOffensiveUser: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    minimumCommentatorAccount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_commentator.GetCommentatorListRequest}
 */
proto.affiliator_commentator.GetCommentatorListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_commentator.GetCommentatorListRequest;
  return proto.affiliator_commentator.GetCommentatorListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_commentator.GetCommentatorListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_commentator.GetCommentatorListRequest}
 */
proto.affiliator_commentator.GetCommentatorListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeOffensiveUser(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinimumCommentatorAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_commentator.GetCommentatorListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_commentator.GetCommentatorListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_commentator.GetCommentatorListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getIncludeOffensiveUser();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getMinimumCommentatorAccount();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.affiliator_commentator.GetCommentatorListRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_commentator.GetCommentatorListRequest} returns this
 */
proto.affiliator_commentator.GetCommentatorListRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.affiliator_commentator.GetCommentatorListRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_commentator.GetCommentatorListRequest} returns this
 */
proto.affiliator_commentator.GetCommentatorListRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool include_offensive_user = 3;
 * @return {boolean}
 */
proto.affiliator_commentator.GetCommentatorListRequest.prototype.getIncludeOffensiveUser = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_commentator.GetCommentatorListRequest} returns this
 */
proto.affiliator_commentator.GetCommentatorListRequest.prototype.setIncludeOffensiveUser = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional uint32 minimum_commentator_account = 4;
 * @return {number}
 */
proto.affiliator_commentator.GetCommentatorListRequest.prototype.getMinimumCommentatorAccount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_commentator.GetCommentatorListRequest} returns this
 */
proto.affiliator_commentator.GetCommentatorListRequest.prototype.setMinimumCommentatorAccount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_commentator.GetCommentatorListResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_commentator.GetCommentatorListResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorListResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    totalAccounts: jspb.Message.getFieldWithDefault(msg, 6, 0),
    offenseCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    isBlacklisted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    totalClient: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_commentator.GetCommentatorListResponseData}
 */
proto.affiliator_commentator.GetCommentatorListResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_commentator.GetCommentatorListResponseData;
  return proto.affiliator_commentator.GetCommentatorListResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_commentator.GetCommentatorListResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_commentator.GetCommentatorListResponseData}
 */
proto.affiliator_commentator.GetCommentatorListResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRating(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalAccounts(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOffenseCount(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBlacklisted(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalClient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_commentator.GetCommentatorListResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_commentator.GetCommentatorListResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorListResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getTotalAccounts();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getOffenseCount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getIsBlacklisted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getTotalClient();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_commentator.GetCommentatorListResponseData} returns this
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_commentator.GetCommentatorListResponseData} returns this
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_commentator.GetCommentatorListResponseData} returns this
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_number = 4;
 * @return {string}
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_commentator.GetCommentatorListResponseData} returns this
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float rating = 5;
 * @return {number}
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_commentator.GetCommentatorListResponseData} returns this
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional uint32 total_accounts = 6;
 * @return {number}
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.getTotalAccounts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_commentator.GetCommentatorListResponseData} returns this
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.setTotalAccounts = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 offense_count = 7;
 * @return {number}
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.getOffenseCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_commentator.GetCommentatorListResponseData} returns this
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.setOffenseCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool is_blacklisted = 8;
 * @return {boolean}
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.getIsBlacklisted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_commentator.GetCommentatorListResponseData} returns this
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.setIsBlacklisted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional uint32 total_client = 9;
 * @return {number}
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.getTotalClient = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_commentator.GetCommentatorListResponseData} returns this
 */
proto.affiliator_commentator.GetCommentatorListResponseData.prototype.setTotalClient = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_commentator.GetCommentatorListResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_commentator.GetCommentatorListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_commentator.GetCommentatorListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_commentator.GetCommentatorListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_commentator.Meta.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.affiliator_commentator.Pagination.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.affiliator_commentator.GetCommentatorListResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_commentator.GetCommentatorListResponse}
 */
proto.affiliator_commentator.GetCommentatorListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_commentator.GetCommentatorListResponse;
  return proto.affiliator_commentator.GetCommentatorListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_commentator.GetCommentatorListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_commentator.GetCommentatorListResponse}
 */
proto.affiliator_commentator.GetCommentatorListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_commentator.Meta;
      reader.readMessage(value,proto.affiliator_commentator.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_commentator.Pagination;
      reader.readMessage(value,proto.affiliator_commentator.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.affiliator_commentator.GetCommentatorListResponseData;
      reader.readMessage(value,proto.affiliator_commentator.GetCommentatorListResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_commentator.GetCommentatorListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_commentator.GetCommentatorListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_commentator.GetCommentatorListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_commentator.Meta.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_commentator.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.affiliator_commentator.GetCommentatorListResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_commentator.Meta}
 */
proto.affiliator_commentator.GetCommentatorListResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_commentator.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_commentator.Meta, 1));
};


/**
 * @param {?proto.affiliator_commentator.Meta|undefined} value
 * @return {!proto.affiliator_commentator.GetCommentatorListResponse} returns this
*/
proto.affiliator_commentator.GetCommentatorListResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_commentator.GetCommentatorListResponse} returns this
 */
proto.affiliator_commentator.GetCommentatorListResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_commentator.GetCommentatorListResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.affiliator_commentator.Pagination}
 */
proto.affiliator_commentator.GetCommentatorListResponse.prototype.getPagination = function() {
  return /** @type{?proto.affiliator_commentator.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_commentator.Pagination, 2));
};


/**
 * @param {?proto.affiliator_commentator.Pagination|undefined} value
 * @return {!proto.affiliator_commentator.GetCommentatorListResponse} returns this
*/
proto.affiliator_commentator.GetCommentatorListResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_commentator.GetCommentatorListResponse} returns this
 */
proto.affiliator_commentator.GetCommentatorListResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_commentator.GetCommentatorListResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated GetCommentatorListResponseData data = 3;
 * @return {!Array<!proto.affiliator_commentator.GetCommentatorListResponseData>}
 */
proto.affiliator_commentator.GetCommentatorListResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.affiliator_commentator.GetCommentatorListResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_commentator.GetCommentatorListResponseData, 3));
};


/**
 * @param {!Array<!proto.affiliator_commentator.GetCommentatorListResponseData>} value
 * @return {!proto.affiliator_commentator.GetCommentatorListResponse} returns this
*/
proto.affiliator_commentator.GetCommentatorListResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.affiliator_commentator.GetCommentatorListResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_commentator.GetCommentatorListResponseData}
 */
proto.affiliator_commentator.GetCommentatorListResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.affiliator_commentator.GetCommentatorListResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_commentator.GetCommentatorListResponse} returns this
 */
proto.affiliator_commentator.GetCommentatorListResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_commentator.GetCommentatorAccountListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_commentator.GetCommentatorAccountListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_commentator.GetCommentatorAccountListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorAccountListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    commentatorId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_commentator.GetCommentatorAccountListRequest}
 */
proto.affiliator_commentator.GetCommentatorAccountListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_commentator.GetCommentatorAccountListRequest;
  return proto.affiliator_commentator.GetCommentatorAccountListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_commentator.GetCommentatorAccountListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_commentator.GetCommentatorAccountListRequest}
 */
proto.affiliator_commentator.GetCommentatorAccountListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommentatorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_commentator.GetCommentatorAccountListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_commentator.GetCommentatorAccountListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_commentator.GetCommentatorAccountListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorAccountListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommentatorId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string commentator_id = 1;
 * @return {string}
 */
proto.affiliator_commentator.GetCommentatorAccountListRequest.prototype.getCommentatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_commentator.GetCommentatorAccountListRequest} returns this
 */
proto.affiliator_commentator.GetCommentatorAccountListRequest.prototype.setCommentatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_commentator.GetCommentatorAccountListResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_commentator.GetCommentatorAccountListResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_commentator.GetCommentatorAccountListResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorAccountListResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_commentator.GetCommentatorAccountListResponseData}
 */
proto.affiliator_commentator.GetCommentatorAccountListResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_commentator.GetCommentatorAccountListResponseData;
  return proto.affiliator_commentator.GetCommentatorAccountListResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_commentator.GetCommentatorAccountListResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_commentator.GetCommentatorAccountListResponseData}
 */
proto.affiliator_commentator.GetCommentatorAccountListResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_commentator.GetCommentatorAccountListResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_commentator.GetCommentatorAccountListResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_commentator.GetCommentatorAccountListResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorAccountListResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_commentator.GetCommentatorAccountListResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_commentator.GetCommentatorAccountListResponseData} returns this
 */
proto.affiliator_commentator.GetCommentatorAccountListResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.affiliator_commentator.GetCommentatorAccountListResponseData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_commentator.GetCommentatorAccountListResponseData} returns this
 */
proto.affiliator_commentator.GetCommentatorAccountListResponseData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.affiliator_commentator.GetCommentatorAccountListResponseData.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_commentator.GetCommentatorAccountListResponseData} returns this
 */
proto.affiliator_commentator.GetCommentatorAccountListResponseData.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_commentator.GetCommentatorAccountListResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_commentator.GetCommentatorAccountListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_commentator.GetCommentatorAccountListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_commentator.GetCommentatorAccountListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorAccountListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_commentator.Meta.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.affiliator_commentator.GetCommentatorAccountListResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_commentator.GetCommentatorAccountListResponse}
 */
proto.affiliator_commentator.GetCommentatorAccountListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_commentator.GetCommentatorAccountListResponse;
  return proto.affiliator_commentator.GetCommentatorAccountListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_commentator.GetCommentatorAccountListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_commentator.GetCommentatorAccountListResponse}
 */
proto.affiliator_commentator.GetCommentatorAccountListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_commentator.Meta;
      reader.readMessage(value,proto.affiliator_commentator.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_commentator.GetCommentatorAccountListResponseData;
      reader.readMessage(value,proto.affiliator_commentator.GetCommentatorAccountListResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_commentator.GetCommentatorAccountListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_commentator.GetCommentatorAccountListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_commentator.GetCommentatorAccountListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorAccountListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_commentator.Meta.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.affiliator_commentator.GetCommentatorAccountListResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_commentator.Meta}
 */
proto.affiliator_commentator.GetCommentatorAccountListResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_commentator.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_commentator.Meta, 1));
};


/**
 * @param {?proto.affiliator_commentator.Meta|undefined} value
 * @return {!proto.affiliator_commentator.GetCommentatorAccountListResponse} returns this
*/
proto.affiliator_commentator.GetCommentatorAccountListResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_commentator.GetCommentatorAccountListResponse} returns this
 */
proto.affiliator_commentator.GetCommentatorAccountListResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_commentator.GetCommentatorAccountListResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated GetCommentatorAccountListResponseData data = 2;
 * @return {!Array<!proto.affiliator_commentator.GetCommentatorAccountListResponseData>}
 */
proto.affiliator_commentator.GetCommentatorAccountListResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.affiliator_commentator.GetCommentatorAccountListResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_commentator.GetCommentatorAccountListResponseData, 2));
};


/**
 * @param {!Array<!proto.affiliator_commentator.GetCommentatorAccountListResponseData>} value
 * @return {!proto.affiliator_commentator.GetCommentatorAccountListResponse} returns this
*/
proto.affiliator_commentator.GetCommentatorAccountListResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.affiliator_commentator.GetCommentatorAccountListResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_commentator.GetCommentatorAccountListResponseData}
 */
proto.affiliator_commentator.GetCommentatorAccountListResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.affiliator_commentator.GetCommentatorAccountListResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_commentator.GetCommentatorAccountListResponse} returns this
 */
proto.affiliator_commentator.GetCommentatorAccountListResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_commentator.GetCommentatorTotalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_commentator.GetCommentatorTotalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_commentator.GetCommentatorTotalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorTotalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_commentator.GetCommentatorTotalRequest}
 */
proto.affiliator_commentator.GetCommentatorTotalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_commentator.GetCommentatorTotalRequest;
  return proto.affiliator_commentator.GetCommentatorTotalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_commentator.GetCommentatorTotalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_commentator.GetCommentatorTotalRequest}
 */
proto.affiliator_commentator.GetCommentatorTotalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_commentator.GetCommentatorTotalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_commentator.GetCommentatorTotalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_commentator.GetCommentatorTotalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorTotalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_commentator.GetCommentatorTotalResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_commentator.GetCommentatorTotalResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_commentator.GetCommentatorTotalResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorTotalResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCommentator: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalCommentatorAccount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_commentator.GetCommentatorTotalResponseData}
 */
proto.affiliator_commentator.GetCommentatorTotalResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_commentator.GetCommentatorTotalResponseData;
  return proto.affiliator_commentator.GetCommentatorTotalResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_commentator.GetCommentatorTotalResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_commentator.GetCommentatorTotalResponseData}
 */
proto.affiliator_commentator.GetCommentatorTotalResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalCommentator(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalCommentatorAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_commentator.GetCommentatorTotalResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_commentator.GetCommentatorTotalResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_commentator.GetCommentatorTotalResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorTotalResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCommentator();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTotalCommentatorAccount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 total_commentator = 1;
 * @return {number}
 */
proto.affiliator_commentator.GetCommentatorTotalResponseData.prototype.getTotalCommentator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_commentator.GetCommentatorTotalResponseData} returns this
 */
proto.affiliator_commentator.GetCommentatorTotalResponseData.prototype.setTotalCommentator = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 total_commentator_account = 2;
 * @return {number}
 */
proto.affiliator_commentator.GetCommentatorTotalResponseData.prototype.getTotalCommentatorAccount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_commentator.GetCommentatorTotalResponseData} returns this
 */
proto.affiliator_commentator.GetCommentatorTotalResponseData.prototype.setTotalCommentatorAccount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_commentator.GetCommentatorTotalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_commentator.GetCommentatorTotalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_commentator.GetCommentatorTotalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorTotalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_commentator.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.affiliator_commentator.GetCommentatorTotalResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_commentator.GetCommentatorTotalResponse}
 */
proto.affiliator_commentator.GetCommentatorTotalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_commentator.GetCommentatorTotalResponse;
  return proto.affiliator_commentator.GetCommentatorTotalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_commentator.GetCommentatorTotalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_commentator.GetCommentatorTotalResponse}
 */
proto.affiliator_commentator.GetCommentatorTotalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_commentator.Meta;
      reader.readMessage(value,proto.affiliator_commentator.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_commentator.GetCommentatorTotalResponseData;
      reader.readMessage(value,proto.affiliator_commentator.GetCommentatorTotalResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_commentator.GetCommentatorTotalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_commentator.GetCommentatorTotalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_commentator.GetCommentatorTotalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_commentator.GetCommentatorTotalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_commentator.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_commentator.GetCommentatorTotalResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_commentator.Meta}
 */
proto.affiliator_commentator.GetCommentatorTotalResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_commentator.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_commentator.Meta, 1));
};


/**
 * @param {?proto.affiliator_commentator.Meta|undefined} value
 * @return {!proto.affiliator_commentator.GetCommentatorTotalResponse} returns this
*/
proto.affiliator_commentator.GetCommentatorTotalResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_commentator.GetCommentatorTotalResponse} returns this
 */
proto.affiliator_commentator.GetCommentatorTotalResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_commentator.GetCommentatorTotalResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetCommentatorTotalResponseData data = 2;
 * @return {?proto.affiliator_commentator.GetCommentatorTotalResponseData}
 */
proto.affiliator_commentator.GetCommentatorTotalResponse.prototype.getData = function() {
  return /** @type{?proto.affiliator_commentator.GetCommentatorTotalResponseData} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_commentator.GetCommentatorTotalResponseData, 2));
};


/**
 * @param {?proto.affiliator_commentator.GetCommentatorTotalResponseData|undefined} value
 * @return {!proto.affiliator_commentator.GetCommentatorTotalResponse} returns this
*/
proto.affiliator_commentator.GetCommentatorTotalResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_commentator.GetCommentatorTotalResponse} returns this
 */
proto.affiliator_commentator.GetCommentatorTotalResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_commentator.GetCommentatorTotalResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.affiliator_commentator);
