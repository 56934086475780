import { useContext, type FC } from 'react';
import { LoadingContext } from '../objects/loading_context';

interface LoadingComponentProps {
    isLoading: boolean
}

const LoadingComponent: FC<LoadingComponentProps> = ({isLoading}) => {

        return ( isLoading ?
            <div className="p-8 m-auto flex justify-center items-center" >
                <div className=" h-20 w-20" >
                    <img src="/assets/loading.gif" alt=""  className='w-16 h-16'/>
                </div>
            </div>
       : null );
}
export default LoadingComponent;