import { useContext, useState, type FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../components/loading';
import { HiOutlineEye } from 'react-icons/hi2';
import { LoadingContext } from '../objects/loading_context';
import { authClient } from '../App';
import { ChangePasswordRequest } from '../grpc/auth/auth_pb';
import { errorToast, successToast } from '../utils/helper-ui';
import { getToken } from '../utils/helper';

interface ChangePasswordProps { }

const ChangePassword: FC<ChangePasswordProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const nav = useNavigate()

    const { userId } = useParams()
    const [password, setPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [confirm, setConfirm] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const changePassword = () => {
        if (password != confirm && password.length > 0) {
            errorToast("Password konfirmasi tidak sama")
            return
        }
        
        setIsLoading(true)

        let req = new ChangePasswordRequest()
        req.setUserId(userId!)
        req.setNewPassword(password)
        req.setOtp(otp)

        authClient.changePassword(req, getToken(), (err, resp) => {
            if (err) {
                errorToast(err.message)
                setIsLoading(false)
                return
            }
            setIsLoading(false)
            successToast('Password berhasil diupdate, silakan login kembali')
            setTimeout(() => {
                nav('/login')
            }, 1000);
        })
    }

    return (
        <div className='h-screen w-full'>
            <Loading />
            <div className='flex flex-col p-4  w-full'>
                <img src={"/assets/logo.svg"} alt="" className=' w-24 mx-auto mt-12 ' />
                <h3 className='text-center font-bold text-xl mb-8'><span className=' text-sky-500 mr-1 mb-4'>Grosir</span><span>Komen</span></h3>
                <h3 className='font-bold mb-0 text-sky-500' >Perbaharui Password</h3>
                <p>Silahkan masukan kode OTP dan Password Baru</p>
                <label className='font-bold mb-1 px-1 mt-4' htmlFor="">Kode OTP</label>
                <div className=' relative'>
                    <input value={otp} max={6} type='number' placeholder="ex: 123456" className='form-control mb-4' onChange={(el) => setOtp(el.currentTarget.value)} />
                </div>


                <label className='font-bold mb-1 px-1' htmlFor="">Password</label>
                <div className=' relative'>
                    <input value={password} type={showPassword ? 'text' : 'password'} placeholder="Password" className='form-control mb-4' onChange={(el) => setPassword(el.currentTarget.value)} />
                    <HiOutlineEye onClick={() => setShowPassword(!showPassword)} size={20} className={`${showPassword ? 'text-gray-600' : 'text-gray-300'}  absolute top-2 right-2`} />
                </div>
                <label className='font-bold mb-1 px-1' htmlFor="">Ulangi Password</label>
                <div className=' relative'>
                    <input value={confirm} type={showPassword ? 'text' : 'password'} placeholder="Ulangi Password" className='form-control mb-4' onChange={(el) => setConfirm(el.currentTarget.value)} />
                    <HiOutlineEye onClick={() => setShowPassword(!showPassword)} size={20} className={`${showPassword ? 'text-gray-600' : 'text-gray-300'}  absolute top-2 right-2`} />
                </div>

                <button onClick={changePassword} type="button" className="btn w-full mt-8">Kirim</button>

            </div>
        </div>
    );
}
export default ChangePassword;