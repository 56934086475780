/**
 * @fileoverview gRPC-Web generated client stub for affiliator_general
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: affiliator_general.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.affiliator_general = require('./affiliator_general_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.affiliator_general.AffiliatorGeneralHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.affiliator_general.AffiliatorGeneralHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.affiliator_general.GetCommissionRequest,
 *   !proto.affiliator_general.GetCommissionResponse>}
 */
const methodDescriptor_AffiliatorGeneralHandler_GetCommission = new grpc.web.MethodDescriptor(
  '/affiliator_general.AffiliatorGeneralHandler/GetCommission',
  grpc.web.MethodType.UNARY,
  proto.affiliator_general.GetCommissionRequest,
  proto.affiliator_general.GetCommissionResponse,
  /**
   * @param {!proto.affiliator_general.GetCommissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.affiliator_general.GetCommissionResponse.deserializeBinary
);


/**
 * @param {!proto.affiliator_general.GetCommissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.affiliator_general.GetCommissionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.affiliator_general.GetCommissionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.affiliator_general.AffiliatorGeneralHandlerClient.prototype.getCommission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/affiliator_general.AffiliatorGeneralHandler/GetCommission',
      request,
      metadata || {},
      methodDescriptor_AffiliatorGeneralHandler_GetCommission,
      callback);
};


/**
 * @param {!proto.affiliator_general.GetCommissionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.affiliator_general.GetCommissionResponse>}
 *     Promise that resolves to the response
 */
proto.affiliator_general.AffiliatorGeneralHandlerPromiseClient.prototype.getCommission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/affiliator_general.AffiliatorGeneralHandler/GetCommission',
      request,
      metadata || {},
      methodDescriptor_AffiliatorGeneralHandler_GetCommission);
};


module.exports = proto.affiliator_general;

