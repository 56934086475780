/**
 * @fileoverview gRPC-Web generated client stub for commentator_order
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: commentator_order.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.commentator_order = require('./commentator_order_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.commentator_order.CommentatorOrderHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.commentator_order.CommentatorOrderHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_order.GetOrderListRequest,
 *   !proto.commentator_order.GetOrderListResponse>}
 */
const methodDescriptor_CommentatorOrderHandler_GetOrderList = new grpc.web.MethodDescriptor(
  '/commentator_order.CommentatorOrderHandler/GetOrderList',
  grpc.web.MethodType.UNARY,
  proto.commentator_order.GetOrderListRequest,
  proto.commentator_order.GetOrderListResponse,
  /**
   * @param {!proto.commentator_order.GetOrderListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_order.GetOrderListResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_order.GetOrderListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_order.GetOrderListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_order.GetOrderListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_order.CommentatorOrderHandlerClient.prototype.getOrderList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_order.CommentatorOrderHandler/GetOrderList',
      request,
      metadata || {},
      methodDescriptor_CommentatorOrderHandler_GetOrderList,
      callback);
};


/**
 * @param {!proto.commentator_order.GetOrderListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_order.GetOrderListResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_order.CommentatorOrderHandlerPromiseClient.prototype.getOrderList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_order.CommentatorOrderHandler/GetOrderList',
      request,
      metadata || {},
      methodDescriptor_CommentatorOrderHandler_GetOrderList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_order.GetOrderDetailRequest,
 *   !proto.commentator_order.GetOrderDetailResponse>}
 */
const methodDescriptor_CommentatorOrderHandler_GetOrderDetail = new grpc.web.MethodDescriptor(
  '/commentator_order.CommentatorOrderHandler/GetOrderDetail',
  grpc.web.MethodType.UNARY,
  proto.commentator_order.GetOrderDetailRequest,
  proto.commentator_order.GetOrderDetailResponse,
  /**
   * @param {!proto.commentator_order.GetOrderDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_order.GetOrderDetailResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_order.GetOrderDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_order.GetOrderDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_order.GetOrderDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_order.CommentatorOrderHandlerClient.prototype.getOrderDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_order.CommentatorOrderHandler/GetOrderDetail',
      request,
      metadata || {},
      methodDescriptor_CommentatorOrderHandler_GetOrderDetail,
      callback);
};


/**
 * @param {!proto.commentator_order.GetOrderDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_order.GetOrderDetailResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_order.CommentatorOrderHandlerPromiseClient.prototype.getOrderDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_order.CommentatorOrderHandler/GetOrderDetail',
      request,
      metadata || {},
      methodDescriptor_CommentatorOrderHandler_GetOrderDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_order.GetOrderDiscussionRequest,
 *   !proto.commentator_order.GetOrderDiscussionResponse>}
 */
const methodDescriptor_CommentatorOrderHandler_GetOrderDiscussion = new grpc.web.MethodDescriptor(
  '/commentator_order.CommentatorOrderHandler/GetOrderDiscussion',
  grpc.web.MethodType.UNARY,
  proto.commentator_order.GetOrderDiscussionRequest,
  proto.commentator_order.GetOrderDiscussionResponse,
  /**
   * @param {!proto.commentator_order.GetOrderDiscussionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_order.GetOrderDiscussionResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_order.GetOrderDiscussionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_order.GetOrderDiscussionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_order.GetOrderDiscussionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_order.CommentatorOrderHandlerClient.prototype.getOrderDiscussion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_order.CommentatorOrderHandler/GetOrderDiscussion',
      request,
      metadata || {},
      methodDescriptor_CommentatorOrderHandler_GetOrderDiscussion,
      callback);
};


/**
 * @param {!proto.commentator_order.GetOrderDiscussionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_order.GetOrderDiscussionResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_order.CommentatorOrderHandlerPromiseClient.prototype.getOrderDiscussion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_order.CommentatorOrderHandler/GetOrderDiscussion',
      request,
      metadata || {},
      methodDescriptor_CommentatorOrderHandler_GetOrderDiscussion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_order.SendDiscussionRequest,
 *   !proto.commentator_order.SendDiscussionResponse>}
 */
const methodDescriptor_CommentatorOrderHandler_SendDiscussion = new grpc.web.MethodDescriptor(
  '/commentator_order.CommentatorOrderHandler/SendDiscussion',
  grpc.web.MethodType.UNARY,
  proto.commentator_order.SendDiscussionRequest,
  proto.commentator_order.SendDiscussionResponse,
  /**
   * @param {!proto.commentator_order.SendDiscussionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_order.SendDiscussionResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_order.SendDiscussionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_order.SendDiscussionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_order.SendDiscussionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_order.CommentatorOrderHandlerClient.prototype.sendDiscussion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_order.CommentatorOrderHandler/SendDiscussion',
      request,
      metadata || {},
      methodDescriptor_CommentatorOrderHandler_SendDiscussion,
      callback);
};


/**
 * @param {!proto.commentator_order.SendDiscussionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_order.SendDiscussionResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_order.CommentatorOrderHandlerPromiseClient.prototype.sendDiscussion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_order.CommentatorOrderHandler/SendDiscussion',
      request,
      metadata || {},
      methodDescriptor_CommentatorOrderHandler_SendDiscussion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_order.SetAsInProgressRequest,
 *   !proto.commentator_order.SetAsInProgressResponse>}
 */
const methodDescriptor_CommentatorOrderHandler_SetAsInProgress = new grpc.web.MethodDescriptor(
  '/commentator_order.CommentatorOrderHandler/SetAsInProgress',
  grpc.web.MethodType.UNARY,
  proto.commentator_order.SetAsInProgressRequest,
  proto.commentator_order.SetAsInProgressResponse,
  /**
   * @param {!proto.commentator_order.SetAsInProgressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_order.SetAsInProgressResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_order.SetAsInProgressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_order.SetAsInProgressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_order.SetAsInProgressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_order.CommentatorOrderHandlerClient.prototype.setAsInProgress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_order.CommentatorOrderHandler/SetAsInProgress',
      request,
      metadata || {},
      methodDescriptor_CommentatorOrderHandler_SetAsInProgress,
      callback);
};


/**
 * @param {!proto.commentator_order.SetAsInProgressRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_order.SetAsInProgressResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_order.CommentatorOrderHandlerPromiseClient.prototype.setAsInProgress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_order.CommentatorOrderHandler/SetAsInProgress',
      request,
      metadata || {},
      methodDescriptor_CommentatorOrderHandler_SetAsInProgress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_order.SetAsFinishedRequest,
 *   !proto.commentator_order.SetAsFinishedResponse>}
 */
const methodDescriptor_CommentatorOrderHandler_SetAsFinished = new grpc.web.MethodDescriptor(
  '/commentator_order.CommentatorOrderHandler/SetAsFinished',
  grpc.web.MethodType.UNARY,
  proto.commentator_order.SetAsFinishedRequest,
  proto.commentator_order.SetAsFinishedResponse,
  /**
   * @param {!proto.commentator_order.SetAsFinishedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_order.SetAsFinishedResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_order.SetAsFinishedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_order.SetAsFinishedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_order.SetAsFinishedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_order.CommentatorOrderHandlerClient.prototype.setAsFinished =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_order.CommentatorOrderHandler/SetAsFinished',
      request,
      metadata || {},
      methodDescriptor_CommentatorOrderHandler_SetAsFinished,
      callback);
};


/**
 * @param {!proto.commentator_order.SetAsFinishedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_order.SetAsFinishedResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_order.CommentatorOrderHandlerPromiseClient.prototype.setAsFinished =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_order.CommentatorOrderHandler/SetAsFinished',
      request,
      metadata || {},
      methodDescriptor_CommentatorOrderHandler_SetAsFinished);
};


module.exports = proto.commentator_order;

