import { useContext, useEffect, useState, type FC } from "react";
import { LoadingContext } from "../../objects/loading_context";
import {
  GetAffiliatorListRequest,
  GetAffiliatorListResponseData,
  Pagination,
} from "../../grpc/admin/admin_affiliator_pb";
import { adminAffiliatorClient } from "../../App";
import { getToken, money } from "../../utils/helper";
import { errorToast } from "../../utils/helper-ui";
import NoData from "../no_data";
import { FaStar } from "react-icons/fa";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import Moment from "react-moment";

interface AdminAffiliatorListProps {
  onClickClient?: (user: GetAffiliatorListResponseData.AsObject) => void;
  onClickCommentator?: (user: GetAffiliatorListResponseData.AsObject) => void;
  onClickWarning?: (user: GetAffiliatorListResponseData.AsObject) => void;
  onClickBlackList?: (user: GetAffiliatorListResponseData.AsObject) => void;
}

const AdminAffiliatorList: FC<AdminAffiliatorListProps> = ({
  onClickClient,
  onClickCommentator,
  onClickWarning,
  onClickBlackList,
}) => {
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [pagination, setPagination] = useState<Pagination.AsObject | null>(
    null
  );
  const [data, setData] = useState<GetAffiliatorListResponseData.AsObject[]>(
    []
  );
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [modalFilter, setModalFilter] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
  }, [mounted]);

  useEffect(() => {
    if (mounted) getData();
  }, [mounted, page, limit]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let resp: GetAffiliatorListResponseData.AsObject[] = await new Promise(
        (resolve, reject) => {
          let req = new GetAffiliatorListRequest();
          req.setPage(page);
          req.setLimit(limit);

          adminAffiliatorClient.getAffiliatorList(
            req,
            getToken(),
            (err, resp) => {
              if (err) {
                reject(err.message);
                return;
              }
              setPagination(resp.getPagination()?.toObject() ?? null);
              resolve(resp.getDataList().map((e) => e.toObject()));
            }
          );
        }
      );
      setData([...data, ...resp]);
      // setOrders([...orders, ...resp])
      // console.log(resp)
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className=" p-4 mt-1 min-h-[100px] bg-white">
      {data.length == 0 ? (
        <NoData />
      ) : (
        <div className="mt-4">
          <div className="flex">
            <div className="w-6/12 font-bold px-4">Nama</div>
            <div className="w-2/12 font-bold text-center">Client</div>
            <div className="w-4/12 font-bold text-center">Rating</div>
            <div></div>
          </div>
          <ul>
            {data.map((e) => (
              <li key={e.id}>
                <div className={`flex p-4  ${e.isBlacklisted ? 'bg-red-50' : 'bg-sky-50'} mx-1 my-2 rounded-lg cursor-pointer items-center`}>
                  <div className="w-6/12">{e.name}</div>
                  <div className="w-2/12 text-center">
                    {e.totalClient ? money(e.totalClient) : "-"}
                  </div>
                  <div
                    className="w-4/12 text-center flex justify-end items-center "
                    onClick={() => {
                      if (expandedIds.includes(e.id)) {
                        setExpandedIds([...expandedIds.filter((id) => !e.id)]);
                      } else {
                        setExpandedIds([...expandedIds, e.id]);
                      }
                    }}
                  >
                    {e.rating ? (
                      <div className="flex justify-center items-center">
                        <FaStar size={8} className="text-orange-300 mr-2" />{" "}
                        {money(e.rating / 2, 2)}
                      </div>
                    ) : (
                      ""
                    )}
                    {expandedIds.includes(e.id) ? (
                      <RiArrowUpSFill size={24} className=" text-sky-600 " />
                    ) : (
                      <RiArrowDownSFill size={24} className=" text-sky-600 " />
                    )}
                  </div>
                </div>
                {expandedIds.includes(e.id) && (
                  <div className="flex flex-col p-4  bg-gray-50 mx-1 my-2 rounded-lg cursor-pointer">
                    <div className="flex mb-2">
                      <div className="w-1/2">
                        <p className="font-semibold">ID</p>
                      </div>
                      <div className="w-1/2 text-right">
                        <p>{e.id}</p>
                      </div>
                    </div>
                    <div className="flex mb-2">
                      <div className="w-1/2">
                        <p className="font-semibold">No. Telp</p>
                      </div>
                      <div className="w-1/2 text-right">
                        <p>{e.phoneNumber}</p>
                      </div>
                    </div>
                    <div className="flex mb-2">
                      <div className="w-1/2">
                        <p className="font-semibold">Kartu</p>
                      </div>
                      <div className="w-1/2 text-right flex justify-end">
                        {e.offenseCount != 0 &&
                          [...Array(e.offenseCount).keys()].map((a) => (
                            <img
                            key={a}
                              className="w-4"
                              src="/assets/card_yellow.png"
                            />
                          ))}
                      </div>
                    </div>
                    <div className="flex mb-2">
                      <div className="w-1/2">
                        <p className="font-semibold">Bergabung</p>
                      </div>
                      <div className="w-1/2 text-right">
                        <Moment format="DD MMMM YYYY">{e.createdAt}</Moment>
                      </div>
                    </div>
                    <div className="flex mb-2">
                      <div className="w-1/2">
                        <p className="font-semibold">
                          Client ({money(e.totalClient)})
                        </p>
                      </div>
                      <div
                        className="w-1/2 text-right"
                        onClick={() => {
                          if (onClickClient) onClickClient(e);
                        }}
                      >
                        <p className="font-semibold text-sky-600">Lihat</p>
                      </div>
                    </div>
                    <div className="flex mb-2">
                      <div className="w-1/2">
                        <p className="font-semibold">
                          Komentator ({money(e.totalCommentatorAccount)} Slot)
                        </p>
                      </div>
                      <div
                        className="w-1/2 text-right"
                        onClick={() => {
                          if (onClickCommentator) onClickCommentator(e);
                        }}
                      >
                        <p className="font-semibold text-sky-600">Lihat</p>
                      </div>
                    </div>
                    <div className="flex mt-4 justify-between">
                      {e.isBlacklisted ? (
                        <div></div>
                      ) : (
                        <button
                          onClick={() => {
                            if (onClickWarning) onClickWarning(e);
                          }}
                          className="btn-orange-pills"
                        >
                          Beri Peringatan
                        </button>
                      )}
                      {e.isBlacklisted ? (
                        <div className=" text-red-400 px-5 py-2.5 text-center">
                          User Diblacklist
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            if (onClickBlackList) onClickBlackList(e);
                          }}
                          className="btn-orange"
                        >
                          Black List
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className=" justify-center items-start flex">
        {(pagination?.totalPages ?? 0) > 0 &&
          (pagination?.next ?? 0) > page && (
            <button
              className="btn-white w-48"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              More
            </button>
          )}
      </div>
    </div>
  );
};
export default AdminAffiliatorList;
