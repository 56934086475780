import { useContext, useRef, useState, type FC } from "react";
import { LoadingContext } from "../../objects/loading_context";
import { FileBase64Request } from "../../grpc/service/file_pb";
import { clientFile } from "../../App";
import { errorToast } from "../../utils/helper-ui";
import { GetDetailCommentatorAccountResponseData } from "../../grpc/commentator/commentator_account_pb";
import { getToken, initials } from "../../utils/helper";
import { Avatar, Modal } from "rsuite";

interface AccountFormProps {
  isNew?: boolean;
  account: GetDetailCommentatorAccountResponseData.AsObject | null;
  onUpdate: (account: GetDetailCommentatorAccountResponseData.AsObject) => void;
  onChange: (account: GetDetailCommentatorAccountResponseData.AsObject) => void;
}

const AccountForm: FC<AccountFormProps> = ({
  account,
  onUpdate,
  onChange,
  isNew,
}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const fileRef = useRef<HTMLInputElement>(null);
  
  return (
    <>
      <input
        accept="image/*"
        type="file"
        className="hidden"
        defaultValue={[]}
        ref={fileRef}
        onChange={(el) => {
          let files = fileRef.current?.files;
          if (files) {
            let reader = new FileReader();

            reader.onload = function () {
              setIsLoading(true);
              let req = new FileBase64Request();
              req.setFile(`${reader?.result}`.split("base64,")[1]);
              clientFile.uploadBase64Temp(req, getToken(), (err, resp) => {
                setIsLoading(false);
                if (err) {
                  errorToast(err.message);

                  return;
                }
                let path = resp.getData()?.toObject();
                if (isNew) {
                  onChange({
                    ...account!,
                    profilePicturePath: path!.path,
                    profilePicturePathType: path!.pathType,
                    profilePictureUrl: path!.url,
                  });
                } else {
                  onUpdate({
                    ...account!,
                    profilePicturePath: path!.path,
                    profilePicturePathType: path!.pathType,
                  });
                }
              });
              // setImgUpload(reader?.result?.toString() ?? "/assets/upload.png")
            };
            reader.readAsDataURL(files[0]);
          }
        }}
      />
      <div className="flex w-full h-full p-4 flex-col">
        <div className="flex-1 overflow-y-auto">
          <div className="w-full flex justify-center flex-col items-center">
            <Avatar
              alt={initials(account?.name)}
              circle
              src={account?.profilePictureUrl}
              size="xl"
              className="m-auto"
              color="blue"
              onClick={() => fileRef.current?.click()}
            />
            <h4 className=" text-black py-2">{account?.name}</h4>
          </div>
          <div className="mt-4">
            <div>
              <label htmlFor="" className="px-2 font-semibold">
                Nama Akun
              </label>
              <input
                className="mb-4 form-control"
                value={account?.name ?? ""}
                onChange={(e) => {
                  onChange({
                    ...account!,
                    name: e.target.value,
                  });
                }}
                type="text"
                placeholder="Nama Akun"
              />
            </div>
            <div>
              <label htmlFor="" className="px-2 font-semibold">
                Instagram
              </label>
              <input
                className="mb-4 form-control"
                value={account?.idInstagram ?? ""}
                onChange={(e) => {
                  onChange({
                    ...account!,
                    idInstagram: e.target.value,
                  });
                }}
                type="text"
                placeholder="Instagram"
              />
            </div>
            <div>
              <label htmlFor="" className="px-2 font-semibold">
                Tiktok
              </label>
              <input
                className="mb-4 form-control"
                value={account?.idTiktok ?? ""}
                onChange={(e) => {
                  onChange({
                    ...account!,
                    idTiktok: e.target.value,
                  });
                }}
                type="text"
                placeholder="Tiktok"
              />
            </div>
            <div>
              <label htmlFor="" className="px-2 font-semibold">
                Twitter / X
              </label>
              <input
                className="mb-4 form-control"
                value={account?.idTwitter ?? ""}
                onChange={(e) => {
                  onChange({
                    ...account!,
                    idTwitter: e.target.value,
                  });
                }}
                type="text"
                placeholder="Twitter"
              />
            </div>
            <div>
              <label htmlFor="" className="px-2 font-semibold">
                Facebook
              </label>
              <input
                className="mb-4 form-control"
                value={account?.idFacebook ?? ""}
                onChange={(e) => {
                  onChange({
                    ...account!,
                    idFacebook: e.target.value,
                  });
                }}
                type="text"
                placeholder="Facebook"
              />
            </div>
            <div>
              <label htmlFor="" className="px-2 font-semibold">
                Youtube
              </label>
              <input
                className="mb-4 form-control"
                value={account?.idYoutube ?? ""}
                onChange={(e) => {
                  onChange({
                    ...account!,
                    idYoutube: e.target.value,
                  });
                }}
                type="text"
                placeholder="Youtube"
              />
            </div>
            <div>
              <label htmlFor="" className="px-2 font-semibold">
                Threads
              </label>
              <input
                className="mb-4 form-control"
                value={account?.idThreads ?? ""}
                onChange={(e) => {
                  onChange({
                    ...account!,
                    idThreads: e.target.value,
                  });
                }}
                type="text"
                placeholder="Threads"
              />
            </div>
            <div>
              <label htmlFor="" className="px-2 font-semibold">
                Detik
              </label>
              <input
                className="mb-4 form-control"
                value={account?.idDetik ?? ""}
                onChange={(e) => {
                  onChange({
                    ...account!,
                    idDetik: e.target.value,
                  });
                }}
                type="text"
                placeholder="Detik"
              />
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={async () => {
              onUpdate(account!);
            }}
            type="button"
            className="btn w-full mt-8"
          >
            Simpan
          </button>
        </div>
      </div>
     
    </>
  );
};
export default AccountForm;
