/**
 * @fileoverview gRPC-Web generated client stub for file
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: file.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.file = require('./file_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.file.FileHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.file.FileHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.file.FileBlobRequest,
 *   !proto.file.FileResponse>}
 */
const methodDescriptor_FileHandler_UploadBlobTemp = new grpc.web.MethodDescriptor(
  '/file.FileHandler/UploadBlobTemp',
  grpc.web.MethodType.UNARY,
  proto.file.FileBlobRequest,
  proto.file.FileResponse,
  /**
   * @param {!proto.file.FileBlobRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.file.FileResponse.deserializeBinary
);


/**
 * @param {!proto.file.FileBlobRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.file.FileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.file.FileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.file.FileHandlerClient.prototype.uploadBlobTemp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/file.FileHandler/UploadBlobTemp',
      request,
      metadata || {},
      methodDescriptor_FileHandler_UploadBlobTemp,
      callback);
};


/**
 * @param {!proto.file.FileBlobRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.file.FileResponse>}
 *     Promise that resolves to the response
 */
proto.file.FileHandlerPromiseClient.prototype.uploadBlobTemp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/file.FileHandler/UploadBlobTemp',
      request,
      metadata || {},
      methodDescriptor_FileHandler_UploadBlobTemp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.file.FileBase64Request,
 *   !proto.file.FileResponse>}
 */
const methodDescriptor_FileHandler_UploadBase64Temp = new grpc.web.MethodDescriptor(
  '/file.FileHandler/UploadBase64Temp',
  grpc.web.MethodType.UNARY,
  proto.file.FileBase64Request,
  proto.file.FileResponse,
  /**
   * @param {!proto.file.FileBase64Request} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.file.FileResponse.deserializeBinary
);


/**
 * @param {!proto.file.FileBase64Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.file.FileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.file.FileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.file.FileHandlerClient.prototype.uploadBase64Temp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/file.FileHandler/UploadBase64Temp',
      request,
      metadata || {},
      methodDescriptor_FileHandler_UploadBase64Temp,
      callback);
};


/**
 * @param {!proto.file.FileBase64Request} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.file.FileResponse>}
 *     Promise that resolves to the response
 */
proto.file.FileHandlerPromiseClient.prototype.uploadBase64Temp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/file.FileHandler/UploadBase64Temp',
      request,
      metadata || {},
      methodDescriptor_FileHandler_UploadBase64Temp);
};


module.exports = proto.file;

