import type { FC } from 'react';
import { HiOutlineCheckCircle } from 'react-icons/hi2';
import { Modal } from 'rsuite';
import { premiumFeatures } from '../utils/contants';
import { useNavigate } from 'react-router-dom';

interface ModalUpgradeProps {
	open: boolean
	onClose: () => void
}

const ModalUpgrade: FC<ModalUpgradeProps> = ({ 
	open,
	onClose,
}) => {
	const nav = useNavigate()
	return (<Modal size={"sm"} open={open} onClose={onClose}>
		<Modal.Title>
			Upgrage Akunmu
		</Modal.Title>
		<Modal.Body>
			<div className='p-2 flex flex-col  justify-center items-center'>
				<img src="/assets/business_logo.png" alt="" className='w-16' />
				<h6>Premium</h6>
			</div>
			<div className=' col-span-2'>
				<ul className='mb-0'>
					{premiumFeatures.map(e => <li key={e} className='flex items-center mb-2 last:mb-0'>
						<HiOutlineCheckCircle color='green' size={16} className='mr-2' />
						<span className=' text-xs'>{e}</span>
					</li>)}
				</ul>
			</div>
			<div className='flex justify-center'>

				<button onClick={() => nav('/buy-package')} type="button" className="btn mt-4 ">Upgrade Akun</button>
			</div>

		</Modal.Body>
	</Modal>);
}
export default ModalUpgrade;