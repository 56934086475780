// source: affiliator_user_package.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.affiliator_user_package.AcceptUserPackageRequest', null, global);
goog.exportSymbol('proto.affiliator_user_package.AcceptUserPackageResponse', null, global);
goog.exportSymbol('proto.affiliator_user_package.AcceptUserPackageResponseData', null, global);
goog.exportSymbol('proto.affiliator_user_package.CancelUserPackageCommentatorRequest', null, global);
goog.exportSymbol('proto.affiliator_user_package.CancelUserPackageCommentatorResponse', null, global);
goog.exportSymbol('proto.affiliator_user_package.CancelUserPackageCommentatorResponseData', null, global);
goog.exportSymbol('proto.affiliator_user_package.GetDetailUserPackageRequest', null, global);
goog.exportSymbol('proto.affiliator_user_package.GetDetailUserPackageResponse', null, global);
goog.exportSymbol('proto.affiliator_user_package.GetDetailUserPackageResponseData', null, global);
goog.exportSymbol('proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator', null, global);
goog.exportSymbol('proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator', null, global);
goog.exportSymbol('proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer', null, global);
goog.exportSymbol('proto.affiliator_user_package.GetListUserPackageRequest', null, global);
goog.exportSymbol('proto.affiliator_user_package.GetListUserPackageResponse', null, global);
goog.exportSymbol('proto.affiliator_user_package.GetListUserPackageResponseData', null, global);
goog.exportSymbol('proto.affiliator_user_package.GetListUserPackageResponseDataUser', null, global);
goog.exportSymbol('proto.affiliator_user_package.Meta', null, global);
goog.exportSymbol('proto.affiliator_user_package.Pagination', null, global);
goog.exportSymbol('proto.affiliator_user_package.UserPackageOfferToCommentatorRequest', null, global);
goog.exportSymbol('proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator', null, global);
goog.exportSymbol('proto.affiliator_user_package.UserPackageOfferToCommentatorResponse', null, global);
goog.exportSymbol('proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.Meta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.Meta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.Meta.displayName = 'proto.affiliator_user_package.Meta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.Pagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.Pagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.Pagination.displayName = 'proto.affiliator_user_package.Pagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.GetListUserPackageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.GetListUserPackageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.GetListUserPackageRequest.displayName = 'proto.affiliator_user_package.GetListUserPackageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.GetListUserPackageResponseDataUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.GetListUserPackageResponseDataUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.GetListUserPackageResponseDataUser.displayName = 'proto.affiliator_user_package.GetListUserPackageResponseDataUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.GetListUserPackageResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.GetListUserPackageResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.GetListUserPackageResponseData.displayName = 'proto.affiliator_user_package.GetListUserPackageResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.GetListUserPackageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_user_package.GetListUserPackageResponse.repeatedFields_, null);
};
goog.inherits(proto.affiliator_user_package.GetListUserPackageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.GetListUserPackageResponse.displayName = 'proto.affiliator_user_package.GetListUserPackageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.AcceptUserPackageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.AcceptUserPackageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.AcceptUserPackageRequest.displayName = 'proto.affiliator_user_package.AcceptUserPackageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.AcceptUserPackageResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.AcceptUserPackageResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.AcceptUserPackageResponseData.displayName = 'proto.affiliator_user_package.AcceptUserPackageResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.AcceptUserPackageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.AcceptUserPackageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.AcceptUserPackageResponse.displayName = 'proto.affiliator_user_package.AcceptUserPackageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.GetDetailUserPackageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.GetDetailUserPackageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.GetDetailUserPackageRequest.displayName = 'proto.affiliator_user_package.GetDetailUserPackageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.displayName = 'proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.displayName = 'proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.displayName = 'proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_user_package.GetDetailUserPackageResponseData.repeatedFields_, null);
};
goog.inherits(proto.affiliator_user_package.GetDetailUserPackageResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.GetDetailUserPackageResponseData.displayName = 'proto.affiliator_user_package.GetDetailUserPackageResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.GetDetailUserPackageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.GetDetailUserPackageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.GetDetailUserPackageResponse.displayName = 'proto.affiliator_user_package.GetDetailUserPackageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.displayName = 'proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.repeatedFields_, null);
};
goog.inherits(proto.affiliator_user_package.UserPackageOfferToCommentatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.displayName = 'proto.affiliator_user_package.UserPackageOfferToCommentatorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData.displayName = 'proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.UserPackageOfferToCommentatorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.displayName = 'proto.affiliator_user_package.UserPackageOfferToCommentatorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.CancelUserPackageCommentatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.CancelUserPackageCommentatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.CancelUserPackageCommentatorRequest.displayName = 'proto.affiliator_user_package.CancelUserPackageCommentatorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.CancelUserPackageCommentatorResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.CancelUserPackageCommentatorResponseData.displayName = 'proto.affiliator_user_package.CancelUserPackageCommentatorResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.affiliator_user_package.CancelUserPackageCommentatorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.affiliator_user_package.CancelUserPackageCommentatorResponse.displayName = 'proto.affiliator_user_package.CancelUserPackageCommentatorResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.Meta.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.Meta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.Meta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.Meta.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.Meta}
 */
proto.affiliator_user_package.Meta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.Meta;
  return proto.affiliator_user_package.Meta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.Meta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.Meta}
 */
proto.affiliator_user_package.Meta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.Meta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.Meta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.Meta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.Meta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.affiliator_user_package.Meta.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.Meta} returns this
 */
proto.affiliator_user_package.Meta.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 status = 2;
 * @return {number}
 */
proto.affiliator_user_package.Meta.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.Meta} returns this
 */
proto.affiliator_user_package.Meta.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.affiliator_user_package.Meta.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.Meta} returns this
 */
proto.affiliator_user_package.Meta.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.Pagination.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.Pagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.Pagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.Pagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    prev: jspb.Message.getFieldWithDefault(msg, 3, 0),
    next: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.Pagination}
 */
proto.affiliator_user_package.Pagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.Pagination;
  return proto.affiliator_user_package.Pagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.Pagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.Pagination}
 */
proto.affiliator_user_package.Pagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrev(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNext(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPages(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.Pagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.Pagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.Pagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.Pagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPrev();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNext();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.affiliator_user_package.Pagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.Pagination} returns this
 */
proto.affiliator_user_package.Pagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.affiliator_user_package.Pagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.Pagination} returns this
 */
proto.affiliator_user_package.Pagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 prev = 3;
 * @return {number}
 */
proto.affiliator_user_package.Pagination.prototype.getPrev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.Pagination} returns this
 */
proto.affiliator_user_package.Pagination.prototype.setPrev = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 next = 4;
 * @return {number}
 */
proto.affiliator_user_package.Pagination.prototype.getNext = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.Pagination} returns this
 */
proto.affiliator_user_package.Pagination.prototype.setNext = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 total_pages = 5;
 * @return {number}
 */
proto.affiliator_user_package.Pagination.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.Pagination} returns this
 */
proto.affiliator_user_package.Pagination.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 total_records = 6;
 * @return {number}
 */
proto.affiliator_user_package.Pagination.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.Pagination} returns this
 */
proto.affiliator_user_package.Pagination.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.GetListUserPackageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.GetListUserPackageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.GetListUserPackageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetListUserPackageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paymentStatus: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.GetListUserPackageRequest}
 */
proto.affiliator_user_package.GetListUserPackageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.GetListUserPackageRequest;
  return proto.affiliator_user_package.GetListUserPackageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.GetListUserPackageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.GetListUserPackageRequest}
 */
proto.affiliator_user_package.GetListUserPackageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.GetListUserPackageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.GetListUserPackageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.GetListUserPackageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetListUserPackageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPaymentStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.affiliator_user_package.GetListUserPackageRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.GetListUserPackageRequest} returns this
 */
proto.affiliator_user_package.GetListUserPackageRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.affiliator_user_package.GetListUserPackageRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.GetListUserPackageRequest} returns this
 */
proto.affiliator_user_package.GetListUserPackageRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string payment_status = 3;
 * @return {string}
 */
proto.affiliator_user_package.GetListUserPackageRequest.prototype.getPaymentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetListUserPackageRequest} returns this
 */
proto.affiliator_user_package.GetListUserPackageRequest.prototype.setPaymentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.GetListUserPackageResponseDataUser.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.GetListUserPackageResponseDataUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.GetListUserPackageResponseDataUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetListUserPackageResponseDataUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseDataUser}
 */
proto.affiliator_user_package.GetListUserPackageResponseDataUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.GetListUserPackageResponseDataUser;
  return proto.affiliator_user_package.GetListUserPackageResponseDataUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.GetListUserPackageResponseDataUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseDataUser}
 */
proto.affiliator_user_package.GetListUserPackageResponseDataUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.GetListUserPackageResponseDataUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.GetListUserPackageResponseDataUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.GetListUserPackageResponseDataUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetListUserPackageResponseDataUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_user_package.GetListUserPackageResponseDataUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseDataUser} returns this
 */
proto.affiliator_user_package.GetListUserPackageResponseDataUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.affiliator_user_package.GetListUserPackageResponseDataUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseDataUser} returns this
 */
proto.affiliator_user_package.GetListUserPackageResponseDataUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.affiliator_user_package.GetListUserPackageResponseDataUser.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseDataUser} returns this
 */
proto.affiliator_user_package.GetListUserPackageResponseDataUser.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.GetListUserPackageResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.GetListUserPackageResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetListUserPackageResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    packageName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    commentPerDay: jspb.Message.getFieldWithDefault(msg, 4, 0),
    durationDay: jspb.Message.getFieldWithDefault(msg, 5, 0),
    affiliatorFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    expiryTime: jspb.Message.getFieldWithDefault(msg, 7, ""),
    paymentStatus: jspb.Message.getFieldWithDefault(msg, 8, ""),
    user: (f = msg.getUser()) && proto.affiliator_user_package.GetListUserPackageResponseDataUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseData}
 */
proto.affiliator_user_package.GetListUserPackageResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.GetListUserPackageResponseData;
  return proto.affiliator_user_package.GetListUserPackageResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.GetListUserPackageResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseData}
 */
proto.affiliator_user_package.GetListUserPackageResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommentPerDay(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationDay(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAffiliatorFee(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiryTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentStatus(value);
      break;
    case 9:
      var value = new proto.affiliator_user_package.GetListUserPackageResponseDataUser;
      reader.readMessage(value,proto.affiliator_user_package.GetListUserPackageResponseDataUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.GetListUserPackageResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.GetListUserPackageResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetListUserPackageResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPackageName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCommentPerDay();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getDurationDay();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getAffiliatorFee();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getExpiryTime();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPaymentStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.affiliator_user_package.GetListUserPackageResponseDataUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string package_name = 3;
 * @return {string}
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.getPackageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.setPackageName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 comment_per_day = 4;
 * @return {number}
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.getCommentPerDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.setCommentPerDay = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 duration_day = 5;
 * @return {number}
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.getDurationDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.setDurationDay = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double affiliator_fee = 6;
 * @return {number}
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.getAffiliatorFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.setAffiliatorFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string expiry_time = 7;
 * @return {string}
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.getExpiryTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.setExpiryTime = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string payment_status = 8;
 * @return {string}
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.getPaymentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.setPaymentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional GetListUserPackageResponseDataUser user = 9;
 * @return {?proto.affiliator_user_package.GetListUserPackageResponseDataUser}
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.getUser = function() {
  return /** @type{?proto.affiliator_user_package.GetListUserPackageResponseDataUser} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_user_package.GetListUserPackageResponseDataUser, 9));
};


/**
 * @param {?proto.affiliator_user_package.GetListUserPackageResponseDataUser|undefined} value
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseData} returns this
*/
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_user_package.GetListUserPackageResponseData.prototype.hasUser = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_user_package.GetListUserPackageResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.GetListUserPackageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.GetListUserPackageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.GetListUserPackageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetListUserPackageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_user_package.Meta.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.affiliator_user_package.Pagination.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.affiliator_user_package.GetListUserPackageResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.GetListUserPackageResponse}
 */
proto.affiliator_user_package.GetListUserPackageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.GetListUserPackageResponse;
  return proto.affiliator_user_package.GetListUserPackageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.GetListUserPackageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.GetListUserPackageResponse}
 */
proto.affiliator_user_package.GetListUserPackageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_user_package.Meta;
      reader.readMessage(value,proto.affiliator_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_user_package.Pagination;
      reader.readMessage(value,proto.affiliator_user_package.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.affiliator_user_package.GetListUserPackageResponseData;
      reader.readMessage(value,proto.affiliator_user_package.GetListUserPackageResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.GetListUserPackageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.GetListUserPackageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.GetListUserPackageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetListUserPackageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_user_package.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.affiliator_user_package.GetListUserPackageResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_user_package.Meta}
 */
proto.affiliator_user_package.GetListUserPackageResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_user_package.Meta, 1));
};


/**
 * @param {?proto.affiliator_user_package.Meta|undefined} value
 * @return {!proto.affiliator_user_package.GetListUserPackageResponse} returns this
*/
proto.affiliator_user_package.GetListUserPackageResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_user_package.GetListUserPackageResponse} returns this
 */
proto.affiliator_user_package.GetListUserPackageResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_user_package.GetListUserPackageResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.affiliator_user_package.Pagination}
 */
proto.affiliator_user_package.GetListUserPackageResponse.prototype.getPagination = function() {
  return /** @type{?proto.affiliator_user_package.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_user_package.Pagination, 2));
};


/**
 * @param {?proto.affiliator_user_package.Pagination|undefined} value
 * @return {!proto.affiliator_user_package.GetListUserPackageResponse} returns this
*/
proto.affiliator_user_package.GetListUserPackageResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_user_package.GetListUserPackageResponse} returns this
 */
proto.affiliator_user_package.GetListUserPackageResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_user_package.GetListUserPackageResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated GetListUserPackageResponseData data = 3;
 * @return {!Array<!proto.affiliator_user_package.GetListUserPackageResponseData>}
 */
proto.affiliator_user_package.GetListUserPackageResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.affiliator_user_package.GetListUserPackageResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_user_package.GetListUserPackageResponseData, 3));
};


/**
 * @param {!Array<!proto.affiliator_user_package.GetListUserPackageResponseData>} value
 * @return {!proto.affiliator_user_package.GetListUserPackageResponse} returns this
*/
proto.affiliator_user_package.GetListUserPackageResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.affiliator_user_package.GetListUserPackageResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_user_package.GetListUserPackageResponseData}
 */
proto.affiliator_user_package.GetListUserPackageResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.affiliator_user_package.GetListUserPackageResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_user_package.GetListUserPackageResponse} returns this
 */
proto.affiliator_user_package.GetListUserPackageResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.AcceptUserPackageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.AcceptUserPackageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.AcceptUserPackageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.AcceptUserPackageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userPackageId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.AcceptUserPackageRequest}
 */
proto.affiliator_user_package.AcceptUserPackageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.AcceptUserPackageRequest;
  return proto.affiliator_user_package.AcceptUserPackageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.AcceptUserPackageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.AcceptUserPackageRequest}
 */
proto.affiliator_user_package.AcceptUserPackageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPackageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.AcceptUserPackageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.AcceptUserPackageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.AcceptUserPackageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.AcceptUserPackageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserPackageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_package_id = 1;
 * @return {string}
 */
proto.affiliator_user_package.AcceptUserPackageRequest.prototype.getUserPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.AcceptUserPackageRequest} returns this
 */
proto.affiliator_user_package.AcceptUserPackageRequest.prototype.setUserPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.AcceptUserPackageResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.AcceptUserPackageResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.AcceptUserPackageResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.AcceptUserPackageResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.AcceptUserPackageResponseData}
 */
proto.affiliator_user_package.AcceptUserPackageResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.AcceptUserPackageResponseData;
  return proto.affiliator_user_package.AcceptUserPackageResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.AcceptUserPackageResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.AcceptUserPackageResponseData}
 */
proto.affiliator_user_package.AcceptUserPackageResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.AcceptUserPackageResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.AcceptUserPackageResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.AcceptUserPackageResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.AcceptUserPackageResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.AcceptUserPackageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.AcceptUserPackageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.AcceptUserPackageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.AcceptUserPackageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_user_package.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.affiliator_user_package.AcceptUserPackageResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.AcceptUserPackageResponse}
 */
proto.affiliator_user_package.AcceptUserPackageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.AcceptUserPackageResponse;
  return proto.affiliator_user_package.AcceptUserPackageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.AcceptUserPackageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.AcceptUserPackageResponse}
 */
proto.affiliator_user_package.AcceptUserPackageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_user_package.Meta;
      reader.readMessage(value,proto.affiliator_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_user_package.AcceptUserPackageResponseData;
      reader.readMessage(value,proto.affiliator_user_package.AcceptUserPackageResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.AcceptUserPackageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.AcceptUserPackageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.AcceptUserPackageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.AcceptUserPackageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_user_package.AcceptUserPackageResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_user_package.Meta}
 */
proto.affiliator_user_package.AcceptUserPackageResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_user_package.Meta, 1));
};


/**
 * @param {?proto.affiliator_user_package.Meta|undefined} value
 * @return {!proto.affiliator_user_package.AcceptUserPackageResponse} returns this
*/
proto.affiliator_user_package.AcceptUserPackageResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_user_package.AcceptUserPackageResponse} returns this
 */
proto.affiliator_user_package.AcceptUserPackageResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_user_package.AcceptUserPackageResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AcceptUserPackageResponseData data = 2;
 * @return {?proto.affiliator_user_package.AcceptUserPackageResponseData}
 */
proto.affiliator_user_package.AcceptUserPackageResponse.prototype.getData = function() {
  return /** @type{?proto.affiliator_user_package.AcceptUserPackageResponseData} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_user_package.AcceptUserPackageResponseData, 2));
};


/**
 * @param {?proto.affiliator_user_package.AcceptUserPackageResponseData|undefined} value
 * @return {!proto.affiliator_user_package.AcceptUserPackageResponse} returns this
*/
proto.affiliator_user_package.AcceptUserPackageResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_user_package.AcceptUserPackageResponse} returns this
 */
proto.affiliator_user_package.AcceptUserPackageResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_user_package.AcceptUserPackageResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.GetDetailUserPackageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.GetDetailUserPackageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.GetDetailUserPackageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetDetailUserPackageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageRequest}
 */
proto.affiliator_user_package.GetDetailUserPackageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.GetDetailUserPackageRequest;
  return proto.affiliator_user_package.GetDetailUserPackageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.GetDetailUserPackageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageRequest}
 */
proto.affiliator_user_package.GetDetailUserPackageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.GetDetailUserPackageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.GetDetailUserPackageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.GetDetailUserPackageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetDetailUserPackageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_user_package.GetDetailUserPackageRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageRequest} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator;
  return proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator;
  return proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    totalAcceptedAccount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalAccount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    commentatorAccountLimit: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer;
  return proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAccepted(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalAcceptedAccount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalAccount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommentatorAccountLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsAccepted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTotalAcceptedAccount();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalAccount();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getCommentatorAccountLimit();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_accepted = 4;
 * @return {boolean}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.getIsAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.setIsAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional uint32 total_accepted_account = 5;
 * @return {number}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.getTotalAcceptedAccount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.setTotalAcceptedAccount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 total_account = 6;
 * @return {number}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.getTotalAccount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.setTotalAccount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 commentator_account_limit = 7;
 * @return {number}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.getCommentatorAccountLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.setCommentatorAccountLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.repeatedFields_ = [10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.GetDetailUserPackageResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    commentPerDay: jspb.Message.getFieldWithDefault(msg, 2, 0),
    durationDay: jspb.Message.getFieldWithDefault(msg, 3, 0),
    commentatorAccountSlot: jspb.Message.getFieldWithDefault(msg, 4, 0),
    commentatorAccountRemainingSlot: jspb.Message.getFieldWithDefault(msg, 5, 0),
    affiliatorFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    commentatorFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 8, ""),
    affiliator: (f = msg.getAffiliator()) && proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.toObject(includeInstance, f),
    commentatorsList: jspb.Message.toObjectList(msg.getCommentatorsList(),
    proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.toObject, includeInstance),
    commentatorOffersList: jspb.Message.toObjectList(msg.getCommentatorOffersList(),
    proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.toObject, includeInstance),
    expiryTime: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.GetDetailUserPackageResponseData;
  return proto.affiliator_user_package.GetDetailUserPackageResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommentPerDay(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationDay(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommentatorAccountSlot(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommentatorAccountRemainingSlot(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAffiliatorFee(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCommentatorFee(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 9:
      var value = new proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator;
      reader.readMessage(value,proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.deserializeBinaryFromReader);
      msg.setAffiliator(value);
      break;
    case 10:
      var value = new proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator;
      reader.readMessage(value,proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.deserializeBinaryFromReader);
      msg.addCommentators(value);
      break;
    case 11:
      var value = new proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer;
      reader.readMessage(value,proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.deserializeBinaryFromReader);
      msg.addCommentatorOffers(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiryTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.GetDetailUserPackageResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommentPerDay();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDurationDay();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getCommentatorAccountSlot();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getCommentatorAccountRemainingSlot();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getAffiliatorFee();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getCommentatorFee();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAffiliator();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator.serializeBinaryToWriter
    );
  }
  f = message.getCommentatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator.serializeBinaryToWriter
    );
  }
  f = message.getCommentatorOffersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer.serializeBinaryToWriter
    );
  }
  f = message.getExpiryTime();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 comment_per_day = 2;
 * @return {number}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.getCommentPerDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.setCommentPerDay = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 duration_day = 3;
 * @return {number}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.getDurationDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.setDurationDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 commentator_account_slot = 4;
 * @return {number}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.getCommentatorAccountSlot = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.setCommentatorAccountSlot = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 commentator_account_remaining_slot = 5;
 * @return {number}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.getCommentatorAccountRemainingSlot = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.setCommentatorAccountRemainingSlot = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double affiliator_fee = 6;
 * @return {number}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.getAffiliatorFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.setAffiliatorFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double commentator_fee = 7;
 * @return {number}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.getCommentatorFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.setCommentatorFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string status = 8;
 * @return {string}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional GetDetailUserPackageResponseDataAffiliator affiliator = 9;
 * @return {?proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.getAffiliator = function() {
  return /** @type{?proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator, 9));
};


/**
 * @param {?proto.affiliator_user_package.GetDetailUserPackageResponseDataAffiliator|undefined} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData} returns this
*/
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.setAffiliator = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.clearAffiliator = function() {
  return this.setAffiliator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.hasAffiliator = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated GetDetailUserPackageResponseDataCommentator commentators = 10;
 * @return {!Array<!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator>}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.getCommentatorsList = function() {
  return /** @type{!Array<!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator, 10));
};


/**
 * @param {!Array<!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator>} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData} returns this
*/
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.setCommentatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.addCommentators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.clearCommentatorsList = function() {
  return this.setCommentatorsList([]);
};


/**
 * repeated GetDetailUserPackageResponseDataCommentatorOffer commentator_offers = 11;
 * @return {!Array<!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer>}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.getCommentatorOffersList = function() {
  return /** @type{!Array<!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer, 11));
};


/**
 * @param {!Array<!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer>} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData} returns this
*/
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.setCommentatorOffersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.addCommentatorOffers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.affiliator_user_package.GetDetailUserPackageResponseDataCommentatorOffer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.clearCommentatorOffersList = function() {
  return this.setCommentatorOffersList([]);
};


/**
 * optional string expiry_time = 12;
 * @return {string}
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.getExpiryTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponseData.prototype.setExpiryTime = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.GetDetailUserPackageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.GetDetailUserPackageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetDetailUserPackageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_user_package.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.affiliator_user_package.GetDetailUserPackageResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponse}
 */
proto.affiliator_user_package.GetDetailUserPackageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.GetDetailUserPackageResponse;
  return proto.affiliator_user_package.GetDetailUserPackageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponse}
 */
proto.affiliator_user_package.GetDetailUserPackageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_user_package.Meta;
      reader.readMessage(value,proto.affiliator_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_user_package.GetDetailUserPackageResponseData;
      reader.readMessage(value,proto.affiliator_user_package.GetDetailUserPackageResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.GetDetailUserPackageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.GetDetailUserPackageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.GetDetailUserPackageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.GetDetailUserPackageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_user_package.GetDetailUserPackageResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_user_package.Meta}
 */
proto.affiliator_user_package.GetDetailUserPackageResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_user_package.Meta, 1));
};


/**
 * @param {?proto.affiliator_user_package.Meta|undefined} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponse} returns this
*/
proto.affiliator_user_package.GetDetailUserPackageResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponse} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_user_package.GetDetailUserPackageResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetDetailUserPackageResponseData data = 2;
 * @return {?proto.affiliator_user_package.GetDetailUserPackageResponseData}
 */
proto.affiliator_user_package.GetDetailUserPackageResponse.prototype.getData = function() {
  return /** @type{?proto.affiliator_user_package.GetDetailUserPackageResponseData} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_user_package.GetDetailUserPackageResponseData, 2));
};


/**
 * @param {?proto.affiliator_user_package.GetDetailUserPackageResponseData|undefined} value
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponse} returns this
*/
proto.affiliator_user_package.GetDetailUserPackageResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_user_package.GetDetailUserPackageResponse} returns this
 */
proto.affiliator_user_package.GetDetailUserPackageResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_user_package.GetDetailUserPackageResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.toObject = function(includeInstance, msg) {
  var f, obj = {
    commentatorId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    commentatorAccountLimit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator;
  return proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommentatorId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommentatorAccountLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommentatorId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommentatorAccountLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string commentator_id = 1;
 * @return {string}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.prototype.getCommentatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator} returns this
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.prototype.setCommentatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 commentator_account_limit = 2;
 * @return {number}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.prototype.getCommentatorAccountLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator} returns this
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.prototype.setCommentatorAccountLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userPackageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    commentatorIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    commentatorsList: jspb.Message.toObjectList(msg.getCommentatorsList(),
    proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequest}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.UserPackageOfferToCommentatorRequest;
  return proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequest}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPackageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCommentatorIds(value);
      break;
    case 3:
      var value = new proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator;
      reader.readMessage(value,proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.deserializeBinaryFromReader);
      msg.addCommentators(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserPackageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommentatorIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getCommentatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_package_id = 1;
 * @return {string}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.prototype.getUserPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequest} returns this
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.prototype.setUserPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string commentator_ids = 2;
 * @return {!Array<string>}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.prototype.getCommentatorIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequest} returns this
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.prototype.setCommentatorIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequest} returns this
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.prototype.addCommentatorIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequest} returns this
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.prototype.clearCommentatorIdsList = function() {
  return this.setCommentatorIdsList([]);
};


/**
 * repeated UserPackageOfferToCommentatorRequestCommentator commentators = 3;
 * @return {!Array<!proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator>}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.prototype.getCommentatorsList = function() {
  return /** @type{!Array<!proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator, 3));
};


/**
 * @param {!Array<!proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator>} value
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequest} returns this
*/
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.prototype.setCommentatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.prototype.addCommentators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.affiliator_user_package.UserPackageOfferToCommentatorRequestCommentator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorRequest} returns this
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorRequest.prototype.clearCommentatorsList = function() {
  return this.setCommentatorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData;
  return proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.UserPackageOfferToCommentatorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_user_package.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorResponse}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.UserPackageOfferToCommentatorResponse;
  return proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.UserPackageOfferToCommentatorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorResponse}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_user_package.Meta;
      reader.readMessage(value,proto.affiliator_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData;
      reader.readMessage(value,proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.UserPackageOfferToCommentatorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_user_package.Meta}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_user_package.Meta, 1));
};


/**
 * @param {?proto.affiliator_user_package.Meta|undefined} value
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorResponse} returns this
*/
proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorResponse} returns this
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserPackageOfferToCommentatorResponseData data = 2;
 * @return {?proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.prototype.getData = function() {
  return /** @type{?proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData, 2));
};


/**
 * @param {?proto.affiliator_user_package.UserPackageOfferToCommentatorResponseData|undefined} value
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorResponse} returns this
*/
proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_user_package.UserPackageOfferToCommentatorResponse} returns this
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_user_package.UserPackageOfferToCommentatorResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.CancelUserPackageCommentatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.CancelUserPackageCommentatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.CancelUserPackageCommentatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userPackageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    commentatorId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.CancelUserPackageCommentatorRequest}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.CancelUserPackageCommentatorRequest;
  return proto.affiliator_user_package.CancelUserPackageCommentatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.CancelUserPackageCommentatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.CancelUserPackageCommentatorRequest}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPackageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommentatorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.CancelUserPackageCommentatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.CancelUserPackageCommentatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.CancelUserPackageCommentatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserPackageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommentatorId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user_package_id = 1;
 * @return {string}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorRequest.prototype.getUserPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.CancelUserPackageCommentatorRequest} returns this
 */
proto.affiliator_user_package.CancelUserPackageCommentatorRequest.prototype.setUserPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string commentator_id = 2;
 * @return {string}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorRequest.prototype.getCommentatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.affiliator_user_package.CancelUserPackageCommentatorRequest} returns this
 */
proto.affiliator_user_package.CancelUserPackageCommentatorRequest.prototype.setCommentatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.CancelUserPackageCommentatorResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.CancelUserPackageCommentatorResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.CancelUserPackageCommentatorResponseData}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.CancelUserPackageCommentatorResponseData;
  return proto.affiliator_user_package.CancelUserPackageCommentatorResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.CancelUserPackageCommentatorResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.CancelUserPackageCommentatorResponseData}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.CancelUserPackageCommentatorResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.CancelUserPackageCommentatorResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.affiliator_user_package.CancelUserPackageCommentatorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.affiliator_user_package.CancelUserPackageCommentatorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.affiliator_user_package.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.affiliator_user_package.CancelUserPackageCommentatorResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.affiliator_user_package.CancelUserPackageCommentatorResponse}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.affiliator_user_package.CancelUserPackageCommentatorResponse;
  return proto.affiliator_user_package.CancelUserPackageCommentatorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.affiliator_user_package.CancelUserPackageCommentatorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.affiliator_user_package.CancelUserPackageCommentatorResponse}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.affiliator_user_package.Meta;
      reader.readMessage(value,proto.affiliator_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.affiliator_user_package.CancelUserPackageCommentatorResponseData;
      reader.readMessage(value,proto.affiliator_user_package.CancelUserPackageCommentatorResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.affiliator_user_package.CancelUserPackageCommentatorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.affiliator_user_package.CancelUserPackageCommentatorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.affiliator_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.affiliator_user_package.CancelUserPackageCommentatorResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.affiliator_user_package.Meta}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponse.prototype.getMeta = function() {
  return /** @type{?proto.affiliator_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_user_package.Meta, 1));
};


/**
 * @param {?proto.affiliator_user_package.Meta|undefined} value
 * @return {!proto.affiliator_user_package.CancelUserPackageCommentatorResponse} returns this
*/
proto.affiliator_user_package.CancelUserPackageCommentatorResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_user_package.CancelUserPackageCommentatorResponse} returns this
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CancelUserPackageCommentatorResponseData data = 2;
 * @return {?proto.affiliator_user_package.CancelUserPackageCommentatorResponseData}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponse.prototype.getData = function() {
  return /** @type{?proto.affiliator_user_package.CancelUserPackageCommentatorResponseData} */ (
    jspb.Message.getWrapperField(this, proto.affiliator_user_package.CancelUserPackageCommentatorResponseData, 2));
};


/**
 * @param {?proto.affiliator_user_package.CancelUserPackageCommentatorResponseData|undefined} value
 * @return {!proto.affiliator_user_package.CancelUserPackageCommentatorResponse} returns this
*/
proto.affiliator_user_package.CancelUserPackageCommentatorResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.affiliator_user_package.CancelUserPackageCommentatorResponse} returns this
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.affiliator_user_package.CancelUserPackageCommentatorResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.affiliator_user_package);
