import { useContext, useEffect, useState, type FC } from 'react';
import { FaStar } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, AvatarGroup, Checkbox, Drawer } from 'rsuite';
import { affiliatorCommentatorClient, affiliatorUserPackageClient } from '../../App';
import Back from '../../components/back';
import { GetCommentatorListRequest, GetCommentatorListResponseData, Pagination } from '../../grpc/affiliator/affiliator_commentator_pb';
import { GetDetailUserPackageRequest, GetDetailUserPackageResponseData, UserPackageOfferToCommentatorRequest } from '../../grpc/affiliator/affiliator_user_package_pb';
import { GetProfileResponseData } from '../../grpc/service/general_pb';
import AffiliatorMid from '../../middlewares/AffiliatorMid';
import { LoadingContext } from '../../objects/loading_context';
import { PENDING_AFFILIATOR, PENDING_COMMENTATOR } from '../../utils/contants';
import { getToken, initials, money } from '../../utils/helper';
import { errorToast, successToast } from '../../utils/helper-ui';

interface AffiliatorPackageDetailProps { }

const AffiliatorPackageDetail: FC<AffiliatorPackageDetailProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [mounted, setMounted] = useState(false);

    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const { packageId } = useParams()
    const [data, setData] = useState<GetDetailUserPackageResponseData.AsObject | null | undefined>(null);
    const nav = useNavigate()

    const [modalOfferingOpen, setModalOfferingOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [pagination, setPagination] = useState<Pagination.AsObject | null>(null);
    const [commentators, setCommentators] = useState<GetCommentatorListResponseData.AsObject[]>([]);

    const [selectedCommentators, setSelectedCommentators] = useState<GetCommentatorListResponseData.AsObject[]>([]);
    const [totalSlot, setTotalSlot] = useState(0);


    useEffect(() => {
        if (mounted)
            getCommentators()
    }, [mounted, page, limit]);

    useEffect(() => {
        setTotalSlot(selectedCommentators.map(e => e.totalAccounts).reduce((a, b) => a + b, 0))
    }, [selectedCommentators]);


    const getCommentators = async () => {
        try {
            setIsLoading(true)
            let resp: GetCommentatorListResponseData.AsObject[] = await new Promise((resolve, reject) => {
                let req = new GetCommentatorListRequest()
                req.setPage(page)
                req.setLimit(limit)

                affiliatorCommentatorClient.getCommentatorList(req, getToken(), (err, resp) => {
                    if (err) {
                        reject(err.message)
                        return
                    }
                    setPagination(resp.getPagination()?.toObject() ?? null)
                    resolve(resp.getDataList().map(e => e.toObject()))
                })
            })
            setCommentators([...commentators, ...resp])
            // setOrders([...orders, ...resp])
            // console.log(resp)
        } catch (error) {
            errorToast(`${error}`)
        } finally {
            setIsLoading(false)
        }
    }

    const renderDetail = () => (
        <>
            <div className='p-4 border-b border-b-gray-100'>
                <h5>Detail Pesanan</h5>
            </div>
            <div className='p-4 border-b border-b-gray-100'>
                <div className='flex justify-between py-2'>
                    <span className=''>Komentar per hari</span>
                    <span>{money(data?.commentPerDay)} / Hari</span>
                </div>
                <div className='flex justify-between py-2'>
                    <span className=''>Jumlah Hari</span>
                    <span>{money(data?.durationDay)} Hari</span>
                </div>
                <div className='flex justify-between py-2'>
                    <span className=''>Slot yang dibutuhkan</span>
                    <span>{money(data?.commentatorAccountSlot)}</span>
                </div>
                <div className='flex justify-between py-2'>
                    <span className=''>Sisa Slot</span>
                    <span>{money(data?.commentatorAccountRemainingSlot)}</span>
                </div>
                <div className='flex justify-between py-2'>
                    <span className=''>Komisi Affiliator</span>
                    <span className=' text-orange-400 font-bold'>Rp. {money(data?.affiliatorFee)}</span>
                </div>
                <div className='flex justify-between py-2'>
                    <span className=''>Komisi Komentator</span>
                    <span className=' text-orange-400 font-bold'>@Rp. {money(data?.commentatorFee)}</span>
                </div>

            </div></>)


    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return
        getDetail()
    }, [mounted]);


    const getDetail = async () => {
        try {
            setIsLoading(false)

            let resp: GetDetailUserPackageResponseData.AsObject = await new Promise((resolve, reject) => {
                let req = new GetDetailUserPackageRequest()
                req.setId(packageId!)
                affiliatorUserPackageClient.getDetailUserPackage(req, getToken(), (err, resp) => {
                    if (err) {
                        reject(err.message)
                        return
                    }
                    resolve(resp.getData()?.toObject()!)
                })
            })
            setData(resp)
        } catch (error) {
            errorToast(`${error}`)
        } finally {
            setIsLoading(false)
        }
    }


    return (<AffiliatorMid header={<div className='w-full px-4 py-2 justify-between items-center flex pt-6 bg-white'>
        <Back />
        <h6>Detail Pesanan</h6>
        <div className='p-2 '></div>
    </div>} onLoadProfile={setProfile} noPadding>
        {(data?.status == PENDING_AFFILIATOR || data?.status == PENDING_COMMENTATOR) && <>
            <div className='w-full  bg-slate-200 pt-9 pb-12 px-6 flex justify-between text-white items-center' style={{ backgroundImage: 'url(/assets/bg.png)', backgroundRepeat: 'no-repeat', backgroundPositionX: 0, backgroundPositionY: -100, backgroundSize: 'cover' }}>
                <div>
                    <h5 className='mb-2'>
                        {data?.status == PENDING_AFFILIATOR && "Menunggu Affiliator"}
                        {data?.status == PENDING_COMMENTATOR && "Menunggu Komentator"}
                    </h5>
                    <p className=' text-xs'>
                        {data?.status == PENDING_AFFILIATOR && `Menunggu Affiliator Mengambil Pesanan Klien`}
                        {data?.status == PENDING_COMMENTATOR && `Menunggu Komentator Mengambil Pesanan Klien`}
                    </p>
                </div>
                <div className='ml-4'>
                    <img src={`/assets/payment_paid.png`} alt="" className='w-10' />
                </div>
            </div>
            <div className=' rounded-t-xl -mt-6 bg-white mb-1'>

                {renderDetail()}

            </div>

            {(data?.status == PENDING_AFFILIATOR || data?.status == PENDING_COMMENTATOR) &&
                <>
                    <div className=' bg-white mb-1'>
                        <div className='p-4 border-b'>
                            <span className=' font-bold'>Affiliator</span>
                        </div>
                        <div className='p-4'>
                            {data.affiliator?.id ?
                                <div className='flex items-center'>
                                    <Avatar size={'sm'} circle src={data.affiliator?.profilePictureUrl} alt={data.affiliator?.profilePictureUrl ? data.affiliator.name : initials(data.affiliator?.name)} />
                                    <p className=' font-semibold ml-2'>{data.affiliator?.name}</p>
                                </div> : <p className='text-center'>Belum diambil</p>}
                        </div>
                    </div>
                    <div className=' bg-white mb-1'>
                        <div className='p-4 border-b flex justify-between'>
                            <span className=' font-bold'>Komentator</span>
                            <span>
                                Tersisa: {money(data.commentatorOffersList.length - data.commentatorsList.length)}
                            </span>
                        </div>
                        <div className='p-4'>

                            <ul>
                                
                                <AvatarGroup stack>
                                    {(data?.commentatorsList ?? [])
                                        .filter((user, i) => i < 5)
                                        .map(e => (
                                            <Avatar key={e.id} size={'sm'} bordered circle src={e.profilePictureUrl} alt={e.profilePictureUrl ? e.name : initials(e.name)} />
                                        ))}
                                    {(data?.commentatorsList ?? []).length > 5 &&
                                    <Avatar size={'sm'} bordered circle style={{ background: '#111' }}>
                                        +{(data?.commentatorsList ?? []).length - 5}
                                    </Avatar>
                                    }
                                </AvatarGroup>
                            </ul>
                            {data.commentatorsList.length == 0 && <p className='text-center'>Belum diambil</p>}
                        </div>
                    </div>
                </>
            }
            <div className='p-4'>

                <button className="btn w-full" onClick={() => {
                    setModalOfferingOpen(true)
                }}>Tawarkan Ke Komentator</button>
            </div>
        </>
        }
        <Drawer className='drawer-rounded' size={"full"} closeButton={null} placement={"bottom"} open={modalOfferingOpen} onClose={() => setModalOfferingOpen(false)}>
            <Drawer.Body style={{ padding: 0 }}>
                <div className='flex flex-col h-full'>
                    <div className='w-full px-4 py-2 justify-between items-center flex pt-6 bg-white border-b'>
                        <Back onClick={() => setModalOfferingOpen(false)} />
                        <h6>Pilih Komentator</h6>
                        <div className='p-2 '></div>
                    </div>
                    <div className='flex flex-1 flex-col'>
                        {(data?.commentatorAccountRemainingSlot ?? 0) - totalSlot > 0 &&
                            <div className=' bg-orange-200 p-4 flex justify-between'>
                                <span>Sisa Slot</span>
                                <span className=' font-semibold'>{money((data?.commentatorAccountRemainingSlot ?? 0) - totalSlot)}</span>
                            </div>
                        }
                        <div className='flex-1  overflow-y-auto p-4'>
                            <div className='bg-white px-4  mb-2'>
                                <div className='grid grid-cols-12 gap-4'>
                                    <div className='py-4 font-semibold col-span-4'>Nama</div>
                                    <div className='py-4 font-semibold col-span-3'>Rating</div>
                                    <div className='py-4 font-semibold col-span-2'>Akun</div>
                                    <div className='py-4 font-semibold col-span-2'>Client</div>
                                    <div className='py-2 font-semibold text-sky-400'><Checkbox className='p-0' checked={selectedCommentators.length == commentators.length} onClick={() => {
                                        if (selectedCommentators.length == commentators.length) {
                                            setSelectedCommentators([])
                                        } else {
                                            setSelectedCommentators([...commentators])
                                        }
                                    }} /></div>
                                </div>
                                {commentators.map(e => <div key={e.id}>
                                    <div className='grid grid-cols-12 gap-4'>
                                        <div className='py-4  col-span-4'>{e.name}</div>
                                        <div className='py-4  col-span-3 flex items-center'><FaStar size={8} className='text-orange-300 mr-2' /> {money(e.rating / 2, 2)}</div>
                                        <div className='py-4  col-span-2'>{money(e.totalAccounts)}</div>
                                        <div className='py-4  col-span-2'>{money(e.totalClient)}</div>
                                        <div className='py-2  text-sky-400'><Checkbox disabled={data?.commentatorOffersList.map(e => e.id).includes(e.id)} className='p-0' checked={selectedCommentators.map(e => e.id).includes(e.id)} onClick={() => {
                                            if (selectedCommentators.map(e => e.id).includes(e.id)) {
                                                setSelectedCommentators([...selectedCommentators.filter(s => s.id != e.id)])
                                            } else {
                                                setSelectedCommentators([...selectedCommentators, e])
                                            }
                                        }} /></div>
                                    </div>
                                </div>)}

                                <div className=' justify-center items-start flex'>
                                    {(pagination?.totalPages ?? 0) > 0 && (pagination?.next ?? 0) > page && <button className='btn-white w-48' onClick={() => {
                                        setPage(page + 1)
                                    }}>More</button>}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-4'>
                        
                        {!((data?.commentatorAccountRemainingSlot ?? 0) - totalSlot > 0) &&
                            <button onClick={() => {
                                setIsLoading(true)
                                let req = new UserPackageOfferToCommentatorRequest()
                                req.setUserPackageId(data!.id)
                                // console.log(selectedCommentators.filter(s => !data?.commentatorOffersList.map(e => e.id).includes(s.id)).map(e => e.id))
                                // return
                                req.setCommentatorIdsList(selectedCommentators.filter(s => !data?.commentatorOffersList.map(e => e.id).includes(s.id)).map(e => e.id))
                                affiliatorUserPackageClient.userPackageOfferToCommentator(req, getToken(), (err, resp) => {
                                    setIsLoading(false)
                                    if (err) {
                                        errorToast(err.message)
                                        return
                                    }
                                    successToast(`Penawaran telah dikirim ke komentator`)
                                    nav(`/affiliator/package?tab=commentator`)
                                })
                            }} type="button" className="btn w-full">Kirim</button>
                        }
                    </div>
                </div>
            </Drawer.Body>
        </Drawer>
    </AffiliatorMid>);
}
export default AffiliatorPackageDetail;