import { useContext, useEffect, useState, type FC } from "react";
import AdminMid from "../../middlewares/AdminMid";
import { LoadingContext } from "../../objects/loading_context";
import { useNavigate } from "react-router-dom";
import { GetProfileResponseData } from "../../grpc/client/client_general_pb";
import Back from "../../components/back";
import {
  DECLINED,
  DONE,
  IN_PROGRESS,
  MUTATION,
  NOT_REQUEST,
  PENDING_REQUEST,
  PENDING_WITHDRAW,
  REJECTED,
  TO_DO,
} from "../../utils/contants";
import { adminUserPackageClient, adminWithdrawClient } from "../../App";
import {
  WithdrawRequestCountRequest,
  WithdrawRequestCountResponseData,
} from "../../grpc/admin/admin_withdraw_request_pb";
import { getToken, money } from "../../utils/helper";
import { errorToast } from "../../utils/helper-ui";
import { GetListUserPackageRequest } from "../../grpc/admin/admin_user_package_pb";
import WithdrawCard from "../../components/admin/WithdrawCard";
import { Drawer } from "rsuite";

interface AdminWidthdrawProps {}

const AdminWidthdraw: FC<AdminWidthdrawProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const nav = useNavigate();
  const [mounted, setMounted] = useState(false);
  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const [mode, setMode] = useState(PENDING_REQUEST);
  const [countRequest, setCountRequest] =
    useState<WithdrawRequestCountResponseData.AsObject | null>(null);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    // let req = new GetListUserPackageRequest()
    // req.setPaymentStatus(PENDING_WITHDRAW)
    // req.setShowUserName(true)
    // adminUserPackageClient.getListUserPackage(req,  getToken(),
    // (err, resp) => {
    //   if (err) {
    //     errorToast(err.message);
    //     setIsLoading(false);
    //     return;
    //   }

    //   console.log("getListUserPackage", resp.getDataList()?.map(e => e.toObject()));
    //   setIsLoading(false);
    // })
    getCount();
  }, [mounted]);

  const getCount = () => {
    adminWithdrawClient.withdrawRequestCount(
      new WithdrawRequestCountRequest(),
      getToken(),
      (err, resp) => {
        if (err) {
          errorToast(err.message);
          setIsLoading(false);
          return;
        }

        setCountRequest(resp.getData()?.toObject() ?? null);
        setIsLoading(false);
      }
    );
  };
  return (
    <AdminMid onLoadProfile={setProfile} hideMenu hideHeader noPadding>
      <div className="flex flex-col h-full">
        <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white">
          <Back />
          <h6>Permintaan Komisi</h6>
          <div className="p-2"></div>
        </div>
        <div className="">
          <div className="scrollmenu bg-white border-b">
            <div
              onClick={() => setMode(PENDING_REQUEST)}
              className={`${mode == PENDING_REQUEST && "active"}`}
            >
              Request{" "}
              {countRequest?.inProgress != 0
                ? `(${money(countRequest?.inProgress)})`
                : ""}
            </div>
            <div
              onClick={() => setMode(NOT_REQUEST)}
              className={`${mode == NOT_REQUEST && "active"}`}
            >
              Belum Request{" "}
              {countRequest?.toDo != 0 ? `(${money(countRequest?.toDo)})` : ""}
            </div>
            <div
              onClick={() => setMode(DONE)}
              className={`${mode == DONE && "active"}`}
            >
              Selesai
            </div>
            <div
              onClick={() => setMode(REJECTED)}
              className={`${mode == REJECTED && "active"}`}
            >
              Ditolak
            </div>
          </div>
        </div>
        <div className="p-4 bg-white my-2">
          {mode == PENDING_REQUEST && (
            <WithdrawCard
              onApprove={() => {
                getCount();
              }}
              status={IN_PROGRESS}
            />
          )}
          {mode == NOT_REQUEST && (
            <WithdrawCard
              onApprove={() => {
                getCount();
              }}
              status={TO_DO}
            />
          )}
          {mode == DONE && (
            <WithdrawCard
              onApprove={() => {
                getCount();
              }}
              status={DONE}
            />
          )}
          {mode == REJECTED && (
            <WithdrawCard
              onApprove={() => {
                getCount();
              }}
              status={DECLINED}
            />
          )}
        </div>
      </div>
    </AdminMid>
  );
};
export default AdminWidthdraw;
