/**
 * @fileoverview gRPC-Web generated client stub for commentator_account
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: commentator_account.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.commentator_account = require('./commentator_account_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.commentator_account.CommentatorAccountHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.commentator_account.CommentatorAccountHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_account.GetListCommentatorAccountRequest,
 *   !proto.commentator_account.GetListCommentatorAccountResponse>}
 */
const methodDescriptor_CommentatorAccountHandler_GetListCommentatorAccount = new grpc.web.MethodDescriptor(
  '/commentator_account.CommentatorAccountHandler/GetListCommentatorAccount',
  grpc.web.MethodType.UNARY,
  proto.commentator_account.GetListCommentatorAccountRequest,
  proto.commentator_account.GetListCommentatorAccountResponse,
  /**
   * @param {!proto.commentator_account.GetListCommentatorAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_account.GetListCommentatorAccountResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_account.GetListCommentatorAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_account.GetListCommentatorAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_account.GetListCommentatorAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_account.CommentatorAccountHandlerClient.prototype.getListCommentatorAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_account.CommentatorAccountHandler/GetListCommentatorAccount',
      request,
      metadata || {},
      methodDescriptor_CommentatorAccountHandler_GetListCommentatorAccount,
      callback);
};


/**
 * @param {!proto.commentator_account.GetListCommentatorAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_account.GetListCommentatorAccountResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_account.CommentatorAccountHandlerPromiseClient.prototype.getListCommentatorAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_account.CommentatorAccountHandler/GetListCommentatorAccount',
      request,
      metadata || {},
      methodDescriptor_CommentatorAccountHandler_GetListCommentatorAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_account.GetDetailCommentatorAccountRequest,
 *   !proto.commentator_account.GetDetailCommentatorAccountResponse>}
 */
const methodDescriptor_CommentatorAccountHandler_GetDetailCommentatorAccount = new grpc.web.MethodDescriptor(
  '/commentator_account.CommentatorAccountHandler/GetDetailCommentatorAccount',
  grpc.web.MethodType.UNARY,
  proto.commentator_account.GetDetailCommentatorAccountRequest,
  proto.commentator_account.GetDetailCommentatorAccountResponse,
  /**
   * @param {!proto.commentator_account.GetDetailCommentatorAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_account.GetDetailCommentatorAccountResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_account.GetDetailCommentatorAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_account.GetDetailCommentatorAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_account.GetDetailCommentatorAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_account.CommentatorAccountHandlerClient.prototype.getDetailCommentatorAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_account.CommentatorAccountHandler/GetDetailCommentatorAccount',
      request,
      metadata || {},
      methodDescriptor_CommentatorAccountHandler_GetDetailCommentatorAccount,
      callback);
};


/**
 * @param {!proto.commentator_account.GetDetailCommentatorAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_account.GetDetailCommentatorAccountResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_account.CommentatorAccountHandlerPromiseClient.prototype.getDetailCommentatorAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_account.CommentatorAccountHandler/GetDetailCommentatorAccount',
      request,
      metadata || {},
      methodDescriptor_CommentatorAccountHandler_GetDetailCommentatorAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_account.GetCommentatorAccountClientRequest,
 *   !proto.commentator_account.GetCommentatorAccountClientResponse>}
 */
const methodDescriptor_CommentatorAccountHandler_GetCommentatorAccountClient = new grpc.web.MethodDescriptor(
  '/commentator_account.CommentatorAccountHandler/GetCommentatorAccountClient',
  grpc.web.MethodType.UNARY,
  proto.commentator_account.GetCommentatorAccountClientRequest,
  proto.commentator_account.GetCommentatorAccountClientResponse,
  /**
   * @param {!proto.commentator_account.GetCommentatorAccountClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_account.GetCommentatorAccountClientResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_account.GetCommentatorAccountClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_account.GetCommentatorAccountClientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_account.GetCommentatorAccountClientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_account.CommentatorAccountHandlerClient.prototype.getCommentatorAccountClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_account.CommentatorAccountHandler/GetCommentatorAccountClient',
      request,
      metadata || {},
      methodDescriptor_CommentatorAccountHandler_GetCommentatorAccountClient,
      callback);
};


/**
 * @param {!proto.commentator_account.GetCommentatorAccountClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_account.GetCommentatorAccountClientResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_account.CommentatorAccountHandlerPromiseClient.prototype.getCommentatorAccountClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_account.CommentatorAccountHandler/GetCommentatorAccountClient',
      request,
      metadata || {},
      methodDescriptor_CommentatorAccountHandler_GetCommentatorAccountClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_account.CreateCommentatorAccountRequest,
 *   !proto.commentator_account.CreateCommentatorAccountResponse>}
 */
const methodDescriptor_CommentatorAccountHandler_CreateCommentatorAccount = new grpc.web.MethodDescriptor(
  '/commentator_account.CommentatorAccountHandler/CreateCommentatorAccount',
  grpc.web.MethodType.UNARY,
  proto.commentator_account.CreateCommentatorAccountRequest,
  proto.commentator_account.CreateCommentatorAccountResponse,
  /**
   * @param {!proto.commentator_account.CreateCommentatorAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_account.CreateCommentatorAccountResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_account.CreateCommentatorAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_account.CreateCommentatorAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_account.CreateCommentatorAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_account.CommentatorAccountHandlerClient.prototype.createCommentatorAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_account.CommentatorAccountHandler/CreateCommentatorAccount',
      request,
      metadata || {},
      methodDescriptor_CommentatorAccountHandler_CreateCommentatorAccount,
      callback);
};


/**
 * @param {!proto.commentator_account.CreateCommentatorAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_account.CreateCommentatorAccountResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_account.CommentatorAccountHandlerPromiseClient.prototype.createCommentatorAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_account.CommentatorAccountHandler/CreateCommentatorAccount',
      request,
      metadata || {},
      methodDescriptor_CommentatorAccountHandler_CreateCommentatorAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_account.UpdateCommentatorAccountRequest,
 *   !proto.commentator_account.UpdateCommentatorAccountResponse>}
 */
const methodDescriptor_CommentatorAccountHandler_UpdateCommentatorAccount = new grpc.web.MethodDescriptor(
  '/commentator_account.CommentatorAccountHandler/UpdateCommentatorAccount',
  grpc.web.MethodType.UNARY,
  proto.commentator_account.UpdateCommentatorAccountRequest,
  proto.commentator_account.UpdateCommentatorAccountResponse,
  /**
   * @param {!proto.commentator_account.UpdateCommentatorAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_account.UpdateCommentatorAccountResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_account.UpdateCommentatorAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_account.UpdateCommentatorAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_account.UpdateCommentatorAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_account.CommentatorAccountHandlerClient.prototype.updateCommentatorAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_account.CommentatorAccountHandler/UpdateCommentatorAccount',
      request,
      metadata || {},
      methodDescriptor_CommentatorAccountHandler_UpdateCommentatorAccount,
      callback);
};


/**
 * @param {!proto.commentator_account.UpdateCommentatorAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_account.UpdateCommentatorAccountResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_account.CommentatorAccountHandlerPromiseClient.prototype.updateCommentatorAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_account.CommentatorAccountHandler/UpdateCommentatorAccount',
      request,
      metadata || {},
      methodDescriptor_CommentatorAccountHandler_UpdateCommentatorAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_account.SetCommentatorAccountIsActiveRequest,
 *   !proto.commentator_account.SetCommentatorAccountIsActiveResponse>}
 */
const methodDescriptor_CommentatorAccountHandler_SetCommentatorAccountIsActive = new grpc.web.MethodDescriptor(
  '/commentator_account.CommentatorAccountHandler/SetCommentatorAccountIsActive',
  grpc.web.MethodType.UNARY,
  proto.commentator_account.SetCommentatorAccountIsActiveRequest,
  proto.commentator_account.SetCommentatorAccountIsActiveResponse,
  /**
   * @param {!proto.commentator_account.SetCommentatorAccountIsActiveRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_account.SetCommentatorAccountIsActiveResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_account.SetCommentatorAccountIsActiveRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_account.SetCommentatorAccountIsActiveResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_account.SetCommentatorAccountIsActiveResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_account.CommentatorAccountHandlerClient.prototype.setCommentatorAccountIsActive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_account.CommentatorAccountHandler/SetCommentatorAccountIsActive',
      request,
      metadata || {},
      methodDescriptor_CommentatorAccountHandler_SetCommentatorAccountIsActive,
      callback);
};


/**
 * @param {!proto.commentator_account.SetCommentatorAccountIsActiveRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_account.SetCommentatorAccountIsActiveResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_account.CommentatorAccountHandlerPromiseClient.prototype.setCommentatorAccountIsActive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_account.CommentatorAccountHandler/SetCommentatorAccountIsActive',
      request,
      metadata || {},
      methodDescriptor_CommentatorAccountHandler_SetCommentatorAccountIsActive);
};


module.exports = proto.commentator_account;

