// source: commentator_account.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.commentator_account.CreateCommentatorAccountRequest', null, global);
goog.exportSymbol('proto.commentator_account.CreateCommentatorAccountResponse', null, global);
goog.exportSymbol('proto.commentator_account.CreateCommentatorAccountResponseData', null, global);
goog.exportSymbol('proto.commentator_account.GetCommentatorAccountClientRequest', null, global);
goog.exportSymbol('proto.commentator_account.GetCommentatorAccountClientResponse', null, global);
goog.exportSymbol('proto.commentator_account.GetCommentatorAccountClientResponseData', null, global);
goog.exportSymbol('proto.commentator_account.GetDetailCommentatorAccountRequest', null, global);
goog.exportSymbol('proto.commentator_account.GetDetailCommentatorAccountResponse', null, global);
goog.exportSymbol('proto.commentator_account.GetDetailCommentatorAccountResponseData', null, global);
goog.exportSymbol('proto.commentator_account.GetListCommentatorAccountRequest', null, global);
goog.exportSymbol('proto.commentator_account.GetListCommentatorAccountResponse', null, global);
goog.exportSymbol('proto.commentator_account.GetListCommentatorAccountResponseData', null, global);
goog.exportSymbol('proto.commentator_account.Meta', null, global);
goog.exportSymbol('proto.commentator_account.Pagination', null, global);
goog.exportSymbol('proto.commentator_account.SetCommentatorAccountIsActiveRequest', null, global);
goog.exportSymbol('proto.commentator_account.SetCommentatorAccountIsActiveResponse', null, global);
goog.exportSymbol('proto.commentator_account.SetCommentatorAccountIsActiveResponseData', null, global);
goog.exportSymbol('proto.commentator_account.UpdateCommentatorAccountRequest', null, global);
goog.exportSymbol('proto.commentator_account.UpdateCommentatorAccountResponse', null, global);
goog.exportSymbol('proto.commentator_account.UpdateCommentatorAccountResponseData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.Meta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.Meta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.Meta.displayName = 'proto.commentator_account.Meta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.Pagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.Pagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.Pagination.displayName = 'proto.commentator_account.Pagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.GetListCommentatorAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.GetListCommentatorAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.GetListCommentatorAccountRequest.displayName = 'proto.commentator_account.GetListCommentatorAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.GetListCommentatorAccountResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.GetListCommentatorAccountResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.GetListCommentatorAccountResponseData.displayName = 'proto.commentator_account.GetListCommentatorAccountResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.GetListCommentatorAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commentator_account.GetListCommentatorAccountResponse.repeatedFields_, null);
};
goog.inherits(proto.commentator_account.GetListCommentatorAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.GetListCommentatorAccountResponse.displayName = 'proto.commentator_account.GetListCommentatorAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.GetDetailCommentatorAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.GetDetailCommentatorAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.GetDetailCommentatorAccountRequest.displayName = 'proto.commentator_account.GetDetailCommentatorAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.GetDetailCommentatorAccountResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.GetDetailCommentatorAccountResponseData.displayName = 'proto.commentator_account.GetDetailCommentatorAccountResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.GetDetailCommentatorAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.GetDetailCommentatorAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.GetDetailCommentatorAccountResponse.displayName = 'proto.commentator_account.GetDetailCommentatorAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.GetCommentatorAccountClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.GetCommentatorAccountClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.GetCommentatorAccountClientRequest.displayName = 'proto.commentator_account.GetCommentatorAccountClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.GetCommentatorAccountClientResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.GetCommentatorAccountClientResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.GetCommentatorAccountClientResponseData.displayName = 'proto.commentator_account.GetCommentatorAccountClientResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.GetCommentatorAccountClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commentator_account.GetCommentatorAccountClientResponse.repeatedFields_, null);
};
goog.inherits(proto.commentator_account.GetCommentatorAccountClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.GetCommentatorAccountClientResponse.displayName = 'proto.commentator_account.GetCommentatorAccountClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.CreateCommentatorAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.CreateCommentatorAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.CreateCommentatorAccountRequest.displayName = 'proto.commentator_account.CreateCommentatorAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.CreateCommentatorAccountResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.CreateCommentatorAccountResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.CreateCommentatorAccountResponseData.displayName = 'proto.commentator_account.CreateCommentatorAccountResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.CreateCommentatorAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.CreateCommentatorAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.CreateCommentatorAccountResponse.displayName = 'proto.commentator_account.CreateCommentatorAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.UpdateCommentatorAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.UpdateCommentatorAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.UpdateCommentatorAccountRequest.displayName = 'proto.commentator_account.UpdateCommentatorAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.UpdateCommentatorAccountResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.UpdateCommentatorAccountResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.UpdateCommentatorAccountResponseData.displayName = 'proto.commentator_account.UpdateCommentatorAccountResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.UpdateCommentatorAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.UpdateCommentatorAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.UpdateCommentatorAccountResponse.displayName = 'proto.commentator_account.UpdateCommentatorAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.SetCommentatorAccountIsActiveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.SetCommentatorAccountIsActiveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.SetCommentatorAccountIsActiveRequest.displayName = 'proto.commentator_account.SetCommentatorAccountIsActiveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.SetCommentatorAccountIsActiveResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.SetCommentatorAccountIsActiveResponseData.displayName = 'proto.commentator_account.SetCommentatorAccountIsActiveResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commentator_account.SetCommentatorAccountIsActiveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commentator_account.SetCommentatorAccountIsActiveResponse.displayName = 'proto.commentator_account.SetCommentatorAccountIsActiveResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.Meta.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.Meta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.Meta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.Meta.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.Meta}
 */
proto.commentator_account.Meta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.Meta;
  return proto.commentator_account.Meta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.Meta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.Meta}
 */
proto.commentator_account.Meta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.Meta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.Meta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.Meta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.Meta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.commentator_account.Meta.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.Meta} returns this
 */
proto.commentator_account.Meta.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 status = 2;
 * @return {number}
 */
proto.commentator_account.Meta.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.commentator_account.Meta} returns this
 */
proto.commentator_account.Meta.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.commentator_account.Meta.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.Meta} returns this
 */
proto.commentator_account.Meta.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.Pagination.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.Pagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.Pagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.Pagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    prev: jspb.Message.getFieldWithDefault(msg, 3, 0),
    next: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.Pagination}
 */
proto.commentator_account.Pagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.Pagination;
  return proto.commentator_account.Pagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.Pagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.Pagination}
 */
proto.commentator_account.Pagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrev(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNext(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPages(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.Pagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.Pagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.Pagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.Pagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPrev();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNext();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.commentator_account.Pagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.commentator_account.Pagination} returns this
 */
proto.commentator_account.Pagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.commentator_account.Pagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.commentator_account.Pagination} returns this
 */
proto.commentator_account.Pagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 prev = 3;
 * @return {number}
 */
proto.commentator_account.Pagination.prototype.getPrev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.commentator_account.Pagination} returns this
 */
proto.commentator_account.Pagination.prototype.setPrev = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 next = 4;
 * @return {number}
 */
proto.commentator_account.Pagination.prototype.getNext = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.commentator_account.Pagination} returns this
 */
proto.commentator_account.Pagination.prototype.setNext = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 total_pages = 5;
 * @return {number}
 */
proto.commentator_account.Pagination.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.commentator_account.Pagination} returns this
 */
proto.commentator_account.Pagination.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 total_records = 6;
 * @return {number}
 */
proto.commentator_account.Pagination.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.commentator_account.Pagination} returns this
 */
proto.commentator_account.Pagination.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.GetListCommentatorAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.GetListCommentatorAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.GetListCommentatorAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetListCommentatorAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.GetListCommentatorAccountRequest}
 */
proto.commentator_account.GetListCommentatorAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.GetListCommentatorAccountRequest;
  return proto.commentator_account.GetListCommentatorAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.GetListCommentatorAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.GetListCommentatorAccountRequest}
 */
proto.commentator_account.GetListCommentatorAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.GetListCommentatorAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.GetListCommentatorAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.GetListCommentatorAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetListCommentatorAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.GetListCommentatorAccountResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.GetListCommentatorAccountResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.GetListCommentatorAccountResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetListCommentatorAccountResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    notificationCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.GetListCommentatorAccountResponseData}
 */
proto.commentator_account.GetListCommentatorAccountResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.GetListCommentatorAccountResponseData;
  return proto.commentator_account.GetListCommentatorAccountResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.GetListCommentatorAccountResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.GetListCommentatorAccountResponseData}
 */
proto.commentator_account.GetListCommentatorAccountResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNotificationCount(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.GetListCommentatorAccountResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.GetListCommentatorAccountResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.GetListCommentatorAccountResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetListCommentatorAccountResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNotificationCount();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.commentator_account.GetListCommentatorAccountResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetListCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetListCommentatorAccountResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.commentator_account.GetListCommentatorAccountResponseData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetListCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetListCommentatorAccountResponseData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.commentator_account.GetListCommentatorAccountResponseData.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetListCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetListCommentatorAccountResponseData.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 notification_count = 4;
 * @return {number}
 */
proto.commentator_account.GetListCommentatorAccountResponseData.prototype.getNotificationCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.commentator_account.GetListCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetListCommentatorAccountResponseData.prototype.setNotificationCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_active = 5;
 * @return {boolean}
 */
proto.commentator_account.GetListCommentatorAccountResponseData.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commentator_account.GetListCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetListCommentatorAccountResponseData.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commentator_account.GetListCommentatorAccountResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.GetListCommentatorAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.GetListCommentatorAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.GetListCommentatorAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetListCommentatorAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.commentator_account.Meta.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.commentator_account.GetListCommentatorAccountResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.GetListCommentatorAccountResponse}
 */
proto.commentator_account.GetListCommentatorAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.GetListCommentatorAccountResponse;
  return proto.commentator_account.GetListCommentatorAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.GetListCommentatorAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.GetListCommentatorAccountResponse}
 */
proto.commentator_account.GetListCommentatorAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.commentator_account.Meta;
      reader.readMessage(value,proto.commentator_account.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.commentator_account.GetListCommentatorAccountResponseData;
      reader.readMessage(value,proto.commentator_account.GetListCommentatorAccountResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.GetListCommentatorAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.GetListCommentatorAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.GetListCommentatorAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetListCommentatorAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.commentator_account.Meta.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.commentator_account.GetListCommentatorAccountResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.commentator_account.Meta}
 */
proto.commentator_account.GetListCommentatorAccountResponse.prototype.getMeta = function() {
  return /** @type{?proto.commentator_account.Meta} */ (
    jspb.Message.getWrapperField(this, proto.commentator_account.Meta, 1));
};


/**
 * @param {?proto.commentator_account.Meta|undefined} value
 * @return {!proto.commentator_account.GetListCommentatorAccountResponse} returns this
*/
proto.commentator_account.GetListCommentatorAccountResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commentator_account.GetListCommentatorAccountResponse} returns this
 */
proto.commentator_account.GetListCommentatorAccountResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commentator_account.GetListCommentatorAccountResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated GetListCommentatorAccountResponseData data = 2;
 * @return {!Array<!proto.commentator_account.GetListCommentatorAccountResponseData>}
 */
proto.commentator_account.GetListCommentatorAccountResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.commentator_account.GetListCommentatorAccountResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.commentator_account.GetListCommentatorAccountResponseData, 2));
};


/**
 * @param {!Array<!proto.commentator_account.GetListCommentatorAccountResponseData>} value
 * @return {!proto.commentator_account.GetListCommentatorAccountResponse} returns this
*/
proto.commentator_account.GetListCommentatorAccountResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.commentator_account.GetListCommentatorAccountResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.commentator_account.GetListCommentatorAccountResponseData}
 */
proto.commentator_account.GetListCommentatorAccountResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.commentator_account.GetListCommentatorAccountResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commentator_account.GetListCommentatorAccountResponse} returns this
 */
proto.commentator_account.GetListCommentatorAccountResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.GetDetailCommentatorAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.GetDetailCommentatorAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.GetDetailCommentatorAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetDetailCommentatorAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.GetDetailCommentatorAccountRequest}
 */
proto.commentator_account.GetDetailCommentatorAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.GetDetailCommentatorAccountRequest;
  return proto.commentator_account.GetDetailCommentatorAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.GetDetailCommentatorAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.GetDetailCommentatorAccountRequest}
 */
proto.commentator_account.GetDetailCommentatorAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.GetDetailCommentatorAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.GetDetailCommentatorAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.GetDetailCommentatorAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetDetailCommentatorAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.commentator_account.GetDetailCommentatorAccountRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountRequest} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.GetDetailCommentatorAccountResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.GetDetailCommentatorAccountResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profilePicturePath: jspb.Message.getFieldWithDefault(msg, 4, ""),
    profilePicturePathType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    idInstagram: jspb.Message.getFieldWithDefault(msg, 6, ""),
    idTiktok: jspb.Message.getFieldWithDefault(msg, 7, ""),
    idTwitter: jspb.Message.getFieldWithDefault(msg, 8, ""),
    idFacebook: jspb.Message.getFieldWithDefault(msg, 9, ""),
    idYoutube: jspb.Message.getFieldWithDefault(msg, 10, ""),
    idDetik: jspb.Message.getFieldWithDefault(msg, 11, ""),
    clientCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    idThreads: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponseData}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.GetDetailCommentatorAccountResponseData;
  return proto.commentator_account.GetDetailCommentatorAccountResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.GetDetailCommentatorAccountResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponseData}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicturePath(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicturePathType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdInstagram(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdTiktok(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdTwitter(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdFacebook(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdYoutube(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdDetik(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setClientCount(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdThreads(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.GetDetailCommentatorAccountResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.GetDetailCommentatorAccountResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfilePicturePath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProfilePicturePathType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIdInstagram();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIdTiktok();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIdTwitter();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIdFacebook();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIdYoutube();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIdDetik();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getClientCount();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getIdThreads();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string profile_picture_path = 4;
 * @return {string}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.getProfilePicturePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.setProfilePicturePath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string profile_picture_path_type = 5;
 * @return {string}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.getProfilePicturePathType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.setProfilePicturePathType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string id_instagram = 6;
 * @return {string}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.getIdInstagram = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.setIdInstagram = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string id_tiktok = 7;
 * @return {string}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.getIdTiktok = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.setIdTiktok = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string id_twitter = 8;
 * @return {string}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.getIdTwitter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.setIdTwitter = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string id_facebook = 9;
 * @return {string}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.getIdFacebook = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.setIdFacebook = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string id_youtube = 10;
 * @return {string}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.getIdYoutube = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.setIdYoutube = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string id_detik = 11;
 * @return {string}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.getIdDetik = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.setIdDetik = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional uint32 client_count = 12;
 * @return {number}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.getClientCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.setClientCount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool is_active = 13;
 * @return {boolean}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string id_threads = 14;
 * @return {string}
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.getIdThreads = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponseData} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountResponseData.prototype.setIdThreads = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.GetDetailCommentatorAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.GetDetailCommentatorAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.GetDetailCommentatorAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetDetailCommentatorAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.commentator_account.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.commentator_account.GetDetailCommentatorAccountResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponse}
 */
proto.commentator_account.GetDetailCommentatorAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.GetDetailCommentatorAccountResponse;
  return proto.commentator_account.GetDetailCommentatorAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.GetDetailCommentatorAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponse}
 */
proto.commentator_account.GetDetailCommentatorAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.commentator_account.Meta;
      reader.readMessage(value,proto.commentator_account.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.commentator_account.GetDetailCommentatorAccountResponseData;
      reader.readMessage(value,proto.commentator_account.GetDetailCommentatorAccountResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.GetDetailCommentatorAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.GetDetailCommentatorAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.GetDetailCommentatorAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetDetailCommentatorAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.commentator_account.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.commentator_account.GetDetailCommentatorAccountResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.commentator_account.Meta}
 */
proto.commentator_account.GetDetailCommentatorAccountResponse.prototype.getMeta = function() {
  return /** @type{?proto.commentator_account.Meta} */ (
    jspb.Message.getWrapperField(this, proto.commentator_account.Meta, 1));
};


/**
 * @param {?proto.commentator_account.Meta|undefined} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponse} returns this
*/
proto.commentator_account.GetDetailCommentatorAccountResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponse} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commentator_account.GetDetailCommentatorAccountResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetDetailCommentatorAccountResponseData data = 2;
 * @return {?proto.commentator_account.GetDetailCommentatorAccountResponseData}
 */
proto.commentator_account.GetDetailCommentatorAccountResponse.prototype.getData = function() {
  return /** @type{?proto.commentator_account.GetDetailCommentatorAccountResponseData} */ (
    jspb.Message.getWrapperField(this, proto.commentator_account.GetDetailCommentatorAccountResponseData, 2));
};


/**
 * @param {?proto.commentator_account.GetDetailCommentatorAccountResponseData|undefined} value
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponse} returns this
*/
proto.commentator_account.GetDetailCommentatorAccountResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commentator_account.GetDetailCommentatorAccountResponse} returns this
 */
proto.commentator_account.GetDetailCommentatorAccountResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commentator_account.GetDetailCommentatorAccountResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.GetCommentatorAccountClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.GetCommentatorAccountClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.GetCommentatorAccountClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetCommentatorAccountClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    search: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sortBy: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.GetCommentatorAccountClientRequest}
 */
proto.commentator_account.GetCommentatorAccountClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.GetCommentatorAccountClientRequest;
  return proto.commentator_account.GetCommentatorAccountClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.GetCommentatorAccountClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.GetCommentatorAccountClientRequest}
 */
proto.commentator_account.GetCommentatorAccountClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSort(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.GetCommentatorAccountClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.GetCommentatorAccountClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.GetCommentatorAccountClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetCommentatorAccountClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSort();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSortBy();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.commentator_account.GetCommentatorAccountClientRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.commentator_account.GetCommentatorAccountClientRequest} returns this
 */
proto.commentator_account.GetCommentatorAccountClientRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.commentator_account.GetCommentatorAccountClientRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.commentator_account.GetCommentatorAccountClientRequest} returns this
 */
proto.commentator_account.GetCommentatorAccountClientRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string search = 3;
 * @return {string}
 */
proto.commentator_account.GetCommentatorAccountClientRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetCommentatorAccountClientRequest} returns this
 */
proto.commentator_account.GetCommentatorAccountClientRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sort = 4;
 * @return {string}
 */
proto.commentator_account.GetCommentatorAccountClientRequest.prototype.getSort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetCommentatorAccountClientRequest} returns this
 */
proto.commentator_account.GetCommentatorAccountClientRequest.prototype.setSort = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string sort_by = 5;
 * @return {string}
 */
proto.commentator_account.GetCommentatorAccountClientRequest.prototype.getSortBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetCommentatorAccountClientRequest} returns this
 */
proto.commentator_account.GetCommentatorAccountClientRequest.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.GetCommentatorAccountClientResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.GetCommentatorAccountClientResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.GetCommentatorAccountClientResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetCommentatorAccountClientResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    commentPerDay: jspb.Message.getFieldWithDefault(msg, 4, 0),
    expiryTime: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.GetCommentatorAccountClientResponseData}
 */
proto.commentator_account.GetCommentatorAccountClientResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.GetCommentatorAccountClientResponseData;
  return proto.commentator_account.GetCommentatorAccountClientResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.GetCommentatorAccountClientResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.GetCommentatorAccountClientResponseData}
 */
proto.commentator_account.GetCommentatorAccountClientResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommentPerDay(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiryTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.GetCommentatorAccountClientResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.GetCommentatorAccountClientResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.GetCommentatorAccountClientResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetCommentatorAccountClientResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCommentPerDay();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getExpiryTime();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.commentator_account.GetCommentatorAccountClientResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetCommentatorAccountClientResponseData} returns this
 */
proto.commentator_account.GetCommentatorAccountClientResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.commentator_account.GetCommentatorAccountClientResponseData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetCommentatorAccountClientResponseData} returns this
 */
proto.commentator_account.GetCommentatorAccountClientResponseData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.commentator_account.GetCommentatorAccountClientResponseData.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetCommentatorAccountClientResponseData} returns this
 */
proto.commentator_account.GetCommentatorAccountClientResponseData.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 comment_per_day = 4;
 * @return {number}
 */
proto.commentator_account.GetCommentatorAccountClientResponseData.prototype.getCommentPerDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.commentator_account.GetCommentatorAccountClientResponseData} returns this
 */
proto.commentator_account.GetCommentatorAccountClientResponseData.prototype.setCommentPerDay = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string expiry_time = 5;
 * @return {string}
 */
proto.commentator_account.GetCommentatorAccountClientResponseData.prototype.getExpiryTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.GetCommentatorAccountClientResponseData} returns this
 */
proto.commentator_account.GetCommentatorAccountClientResponseData.prototype.setExpiryTime = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commentator_account.GetCommentatorAccountClientResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.GetCommentatorAccountClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.GetCommentatorAccountClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.GetCommentatorAccountClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetCommentatorAccountClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.commentator_account.Meta.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.commentator_account.Pagination.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.commentator_account.GetCommentatorAccountClientResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.GetCommentatorAccountClientResponse}
 */
proto.commentator_account.GetCommentatorAccountClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.GetCommentatorAccountClientResponse;
  return proto.commentator_account.GetCommentatorAccountClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.GetCommentatorAccountClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.GetCommentatorAccountClientResponse}
 */
proto.commentator_account.GetCommentatorAccountClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.commentator_account.Meta;
      reader.readMessage(value,proto.commentator_account.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.commentator_account.Pagination;
      reader.readMessage(value,proto.commentator_account.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.commentator_account.GetCommentatorAccountClientResponseData;
      reader.readMessage(value,proto.commentator_account.GetCommentatorAccountClientResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.GetCommentatorAccountClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.GetCommentatorAccountClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.GetCommentatorAccountClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.GetCommentatorAccountClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.commentator_account.Meta.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.commentator_account.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.commentator_account.GetCommentatorAccountClientResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.commentator_account.Meta}
 */
proto.commentator_account.GetCommentatorAccountClientResponse.prototype.getMeta = function() {
  return /** @type{?proto.commentator_account.Meta} */ (
    jspb.Message.getWrapperField(this, proto.commentator_account.Meta, 1));
};


/**
 * @param {?proto.commentator_account.Meta|undefined} value
 * @return {!proto.commentator_account.GetCommentatorAccountClientResponse} returns this
*/
proto.commentator_account.GetCommentatorAccountClientResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commentator_account.GetCommentatorAccountClientResponse} returns this
 */
proto.commentator_account.GetCommentatorAccountClientResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commentator_account.GetCommentatorAccountClientResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.commentator_account.Pagination}
 */
proto.commentator_account.GetCommentatorAccountClientResponse.prototype.getPagination = function() {
  return /** @type{?proto.commentator_account.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.commentator_account.Pagination, 2));
};


/**
 * @param {?proto.commentator_account.Pagination|undefined} value
 * @return {!proto.commentator_account.GetCommentatorAccountClientResponse} returns this
*/
proto.commentator_account.GetCommentatorAccountClientResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commentator_account.GetCommentatorAccountClientResponse} returns this
 */
proto.commentator_account.GetCommentatorAccountClientResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commentator_account.GetCommentatorAccountClientResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated GetCommentatorAccountClientResponseData data = 3;
 * @return {!Array<!proto.commentator_account.GetCommentatorAccountClientResponseData>}
 */
proto.commentator_account.GetCommentatorAccountClientResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.commentator_account.GetCommentatorAccountClientResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.commentator_account.GetCommentatorAccountClientResponseData, 3));
};


/**
 * @param {!Array<!proto.commentator_account.GetCommentatorAccountClientResponseData>} value
 * @return {!proto.commentator_account.GetCommentatorAccountClientResponse} returns this
*/
proto.commentator_account.GetCommentatorAccountClientResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.commentator_account.GetCommentatorAccountClientResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.commentator_account.GetCommentatorAccountClientResponseData}
 */
proto.commentator_account.GetCommentatorAccountClientResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.commentator_account.GetCommentatorAccountClientResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commentator_account.GetCommentatorAccountClientResponse} returns this
 */
proto.commentator_account.GetCommentatorAccountClientResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.CreateCommentatorAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.CreateCommentatorAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.CreateCommentatorAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profilePicturePath: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePicturePathType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    idInstagram: jspb.Message.getFieldWithDefault(msg, 4, ""),
    idTiktok: jspb.Message.getFieldWithDefault(msg, 5, ""),
    idTwitter: jspb.Message.getFieldWithDefault(msg, 6, ""),
    idFacebook: jspb.Message.getFieldWithDefault(msg, 7, ""),
    idYoutube: jspb.Message.getFieldWithDefault(msg, 8, ""),
    idDetik: jspb.Message.getFieldWithDefault(msg, 9, ""),
    idThreads: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.CreateCommentatorAccountRequest}
 */
proto.commentator_account.CreateCommentatorAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.CreateCommentatorAccountRequest;
  return proto.commentator_account.CreateCommentatorAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.CreateCommentatorAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.CreateCommentatorAccountRequest}
 */
proto.commentator_account.CreateCommentatorAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicturePath(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicturePathType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdInstagram(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdTiktok(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdTwitter(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdFacebook(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdYoutube(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdDetik(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdThreads(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.CreateCommentatorAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.CreateCommentatorAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.CreateCommentatorAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfilePicturePath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePicturePathType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIdInstagram();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIdTiktok();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIdTwitter();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIdFacebook();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIdYoutube();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIdDetik();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIdThreads();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.CreateCommentatorAccountRequest} returns this
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string profile_picture_path = 2;
 * @return {string}
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.getProfilePicturePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.CreateCommentatorAccountRequest} returns this
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.setProfilePicturePath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_path_type = 3;
 * @return {string}
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.getProfilePicturePathType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.CreateCommentatorAccountRequest} returns this
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.setProfilePicturePathType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string id_instagram = 4;
 * @return {string}
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.getIdInstagram = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.CreateCommentatorAccountRequest} returns this
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.setIdInstagram = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string id_tiktok = 5;
 * @return {string}
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.getIdTiktok = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.CreateCommentatorAccountRequest} returns this
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.setIdTiktok = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string id_twitter = 6;
 * @return {string}
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.getIdTwitter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.CreateCommentatorAccountRequest} returns this
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.setIdTwitter = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string id_facebook = 7;
 * @return {string}
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.getIdFacebook = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.CreateCommentatorAccountRequest} returns this
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.setIdFacebook = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string id_youtube = 8;
 * @return {string}
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.getIdYoutube = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.CreateCommentatorAccountRequest} returns this
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.setIdYoutube = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string id_detik = 9;
 * @return {string}
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.getIdDetik = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.CreateCommentatorAccountRequest} returns this
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.setIdDetik = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string id_threads = 10;
 * @return {string}
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.getIdThreads = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.CreateCommentatorAccountRequest} returns this
 */
proto.commentator_account.CreateCommentatorAccountRequest.prototype.setIdThreads = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.CreateCommentatorAccountResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.CreateCommentatorAccountResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.CreateCommentatorAccountResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.CreateCommentatorAccountResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.CreateCommentatorAccountResponseData}
 */
proto.commentator_account.CreateCommentatorAccountResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.CreateCommentatorAccountResponseData;
  return proto.commentator_account.CreateCommentatorAccountResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.CreateCommentatorAccountResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.CreateCommentatorAccountResponseData}
 */
proto.commentator_account.CreateCommentatorAccountResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.CreateCommentatorAccountResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.CreateCommentatorAccountResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.CreateCommentatorAccountResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.CreateCommentatorAccountResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.CreateCommentatorAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.CreateCommentatorAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.CreateCommentatorAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.CreateCommentatorAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.commentator_account.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.commentator_account.CreateCommentatorAccountResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.CreateCommentatorAccountResponse}
 */
proto.commentator_account.CreateCommentatorAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.CreateCommentatorAccountResponse;
  return proto.commentator_account.CreateCommentatorAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.CreateCommentatorAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.CreateCommentatorAccountResponse}
 */
proto.commentator_account.CreateCommentatorAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.commentator_account.Meta;
      reader.readMessage(value,proto.commentator_account.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.commentator_account.CreateCommentatorAccountResponseData;
      reader.readMessage(value,proto.commentator_account.CreateCommentatorAccountResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.CreateCommentatorAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.CreateCommentatorAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.CreateCommentatorAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.CreateCommentatorAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.commentator_account.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.commentator_account.CreateCommentatorAccountResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.commentator_account.Meta}
 */
proto.commentator_account.CreateCommentatorAccountResponse.prototype.getMeta = function() {
  return /** @type{?proto.commentator_account.Meta} */ (
    jspb.Message.getWrapperField(this, proto.commentator_account.Meta, 1));
};


/**
 * @param {?proto.commentator_account.Meta|undefined} value
 * @return {!proto.commentator_account.CreateCommentatorAccountResponse} returns this
*/
proto.commentator_account.CreateCommentatorAccountResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commentator_account.CreateCommentatorAccountResponse} returns this
 */
proto.commentator_account.CreateCommentatorAccountResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commentator_account.CreateCommentatorAccountResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateCommentatorAccountResponseData data = 2;
 * @return {?proto.commentator_account.CreateCommentatorAccountResponseData}
 */
proto.commentator_account.CreateCommentatorAccountResponse.prototype.getData = function() {
  return /** @type{?proto.commentator_account.CreateCommentatorAccountResponseData} */ (
    jspb.Message.getWrapperField(this, proto.commentator_account.CreateCommentatorAccountResponseData, 2));
};


/**
 * @param {?proto.commentator_account.CreateCommentatorAccountResponseData|undefined} value
 * @return {!proto.commentator_account.CreateCommentatorAccountResponse} returns this
*/
proto.commentator_account.CreateCommentatorAccountResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commentator_account.CreateCommentatorAccountResponse} returns this
 */
proto.commentator_account.CreateCommentatorAccountResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commentator_account.CreateCommentatorAccountResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.UpdateCommentatorAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.UpdateCommentatorAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.UpdateCommentatorAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePicturePath: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profilePicturePathType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    idInstagram: jspb.Message.getFieldWithDefault(msg, 5, ""),
    idTiktok: jspb.Message.getFieldWithDefault(msg, 6, ""),
    idTwitter: jspb.Message.getFieldWithDefault(msg, 7, ""),
    idFacebook: jspb.Message.getFieldWithDefault(msg, 8, ""),
    idYoutube: jspb.Message.getFieldWithDefault(msg, 9, ""),
    idDetik: jspb.Message.getFieldWithDefault(msg, 10, ""),
    idThreads: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.UpdateCommentatorAccountRequest}
 */
proto.commentator_account.UpdateCommentatorAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.UpdateCommentatorAccountRequest;
  return proto.commentator_account.UpdateCommentatorAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.UpdateCommentatorAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.UpdateCommentatorAccountRequest}
 */
proto.commentator_account.UpdateCommentatorAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicturePath(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicturePathType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdInstagram(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdTiktok(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdTwitter(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdFacebook(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdYoutube(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdDetik(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdThreads(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.UpdateCommentatorAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.UpdateCommentatorAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.UpdateCommentatorAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePicturePath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfilePicturePathType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIdInstagram();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIdTiktok();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIdTwitter();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIdFacebook();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIdYoutube();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIdDetik();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIdThreads();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.UpdateCommentatorAccountRequest} returns this
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.UpdateCommentatorAccountRequest} returns this
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_path = 3;
 * @return {string}
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.getProfilePicturePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.UpdateCommentatorAccountRequest} returns this
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.setProfilePicturePath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string profile_picture_path_type = 4;
 * @return {string}
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.getProfilePicturePathType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.UpdateCommentatorAccountRequest} returns this
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.setProfilePicturePathType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string id_instagram = 5;
 * @return {string}
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.getIdInstagram = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.UpdateCommentatorAccountRequest} returns this
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.setIdInstagram = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string id_tiktok = 6;
 * @return {string}
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.getIdTiktok = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.UpdateCommentatorAccountRequest} returns this
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.setIdTiktok = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string id_twitter = 7;
 * @return {string}
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.getIdTwitter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.UpdateCommentatorAccountRequest} returns this
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.setIdTwitter = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string id_facebook = 8;
 * @return {string}
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.getIdFacebook = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.UpdateCommentatorAccountRequest} returns this
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.setIdFacebook = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string id_youtube = 9;
 * @return {string}
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.getIdYoutube = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.UpdateCommentatorAccountRequest} returns this
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.setIdYoutube = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string id_detik = 10;
 * @return {string}
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.getIdDetik = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.UpdateCommentatorAccountRequest} returns this
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.setIdDetik = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string id_threads = 11;
 * @return {string}
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.getIdThreads = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.UpdateCommentatorAccountRequest} returns this
 */
proto.commentator_account.UpdateCommentatorAccountRequest.prototype.setIdThreads = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.UpdateCommentatorAccountResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.UpdateCommentatorAccountResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.UpdateCommentatorAccountResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.UpdateCommentatorAccountResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.UpdateCommentatorAccountResponseData}
 */
proto.commentator_account.UpdateCommentatorAccountResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.UpdateCommentatorAccountResponseData;
  return proto.commentator_account.UpdateCommentatorAccountResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.UpdateCommentatorAccountResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.UpdateCommentatorAccountResponseData}
 */
proto.commentator_account.UpdateCommentatorAccountResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.UpdateCommentatorAccountResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.UpdateCommentatorAccountResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.UpdateCommentatorAccountResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.UpdateCommentatorAccountResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.UpdateCommentatorAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.UpdateCommentatorAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.UpdateCommentatorAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.UpdateCommentatorAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.commentator_account.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.commentator_account.UpdateCommentatorAccountResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.UpdateCommentatorAccountResponse}
 */
proto.commentator_account.UpdateCommentatorAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.UpdateCommentatorAccountResponse;
  return proto.commentator_account.UpdateCommentatorAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.UpdateCommentatorAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.UpdateCommentatorAccountResponse}
 */
proto.commentator_account.UpdateCommentatorAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.commentator_account.Meta;
      reader.readMessage(value,proto.commentator_account.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.commentator_account.UpdateCommentatorAccountResponseData;
      reader.readMessage(value,proto.commentator_account.UpdateCommentatorAccountResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.UpdateCommentatorAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.UpdateCommentatorAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.UpdateCommentatorAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.UpdateCommentatorAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.commentator_account.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.commentator_account.UpdateCommentatorAccountResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.commentator_account.Meta}
 */
proto.commentator_account.UpdateCommentatorAccountResponse.prototype.getMeta = function() {
  return /** @type{?proto.commentator_account.Meta} */ (
    jspb.Message.getWrapperField(this, proto.commentator_account.Meta, 1));
};


/**
 * @param {?proto.commentator_account.Meta|undefined} value
 * @return {!proto.commentator_account.UpdateCommentatorAccountResponse} returns this
*/
proto.commentator_account.UpdateCommentatorAccountResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commentator_account.UpdateCommentatorAccountResponse} returns this
 */
proto.commentator_account.UpdateCommentatorAccountResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commentator_account.UpdateCommentatorAccountResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UpdateCommentatorAccountResponseData data = 2;
 * @return {?proto.commentator_account.UpdateCommentatorAccountResponseData}
 */
proto.commentator_account.UpdateCommentatorAccountResponse.prototype.getData = function() {
  return /** @type{?proto.commentator_account.UpdateCommentatorAccountResponseData} */ (
    jspb.Message.getWrapperField(this, proto.commentator_account.UpdateCommentatorAccountResponseData, 2));
};


/**
 * @param {?proto.commentator_account.UpdateCommentatorAccountResponseData|undefined} value
 * @return {!proto.commentator_account.UpdateCommentatorAccountResponse} returns this
*/
proto.commentator_account.UpdateCommentatorAccountResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commentator_account.UpdateCommentatorAccountResponse} returns this
 */
proto.commentator_account.UpdateCommentatorAccountResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commentator_account.UpdateCommentatorAccountResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.SetCommentatorAccountIsActiveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.SetCommentatorAccountIsActiveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.SetCommentatorAccountIsActiveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.SetCommentatorAccountIsActiveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.SetCommentatorAccountIsActiveRequest}
 */
proto.commentator_account.SetCommentatorAccountIsActiveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.SetCommentatorAccountIsActiveRequest;
  return proto.commentator_account.SetCommentatorAccountIsActiveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.SetCommentatorAccountIsActiveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.SetCommentatorAccountIsActiveRequest}
 */
proto.commentator_account.SetCommentatorAccountIsActiveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.SetCommentatorAccountIsActiveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.SetCommentatorAccountIsActiveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.SetCommentatorAccountIsActiveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.SetCommentatorAccountIsActiveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.commentator_account.SetCommentatorAccountIsActiveRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commentator_account.SetCommentatorAccountIsActiveRequest} returns this
 */
proto.commentator_account.SetCommentatorAccountIsActiveRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_active = 2;
 * @return {boolean}
 */
proto.commentator_account.SetCommentatorAccountIsActiveRequest.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commentator_account.SetCommentatorAccountIsActiveRequest} returns this
 */
proto.commentator_account.SetCommentatorAccountIsActiveRequest.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.SetCommentatorAccountIsActiveResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.SetCommentatorAccountIsActiveResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.SetCommentatorAccountIsActiveResponseData}
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.SetCommentatorAccountIsActiveResponseData;
  return proto.commentator_account.SetCommentatorAccountIsActiveResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.SetCommentatorAccountIsActiveResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.SetCommentatorAccountIsActiveResponseData}
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.SetCommentatorAccountIsActiveResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.SetCommentatorAccountIsActiveResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.commentator_account.SetCommentatorAccountIsActiveResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commentator_account.SetCommentatorAccountIsActiveResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.commentator_account.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.commentator_account.SetCommentatorAccountIsActiveResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commentator_account.SetCommentatorAccountIsActiveResponse}
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commentator_account.SetCommentatorAccountIsActiveResponse;
  return proto.commentator_account.SetCommentatorAccountIsActiveResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commentator_account.SetCommentatorAccountIsActiveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commentator_account.SetCommentatorAccountIsActiveResponse}
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.commentator_account.Meta;
      reader.readMessage(value,proto.commentator_account.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.commentator_account.SetCommentatorAccountIsActiveResponseData;
      reader.readMessage(value,proto.commentator_account.SetCommentatorAccountIsActiveResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commentator_account.SetCommentatorAccountIsActiveResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commentator_account.SetCommentatorAccountIsActiveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.commentator_account.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.commentator_account.SetCommentatorAccountIsActiveResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.commentator_account.Meta}
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponse.prototype.getMeta = function() {
  return /** @type{?proto.commentator_account.Meta} */ (
    jspb.Message.getWrapperField(this, proto.commentator_account.Meta, 1));
};


/**
 * @param {?proto.commentator_account.Meta|undefined} value
 * @return {!proto.commentator_account.SetCommentatorAccountIsActiveResponse} returns this
*/
proto.commentator_account.SetCommentatorAccountIsActiveResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commentator_account.SetCommentatorAccountIsActiveResponse} returns this
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SetCommentatorAccountIsActiveResponseData data = 2;
 * @return {?proto.commentator_account.SetCommentatorAccountIsActiveResponseData}
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponse.prototype.getData = function() {
  return /** @type{?proto.commentator_account.SetCommentatorAccountIsActiveResponseData} */ (
    jspb.Message.getWrapperField(this, proto.commentator_account.SetCommentatorAccountIsActiveResponseData, 2));
};


/**
 * @param {?proto.commentator_account.SetCommentatorAccountIsActiveResponseData|undefined} value
 * @return {!proto.commentator_account.SetCommentatorAccountIsActiveResponse} returns this
*/
proto.commentator_account.SetCommentatorAccountIsActiveResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commentator_account.SetCommentatorAccountIsActiveResponse} returns this
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commentator_account.SetCommentatorAccountIsActiveResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.commentator_account);
