import { useContext, useEffect, useState, type FC, type ReactNode } from 'react';
import ProfileMid from './ProfileMid';
import Forbidden from '../components/forbidden';
import { GetProfileResponseData } from '../grpc/service/general_pb';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { asyncLocalStorage, getToken } from '../utils/helper';
import { USER_ROLE } from '../utils/contants';
import { HiBriefcase, HiHome, HiOutlineBriefcase, HiOutlineHome, HiOutlineShoppingBag, HiOutlineUserGroup, HiOutlineUsers, HiShoppingBag, HiUserGroup, HiUsers } from 'react-icons/hi2';
import Sidebar from '../components/commentator/Sidebar';
import { commentatorAccountClient } from '../App';
import { LoadingContext } from '../objects/loading_context';
import { GetListCommentatorAccountRequest, GetListCommentatorAccountResponseData } from '../grpc/commentator/commentator_account_pb';
import { errorToast } from '../utils/helper-ui';
import { SidebarContext } from '../objects/SidebarContext';

interface CommentatorMidProps {
    children: ReactNode
    onLoadProfile?: (d: GetProfileResponseData.AsObject) => void
    noPadding?: boolean
    hideHeader?: boolean
    hideMenu?: boolean
    header?: ReactNode
}

const CommentatorMid: FC<CommentatorMidProps> = ({
    children,
    onLoadProfile,
    noPadding,
    hideHeader,
    hideMenu,
    header,
}) => {

     const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const [role, setRole] = useState("");
    const nav = useNavigate()
    const [mounted, setMounted] = useState(false);
    const location = useLocation();
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [accounts, setAccounts] = useState<GetListCommentatorAccountResponseData.AsObject[]>([]);
    const {isOpen, setOpen} = useContext(SidebarContext)




    useEffect(() => {
        setMounted(true)
        getAccounts()
    }, []);


    const getAccounts = () => {
        setIsLoading(true)
        let req = new GetListCommentatorAccountRequest()
        commentatorAccountClient.getListCommentatorAccount(req, getToken(), (err, resp) => {
            setIsLoading(false)
            if (err) {
                errorToast(err.message)
                return
            }
            setAccounts(resp.getDataList().map(e => e.toObject()))
        })
    }

    
    useEffect(() => {
        if (!mounted) return
        asyncLocalStorage.getItem(USER_ROLE).then(v => setRole(v))
        
    }, [mounted]);
        return (role != "commentator" ? <Forbidden /> : <ProfileMid onLoad={onLoadProfile} >
            {!hideHeader && (header ??
                <div className='w-full px-4 py-2 justify-between flex pt-6 bg-white'>
                    <div className='flex items-center '>
                        <img src={"/assets/logo.svg"} alt="" className=' w-6  mr-2 ' />
                        <h3 className='font-bold text-lg'><span className=' text-sky-500 mr-1 mb-4'>Grosir</span><span>Komen</span></h3>
                    </div>
                    {/* <img src={"/assets/customer_service.svg"} alt="" className=' w-6 ' onClick={() => {
                        window.open(`https://wa.me/6287824185785?text=Halo, min boleh saya tanya tanya dulu?`)
                    }} /> */}
    
                </div>)
            }
            <div className={`flex-1 ${!noPadding && 'p-4'} overflow-y-auto `}>
                {children}
            </div>
            {!hideMenu &&
                <div className="flex  w-full  menu-bottom justify-between bg-white shadow-[_0_0px_10px_rgba(0,0,0,0.1)] text-gray-400 px-4 ">
                    <Link to={'/commentator'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/commentator' && 'active'}`} >
                    {location.pathname == '/commentator' ? <HiHome size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineHome size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Home</span>
                </Link>

                <Link to={'/commentator/package'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/commentator/package' && 'active'}`} >
                    {location.pathname == '/commentator/package' ? <HiShoppingBag size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineShoppingBag size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Order</span>
                </Link>
                <Link to={'/commentator/profile'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/commentator/profile' && 'active'}`} >
                    {location.pathname == '/commentator/profile' ? <HiBriefcase size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineBriefcase size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Profil</span>
                </Link>
                </div>
            }
            <Sidebar accounts={accounts} />
        </ProfileMid>);
}
export default CommentatorMid;