import type { FC } from 'react';
import { GetCommissionResponseData } from '../../grpc/affiliator/affiliator_general_pb';
import { money } from '../../utils/helper';

interface HeroCommisionProps {
    data: GetCommissionResponseData.AsObject | null | undefined
    onWithdrawClick: () => void
 }

const HeroCommision: FC<HeroCommisionProps> = ({ 
    onWithdrawClick,
    data
 }) => {
    return (
        <div className='flex flex-col rounded-xl mb-4 text-white pl-4 pt-4' style={{ backgroundImage: "url(/assets/bg-hero-blue.png)", backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover', minHeight: 100 }}>
            <p className='text-sm '>Fee Aktif</p>
            <div className='flex justify-between items-center'>
                <h3 className='-mt-2'>Rp. {money(data?.activeCommission)}</h3>
                <div className=' border border-white rounded-s-xl text-xs px-6 py-1 -mr-5 cursor-pointer' onClick={onWithdrawClick}>Tarik</div>
            </div>
            <p className='text-sm mt-8 '>Fee Belum Selesai</p>
            <p className='-mt-1 text-base'>Rp. {money(data?.inactiveCommission)}</p>

            <div className='w-full mt-8 mb-4 flex flex-col justify-end '>
                <div className='flex items-center' >
                    <img src="/assets/ep_warning.png" alt="" className='w-4 h-4' />
                    <span className=' text-xs ml-2'>Saldo di transfer ke DANA, estimasi 2x24 jam</span>

                </div>
            </div>
        </div>
    );
}
export default HeroCommision;