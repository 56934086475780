import { useContext, useState, type FC } from 'react';
import AffiliatorClientList from '../../components/affiliator/AffiliatorClientList';
import { GetProfileResponseData } from '../../grpc/service/general_pb';
import AffiliatorMid from '../../middlewares/AffiliatorMid';
import { LoadingContext } from '../../objects/loading_context';
import { GetClientListResponseData } from '../../grpc/affiliator/affiliator_client_pb';
import { affiliatorClientClient } from '../../App';

interface AffiliatorClientProps { }

const AffiliatorClient: FC<AffiliatorClientProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);

    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();

    const onClickCommentator = (e: GetClientListResponseData.AsObject) => {
        // setIsLoading(true)
        // let req = new GetClientUserPackageHistoryRequest()
        // req.setUserId(e.id)
        // affiliatorClientClient.getClientUserPackageHistory(req, getToken(), (err, resp) => {
        //     if (err) {
        //         setIsLoading(false)
        //         errorToast(err.message)
        //         return
        //     }

        //     setHistories(resp.getDataList().map(e => e.toObject()))
        //     setModalHistoryOpen(true)
        //     setIsLoading(false)
        // })
    }

    return (
        <AffiliatorMid header={<div className='w-full px-4 py-2 justify-center items-center flex pt-6 bg-white'>
            <h6 className='text-center'>Client</h6>

        </div>} onLoadProfile={setProfile} noPadding>
            <div className='bg-white'>
                <AffiliatorClientList onClickCommentator={onClickCommentator} />
            </div>
            {/* <Drawer className='drawer-rounded' size={"md"} closeButton={null} placement={"bottom"} open={modalHistoryOpen} onClose={() => setModalHistoryOpen(false)}>
                    <Drawer.Title>
                        <div className='flex p-4  justify-between items-center'>
                            <div className='flex   items-center'>
                                <RiHistoryLine className='mr-2' />
                                <p> History Pesanan</p>
                            </div>
                            <HiXMark onClick={() => setModalHistoryOpen(false)} />
                        </div>
                    </Drawer.Title>
                    <Drawer.Body style={{ padding: 20 }}>
                        <ul>
                            {histories.map(e => <li key={randomStr(6)}>
                                <div className='flex justify-between items-center mb-2 border-b pb-2 last:border-b-0'>
                                    <div>
                                        <p className=' font-semibold'>{e.packageName}</p>
                                        <Moment format='DD MMMM YYYY'>{e.createdAt}</Moment>
                                    </div>
                                    <div>
                                        Rp. {money(e.totalPrice)}
                                    </div>
                                </div>
                            </li>)}
    
    
                        </ul>
                    </Drawer.Body>
                </Drawer> */}
        </AffiliatorMid>
    );
}
export default AffiliatorClient;