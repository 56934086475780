import { useState, type FC } from 'react';
import { GetProfileResponseData } from '../../grpc/client/client_general_pb';
import { GetUserPackageStatusResponseData } from '../../grpc/client/client_user_package_pb';
import { useNavigate } from 'react-router-dom';
import ClientMid from '../../middlewares/ClientMid';
import { ORDER_DONE, ORDER_FAILED, ORDER_PROCESSING, ORDER_UNPAID } from '../../utils/contants';
import ListPackageOrder from '../../components/list_package_order';
interface PackageOrderProps { }

const PackageOrder: FC<PackageOrderProps> = ({ }) => {
    const [orderModeActive, setOrderModeActive] = useState(ORDER_UNPAID);
    const nav = useNavigate()
    const [mounted, setMounted] = useState(false);
    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const [userPackage, setUserPackage] = useState<GetUserPackageStatusResponseData.AsObject>();
    return (<ClientMid onLoad={setUserPackage} onLoadProfile={setProfile} noPadding={true}>
        <div className="scrollmenu bg-white border-b-2  border-b-gray-100">
            <div onClick={() => setOrderModeActive(ORDER_UNPAID)} className={`${orderModeActive == ORDER_UNPAID && 'active'}`}>Belum dibayar</div>
            <div onClick={() => setOrderModeActive(ORDER_PROCESSING)} className={`${orderModeActive == ORDER_PROCESSING && 'active'}`} >Diproses</div>
            <div onClick={() => setOrderModeActive(ORDER_DONE)} className={`${orderModeActive == ORDER_DONE && 'active'}`} >Berhasil</div>
            <div onClick={() => setOrderModeActive(ORDER_FAILED)} className={`${orderModeActive == ORDER_FAILED && 'active'}`} >Gagal</div>
        </div>
        <ListPackageOrder mode={orderModeActive} />
    </ClientMid>);
}
export default PackageOrder;