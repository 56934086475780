import { useContext, useEffect, useState, type FC, type ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStorageProfile, getStorageUserPackage } from '../utils/helper';
import { LoadingContext } from '../objects/loading_context';
import Loading from '../components/loading';
import { GetProfileResponseData } from '../grpc/service/general_pb';

interface ProfileMidProps {
    children: ReactNode
    onLoad?: (d: GetProfileResponseData.AsObject) => void
}

const ProfileMid: FC<ProfileMidProps> = ({ children, onLoad }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();

    const nav = useNavigate()
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return
        getStorageProfile().then(v => setProfile(v))
    }, [mounted]);


    useEffect(() => {
        if (profile) {
            if (onLoad) onLoad!(profile)
        }
    }, [profile]);

    return (
        <div className='h-screen w-full'>
           <Loading />
            <div className='flex flex-col h-full w-full'>
                {children}
            </div>


        </div>);
}
export default ProfileMid;