/**
 * @fileoverview gRPC-Web generated client stub for general
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: general.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.general = require('./general_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.general.GeneralHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.general.GeneralHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.GetAdminPhoneNumberRequest,
 *   !proto.general.GetAdminPhoneNumberResponse>}
 */
const methodDescriptor_GeneralHandler_GetAdminPhoneNumber = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/GetAdminPhoneNumber',
  grpc.web.MethodType.UNARY,
  proto.general.GetAdminPhoneNumberRequest,
  proto.general.GetAdminPhoneNumberResponse,
  /**
   * @param {!proto.general.GetAdminPhoneNumberRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.GetAdminPhoneNumberResponse.deserializeBinary
);


/**
 * @param {!proto.general.GetAdminPhoneNumberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.GetAdminPhoneNumberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.GetAdminPhoneNumberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.getAdminPhoneNumber =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/GetAdminPhoneNumber',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetAdminPhoneNumber,
      callback);
};


/**
 * @param {!proto.general.GetAdminPhoneNumberRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.GetAdminPhoneNumberResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.getAdminPhoneNumber =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/GetAdminPhoneNumber',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetAdminPhoneNumber);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.GetAboutUsRequest,
 *   !proto.general.GetAboutUsResponse>}
 */
const methodDescriptor_GeneralHandler_GetAboutUs = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/GetAboutUs',
  grpc.web.MethodType.UNARY,
  proto.general.GetAboutUsRequest,
  proto.general.GetAboutUsResponse,
  /**
   * @param {!proto.general.GetAboutUsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.GetAboutUsResponse.deserializeBinary
);


/**
 * @param {!proto.general.GetAboutUsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.GetAboutUsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.GetAboutUsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.getAboutUs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/GetAboutUs',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetAboutUs,
      callback);
};


/**
 * @param {!proto.general.GetAboutUsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.GetAboutUsResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.getAboutUs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/GetAboutUs',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetAboutUs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.GetTermsAndConditionsRequest,
 *   !proto.general.GetTermsAndConditionsResponse>}
 */
const methodDescriptor_GeneralHandler_GetTermsAndConditions = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/GetTermsAndConditions',
  grpc.web.MethodType.UNARY,
  proto.general.GetTermsAndConditionsRequest,
  proto.general.GetTermsAndConditionsResponse,
  /**
   * @param {!proto.general.GetTermsAndConditionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.GetTermsAndConditionsResponse.deserializeBinary
);


/**
 * @param {!proto.general.GetTermsAndConditionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.GetTermsAndConditionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.GetTermsAndConditionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.getTermsAndConditions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/GetTermsAndConditions',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetTermsAndConditions,
      callback);
};


/**
 * @param {!proto.general.GetTermsAndConditionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.GetTermsAndConditionsResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.getTermsAndConditions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/GetTermsAndConditions',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetTermsAndConditions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.GetTeamRequest,
 *   !proto.general.GetTeamResponse>}
 */
const methodDescriptor_GeneralHandler_GetTeam = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/GetTeam',
  grpc.web.MethodType.UNARY,
  proto.general.GetTeamRequest,
  proto.general.GetTeamResponse,
  /**
   * @param {!proto.general.GetTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.GetTeamResponse.deserializeBinary
);


/**
 * @param {!proto.general.GetTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.GetTeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.GetTeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.getTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/GetTeam',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetTeam,
      callback);
};


/**
 * @param {!proto.general.GetTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.GetTeamResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.getTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/GetTeam',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.InviteTemporaryUserRequest,
 *   !proto.general.InviteTemporaryUserResponse>}
 */
const methodDescriptor_GeneralHandler_InviteTemporaryUser = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/InviteTemporaryUser',
  grpc.web.MethodType.UNARY,
  proto.general.InviteTemporaryUserRequest,
  proto.general.InviteTemporaryUserResponse,
  /**
   * @param {!proto.general.InviteTemporaryUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.InviteTemporaryUserResponse.deserializeBinary
);


/**
 * @param {!proto.general.InviteTemporaryUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.InviteTemporaryUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.InviteTemporaryUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.inviteTemporaryUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/InviteTemporaryUser',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_InviteTemporaryUser,
      callback);
};


/**
 * @param {!proto.general.InviteTemporaryUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.InviteTemporaryUserResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.inviteTemporaryUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/InviteTemporaryUser',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_InviteTemporaryUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.ReportOffenseRequest,
 *   !proto.general.ReportOffenseResponse>}
 */
const methodDescriptor_GeneralHandler_ReportOffense = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/ReportOffense',
  grpc.web.MethodType.UNARY,
  proto.general.ReportOffenseRequest,
  proto.general.ReportOffenseResponse,
  /**
   * @param {!proto.general.ReportOffenseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.ReportOffenseResponse.deserializeBinary
);


/**
 * @param {!proto.general.ReportOffenseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.ReportOffenseResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.ReportOffenseResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.reportOffense =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/ReportOffense',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_ReportOffense,
      callback);
};


/**
 * @param {!proto.general.ReportOffenseRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.ReportOffenseResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.reportOffense =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/ReportOffense',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_ReportOffense);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.GetProfileRequest,
 *   !proto.general.GetProfileResponse>}
 */
const methodDescriptor_GeneralHandler_GetProfile = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/GetProfile',
  grpc.web.MethodType.UNARY,
  proto.general.GetProfileRequest,
  proto.general.GetProfileResponse,
  /**
   * @param {!proto.general.GetProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.GetProfileResponse.deserializeBinary
);


/**
 * @param {!proto.general.GetProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.GetProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.GetProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.getProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/GetProfile',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetProfile,
      callback);
};


/**
 * @param {!proto.general.GetProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.GetProfileResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.getProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/GetProfile',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.UpdateProfileRequest,
 *   !proto.general.UpdateProfileResponse>}
 */
const methodDescriptor_GeneralHandler_UpdateProfile = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/UpdateProfile',
  grpc.web.MethodType.UNARY,
  proto.general.UpdateProfileRequest,
  proto.general.UpdateProfileResponse,
  /**
   * @param {!proto.general.UpdateProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.UpdateProfileResponse.deserializeBinary
);


/**
 * @param {!proto.general.UpdateProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.UpdateProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.UpdateProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.updateProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/UpdateProfile',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_UpdateProfile,
      callback);
};


/**
 * @param {!proto.general.UpdateProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.UpdateProfileResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.updateProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/UpdateProfile',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_UpdateProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.GetRatingRequest,
 *   !proto.general.GetRatingResponse>}
 */
const methodDescriptor_GeneralHandler_GetRating = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/GetRating',
  grpc.web.MethodType.UNARY,
  proto.general.GetRatingRequest,
  proto.general.GetRatingResponse,
  /**
   * @param {!proto.general.GetRatingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.GetRatingResponse.deserializeBinary
);


/**
 * @param {!proto.general.GetRatingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.GetRatingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.GetRatingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.getRating =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/GetRating',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetRating,
      callback);
};


/**
 * @param {!proto.general.GetRatingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.GetRatingResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.getRating =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/GetRating',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetRating);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.GetMutationLogRequest,
 *   !proto.general.GetMutationLogResponse>}
 */
const methodDescriptor_GeneralHandler_GetMutationLog = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/GetMutationLog',
  grpc.web.MethodType.UNARY,
  proto.general.GetMutationLogRequest,
  proto.general.GetMutationLogResponse,
  /**
   * @param {!proto.general.GetMutationLogRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.GetMutationLogResponse.deserializeBinary
);


/**
 * @param {!proto.general.GetMutationLogRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.GetMutationLogResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.GetMutationLogResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.getMutationLog =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/GetMutationLog',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetMutationLog,
      callback);
};


/**
 * @param {!proto.general.GetMutationLogRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.GetMutationLogResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.getMutationLog =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/GetMutationLog',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetMutationLog);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.GetNotificationRequest,
 *   !proto.general.GetNotificationResponse>}
 */
const methodDescriptor_GeneralHandler_GetNotification = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/GetNotification',
  grpc.web.MethodType.UNARY,
  proto.general.GetNotificationRequest,
  proto.general.GetNotificationResponse,
  /**
   * @param {!proto.general.GetNotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.GetNotificationResponse.deserializeBinary
);


/**
 * @param {!proto.general.GetNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.GetNotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.GetNotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.getNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/GetNotification',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetNotification,
      callback);
};


/**
 * @param {!proto.general.GetNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.GetNotificationResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.getNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/GetNotification',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetNotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.SetNotificationReadRequest,
 *   !proto.general.SetNotificationReadResponse>}
 */
const methodDescriptor_GeneralHandler_SetNotificationRead = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/SetNotificationRead',
  grpc.web.MethodType.UNARY,
  proto.general.SetNotificationReadRequest,
  proto.general.SetNotificationReadResponse,
  /**
   * @param {!proto.general.SetNotificationReadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.SetNotificationReadResponse.deserializeBinary
);


/**
 * @param {!proto.general.SetNotificationReadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.SetNotificationReadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.SetNotificationReadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.setNotificationRead =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/SetNotificationRead',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_SetNotificationRead,
      callback);
};


/**
 * @param {!proto.general.SetNotificationReadRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.SetNotificationReadResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.setNotificationRead =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/SetNotificationRead',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_SetNotificationRead);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.JoinAffiliateProgramRequest,
 *   !proto.general.JoinAffiliateProgramResponse>}
 */
const methodDescriptor_GeneralHandler_JoinAffiliateProgram = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/JoinAffiliateProgram',
  grpc.web.MethodType.UNARY,
  proto.general.JoinAffiliateProgramRequest,
  proto.general.JoinAffiliateProgramResponse,
  /**
   * @param {!proto.general.JoinAffiliateProgramRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.JoinAffiliateProgramResponse.deserializeBinary
);


/**
 * @param {!proto.general.JoinAffiliateProgramRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.JoinAffiliateProgramResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.JoinAffiliateProgramResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.joinAffiliateProgram =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/JoinAffiliateProgram',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_JoinAffiliateProgram,
      callback);
};


/**
 * @param {!proto.general.JoinAffiliateProgramRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.JoinAffiliateProgramResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.joinAffiliateProgram =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/JoinAffiliateProgram',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_JoinAffiliateProgram);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.AddToplineUserRequest,
 *   !proto.general.AddToplineUserResponse>}
 */
const methodDescriptor_GeneralHandler_AddToplineUser = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/AddToplineUser',
  grpc.web.MethodType.UNARY,
  proto.general.AddToplineUserRequest,
  proto.general.AddToplineUserResponse,
  /**
   * @param {!proto.general.AddToplineUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.AddToplineUserResponse.deserializeBinary
);


/**
 * @param {!proto.general.AddToplineUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.AddToplineUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.AddToplineUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.addToplineUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/AddToplineUser',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_AddToplineUser,
      callback);
};


/**
 * @param {!proto.general.AddToplineUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.AddToplineUserResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.addToplineUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/AddToplineUser',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_AddToplineUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.GetMarketingCommissionRequest,
 *   !proto.general.GetMarketingCommissionResponse>}
 */
const methodDescriptor_GeneralHandler_GetMarketingCommission = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/GetMarketingCommission',
  grpc.web.MethodType.UNARY,
  proto.general.GetMarketingCommissionRequest,
  proto.general.GetMarketingCommissionResponse,
  /**
   * @param {!proto.general.GetMarketingCommissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.GetMarketingCommissionResponse.deserializeBinary
);


/**
 * @param {!proto.general.GetMarketingCommissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.GetMarketingCommissionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.GetMarketingCommissionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.getMarketingCommission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/GetMarketingCommission',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetMarketingCommission,
      callback);
};


/**
 * @param {!proto.general.GetMarketingCommissionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.GetMarketingCommissionResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.getMarketingCommission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/GetMarketingCommission',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetMarketingCommission);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.GetMarketingSummaryRequest,
 *   !proto.general.GetMarketingSummaryResponse>}
 */
const methodDescriptor_GeneralHandler_GetMarketingSummary = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/GetMarketingSummary',
  grpc.web.MethodType.UNARY,
  proto.general.GetMarketingSummaryRequest,
  proto.general.GetMarketingSummaryResponse,
  /**
   * @param {!proto.general.GetMarketingSummaryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.GetMarketingSummaryResponse.deserializeBinary
);


/**
 * @param {!proto.general.GetMarketingSummaryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.GetMarketingSummaryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.GetMarketingSummaryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.getMarketingSummary =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/GetMarketingSummary',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetMarketingSummary,
      callback);
};


/**
 * @param {!proto.general.GetMarketingSummaryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.GetMarketingSummaryResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.getMarketingSummary =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/GetMarketingSummary',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetMarketingSummary);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.GetDownlineUserPackageListRequest,
 *   !proto.general.GetDownlineUserPackageListResponse>}
 */
const methodDescriptor_GeneralHandler_GetDownlineUserPackageList = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/GetDownlineUserPackageList',
  grpc.web.MethodType.UNARY,
  proto.general.GetDownlineUserPackageListRequest,
  proto.general.GetDownlineUserPackageListResponse,
  /**
   * @param {!proto.general.GetDownlineUserPackageListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.GetDownlineUserPackageListResponse.deserializeBinary
);


/**
 * @param {!proto.general.GetDownlineUserPackageListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.GetDownlineUserPackageListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.GetDownlineUserPackageListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.getDownlineUserPackageList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/GetDownlineUserPackageList',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetDownlineUserPackageList,
      callback);
};


/**
 * @param {!proto.general.GetDownlineUserPackageListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.GetDownlineUserPackageListResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.getDownlineUserPackageList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/GetDownlineUserPackageList',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetDownlineUserPackageList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.general.GetUserPackageFeeRequest,
 *   !proto.general.GetUserPackageFeeResponse>}
 */
const methodDescriptor_GeneralHandler_GetUserPackageFee = new grpc.web.MethodDescriptor(
  '/general.GeneralHandler/GetUserPackageFee',
  grpc.web.MethodType.UNARY,
  proto.general.GetUserPackageFeeRequest,
  proto.general.GetUserPackageFeeResponse,
  /**
   * @param {!proto.general.GetUserPackageFeeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.general.GetUserPackageFeeResponse.deserializeBinary
);


/**
 * @param {!proto.general.GetUserPackageFeeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.general.GetUserPackageFeeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.general.GetUserPackageFeeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.general.GeneralHandlerClient.prototype.getUserPackageFee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/general.GeneralHandler/GetUserPackageFee',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetUserPackageFee,
      callback);
};


/**
 * @param {!proto.general.GetUserPackageFeeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.general.GetUserPackageFeeResponse>}
 *     Promise that resolves to the response
 */
proto.general.GeneralHandlerPromiseClient.prototype.getUserPackageFee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/general.GeneralHandler/GetUserPackageFee',
      request,
      metadata || {},
      methodDescriptor_GeneralHandler_GetUserPackageFee);
};


module.exports = proto.general;

