// source: admin_order.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.admin_order.GetOrderDetailRequest', null, global);
goog.exportSymbol('proto.admin_order.GetOrderDetailResponse', null, global);
goog.exportSymbol('proto.admin_order.GetOrderDetailResponseData', null, global);
goog.exportSymbol('proto.admin_order.GetOrderDetailResponseDataUser', null, global);
goog.exportSymbol('proto.admin_order.GetOrderDiscussionRequest', null, global);
goog.exportSymbol('proto.admin_order.GetOrderDiscussionResponse', null, global);
goog.exportSymbol('proto.admin_order.GetOrderDiscussionResponseData', null, global);
goog.exportSymbol('proto.admin_order.GetOrderDiscussionResponseDataReply', null, global);
goog.exportSymbol('proto.admin_order.GetOrderDiscussionResponseDataUser', null, global);
goog.exportSymbol('proto.admin_order.GetOrderListRequest', null, global);
goog.exportSymbol('proto.admin_order.GetOrderListResponse', null, global);
goog.exportSymbol('proto.admin_order.GetOrderListResponseData', null, global);
goog.exportSymbol('proto.admin_order.GetOrderListResponseDataUser', null, global);
goog.exportSymbol('proto.admin_order.Meta', null, global);
goog.exportSymbol('proto.admin_order.Pagination', null, global);
goog.exportSymbol('proto.admin_order.SendDiscussionRequest', null, global);
goog.exportSymbol('proto.admin_order.SendDiscussionResponse', null, global);
goog.exportSymbol('proto.admin_order.SendDiscussionResponseData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.Meta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_order.Meta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.Meta.displayName = 'proto.admin_order.Meta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.Pagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_order.Pagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.Pagination.displayName = 'proto.admin_order.Pagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.GetOrderListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_order.GetOrderListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.GetOrderListRequest.displayName = 'proto.admin_order.GetOrderListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.GetOrderListResponseDataUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_order.GetOrderListResponseDataUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.GetOrderListResponseDataUser.displayName = 'proto.admin_order.GetOrderListResponseDataUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.GetOrderListResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_order.GetOrderListResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.GetOrderListResponseData.displayName = 'proto.admin_order.GetOrderListResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.GetOrderListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin_order.GetOrderListResponse.repeatedFields_, null);
};
goog.inherits(proto.admin_order.GetOrderListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.GetOrderListResponse.displayName = 'proto.admin_order.GetOrderListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.GetOrderDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_order.GetOrderDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.GetOrderDetailRequest.displayName = 'proto.admin_order.GetOrderDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.GetOrderDetailResponseDataUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_order.GetOrderDetailResponseDataUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.GetOrderDetailResponseDataUser.displayName = 'proto.admin_order.GetOrderDetailResponseDataUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.GetOrderDetailResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_order.GetOrderDetailResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.GetOrderDetailResponseData.displayName = 'proto.admin_order.GetOrderDetailResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.GetOrderDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_order.GetOrderDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.GetOrderDetailResponse.displayName = 'proto.admin_order.GetOrderDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.GetOrderDiscussionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_order.GetOrderDiscussionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.GetOrderDiscussionRequest.displayName = 'proto.admin_order.GetOrderDiscussionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.GetOrderDiscussionResponseDataUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_order.GetOrderDiscussionResponseDataUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.GetOrderDiscussionResponseDataUser.displayName = 'proto.admin_order.GetOrderDiscussionResponseDataUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.GetOrderDiscussionResponseDataReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_order.GetOrderDiscussionResponseDataReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.GetOrderDiscussionResponseDataReply.displayName = 'proto.admin_order.GetOrderDiscussionResponseDataReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.GetOrderDiscussionResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin_order.GetOrderDiscussionResponseData.repeatedFields_, null);
};
goog.inherits(proto.admin_order.GetOrderDiscussionResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.GetOrderDiscussionResponseData.displayName = 'proto.admin_order.GetOrderDiscussionResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.GetOrderDiscussionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin_order.GetOrderDiscussionResponse.repeatedFields_, null);
};
goog.inherits(proto.admin_order.GetOrderDiscussionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.GetOrderDiscussionResponse.displayName = 'proto.admin_order.GetOrderDiscussionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.SendDiscussionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_order.SendDiscussionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.SendDiscussionRequest.displayName = 'proto.admin_order.SendDiscussionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.SendDiscussionResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_order.SendDiscussionResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.SendDiscussionResponseData.displayName = 'proto.admin_order.SendDiscussionResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_order.SendDiscussionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_order.SendDiscussionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_order.SendDiscussionResponse.displayName = 'proto.admin_order.SendDiscussionResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.Meta.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.Meta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.Meta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.Meta.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.Meta}
 */
proto.admin_order.Meta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.Meta;
  return proto.admin_order.Meta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.Meta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.Meta}
 */
proto.admin_order.Meta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.Meta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.Meta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.Meta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.Meta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.admin_order.Meta.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.Meta} returns this
 */
proto.admin_order.Meta.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 status = 2;
 * @return {number}
 */
proto.admin_order.Meta.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_order.Meta} returns this
 */
proto.admin_order.Meta.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.admin_order.Meta.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.Meta} returns this
 */
proto.admin_order.Meta.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.Pagination.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.Pagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.Pagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.Pagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    prev: jspb.Message.getFieldWithDefault(msg, 3, 0),
    next: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.Pagination}
 */
proto.admin_order.Pagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.Pagination;
  return proto.admin_order.Pagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.Pagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.Pagination}
 */
proto.admin_order.Pagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrev(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNext(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPages(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.Pagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.Pagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.Pagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.Pagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPrev();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNext();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.admin_order.Pagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_order.Pagination} returns this
 */
proto.admin_order.Pagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.admin_order.Pagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_order.Pagination} returns this
 */
proto.admin_order.Pagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 prev = 3;
 * @return {number}
 */
proto.admin_order.Pagination.prototype.getPrev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_order.Pagination} returns this
 */
proto.admin_order.Pagination.prototype.setPrev = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 next = 4;
 * @return {number}
 */
proto.admin_order.Pagination.prototype.getNext = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_order.Pagination} returns this
 */
proto.admin_order.Pagination.prototype.setNext = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 total_pages = 5;
 * @return {number}
 */
proto.admin_order.Pagination.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_order.Pagination} returns this
 */
proto.admin_order.Pagination.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 total_records = 6;
 * @return {number}
 */
proto.admin_order.Pagination.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_order.Pagination} returns this
 */
proto.admin_order.Pagination.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.GetOrderListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.GetOrderListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.GetOrderListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isFinished: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.GetOrderListRequest}
 */
proto.admin_order.GetOrderListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.GetOrderListRequest;
  return proto.admin_order.GetOrderListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.GetOrderListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.GetOrderListRequest}
 */
proto.admin_order.GetOrderListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFinished(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.GetOrderListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.GetOrderListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.GetOrderListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getIsFinished();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.admin_order.GetOrderListRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_order.GetOrderListRequest} returns this
 */
proto.admin_order.GetOrderListRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.admin_order.GetOrderListRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_order.GetOrderListRequest} returns this
 */
proto.admin_order.GetOrderListRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_finished = 3;
 * @return {boolean}
 */
proto.admin_order.GetOrderListRequest.prototype.getIsFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin_order.GetOrderListRequest} returns this
 */
proto.admin_order.GetOrderListRequest.prototype.setIsFinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.GetOrderListResponseDataUser.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.GetOrderListResponseDataUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.GetOrderListResponseDataUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderListResponseDataUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.GetOrderListResponseDataUser}
 */
proto.admin_order.GetOrderListResponseDataUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.GetOrderListResponseDataUser;
  return proto.admin_order.GetOrderListResponseDataUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.GetOrderListResponseDataUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.GetOrderListResponseDataUser}
 */
proto.admin_order.GetOrderListResponseDataUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.GetOrderListResponseDataUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.GetOrderListResponseDataUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.GetOrderListResponseDataUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderListResponseDataUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_order.GetOrderListResponseDataUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderListResponseDataUser} returns this
 */
proto.admin_order.GetOrderListResponseDataUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.admin_order.GetOrderListResponseDataUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderListResponseDataUser} returns this
 */
proto.admin_order.GetOrderListResponseDataUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.admin_order.GetOrderListResponseDataUser.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderListResponseDataUser} returns this
 */
proto.admin_order.GetOrderListResponseDataUser.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.GetOrderListResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.GetOrderListResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.GetOrderListResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderListResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dueDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    thumbnailUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    url: jspb.Message.getFieldWithDefault(msg, 6, ""),
    hasUnreadMessage: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    user: (f = msg.getUser()) && proto.admin_order.GetOrderListResponseDataUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.GetOrderListResponseData}
 */
proto.admin_order.GetOrderListResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.GetOrderListResponseData;
  return proto.admin_order.GetOrderListResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.GetOrderListResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.GetOrderListResponseData}
 */
proto.admin_order.GetOrderListResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDueDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasUnreadMessage(value);
      break;
    case 8:
      var value = new proto.admin_order.GetOrderListResponseDataUser;
      reader.readMessage(value,proto.admin_order.GetOrderListResponseDataUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.GetOrderListResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.GetOrderListResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.GetOrderListResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderListResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDueDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getThumbnailUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHasUnreadMessage();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.admin_order.GetOrderListResponseDataUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_order.GetOrderListResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderListResponseData} returns this
 */
proto.admin_order.GetOrderListResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string due_date = 2;
 * @return {string}
 */
proto.admin_order.GetOrderListResponseData.prototype.getDueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderListResponseData} returns this
 */
proto.admin_order.GetOrderListResponseData.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.admin_order.GetOrderListResponseData.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderListResponseData} returns this
 */
proto.admin_order.GetOrderListResponseData.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.admin_order.GetOrderListResponseData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderListResponseData} returns this
 */
proto.admin_order.GetOrderListResponseData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string thumbnail_url = 5;
 * @return {string}
 */
proto.admin_order.GetOrderListResponseData.prototype.getThumbnailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderListResponseData} returns this
 */
proto.admin_order.GetOrderListResponseData.prototype.setThumbnailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string url = 6;
 * @return {string}
 */
proto.admin_order.GetOrderListResponseData.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderListResponseData} returns this
 */
proto.admin_order.GetOrderListResponseData.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool has_unread_message = 7;
 * @return {boolean}
 */
proto.admin_order.GetOrderListResponseData.prototype.getHasUnreadMessage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin_order.GetOrderListResponseData} returns this
 */
proto.admin_order.GetOrderListResponseData.prototype.setHasUnreadMessage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional GetOrderListResponseDataUser user = 8;
 * @return {?proto.admin_order.GetOrderListResponseDataUser}
 */
proto.admin_order.GetOrderListResponseData.prototype.getUser = function() {
  return /** @type{?proto.admin_order.GetOrderListResponseDataUser} */ (
    jspb.Message.getWrapperField(this, proto.admin_order.GetOrderListResponseDataUser, 8));
};


/**
 * @param {?proto.admin_order.GetOrderListResponseDataUser|undefined} value
 * @return {!proto.admin_order.GetOrderListResponseData} returns this
*/
proto.admin_order.GetOrderListResponseData.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_order.GetOrderListResponseData} returns this
 */
proto.admin_order.GetOrderListResponseData.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_order.GetOrderListResponseData.prototype.hasUser = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin_order.GetOrderListResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.GetOrderListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.GetOrderListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.GetOrderListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_order.Meta.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.admin_order.Pagination.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.admin_order.GetOrderListResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.GetOrderListResponse}
 */
proto.admin_order.GetOrderListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.GetOrderListResponse;
  return proto.admin_order.GetOrderListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.GetOrderListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.GetOrderListResponse}
 */
proto.admin_order.GetOrderListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_order.Meta;
      reader.readMessage(value,proto.admin_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_order.Pagination;
      reader.readMessage(value,proto.admin_order.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.admin_order.GetOrderListResponseData;
      reader.readMessage(value,proto.admin_order.GetOrderListResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.GetOrderListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.GetOrderListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.GetOrderListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_order.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.admin_order.GetOrderListResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_order.Meta}
 */
proto.admin_order.GetOrderListResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_order.Meta, 1));
};


/**
 * @param {?proto.admin_order.Meta|undefined} value
 * @return {!proto.admin_order.GetOrderListResponse} returns this
*/
proto.admin_order.GetOrderListResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_order.GetOrderListResponse} returns this
 */
proto.admin_order.GetOrderListResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_order.GetOrderListResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.admin_order.Pagination}
 */
proto.admin_order.GetOrderListResponse.prototype.getPagination = function() {
  return /** @type{?proto.admin_order.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.admin_order.Pagination, 2));
};


/**
 * @param {?proto.admin_order.Pagination|undefined} value
 * @return {!proto.admin_order.GetOrderListResponse} returns this
*/
proto.admin_order.GetOrderListResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_order.GetOrderListResponse} returns this
 */
proto.admin_order.GetOrderListResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_order.GetOrderListResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated GetOrderListResponseData data = 3;
 * @return {!Array<!proto.admin_order.GetOrderListResponseData>}
 */
proto.admin_order.GetOrderListResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.admin_order.GetOrderListResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin_order.GetOrderListResponseData, 3));
};


/**
 * @param {!Array<!proto.admin_order.GetOrderListResponseData>} value
 * @return {!proto.admin_order.GetOrderListResponse} returns this
*/
proto.admin_order.GetOrderListResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.admin_order.GetOrderListResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin_order.GetOrderListResponseData}
 */
proto.admin_order.GetOrderListResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.admin_order.GetOrderListResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin_order.GetOrderListResponse} returns this
 */
proto.admin_order.GetOrderListResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.GetOrderDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.GetOrderDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.GetOrderDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.GetOrderDetailRequest}
 */
proto.admin_order.GetOrderDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.GetOrderDetailRequest;
  return proto.admin_order.GetOrderDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.GetOrderDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.GetOrderDetailRequest}
 */
proto.admin_order.GetOrderDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.GetOrderDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.GetOrderDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.GetOrderDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_order.GetOrderDetailRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailRequest} returns this
 */
proto.admin_order.GetOrderDetailRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.GetOrderDetailResponseDataUser.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.GetOrderDetailResponseDataUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.GetOrderDetailResponseDataUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDetailResponseDataUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.GetOrderDetailResponseDataUser}
 */
proto.admin_order.GetOrderDetailResponseDataUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.GetOrderDetailResponseDataUser;
  return proto.admin_order.GetOrderDetailResponseDataUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.GetOrderDetailResponseDataUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.GetOrderDetailResponseDataUser}
 */
proto.admin_order.GetOrderDetailResponseDataUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.GetOrderDetailResponseDataUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.GetOrderDetailResponseDataUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.GetOrderDetailResponseDataUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDetailResponseDataUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseDataUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseDataUser} returns this
 */
proto.admin_order.GetOrderDetailResponseDataUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseDataUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseDataUser} returns this
 */
proto.admin_order.GetOrderDetailResponseDataUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseDataUser.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseDataUser} returns this
 */
proto.admin_order.GetOrderDetailResponseDataUser.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.GetOrderDetailResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.GetOrderDetailResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDetailResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dueDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    platform: jspb.Message.getFieldWithDefault(msg, 3, ""),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    thumbnailUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    url: jspb.Message.getFieldWithDefault(msg, 7, ""),
    positiveComment: jspb.Message.getFieldWithDefault(msg, 8, 0),
    negativeComment: jspb.Message.getFieldWithDefault(msg, 9, 0),
    tagFriend: jspb.Message.getFieldWithDefault(msg, 10, 0),
    like: jspb.Message.getFieldWithDefault(msg, 11, 0),
    share: jspb.Message.getFieldWithDefault(msg, 12, 0),
    remarks: jspb.Message.getFieldWithDefault(msg, 13, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 14, ""),
    finishedAt: jspb.Message.getFieldWithDefault(msg, 15, ""),
    reportImageUrl: jspb.Message.getFieldWithDefault(msg, 16, ""),
    feedbackRating: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    feedbackComment: jspb.Message.getFieldWithDefault(msg, 18, ""),
    user: (f = msg.getUser()) && proto.admin_order.GetOrderDetailResponseDataUser.toObject(includeInstance, f),
    initialImageUrl: jspb.Message.getFieldWithDefault(msg, 20, ""),
    feedbackTime: jspb.Message.getFieldWithDefault(msg, 21, ""),
    status: jspb.Message.getFieldWithDefault(msg, 22, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.GetOrderDetailResponseData}
 */
proto.admin_order.GetOrderDetailResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.GetOrderDetailResponseData;
  return proto.admin_order.GetOrderDetailResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.GetOrderDetailResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.GetOrderDetailResponseData}
 */
proto.admin_order.GetOrderDetailResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDueDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatform(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositiveComment(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNegativeComment(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTagFriend(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLike(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShare(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinishedAt(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportImageUrl(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFeedbackRating(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedbackComment(value);
      break;
    case 19:
      var value = new proto.admin_order.GetOrderDetailResponseDataUser;
      reader.readMessage(value,proto.admin_order.GetOrderDetailResponseDataUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialImageUrl(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedbackTime(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.GetOrderDetailResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.GetOrderDetailResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDetailResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDueDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlatform();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getThumbnailUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPositiveComment();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getNegativeComment();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getTagFriend();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getLike();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getShare();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getFinishedAt();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getReportImageUrl();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getFeedbackRating();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getFeedbackComment();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.admin_order.GetOrderDetailResponseDataUser.serializeBinaryToWriter
    );
  }
  f = message.getInitialImageUrl();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getFeedbackTime();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string due_date = 2;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getDueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string platform = 3;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getPlatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string thumbnail_url = 6;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getThumbnailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setThumbnailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string url = 7;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 positive_comment = 8;
 * @return {number}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getPositiveComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setPositiveComment = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 negative_comment = 9;
 * @return {number}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getNegativeComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setNegativeComment = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 tag_friend = 10;
 * @return {number}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getTagFriend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setTagFriend = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 like = 11;
 * @return {number}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getLike = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setLike = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 share = 12;
 * @return {number}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getShare = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setShare = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string remarks = 13;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string created_at = 14;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string finished_at = 15;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getFinishedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setFinishedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string report_image_url = 16;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getReportImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setReportImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional float feedback_rating = 17;
 * @return {number}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getFeedbackRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setFeedbackRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional string feedback_comment = 18;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getFeedbackComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setFeedbackComment = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional GetOrderDetailResponseDataUser user = 19;
 * @return {?proto.admin_order.GetOrderDetailResponseDataUser}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getUser = function() {
  return /** @type{?proto.admin_order.GetOrderDetailResponseDataUser} */ (
    jspb.Message.getWrapperField(this, proto.admin_order.GetOrderDetailResponseDataUser, 19));
};


/**
 * @param {?proto.admin_order.GetOrderDetailResponseDataUser|undefined} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
*/
proto.admin_order.GetOrderDetailResponseData.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.hasUser = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string initial_image_url = 20;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getInitialImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setInitialImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string feedback_time = 21;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getFeedbackTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setFeedbackTime = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string status = 22;
 * @return {string}
 */
proto.admin_order.GetOrderDetailResponseData.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDetailResponseData} returns this
 */
proto.admin_order.GetOrderDetailResponseData.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.GetOrderDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.GetOrderDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.GetOrderDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_order.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_order.GetOrderDetailResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.GetOrderDetailResponse}
 */
proto.admin_order.GetOrderDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.GetOrderDetailResponse;
  return proto.admin_order.GetOrderDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.GetOrderDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.GetOrderDetailResponse}
 */
proto.admin_order.GetOrderDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_order.Meta;
      reader.readMessage(value,proto.admin_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_order.GetOrderDetailResponseData;
      reader.readMessage(value,proto.admin_order.GetOrderDetailResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.GetOrderDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.GetOrderDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.GetOrderDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_order.GetOrderDetailResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_order.Meta}
 */
proto.admin_order.GetOrderDetailResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_order.Meta, 1));
};


/**
 * @param {?proto.admin_order.Meta|undefined} value
 * @return {!proto.admin_order.GetOrderDetailResponse} returns this
*/
proto.admin_order.GetOrderDetailResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_order.GetOrderDetailResponse} returns this
 */
proto.admin_order.GetOrderDetailResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_order.GetOrderDetailResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetOrderDetailResponseData data = 2;
 * @return {?proto.admin_order.GetOrderDetailResponseData}
 */
proto.admin_order.GetOrderDetailResponse.prototype.getData = function() {
  return /** @type{?proto.admin_order.GetOrderDetailResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_order.GetOrderDetailResponseData, 2));
};


/**
 * @param {?proto.admin_order.GetOrderDetailResponseData|undefined} value
 * @return {!proto.admin_order.GetOrderDetailResponse} returns this
*/
proto.admin_order.GetOrderDetailResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_order.GetOrderDetailResponse} returns this
 */
proto.admin_order.GetOrderDetailResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_order.GetOrderDetailResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.GetOrderDiscussionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.GetOrderDiscussionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.GetOrderDiscussionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDiscussionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.GetOrderDiscussionRequest}
 */
proto.admin_order.GetOrderDiscussionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.GetOrderDiscussionRequest;
  return proto.admin_order.GetOrderDiscussionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.GetOrderDiscussionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.GetOrderDiscussionRequest}
 */
proto.admin_order.GetOrderDiscussionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.GetOrderDiscussionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.GetOrderDiscussionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.GetOrderDiscussionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDiscussionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.admin_order.GetOrderDiscussionRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDiscussionRequest} returns this
 */
proto.admin_order.GetOrderDiscussionRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.admin_order.GetOrderDiscussionRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDiscussionRequest} returns this
 */
proto.admin_order.GetOrderDiscussionRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.GetOrderDiscussionResponseDataUser.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.GetOrderDiscussionResponseDataUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.GetOrderDiscussionResponseDataUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDiscussionResponseDataUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.GetOrderDiscussionResponseDataUser}
 */
proto.admin_order.GetOrderDiscussionResponseDataUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.GetOrderDiscussionResponseDataUser;
  return proto.admin_order.GetOrderDiscussionResponseDataUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.GetOrderDiscussionResponseDataUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.GetOrderDiscussionResponseDataUser}
 */
proto.admin_order.GetOrderDiscussionResponseDataUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.GetOrderDiscussionResponseDataUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.GetOrderDiscussionResponseDataUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.GetOrderDiscussionResponseDataUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDiscussionResponseDataUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_order.GetOrderDiscussionResponseDataUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDiscussionResponseDataUser} returns this
 */
proto.admin_order.GetOrderDiscussionResponseDataUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.admin_order.GetOrderDiscussionResponseDataUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDiscussionResponseDataUser} returns this
 */
proto.admin_order.GetOrderDiscussionResponseDataUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.admin_order.GetOrderDiscussionResponseDataUser.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDiscussionResponseDataUser} returns this
 */
proto.admin_order.GetOrderDiscussionResponseDataUser.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.GetOrderDiscussionResponseDataReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.GetOrderDiscussionResponseDataReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attachmentUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    selfComment: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    user: (f = msg.getUser()) && proto.admin_order.GetOrderDiscussionResponseDataUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.GetOrderDiscussionResponseDataReply}
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.GetOrderDiscussionResponseDataReply;
  return proto.admin_order.GetOrderDiscussionResponseDataReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.GetOrderDiscussionResponseDataReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.GetOrderDiscussionResponseDataReply}
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentUrl(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelfComment(value);
      break;
    case 6:
      var value = new proto.admin_order.GetOrderDiscussionResponseDataUser;
      reader.readMessage(value,proto.admin_order.GetOrderDiscussionResponseDataUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.GetOrderDiscussionResponseDataReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.GetOrderDiscussionResponseDataReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttachmentUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSelfComment();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.admin_order.GetOrderDiscussionResponseDataUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string attachment_url = 4;
 * @return {string}
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.prototype.getAttachmentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.prototype.setAttachmentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool self_comment = 5;
 * @return {boolean}
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.prototype.getSelfComment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.prototype.setSelfComment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional GetOrderDiscussionResponseDataUser user = 6;
 * @return {?proto.admin_order.GetOrderDiscussionResponseDataUser}
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.prototype.getUser = function() {
  return /** @type{?proto.admin_order.GetOrderDiscussionResponseDataUser} */ (
    jspb.Message.getWrapperField(this, proto.admin_order.GetOrderDiscussionResponseDataUser, 6));
};


/**
 * @param {?proto.admin_order.GetOrderDiscussionResponseDataUser|undefined} value
 * @return {!proto.admin_order.GetOrderDiscussionResponseDataReply} returns this
*/
proto.admin_order.GetOrderDiscussionResponseDataReply.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_order.GetOrderDiscussionResponseDataReply} returns this
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_order.GetOrderDiscussionResponseDataReply.prototype.hasUser = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin_order.GetOrderDiscussionResponseData.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.GetOrderDiscussionResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.GetOrderDiscussionResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDiscussionResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attachmentUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    selfComment: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    user: (f = msg.getUser()) && proto.admin_order.GetOrderDiscussionResponseDataUser.toObject(includeInstance, f),
    repliesList: jspb.Message.toObjectList(msg.getRepliesList(),
    proto.admin_order.GetOrderDiscussionResponseDataReply.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.GetOrderDiscussionResponseData}
 */
proto.admin_order.GetOrderDiscussionResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.GetOrderDiscussionResponseData;
  return proto.admin_order.GetOrderDiscussionResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.GetOrderDiscussionResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.GetOrderDiscussionResponseData}
 */
proto.admin_order.GetOrderDiscussionResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentUrl(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelfComment(value);
      break;
    case 6:
      var value = new proto.admin_order.GetOrderDiscussionResponseDataUser;
      reader.readMessage(value,proto.admin_order.GetOrderDiscussionResponseDataUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 7:
      var value = new proto.admin_order.GetOrderDiscussionResponseDataReply;
      reader.readMessage(value,proto.admin_order.GetOrderDiscussionResponseDataReply.deserializeBinaryFromReader);
      msg.addReplies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.GetOrderDiscussionResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.GetOrderDiscussionResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDiscussionResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttachmentUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSelfComment();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.admin_order.GetOrderDiscussionResponseDataUser.serializeBinaryToWriter
    );
  }
  f = message.getRepliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.admin_order.GetOrderDiscussionResponseDataReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDiscussionResponseData} returns this
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDiscussionResponseData} returns this
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDiscussionResponseData} returns this
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string attachment_url = 4;
 * @return {string}
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.getAttachmentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.GetOrderDiscussionResponseData} returns this
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.setAttachmentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool self_comment = 5;
 * @return {boolean}
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.getSelfComment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin_order.GetOrderDiscussionResponseData} returns this
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.setSelfComment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional GetOrderDiscussionResponseDataUser user = 6;
 * @return {?proto.admin_order.GetOrderDiscussionResponseDataUser}
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.getUser = function() {
  return /** @type{?proto.admin_order.GetOrderDiscussionResponseDataUser} */ (
    jspb.Message.getWrapperField(this, proto.admin_order.GetOrderDiscussionResponseDataUser, 6));
};


/**
 * @param {?proto.admin_order.GetOrderDiscussionResponseDataUser|undefined} value
 * @return {!proto.admin_order.GetOrderDiscussionResponseData} returns this
*/
proto.admin_order.GetOrderDiscussionResponseData.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_order.GetOrderDiscussionResponseData} returns this
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.hasUser = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated GetOrderDiscussionResponseDataReply replies = 7;
 * @return {!Array<!proto.admin_order.GetOrderDiscussionResponseDataReply>}
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.getRepliesList = function() {
  return /** @type{!Array<!proto.admin_order.GetOrderDiscussionResponseDataReply>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin_order.GetOrderDiscussionResponseDataReply, 7));
};


/**
 * @param {!Array<!proto.admin_order.GetOrderDiscussionResponseDataReply>} value
 * @return {!proto.admin_order.GetOrderDiscussionResponseData} returns this
*/
proto.admin_order.GetOrderDiscussionResponseData.prototype.setRepliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.admin_order.GetOrderDiscussionResponseDataReply=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin_order.GetOrderDiscussionResponseDataReply}
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.addReplies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.admin_order.GetOrderDiscussionResponseDataReply, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin_order.GetOrderDiscussionResponseData} returns this
 */
proto.admin_order.GetOrderDiscussionResponseData.prototype.clearRepliesList = function() {
  return this.setRepliesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin_order.GetOrderDiscussionResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.GetOrderDiscussionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.GetOrderDiscussionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.GetOrderDiscussionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDiscussionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_order.Meta.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.admin_order.GetOrderDiscussionResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.GetOrderDiscussionResponse}
 */
proto.admin_order.GetOrderDiscussionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.GetOrderDiscussionResponse;
  return proto.admin_order.GetOrderDiscussionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.GetOrderDiscussionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.GetOrderDiscussionResponse}
 */
proto.admin_order.GetOrderDiscussionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_order.Meta;
      reader.readMessage(value,proto.admin_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_order.GetOrderDiscussionResponseData;
      reader.readMessage(value,proto.admin_order.GetOrderDiscussionResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.GetOrderDiscussionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.GetOrderDiscussionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.GetOrderDiscussionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.GetOrderDiscussionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.admin_order.GetOrderDiscussionResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_order.Meta}
 */
proto.admin_order.GetOrderDiscussionResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_order.Meta, 1));
};


/**
 * @param {?proto.admin_order.Meta|undefined} value
 * @return {!proto.admin_order.GetOrderDiscussionResponse} returns this
*/
proto.admin_order.GetOrderDiscussionResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_order.GetOrderDiscussionResponse} returns this
 */
proto.admin_order.GetOrderDiscussionResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_order.GetOrderDiscussionResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated GetOrderDiscussionResponseData data = 2;
 * @return {!Array<!proto.admin_order.GetOrderDiscussionResponseData>}
 */
proto.admin_order.GetOrderDiscussionResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.admin_order.GetOrderDiscussionResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin_order.GetOrderDiscussionResponseData, 2));
};


/**
 * @param {!Array<!proto.admin_order.GetOrderDiscussionResponseData>} value
 * @return {!proto.admin_order.GetOrderDiscussionResponse} returns this
*/
proto.admin_order.GetOrderDiscussionResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.admin_order.GetOrderDiscussionResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin_order.GetOrderDiscussionResponseData}
 */
proto.admin_order.GetOrderDiscussionResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.admin_order.GetOrderDiscussionResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin_order.GetOrderDiscussionResponse} returns this
 */
proto.admin_order.GetOrderDiscussionResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.SendDiscussionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.SendDiscussionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.SendDiscussionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.SendDiscussionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    parentOrderDiscussionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attachmentPath: jspb.Message.getFieldWithDefault(msg, 4, ""),
    attachmentPathType: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.SendDiscussionRequest}
 */
proto.admin_order.SendDiscussionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.SendDiscussionRequest;
  return proto.admin_order.SendDiscussionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.SendDiscussionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.SendDiscussionRequest}
 */
proto.admin_order.SendDiscussionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentOrderDiscussionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentPath(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentPathType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.SendDiscussionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.SendDiscussionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.SendDiscussionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.SendDiscussionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParentOrderDiscussionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttachmentPath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAttachmentPathType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.admin_order.SendDiscussionRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.SendDiscussionRequest} returns this
 */
proto.admin_order.SendDiscussionRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string parent_order_discussion_id = 2;
 * @return {string}
 */
proto.admin_order.SendDiscussionRequest.prototype.getParentOrderDiscussionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.SendDiscussionRequest} returns this
 */
proto.admin_order.SendDiscussionRequest.prototype.setParentOrderDiscussionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.admin_order.SendDiscussionRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.SendDiscussionRequest} returns this
 */
proto.admin_order.SendDiscussionRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string attachment_path = 4;
 * @return {string}
 */
proto.admin_order.SendDiscussionRequest.prototype.getAttachmentPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.SendDiscussionRequest} returns this
 */
proto.admin_order.SendDiscussionRequest.prototype.setAttachmentPath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string attachment_path_type = 5;
 * @return {string}
 */
proto.admin_order.SendDiscussionRequest.prototype.getAttachmentPathType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_order.SendDiscussionRequest} returns this
 */
proto.admin_order.SendDiscussionRequest.prototype.setAttachmentPathType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.SendDiscussionResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.SendDiscussionResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.SendDiscussionResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.SendDiscussionResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.SendDiscussionResponseData}
 */
proto.admin_order.SendDiscussionResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.SendDiscussionResponseData;
  return proto.admin_order.SendDiscussionResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.SendDiscussionResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.SendDiscussionResponseData}
 */
proto.admin_order.SendDiscussionResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.SendDiscussionResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.SendDiscussionResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.SendDiscussionResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.SendDiscussionResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_order.SendDiscussionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_order.SendDiscussionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_order.SendDiscussionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.SendDiscussionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_order.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_order.SendDiscussionResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_order.SendDiscussionResponse}
 */
proto.admin_order.SendDiscussionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_order.SendDiscussionResponse;
  return proto.admin_order.SendDiscussionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_order.SendDiscussionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_order.SendDiscussionResponse}
 */
proto.admin_order.SendDiscussionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_order.Meta;
      reader.readMessage(value,proto.admin_order.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_order.SendDiscussionResponseData;
      reader.readMessage(value,proto.admin_order.SendDiscussionResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_order.SendDiscussionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_order.SendDiscussionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_order.SendDiscussionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_order.SendDiscussionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_order.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_order.SendDiscussionResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_order.Meta}
 */
proto.admin_order.SendDiscussionResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_order.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_order.Meta, 1));
};


/**
 * @param {?proto.admin_order.Meta|undefined} value
 * @return {!proto.admin_order.SendDiscussionResponse} returns this
*/
proto.admin_order.SendDiscussionResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_order.SendDiscussionResponse} returns this
 */
proto.admin_order.SendDiscussionResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_order.SendDiscussionResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SendDiscussionResponseData data = 2;
 * @return {?proto.admin_order.SendDiscussionResponseData}
 */
proto.admin_order.SendDiscussionResponse.prototype.getData = function() {
  return /** @type{?proto.admin_order.SendDiscussionResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_order.SendDiscussionResponseData, 2));
};


/**
 * @param {?proto.admin_order.SendDiscussionResponseData|undefined} value
 * @return {!proto.admin_order.SendDiscussionResponse} returns this
*/
proto.admin_order.SendDiscussionResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_order.SendDiscussionResponse} returns this
 */
proto.admin_order.SendDiscussionResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_order.SendDiscussionResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.admin_order);
