import { useContext, useEffect, useState, type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AcceptUserPackageRequest, GetListUserPackageRequest, GetListUserPackageResponseData, Pagination } from '../../grpc/affiliator/affiliator_user_package_pb';
import { LoadingContext } from '../../objects/loading_context';
import { affiliatorUserPackageClient } from '../../App';
import { getToken, initials, money } from '../../utils/helper';
import { ORDER_AFFILIATOR, ORDER_COMMENTATOR, ORDER_DONE, PAID, PENDING_AFFILIATOR, PENDING_COMMENTATOR } from '../../utils/contants';
import { errorToast } from '../../utils/helper-ui';
import NoData from '../no_data';
import { Avatar } from 'rsuite';

interface AffiliatorPackageListProps {
    mode: string
}

const AffiliatorPackageList: FC<AffiliatorPackageListProps> = ({
    mode
}) => {
    let nav = useNavigate()
    const [mounted, setMounted] = useState(false);
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    // const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [pagination, setPagination] = useState<Pagination.AsObject | null>(null);
    const [data, setData] = useState<GetListUserPackageResponseData.AsObject[]>([]);
    const [expandedIds, setExpandedIds] = useState<string[]>([]);
    const [modalFilter, setModalFilter] = useState(false);

    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return
    }, [mounted]);

    useEffect(() => {
        if (mounted)
            getData()
    }, [mounted, page, limit]);




    const getData = async () => {
        try {
            setIsLoading(true)
            let resp: GetListUserPackageResponseData.AsObject[] = await new Promise((resolve, reject) => {
                let req = new GetListUserPackageRequest()
                req.setPage(page)
                req.setLimit(limit)
                switch (mode) {


                    case ORDER_COMMENTATOR:
                        req.setPaymentStatus(PENDING_COMMENTATOR)
                        break;
                    case ORDER_DONE:
                        req.setPaymentStatus(PAID)
                        break;
                    default:
                        req.setPaymentStatus(PENDING_AFFILIATOR)
                        break;
                }

                affiliatorUserPackageClient.getListUserPackage(req, getToken(), (err, resp) => {
                    if (err) {
                        reject(err.message)
                        return
                    }
                    setPagination(resp.getPagination()?.toObject() ?? null)
                    resolve(resp.getDataList().map(e => e.toObject()))
                })
            })
            setData([...data, ...resp])
        } catch (error) {
            errorToast(`${error}`)
        } finally {
            setIsLoading(false)
        }
    }

    const takeOrderPackages = (e: GetListUserPackageResponseData.AsObject) => {
        setIsLoading(true)
        let req = new AcceptUserPackageRequest();
        req.setUserPackageId(e.id)
        affiliatorUserPackageClient.acceptUserPackage(req, getToken(), (err, resp) => {
            setIsLoading(false)
            if (err) {
                errorToast(err.message)
                return
            }
            nav(`/affiliator/package/${e.id}`)
        })
    }



    const renderCard = (e: GetListUserPackageResponseData.AsObject) => (
        <div className=' p-4 rounded-lg bg-white shadow-md shadow-gray-100 mb-2 last:mb-0'>
            <div className='flex justify-between py-4 px-4 items-center border-b border-b-gray-200'>
                <div className=' flex items-center'>
                    <Avatar size={'sm'} circle src={e.user?.profilePictureUrl} alt={e.user?.profilePictureUrl ? e.user.name : initials(e.user?.name)} />
                    <p className=' font-semibold ml-2'>{e.user?.name}</p>
                </div>
                {/* {mode == ORDER_UNPAID && <span className='text-sm text-red-500'>Belum dibayar</span>}
                {mode == ORDER_PROCESSING && <span className='text-sm text-sky-500'>Sedang diproses</span>}
                {mode == ORDER_DONE && <span className='text-sm text-sky-500'>Berhasil</span>}
                {mode == ORDER_FAILED && <span className='text-sm text-red-500'>Gagal</span>} */}
            </div>
            <div className='p-4 grid grid-cols-5 gap-4  border-b-gray-200'>
                <div className='flex flex-col'>
                    <div className=' bg-sky-50 flex justify-center items-center rounded-lg p-2'>
                        <img src={e.packageName.toLocaleLowerCase() == 'premium' ? `/assets/business_logo.png` : `/assets/freemium_logo.png`} alt="" className='w-10 h-10' />
                    </div>
                </div>
                <div className=' col-span-4'>
                    <h6>{e.packageName}</h6>
                    <div className='flex justify-between text-sm'>
                        <span className=''>Total Komentar</span>
                        <span>{money(e.commentPerDay)} / Hari</span>
                    </div>
                    <div className='flex justify-between'>
                        <span className=''>Periode</span>
                        <span>{money(e.durationDay)}</span>
                    </div>

                </div>
            </div>
            <div className='px-4 justify-between flex   items-center '>

                <div className=''>
                   <span className='font-semibold'>Komisi</span>  <span className='font-semibold ml-2'>  Rp. {money(e.affiliatorFee)}</span>
                </div>

                <div className='ml-4'>
                    <button onClick={() => {
                        if (mode == ORDER_AFFILIATOR) {
                            takeOrderPackages(e)
                        } else {
                            nav(`/affiliator/package/${e.id}`)
                        }
                    }} type="button" className="btn">{mode == ORDER_AFFILIATOR ? 'Terima Tawaran' : 'Detail' } </button>
                </div>
            </div>
        </div>
    )



    return (
        <div className='min-h-[100px] p-4'>
            {!isLoading &&
                <ul>
                    {data.map(e => <li key={e.id} className='mb-2'>{renderCard(e)}</li>)}
                </ul>
            }
            {data.length == 0 && !isLoading && <NoData />}
            <div className=' justify-center items-start flex mt-4'>
                {(pagination?.totalPages ?? 0) > 0 && (pagination?.next ?? 0) > page && <button className='btn-white w-48' onClick={() => {
                    setPage(page + 1)
                }}>More</button>}

            </div>
        </div>
    );
}
export default AffiliatorPackageList;