import { useContext, useEffect, useState, type FC } from 'react';
import { GetProfileResponseData } from '../../grpc/client/client_general_pb';
import { GetUserPackageStatusResponseData } from '../../grpc/client/client_user_package_pb';
import { useNavigate } from 'react-router-dom';
import ClientMid from '../../middlewares/ClientMid';
import { clientGeneral, clientOrder, serviceGeneralClient } from '../../App';
import moment from 'moment';
import { GetNotificationRequest, GetNotificationResponseData } from '../../grpc/service/general_pb';
import { getToken } from '../../utils/helper';
import { errorToast } from '../../utils/helper-ui';
import { LoadingContext } from '../../objects/loading_context';

interface NotifProps { }

const Notif: FC<NotifProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);

    const nav = useNavigate()
    const [mounted, setMounted] = useState(false);
    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const [userPackage, setUserPackage] = useState<GetUserPackageStatusResponseData.AsObject>();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [notifications, setNotifications] = useState<GetNotificationResponseData.AsObject[]>([]);


    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return

    }, [mounted]);

    useEffect(() => {
        getNotif()

    }, [page, limit]);

    const getNotif = () => {
        setIsLoading(true)
        let req = new GetNotificationRequest()
        req.setPage(page)
        req.setLimit(limit)
        serviceGeneralClient.getNotification(req, getToken(), (err, resp) => {
            if (err) {
                errorToast(err.message)
                setIsLoading(false)
                return
            }
            setIsLoading(false)
            setNotifications(resp.getDataList().map(e => e.toObject()))
        })
    }
    return (<ClientMid onLoad={setUserPackage} onLoadProfile={setProfile}>
        <div className='flex flex-col items-center px-8'>
            {notifications.map(e => (<div className='flex justify-center flex-col items-center' key={e.id}>
                <small className='text-center'>{moment.utc(e.createdAt).format("DD MMMM YYYY HH:mm")}</small>
                <div className=' w-full border rounded-lg border-sky-400 flex-col flex'>
                    <div className=' rounded-t-lg p-4 bg-sky-400 text-white text-center'>
                        {e.title}
                    </div>
                    <div className=' rounded-b-lg text-gray-600 p-4'>
                        {e.body}
                    </div>
                </div>
            </div>))}

        </div>
    </ClientMid>);
}
export default Notif;