import { useContext, useState, type FC } from 'react';
import ProfileMid from '../middlewares/ProfileMid';
import { GetProfileRequest, GetProfileResponseData } from '../grpc/client/client_general_pb';
import Back from '../components/back';
import { Link, useNavigate } from 'react-router-dom';
import { clientGeneral, generalClient } from '../App';
import { JoinAffiliateProgramRequest } from '../grpc/service/general_pb';
import { getToken, setStorageProfile } from '../utils/helper';
import { LoadingContext } from '../objects/loading_context';
import { errorToast } from '../utils/helper-ui';
import RefTerm from '../components/ref_term';

interface InviteProps { }

const Invite: FC<InviteProps> = ({ }) => {
    const nav = useNavigate()
    const { isLoading, setIsLoading } = useContext(LoadingContext);

    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();

    const getProfile = async () => {
        return new Promise((resolve, reject) => {
            clientGeneral.getProfile(new GetProfileRequest(), getToken(), (err, resp) => {
                if (err) {
                    reject(err.message)
                    return
                }
                resolve(resp.getData()?.toObject())
            })
        })
    }
    const sendRequest = async () => {
        return new Promise((resolve, reject) => {
            let req = new JoinAffiliateProgramRequest()
            generalClient.joinAffiliateProgram(req, getToken(), (err, resp) => {
                if (err) {
                    reject(err.message)
                    return
                }
                resolve(true)
            })
        })
    }

    return (
        <ProfileMid onLoad={setProfile}>
            <div className='flex flex-col h-full'>
                <div className='w-full px-4 py-2 justify-between items-center flex pt-6 bg-white'>
                    <Back />
                    <h6>Ajak Teman</h6>
                    <div className='p-2'></div>
                </div>
                <div className='p-6 flex-1 overflow-y-auto'>
                    <RefTerm>
                        <div className='flex justify-center'>

                            <button onClick={async () => {
                                try {
                                    setIsLoading(true)
                                    await sendRequest()
                                    let profile = await getProfile()
                                    setStorageProfile(profile)
                                    nav(-1)
                                } catch (error) {
                                    errorToast(`${error}`)
                                } finally {
                                    setIsLoading(false)
                                }


                            }} type="button" className="btn m-auto">Terima & Lanjutkan</button>
                        </div>
                    </RefTerm>
                </div>
            </div>
        </ProfileMid>
    );
}
export default Invite;