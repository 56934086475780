import type { FC } from 'react';
import { HiOutlineCheckCircle } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import { premiumFeatures } from '../utils/contants';

interface UpgradePackageProps { }

const UpgradePackage: FC<UpgradePackageProps> = ({ }) => {
    const nav = useNavigate()

    return (<div className='cursor-pointer' onClick={() => nav('/buy-package')}>
        <h5 className='mb-2'>Upgrade Akunmu</h5>
        <div className='p-4 bg-white shadow-lg shadow-gray-200 rounded-xl mb-8'>
            <div className='grid grid-cols-3 gap-4'>
                <div className='p-2 flex flex-col  justify-center items-center'>
                    <img src="/assets/business_logo.png" alt="" className='w-16' />
                    <h6>Premium</h6>
                </div>
                <div className=' col-span-2'>
                    <ul className='mb-0'>
                        {premiumFeatures.map(e => <li key={e} className='flex items-center mb-2 last:mb-0'>
                            <HiOutlineCheckCircle color='green' size={16} className='mr-2' />
                            <span className=' text-xs'>{e}</span>
                        </li>)}
                    </ul>
                </div>
            </div>
        </div>
    </div>);
}
export default UpgradePackage;