import { useEffect, useState, type FC, type ReactNode } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Forbidden from '../components/forbidden';
import { USER_ROLE } from '../utils/contants';
import { asyncLocalStorage } from '../utils/helper';
import ProfileMid from './ProfileMid';
import { HiBriefcase, HiHome, HiOutlineBriefcase, HiOutlineHome, HiOutlineShoppingBag, HiOutlineUserGroup, HiOutlineUsers, HiShoppingBag, HiUserGroup, HiUsers } from 'react-icons/hi2';
import { GetProfileResponseData } from '../grpc/service/general_pb';

interface AdminMidProps {
    children: ReactNode
    onLoadProfile?: (d: GetProfileResponseData.AsObject) => void
    noPadding?: boolean
    hideHeader?: boolean
    hideMenu?: boolean
    header?: ReactNode
}

const AdminMid: FC<AdminMidProps> = ({
    children,
    onLoadProfile,
    noPadding,
    hideHeader,
    hideMenu,
    header,
}) => {
    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const [role, setRole] = useState("");
    const nav = useNavigate()
    const [mounted, setMounted] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return
        asyncLocalStorage.getItem(USER_ROLE).then(v => setRole(v))

    }, [mounted]);

    return (role != "admin" ? <Forbidden /> : <ProfileMid onLoad={onLoadProfile} >
        {!hideHeader && (header ??
            <div className='w-full px-4 py-2 justify-between flex pt-6 bg-white'>
                <div className='flex items-center '>
                    <img src={"/assets/logo.svg"} alt="" className=' w-6  mr-2 ' />
                    <h3 className='font-bold text-lg'><span className=' text-sky-500 mr-1 mb-4'>Grosir</span><span>Komen</span></h3>
                </div>
                {/* <img src={"/assets/customer_service.svg"} alt="" className=' w-6 ' onClick={() => {
                    window.open(`https://wa.me/6287824185785?text=Halo, min boleh saya tanya tanya dulu?`)
                }} /> */}

            </div>)
        }
        <div className={`flex-1 ${!noPadding && 'p-4'} overflow-y-auto `}>
            {children}
        </div>
        {!hideMenu &&
            <div className="flex  w-full  menu-bottom justify-between bg-white shadow-[_0_0px_10px_rgba(0,0,0,0.1)] text-gray-400 px-4 ">
                <Link to={'/admin'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/admin' && 'active'}`} >
                    {location.pathname == '/admin' ? <HiHome size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineHome size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Home</span>
                </Link>
                <Link to={'/admin/client'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/admin/client' && 'active'}`} >
                    {location.pathname == '/admin/client' ? <HiUsers size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineUsers size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Client</span>
                </Link>
                <Link to={'/admin/affiliator'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/admin/affiliator' && 'active'}`} >
                    {location.pathname == '/admin/affiliator' ? <HiUserGroup size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineUserGroup size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Affiliator</span>
                </Link>

                <Link to={'/admin/package'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/admin/package' && 'active'}`} >
                    {location.pathname == '/admin/package' ? <HiShoppingBag size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineShoppingBag size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Order</span>
                </Link>
                <Link to={'/admin/profile'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/admin/profile' && 'active'}`} >
                    {location.pathname == '/admin/profile' ? <HiBriefcase size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineBriefcase size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Profil</span>
                </Link>
            </div>
        }
    </ProfileMid>);
}
export default AdminMid;