import { useContext, useEffect, useState, type FC } from "react";
import { LoadingContext } from "../../objects/loading_context";
import {
  GetOrderListRequest,
  GetOrderListResponseData,
} from "../../grpc/commentator/commentator_order_pb";
import { commentatorAccountClient, commentatorOrderClient } from "../../App";
import {
  GetDetailCommentatorAccountRequest,
  Pagination,
} from "../../grpc/commentator/commentator_account_pb";
import { errorToast } from "../../utils/helper-ui";
import { getToken, numberToDuration } from "../../utils/helper";
import NoData from "../no_data";
import moment from "moment";
import { DONE, IN_PROGRESS } from "../../utils/contants";
import { HiOutlineClock } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

interface CommentatorOrderListProps {
  accountId: string;
  mode: string;
}

const CommentatorOrderList: FC<CommentatorOrderListProps> = ({
  accountId,
  mode,
}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [orders, setOrders] = useState<GetOrderListResponseData.AsObject[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState<Pagination.AsObject | null>(
    null
  );
  const nav = useNavigate()

  useEffect(() => {
    getOrders();
  }, [mode, page, limit, accountId]);

  const getOrders = () => {
    if (!accountId) return;
    setIsLoading(true);
    let req = new GetOrderListRequest();
    req.setCommentatorAccountId(accountId);
    req.setPage(page);
    req.setLimit(limit);
    req.setStatus(mode);
    commentatorOrderClient.getOrderList(req, getToken(), (err, resp) => {
      setIsLoading(false);
      if (err) {
        errorToast(err.message);
        return;
      }
      setOrders(resp?.getDataList()?.map((e) => e.toObject()));
      setPagination(resp.getPagination()?.toObject() ?? null);
    });
  };

  const renderData = () => {
    if (orders.length == 0) return <NoData />
    
    return (
        <div className='  px-4 mt-2'>
            {orders.map(e => {
                let hours = moment.utc(e.dueDate).diff(moment.utc(), 'hours', false)
                let gradient = ""
                if (mode != DONE) {

                    if (hours < 4) {
                        gradient = "from-yellow-100 via-white to-white bg-gradient-to-b"
                    }
                    if (hours < 0) {
                        gradient = "from-red-200 via-white to-white bg-gradient-to-b"
                    }

                }
                return (<div className={`p-4 rounded-lg bg-white shadow-md shadow-gray-100 last:mb-0 mb-4 ${gradient}`} key={e.id}>
                    <div className='flex justify-between mb-4'>
                        <div className='flex'>
                            <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/assets/logo.svg";
                            }} src={e.user?.profilePictureUrl} alt="" className=" object-cover w-8 h-8 rounded-lg bg-gray-50 mr-2" />
                            <span className=' font-semibold'>{e.user?.name}</span>
                        </div>
                        {mode == IN_PROGRESS && <span className='flex text-orange-400 items-center'><HiOutlineClock size={18} className='mr-2' />   {numberToDuration(moment.utc().diff(moment.utc(e.dueDate), 'minutes'))} </span>}
                        {mode == DONE && <span className='flex text-green-400 items-center'>
                            Selesai
                        </span>}
                    </div>
                    <div className='flex'>
                        <img onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/assets/logo.svg";
                        }} src={e.thumbnailUrl} alt="" className=" object-cover w-16 h-16 rounded-lg bg-gray-50 mr-2 flex" />
                        <div className='flex flex-col '>
                            <h6 className='overflow-hidden w-64 truncate'> {e.title} </h6>
                            <p className='overflow-hidden w-64 truncate'>
                                {e.description}
                            </p>
                        </div>
                    </div>
                    <hr />
                    <div className='flex justify-end items-center'>

                        <button className='btn' onClick={() => {
                            nav(`/commentator/${accountId}/order/${e.id}`)
                        }}>Lihat</button>
                    </div>

                </div>)
            })}
        </div>
    );
}

  return (
    <div className="min-h-[300px] py-4">
        {renderData()}
      <div className=" justify-center items-start flex mt-4">
        {(pagination?.totalPages ?? 0) > 0 &&
          (pagination?.next ?? 0) > page && (
            <button
              className="btn-white w-48"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              More
            </button>
          )}
      </div>
    </div>
  );
};
export default CommentatorOrderList;
