import { useContext, useEffect, useRef, useState, type FC } from "react";
import { LoadingContext } from "../../objects/loading_context";
import {
  ApproveWithdrawRequestRequest,
  DeclineWithdrawRequestRequest,
  Pagination,
  WithdrawRequestListRequest,
  WithdrawRequestListResponseData,
} from "../../grpc/admin/admin_withdraw_request_pb";
import { adminWithdrawClient, clientFile } from "../../App";
import { getToken, initials, money, sepNumber } from "../../utils/helper";
import { errorToast } from "../../utils/helper-ui";
import { HiMagnifyingGlass, HiOutlineFunnel, HiXMark } from "react-icons/hi2";
import { Avatar, Drawer } from "rsuite";
import Moment from "react-moment";
import Back from "../back";
import { BsCamera } from "react-icons/bs";
import { FileBase64Request } from "../../grpc/service/file_pb";

interface WithdrawCardProps {
  status: string;
  onApprove: () => void;
}

const WithdrawCard: FC<WithdrawCardProps> = ({ status, onApprove }) => {
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [pagination, setPagination] = useState<Pagination.AsObject | null>(
    null
  );

  const [search, setSearch] = useState("");
  const [data, setData] = useState<WithdrawRequestListResponseData.AsObject[]>(
    []
  );
  const [modalFilter, setModalFilter] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [selectedData, setSelectedData] =
    useState<WithdrawRequestListResponseData.AsObject | null>(null);

  const [modalRejectOpen, setModalRejectOpen] = useState(false);
  const [modalApproveOpen, setModalApproveOpen] = useState(false);
  const [proofPath, setProofPath] = useState<string | null>(null);
  const [proofUrl, setProofUrl] = useState<string | null>(null);
  const [proofType, setProofType] = useState<string | null>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const [remarks, setRemarks] = useState("");

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
  }, [mounted]);

  useEffect(() => {
    setData([]);
  }, [search]);

  useEffect(() => {
    if (mounted) getData();
  }, [mounted, page, limit, search]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let req = new WithdrawRequestListRequest();
      req.setPage(page);
      req.setLimit(limit);
      req.setStatus(status);
      req.setName(search);
      adminWithdrawClient.withdrawRequestList(req, getToken(), (err, resp) => {
        if (err) {
          errorToast(err.message);
          return;
        }
        setPagination(resp.getPagination()?.toObject() ?? null);
        let _data = resp.getDataList().map((e) => e.toObject());
        if (resp.getPagination()?.toObject().page == 1) {
          setData(_data);
        } else {
          setData([...data, ..._data]);
        }
      });

      // console.log(resp)
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="flex">
        <div className="relative flex-1">
          <input
            className="search-control"
            placeholder="Search ...."
            onChange={(val) => {
              setSearch(val.target.value);
            }}
            value={search}
          />
          <HiMagnifyingGlass className="absolute top-2.5 left-4 text-lg text-gray-400" />
        </div>
        <div
          className=" border-gray-300 rounded-lg p-2.5 ml-2  border-2"
          onClick={() => setModalFilter(true)}
        >
          <HiOutlineFunnel />
        </div>
      </div>
      <div className="mt-4">
        {data.map((e, i) => (
          <div
            key={i}
            className="flex flex-row mb-4 justify-between p-4 border-b last:border-b-0"
            onClick={() => {
              setSelectedData(e);
              setModalDetail(true);
            }}
          >
            <div className="flex flex-row gap-2">
              <Avatar
                circle
                size="md"
                src={e.userProfilePictureUrl}
                alt={initials(e.userName)}
              />
              <div>
                <div className="flex flex-row  justify-center items-center gap-2">
                  <p className="font-[600] max-w-32 whitespace-nowrap text-ellipsis overflow-hidden">
                    {e.userName}
                  </p>
                  <div className="w-1 h-1 rounded-full bg-gray-700"></div>
                  <p className=" text-gray-500 text-sm italic">{e.userRole}</p>
                </div>
                <Moment format="DD MMM YYYY" className="text-xs text-gray-600">
                  {e.createdAt}
                </Moment>
              </div>
            </div>

            <div>{money(e.amount)}</div>
          </div>
        ))}
      </div>
      <div className=" justify-center items-start flex">
        {(pagination?.totalPages ?? 0) > 0 &&
          (pagination?.next ?? 0) > page && (
            <button
              className="btn-white w-48"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              More
            </button>
          )}
      </div>

      <Drawer
        className="drawer-rounded"
        size={"md"}
        closeButton={null}
        placement={"bottom"}
        open={modalFilter}
        onClose={() => setModalFilter(false)}
      >
        <Drawer.Title>
          <div className="flex p-4  justify-between items-center">
            <div className="flex   items-center">
              <p> Filter</p>
            </div>
            <HiXMark onClick={() => setModalFilter(false)} />
          </div>
        </Drawer.Title>
        <Drawer.Body style={{ padding: 20 }}></Drawer.Body>
      </Drawer>

      <Drawer
        className="drawer-rounded"
        size={"full"}
        closeButton={null}
        placement={"bottom"}
        open={modalDetail}
        onClose={() => setModalDetail(false)}
      >
        <Drawer.Body style={{ padding: 0 }}>
          <div className="flex flex-col h-full">
            <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white border-b">
              <Back onClick={() => setModalDetail(false)} />
              <h6>Rincian Penarikan</h6>
              <div className="p-2 "></div>
            </div>
            <div className="flex flex-1 flex-col px-4 py-8">
              <p className="text-center">Total Permintaan</p>
              <h3 className="text-center">Rp. {money(selectedData?.amount)}</h3>
              <div className="mt-8">
                <div className="flex justify-between items-center mb-4">
                  <p className="m-0">Nama</p>
                  <p className="m-0">{selectedData?.userName}</p>
                </div>
                <div className="flex justify-between items-center mb-4">
                  <p className="m-0">Role</p>
                  <p className="m-0">{selectedData?.userRole}</p>
                </div>
                <div className="flex justify-between items-center mb-4">
                  <p className="m-0">Tgl. Permintaan</p>
                  <Moment format="DD MMMM YYYY" className="m-0">
                    {selectedData?.createdAt}
                  </Moment>
                </div>

                <div className="p-4 mt-8 bg-gray-200 rounded-2xl flex justify-between items-center">
                  <div>
                    <p className="text-lg font-[600] m-0">
                      {selectedData?.userFinancialAccountType}
                    </p>
                    <p className="m-0">
                      {selectedData?.userFinancialAccountName}
                    </p>
                    <p className="m-0">
                      {selectedData?.userFinancialAccountNumber}
                    </p>
                  </div>
                  <div
                    className="text-blue-500 text-lg cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        selectedData?.userFinancialAccountNumber ?? ""
                      );
                    }}
                  >
                    Salin
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 justify-between flex">
              <button
                onClick={() => {
                  setModalDetail(false);
                  setModalRejectOpen(true);
                }}
                className="btn-pills"
              >
                Tolak
              </button>
              <button
                onClick={() => {
                  setModalDetail(false);
                  setModalApproveOpen(true);
                }}
                className="btn"
              >
                Terima
              </button>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>

      <Drawer
        className="drawer-rounded"
        size={"md"}
        closeButton={null}
        placement={"bottom"}
        open={modalRejectOpen}
        onClose={() => setModalRejectOpen(false)}
      >
        <Drawer.Title>
          <div className="flex p-4  justify-between items-center">
            <div className="flex   items-center">
              <p> Alasan Penolakan</p>
            </div>
            <HiXMark onClick={() => setModalRejectOpen(false)} />
          </div>
        </Drawer.Title>
        <Drawer.Body style={{ padding: 20 }}>
          <div className="flex flex-col ">
            <div className="flex-1">
              <textarea
                className="form-control"
                value={remarks}
                onChange={(val) => setRemarks(val.target.value)}
                rows={5}
                placeholder="Beri Alasan"
              />
            </div>

            <div className="mt-8">
              <button onClick={() => {
               try {
                if (!remarks)  {
                    throw Error("Berikan alasan penolakan")
                }
                setIsLoading(true);
                let req = new DeclineWithdrawRequestRequest();
                req.setId(selectedData?.id!);
                req.setRemarks(remarks)
                adminWithdrawClient.declineWithdrawRequest(
                  req,
                  getToken(),
                  (err, resp) => {
                    if (err) {
                      errorToast(err.message);
                      setIsLoading(false);
                      return;
                    }
                    setModalRejectOpen(false);
                    setRemarks("")
                    getData();
                    onApprove();
                  }
                );
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                setIsLoading(false);
              }
              }} type="button" className="btn w-full">
                Konfirmasi
              </button>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>

      <Drawer
        className="drawer-rounded"
        size={"full"}
        closeButton={null}
        placement={"bottom"}
        open={modalApproveOpen}
        onClose={() => setModalApproveOpen(false)}
      >
        <Drawer.Body style={{ padding: 0, backgroundColor: "#f8f8f9" }}>
          <div className="flex flex-col h-full">
            <div className="w-full px-4 py-2 justify-between items-center flex pt-6  border-b bg-white">
              <Back onClick={() => setModalApproveOpen(false)} />
              <h6>Bukti Transfer</h6>
              <div className="p-2 "></div>
            </div>
            <div className="flex-1">
              <div className="flex flex-col px-4 py-4 bg-white">
                <p className="font-[600] text-xl">Detail Penarikan</p>
                <div className="mt-4">
                  <div className="flex justify-between items-center mb-4">
                    <p className="m-0">Nama</p>
                    <p className="m-0">{selectedData?.userName}</p>
                  </div>
                  <div className="flex justify-between items-center mb-4">
                    <p className="m-0">Role</p>
                    <p className="m-0">{selectedData?.userRole}</p>
                  </div>
                  <div className="flex justify-between items-center mb-4">
                    <p className="m-0">Tgl. Permintaan</p>
                    <Moment format="DD MMMM YYYY" className="m-0">
                      {selectedData?.createdAt}
                    </Moment>
                  </div>
                  <div className="flex justify-between items-center mb-4">
                    <p className="m-0">Total Permintaan</p>
                    <p className="m-0">{money(selectedData?.amount)}</p>
                  </div>
                  <div className="flex justify-between items-center mb-4">
                    <p className="m-0">No. Dana</p>
                    <p className="m-0">
                      {sepNumber(
                        selectedData?.userFinancialAccountNumber ?? ""
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col px-4 py-4 bg-white mt-2">
                <p className="font-[600] text-xl">Bukti Transfer</p>
                <div className="p-2 bg-orange-200 rounded-xl my-4">
                  <p className="text-center text-sm">
                    Pastikan data yang anda masukan sudah benar
                  </p>
                </div>

                <div
                  className=" bg-gray-300 rounded-xl "
                  onClick={() => {
                    fileRef?.current?.click();
                  }}
                >
                  {proofUrl ? (
                    <img
                      src={proofUrl}
                      className=" rounded-xl object-cover w-full h-full"
                    />
                  ) : (
                    <div className=" p-12 flex flex-col justify-center items-center p-12 bg-gray-300 rounded-xl">
                      <BsCamera size={64} />+ Upload Bukti
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="p-4 justify-between flex">
              <button
                onClick={() => {
                  try {
                    setIsLoading(true);
                    let req = new ApproveWithdrawRequestRequest();
                    req.setId(selectedData?.id!);
                    req.setReceiptPath(proofPath!);
                    req.setReceiptPathType(proofType!);
                    adminWithdrawClient.approveWithdrawRequest(
                      req,
                      getToken(),
                      (err, resp) => {
                        if (err) {
                          errorToast(err.message);
                          setIsLoading(false);
                          return;
                        }
                        setModalApproveOpen(false);
                        setProofPath(null);
                        setProofType(null);
                        setProofUrl(null);
                        getData();
                        onApprove();
                      }
                    );
                  } catch (error) {
                    errorToast(`${error}`);
                  } finally {
                    setIsLoading(false);
                  }
                }}
                className="btn w-full"
              >
                Kirim
              </button>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
      <input
        accept="image/*"
        type="file"
        className="hidden"
        ref={fileRef}
        onChange={(el) => {
          if (el.target.files) {
            let reader = new FileReader();

            reader.onload = function () {
              // console.log(reader?.result?.toString())
              let req = new FileBase64Request();
              req.setFile(`${reader?.result}`.split("base64,")[1]);
              clientFile.uploadBase64Temp(req, getToken(), (err, resp) => {
                if (err) {
                  errorToast(err.message);
                  setIsLoading(false);
                  return;
                }
                let path = resp.getData()?.toObject();

                setProofType(path?.pathType ?? null);
                setProofUrl(path?.url ?? null);
                setProofPath(path?.path ?? null);
              });
            };
            reader.readAsDataURL(el.target.files[0]);
          }
        }}
      />
    </div>
  );
};
export default WithdrawCard;
