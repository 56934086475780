import type { FC } from 'react';
import { GetMarketingSummaryResponseData } from '../grpc/service/general_pb';
import { money } from '../utils/helper';

interface HeroReferalProps {
    data?: GetMarketingSummaryResponseData.AsObject | null
}

const HeroReferal: FC<HeroReferalProps> = ({ 
    data
}) => {
    return (<div className='flex flex-col rounded-xl mb-4 bg-orange-400 text-white p-4 max-h-[200px]' style={{ backgroundImage: 'url(/assets/bghero.png)',  backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover' }}>
        <p className='text-sm font-semibold'>Rewards Ajak Teman</p>
        <div className='flex justify-between items-center'>
            <h3>Rp. {money(data?.totalMarketingFee ?? 0)}</h3>
            <div className=' border border-white rounded-s-xl text-xs px-6 py-1 -mr-5'>Tarik</div>
        </div>
        <div className='w-full h-48 flex flex-col justify-end '>
            <div className='flex items-center' >
                <img src="/assets/ep_warning.png" alt="" className='w-4 h-4' />
                <span className=' text-xs ml-2'>Saldo di transfer ke DANA, estimasi 2x24 jam</span>

            </div>
        </div>
    </div>);
}
export default HeroReferal;