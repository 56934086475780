import type { FC } from 'react';
import { GetOrderDiscussionResponseDataReply } from '../grpc/client/client_order_pb';
import { GetProfileResponseData } from '../grpc/client/client_general_pb';
import { Avatar } from 'rsuite';
import { initials } from '../utils/helper';
import moment from 'moment';

interface CommentBubbleProps {
    data: GetOrderDiscussionResponseDataReply.AsObject
    profile: GetProfileResponseData.AsObject | undefined
}

const CommentBubble: FC<CommentBubbleProps> = ({
    data,
    profile,
}) => {
    return (
        <div className={`flex mb-4 items-end ${profile?.id == data.user?.id ? 'justify-start' : 'justify-end'}`}>
            {profile?.id == data.user?.id &&
                <Avatar size='xs' circle src={data.user?.profilePictureUrl} alt={initials(data.user?.name)} />
            }
            <div className='px-2 py-2 pb-4 flex flex-col bg-sky-50 rounded-lg mx-4 relative min-w-32'>
                <span className='text-sm font-semibold mb-4'>{data.user?.name}</span>
                {data.comment}
                <span className=' text-2xs absolute bottom-1 right-1'>{moment.utc(data.createdAt).format("HH:mm")}</span>
            </div>
            {profile?.id != data.user?.id &&
                <Avatar size='xs' circle src={data.user?.profilePictureUrl} alt={initials(data.user?.name)} />
            }
        </div>
    );
}
export default CommentBubble;