import { useEffect, useState, type FC, type ReactNode } from 'react';
import { HiBellAlert, HiHome, HiOutlineBellAlert, HiOutlineHome, HiOutlineListBullet, HiOutlineMegaphone, HiOutlineShoppingBag, HiOutlineUser, HiShoppingBag, HiUser } from 'react-icons/hi2';
import { PiNewspaper, PiPlusCircle, PiPlusCircleBold } from 'react-icons/pi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GetUserPackageStatusRequest, GetUserPackageStatusResponseData } from '../grpc/client/client_user_package_pb';
import { asyncLocalStorage, getStorageProfile, getStorageUserPackage, getToken, printLog } from '../utils/helper';
import ProfileMid from './ProfileMid';
import { USER_ROLE } from '../utils/contants';
import Forbidden from '../components/forbidden';
import { ClientUserPackageHandlerClient } from '../grpc/client/client_user_package_grpc_web_pb';
import { clientUserPackage } from '../App';
import { GetProfileResponseData } from '../grpc/service/general_pb';
import { errorToast } from '../utils/helper-ui';

interface ClientMidProps {
    children: ReactNode
    onLoad?: (d: GetUserPackageStatusResponseData.AsObject) => void
    onLoadProfile?: (d: GetProfileResponseData.AsObject) => void
    noPadding?: boolean
    hideHeader?: boolean
    hideMenu?: boolean
    header?: ReactNode
}

const ClientMid: FC<ClientMidProps> = ({ children, onLoad, onLoadProfile, noPadding, hideHeader, hideMenu, header }) => {
    const [userPackage, setUserPackage] = useState<GetUserPackageStatusResponseData.AsObject>();
    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const [role, setRole] = useState("");
    const nav = useNavigate()
    const [mounted, setMounted] = useState(false);
    const location = useLocation();
    const [packageLoaded, setPackageLoaded] = useState(false);

    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return
        if (!packageLoaded)
            getPackage().then(v => {
                //  console.log(v)
                setUserPackage(v as GetUserPackageStatusResponseData.AsObject)
                setPackageLoaded(true)
            }).catch(err => errorToast(`${err}`))
        getStorageUserPackage().then(v => setUserPackage(v))
        getStorageProfile().then(v => setProfile(v))
        asyncLocalStorage.getItem(USER_ROLE).then(v => setRole(v))

    }, [mounted]);

    const getPackage = async () => {
        return new Promise((resolve, reject) => {
            clientUserPackage.getUserPackageStatus(new GetUserPackageStatusRequest(), getToken(), (err, resp) => {
                if (err) {
                    reject(err.message)
                    return
                }
                resolve(resp.getData()?.toObject())
            })
        })

    }


    useEffect(() => {
        if (userPackage) {
            if (onLoad) onLoad!(userPackage)
        }
        // printLog(userPackage)
    }, [userPackage]);

    useEffect(() => {

        // printLog(profile)
    }, [profile]);

    return (role != "user" ? <Forbidden /> : <ProfileMid onLoad={onLoadProfile}>
        {!hideHeader && (header ??
            <div className='w-full px-4 py-2 justify-between flex pt-6 bg-white'>
                <div className='flex items-center '>
                    <img src={"/assets/logo.svg"} alt="" className=' w-6  mr-2 ' />
                    <h3 className='font-bold text-lg'><span className=' text-sky-500 mr-1 mb-4'>Grosir</span><span>Komen</span></h3>
                </div>
                <img src={"/assets/customer_service.svg"} alt="" className=' w-6 ' onClick={() => {
                    window.open(`https://wa.me/6287824185785?text=Halo, min boleh saya tanya tanya dulu?`)
                }} />

            </div>)
        }
        <div className={`flex-1 ${!noPadding && 'p-4'} overflow-y-auto `}>
            {children}
        </div>
        {!hideMenu &&
            <div className="flex  w-full  menu-bottom justify-between bg-white shadow-[_0_0px_10px_rgba(0,0,0,0.1)] text-gray-400 px-4 ">
                <Link to={'/'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/' && 'active'}`} >
                    {location.pathname == '/' ? <HiHome size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineHome size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Home</span>
                </Link>
                <Link to={'/package-order'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/package-order' && 'active'}`} >
                    {location.pathname == '/package-order' ? <HiShoppingBag size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineShoppingBag size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Pesanan</span>
                </Link>
                <Link to={'/comment-order'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 ${location.pathname == '/comment-order' && 'active'}`} >

                    <PiPlusCircle size={24} className='w-9 h-9 cursor-pointer transition' />
                </Link>
                <Link to={'/notif'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/notif' && 'active'}`} >
                    {location.pathname == '/notif' ? <HiBellAlert size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineBellAlert size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Notif</span>
                </Link>
                <Link to={'/profile'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/profile' && 'active'}`} >
                    {location.pathname == '/profile' ? <HiUser size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineUser size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Profil</span>
                </Link>
            </div>
        }
    </ProfileMid>);
}
export default ClientMid;