/**
 * @fileoverview gRPC-Web generated client stub for admin_general
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: admin_general.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.admin_general = require('./admin_general_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.admin_general.AdminGeneralHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.admin_general.AdminGeneralHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_general.GetSummaryRequest,
 *   !proto.admin_general.GetSummaryResponse>}
 */
const methodDescriptor_AdminGeneralHandler_GetSummary = new grpc.web.MethodDescriptor(
  '/admin_general.AdminGeneralHandler/GetSummary',
  grpc.web.MethodType.UNARY,
  proto.admin_general.GetSummaryRequest,
  proto.admin_general.GetSummaryResponse,
  /**
   * @param {!proto.admin_general.GetSummaryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_general.GetSummaryResponse.deserializeBinary
);


/**
 * @param {!proto.admin_general.GetSummaryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_general.GetSummaryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_general.GetSummaryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_general.AdminGeneralHandlerClient.prototype.getSummary =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/GetSummary',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_GetSummary,
      callback);
};


/**
 * @param {!proto.admin_general.GetSummaryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_general.GetSummaryResponse>}
 *     Promise that resolves to the response
 */
proto.admin_general.AdminGeneralHandlerPromiseClient.prototype.getSummary =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/GetSummary',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_GetSummary);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_general.GetSummaryUserRequest,
 *   !proto.admin_general.GetSummaryUserResponse>}
 */
const methodDescriptor_AdminGeneralHandler_GetSummaryUser = new grpc.web.MethodDescriptor(
  '/admin_general.AdminGeneralHandler/GetSummaryUser',
  grpc.web.MethodType.UNARY,
  proto.admin_general.GetSummaryUserRequest,
  proto.admin_general.GetSummaryUserResponse,
  /**
   * @param {!proto.admin_general.GetSummaryUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_general.GetSummaryUserResponse.deserializeBinary
);


/**
 * @param {!proto.admin_general.GetSummaryUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_general.GetSummaryUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_general.GetSummaryUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_general.AdminGeneralHandlerClient.prototype.getSummaryUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/GetSummaryUser',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_GetSummaryUser,
      callback);
};


/**
 * @param {!proto.admin_general.GetSummaryUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_general.GetSummaryUserResponse>}
 *     Promise that resolves to the response
 */
proto.admin_general.AdminGeneralHandlerPromiseClient.prototype.getSummaryUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/GetSummaryUser',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_GetSummaryUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_general.GetSummaryRevenueRequest,
 *   !proto.admin_general.GetSummaryRevenueResponse>}
 */
const methodDescriptor_AdminGeneralHandler_GetSummaryRevenue = new grpc.web.MethodDescriptor(
  '/admin_general.AdminGeneralHandler/GetSummaryRevenue',
  grpc.web.MethodType.UNARY,
  proto.admin_general.GetSummaryRevenueRequest,
  proto.admin_general.GetSummaryRevenueResponse,
  /**
   * @param {!proto.admin_general.GetSummaryRevenueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_general.GetSummaryRevenueResponse.deserializeBinary
);


/**
 * @param {!proto.admin_general.GetSummaryRevenueRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_general.GetSummaryRevenueResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_general.GetSummaryRevenueResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_general.AdminGeneralHandlerClient.prototype.getSummaryRevenue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/GetSummaryRevenue',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_GetSummaryRevenue,
      callback);
};


/**
 * @param {!proto.admin_general.GetSummaryRevenueRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_general.GetSummaryRevenueResponse>}
 *     Promise that resolves to the response
 */
proto.admin_general.AdminGeneralHandlerPromiseClient.prototype.getSummaryRevenue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/GetSummaryRevenue',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_GetSummaryRevenue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_general.EditAboutUsRequest,
 *   !proto.admin_general.EditAboutUsResponse>}
 */
const methodDescriptor_AdminGeneralHandler_EditAboutUs = new grpc.web.MethodDescriptor(
  '/admin_general.AdminGeneralHandler/EditAboutUs',
  grpc.web.MethodType.UNARY,
  proto.admin_general.EditAboutUsRequest,
  proto.admin_general.EditAboutUsResponse,
  /**
   * @param {!proto.admin_general.EditAboutUsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_general.EditAboutUsResponse.deserializeBinary
);


/**
 * @param {!proto.admin_general.EditAboutUsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_general.EditAboutUsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_general.EditAboutUsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_general.AdminGeneralHandlerClient.prototype.editAboutUs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/EditAboutUs',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_EditAboutUs,
      callback);
};


/**
 * @param {!proto.admin_general.EditAboutUsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_general.EditAboutUsResponse>}
 *     Promise that resolves to the response
 */
proto.admin_general.AdminGeneralHandlerPromiseClient.prototype.editAboutUs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/EditAboutUs',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_EditAboutUs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_general.EditTermsAndConditionsRequest,
 *   !proto.admin_general.EditTermsAndConditionsResponse>}
 */
const methodDescriptor_AdminGeneralHandler_EditTermsAndConditions = new grpc.web.MethodDescriptor(
  '/admin_general.AdminGeneralHandler/EditTermsAndConditions',
  grpc.web.MethodType.UNARY,
  proto.admin_general.EditTermsAndConditionsRequest,
  proto.admin_general.EditTermsAndConditionsResponse,
  /**
   * @param {!proto.admin_general.EditTermsAndConditionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_general.EditTermsAndConditionsResponse.deserializeBinary
);


/**
 * @param {!proto.admin_general.EditTermsAndConditionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_general.EditTermsAndConditionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_general.EditTermsAndConditionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_general.AdminGeneralHandlerClient.prototype.editTermsAndConditions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/EditTermsAndConditions',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_EditTermsAndConditions,
      callback);
};


/**
 * @param {!proto.admin_general.EditTermsAndConditionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_general.EditTermsAndConditionsResponse>}
 *     Promise that resolves to the response
 */
proto.admin_general.AdminGeneralHandlerPromiseClient.prototype.editTermsAndConditions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/EditTermsAndConditions',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_EditTermsAndConditions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_general.DeleteClientRequest,
 *   !proto.admin_general.DeleteClientResponse>}
 */
const methodDescriptor_AdminGeneralHandler_DeleteClient = new grpc.web.MethodDescriptor(
  '/admin_general.AdminGeneralHandler/DeleteClient',
  grpc.web.MethodType.UNARY,
  proto.admin_general.DeleteClientRequest,
  proto.admin_general.DeleteClientResponse,
  /**
   * @param {!proto.admin_general.DeleteClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_general.DeleteClientResponse.deserializeBinary
);


/**
 * @param {!proto.admin_general.DeleteClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_general.DeleteClientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_general.DeleteClientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_general.AdminGeneralHandlerClient.prototype.deleteClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/DeleteClient',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_DeleteClient,
      callback);
};


/**
 * @param {!proto.admin_general.DeleteClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_general.DeleteClientResponse>}
 *     Promise that resolves to the response
 */
proto.admin_general.AdminGeneralHandlerPromiseClient.prototype.deleteClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/DeleteClient',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_DeleteClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_general.GetInvitationTextRequest,
 *   !proto.admin_general.GetInvitationTextResponse>}
 */
const methodDescriptor_AdminGeneralHandler_GetInvitationText = new grpc.web.MethodDescriptor(
  '/admin_general.AdminGeneralHandler/GetInvitationText',
  grpc.web.MethodType.UNARY,
  proto.admin_general.GetInvitationTextRequest,
  proto.admin_general.GetInvitationTextResponse,
  /**
   * @param {!proto.admin_general.GetInvitationTextRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_general.GetInvitationTextResponse.deserializeBinary
);


/**
 * @param {!proto.admin_general.GetInvitationTextRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_general.GetInvitationTextResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_general.GetInvitationTextResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_general.AdminGeneralHandlerClient.prototype.getInvitationText =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/GetInvitationText',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_GetInvitationText,
      callback);
};


/**
 * @param {!proto.admin_general.GetInvitationTextRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_general.GetInvitationTextResponse>}
 *     Promise that resolves to the response
 */
proto.admin_general.AdminGeneralHandlerPromiseClient.prototype.getInvitationText =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/GetInvitationText',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_GetInvitationText);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_general.UpdateInvitationTextRequest,
 *   !proto.admin_general.UpdateInvitationTextResponse>}
 */
const methodDescriptor_AdminGeneralHandler_UpdateInvitationText = new grpc.web.MethodDescriptor(
  '/admin_general.AdminGeneralHandler/UpdateInvitationText',
  grpc.web.MethodType.UNARY,
  proto.admin_general.UpdateInvitationTextRequest,
  proto.admin_general.UpdateInvitationTextResponse,
  /**
   * @param {!proto.admin_general.UpdateInvitationTextRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_general.UpdateInvitationTextResponse.deserializeBinary
);


/**
 * @param {!proto.admin_general.UpdateInvitationTextRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_general.UpdateInvitationTextResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_general.UpdateInvitationTextResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_general.AdminGeneralHandlerClient.prototype.updateInvitationText =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/UpdateInvitationText',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_UpdateInvitationText,
      callback);
};


/**
 * @param {!proto.admin_general.UpdateInvitationTextRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_general.UpdateInvitationTextResponse>}
 *     Promise that resolves to the response
 */
proto.admin_general.AdminGeneralHandlerPromiseClient.prototype.updateInvitationText =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/UpdateInvitationText',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_UpdateInvitationText);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admin_general.SendCustomNotificationRequest,
 *   !proto.admin_general.SendCustomNotificationResponse>}
 */
const methodDescriptor_AdminGeneralHandler_SendCustomNotification = new grpc.web.MethodDescriptor(
  '/admin_general.AdminGeneralHandler/SendCustomNotification',
  grpc.web.MethodType.UNARY,
  proto.admin_general.SendCustomNotificationRequest,
  proto.admin_general.SendCustomNotificationResponse,
  /**
   * @param {!proto.admin_general.SendCustomNotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admin_general.SendCustomNotificationResponse.deserializeBinary
);


/**
 * @param {!proto.admin_general.SendCustomNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admin_general.SendCustomNotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admin_general.SendCustomNotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admin_general.AdminGeneralHandlerClient.prototype.sendCustomNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/SendCustomNotification',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_SendCustomNotification,
      callback);
};


/**
 * @param {!proto.admin_general.SendCustomNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admin_general.SendCustomNotificationResponse>}
 *     Promise that resolves to the response
 */
proto.admin_general.AdminGeneralHandlerPromiseClient.prototype.sendCustomNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admin_general.AdminGeneralHandler/SendCustomNotification',
      request,
      metadata || {},
      methodDescriptor_AdminGeneralHandler_SendCustomNotification);
};


module.exports = proto.admin_general;

