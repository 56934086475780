import { useContext, useEffect, useRef, useState, type FC } from "react";
import ClientMid from "../../middlewares/ClientMid";
import { GetProfileResponseData } from "../../grpc/client/client_general_pb";
import { GetUserPackageStatusResponseData } from "../../grpc/client/client_user_package_pb";
import { useParams } from "react-router-dom";
import { clientOrder } from "../../App";
import {
  GetOrderDetailRequest,
  GetOrderDetailResponseData,
  GetOrderDiscussionRequest,
  GetOrderDiscussionResponseData,
  GiveOrderFeedbackRequest,
  SendDiscussionRequest,
} from "../../grpc/client/client_order_pb";
import { getToken, initials, money, statusLabel } from "../../utils/helper";
import { errorToast, successToast } from "../../utils/helper-ui";
import Back from "../../components/back";
import { Avatar, Divider } from "rsuite";
import { OrderContext } from "../../objects/order_context";
import moment from "moment";
import { HiArrowDown, HiPaperClip } from "react-icons/hi2";
import { PiSmiley, PiTriangleFill } from "react-icons/pi";
import "swiper/css";
import "swiper/css/navigation";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import StarRatings from "react-star-ratings";
import { LoadingContext } from "../../objects/loading_context";
import { ORDER_DONE, ORDER_PROCESSING } from "../../utils/contants";
import CommentBubble from "../../components/comment_bubble";
import { IoMdSend } from "react-icons/io";
import ChatBubble from "../../components/ChatBubble";
import ChatBox from "../../components/ChatBox";

interface OrderDetailProps {}

const OrderDetail: FC<OrderDetailProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const [userPackage, setUserPackage] =
    useState<GetUserPackageStatusResponseData.AsObject>();
  const { orderId } = useParams();
  const [mounted, setMounted] = useState(false);
  const [data, setData] = useState<
    GetOrderDetailResponseData.AsObject | null | undefined
  >(null);
  const { orderCtx, setOrderCtx } = useContext(OrderContext);
  const [activeReport, setActiveReport] = useState(false);
  const [activeConversation, setActiveConversation] = useState(false);
  const [conversations, setConversations] = useState<
    GetOrderDiscussionResponseData.AsObject[]
  >([]);
  const [rating, setRating] = useState(2.5);
  const [response, setResponse] = useState("");
  const fileRef = useRef<HTMLInputElement>(null);
  const [modalEmoji, setModalEmoji] = useState(false);
  const [msg, setMsg] = useState("");
  const [msgClients, setMsgClients] = useState<
    GetOrderDiscussionResponseData.AsObject[]
  >([]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getDetail();
  }, [mounted]);

  useEffect(() => {
    if (!data) return;
    // if (data)
  }, [data]);

  const sendFeedback = () => {
    setIsLoading(true);
    let req = new GiveOrderFeedbackRequest();
    req.setRating(rating * 2);
    req.setComment(response);
    req.setId(orderId!);
    clientOrder.giveOrderFeedback(req, getToken(), (err, resp) => {
      if (err) {
        errorToast(err.message);
        setIsLoading(false);
        return;
      }
      getDetail();
      setIsLoading(false);
      successToast("Feedback anda berhasil dikirim");
      // console.log(resp.getData()?.toObject())
    });
  };

  const getDetail = () => {
    let req = new GetOrderDetailRequest();
    req.setId(orderId!);
    clientOrder.getOrderDetail(req, getToken(), (err, resp) => {
      if (err) {
        errorToast(err.message);
        return;
      }
      setData(resp.getData()?.toObject());
      getConversation();
      // console.log(resp.getData()?.toObject())
    });
  };

  useEffect(() => {
    // console.log(conversations);
  }, [conversations]);

  useEffect(() => {
    // console.log(data)
    setResponse(data?.feedbackComment ?? "");
    setRating((data?.feedbackRating ?? 0) / 2);
    getDiscussions()
  }, [data]);

  const sendMessage = async (message: string, filePath?: string | null) => {
    setIsLoading(true)
    let req = new SendDiscussionRequest();
    req.setOrderId(orderId!);
    req.setComment(message);
    req.setAttachmentPath(filePath ?? "");
    req.setAttachmentPathType(filePath ? "firebase" : "");
    // if (activeDiscussion == "client") {
    //     if (msgClients.length) {
    //         let last = msgClients[msgClients.length - 1]
    //         req.setParentOrderDiscussionId(last.id)
    //     }

    // }

    clientOrder.sendDiscussion(req, getToken(), (err, resp) => {
        setIsLoading(false)
      if (err) {
        errorToast(err.message);
        return;
      }
      setMsg("");
      getDiscussions();
    });
  };

  const getDiscussions = async () => {
    try {
      setIsLoading(false);
      let toClient = await getConversation();

      toClient.reverse();

      setMsgClients(toClient);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderDiscussions = (data: GetOrderDiscussionResponseData.AsObject[]) =>
    data.map((e) => (
      <div className="w-full" key={e.id}>
        <ChatBubble profile={profile} data={e} />
        {e.repliesList.map((r) => (
          <div key={r.id}>
            <ChatBubble profile={profile} data={r} />
          </div>
        ))}
      </div>
    ));

  const getConversation = async (): Promise<
    GetOrderDiscussionResponseData.AsObject[]
  > => {
    return await new Promise((resolve, reject) => {
      let req = new GetOrderDiscussionRequest();
      req.setOrderId(orderId!);
      clientOrder.getOrderDiscussion(req, getToken(), (err, resp) => {
        if (err) {
          reject(err.message);
          return;
        }
        resolve(resp.getDataList().map((e) => e.toObject()));
      });
    });
  };

  return (
    <ClientMid
      onLoadProfile={setProfile}
      onLoad={setUserPackage}
      noPadding
      header={
        <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white">
          <Back />
          <h6>Detail Pesanan</h6>
          <div className="p-2 "></div>
        </div>
      }
    >
      <div className="flex flex-col h-full">
        <div className="flex-1  overflow-y-auto">
          <div className="bg-white px-4 py-8 mb-2">
            <div className="flex border-b pb-4">
              <img
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/assets/logo.svg";
                }}
                src={data?.thumbnailUrl}
                alt=""
                className=" object-cover w-10 h-10 rounded-lg bg-gray-50 mr-2"
              />
              <div className="flex flex-col" onClick={() => {}}>
                <h6>{data?.title}</h6>
                <p className=" text-nowrap text-ellipsis cursor-pointer">
                  {data?.url}
                </p>
              </div>
            </div>
            <div className=" flex justify-between py-4 items-center">
              <p className=" font-bold text-lg">Status</p>
              <p className=" text-sky-600">{statusLabel(orderCtx?.status)}</p>
            </div>
            <h5 className=" font-bold text-lg mb-4">Detail Pesanan</h5>
            <div className=" justify-between flex mb-2">
              <span>Komentar Positif</span>
              <span>{money(data?.positiveComment)}</span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>Komentar Negatif</span>
              <span>{money(data?.negativeComment)}</span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>Tag Teman</span>
              <span>{money(data?.tagFriend)}</span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>+ Bonus Like</span>
              <span>{money(data?.like)}</span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>+ Bonus Share</span>
              <span>{money(data?.share)}</span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>Catatan</span>
            </div>
            <div className=" border border-gray-150 px-12 py-4 rounded-xl text-center">
              {data?.remarks}
            </div>
          </div>

          {orderCtx?.status == ORDER_DONE && (
            <div className="bg-white px-4 py-4 mb-2">
              <div className=" justify-between flex mb-2">
                <span>Waktu Pemesanan</span>
                <span>
                  {moment.utc(data?.createdAt).format("DD/MM/YYYY HH:mm")}
                </span>
              </div>
              <div className=" justify-between flex">
                <span>Waktu Selesai</span>
                <span>
                  {moment.utc(data?.finishedAt).format("DD/MM/YYYY HH:mm")}
                </span>
              </div>
            </div>
          )}
          {orderCtx?.status == ORDER_DONE && (
            <div className="bg-white px-4 py-4 mb-2">
              <div className="justify-between flex items-center">
                <h5 className=" font-bold text-lg ">Bukti Laporan</h5>
                <PiTriangleFill
                  onClick={() => setActiveReport(!activeReport)}
                  className={`w-3 ${!activeReport && "rotate-180"}`}
                />
              </div>
              {activeReport && (
                <div>
                  <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className="px-8 py-4 justify-center flex flex-col items-center">
                        <div className="mb-4">
                          <span className=" py-1 px-2 bg-sky-200 rounded-lg text-2xs">
                            Sebelum
                          </span>
                        </div>
                        <img src={data?.initialImageUrl} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="px-8 py-4 justify-center flex flex-col items-center">
                        <div className="mb-4">
                          <span className=" py-1 px-2 bg-sky-200 rounded-lg text-2xs">
                            Sesudah
                          </span>
                        </div>
                        <img src={data?.reportImageUrl} alt="" />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                  <div className=" border-t border-b py-8 px-4 justify-center flex flex-col">
                    <span className="text-center text-base ">
                      Bagaimana Kinerja Kami ?
                    </span>
                    <div className="px-10">
                      <StarRatings
                        rating={rating}
                        starRatedColor="orange"
                        changeRating={setRating}
                        numberOfStars={5}
                        name="rating"
                        starDimension="40px"
                        starSpacing="5px"
                        starHoverColor="#f54100"
                      />
                    </div>
                    <textarea
                      value={response}
                      onChange={(val) => setResponse(val.target.value)}
                      placeholder="tuliskan respon anda"
                      rows={5}
                      className="p-4 rounded-lg border-gray-200 mt-4"
                    />
                    <button className="btn mt-4" onClick={sendFeedback}>
                      Kirim
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="bg-white px-4 py-4 mb-2 mt-2 min-h-[300px]">
            <div className="py-4 w-full flex flex-col">
              {renderDiscussions(msgClients)}
            </div>
          </div>
        </div>
        {orderCtx?.status == ORDER_PROCESSING && (
          <ChatBox sendMessage={sendMessage} />
        )}
        {orderCtx?.status == ORDER_DONE && (
          <div className="bg-white px-4 py-4  z-50">
            <div className="justify-between flex items-center">
              <h5 className=" font-bold text-lg ">Histori Percakapan</h5>
              <PiTriangleFill
                onClick={() => setActiveConversation(!activeConversation)}
                className={`w-3 ${!activeConversation && "rotate-180"}`}
              />
            </div>
            {activeConversation && (
              <div className="py-4">
                {conversations.map((e) => (
                  <div key={e.id}>
                    <div className="flex items-end mb-4">
                      <CommentBubble profile={profile} data={e} />
                    </div>
                    {e.repliesList.map((r) => (
                      <div key={r.id}>
                        <CommentBubble profile={profile} data={r} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </ClientMid>
  );
};
export default OrderDetail;
