import type { FC } from 'react';
import { GetUserPackageStatusResponseData } from '../grpc/client/client_user_package_pb';
import { ACTIVE, FREE } from '../utils/contants';

interface HeroPackageProps {
    userPackage?: GetUserPackageStatusResponseData.AsObject
}

const HeroPackage: FC<HeroPackageProps> = ({userPackage}) => {
        return (<div className='flex flex-col rounded-xl mb-4' style={{ backgroundImage: "url(/assets/bg_client_home.png)", backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover' }}>
        <div className='flex px-4 mt-4'>
            {(userPackage?.userPackageStatus == ACTIVE || userPackage?.userPackageStatus == FREE) &&
                <span className=' py-1 px-2 bg-white rounded-lg text-2xs'>
                    {userPackage?.packageName}
                </span>
            }
        </div>

        <div className='w-full h-48  px-4 py-2 grid grid-cols-2 gap-4 '>
            <div className='border h-28 border-white rounded-lg p-4 text-white text-center text-sm flex flex-col'>
                <span>Kuota Hari ini</span>
                <span className='font-bold text-3xl'>{userPackage?.todayRemainingComment}</span>
                <span>Komentar</span>
            </div>
            <div className='border h-28 border-white rounded-lg p-4 text-white text-center text-sm flex flex-col'>
                <span>Waktu Tersisa</span>
                <span className='font-bold text-3xl'>{userPackage?.remainingDurationDay}</span>
                <span>Hari</span>
            </div>
        </div>
    </div>);
}
export default HeroPackage;