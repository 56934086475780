import { useContext, useEffect, useState, type FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommentatorMid from "../../middlewares/CommentatorMid";
import { GetProfileResponseData } from "../../grpc/client/client_general_pb";
import { Avatar, Sidebar } from "rsuite";
import { getToken, initials, money } from "../../utils/helper";
import { RiMenu3Fill } from "react-icons/ri";
import { SidebarContext } from "../../objects/SidebarContext";
import {
  commentatorAccountClient,
  commentatorClientClient,
  commentatorOrderClient,
} from "../../App";
import {
  GetCommentatorAccountClientRequest,
  GetDetailCommentatorAccountRequest,
  GetDetailCommentatorAccountResponseData,
} from "../../grpc/commentator/commentator_account_pb";
import { errorToast } from "../../utils/helper-ui";
import { Button } from "flowbite-react";
import { HiOutlineChevronRight } from "react-icons/hi";
import { GetClientListRequest } from "../../grpc/commentator/commentator_client_pb";
import { LoadingContext } from "../../objects/loading_context";
import {
  GetOrderListRequest,
  GetOrderListResponseData,
} from "../../grpc/commentator/commentator_order_pb";
import {
  DONE,
  IN_PROGRESS,
  ORDER_COMMENTATOR,
  ORDER_PROCESSING,
  PENDING_COMMENTATOR,
  TO_DO,
} from "../../utils/contants";
import CommentatorOrderList from "../../components/commentator/CommentatorOrderList";

interface CommentatorAccountProps {}

const CommentatorAccount: FC<CommentatorAccountProps> = ({}) => {
  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const { isOpen, setOpen } = useContext(SidebarContext);
  const [account, setAccount] =
    useState<GetDetailCommentatorAccountResponseData.AsObject | null>(null);
  const nav = useNavigate();
  const { accountId } = useParams();
  const [totalPage, setTotalPage] = useState(0);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [orders, setOrders] = useState<GetOrderListResponseData.AsObject[]>([]);
  const [orderStatusMode, setOrderStatusMode] = useState(TO_DO);

  useEffect(() => {
    getAccountDetail();
    getClient();
    
  }, [accountId]);

  const getClient = () => {
    if (!accountId) return;
    setIsLoading(true);
    let req = new GetClientListRequest();
    req.setCommentatorAccountId(accountId);
    req.setPage(1);
    req.setLimit(10);
    commentatorClientClient.getClientList(req, getToken(), (err, resp) => {
      setIsLoading(false);
      if (err) {
        errorToast(err.message);
        return;
      }
      // console.log(resp?.getPagination()?.toObject())
      setTotalPage(resp?.getPagination()?.toObject()?.totalRecords ?? 0);
      //   setProfile(resp);
    });
  };
  const getAccountDetail = () => {
    if (!accountId) return;
    setIsLoading(true);
    let req = new GetDetailCommentatorAccountRequest();
    req.setId(accountId!);
    commentatorAccountClient.getDetailCommentatorAccount(
      req,
      getToken(),
      (err, resp) => {
        setIsLoading(false);
        if (err) {
          errorToast(err.message);
          return;
        }
        setAccount(resp.getData()?.toObject() ?? null);
        //   setProfile(resp);
      }
    );
  };

  useEffect(() => {
    // console.log("account", account);
  }, [account]);
  return (
    <CommentatorMid noPadding hideMenu hideHeader onLoadProfile={setProfile}>
      <div
        className="flex flex-col h-[300px] relative"
        style={{
          backgroundImage: "url(/assets/bg-client.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <RiMenu3Fill
          size={16}
          className="text-white absolute top-4 left-4"
          onClick={() => setOpen(!isOpen)}
        />
      </div>
      <div className="bg-white p-4 -mt-[160px] min-h-[200px]  rounded-2xl z-10 relative">
        <div className="m-auto flex justify-center -mt-[50px]">
          <Avatar
            className="ml-auto mr-auto"
            size={"xl"}
            bordered
            circle
            src={account?.profilePictureUrl}
            alt={
              account?.profilePictureUrl
                ? account?.name
                : initials(account?.name)
            }
          />
        </div>
        <p className="text-center mt-4 text-lg font-bold">{account?.name}</p>
        <div className="flex justify-center">
          <button
            onClick={() => {
              nav(`/commentator/account/${account?.id}/edit`);
            }}
            className="mt-4 rounded-2xl bg-gray-200 hover:bg-gray-400 hover:text-white py-2 px-4 cursor-pointer"
          >
            Edit Akun
          </button>
        </div>
        <div className="py-4 flex justify-between ">
          <p>Client</p>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              nav(`/commentator/account/${account?.id}/client`);
            }}
          >
            <p className="px-2">{money(totalPage)}</p>
            <HiOutlineChevronRight />
          </div>
        </div>
      </div>
      <div className="bg-white mt-2">
        <div className="flex justify-between px-4 py-4">
          <h5 className="mb-2">Pesanan Hari ini</h5>
        </div>
        <div className=" w-full">
          <div className="tabmenu w-full">
            <div
              onClick={() => setOrderStatusMode(TO_DO)}
              className={`${orderStatusMode == TO_DO && "active"}`}
            >
              Diproses
            </div>
            <div
              onClick={() => setOrderStatusMode(IN_PROGRESS)}
              className={`${orderStatusMode == IN_PROGRESS && "active"}`}
            >
              Dikerjakan
            </div>
            <div
              onClick={() => setOrderStatusMode(DONE)}
              className={`${orderStatusMode == DONE && "active"}`}
            >
              Selesai
            </div>
          </div>
        </div>
        {accountId && <CommentatorOrderList mode={orderStatusMode} accountId={accountId} />}
      </div>
    </CommentatorMid>
  );
};
export default CommentatorAccount;
