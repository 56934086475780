import * as CryptoJS from 'crypto-js';
import {
    PROFILE, TOKEN, USER_PACKAGE, ORDER_REVIEWED, ORDER_REJECTED, ORDER_PROCESSING, ORDER_DONE,
} from './contants';

import moment from 'moment';

export const asyncLocalStorage = {
    async setItem(key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, encrypt(value));
        });
    },
    async getItem(key) {
        return Promise.resolve().then(function () {
            let data = localStorage.getItem(key) || "";
            return decrypt(data) || null;
        });
    },
    async removeItem(key) {
        return Promise.resolve().then(function () {
            return localStorage.removeItem(key);
        });
    }
};

export async function setStorageProfile(profile) {
    await asyncLocalStorage.setItem(PROFILE, JSON.stringify(profile))
}
export async function getStorageProfile() {
    let res = await asyncLocalStorage.getItem(PROFILE)
    // console.log(res)
    if (res) return JSON.parse(res)
    return null
}
export async function setStorageUserPackage(userPackage) {
    await asyncLocalStorage.setItem(USER_PACKAGE, JSON.stringify(userPackage))
}

export async function getStorageUserPackage() {
    let res = await asyncLocalStorage.getItem(USER_PACKAGE)
    // console.log("res", res)
    if (res) return JSON.parse(res)
    return null
}

export function getToken() {
    let data = localStorage.getItem(TOKEN) || "";
    var token = decrypt(data) || null;
    return {
        "authorization": "Bearer " + token
    }
}

export function getNullToken() {

    return {

    }
}

function encrypt(txt) {
    return CryptoJS.AES.encrypt(txt, process.env.REACT_APP_SECRET_KEY).toString();
}

function decrypt(txtToDecrypt) {
    return CryptoJS.AES.decrypt(txtToDecrypt, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
}

// export function numberToDuration(num) {
//     let hours = Math.floor(num / 60)
//     let minutes = num % 60
//     return (hours ? `${money(hours)} Jam` : '') + ' ' + (minutes ? `${money(minutes)} Menit` : '');
// }


export const money = (val, friction = 2) => {
    if (!val) return 0
    return val.toLocaleString('id-ID', { maximumFractionDigits: friction });
}

export const printLog = (log) => {
    if (process.env.NODE_ENV == "development") {
        console.log(log)
    }
}



export function randomStr(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function uuidv4() {
    return crypto.randomUUID();
}


export function initials(name) {
    if (!name) return ""
    return name.split(" ").map((n) => `${n[0]}`.toUpperCase()).join("");
}

export function extractDomain(url) {
    try {
        const urlObj = new URL(url);
        return urlObj.hostname;
    } catch (error) {
        console.error('Invalid URL:', error);
        return null;
    }
}

export function statusLabel(status) {
    switch (status) {
        case ORDER_REVIEWED:
            return "Menunggu"

        case ORDER_REJECTED:
            return "Ditolak"

        case ORDER_PROCESSING:
            return "Diproses"

        case ORDER_DONE:
            return "Selesai"
        default:
            return ""
    }
}


export function numberToDuration(num) {
    let days = Math.floor(num / 60 / 24)
    // let hours = Math.floor(num / 60)
    let hours = Math.floor((num / 60) % 24)
    // console.log("MOD HOUR", (num / 60) % 24)
    let minutes = num % 60
    return (days ? `${money(days)} Hari ` : '') + (hours ? `${pad(hours, 2)}jam ` : '00 jam ') + (minutes > 0 ? `${pad(minutes, 2)}m` : '00 m');
}

function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

export function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function sepNumber(val) {
    var foo = val.split("-").join("");
    if (foo.length > 0) {
        foo = foo.match(new RegExp('.{1,4}', 'g')).join("-");
    }
    return foo
}

export function groupDataLog(data) {
    const today = moment().format('YYYY-MM-DD');
    const yes = moment().subtract(1, 'day').format('YYYY-MM-DD');

    const result = {
        "today": [],
        "yesterday": [],
        "others": [],
    };

    data.forEach((d) => {
        const date = moment.utc(d.createdAt).format('YYYY-MM-DD');
        if (date === today) {
            result["today"].push(d);
        } else if (date === yes) {
            result["yesterday"].push(d);
        } else {
            if (!result["others"][date]) {
                result["others"][date] = [];
            }
            result["others"][date].push(d);
        }
    });

    return result;
}
