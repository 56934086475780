import { useContext, useEffect, useState, type FC } from "react";
import { GetProfileResponseData } from "../../grpc/service/general_pb";
import CommentatorMid from "../../middlewares/CommentatorMid";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingContext } from "../../objects/loading_context";
import {
  GetClientListRequest,
  GetClientListResponseData,
  Pagination,
} from "../../grpc/commentator/commentator_client_pb";
import { commentatorClientClient } from "../../App";
import { getToken } from "../../utils/helper";
import { errorToast } from "../../utils/helper-ui";
import { USER_SORT_CREATED_AT, USER_SORT_NAME } from "../../utils/contants";
import { Drawer } from "rsuite";
import { HiMagnifyingGlass, HiOutlineFunnel, HiXMark } from "react-icons/hi2";
import NoData from "../../components/no_data";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import Moment from "react-moment";
import Back from "../../components/back";

interface CommentatorClientProps {}

const CommentatorClient: FC<CommentatorClientProps> = ({}) => {
  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const { accountId } = useParams();
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [hasNext, setHasNext] = useState(false);
  const [pagination, setPagination] = useState<Pagination.AsObject | null>(
    null
  );
  const [data, setData] = useState<GetClientListResponseData.AsObject[]>([]);
  const [sortBy, setSortBy] = useState(USER_SORT_CREATED_AT);
  const [sort, setSort] = useState("DESC");
  const [modalFilter, setModalFilter] = useState(false);
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const nav = useNavigate();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
  }, [mounted]);

  useEffect(() => {
    if (mounted) getData();
  }, [mounted, page, limit, sortBy, sort, accountId]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let resp: GetClientListResponseData.AsObject[] = await new Promise(
        (resolve, reject) => {
          let req = new GetClientListRequest();
          req.setCommentatorAccountId(accountId!);
          req.setPage(page);
          req.setLimit(limit);
          req.setSortBy(sortBy);
          req.setSort(sort);

          commentatorClientClient.getClientList(
            req,
            getToken(),
            (err, resp) => {
              if (err) {
                reject(err.message);
                return;
              }
              setPagination(resp.getPagination()?.toObject() ?? null);
              resolve(resp.getDataList().map((e) => e.toObject()));
            }
          );
        }
      );
      setData([...data, ...resp]);
      // setOrders([...orders, ...resp])
      // console.log(resp)
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CommentatorMid noPadding hideMenu header={
        <div className="flex justify-between items-center p-4">
          <Back onClick={() => nav(-1)} />
          <h5>Client</h5>
          <div></div>
        </div>
      } onLoadProfile={setProfile}>
      <div className="p-4">
        <div className="flex">
          <div className="relative flex-1">
            <input className="search-control" placeholder="Search ...." />
            <HiMagnifyingGlass className="absolute top-2.5 left-4 text-lg text-gray-400" />
          </div>
          <div
            className=" border-gray-300 rounded-lg p-2.5 ml-2  border-2"
            onClick={() => setModalFilter(true)}
          >
            <HiOutlineFunnel />
          </div>
        </div>
        <div>
          {data.length == 0 ? (
            <NoData />
          ) : (
            <div className="mt-4">
              <div className="flex">
                <div className="w-1/2 font-bold">Nama</div>
                <div className="w-1/2 font-bold">Projek</div>
                <div></div>
              </div>
              <ul>
                {data.map((e) => (
                  <li className="" key={e.id}>
                    <div className="flex p-4  bg-sky-50 mx-1 my-2 rounded-lg cursor-pointer">
                      <div className="w-1/2">{e.name}</div>
                      <div
                        className="w-1/2 flex justify-between"
                        onClick={() => {
                          if (expandedIds.includes(e.id)) {
                            setExpandedIds([
                              ...expandedIds.filter((id) => !e.id),
                            ]);
                          } else {
                            setExpandedIds([...expandedIds, e.id]);
                          }
                        }}
                      >
                        {e.userPackageCommentPerDay}/Hari
                        {expandedIds.includes(e.id) ? (
                          <RiArrowUpSFill
                            size={24}
                            className=" text-sky-600 "
                          />
                        ) : (
                          <RiArrowDownSFill
                            size={24}
                            className=" text-sky-600 "
                          />
                        )}
                      </div>
                    </div>
                    {expandedIds.includes(e.id) && (
                      <div className="flex flex-col p-4  bg-gray-50 mx-1 my-2 rounded-lg cursor-pointer">
                        <div className="flex mb-2">
                          <div className="w-1/2">
                            <p className="font-semibold">ID</p>
                          </div>
                          <div className="w-1/2 text-right">
                            <p>{e.id}</p>
                          </div>
                        </div>
                        <div className="flex mb-2">
                          <div className="w-1/2">
                            <p className="font-semibold">Tanggal Berakhir</p>
                          </div>
                          <div className="w-1/2 text-right">
                            <Moment format="DD/MM/YYYY">
                              {e.userPackageExpiryDate}
                            </Moment>
                          </div>
                        </div>
                        <div className="flex mb-2">
                          {/* <div className='w-1/2'>
                                        <p className='font-semibold'>Komentator</p>
                                    </div>
                                    <div className='w-1/2 text-right' onClick={() => {
                                        if (onClickCommentator) onClickCommentator(e)
                                    }}>
                                        <p className='font-semibold text-sky-600'>Lihat</p>
                                    </div> */}
                        </div>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className=" justify-center items-start flex">
          {(pagination?.totalPages ?? 0) > 0 &&
            (pagination?.next ?? 0) > page && (
              <button
                className="btn-white w-48"
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                More
              </button>
            )}
        </div>
        <Drawer
          className="drawer-rounded"
          size={"md"}
          closeButton={null}
          placement={"bottom"}
          open={modalFilter}
          onClose={() => setModalFilter(false)}
        >
          <Drawer.Title>
            <div className="flex p-4  justify-between items-center">
              <div className="flex   items-center">
                <p> Filter</p>
              </div>
              <HiXMark onClick={() => setModalFilter(false)} />
            </div>
          </Drawer.Title>
          <Drawer.Body style={{ padding: 20 }}>
            <div className="mb-4">
              <p className="font-semibold mb-2">Urutkan</p>
              <div className=" flex">
                <div
                  onClick={() => {
                    setData([]);
                    setSortBy(USER_SORT_NAME);
                    setSort("ASC");
                  }}
                  className={`border rounded-full py-2 px-4 min-w-[100px] text-center mr-2 ${
                    sortBy == USER_SORT_NAME &&
                    sort == "ASC" &&
                    "bg-sky-400 text-white"
                  }`}
                >
                  {" "}
                  A - Z
                </div>
                <div
                  onClick={() => {
                    setData([]);
                    setSortBy(USER_SORT_NAME);
                    setSort("DESC");
                  }}
                  className={`border rounded-full py-2 px-4 min-w-[100px] text-center mr-2 ${
                    sortBy == USER_SORT_NAME &&
                    sort == "DESC" &&
                    "bg-sky-400 text-white"
                  }`}
                >
                  {" "}
                  Z - A
                </div>
              </div>
            </div>
            <div className="mb-4">
              <p className="font-semibold mb-2">Waktu Pesan</p>
              <div className=" flex">
                <div
                  onClick={() => {
                    setData([]);
                    setSortBy(USER_SORT_CREATED_AT);
                    setSort("DESC");
                  }}
                  className={`border rounded-full py-2 px-4 min-w-[100px] text-center mr-2 ${
                    sortBy == USER_SORT_CREATED_AT &&
                    sort == "DESC" &&
                    "bg-sky-400 text-white"
                  }`}
                >
                  {" "}
                  Paling Baru
                </div>
                <div
                  onClick={() => {
                    setData([]);
                    setSortBy(USER_SORT_CREATED_AT);
                    setSort("ASC");
                  }}
                  className={`border rounded-full py-2 px-4 min-w-[100px] text-center mr-2 ${
                    sortBy == USER_SORT_CREATED_AT &&
                    sort == "ASC" &&
                    "bg-sky-400 text-white"
                  }`}
                >
                  {" "}
                  Paling Lama
                </div>
              </div>
            </div>
            {/* <div className='mb-4'>
                            <p className='font-semibold mb-2'>Payment</p>
                            <div className=' flex'>
                                <div onClick={() => {
                                    setData([])
                                    setSortBy(SORT_PAYMENT)
                                    setSort("DESC")
                                }} className={`border rounded-full py-2 px-4 min-w-[100px] text-center mr-2 ${sortBy == SORT_PAYMENT && sort == 'DESC' && 'bg-sky-400 text-white'}`}> Paling Besar</div>
                                <div onClick={() => {
                                    setData([])
                                    setSortBy(SORT_PAYMENT)
                                    setSort("ASC")
                                }} className={`border rounded-full py-2 px-4 min-w-[100px] text-center mr-2 ${sortBy == SORT_PAYMENT && sort == 'ASC' && 'bg-sky-400 text-white'}`}> Paling Kecil</div>
                            </div>
                        </div> */}
          </Drawer.Body>
        </Drawer>
      </div>
    </CommentatorMid>
  );
};
export default CommentatorClient;
