import { useEffect, type FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface JoinProps {}

const Join: FC<JoinProps> = ({}) => {
  const { token } = useParams();
    const nav = useNavigate();

  useEffect(() => {
        nav(`/register?ref=${token}`)    
    }, [token]);

  return <div className="flex justify-center items-center w-full h-full">
    <p>
    Loading ...

    </p>
  </div>;
};
export default Join;
