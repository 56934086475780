import { useContext, useEffect, useState, type FC } from 'react';
import { GetProfileResponseData } from '../../grpc/service/general_pb';
import CommentatorMid from '../../middlewares/CommentatorMid';
import { LoadingContext } from '../../objects/loading_context';
import { ORDER_AFFILIATOR, ORDER_COMMENTATOR, ORDER_DONE, ORDER_PROCESSING } from '../../utils/contants';
import { useParams, useSearchParams } from 'react-router-dom';
import CommentatorPackageList from '../../components/commentator/CommentatorPackageList';

interface CommentatorPackageProps { }

const CommentatorPackage: FC<CommentatorPackageProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [searchParams] = useSearchParams()

    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const [clientOrderActive, setClientOrderActive] = useState(ORDER_COMMENTATOR);

    useEffect(() => {
        if (searchParams.get('tab') == "commentator") {
            setClientOrderActive(ORDER_COMMENTATOR)
        }
    }, []);
    return (
        <CommentatorMid onLoadProfile={setProfile} noPadding>
            <div className=''>
                <div className="tabmenu bg-white border-b">
                    <div onClick={() => setClientOrderActive(ORDER_COMMENTATOR)} className={`${clientOrderActive == ORDER_COMMENTATOR && 'active'}`} >Tawaran</div>
                    <div onClick={() => setClientOrderActive(ORDER_PROCESSING)} className={`${clientOrderActive == ORDER_PROCESSING && 'active'}`} >Komentator</div>
                    <div onClick={() => setClientOrderActive(ORDER_DONE)} className={`${clientOrderActive == ORDER_DONE && 'active'}`} >Berhasil</div>
                </div>
                {clientOrderActive == ORDER_COMMENTATOR && <CommentatorPackageList mode={ORDER_COMMENTATOR} />}
                {clientOrderActive == ORDER_PROCESSING && <CommentatorPackageList mode={ORDER_PROCESSING} />}
                {clientOrderActive == ORDER_DONE && <CommentatorPackageList mode={ORDER_DONE} />}
            </div>
        </CommentatorMid>
    );
}
export default CommentatorPackage;