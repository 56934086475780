import { useContext, useEffect, useRef, useState, type FC } from "react";
import { LoadingContext } from "../../objects/loading_context";
import { GetProfileResponseData } from "../../grpc/client/client_general_pb";
import AffiliatorMid from "../../middlewares/AffiliatorMid";
import Back from "../../components/back";
import { affiliatorOrderClient, clientFile, generalClient } from "../../App";
import {
  GetDoneCommentatorRequest,
  GetDoneCommentatorResponseData,
  GetOrderDetailRequest,
  GetOrderDetailResponseData,
  GetOrderDiscussionRequest,
  GetOrderDiscussionResponseData,
  GetOrderSuspiciousActivityRequest,
  GetOrderSuspiciousActivityResponseData,
  GetOrderSuspiciousActivityResponseDataCommentator,
  SendDiscussionRequest,
  SetAsFinishedRequest,
  SetSuspiciousActivityAsDoneRequest,
} from "../../grpc/affiliator/affiliator_order_pb";
import { useNavigate, useParams } from "react-router-dom";
import {
  getToken,
  initials,
  money,
  numberToDuration,
} from "../../utils/helper";
import { errorToast, successToast } from "../../utils/helper-ui";
import moment from "moment";
import { PiSmiley, PiTriangleFill } from "react-icons/pi";
import {
  HiChevronDoubleDown,
  HiChevronDown,
  HiChevronUp,
  HiOutlineClock,
  HiPaperClip,
  HiXMark,
} from "react-icons/hi2";
import { IoMdSend } from "react-icons/io";
import ChatBubble from "../../components/ChatBubble";
import {
  AFFILIATOR_ADMIN,
  AFFILIATOR_CLIENT,
  AFFILIATOR_COMMENTATOR,
} from "../../utils/contants";
import { Avatar, Badge, Checkbox, Drawer } from "rsuite";
import EmojiPicker from "emoji-picker-react";
import Webcam from "react-webcam";
import React from "react";
import { FileBase64Request } from "../../grpc/service/file_pb";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import StarRatings from "react-star-ratings";
import CommentBubble from "../../components/comment_bubble";
import ChatBox from "../../components/ChatBox";
import { ReportOffenseRequest } from "../../grpc/service/general_pb";

interface AffiliatorOrderDetailProps {}

const AffiliatorOrderDetail: FC<AffiliatorOrderDetailProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { orderId } = useParams();
  const nav = useNavigate();
  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const [mounted, setMounted] = useState(false);
  const [data, setData] = useState<
    GetOrderDetailResponseData.AsObject | null | undefined
  >(null);
  const [msgClients, setMsgClients] = useState<
    GetOrderDiscussionResponseData.AsObject[]
  >([]);
  const [msgAffiliators, setMsgAffiliators] = useState<
    GetOrderDiscussionResponseData.AsObject[]
  >([]);
  const [msgCommentators, setMsgCommentators] = useState<
    GetOrderDiscussionResponseData.AsObject[]
  >([]);
  const [activeDiscussion, setActiveDiscussion] = useState("client");
  const [modalEmoji, setModalEmoji] = useState(false);
  const [msg, setMsg] = useState("");
  const fileRef = useRef<HTMLInputElement>(null);
  const [chatBoxEnabled, setChatBoxEnabled] = useState(false);
  const [tabMode, setTabMode] = useState("chat");
  const [suspiciousData, setSuspiciousData] =
    useState<GetOrderSuspiciousActivityResponseData.AsObject | null>(null);
  const [doneCommentators, setDoneCommentators] =
    useState<GetDoneCommentatorResponseData.AsObject | null>(null);
  const [modalSuspiciousOpen, setModalSuspiciousOpen] = useState(false);
  const [updateSuspiciousOpen, setUpdateSuspiciousOpen] = useState(false);
  const [selectedSuspiciousAccount, setSelectedSuspiciousAccount] =
    useState<GetOrderSuspiciousActivityResponseDataCommentator.AsObject | null>(
      null
    );
  const [selectedAccountIDs, setSelectedAccountIDs] = useState<string[]>([]);
  const [selectedDoneAccountIDs, setSelectedDoneAccountIDs] = useState<
    string[]
  >([]);
  const [modalReport, setModalReport] = useState(false);
  const [reportCommentDone, setReportCommentDone] = useState(false);
  const [reportLikeDone, setReportLikeDone] = useState(false);
  const [reportShareDone, setReportShareDone] = useState(false);
  const [imgUpload, setImgUpload] = useState("/assets/upload.png");
  const [cameraOn, setCameraOn] = useState(false);
  const fileRef2 = useRef<HTMLInputElement>(null);
  const webcamRef = useRef<Webcam>(null);
  const [isFinished, setIsFinished] = useState(false);
  const [activeReport, setActiveReport] = useState(false);
  const [activeConversation, setActiveConversation] = useState(false);
  const [rating, setRating] = useState(2.5);
  const [response, setResponse] = useState("");
  const [conversations, setConversations] = useState<
    GetOrderDiscussionResponseData.AsObject[]
  >([]);

  const [modalWarningOpen, setModalWarningOpen] = useState(false);
  const capture = React.useCallback(() => {
    if (!webcamRef) return;
    const imageSrc = webcamRef?.current?.getScreenshot();

    setImgUpload(imageSrc ?? "/assets/upload.png");
    setCameraOn(false);
  }, [webcamRef]);

  const videoConstraints = {};

  useEffect(() => {
    if (data) {
      setIsFinished(data?.finishedAt != null && data?.finishedAt != "");
      setResponse(data?.feedbackComment ?? "");
      setRating((data?.feedbackRating ?? 0) / 2);
      getHistoryConversation();
    }
  }, [data]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getDetail();
    getDiscussions();
    getSuspicious();
    getDone();
  }, [mounted]);

  useEffect(() => {
    setChatBoxEnabled(false);
    if (activeDiscussion == "client" && !isFinished) {
      setChatBoxEnabled(true);
    }
    if (activeDiscussion == "commentator" && !isFinished) {
      setChatBoxEnabled(true);
    }
    if (activeDiscussion == "admin" && !isFinished) {
      setChatBoxEnabled(true);
    }
  }, [activeDiscussion]);

  const getDetail = async () => {
    try {
      setIsLoading(false);

      let resp: GetOrderDetailResponseData.AsObject = await new Promise(
        (resolve, reject) => {
          let req = new GetOrderDetailRequest();
          req.setId(orderId!);
          affiliatorOrderClient.getOrderDetail(req, getToken(), (err, resp) => {
            if (err) {
              reject(err.message);
              return;
            }
            resolve(resp.getData()?.toObject()!);
          });
        }
      );
      setData(resp);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const sendMessage = async (message: string, filePath?: string | null) => {
    setIsLoading(true);
    let req = new SendDiscussionRequest();
    req.setOrderId(orderId!);
    req.setComment(message);
    req.setAttachmentPath(filePath ?? "");
    req.setAttachmentPathType(filePath ? "firebase" : "");
    if (activeDiscussion == "client") {
      if (msgClients.length) {
        let last = msgClients[msgClients.length - 1];
        req.setParentOrderDiscussionId(last.id);
      }
    }
    switch (activeDiscussion) {
      case "client":
        req.setType(AFFILIATOR_CLIENT);
        break;
      case "admin":
        req.setType(AFFILIATOR_ADMIN);
        break;
      case "commentator":
        req.setType(AFFILIATOR_COMMENTATOR);
        break;
      default:
        break;
    }
    affiliatorOrderClient.sendDiscussion(req, getToken(), (err, resp) => {
      setIsLoading(false);
      if (err) {
        errorToast(err.message);
        return;
      }
      setMsg("");
      getDiscussions();
    });
  };

  const getHistoryConversation = () => {
    let req = new GetOrderDiscussionRequest();
    req.setOrderId(orderId!);
    req.setType(AFFILIATOR_ADMIN);
    affiliatorOrderClient.getOrderDiscussion(req, getToken(), (err, resp) => {
      if (err) {
        errorToast(err.message);
        return;
      }
      setConversations(resp.getDataList().map((e) => e.toObject()));
    });
  };

  const getConversation = async (
    type: string
  ): Promise<GetOrderDiscussionResponseData.AsObject[]> => {
    return await new Promise((resolve, reject) => {
      let req = new GetOrderDiscussionRequest();
      req.setOrderId(orderId!);
      req.setType(type);
      affiliatorOrderClient.getOrderDiscussion(req, getToken(), (err, resp) => {
        if (err) {
          reject(err.message);
          return;
        }
        resolve(resp.getDataList().map((e) => e.toObject()));
      });
    });
  };

  const getDone = async () => {
    try {
      setIsLoading(false);
      let req = new GetDoneCommentatorRequest();
      req.setOrderId(orderId!);
      affiliatorOrderClient.getDoneCommentator(req, getToken(), (err, resp) => {
        if (err) {
          errorToast(err.message);
          return;
        }
        setDoneCommentators(resp.getData()!.toObject());
      });
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };
  const getSuspicious = async () => {
    try {
      setIsLoading(false);
      let req = new GetOrderSuspiciousActivityRequest();
      req.setOrderId(orderId!);
      affiliatorOrderClient.getOrderSuspiciousActivity(
        req,
        getToken(),
        (err, resp) => {
          if (err) {
            errorToast(err.message);
            return;
          }
          setSuspiciousData(resp.getData()!.toObject());
        }
      );
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };
  const getDiscussions = async () => {
    try {
      setIsLoading(false);
      let toClient = await getConversation(AFFILIATOR_CLIENT);
      let toAffiliator = await getConversation(AFFILIATOR_ADMIN);
      let toCommentator = await getConversation(AFFILIATOR_COMMENTATOR);
      toClient.reverse();
      toAffiliator.reverse();
      toCommentator.reverse();
      setMsgClients(toClient);
      setMsgAffiliators(toAffiliator);
      setMsgCommentators(toCommentator);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderDiscussions = (data: GetOrderDiscussionResponseData.AsObject[]) =>
    data.map((e) => (
      <div className="w-full" key={e.id}>
        <ChatBubble profile={profile} data={e} />
        {e.repliesList.map((r) => (
          <div key={r.id}>
            <ChatBubble profile={profile} data={r} />
          </div>
        ))}
      </div>
    ));

  return (
    <AffiliatorMid
      header={
        <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white">
          <Back />
          <h6>Detail Pesanan</h6>
          <div className="p-2 "></div>
        </div>
      }
      onLoadProfile={setProfile}
      noPadding
      hideMenu
    >
      <div className="flex flex-col h-full">
        <div className="flex-1  overflow-y-auto">
          <div className="bg-white px-4 py-8 mb-2">
            <div className="flex">
              <div className="w-2/12">
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/assets/logo.svg";
                  }}
                  src={data?.thumbnailUrl}
                  alt=""
                  className=" object-cover w-10 h-10 rounded-lg bg-gray-50 mr-2"
                />
              </div>
              <div className="w-10/12 truncate">
                <h6>{data?.title}</h6>
                <p className=" truncate cursor-pointer">{data?.url}</p>
              </div>
            </div>
            <div className="flex border-b pb-4"></div>
            <div className=" flex justify-between mt-4 mb-2 items-center">
              <p className=" font-bold text-lg">Status</p>
              {!data?.finishedAt && (
                <span className=" text-sky-400 font-semibold">Diproses</span>
              )}
            </div>
            <div className=" justify-between flex mb-2">
              <span>Sisa Waktu</span>
              <span className="flex text-orange-400 items-center">
                <HiOutlineClock size={18} className="mr-2" />
                {numberToDuration(
                  moment.utc().diff(moment.utc(data?.dueDate), "minutes")
                )}
              </span>
            </div>
            <h5 className=" font-bold text-lg mt-4">Detail Pesanan</h5>
            <div className=" justify-between flex mb-2">
              <span>Client</span>
              <span> {data?.user?.name}</span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>Komentar Positif</span>
              <span>{money(data?.positiveComment)}</span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>Komentar Negatif</span>
              <span>{money(data?.negativeComment)}</span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>Tag Teman</span>
              <span>{money(data?.tagFriend)}</span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>+ Bonus Like</span>
              <span>{money(data?.like)}</span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>+ Bonus Share</span>
              <span>{money(data?.share)}</span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>Catatan</span>
            </div>
            <div className=" border border-gray-150 px-12 py-4 rounded-xl text-center">
              {data?.remarks}
            </div>
            {!isFinished && (
              <div className="flex justify-between mt-8">
                <button
                  className="btn-orange-pills"
                  onClick={() => {
                    window.open(data?.url);
                  }}
                >
                  Lihat Progress
                </button>
                <button
                  className="btn-orange"
                  onClick={() => {
                    setModalReport(true);
                  }}
                >
                  Buat Laporan
                </button>
              </div>
            )}
          </div>
          {!isFinished && (
            <div className="bg-white px-4 py-4 mb-2 mt-2 min-h-[300px]">
              <div className="grid grid-cols-3 gap-4">
                <div
                  className={`${
                    tabMode == "chat" &&
                    "border-0 border-b-2 border-white border-b-sky-400"
                  } flex flex-col  items-center justify-center`}
                  onClick={() => setTabMode("chat")}
                >
                  <Badge
                    content={
                      data?.hasUnreadAdminMessage ||
                      data?.hasUnreadCommentatorMessage ||
                      data?.hasUnreadClientMessage
                    }
                  >
                    <img
                      src={`${
                        tabMode == "chat"
                          ? "/assets/images/icons/chat_active.png"
                          : "/assets/images/icons/Chat.png"
                      }`}
                      alt=""
                      className="w-8 mb-2"
                    />
                  </Badge>
                  <p
                    className={`${
                      tabMode == "chat" && "text-sky-400"
                    } text-sm text-center`}
                  >
                    Pesan
                  </p>
                </div>
                <div
                  className={`${
                    tabMode == "activity" &&
                    "border-0 border-b-2 border-white border-b-sky-400"
                  } flex flex-col  items-center justify-center`}
                  onClick={() => setTabMode("activity")}
                >
                  <Badge content={data?.hasSuspiciousActivity}>
                    <img
                      src={`${
                        tabMode == "activity"
                          ? "/assets/images/icons/eye_active.png"
                          : "/assets/images/icons/eye.png"
                      }`}
                      alt=""
                      className="w-8 mb-2"
                    />
                  </Badge>
                  <p
                    className={`${
                      tabMode == "activity" && "text-sky-400"
                    } text-sm text-center`}
                  >
                    Aktivitas
                  </p>
                </div>
                <div
                  className={`${
                    tabMode == "done" &&
                    "border-0 border-b-2 border-white border-b-sky-400"
                  } flex flex-col  items-center justify-center`}
                  onClick={() => setTabMode("done")}
                >
                  <img
                    src={`${
                      tabMode == "done"
                        ? "/assets/images/icons/shield_active.png"
                        : "/assets/images/icons/shield.png"
                    }`}
                    alt=""
                    className="w-8 mb-2"
                  />

                  <p
                    className={`${
                      tabMode == "done" && "text-sky-400"
                    } text-sm text-center`}
                  >
                    Selesai
                  </p>
                </div>
              </div>
              {tabMode == "chat" && (
                <>
                  <div className="  rounded-xl  justify-center grid grid-cols-3 mt-4">
                    <div
                      onClick={() => setActiveDiscussion("client")}
                      className={`px-4 py-2 text-center text-xs border border-sky-400 rounded-s-xl ${
                        activeDiscussion == "client" && "bg-sky-400 text-white"
                      }`}
                    >
                      CLIENT
                    </div>
                    <div
                      onClick={() => setActiveDiscussion("admin")}
                      className={`px-4 py-2 text-center text-xs border border-sky-400 ${
                        activeDiscussion == "admin" && "bg-sky-400 text-white"
                      }`}
                    >
                      SUPERADMIN
                    </div>
                    <div
                      onClick={() => setActiveDiscussion("commentator")}
                      className={`px-4 py-2 text-center text-xs border border-sky-400 rounded-e-xl ${
                        activeDiscussion == "commentator" &&
                        "bg-sky-400 text-white"
                      }`}
                    >
                      COMMENTATOR
                    </div>
                  </div>
                  <div className="py-4 w-full flex flex-col">
                    {activeDiscussion == "client" &&
                      renderDiscussions(msgClients)}
                    {activeDiscussion == "admin" &&
                      renderDiscussions(msgAffiliators)}
                    {activeDiscussion == "commentator" &&
                      renderDiscussions(msgCommentators)}
                  </div>
                </>
              )}
              {tabMode == "activity" && (
                <div className="mt-4">
                  <div className="flex flex-col justify-center items-center border-t border-b py-8">
                    <h3>{money(suspiciousData?.count)}</h3>
                    <p>Aktifitas Mencurigakan</p>
                  </div>
                  <ul className="mt-4">
                    {(suspiciousData?.commentatorsList ?? []).map((e) => (
                      <li key={e.id} className="my-4 border-b py-4">
                        <div className="flex">
                          <Avatar
                            circle
                            size="sm"
                            src={e.profilePictureUrl}
                            alt={initials(e.name)}
                          />
                          <div className="ml-4">
                            <p className=" text-base font-semibold">{e.name}</p>
                            <p className="-mt-1">
                              {e.accountsList.length} Aktifitas Mencurigakan
                            </p>
                          </div>
                        </div>
                        <div className="flex justify-between mt-4">
                          <button
                            onClick={() => {
                              setModalSuspiciousOpen(true);
                              setSelectedSuspiciousAccount(e);
                            }}
                            className="btn-sm btn-pills"
                          >
                            Periksa
                          </button>
                          <button
                            onClick={() => {
                              setUpdateSuspiciousOpen(true);
                              setSelectedSuspiciousAccount(e);
                            }}
                            className="btn-sm btn"
                          >
                            Update
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {tabMode == "done" && (
                <div className="mt-4">
                  <div className="flex flex-col justify-center items-center border-t border-b py-8">
                    <h3>{money(doneCommentators?.count)}</h3>
                    <p>Akun Telah Mengerjakan</p>
                  </div>
                  <ul className="mt-4">
                    {(doneCommentators?.commentatorsList ?? []).map((e) => (
                      <li
                        key={e.id}
                        className=" py-4 flex justify-between"
                      >
                        <div className="flex flex-col">
                          <div className="flex">
                            <Avatar
                              circle
                              size="sm"
                              src={e.profilePictureUrl}
                              alt={initials(e.name)}
                            />
                            <div className="ml-4">
                              <p className=" text-base font-semibold">
                                {e.name}
                              </p>
                            </div>
                          </div>
                          {selectedDoneAccountIDs.includes(e.id) && (
                            <ul className="mt-4">
                              {e.accountsList.map((acc) => (
                                <li key={acc.id} className="flex flex-row ml-8 mb-2 last:mb-0">
                                  <Avatar
                                    circle
                                    size="xs"
                                    src={acc.profilePictureUrl}
                                    alt={initials(acc.name)}
                                  />
                                  <div className="ml-4">
                                    <p className=" text-sm font-[600]">
                                      {acc.name}
                                    </p>
                                    <small className=" text-xs">
                                      {moment.utc(acc.finishedAt).format("DD/MM/YYYY HH:mm")}
                                    </small>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                        <div
                          className=""
                          onClick={() => {
                            if (selectedDoneAccountIDs.includes(e.id)) {
                              setSelectedDoneAccountIDs([
                                ...selectedDoneAccountIDs.filter(
                                  (s) => s != e.id
                                ),
                              ]);
                            } else {
                              setSelectedDoneAccountIDs([
                                ...selectedDoneAccountIDs,
                                e.id,
                              ]);
                            }
                          }}
                        >
                          {selectedDoneAccountIDs.includes(e.id) ? (
                            <HiChevronUp size={16} />
                          ) : (
                            <HiChevronDown size={16} />
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
          {isFinished && (
            <>
              <div className="bg-white px-4 py-4 mb-2">
                <div className="justify-between flex items-center">
                  <h5 className=" font-bold text-lg ">Bukti Laporan</h5>
                  <PiTriangleFill
                    onClick={() => setActiveReport(!activeReport)}
                    className={`w-3 ${!activeReport && "rotate-180"}`}
                  />
                </div>
                {activeReport && (
                  <div>
                    <Swiper
                      navigation={true}
                      modules={[Navigation]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="px-8 py-4 justify-center flex flex-col items-center">
                          <div className="mb-4">
                            <span className=" py-1 px-2 bg-sky-200 rounded-lg text-2xs">
                              Sebelum
                            </span>
                          </div>
                          <img src={data?.initialImageUrl} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="px-8 py-4 justify-center flex flex-col items-center">
                          <div className="mb-4">
                            <span className=" py-1 px-2 bg-sky-200 rounded-lg text-2xs">
                              Sesudah
                            </span>
                          </div>
                          <img src={data?.reportImageUrl} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                )}
              </div>
              <div className="bg-white px-4 py-4  z-50">
                <div className="justify-between flex items-center">
                  <h5 className=" font-bold text-lg ">Histori Percakapan</h5>
                  <PiTriangleFill
                    onClick={() => setActiveConversation(!activeConversation)}
                    className={`w-3 ${!activeConversation && "rotate-180"}`}
                  />
                </div>
                {activeConversation && (
                  <div className="py-4">
                    {conversations.map((e) => (
                      <div key={e.id}>
                        <div className="flex items-end mb-4">
                          <CommentBubble profile={profile} data={e} />
                        </div>
                        {e.repliesList.map((r) => (
                          <div key={r.id}>
                            <CommentBubble profile={profile} data={r} />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {/* CHATBOX */}
        {chatBoxEnabled && !isFinished && <ChatBox sendMessage={sendMessage} />}
      </div>

      <Drawer
        className="drawer-rounded"
        size={"md"}
        placement={"bottom"}
        open={modalEmoji}
        onClose={() => setModalEmoji(false)}
      >
        <Drawer.Body style={{}}>
          <EmojiPicker
            onEmojiClick={(val, ev) => {
              setMsg(msg + val.emoji);
            }}
          />
        </Drawer.Body>
      </Drawer>
      <Drawer
        className="drawer-rounded"
        closeButton={null}
        size={"md"}
        placement={"bottom"}
        open={modalSuspiciousOpen}
        onClose={() => setModalSuspiciousOpen(false)}
      >
        <Drawer.Body style={{ padding: 0 }}>
          <div className="flex flex-col h-full">
            <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white border-b">
              <div className="p-2 "></div>
              <h6>Akun yang dicurigai</h6>
              <div className="p-2 ">
                <HiXMark onClick={() => setModalSuspiciousOpen(false)} />
              </div>
            </div>
            <div className="flex-1 p-4 overflow-y-auto">
              <ul className="">
                {(selectedSuspiciousAccount?.accountsList ?? []).map((e) => (
                  <li className="mb-4" key={e.id}>
                    <div className="flex items-center">
                      <Avatar
                        circle
                        size="sm"
                        src={e.profilePictureUrl}
                        alt={initials(e.name)}
                      />
                      <div className="ml-4">
                        <div>
                          <p className=" text-base font-semibold">{e.name}</p>
                          <small className=" text-xs">{e.remarks}</small>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="p-4">
              <button
                className="btn w-full"
                onClick={() => {
                  window.open(data?.url);
                }}
              >
                Periksa Sekarang
              </button>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
      <Drawer
        className="drawer-rounded"
        closeButton={null}
        size={"md"}
        placement={"bottom"}
        open={updateSuspiciousOpen}
        onClose={() => setUpdateSuspiciousOpen(false)}
      >
        <Drawer.Body style={{ padding: 0 }}>
          <div className="flex flex-col h-full">
            <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white border-b">
              <div className="p-2 "></div>
              <h6>Apakah Akun Ini Sudah Mengerjakan ?</h6>
              <div className="p-2 ">
                <HiXMark onClick={() => setUpdateSuspiciousOpen(false)} />
              </div>
            </div>
            <div className="flex-1 p-4 overflow-y-auto">
              <ul className="">
                {(selectedSuspiciousAccount?.accountsList ?? []).map((e) => (
                  <li className="mb-4" key={e.id}>
                    <div className="flex items-center">
                      <Avatar
                        circle
                        size="sm"
                        src={e.profilePictureUrl}
                        alt={initials(e.name)}
                      />
                      <div className="ml-4 justify-between flex w-full items-center">
                        <div>
                          <p className=" text-base font-semibold">{e.name}</p>
                          <small className="text-xs">{e.remarks}</small>
                        </div>
                        <Checkbox
                          checked={selectedAccountIDs.includes(e.id)}
                          onChange={(val, checked) => {
                            if (selectedAccountIDs.includes(e.id)) {
                              setSelectedAccountIDs([
                                ...selectedAccountIDs.filter((s) => s !== e.id),
                              ]);
                            } else {
                              setSelectedAccountIDs([
                                ...selectedAccountIDs,
                                e.id,
                              ]);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="p-4 class flex  justify-between">
              <button
                className="btn-pills "
                onClick={() => {
                  setUpdateSuspiciousOpen(false);
                  setModalWarningOpen(true);
                }}
              >
                Beri Peringatan
              </button>
              <button
                className="btn "
                onClick={() => {
                  let req = new SetSuspiciousActivityAsDoneRequest();
                  req.setOrderId(orderId!);
                  req.setCommentatorAccountIdList(selectedAccountIDs);
                  affiliatorOrderClient.setSuspiciousActivityAsDone(
                    req,
                    getToken(),
                    (err, resp) => {
                      setIsLoading(false);
                      if (err) {
                        errorToast(err.message);
                        return;
                      }
                      successToast(
                        `${selectedSuspiciousAccount?.name} berhasil di update`
                      );
                      setUpdateSuspiciousOpen(false);
                      setMounted(false);
                      setTimeout(() => {
                        setMounted(true);
                      }, 300);
                    }
                  );
                }}
              >
                Sudah, Aman
              </button>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
      <Drawer
        className="drawer-rounded"
        closeButton={null}
        size={"full"}
        placement={"bottom"}
        open={modalReport}
        onClose={() => setModalReport(false)}
      >
        <Drawer.Body style={{ padding: 0 }}>
          <div className="flex flex-col h-full">
            <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white border-b">
              <div className="p-2 ">
                <Back onClick={() => setModalReport(false)} />
              </div>
              <h6>Buat Laporan</h6>
              <div className="p-2 "></div>
            </div>
            <div className="flex-1 p-4 overflow-y-auto">
              <ul>
                <li className="mb-2 text-base justify-between flex">
                  <span>
                    Komentar Sudah Lebih dari{" "}
                    {(data?.positiveCommentDone ?? 0) +
                      (data?.negativeComment ?? 0) +
                      (data?.tagFriendDone ?? 0)}
                  </span>{" "}
                  <Checkbox
                    checked={reportCommentDone}
                    onChange={(val, checked) => {
                      setReportCommentDone(checked);
                    }}
                  />
                </li>
                <li className="mb-2 text-base justify-between flex">
                  <span>Like Sudah Lebih dari {data?.likeDone ?? 0}</span>{" "}
                  <Checkbox
                    checked={reportLikeDone}
                    onChange={(val, checked) => {
                      setReportLikeDone(checked);
                    }}
                  />
                </li>
                <li className="mb-2 text-base justify-between flex">
                  <span>Share Sudah Lebih dari {data?.shareDone ?? 0}</span>{" "}
                  <Checkbox
                    checked={reportShareDone}
                    onChange={(val, checked) => {
                      setReportShareDone(checked);
                    }}
                  />
                </li>
              </ul>

              <div className="p-2 ">
                {cameraOn ? (
                  <div className="flex-1 flex justify-center items-center flex-col">
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={videoConstraints}
                    />
                  </div>
                ) : (
                  <div className="flex-1 flex justify-center items-center">
                    <div className="flex justify-center flex-col">
                      <div className=" p-24 rounded-3xl border-dashed border-2">
                        <img src={imgUpload} alt="" />
                      </div>
                      <div className="mt-8 flex justify-between">
                        <img
                          onClick={() => {
                            fileRef2.current?.click();
                          }}
                          style={{ height: 50 }}
                          className=""
                          src="/assets/button_gallery.png"
                          alt=""
                        />
                        <img
                          onClick={() => {
                            setCameraOn(true);
                          }}
                          style={{ height: 60 }}
                          className=""
                          src="/assets/button_camera.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                )}

                <input
                  accept="image/*"
                  type="file"
                  className="hidden"
                  ref={fileRef2}
                  onChange={(el) => {
                    let files = fileRef2.current?.files;
                    if (files) {
                      let reader = new FileReader();

                      reader.onload = function () {
                        // console.log(reader?.result?.toString())
                        setImgUpload(
                          reader?.result?.toString() ?? "/assets/upload.png"
                        );
                      };
                      reader.readAsDataURL(files[0]);
                    }
                  }}
                />
              </div>
            </div>
            <div className="p-4 class flex  justify-between">
              <button
                className="btn w-full "
                onClick={() => {
                  if (cameraOn) {
                    capture();
                    return;
                  }
                  if (
                    !reportCommentDone &&
                    !reportLikeDone &&
                    !reportShareDone
                  ) {
                    errorToast(`pastikan semua sudah di cek`);
                    return;
                  }
                  setIsLoading(true);
                  let req = new FileBase64Request();
                  req.setFile(imgUpload.split("base64,")[1]);
                  // imgUpload.split()
                  // console.log()
                  clientFile.uploadBase64Temp(req, getToken(), (err, resp) => {
                    if (err) {
                      errorToast(err.message);
                      setIsLoading(false);
                      return;
                    }
                    let path = resp.getData()?.toObject();
                    let req = new SetAsFinishedRequest();
                    req.setOrderId(data!.id);
                    req.setReportImagePath(path!.path);
                    req.setReportImagePathType(path!.pathType);
                    setIsLoading(true);
                    affiliatorOrderClient.setAsFinished(
                      req,
                      getToken(),
                      (err, resp) => {
                        setIsLoading(false);
                        if (err) {
                          errorToast(err.message);
                          return;
                        }
                      }
                    );
                    successToast(`Order telah di report`);
                    setModalReport(false);
                    getDetail();
                    nav("/affiliator/order");
                    setImgUpload("/assets/upload.png");
                  });
                }}
              >
                {cameraOn ? "Ambil Foto" : "Kirim Laporan"}
              </button>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>

      <Drawer
        className="drawer-rounded"
        size={"md"}
        closeButton={null}
        placement={"bottom"}
        open={modalWarningOpen}
        onClose={() => {
          setModalWarningOpen(false);
        }}
      >
        <Drawer.Body style={{ padding: 20 }}>
          <div className="flex flex-col h-full">
            <div className="flex flex-1 flex-col justify-center items-center">
              <img
                src="/assets/card_attention.png"
                alt=""
                className="w-12 mb-8 mt-8"
              />
              <p className="text-center mb-4 bg-orange-200 py-2 px-4 rounded-lg">
                Kartu digunakan untuk komentator yang melanggar, setelah 3x
                peringatan komentator akan di blokir untuk mendapatkan pesanan
                lagi
              </p>
            </div>
            <div className="flex justify-between mt-4">
              <button
                className="btn-pills"
                onClick={() => setModalWarningOpen(false)}
              >
                Batal
              </button>
              <button
                className="btn"
                onClick={() => {
                  setIsLoading(true);
                  let req = new ReportOffenseRequest();
                  req.setOrderId(orderId!);
                  req.setUserId(selectedSuspiciousAccount!.id);
                  // req.setRemarks(remarks)
                  generalClient.reportOffense(req, getToken(), (err, resp) => {
                    setIsLoading(false);
                    if (err) {
                      errorToast(err.message);
                      return;
                    }
                    successToast(
                      `${selectedSuspiciousAccount?.name} berhasil diberikan kartu`
                    );
                    setModalWarningOpen(false);
                    setMounted(false);
                    setTimeout(() => {
                      setMounted(true);
                    }, 300);
                  });
                }}
              >
                Beri Kartu
              </button>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
    </AffiliatorMid>
  );
};
export default AffiliatorOrderDetail;
