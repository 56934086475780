/**
 * @fileoverview gRPC-Web generated client stub for auth
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: auth.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.auth = require('./auth_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.auth.AuthHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.auth.AuthHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.RegistrationRequest,
 *   !proto.auth.RegistrationResponse>}
 */
const methodDescriptor_AuthHandler_Registration = new grpc.web.MethodDescriptor(
  '/auth.AuthHandler/Registration',
  grpc.web.MethodType.UNARY,
  proto.auth.RegistrationRequest,
  proto.auth.RegistrationResponse,
  /**
   * @param {!proto.auth.RegistrationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.RegistrationResponse.deserializeBinary
);


/**
 * @param {!proto.auth.RegistrationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.RegistrationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.RegistrationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthHandlerClient.prototype.registration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthHandler/Registration',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_Registration,
      callback);
};


/**
 * @param {!proto.auth.RegistrationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.RegistrationResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthHandlerPromiseClient.prototype.registration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthHandler/Registration',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_Registration);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.LoginPasswordRequest,
 *   !proto.auth.LoginResponse>}
 */
const methodDescriptor_AuthHandler_LoginPassword = new grpc.web.MethodDescriptor(
  '/auth.AuthHandler/LoginPassword',
  grpc.web.MethodType.UNARY,
  proto.auth.LoginPasswordRequest,
  proto.auth.LoginResponse,
  /**
   * @param {!proto.auth.LoginPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.auth.LoginPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthHandlerClient.prototype.loginPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthHandler/LoginPassword',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_LoginPassword,
      callback);
};


/**
 * @param {!proto.auth.LoginPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthHandlerPromiseClient.prototype.loginPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthHandler/LoginPassword',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_LoginPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.LoginWithGoogleRequest,
 *   !proto.auth.LoginResponse>}
 */
const methodDescriptor_AuthHandler_LoginWithGoogle = new grpc.web.MethodDescriptor(
  '/auth.AuthHandler/LoginWithGoogle',
  grpc.web.MethodType.UNARY,
  proto.auth.LoginWithGoogleRequest,
  proto.auth.LoginResponse,
  /**
   * @param {!proto.auth.LoginWithGoogleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.auth.LoginWithGoogleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthHandlerClient.prototype.loginWithGoogle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthHandler/LoginWithGoogle',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_LoginWithGoogle,
      callback);
};


/**
 * @param {!proto.auth.LoginWithGoogleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthHandlerPromiseClient.prototype.loginWithGoogle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthHandler/LoginWithGoogle',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_LoginWithGoogle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.LoginWithGoogleRequest,
 *   !proto.auth.LoginResponse>}
 */
const methodDescriptor_AuthHandler_LoginWithGoogleWeb = new grpc.web.MethodDescriptor(
  '/auth.AuthHandler/LoginWithGoogleWeb',
  grpc.web.MethodType.UNARY,
  proto.auth.LoginWithGoogleRequest,
  proto.auth.LoginResponse,
  /**
   * @param {!proto.auth.LoginWithGoogleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.auth.LoginWithGoogleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthHandlerClient.prototype.loginWithGoogleWeb =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthHandler/LoginWithGoogleWeb',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_LoginWithGoogleWeb,
      callback);
};


/**
 * @param {!proto.auth.LoginWithGoogleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthHandlerPromiseClient.prototype.loginWithGoogleWeb =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthHandler/LoginWithGoogleWeb',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_LoginWithGoogleWeb);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.LoginWithAppleRequest,
 *   !proto.auth.LoginResponse>}
 */
const methodDescriptor_AuthHandler_LoginWithApple = new grpc.web.MethodDescriptor(
  '/auth.AuthHandler/LoginWithApple',
  grpc.web.MethodType.UNARY,
  proto.auth.LoginWithAppleRequest,
  proto.auth.LoginResponse,
  /**
   * @param {!proto.auth.LoginWithAppleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.auth.LoginWithAppleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthHandlerClient.prototype.loginWithApple =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthHandler/LoginWithApple',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_LoginWithApple,
      callback);
};


/**
 * @param {!proto.auth.LoginWithAppleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthHandlerPromiseClient.prototype.loginWithApple =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthHandler/LoginWithApple',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_LoginWithApple);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.RefreshTokenRequest,
 *   !proto.auth.RefreshTokenResponse>}
 */
const methodDescriptor_AuthHandler_RefreshToken = new grpc.web.MethodDescriptor(
  '/auth.AuthHandler/RefreshToken',
  grpc.web.MethodType.UNARY,
  proto.auth.RefreshTokenRequest,
  proto.auth.RefreshTokenResponse,
  /**
   * @param {!proto.auth.RefreshTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.RefreshTokenResponse.deserializeBinary
);


/**
 * @param {!proto.auth.RefreshTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.RefreshTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.RefreshTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthHandlerClient.prototype.refreshToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthHandler/RefreshToken',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_RefreshToken,
      callback);
};


/**
 * @param {!proto.auth.RefreshTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.RefreshTokenResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthHandlerPromiseClient.prototype.refreshToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthHandler/RefreshToken',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_RefreshToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.ForgotPasswordRequest,
 *   !proto.auth.ForgotPasswordResponse>}
 */
const methodDescriptor_AuthHandler_ForgotPassword = new grpc.web.MethodDescriptor(
  '/auth.AuthHandler/ForgotPassword',
  grpc.web.MethodType.UNARY,
  proto.auth.ForgotPasswordRequest,
  proto.auth.ForgotPasswordResponse,
  /**
   * @param {!proto.auth.ForgotPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.ForgotPasswordResponse.deserializeBinary
);


/**
 * @param {!proto.auth.ForgotPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.ForgotPasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.ForgotPasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthHandlerClient.prototype.forgotPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthHandler/ForgotPassword',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_ForgotPassword,
      callback);
};


/**
 * @param {!proto.auth.ForgotPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.ForgotPasswordResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthHandlerPromiseClient.prototype.forgotPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthHandler/ForgotPassword',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_ForgotPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.ChangePasswordRequest,
 *   !proto.auth.ChangePasswordResponse>}
 */
const methodDescriptor_AuthHandler_ChangePassword = new grpc.web.MethodDescriptor(
  '/auth.AuthHandler/ChangePassword',
  grpc.web.MethodType.UNARY,
  proto.auth.ChangePasswordRequest,
  proto.auth.ChangePasswordResponse,
  /**
   * @param {!proto.auth.ChangePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.ChangePasswordResponse.deserializeBinary
);


/**
 * @param {!proto.auth.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.ChangePasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.ChangePasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthHandlerClient.prototype.changePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthHandler/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_ChangePassword,
      callback);
};


/**
 * @param {!proto.auth.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.ChangePasswordResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthHandlerPromiseClient.prototype.changePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthHandler/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_ChangePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.UpdatePasswordRequest,
 *   !proto.auth.UpdatePasswordResponse>}
 */
const methodDescriptor_AuthHandler_UpdatePassword = new grpc.web.MethodDescriptor(
  '/auth.AuthHandler/UpdatePassword',
  grpc.web.MethodType.UNARY,
  proto.auth.UpdatePasswordRequest,
  proto.auth.UpdatePasswordResponse,
  /**
   * @param {!proto.auth.UpdatePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.UpdatePasswordResponse.deserializeBinary
);


/**
 * @param {!proto.auth.UpdatePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.UpdatePasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.UpdatePasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthHandlerClient.prototype.updatePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthHandler/UpdatePassword',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_UpdatePassword,
      callback);
};


/**
 * @param {!proto.auth.UpdatePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.UpdatePasswordResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthHandlerPromiseClient.prototype.updatePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthHandler/UpdatePassword',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_UpdatePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.LogoutRequest,
 *   !proto.auth.LogoutResponse>}
 */
const methodDescriptor_AuthHandler_Logout = new grpc.web.MethodDescriptor(
  '/auth.AuthHandler/Logout',
  grpc.web.MethodType.UNARY,
  proto.auth.LogoutRequest,
  proto.auth.LogoutResponse,
  /**
   * @param {!proto.auth.LogoutRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.LogoutResponse.deserializeBinary
);


/**
 * @param {!proto.auth.LogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.LogoutResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.LogoutResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthHandlerClient.prototype.logout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthHandler/Logout',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_Logout,
      callback);
};


/**
 * @param {!proto.auth.LogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.LogoutResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthHandlerPromiseClient.prototype.logout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthHandler/Logout',
      request,
      metadata || {},
      methodDescriptor_AuthHandler_Logout);
};


module.exports = proto.auth;

