import { useContext, useEffect, useState, type FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingContext } from '../../objects/loading_context';
import { GetProfileResponseData } from '../../grpc/client/client_general_pb';
import { GetDetailUserPackageRequest, GetDetailUserPackageResponseData, GetUserPackageStatusResponseData } from '../../grpc/client/client_user_package_pb';
import { clientUserPackage } from '../../App';
import { getToken } from '../../utils/helper';
import { errorToast } from '../../utils/helper-ui';
import ClientMid from '../../middlewares/ClientMid';
import Back from '../../components/back';

interface OrderProcessingProps { }

const OrderProcessing: FC<OrderProcessingProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);

    const nav = useNavigate()
    const { userPackageId } = useParams()
    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const [userPackage, setUserPackage] = useState<GetUserPackageStatusResponseData.AsObject>();
    const [mounted, setMounted] = useState(false);
    const [data, setData] = useState<GetDetailUserPackageResponseData.AsObject | null | undefined>(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted && !userPackageId) return

        getDetail()
    }, [mounted]);

    const getDetail = async () => {
        let req = new GetDetailUserPackageRequest()
        req.setId(userPackageId!)
        clientUserPackage.getDetailUserPackage(req, getToken(), (err, resp) => {
            if (err) {
                errorToast(err.message)
                return
            }
            setData(resp.getData()?.toObject())
        })
    }

    return (
        <ClientMid onLoad={setUserPackage} onLoadProfile={setProfile} noPadding={true} hideMenu header={<div className='w-full px-4 py-2 justify-between items-center flex pt-6 bg-white'>
            <Back />
        </div>} >
            <div className='h-full justify-center items-center flex flex-col'>
                <div className='flex-1 flex justify-center items-center'>
                    <div className='flex justify-center flex-col'>
                        <h4 className='text-center'>Mohon Menunggu ....</h4>
                        <p className='text-center'>Bukti Pembayaran sedang diproses</p>
                        <div className='p-8'>
                            <img src="/assets/illus_menunggu.png" alt="" className='m-auto' />

                        </div>

                        <p className='text-center m-0'>Pengerjaan maksimal 24 jam</p>
                        <p className='text-center m-0'>Cek status di Daftar Pesananmu</p>
                    </div>
                </div>
                <div className='p-4 w-full'>
                    <button onClick={() => {
                        nav(`/package-order/${userPackageId}`)
                    }} type="button" className="btn w-full">Cek Status</button>
                </div>
            </div>
        </ClientMid>
    );
}
export default OrderProcessing;