import type { FC } from 'react';

interface NoDataProps { 
    height?: number
}

const NoData: FC<NoDataProps> = ({
    height
 }) => {
    return (
        <div className={`flex flex-col justify-center items-center min-h-[${height??200}px]`}>No Data</div>
    );
}
export default NoData;