import { useContext, useEffect, useRef, useState, type FC } from 'react';
import ProfileMid from '../../middlewares/ProfileMid';
import { GetProfileResponseData, GetProfileResponseDataFinancialAccount } from '../../grpc/client/client_general_pb';
import Back from '../../components/back';
import { Avatar } from 'rsuite';
import { getToken, initials, setStorageProfile } from '../../utils/helper';
import { LoadingContext } from '../../objects/loading_context';
import { FileBase64Request } from '../../grpc/service/file_pb';
import { authClient, clientFile, clientGeneral, generalClient } from '../../App';
import { errorToast, successToast } from '../../utils/helper-ui';
import { GetProfileRequest, UpdateProfileRequest, UpdateProfileRequestFinancialAccount } from '../../grpc/service/general_pb';
import { HiOutlineEye } from 'react-icons/hi2';
import { ChangePasswordRequest, UpdatePasswordRequest } from '../../grpc/auth/auth_pb';

interface AccountSecurityProps { }

const AccountSecurity: FC<AccountSecurityProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);

    const fileRef = useRef<HTMLInputElement>(null)
    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [name, setName] = useState("");
    const [confirm, setConfirm] = useState("");
    const [phone, setPhone] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [selectedFinanceAccount, setSelectedFinanceAccount] = useState<GetProfileResponseDataFinancialAccount.AsObject | null | undefined>(null);


    const updateProfile = () => {
        setIsLoading(true)
        let reqProfile = new UpdateProfileRequest()
        reqProfile.setName(profile?.name ?? "")
        reqProfile.setEmail(profile?.email ?? "")
        reqProfile.setPhoneNumber(profile?.phoneNumber ?? "")
        reqProfile.setProfilePicturePath(profile?.profilePicturePath ?? "")
        reqProfile.setProfilePicturePathType(profile?.profilePicturePathType ?? "")
        let finances: UpdateProfileRequestFinancialAccount[] = []
        for (const iterator of profile?.financialAccountsList ?? []) {
            let finance = new UpdateProfileRequestFinancialAccount()
            finance.setId(iterator.id)
            finance.setName(iterator.name)
            finance.setType(iterator.type)
            finance.setAccountNumber(iterator.accountNumber)
            finances.push(finance)
        }
        reqProfile.setFinancialAccountsList(finances)
        generalClient.updateProfile(reqProfile, getToken(), (err, resp) => {
            if (err) {
                errorToast(err.message)
                setIsLoading(false)
                return
            }
            setIsLoading(false)
        })
        getProfile()
    }
    const getProfile = () => {
        generalClient.getProfile(new GetProfileRequest(), getToken(), (err, resp) => {
            if (err) {
                errorToast(err.message)
                setIsLoading(false)
                return
            }
            setProfile(resp.getData()?.toObject())
            setStorageProfile(resp.getData()?.toObject())
        })
    }

    useEffect(() => {
        if (profile) {
            setName(profile?.name)
            setPhone(profile?.phoneNumber)
            setEmail(profile?.email)
            if (profile?.financialAccountsList.length > 0) {
                setSelectedFinanceAccount(profile.financialAccountsList[0])
            } else {
                let finance = new GetProfileResponseDataFinancialAccount()
                finance.setId("")
                finance.setName("")
                finance.setType("DANA")
                finance.setAccountNumber("")
                setSelectedFinanceAccount(finance.toObject())
            }
        }
    }, [profile]);

    return (<ProfileMid onLoad={setProfile}>
        <div className='w-full px-4 py-2 justify-between items-center flex pt-6 bg-white'>
            <Back />
            <h6>Akun & Keamanan</h6>
            <div className='p-2 '></div>
        </div>
        <div className='bg-white h-full flex flex-col px-4'>
            <div className='flex flex-col justify-center items-center py-8'>
                <Avatar alt={initials(profile?.name)} circle src={profile?.profilePictureUrl} size='xl' className='m-auto' color='blue' />
                <p className=' text-sky-400 py-2 cursor-pointer' onClick={() => {
                    fileRef.current?.click()
                }}>Ganti Foto Profil</p>
            </div>
            <input accept='image/*' type="file" className='hidden' ref={fileRef} onChange={(el) => {
                let files = fileRef.current?.files
                if (files) {
                    let reader = new FileReader();

                    reader.onload = function () {

                        setIsLoading(true)
                        let req = new FileBase64Request()
                        req.setFile((reader?.result?.toString() ?? "").split("base64,")[1])
                        clientFile.uploadBase64Temp(req, getToken(), (err, resp) => {
                            if (err) {
                                errorToast(err.message)
                                setIsLoading(false)
                                return
                            }
                            profile!.profilePicturePath = resp.getData()?.toObject().path ?? ""
                            profile!.profilePicturePathType = resp.getData()?.toObject().pathType ?? ""
                            setTimeout(() => {
                                updateProfile()
                            }, 1000);
                            setIsLoading(false)
                        })
                    }
                    reader.readAsDataURL(files[0]);
                }
            }} />



            <label className='font-bold mb-1 px-1' htmlFor="">Nama Lengkap</label>
            <div className=' relative'>
                <input value={profile?.name ?? ""} placeholder="Masukan Nama Lengkap" className='form-control mb-4' onChange={(el) => {
                    setProfile({
                        ...profile!,
                        name: el.target.value,
                    })
                }} />
            </div>
            <label className='font-bold mb-1 px-1' htmlFor="">Telepon</label>
            <div className=' relative'>
                <input value={profile?.phoneNumber ?? ""} placeholder="Masukan no telp" className='form-control mb-4' onChange={(el) => {
                    setProfile({
                        ...profile!,
                        phoneNumber: el.target.value,
                    })
                }} />
            </div>
            <label className='font-bold mb-1 px-1' htmlFor="">Email</label>
            <div className=' relative'>
                <input readOnly value={profile?.email ?? ""} type='email' placeholder="Email" className='form-control mb-4' onChange={(el) => {
                     setProfile({
                        ...profile!,
                        email: el.target.value,
                    })
                }} />
            </div>
            <label className='font-bold mb-1 px-1' htmlFor="">Nomor DANA</label>
            <div className=' relative'>
                <input readOnly value={selectedFinanceAccount?.accountNumber ?? ""} type='text' placeholder="Nomor DANA" className='form-control mb-4' onChange={(el) => {
                    setSelectedFinanceAccount({
                        ...selectedFinanceAccount!,
                        name: el.target.value,
                    })
                }} />
            </div>
            <label className='font-bold mb-1 px-1' htmlFor="">Nama Penerima DANA</label>
            <div className=' relative'>
                <input readOnly value={selectedFinanceAccount?.name ?? ""} type='text' placeholder="Nama Penerima DANA" className='form-control mb-4' onChange={(el) => {
                    setSelectedFinanceAccount({
                        ...selectedFinanceAccount!,
                        name: el.target.value,
                    })
                }} />
            </div>

            <button onClick={updateProfile} type="button" className="btn w-full mt-2 mb-8">Update Profil</button>

            <div className=' border rounded-xl py-4 px-4'>
                <h5 className='mb-4'>Ganti Password</h5>
                <label className='font-bold mb-1 px-1' htmlFor="">Password saat ini</label>
                <div className=' relative'>
                    <input value={currentPassword} type={showPassword ? 'text' : 'password'} placeholder="Password" className='form-control mb-4' onChange={(el) => setCurrentPassword(el.currentTarget.value)} />
                    <HiOutlineEye onClick={() => setShowPassword(!showPassword)} size={20} className={`${showPassword ? 'text-gray-600' : 'text-gray-300'}  absolute top-2 right-2`} />
                </div>
                <label className='font-bold mb-1 px-1' htmlFor="">Password</label>
                <div className=' relative'>
                    <input value={password} type={showPassword ? 'text' : 'password'} placeholder="Password" className='form-control mb-4' onChange={(el) => setPassword(el.currentTarget.value)} />
                    <HiOutlineEye onClick={() => setShowPassword(!showPassword)} size={20} className={`${showPassword ? 'text-gray-600' : 'text-gray-300'}  absolute top-2 right-2`} />
                </div>
                <label className='font-bold mb-1 px-1' htmlFor="">Ulangi Password</label>
                <div className=' relative'>
                    <input value={confirm} type={showPassword ? 'text' : 'password'} placeholder="Ulangi Password" className='form-control mb-4' onChange={(el) => setConfirm(el.currentTarget.value)} />
                    <HiOutlineEye onClick={() => setShowPassword(!showPassword)} size={20} className={`${showPassword ? 'text-gray-600' : 'text-gray-300'}  absolute top-2 right-2`} />
                </div>

                <button onClick={async () => {
                    try {
                        if (password != confirm && password.length > 0) throw Error("Password tidak sama")
                        setIsLoading(true)
                        await new Promise((resolve, reject) => {
                            let req = new UpdatePasswordRequest()
                            req.setOldPassword(currentPassword)
                            req.setNewPassword(password)
                            authClient.updatePassword(req, getToken(), (err, resp) => {
                                if (err) {
                                    reject(err)
                                    return
                                }
                                resolve(resp)
                            })
                        })
                        successToast("Password berhasil diupdate")
                    } catch (error) {
                        errorToast(`${error}`)
                    } finally {
                        setIsLoading(false)
                    }
                }} type="button" className="btn w-full mt-8">Simpan Password</button>
            </div>
        </div>

    </ProfileMid>);
}
export default AccountSecurity;