import { useContext, useRef, useState, type FC } from "react";
import AffiliatorMid from "../../middlewares/AffiliatorMid";
import Back from "../../components/back";
import { LoadingContext } from "../../objects/loading_context";
import { GetProfileResponseData } from "../../grpc/service/general_pb";
import {
  ORDER_DONE,
  ORDER_PROCESSING,
  ORDER_REJECTED,
  ORDER_REVIEWED,
} from "../../utils/contants";
import AffiliatorOrderList from "../../components/affiliator/AffiliatorOrderList";
import { GetOrderDraftListResponseData } from "../../grpc/client/client_order_pb";
import { useNavigate } from "react-router-dom";
import { Drawer } from "rsuite";
import { getToken, money, randomStr } from "../../utils/helper";
import { HiXMark } from "react-icons/hi2";
import React from "react";
import Webcam from "react-webcam";
import { PiCheckCircleFill, PiCircleBold } from "react-icons/pi";
import { FileBase64Request } from "../../grpc/service/file_pb";
import { affiliatorOrderClient, clientFile } from "../../App";
import { errorToast, successToast } from "../../utils/helper-ui";
import {
  CreateOrderRequest,
  DeclineDraftRequest,
} from "../../grpc/affiliator/affiliator_order_pb";

interface AffiliatorOrderProps {}

const AffiliatorOrder: FC<AffiliatorOrderProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const [mounted, setMounted] = useState(false);
  const [orderStatusMode, setOrderStatusMode] = useState(ORDER_REVIEWED);
  const nav = useNavigate();
  const [modalDetail, setModalDetail] = useState(false);
  const [selectedDraftDetail, setSelectedDraftDetail] =
    useState<GetOrderDraftListResponseData.AsObject | null>(null);
  const [isReviewed, setIsReviewed] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [modalRejectOpen, setModalRejectOpen] = useState(false);
  const [selectedRemarks, setSelectedRemarks] = useState<string[]>([]);
  const [otherRemarks, setOtherRemarks] = useState("");
  const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
  const [imgUpload, setImgUpload] = useState("/assets/upload.png");
  const [cameraOn, setCameraOn] = useState(false);

  const fileRef = useRef<HTMLInputElement>(null);
  const webcamRef = useRef<Webcam>(null);
  const capture = React.useCallback(() => {
    if (!webcamRef) return;
    const imageSrc = webcamRef?.current?.getScreenshot();

    setImgUpload(imageSrc ?? "/assets/upload.png");
    setCameraOn(false);
  }, [webcamRef]);

  const videoConstraints = {};

  const remarkList = [
    "Catatan mengandung HOAX",
    "Catatan mengandung SARA",
    "Catatan menyinggung keluarga",
    "Catatan menyerang fisik",
    "Link salah atau hilang",
    "Lainnya",
  ];

  return (
    <AffiliatorMid
      header={
        <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white">
          <Back />
          <h6>Pesanan Komentar</h6>
          <div className="p-2 "></div>
        </div>
      }
      onLoadProfile={setProfile}
      noPadding
      hideMenu
    >
      <div className=" w-full">
        <div className="tabmenu w-full">
          <div
            onClick={() => setOrderStatusMode(ORDER_REVIEWED)}
            className={`${orderStatusMode == ORDER_REVIEWED && "active"}`}
          >
            Ditinjau
          </div>
          <div
            onClick={() => setOrderStatusMode(ORDER_PROCESSING)}
            className={`${orderStatusMode == ORDER_PROCESSING && "active"}`}
          >
            Diproses
          </div>
          <div
            onClick={() => setOrderStatusMode(ORDER_DONE)}
            className={`${orderStatusMode == ORDER_DONE && "active"}`}
          >
            Selesai
          </div>
          <div
            onClick={() => setOrderStatusMode(ORDER_REJECTED)}
            className={`${orderStatusMode == ORDER_REJECTED && "active"}`}
          >
            Ditolak
          </div>
        </div>
        {orderStatusMode == ORDER_REVIEWED && (
          <AffiliatorOrderList
            onClickDetailDraft={(data) => {
              setSelectedDraftDetail(data);
              setModalDetail(true);
              setIsReviewed(true);
              setIsDeclined(false);
            }}
            mode={ORDER_REVIEWED}
          />
        )}
        {orderStatusMode == ORDER_PROCESSING && (
          <AffiliatorOrderList mode={ORDER_PROCESSING} />
        )}
        {orderStatusMode == ORDER_DONE && (
          <AffiliatorOrderList mode={ORDER_DONE} />
        )}
        {orderStatusMode == ORDER_REJECTED && (
          <AffiliatorOrderList
            onClickDetailDraft={(data) => {
              setSelectedDraftDetail(data);
              setModalDetail(true);
              setIsReviewed(false);
              setIsDeclined(true);
            }}
            mode={ORDER_REJECTED}
          />
        )}
      </div>
      <Drawer
        className="drawer-rounded"
        size={"full"}
        closeButton={null}
        placement={"bottom"}
        open={modalDetail}
        onClose={() => setModalDetail(false)}
      >
        <Drawer.Body style={{ padding: 0 }}>
          <div className="flex flex-col h-full">
            <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white border-b">
              <Back onClick={() => setModalDetail(false)} />
              <h6>Detail Pesanan</h6>
              <div className="p-2 "></div>
            </div>
            <div className="flex flex-1 flex-col">
              <div className="flex-1  overflow-y-auto p-4">
                <div className="bg-white px-4 py-8 mb-2">
                  <div className="flex" onClick={() => window.open(selectedDraftDetail?.url)}>
                    <div className="w-2/12">
                      <img
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = "/assets/logo.svg";
                        }}
                        src={selectedDraftDetail?.thumbnailUrl}
                        alt=""
                        className=" object-cover w-10 h-10 rounded-lg bg-gray-50 mr-2"
                      />
                    </div>
                    <div className="w-10/12 truncate">
                      <h6>{selectedDraftDetail?.title}</h6>
                      <p className=" truncate cursor-pointer">
                        {selectedDraftDetail?.url}
                      </p>
                    </div>
                  </div>
                  <div className="flex border-b pb-4"></div>
                  <div className=" flex justify-between mt-4 mb-2 items-center">
                    <p className=" font-bold text-lg">Status</p>
                    {isReviewed && (
                      <span className=" text-sky-400 font-semibold">
                        Diproses
                      </span>
                    )}
                    {isDeclined && (
                      <span className=" text-orange-400 font-semibold">
                        Ditolak
                      </span>
                    )}
                  </div>

                  <h5 className=" font-bold text-lg mt-4">Detail Pesanan</h5>
                  <div className=" justify-between flex mb-2">
                    <span>Client</span>
                    <span> {selectedDraftDetail?.user?.name}</span>
                  </div>
                  <div className=" justify-between flex mb-2">
                    <span>Komentar Positif</span>
                    <span>{money(selectedDraftDetail?.positiveComment)}</span>
                  </div>
                  <div className=" justify-between flex mb-2">
                    <span>Komentar Negatif</span>
                    <span>{money(selectedDraftDetail?.negativeComment)}</span>
                  </div>
                  <div className=" justify-between flex mb-2">
                    <span>Tag Teman</span>
                    <span>{money(selectedDraftDetail?.tagFriend)}</span>
                  </div>
                  <div className=" justify-between flex mb-2">
                    <span>Catatan</span>
                  </div>
                  <div className=" border border-gray-150 px-12 py-4 rounded-xl text-center">
                    {selectedDraftDetail?.remarks}
                  </div>
                  {!isReviewed && (
                    <>
                      <div className=" justify-between flex mb-2 mt-4">
                        <span>Catatan Penolakan</span>
                      </div>
                      <div className=" border border-gray-150 px-12 py-4 rounded-xl text-center">
                        {selectedDraftDetail?.declinalRemarks}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {isReviewed && (
                <div className="p-4 justify-between flex">
                  <button
                    onClick={() => {
                      setModalDetail(false);
                      setModalRejectOpen(true);
                    }}
                    className="btn-pills"
                  >
                    Tolak
                  </button>
                  <button
                    onClick={() => {
                      setModalDetail(false);
                      setModalPreviewOpen(true);
                    }}
                    className="btn"
                  >
                    Terima
                  </button>
                </div>
              )}
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
      <Drawer
        className="drawer-rounded"
        size={"md"}
        closeButton={null}
        placement={"bottom"}
        open={modalRejectOpen}
        onClose={() => setModalRejectOpen(false)}
      >
        <Drawer.Body style={{ padding: 0 }}>
          <div className="flex flex-col h-full">
            <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white border-b">
              <div className="p-2 "></div>
              <h6>Alasan Penolakan</h6>
              <div className="p-2 ">
                <HiXMark onClick={() => setModalRejectOpen(false)} />
              </div>
            </div>
            <div className="flex flex-1 flex-col">
              <div className="flex-1  overflow-y-auto p-4">
                <div className="flex-1">
                  <ul className="text-base">
                    {remarkList.map((e) => (
                      <li
                        className="flex items-center mb-2"
                        key={randomStr(30)}
                      >
                        <div
                          onClick={() => {
                            setSelectedRemarks([e]);
                          }}
                        >
                          {selectedRemarks.includes(e) ? (
                            <PiCheckCircleFill
                              className=" text-green-400 mr-2"
                              size={16}
                            />
                          ) : (
                            <PiCircleBold className=" mr-2" size={16} />
                          )}
                        </div>
                        {e}
                      </li>
                    ))}
                    {selectedRemarks.includes("Lainnya") && (
                      <li className="pr-4">
                        {" "}
                        <input
                          placeholder="Alasan lainnya ...."
                          className="form-control ml-4"
                          value={otherRemarks}
                          onChange={(el) => {
                            setOtherRemarks(el.target.value);
                          }}
                        />
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="p-4 justify-between flex">
                <button
                  onClick={() => {
                    let req = new DeclineDraftRequest();
                    req.setOrderDraftId(selectedDraftDetail!.id);
                    if (selectedRemarks[0] == "Lainnya") {
                      req.setRemarks(otherRemarks);
                    } else {
                      req.setRemarks(selectedRemarks[0]);
                    }
                    setIsLoading(true);
                    affiliatorOrderClient.declineDraft(
                      req,
                      getToken(),
                      (err, resp) => {
                        setIsLoading(false);
                        if (err) {
                          errorToast(err.message);
                          return;
                        }
                        setOrderStatusMode("");
                        setTimeout(() => {
                          setOrderStatusMode(ORDER_REJECTED);
                          setModalRejectOpen(false);
                        }, 300);
                      }
                    );
                  }}
                  className="btn w-full"
                >
                  Kirim
                </button>
              </div>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
      <Drawer
        className="drawer-rounded"
        size={"full"}
        closeButton={null}
        placement={"bottom"}
        open={modalPreviewOpen}
        onClose={() => setModalPreviewOpen(false)}
      >
        <Drawer.Body style={{ padding: 0 }}>
          <div className="flex flex-col h-full">
            <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white border-b">
              <div className="p-2 ">
                <Back onClick={() => setModalPreviewOpen(false)} />
              </div>
              <h6>Bukti Laporan</h6>
              <div></div>
            </div>
            <div className="flex flex-1 flex-col">
              <div className="flex-1  overflow-y-auto p-4">
                <div className="p-2 ">
                  {cameraOn ? (
                    <div className="flex-1 flex justify-center items-center flex-col">
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                      />
                    </div>
                  ) : (
                    <div className="flex-1 flex justify-center items-center">
                      <div className="flex justify-center flex-col">
                        <h6 className="mb-8 text-center mt-8">
                          Screenshot jumlah komentar
                          <br />
                          sebelum pengerjaan
                        </h6>
                        <div className=" p-24 rounded-3xl border-dashed border-2">
                          <img src={imgUpload} alt="" />
                        </div>
                        <div className="mt-8 flex justify-between">
                          <img
                            onClick={() => {
                              fileRef.current?.click();
                            }}
                            style={{ height: 50 }}
                            className=""
                            src="/assets/button_gallery.png"
                            alt=""
                          />
                          <img
                            onClick={() => {
                              setCameraOn(true);
                            }}
                            style={{ height: 60 }}
                            className=""
                            src="/assets/button_camera.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <input
                    accept="image/*"
                    type="file"
                    className="hidden"
                    ref={fileRef}
                    onChange={(el) => {
                      let files = fileRef.current?.files;
                      if (files) {
                        let reader = new FileReader();

                        reader.onload = function () {
                          // console.log(reader?.result?.toString())
                          setImgUpload(
                            reader?.result?.toString() ?? "/assets/upload.png"
                          );
                        };
                        reader.readAsDataURL(files[0]);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="p-4 justify-between flex">
                <button
                  onClick={() => {
                    if (cameraOn) {
                      capture();
                      return;
                    }
                    setIsLoading(true);
                    let req = new FileBase64Request();
                    req.setFile(imgUpload.split("base64,")[1]);
                    // imgUpload.split()
                    // console.log()
                    clientFile.uploadBase64Temp(
                      req,
                      getToken(),
                      (err, resp) => {
                        if (err) {
                          errorToast(err.message);
                          setIsLoading(false);
                          return;
                        }
                        let path = resp.getData()?.toObject();
                        let req = new CreateOrderRequest();
                        req.setOrderDraftId(selectedDraftDetail!.id);
                        req.setInitialImagePath(path!.path);
                        req.setInitialImagePathType(path!.pathType);
                        setIsLoading(true);
                        affiliatorOrderClient.createOrder(
                          req,
                          getToken(),
                          (err, resp) => {
                            setIsLoading(false);
                            if (err) {
                              errorToast(err.message);
                              return;
                            }
                            successToast(`Order telah di kirim`);
                            setModalPreviewOpen(false);
                            setOrderStatusMode("");
                            setTimeout(() => {
                              setOrderStatusMode(ORDER_PROCESSING);
                            }, 300);
                            setImgUpload("/assets/upload.png");
                          }
                        );
                      }
                    );
                  }}
                  className="btn w-full"
                >
                  {cameraOn ? "Ambil Foto" : "Kirim Laporan"}
                </button>
              </div>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
    </AffiliatorMid>
  );
};
export default AffiliatorOrder;
