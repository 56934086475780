import EmojiPicker from "emoji-picker-react";
import { useContext, useRef, useState, type FC } from "react";
import { HiPaperClip } from "react-icons/hi2";
import { IoMdSend } from "react-icons/io";
import { PiSmiley } from "react-icons/pi";
import { Drawer } from "rsuite";
import { clientFile } from "../App";
import { FileBase64Request } from "../grpc/service/file_pb";
import { getToken } from "../utils/helper";
import { errorToast } from "../utils/helper-ui";
import { LoadingContext } from "../objects/loading_context";

interface ChatBoxProps {
  sendMessage: (s: string, filePath?: string | null) => Promise<void>;
}

const ChatBox: FC<ChatBoxProps> = ({ sendMessage }) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [modalEmoji, setModalEmoji] = useState(false);
  const [msg, setMsg] = useState("");
  const [urlFile, setUrlFile] = useState<string | null>(null);
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [filePath, setFilePath] = useState<string | null>(null);

  return (
    <>
      <div className="flex justify-between p-2 items-center">
        <input
          accept="image/*"
          type="file"
          className="hidden"
          ref={fileRef}
          onChange={(el) => {
            if (el.target.files) {
              let reader = new FileReader();

              reader.onload = function () {
                // console.log(reader?.result?.toString())
                let req = new FileBase64Request();
                req.setFile(`${reader?.result}`.split("base64,")[1]);
                clientFile.uploadBase64Temp(req, getToken(), (err, resp) => {
                  if (err) {
                    errorToast(err.message);
                    setIsLoading(false);
                    return;
                  }
                  let path = resp.getData()?.toObject();
                  setUrlFile(path?.url ?? null);
                  setFilePath(path?.path ?? null);
                });
              };
              reader.readAsDataURL(el.target.files[0]);
            }
          }}
        />
        <div className="flex items-center bg-white py-2 px-4 rounded-full flex-1">
          <PiSmiley
            size={24}
            className=" text-sky-400"
            onClick={() => setModalEmoji(true)}
          />
          <input
            onKeyUp={(el) => {
              if (el.key == "Enter") {
                sendMessage(msg, filePath).then(() => {
                    setMsg("")
                    setFilePath(null)
                    setUrlFile(null)
                });
              }
            }}
            value={msg}
            onChange={(el) => setMsg(el.target.value)}
            placeholder="Tulis Pesan ..."
            className="chatBox"
            type="text"
          />
          <div className="flex flex-row items-center justify-end w-16">
            {urlFile && <img src={urlFile} alt="" className="w-6 h-6 rounded-full object-cover" />}
            <HiPaperClip
              size={24}
              className="  text-gray-400"
              onClick={() => fileRef.current?.click()}
            />
          </div>
        </div>
        <div className=" bg-sky-500 flex justify-center items-center w-10 h-10 rounded-full ml-2">
          <IoMdSend
            onClick={() => sendMessage(msg, filePath).then(() => {
                setMsg("")
                setFilePath(null)
                setUrlFile(null)
            })}
            size={16}
            className=" text-white"
          />
        </div>
      </div>
      <Drawer
        className="drawer-rounded"
        size={"md"}
        placement={"bottom"}
        open={modalEmoji}
        onClose={() => setModalEmoji(false)}
      >
        <Drawer.Body style={{}}>
          <EmojiPicker
            onEmojiClick={(val, ev) => {
              setMsg(msg + val.emoji);
            }}
          />
        </Drawer.Body>
      </Drawer>
    </>
  );
};
export default ChatBox;
