import { useContext, useEffect, useState, type FC } from 'react';
import AdminMid from '../../middlewares/AdminMid';
import Back from '../../components/back';
import { LoadingContext } from '../../objects/loading_context';
import { GetProfileResponseData } from '../../grpc/client/client_general_pb';
import { useNavigate, useParams } from 'react-router-dom';
import { adminAffiliatorClient, adminUserPackageClient } from '../../App';
import { GetAffiliatorListRequest, GetAffiliatorListResponseData, Pagination } from '../../grpc/admin/admin_affiliator_pb';
import { getToken, money } from '../../utils/helper';
import { errorToast, successToast } from '../../utils/helper-ui';
import NoData from '../../components/no_data';
import { FaStar } from 'react-icons/fa';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import Moment from 'react-moment';
import { CheckPicker, Checkbox } from 'rsuite';
import { GetDetailUserPackageRequest, GetDetailUserPackageResponseData, UserPackageOfferToAffiliatorRequest } from '../../grpc/admin/admin_user_package_pb';

interface AdminOrderOfferingProps { }

const AdminOrderOffering: FC<AdminOrderOfferingProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const [mounted, setMounted] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const { packageId } = useParams()
    const [pagination, setPagination] = useState<Pagination.AsObject | null>(null);
    const [data, setData] = useState<GetAffiliatorListResponseData.AsObject[]>([]);
    const [expandedIds, setExpandedIds] = useState<string[]>([]);
    const [detail, setDetail] = useState<GetDetailUserPackageResponseData.AsObject | null | undefined>(null);
    const nav = useNavigate()


    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return
        getDetail()
    }, [mounted]);

    useEffect(() => {
        if (mounted) {
            getData()
        }
    }, [mounted, page, limit]);


    const getDetail = async () => {
        try {
            setIsLoading(false)

            let resp: GetDetailUserPackageResponseData.AsObject = await new Promise((resolve, reject) => {
                let req = new GetDetailUserPackageRequest()
                req.setId(packageId!)
                adminUserPackageClient.getDetailUserPackage(req, getToken(), (err, resp) => {
                    if (err) {
                        reject(err.message)
                        return
                    }
                    resolve(resp.getData()?.toObject()!)
                })
            })
            setDetail(resp)
        } catch (error) {
            errorToast(`${error}`)
        } finally {
            setIsLoading(false)
        }
    }


    const getData = async () => {
        try {
            setIsLoading(true)
            let resp: GetAffiliatorListResponseData.AsObject[] = await new Promise((resolve, reject) => {
                let req = new GetAffiliatorListRequest()
                req.setPage(page)
                req.setLimit(limit)

                adminAffiliatorClient.getAffiliatorList(req, getToken(), (err, resp) => {
                    if (err) {
                        reject(err.message)
                        return
                    }
                    setPagination(resp.getPagination()?.toObject() ?? null)
                    resolve(resp.getDataList().map(e => e.toObject()))
                })
            })
            setData([...data, ...resp])
            // setOrders([...orders, ...resp])
            // console.log(resp)
        } catch (error) {
            errorToast(`${error}`)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <AdminMid noPadding header={<div className='w-full px-4 py-2 justify-between items-center flex pt-6 bg-white'>
            <Back />
            <h6>Pilih Affiliator</h6>
            <div className='p-2 '></div>
        </div>}>
            <div className='flex flex-col px-4'>
                <div className='flex-1'>
                    {data.length == 0 ? <NoData /> :
                        <div className='mt-4'>
                            <div className='flex px-4 py-2 mx-1 my-2 rounded-lg cursor-pointer items-center'>
                                <div className='w-1/12'>
                                    <Checkbox checked={data.length == expandedIds.length} onClick={() => {
                                        if (data.length == expandedIds.length) {
                                            setExpandedIds([])
                                        } else {
                                            setExpandedIds(data.map(e => e.id))
                                        }
                                    }} />
                                </div>
                                <div className='w-5/12 font-bold px-4'>Nama</div>
                                <div className='w-2/12 font-bold text-center'>Client</div>
                                <div className='w-4/12 font-bold text-right'>Rating</div>
                                <div></div>
                            </div>
                            <ul>
                                {data.map(e => <li onClick={() => {
                                    if (expandedIds.includes(e.id)) {
                                        setExpandedIds([...expandedIds.filter(id => !e.id)])
                                    } else {
                                        setExpandedIds([...expandedIds, e.id])
                                    }
                                }} key={e.id}>
                                    <div className='flex px-4 py-2 bg-sky-50 mx-1 my-2 rounded-lg cursor-pointer items-center'>
                                        <div className='w-1/12'>
                                            <Checkbox checked={expandedIds.includes(e.id)} />
                                        </div>
                                        <div className='w-5/12 px-4'>
                                            {e.name}
                                        </div>
                                        <div className='w-2/12 text-center'>
                                            {e.totalClient ? money(e.totalClient) : '-'}
                                        </div>
                                        <div className='w-4/12 text-center flex justify-end items-center ' onClick={() => {
                                            if (expandedIds.includes(e.id)) {
                                                setExpandedIds([...expandedIds.filter(id => !e.id)])
                                            } else {
                                                setExpandedIds([...expandedIds, e.id])
                                            }
                                        }}>
                                            {e.rating ?
                                                <div className='flex justify-center items-center'>
                                                    <FaStar size={8} className='text-orange-300 mr-2' /> {money(e.rating / 2, 2)}

                                                </div>
                                                : ''}

                                        </div>
                                    </div>

                                </li>)}
                            </ul>
                        </div>
                    }
                    <div className=' justify-center items-start flex'>
                        {(pagination?.totalPages ?? 0) > 0 && (pagination?.next ?? 0) > page && <button className='btn-white w-48' onClick={() => {
                            setPage(page + 1)
                        }}>More</button>}

                    </div>

                    <button className='btn my-4 w-full' onClick={() => {
                        setIsLoading(true)
                        let req = new UserPackageOfferToAffiliatorRequest()
                        req.setUserPackageId(packageId!)
                        req.setAffiliatorIdsList(expandedIds)
                        adminUserPackageClient.userPackageOfferToAffiliator(req, getToken(), (err, resp) => {
                            setIsLoading(true)
                            if (err) {
                                errorToast(err.message)
                                return
                            }
                            successToast(`Order telah diteruskan ke affiliator`)
                            nav('/admin/package')
                            // console.log(resp.getData()?.toObject())
                        })
                    }}>Kirim</button>
                </div>
            </div>
        </AdminMid>
    );
}
export default AdminOrderOffering;