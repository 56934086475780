import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NotFound from "../pages/NotFound";
import Login from "../pages/Login";
import Register from "../pages/client/Register";
import Forgot from "../pages/Forgot";
import ChangePassword from "../pages/ChangePassword";
import Join from "../pages/Join";
import RegisterPartner from "../pages/client/RegisterPartner";

interface PublicRoutesProps { }

const PublicRoutes: FC<PublicRoutesProps> = ({ }) => {
    return (
        <Routes>
            {/* http://localhost:3001/join/ref/7xRuoJ5 */}
            
            <Route path='login' element={<Login />} />
            <Route path='forgot' element={<Forgot />} />
            <Route path='/send-new-password/:userId' element={<ChangePassword />} />
            <Route path='/register' element={<Register />} />
            <Route path='/new-partner/:token' element={<RegisterPartner />} />
            {/* <Route path="*"  element={<NotFound />} /> */}
            <Route path='*' element={<Navigate to='/login' replace />} />
            {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
    );
}
export default PublicRoutes;