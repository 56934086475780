/**
 * @fileoverview gRPC-Web generated client stub for commentator_user_package
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: commentator_user_package.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.commentator_user_package = require('./commentator_user_package_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.commentator_user_package.CommentatorUserPackageHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.commentator_user_package.CommentatorUserPackageHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_user_package.GetListUserPackageRequest,
 *   !proto.commentator_user_package.GetListUserPackageResponse>}
 */
const methodDescriptor_CommentatorUserPackageHandler_GetListUserPackage = new grpc.web.MethodDescriptor(
  '/commentator_user_package.CommentatorUserPackageHandler/GetListUserPackage',
  grpc.web.MethodType.UNARY,
  proto.commentator_user_package.GetListUserPackageRequest,
  proto.commentator_user_package.GetListUserPackageResponse,
  /**
   * @param {!proto.commentator_user_package.GetListUserPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_user_package.GetListUserPackageResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_user_package.GetListUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_user_package.GetListUserPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_user_package.GetListUserPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_user_package.CommentatorUserPackageHandlerClient.prototype.getListUserPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_user_package.CommentatorUserPackageHandler/GetListUserPackage',
      request,
      metadata || {},
      methodDescriptor_CommentatorUserPackageHandler_GetListUserPackage,
      callback);
};


/**
 * @param {!proto.commentator_user_package.GetListUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_user_package.GetListUserPackageResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_user_package.CommentatorUserPackageHandlerPromiseClient.prototype.getListUserPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_user_package.CommentatorUserPackageHandler/GetListUserPackage',
      request,
      metadata || {},
      methodDescriptor_CommentatorUserPackageHandler_GetListUserPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_user_package.AcceptUserPackageRequest,
 *   !proto.commentator_user_package.AcceptUserPackageResponse>}
 */
const methodDescriptor_CommentatorUserPackageHandler_AcceptUserPackage = new grpc.web.MethodDescriptor(
  '/commentator_user_package.CommentatorUserPackageHandler/AcceptUserPackage',
  grpc.web.MethodType.UNARY,
  proto.commentator_user_package.AcceptUserPackageRequest,
  proto.commentator_user_package.AcceptUserPackageResponse,
  /**
   * @param {!proto.commentator_user_package.AcceptUserPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_user_package.AcceptUserPackageResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_user_package.AcceptUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_user_package.AcceptUserPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_user_package.AcceptUserPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_user_package.CommentatorUserPackageHandlerClient.prototype.acceptUserPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_user_package.CommentatorUserPackageHandler/AcceptUserPackage',
      request,
      metadata || {},
      methodDescriptor_CommentatorUserPackageHandler_AcceptUserPackage,
      callback);
};


/**
 * @param {!proto.commentator_user_package.AcceptUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_user_package.AcceptUserPackageResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_user_package.CommentatorUserPackageHandlerPromiseClient.prototype.acceptUserPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_user_package.CommentatorUserPackageHandler/AcceptUserPackage',
      request,
      metadata || {},
      methodDescriptor_CommentatorUserPackageHandler_AcceptUserPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_user_package.GetDetailUserPackageRequest,
 *   !proto.commentator_user_package.GetDetailUserPackageResponse>}
 */
const methodDescriptor_CommentatorUserPackageHandler_GetDetailUserPackage = new grpc.web.MethodDescriptor(
  '/commentator_user_package.CommentatorUserPackageHandler/GetDetailUserPackage',
  grpc.web.MethodType.UNARY,
  proto.commentator_user_package.GetDetailUserPackageRequest,
  proto.commentator_user_package.GetDetailUserPackageResponse,
  /**
   * @param {!proto.commentator_user_package.GetDetailUserPackageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_user_package.GetDetailUserPackageResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_user_package.GetDetailUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_user_package.GetDetailUserPackageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_user_package.GetDetailUserPackageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_user_package.CommentatorUserPackageHandlerClient.prototype.getDetailUserPackage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_user_package.CommentatorUserPackageHandler/GetDetailUserPackage',
      request,
      metadata || {},
      methodDescriptor_CommentatorUserPackageHandler_GetDetailUserPackage,
      callback);
};


/**
 * @param {!proto.commentator_user_package.GetDetailUserPackageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_user_package.GetDetailUserPackageResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_user_package.CommentatorUserPackageHandlerPromiseClient.prototype.getDetailUserPackage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_user_package.CommentatorUserPackageHandler/GetDetailUserPackage',
      request,
      metadata || {},
      methodDescriptor_CommentatorUserPackageHandler_GetDetailUserPackage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commentator_user_package.CancelUserPackageCommentatorRequest,
 *   !proto.commentator_user_package.CancelUserPackageCommentatorResponse>}
 */
const methodDescriptor_CommentatorUserPackageHandler_CancelUserPackageCommentator = new grpc.web.MethodDescriptor(
  '/commentator_user_package.CommentatorUserPackageHandler/CancelUserPackageCommentator',
  grpc.web.MethodType.UNARY,
  proto.commentator_user_package.CancelUserPackageCommentatorRequest,
  proto.commentator_user_package.CancelUserPackageCommentatorResponse,
  /**
   * @param {!proto.commentator_user_package.CancelUserPackageCommentatorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commentator_user_package.CancelUserPackageCommentatorResponse.deserializeBinary
);


/**
 * @param {!proto.commentator_user_package.CancelUserPackageCommentatorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commentator_user_package.CancelUserPackageCommentatorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commentator_user_package.CancelUserPackageCommentatorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commentator_user_package.CommentatorUserPackageHandlerClient.prototype.cancelUserPackageCommentator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commentator_user_package.CommentatorUserPackageHandler/CancelUserPackageCommentator',
      request,
      metadata || {},
      methodDescriptor_CommentatorUserPackageHandler_CancelUserPackageCommentator,
      callback);
};


/**
 * @param {!proto.commentator_user_package.CancelUserPackageCommentatorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commentator_user_package.CancelUserPackageCommentatorResponse>}
 *     Promise that resolves to the response
 */
proto.commentator_user_package.CommentatorUserPackageHandlerPromiseClient.prototype.cancelUserPackageCommentator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commentator_user_package.CommentatorUserPackageHandler/CancelUserPackageCommentator',
      request,
      metadata || {},
      methodDescriptor_CommentatorUserPackageHandler_CancelUserPackageCommentator);
};


module.exports = proto.commentator_user_package;

