import { useEffect, useState, type FC } from 'react';

import { HiOutlineCheckCircle } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import { GetProfileResponseData } from '../../grpc/client/client_general_pb';
import { GetListUserPackageRequest, GetUserPackageStatusResponseData } from '../../grpc/client/client_user_package_pb';
import ClientMid from '../../middlewares/ClientMid';
import { ACTIVE, FREE, ORDER_DONE, ORDER_PROCESSING, ORDER_REJECTED, ORDER_REVIEWED, PAID } from '../../utils/contants';
import UpgradePackage from '../../components/upgrade_package';
import ListOrderComment from '../../components/list_comment_order';
import { clientUserPackage } from '../../App';
import { getToken } from '../../utils/helper';
import { GetOrderListRequest } from '../../grpc/client/client_order_pb';
import HeroPackage from '../../components/hero_package';

interface HomeProps { }


const Home: FC<HomeProps> = ({ }) => {
    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const [userPackage, setUserPackage] = useState<GetUserPackageStatusResponseData.AsObject>();
    const [orderModeActive, setOrderModeActive] = useState(ORDER_REVIEWED);

    const nav = useNavigate()
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return

    }, [mounted]);


    // const getUserPackageList = () => {
    //     let req = new GetListUserPackageRequest()
    //     req.setPage(1)
    //     req.setLimit(20)
    //     req.setPaymentStatus(PAID)
    //     clientUserPackage.getListUserPackage(req, getToken(), (err, resp) => {
    //         console.log(resp.getDataList().map(e => e.toObject()))
    //     })
    // }



    return (
        <ClientMid onLoad={setUserPackage} onLoadProfile={setProfile}>
            <HeroPackage userPackage={userPackage} />
            {userPackage?.userPackageStatus != ACTIVE && <UpgradePackage />}

            <h5 className='mb-2'>Status Pesanan Komentar</h5>
            <div className="scrollmenu">
                <div onClick={() => setOrderModeActive(ORDER_REVIEWED)} className={`${orderModeActive == ORDER_REVIEWED && 'active'}`}>Ditinjau</div>
                <div onClick={() => setOrderModeActive(ORDER_PROCESSING)} className={`${orderModeActive == ORDER_PROCESSING && 'active'}`} >Diproses</div>
                <div onClick={() => setOrderModeActive(ORDER_DONE)} className={`${orderModeActive == ORDER_DONE && 'active'}`} >Selesai</div>
                <div onClick={() => setOrderModeActive(ORDER_REJECTED)} className={`${orderModeActive == ORDER_REJECTED && 'active'}`} >Ditolak</div>
            </div>
            <ListOrderComment mode={orderModeActive} />
        </ClientMid>
    );
}
export default Home;