import { useContext, useEffect, useState, type FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Back from '../../components/back';
import { HiOutlineEye, HiXMark } from 'react-icons/hi2';
import { Modal, useToaster } from 'rsuite';
import { LoadingContext } from '../../objects/loading_context';
import { clientAuth, clientGeneral, clientUserPackage, generalClient } from '../../App';
import { RegistrationRequest, RegistrationResponseData } from '../../grpc/auth/auth_pb';
import { error } from 'console';
import { errorToast } from '../../utils/helper-ui';
import { asyncLocalStorage, getToken, setStorageProfile, setStorageUserPackage } from '../../utils/helper';
import { REFRESH_TOKEN, TOKEN, USER_ROLE } from '../../utils/contants';
import { GetProfileRequest } from '../../grpc/client/client_general_pb';
import { GetUserPackageStatusRequest } from '../../grpc/client/client_user_package_pb';
import Loading from '../../components/loading';
import { AddToplineUserRequest } from '../../grpc/service/general_pb';

interface RegisterProps { }

const Register: FC<RegisterProps> = ({ }) => {
    const nav = useNavigate()
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [mounted, setMounted] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [confirm, setConfirm] = useState("");
    const toaster = useToaster();
    const [phone, setPhone] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [regResponse, setRegResponse] = useState<RegistrationResponseData.AsObject>();
    const [referralCode, setReferralCode] = useState("");
    const [open, setOpen] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const [searchParams] = useSearchParams()
    // const [ref, setRef] = useState("");

    useEffect(() => {
        searchParams.get('ref') && setReferralCode(searchParams.get('ref') ?? "")
    }, []);

    const signup = () => {
        if (password.length == 0) {
            errorToast('Password tidak boleh kosong')
            return
        }
        if (password != confirm && password.length > 0) {
            errorToast('Password konfirmasi tidak sama')
            return
        }
        setIsLoading(true)
        let req = new RegistrationRequest()
        req.setName(name)
        req.setPhoneNumber(phone)
        req.setEmail(email)
        req.setPassword(password)
        clientAuth.registration(req, {}, (err, resp) => {
            if (err) {
                errorToast(err.message)
                setIsLoading(false)
                return
            }

            setIsLoading(false)
            setRegResponse(resp.getData()?.toObject())
        })
    }

    useEffect(() => {
        if (regResponse) {
            processReg(regResponse)
        }
    }, [regResponse]);

    const processReg = async (resp: RegistrationResponseData.AsObject) => {
        await asyncLocalStorage.setItem(TOKEN, resp?.accessToken)
        await asyncLocalStorage.setItem(REFRESH_TOKEN, resp?.refreshToken)
        await asyncLocalStorage.setItem(USER_ROLE, resp?.role)
        let profile = await getProfile()
        let userPackage = await await getPackage()

        await setStorageProfile(profile)
        await setStorageUserPackage(userPackage)
        setIsRegistered(true)
        setOpen(true)


    }


    const getProfile = async () => {
        return new Promise((resolve, reject) => {
            clientGeneral.getProfile(new GetProfileRequest(), getToken(), (err, resp) => {
                if (err) {
                    reject(err.message)
                    return
                }
                resolve(resp.getData()?.toObject())
            })
        })

    }
    const getPackage = async () => {
        return new Promise((resolve, reject) => {
            clientUserPackage.getUserPackageStatus(new GetUserPackageStatusRequest(), getToken(), (err, resp) => {
                if (err) {
                    errorToast(err.message)
                    setIsLoading(false)
                    return
                }

                resolve(resp.getData()?.toObject())
            })
        })

    }

    const setReferral = () => {
        setIsLoading(true)
        let req = new AddToplineUserRequest()
        req.setReferralCode(referralCode)
        generalClient.addToplineUser(req, getToken(), (err, resp) => {
            if (err) {
                errorToast(err.message)
                setIsLoading(false)
                return
            }
            // console.log(resp.getData()?.toObject())
            if (regResponse?.isNewUser) {
                window.location.href = "/buy-package"
                return
            }

            window.location.href = "/"
        })
    }

    return (
        <div className='h-screen w-full'>
            <Loading />
            <div className='p-4 w-full'>
                <Back />
                <h3 className=' text-sky-500 mt-4'>Daftar</h3>
                <small>Silahkan isi form pendaftaran dibawah ini</small>
                <div className='mt-8'></div>

                <label className='font-bold mb-1 px-1' htmlFor="">Nama Lengkap</label>
                <div className=' relative'>
                    <input value={name} placeholder="Masukan Nama Lengkap" className='form-control mb-4' onChange={(el) => setName(el.currentTarget.value)} />
                </div>
                <label className='font-bold mb-1 px-1' htmlFor="">Telepon</label>
                <div className=' relative'>
                    <input value={phone} placeholder="Masukan no telp" className='form-control mb-4' onChange={(el) => setPhone(el.currentTarget.value)} />
                </div>
                <label className='font-bold mb-1 px-1' htmlFor="">Email</label>
                <div className=' relative'>
                    <input value={email} type='email' placeholder="Email" className='form-control mb-4' onChange={(el) => setEmail(el.currentTarget.value)} />
                </div>


                <label className='font-bold mb-1 px-1' htmlFor="">Password</label>
                <div className=' relative'>
                    <input value={password} type={showPassword ? 'text' : 'password'} placeholder="Password" className='form-control mb-4' onChange={(el) => setPassword(el.currentTarget.value)} />
                    <HiOutlineEye onClick={() => setShowPassword(!showPassword)} size={20} className={`${showPassword ? 'text-gray-600' : 'text-gray-300'}  absolute top-2 right-2`} />
                </div>
                <label className='font-bold mb-1 px-1' htmlFor="">Ulangi Password</label>
                <div className=' relative'>
                    <input value={confirm} type={showPassword ? 'text' : 'password'} placeholder="Ulangi Password" className='form-control mb-4' onChange={(el) => setConfirm(el.currentTarget.value)} />
                    <HiOutlineEye onClick={() => setShowPassword(!showPassword)} size={20} className={`${showPassword ? 'text-gray-600' : 'text-gray-300'}  absolute top-2 right-2`} />
                </div>
                {!isRegistered ?
                    <button onClick={signup} type="button" className="btn w-full mt-8">Daftar</button>
                    : <button onClick={() => {
                        if (regResponse?.isNewUser) {
                            window.location.href = "/buy-package"
                            return
                        }

                        window.location.href = "/"
                    }} type="button" className="btn w-full mt-8">Masuk </button>
                }

            </div>
            <Modal size={"full"} open={open} onClick={() => setOpen(false)}>
                <Modal.Title>
                    <div className='flex justify-between items-center'>
                        <h3 className=' text-sky-500 '>Pakai Referral?</h3>
                        <HiXMark onClick={() => setOpen(false)} />

                    </div>
                    <p>Masukkan kode Referral yang kamu punya</p>

                </Modal.Title>
                <Modal.Body className='flex  flex-col items-center'>
                    <input value={referralCode} placeholder="Masukan kode referral" className='form-control mb-4' onChange={(el) => setReferralCode(el.currentTarget.value)} />
                    <button onClick={setReferral} type="button" className="btn w-full mt-8">Gunakan Kode</button>
                    <p className='my-4 text-gray-500'>Tidak Punya Refeffal</p>
                    <h5 className='text-sky-500  cursor-pointer' onClick={() => {
                        if (regResponse?.isNewUser) {
                            window.location.href = "/buy-package"
                            return
                        }

                        window.location.href = "/"
                    }}>Lewati </h5>
                </Modal.Body>
            </Modal>
        </div>
    );
}
export default Register;