import { RefObject, useContext, useEffect, useRef, useState, type FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ClientMid from '../../middlewares/ClientMid';
import { GetProfileResponseData } from '../../grpc/client/client_general_pb';
import { ConfirmPaymentUserPackageRequest, GetDetailUserPackageRequest, GetDetailUserPackageResponseData, GetUserPackageStatusResponseData } from '../../grpc/client/client_user_package_pb';
import { clientFile, clientUserPackage } from '../../App';
import { getToken, money } from '../../utils/helper';
import { errorToast, successToast } from '../../utils/helper-ui';
import Back from '../../components/back';
import { ACCOUNT_HOLDER_NAME, ACCOUNT_NUMBER, CANCELED, DECLINED, FAILED, PAID, PENDING_CONFIRMATION } from '../../utils/contants';
import { Drawer, Timeline } from 'rsuite';
import CheckIcon from '@rsuite/icons/legacy/Check';
import { HiClock, HiOutlineClock, HiOutlineWallet } from 'react-icons/hi2';
import moment from 'moment';
import { PiWallet } from 'react-icons/pi';
import React from 'react';
import Webcam from 'react-webcam';
import { FileBase64Request } from '../../grpc/service/file_pb';
import { LoadingContext } from '../../objects/loading_context';

interface PackageOrderConfirmationDetailProps { }

const PackageOrderConfirmationDetail: FC<PackageOrderConfirmationDetailProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);

    const nav = useNavigate()
    const { userPackageId } = useParams()
    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const [userPackage, setUserPackage] = useState<GetUserPackageStatusResponseData.AsObject>();
    const [mounted, setMounted] = useState(false);
    const [data, setData] = useState<GetDetailUserPackageResponseData.AsObject | null | undefined>(null);
    const [open, setOpen] = useState(false);
    const fileRef = useRef<HTMLInputElement>(null)
    const [imgUpload, setImgUpload] = useState("/assets/upload.png");
    const [cameraOn, setCameraOn] = useState(false);

    const webcamRef = useRef<Webcam>(null)
    const capture = React.useCallback(
        () => {
            if (!webcamRef) return
            const imageSrc = webcamRef?.current?.getScreenshot()

            setImgUpload(imageSrc ?? "/assets/upload.png")
            setCameraOn(false)
        },
        [webcamRef]
    );

    const videoConstraints = {
    };


    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted && !userPackageId) return

        getDetail()
    }, [mounted]);

    const getDetail = async () => {
        let req = new GetDetailUserPackageRequest()
        req.setId(userPackageId!)
        clientUserPackage.getDetailUserPackage(req, getToken(), (err, resp) => {
            if (err) {
                errorToast(err.message)
                return
            }
            setData(resp.getData()?.toObject())
        })
    }

    return (<ClientMid onLoad={setUserPackage} onLoadProfile={setProfile} noPadding={true} hideMenu header={
        <div className='w-full px-4 py-2 justify-between items-center flex pt-6 bg-white'>
            <Back />
            <h6>Rincian</h6>
            <div className='p-2 '></div>
        </div>}>

        <div className='flex flex-col h-full'>
            <div className='p-6 flex-1'>
                <div className=' bg-white rounded-xl mb-6 p-4 '>

                    <div className='pb-2 border-b border-b-gray-100'>
                        <h5>Rincian Pesanan</h5>
                    </div>
                    <div className='flex justify-between py-2'>
                        <span className=''>Komentar per hari</span>
                        <span>{money(data?.commentPerDay)} / Hari</span>
                    </div>
                    <div className='flex justify-between py-2'>
                        <span className=''>Jumlah Hari</span>
                        <span>{money(30)}</span>
                    </div>
                    <div className='flex justify-between py-2 border-t'>
                        <span className=' font-bold'>Total Komentar</span>
                        <span>{money(data?.totalComment)}</span>
                    </div>
                    <div className='flex justify-between py-2'>
                        <span className=' text-sky-500'>+ Bonus Likes</span>
                        <span className='text-sky-500'>{money(data?.totalLikes)}</span>
                    </div>
                    <div className='flex justify-between py-2'>
                        <span className=' text-sky-500'>+ Bonus Shares</span>
                        <span className='text-sky-500'>{money(data?.totalShares)}</span>
                    </div>
                    <div className='flex justify-between py-2 border-b'>
                        <span className=' font-bold'>Harga per Komentar</span>
                        <span className=' font-semibold'>Rp. {money(data?.pricePerComment)}</span>
                    </div>
                    <div className='flex justify-between py-2'>
                        <span className=' font-bold'>Total</span>
                        <span className=' font-semibold'>Rp. {money(data?.totalPrice)}</span>
                    </div>
                </div>
                <div className=' bg-white rounded-xl mb-6 p-4 relative'>
                    <div className='flex mb-4'>
                        <HiOutlineClock size={24} className='mr-2' />
                        <div>
                            <h6>Bayar Sebelum</h6>
                            <p className=' text-base mb-0'>{moment.utc(data?.paymentExpiryTime).format("HH:mm")}</p>
                            <p className='text-gray-400 m-0'>Selesaikan transaksi Anda dalam 24 jam</p>
                        </div>
                    </div>
                    <div className='flex mb-4'>
                        <PiWallet size={24} className='mr-2' />
                        <div className='w-full'>
                            <h6>Transfer Ke</h6>
                            <div className='flex justify-between w-full'>
                                <span>
                                    {ACCOUNT_HOLDER_NAME}
                                </span>
                                <img className='w-16' src="/assets/logo_bca.png" alt="" />
                            </div>
                            <div className='px-4 py-2 rounded-lg bg-gray-100 mt-2 justify-between items-center flex'>
                                <small className=' text-xl font-semibold text-black'>
                                    {ACCOUNT_NUMBER}
                                </small>
                                <small className='text-blue-500 text-base cursor-pointer' onClick={() => {
                                    navigator.clipboard.writeText(ACCOUNT_NUMBER);
                                    successToast("No. Rekening berhasil di salin ke clipboard")
                                }}  >Salin</small>
                            </div>
                            <h6 className='mt-4'>Jumlah Pembayaran</h6>
                            {data?.totalPrice &&
                                <div className='px-4 py-2 rounded-lg bg-gray-100  justify-between items-center flex mb-8'>
                                    <small className=' text-xl font-semibold text-black'>
                                        Rp. {money(data?.totalPrice).split("").slice(0, -3)}<span className=' text-orange-400'>{money(data?.totalPrice).split("").slice(Math.max(money(data?.totalPrice).split("").length - 3, 1))}</span>
                                    </small>
                                    <small className='text-blue-500 text-base cursor-pointer' onClick={() => {
                                        navigator.clipboard.writeText(`${data?.totalPrice}`);
                                        successToast("Jumlah Pembayaran berhasil di salin ke clipboard")
                                    }}  >Salin</small>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='absolute bottom-0 left-0 right-0 py-2 px-4  w-full bg-yellow-300 rounded-b-xl text-black font-semibold'>
                        PENTING! Silakan transfer jumlah yang tepat (termasuk 3 digit terakhir)
                    </div>
                </div>
            </div>

            <div className='p-4'>
                <button onClick={() => {
                    setOpen(true)
                }} type="button" className="btn w-full ">Saya Sudah Bayar</button>

            </div>
        </div>

        <Drawer className='drawer-rounded' size={'full'} placement={"bottom"} open={open} onClose={() => {
            setOpen(false)

        }}>
            <Drawer.Header>
                <Drawer.Title as={"div"}>
                    <h5 className='mt-1'>Pembayaran</h5>
                </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body className=' flex flex-col' style={{ padding: 20 }}>
                {cameraOn ? <div className='flex-1 flex justify-center items-center flex-col'>
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                    />

                </div>
                    : <div className='flex-1 flex justify-center items-center'>
                        <div className='flex justify-center flex-col'>
                            <h6 className='mb-8 text-center'>Unggah bukti pembayaran</h6>
                            <div className=' p-24 rounded-3xl border-dashed border-2'>
                                <img src={imgUpload} alt="" />
                            </div>
                            <div className='mt-8 flex justify-between'>
                                <img onClick={() => {
                                    fileRef.current?.click()
                                }} style={{ height: 50 }} className='' src="/assets/button_gallery.png" alt="" />
                                <img onClick={() => {
                                    setCameraOn(true)
                                }} style={{ height: 60 }} className='' src="/assets/button_camera.png" alt="" />
                            </div>
                        </div>
                    </div>}

                <input accept='image/*' type="file" className='hidden' ref={fileRef} onChange={(el) => {
                    let files = fileRef.current?.files
                    if (files) {
                        let reader = new FileReader();

                        reader.onload = function () {

                            setImgUpload(reader?.result?.toString() ?? "/assets/upload.png")
                        }
                        reader.readAsDataURL(files[0]);
                    }
                }} />
                <button onClick={() => {
                    if (cameraOn) {
                        capture()
                        return
                    }
                    setIsLoading(true)
                    let req = new FileBase64Request()
                    req.setFile(imgUpload.split("base64,")[1])
                    // imgUpload.split()
                    // console.log()
                    clientFile.uploadBase64Temp(req, getToken(), (err, resp) => {
                        if (err) {
                            errorToast(err.message)
                            setIsLoading(false)
                            return
                        }
                        // console.log(resp.getData()?.toObject())
                        let reqPkg = new ConfirmPaymentUserPackageRequest()
                        reqPkg.setReceiptPath(resp.getData()?.toObject()?.path ?? "")
                        reqPkg.setReceiptPathType(resp.getData()?.toObject()?.pathType ?? "")
                        reqPkg.setUserPackageId(data?.id ?? "")

                        clientUserPackage.confirmPaymentUserPackage(reqPkg, getToken(), (err, resp) => {
                            if (err) {
                                errorToast(err.message)
                                setIsLoading(false)
                                return
                            }
                            setIsLoading(false)
                            nav(`/order/processing/${data?.id}`)
                        })

                        
                    })
                }} type="button" className="btn">{cameraOn ? 'Ambil Foto' : "Lanjutkan"}</button>
            </Drawer.Body>

        </Drawer>
    </ClientMid>
    );
}
export default PackageOrderConfirmationDetail;