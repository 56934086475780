import { useContext, useEffect, useState, type FC } from "react";
import ProfileMid from "../middlewares/ProfileMid";
import { LoadingContext } from "../objects/loading_context";
import { useNavigate, useParams } from "react-router-dom";
import { GetProfileResponseData } from "../grpc/client/client_general_pb";
import Back from "../components/back";
import {
  affiliatorGeneralClient,
  affiliatorWithdrawClient,
  commentatorGeneralClient,
  commentatorWithdrawClient,
} from "../App";
import { getToken, money } from "../utils/helper";
import {
  WithdrawRequestDetailRequest,
  WithdrawRequestDetailResponseData,
} from "../grpc/commentator/commentator_withdraw_request_pb";
import { errorToast, successToast } from "../utils/helper-ui";
import {
  CONFIRM,
  DONE,
  IN_PROGRESS,
  PENDING_CONFIRMATION,
} from "../utils/contants";
import CheckIcon from "@rsuite/icons/legacy/Check";
import { Drawer, Timeline } from "rsuite";
import Moment from "react-moment";

interface WithdrawalRequestDetailProps {
  isAffiliator?: boolean;
  isCommentator?: boolean;
  isMarketing?: boolean;
}

const WithdrawalRequestDetail: FC<WithdrawalRequestDetailProps> = ({
  isAffiliator,
  isCommentator,
  isMarketing,
}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const nav = useNavigate();
  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const { withdrawId } = useParams();
  const [data, setData] =
    useState<WithdrawRequestDetailResponseData.AsObject>();

  const [modalTransfer, setModalTransfer] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getDetail();
  }, [mounted, withdrawId]);

  const confirmWithdraw = () => {
    setIsLoading(true);

    let req = new WithdrawRequestDetailRequest();
    req.setId(withdrawId!);
    if (isAffiliator) {
      affiliatorWithdrawClient.confirmWithdrawRequest(
        req,
        getToken(),
        (err, resp) => {
          setIsLoading(false);
          if (err) {
            errorToast(err.message);
            return;
          }
          successToast("Withdraw request confirmed");
          setModalTransfer(false)
          nav(-1)
        }
      );
    }
    if (isCommentator) {
      commentatorWithdrawClient.confirmWithdrawRequest(
        req,
        getToken(),
        (err, resp) => {
          setIsLoading(false);
          if (err) {
            errorToast(err.message);
            return;
          }
          successToast("Withdraw request confirmed");
        }
      );
    }
  };
  const getDetail = () => {
    setIsLoading(true);

    let req = new WithdrawRequestDetailRequest();
    req.setId(withdrawId!);
    if (isAffiliator) {
      affiliatorWithdrawClient.withdrawRequestDetail(
        req,
        getToken(),
        (err, resp) => {
          setIsLoading(false);
          if (err) {
            errorToast(err.message);
            return;
          }
          setData(resp.getData()?.toObject());
        }
      );
    }
    if (isCommentator) {
      commentatorWithdrawClient.withdrawRequestDetail(
        req,
        getToken(),
        (err, resp) => {
          setIsLoading(false);
          if (err) {
            errorToast(err.message);
            return;
          }
          setData(resp.getData()?.toObject());
        }
      );
    }
  };

  useEffect(() => {
    // console.log(data);
  }, [data]);

  return (
    <ProfileMid onLoad={(val) => setProfile(val)}>
      <div className="flex flex-col h-full">
        <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white">
          <Back />
          <h6>Detail Penarikan</h6>
          <div className="p-2"></div>
        </div>
        {data && (
          <>
            <div
              className="w-full  bg-slate-200 pt-9 pb-12 px-6 flex justify-between text-white items-center"
              style={{
                backgroundImage: "url(/assets/bg.png)",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: 0,
                backgroundPositionY: -100,
                backgroundSize: "cover",
              }}
            >
              <p className="text-white">{data.status == IN_PROGRESS}</p>
              <div>
                <h5 className="mb-2">
                  {data?.status == IN_PROGRESS && "Penarikan sedang diproses"}
                  {data?.status == CONFIRM && "Penarikan disetujui"}
                  {data?.status == DONE && "Penarikan Selesai"}
                </h5>
                <p className=" text-xs">
                  {data?.status == IN_PROGRESS &&
                    `Mohon tunggu beberapa saat sampai penarikan telah disetujui`}
                  {data?.status == CONFIRM &&
                    `Mohon tunggu beberapa saat sampai penarikan berhasil`}
                </p>
              </div>
              <div className="ml-4">
                <img
                  src={
                    data?.status == DONE
                      ? `/assets/payment_paid.png`
                      : `/assets/payment_pending.png`
                  }
                  alt=""
                  className="w-10"
                />
              </div>
            </div>
            <div className=" rounded-t-xl -mt-6 bg-white mb-1">
              <div className="p-4 border-b border-b-gray-100">
                <h5>Status Pembayaran</h5>
              </div>
              <div className="p-4 border-b border-b-gray-100">
                <Timeline className="custom-timeline">
                  {data.logsList.map((e) => {
                    return (
                      <Timeline.Item
                        key={e.status}
                        dot={
                          <CheckIcon
                            style={
                              e?.createdAt != ""
                                ? {
                                    background: "rgb(14,165,233)",
                                    color: "#fff",
                                  }
                                : {}
                            }
                          />
                        }
                      >
                        {e.status == IN_PROGRESS && (
                          <p>Penarikan Fee diajukan</p>
                        )}
                        {e.status == PENDING_CONFIRMATION && (
                          <p>Penarikan disetujui</p>
                        )}
                        {e.status == DONE && <p>Penarikan Selesai</p>}

                        {e.createdAt && (
                          <Moment
                            format="DD MMM YYYY, HH:mm"
                            className="text-xs"
                          >
                            {e.createdAt}
                          </Moment>
                        )}
                      </Timeline.Item>
                    );
                  })}
                </Timeline>
              </div>
            </div>
            <div className=" bg-white mb-1">
              <div className="p-4 border-b border-b-gray-100">
                <h5>Ringkasan Penarikan`</h5>
              </div>
              <div className="p-4 border-b border-b-gray-100">
                <div className="flex justify-between py-2">
                  <span className="">Diajukan Pada</span>
                  <Moment format="DD MMM YYYY" className="text-sm">
                    {data.createdAt}
                  </Moment>
                </div>
                <div className="flex justify-between py-2">
                  <span className="">Jumlah</span>
                  <span>{money(data.amount)}</span>
                </div>
                <div className="flex justify-between py-2">
                  <span className="">Penarikan Fee Ke</span>
                  <span>
                    {data.userFinancialAccountType}{" "}
                    {data?.userFinancialAccountNumber}
                  </span>
                </div>
                <div className="flex justify-between py-2">
                  <span className="">Atas Nama</span>
                  <span>{data.userFinancialAccountName}</span>
                </div>
                <div className="flex justify-between py-2">
                  <span className="">Estimasi Waktu</span>
                  <span>2 x 24 Jam</span>
                </div>
              </div>
            </div>
            {data.status == PENDING_CONFIRMATION && (
              <div className="p-4">
                <button
                  onClick={() => {
                    setModalTransfer(true);
                  }}
                  type="button"
                  className="btn w-full "
                >
                  Cek Bukti Transfer
                </button>
              </div>
            )}
          </>
        )}
      </div>
      <Drawer
        className="drawer-rounded"
        size={"md"}
        placement={"bottom"}
        open={modalTransfer}
        onClose={() => setModalTransfer(false)}
      >
        <Drawer.Body
          style={{
            minHeight: "50%",
          }}
        >
          <div className="flex justify-center flex-col">
            <img src={data?.receiptUrl} alt="" />
            <button onClick={() => confirmWithdraw()} type="button" className="btn w-full mt-4">
              Transfer Diterima
            </button>
          </div>
        </Drawer.Body>
      </Drawer>
    </ProfileMid>
  );
};
export default WithdrawalRequestDetail;
