// source: admin_withdraw_request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.admin_withdraw_request.ApproveWithdrawRequestRequest', null, global);
goog.exportSymbol('proto.admin_withdraw_request.ApproveWithdrawRequestResponse', null, global);
goog.exportSymbol('proto.admin_withdraw_request.ApproveWithdrawRequestResponseData', null, global);
goog.exportSymbol('proto.admin_withdraw_request.DeclineWithdrawRequestRequest', null, global);
goog.exportSymbol('proto.admin_withdraw_request.DeclineWithdrawRequestResponse', null, global);
goog.exportSymbol('proto.admin_withdraw_request.DeclineWithdrawRequestResponseData', null, global);
goog.exportSymbol('proto.admin_withdraw_request.HasInProgressWithdrawRequest', null, global);
goog.exportSymbol('proto.admin_withdraw_request.HasInProgressWithdrawResponse', null, global);
goog.exportSymbol('proto.admin_withdraw_request.HasInProgressWithdrawResponseData', null, global);
goog.exportSymbol('proto.admin_withdraw_request.Meta', null, global);
goog.exportSymbol('proto.admin_withdraw_request.Pagination', null, global);
goog.exportSymbol('proto.admin_withdraw_request.WithdrawRequestCountRequest', null, global);
goog.exportSymbol('proto.admin_withdraw_request.WithdrawRequestCountResponse', null, global);
goog.exportSymbol('proto.admin_withdraw_request.WithdrawRequestCountResponseData', null, global);
goog.exportSymbol('proto.admin_withdraw_request.WithdrawRequestListRequest', null, global);
goog.exportSymbol('proto.admin_withdraw_request.WithdrawRequestListResponse', null, global);
goog.exportSymbol('proto.admin_withdraw_request.WithdrawRequestListResponseData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.Meta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_withdraw_request.Meta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.Meta.displayName = 'proto.admin_withdraw_request.Meta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.Pagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_withdraw_request.Pagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.Pagination.displayName = 'proto.admin_withdraw_request.Pagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.WithdrawRequestListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_withdraw_request.WithdrawRequestListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.WithdrawRequestListRequest.displayName = 'proto.admin_withdraw_request.WithdrawRequestListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_withdraw_request.WithdrawRequestListResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.WithdrawRequestListResponseData.displayName = 'proto.admin_withdraw_request.WithdrawRequestListResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.WithdrawRequestListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin_withdraw_request.WithdrawRequestListResponse.repeatedFields_, null);
};
goog.inherits(proto.admin_withdraw_request.WithdrawRequestListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.WithdrawRequestListResponse.displayName = 'proto.admin_withdraw_request.WithdrawRequestListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.WithdrawRequestCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_withdraw_request.WithdrawRequestCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.WithdrawRequestCountRequest.displayName = 'proto.admin_withdraw_request.WithdrawRequestCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.WithdrawRequestCountResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_withdraw_request.WithdrawRequestCountResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.WithdrawRequestCountResponseData.displayName = 'proto.admin_withdraw_request.WithdrawRequestCountResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.WithdrawRequestCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_withdraw_request.WithdrawRequestCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.WithdrawRequestCountResponse.displayName = 'proto.admin_withdraw_request.WithdrawRequestCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.HasInProgressWithdrawRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_withdraw_request.HasInProgressWithdrawRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.HasInProgressWithdrawRequest.displayName = 'proto.admin_withdraw_request.HasInProgressWithdrawRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_withdraw_request.HasInProgressWithdrawResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.HasInProgressWithdrawResponseData.displayName = 'proto.admin_withdraw_request.HasInProgressWithdrawResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_withdraw_request.HasInProgressWithdrawResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.HasInProgressWithdrawResponse.displayName = 'proto.admin_withdraw_request.HasInProgressWithdrawResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.ApproveWithdrawRequestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_withdraw_request.ApproveWithdrawRequestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.ApproveWithdrawRequestRequest.displayName = 'proto.admin_withdraw_request.ApproveWithdrawRequestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_withdraw_request.ApproveWithdrawRequestResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.ApproveWithdrawRequestResponseData.displayName = 'proto.admin_withdraw_request.ApproveWithdrawRequestResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_withdraw_request.ApproveWithdrawRequestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.ApproveWithdrawRequestResponse.displayName = 'proto.admin_withdraw_request.ApproveWithdrawRequestResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.DeclineWithdrawRequestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_withdraw_request.DeclineWithdrawRequestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.DeclineWithdrawRequestRequest.displayName = 'proto.admin_withdraw_request.DeclineWithdrawRequestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_withdraw_request.DeclineWithdrawRequestResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.DeclineWithdrawRequestResponseData.displayName = 'proto.admin_withdraw_request.DeclineWithdrawRequestResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_withdraw_request.DeclineWithdrawRequestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_withdraw_request.DeclineWithdrawRequestResponse.displayName = 'proto.admin_withdraw_request.DeclineWithdrawRequestResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.Meta.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.Meta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.Meta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.Meta.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.Meta}
 */
proto.admin_withdraw_request.Meta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.Meta;
  return proto.admin_withdraw_request.Meta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.Meta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.Meta}
 */
proto.admin_withdraw_request.Meta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.Meta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.Meta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.Meta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.Meta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.admin_withdraw_request.Meta.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.Meta} returns this
 */
proto.admin_withdraw_request.Meta.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 status = 2;
 * @return {number}
 */
proto.admin_withdraw_request.Meta.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_withdraw_request.Meta} returns this
 */
proto.admin_withdraw_request.Meta.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.admin_withdraw_request.Meta.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.Meta} returns this
 */
proto.admin_withdraw_request.Meta.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.Pagination.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.Pagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.Pagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.Pagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    prev: jspb.Message.getFieldWithDefault(msg, 3, 0),
    next: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.Pagination}
 */
proto.admin_withdraw_request.Pagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.Pagination;
  return proto.admin_withdraw_request.Pagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.Pagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.Pagination}
 */
proto.admin_withdraw_request.Pagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrev(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNext(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPages(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.Pagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.Pagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.Pagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.Pagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPrev();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNext();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.admin_withdraw_request.Pagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_withdraw_request.Pagination} returns this
 */
proto.admin_withdraw_request.Pagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.admin_withdraw_request.Pagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_withdraw_request.Pagination} returns this
 */
proto.admin_withdraw_request.Pagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 prev = 3;
 * @return {number}
 */
proto.admin_withdraw_request.Pagination.prototype.getPrev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_withdraw_request.Pagination} returns this
 */
proto.admin_withdraw_request.Pagination.prototype.setPrev = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 next = 4;
 * @return {number}
 */
proto.admin_withdraw_request.Pagination.prototype.getNext = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_withdraw_request.Pagination} returns this
 */
proto.admin_withdraw_request.Pagination.prototype.setNext = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 total_pages = 5;
 * @return {number}
 */
proto.admin_withdraw_request.Pagination.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_withdraw_request.Pagination} returns this
 */
proto.admin_withdraw_request.Pagination.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 total_records = 6;
 * @return {number}
 */
proto.admin_withdraw_request.Pagination.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_withdraw_request.Pagination} returns this
 */
proto.admin_withdraw_request.Pagination.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.WithdrawRequestListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.WithdrawRequestListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.WithdrawRequestListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.WithdrawRequestListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userPackageId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.WithdrawRequestListRequest}
 */
proto.admin_withdraw_request.WithdrawRequestListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.WithdrawRequestListRequest;
  return proto.admin_withdraw_request.WithdrawRequestListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.WithdrawRequestListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.WithdrawRequestListRequest}
 */
proto.admin_withdraw_request.WithdrawRequestListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPackageId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.WithdrawRequestListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.WithdrawRequestListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.WithdrawRequestListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.WithdrawRequestListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserPackageId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.admin_withdraw_request.WithdrawRequestListRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListRequest} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.admin_withdraw_request.WithdrawRequestListRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListRequest} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.admin_withdraw_request.WithdrawRequestListRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListRequest} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_package_id = 4;
 * @return {string}
 */
proto.admin_withdraw_request.WithdrawRequestListRequest.prototype.getUserPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListRequest} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListRequest.prototype.setUserPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.admin_withdraw_request.WithdrawRequestListRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListRequest} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.WithdrawRequestListResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.WithdrawRequestListResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userRole: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userProfilePictureUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    userFinancialAccountType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    userFinancialAccountNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    userFinancialAccountName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    statusRemarks: jspb.Message.getFieldWithDefault(msg, 9, ""),
    isMarketing: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponseData}
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.WithdrawRequestListResponseData;
  return proto.admin_withdraw_request.WithdrawRequestListResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.WithdrawRequestListResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponseData}
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserRole(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserProfilePictureUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserFinancialAccountType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserFinancialAccountNumber(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserFinancialAccountName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusRemarks(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMarketing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.WithdrawRequestListResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.WithdrawRequestListResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserRole();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getUserFinancialAccountType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUserFinancialAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUserFinancialAccountName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStatusRemarks();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIsMarketing();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_role = 3;
 * @return {string}
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.getUserRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.setUserRole = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_profile_picture_url = 4;
 * @return {string}
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.getUserProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.setUserProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string created_at = 5;
 * @return {string}
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double amount = 6;
 * @return {number}
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string user_financial_account_type = 7;
 * @return {string}
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.getUserFinancialAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.setUserFinancialAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string user_financial_account_number = 8;
 * @return {string}
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.getUserFinancialAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.setUserFinancialAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string user_financial_account_name = 11;
 * @return {string}
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.getUserFinancialAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.setUserFinancialAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string status_remarks = 9;
 * @return {string}
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.getStatusRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.setStatusRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool is_marketing = 10;
 * @return {boolean}
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.getIsMarketing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponseData} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListResponseData.prototype.setIsMarketing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin_withdraw_request.WithdrawRequestListResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.WithdrawRequestListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.WithdrawRequestListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.WithdrawRequestListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.WithdrawRequestListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_withdraw_request.Meta.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.admin_withdraw_request.Pagination.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.admin_withdraw_request.WithdrawRequestListResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponse}
 */
proto.admin_withdraw_request.WithdrawRequestListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.WithdrawRequestListResponse;
  return proto.admin_withdraw_request.WithdrawRequestListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.WithdrawRequestListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponse}
 */
proto.admin_withdraw_request.WithdrawRequestListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_withdraw_request.Meta;
      reader.readMessage(value,proto.admin_withdraw_request.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_withdraw_request.Pagination;
      reader.readMessage(value,proto.admin_withdraw_request.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.admin_withdraw_request.WithdrawRequestListResponseData;
      reader.readMessage(value,proto.admin_withdraw_request.WithdrawRequestListResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.WithdrawRequestListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.WithdrawRequestListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.WithdrawRequestListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.WithdrawRequestListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_withdraw_request.Meta.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_withdraw_request.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.admin_withdraw_request.WithdrawRequestListResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_withdraw_request.Meta}
 */
proto.admin_withdraw_request.WithdrawRequestListResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_withdraw_request.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_withdraw_request.Meta, 1));
};


/**
 * @param {?proto.admin_withdraw_request.Meta|undefined} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponse} returns this
*/
proto.admin_withdraw_request.WithdrawRequestListResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponse} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_withdraw_request.WithdrawRequestListResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.admin_withdraw_request.Pagination}
 */
proto.admin_withdraw_request.WithdrawRequestListResponse.prototype.getPagination = function() {
  return /** @type{?proto.admin_withdraw_request.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.admin_withdraw_request.Pagination, 2));
};


/**
 * @param {?proto.admin_withdraw_request.Pagination|undefined} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponse} returns this
*/
proto.admin_withdraw_request.WithdrawRequestListResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponse} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_withdraw_request.WithdrawRequestListResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated WithdrawRequestListResponseData data = 3;
 * @return {!Array<!proto.admin_withdraw_request.WithdrawRequestListResponseData>}
 */
proto.admin_withdraw_request.WithdrawRequestListResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.admin_withdraw_request.WithdrawRequestListResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin_withdraw_request.WithdrawRequestListResponseData, 3));
};


/**
 * @param {!Array<!proto.admin_withdraw_request.WithdrawRequestListResponseData>} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponse} returns this
*/
proto.admin_withdraw_request.WithdrawRequestListResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.admin_withdraw_request.WithdrawRequestListResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponseData}
 */
proto.admin_withdraw_request.WithdrawRequestListResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.admin_withdraw_request.WithdrawRequestListResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin_withdraw_request.WithdrawRequestListResponse} returns this
 */
proto.admin_withdraw_request.WithdrawRequestListResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.WithdrawRequestCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.WithdrawRequestCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.WithdrawRequestCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.WithdrawRequestCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userPackageId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.WithdrawRequestCountRequest}
 */
proto.admin_withdraw_request.WithdrawRequestCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.WithdrawRequestCountRequest;
  return proto.admin_withdraw_request.WithdrawRequestCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.WithdrawRequestCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.WithdrawRequestCountRequest}
 */
proto.admin_withdraw_request.WithdrawRequestCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPackageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.WithdrawRequestCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.WithdrawRequestCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.WithdrawRequestCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.WithdrawRequestCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserPackageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_package_id = 1;
 * @return {string}
 */
proto.admin_withdraw_request.WithdrawRequestCountRequest.prototype.getUserPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestCountRequest} returns this
 */
proto.admin_withdraw_request.WithdrawRequestCountRequest.prototype.setUserPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.WithdrawRequestCountResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.WithdrawRequestCountResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.WithdrawRequestCountResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.WithdrawRequestCountResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    toDo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    inProgress: jspb.Message.getFieldWithDefault(msg, 2, 0),
    declined: jspb.Message.getFieldWithDefault(msg, 3, 0),
    done: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.WithdrawRequestCountResponseData}
 */
proto.admin_withdraw_request.WithdrawRequestCountResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.WithdrawRequestCountResponseData;
  return proto.admin_withdraw_request.WithdrawRequestCountResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.WithdrawRequestCountResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.WithdrawRequestCountResponseData}
 */
proto.admin_withdraw_request.WithdrawRequestCountResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setToDo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setInProgress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeclined(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.WithdrawRequestCountResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.WithdrawRequestCountResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.WithdrawRequestCountResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.WithdrawRequestCountResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToDo();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getInProgress();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDeclined();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getDone();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 to_do = 1;
 * @return {number}
 */
proto.admin_withdraw_request.WithdrawRequestCountResponseData.prototype.getToDo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestCountResponseData} returns this
 */
proto.admin_withdraw_request.WithdrawRequestCountResponseData.prototype.setToDo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 in_progress = 2;
 * @return {number}
 */
proto.admin_withdraw_request.WithdrawRequestCountResponseData.prototype.getInProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestCountResponseData} returns this
 */
proto.admin_withdraw_request.WithdrawRequestCountResponseData.prototype.setInProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 declined = 3;
 * @return {number}
 */
proto.admin_withdraw_request.WithdrawRequestCountResponseData.prototype.getDeclined = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestCountResponseData} returns this
 */
proto.admin_withdraw_request.WithdrawRequestCountResponseData.prototype.setDeclined = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 done = 4;
 * @return {number}
 */
proto.admin_withdraw_request.WithdrawRequestCountResponseData.prototype.getDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestCountResponseData} returns this
 */
proto.admin_withdraw_request.WithdrawRequestCountResponseData.prototype.setDone = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.WithdrawRequestCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.WithdrawRequestCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.WithdrawRequestCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.WithdrawRequestCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_withdraw_request.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_withdraw_request.WithdrawRequestCountResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.WithdrawRequestCountResponse}
 */
proto.admin_withdraw_request.WithdrawRequestCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.WithdrawRequestCountResponse;
  return proto.admin_withdraw_request.WithdrawRequestCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.WithdrawRequestCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.WithdrawRequestCountResponse}
 */
proto.admin_withdraw_request.WithdrawRequestCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_withdraw_request.Meta;
      reader.readMessage(value,proto.admin_withdraw_request.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_withdraw_request.WithdrawRequestCountResponseData;
      reader.readMessage(value,proto.admin_withdraw_request.WithdrawRequestCountResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.WithdrawRequestCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.WithdrawRequestCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.WithdrawRequestCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.WithdrawRequestCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_withdraw_request.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_withdraw_request.WithdrawRequestCountResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_withdraw_request.Meta}
 */
proto.admin_withdraw_request.WithdrawRequestCountResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_withdraw_request.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_withdraw_request.Meta, 1));
};


/**
 * @param {?proto.admin_withdraw_request.Meta|undefined} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestCountResponse} returns this
*/
proto.admin_withdraw_request.WithdrawRequestCountResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_withdraw_request.WithdrawRequestCountResponse} returns this
 */
proto.admin_withdraw_request.WithdrawRequestCountResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_withdraw_request.WithdrawRequestCountResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional WithdrawRequestCountResponseData data = 2;
 * @return {?proto.admin_withdraw_request.WithdrawRequestCountResponseData}
 */
proto.admin_withdraw_request.WithdrawRequestCountResponse.prototype.getData = function() {
  return /** @type{?proto.admin_withdraw_request.WithdrawRequestCountResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_withdraw_request.WithdrawRequestCountResponseData, 2));
};


/**
 * @param {?proto.admin_withdraw_request.WithdrawRequestCountResponseData|undefined} value
 * @return {!proto.admin_withdraw_request.WithdrawRequestCountResponse} returns this
*/
proto.admin_withdraw_request.WithdrawRequestCountResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_withdraw_request.WithdrawRequestCountResponse} returns this
 */
proto.admin_withdraw_request.WithdrawRequestCountResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_withdraw_request.WithdrawRequestCountResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.HasInProgressWithdrawRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.HasInProgressWithdrawRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.HasInProgressWithdrawRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.HasInProgressWithdrawRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.HasInProgressWithdrawRequest}
 */
proto.admin_withdraw_request.HasInProgressWithdrawRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.HasInProgressWithdrawRequest;
  return proto.admin_withdraw_request.HasInProgressWithdrawRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.HasInProgressWithdrawRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.HasInProgressWithdrawRequest}
 */
proto.admin_withdraw_request.HasInProgressWithdrawRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.HasInProgressWithdrawRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.HasInProgressWithdrawRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.HasInProgressWithdrawRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.HasInProgressWithdrawRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.HasInProgressWithdrawResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.HasInProgressWithdrawResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    inProgressWithdrawCount: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.HasInProgressWithdrawResponseData}
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.HasInProgressWithdrawResponseData;
  return proto.admin_withdraw_request.HasInProgressWithdrawResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.HasInProgressWithdrawResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.HasInProgressWithdrawResponseData}
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setInProgressWithdrawCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.HasInProgressWithdrawResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.HasInProgressWithdrawResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInProgressWithdrawCount();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 in_progress_withdraw_count = 1;
 * @return {number}
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponseData.prototype.getInProgressWithdrawCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_withdraw_request.HasInProgressWithdrawResponseData} returns this
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponseData.prototype.setInProgressWithdrawCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.HasInProgressWithdrawResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.HasInProgressWithdrawResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_withdraw_request.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_withdraw_request.HasInProgressWithdrawResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.HasInProgressWithdrawResponse}
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.HasInProgressWithdrawResponse;
  return proto.admin_withdraw_request.HasInProgressWithdrawResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.HasInProgressWithdrawResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.HasInProgressWithdrawResponse}
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_withdraw_request.Meta;
      reader.readMessage(value,proto.admin_withdraw_request.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_withdraw_request.HasInProgressWithdrawResponseData;
      reader.readMessage(value,proto.admin_withdraw_request.HasInProgressWithdrawResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.HasInProgressWithdrawResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.HasInProgressWithdrawResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_withdraw_request.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_withdraw_request.HasInProgressWithdrawResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_withdraw_request.Meta}
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_withdraw_request.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_withdraw_request.Meta, 1));
};


/**
 * @param {?proto.admin_withdraw_request.Meta|undefined} value
 * @return {!proto.admin_withdraw_request.HasInProgressWithdrawResponse} returns this
*/
proto.admin_withdraw_request.HasInProgressWithdrawResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_withdraw_request.HasInProgressWithdrawResponse} returns this
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional HasInProgressWithdrawResponseData data = 2;
 * @return {?proto.admin_withdraw_request.HasInProgressWithdrawResponseData}
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponse.prototype.getData = function() {
  return /** @type{?proto.admin_withdraw_request.HasInProgressWithdrawResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_withdraw_request.HasInProgressWithdrawResponseData, 2));
};


/**
 * @param {?proto.admin_withdraw_request.HasInProgressWithdrawResponseData|undefined} value
 * @return {!proto.admin_withdraw_request.HasInProgressWithdrawResponse} returns this
*/
proto.admin_withdraw_request.HasInProgressWithdrawResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_withdraw_request.HasInProgressWithdrawResponse} returns this
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_withdraw_request.HasInProgressWithdrawResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.ApproveWithdrawRequestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.ApproveWithdrawRequestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.ApproveWithdrawRequestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    receiptPath: jspb.Message.getFieldWithDefault(msg, 2, ""),
    receiptPathType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.ApproveWithdrawRequestRequest}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.ApproveWithdrawRequestRequest;
  return proto.admin_withdraw_request.ApproveWithdrawRequestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.ApproveWithdrawRequestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.ApproveWithdrawRequestRequest}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiptPath(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiptPathType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.ApproveWithdrawRequestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.ApproveWithdrawRequestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.ApproveWithdrawRequestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReceiptPath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReceiptPathType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.ApproveWithdrawRequestRequest} returns this
 */
proto.admin_withdraw_request.ApproveWithdrawRequestRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string receipt_path = 2;
 * @return {string}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestRequest.prototype.getReceiptPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.ApproveWithdrawRequestRequest} returns this
 */
proto.admin_withdraw_request.ApproveWithdrawRequestRequest.prototype.setReceiptPath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string receipt_path_type = 3;
 * @return {string}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestRequest.prototype.getReceiptPathType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.ApproveWithdrawRequestRequest} returns this
 */
proto.admin_withdraw_request.ApproveWithdrawRequestRequest.prototype.setReceiptPathType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.ApproveWithdrawRequestResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.ApproveWithdrawRequestResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.ApproveWithdrawRequestResponseData}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.ApproveWithdrawRequestResponseData;
  return proto.admin_withdraw_request.ApproveWithdrawRequestResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.ApproveWithdrawRequestResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.ApproveWithdrawRequestResponseData}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.ApproveWithdrawRequestResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.ApproveWithdrawRequestResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.ApproveWithdrawRequestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.ApproveWithdrawRequestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_withdraw_request.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_withdraw_request.ApproveWithdrawRequestResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.ApproveWithdrawRequestResponse}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.ApproveWithdrawRequestResponse;
  return proto.admin_withdraw_request.ApproveWithdrawRequestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.ApproveWithdrawRequestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.ApproveWithdrawRequestResponse}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_withdraw_request.Meta;
      reader.readMessage(value,proto.admin_withdraw_request.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_withdraw_request.ApproveWithdrawRequestResponseData;
      reader.readMessage(value,proto.admin_withdraw_request.ApproveWithdrawRequestResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.ApproveWithdrawRequestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.ApproveWithdrawRequestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_withdraw_request.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_withdraw_request.ApproveWithdrawRequestResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_withdraw_request.Meta}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_withdraw_request.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_withdraw_request.Meta, 1));
};


/**
 * @param {?proto.admin_withdraw_request.Meta|undefined} value
 * @return {!proto.admin_withdraw_request.ApproveWithdrawRequestResponse} returns this
*/
proto.admin_withdraw_request.ApproveWithdrawRequestResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_withdraw_request.ApproveWithdrawRequestResponse} returns this
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ApproveWithdrawRequestResponseData data = 2;
 * @return {?proto.admin_withdraw_request.ApproveWithdrawRequestResponseData}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponse.prototype.getData = function() {
  return /** @type{?proto.admin_withdraw_request.ApproveWithdrawRequestResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_withdraw_request.ApproveWithdrawRequestResponseData, 2));
};


/**
 * @param {?proto.admin_withdraw_request.ApproveWithdrawRequestResponseData|undefined} value
 * @return {!proto.admin_withdraw_request.ApproveWithdrawRequestResponse} returns this
*/
proto.admin_withdraw_request.ApproveWithdrawRequestResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_withdraw_request.ApproveWithdrawRequestResponse} returns this
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_withdraw_request.ApproveWithdrawRequestResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.DeclineWithdrawRequestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.DeclineWithdrawRequestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.DeclineWithdrawRequestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    remarks: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.DeclineWithdrawRequestRequest}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.DeclineWithdrawRequestRequest;
  return proto.admin_withdraw_request.DeclineWithdrawRequestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.DeclineWithdrawRequestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.DeclineWithdrawRequestRequest}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.DeclineWithdrawRequestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.DeclineWithdrawRequestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.DeclineWithdrawRequestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.DeclineWithdrawRequestRequest} returns this
 */
proto.admin_withdraw_request.DeclineWithdrawRequestRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string remarks = 2;
 * @return {string}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestRequest.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_withdraw_request.DeclineWithdrawRequestRequest} returns this
 */
proto.admin_withdraw_request.DeclineWithdrawRequestRequest.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.DeclineWithdrawRequestResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.DeclineWithdrawRequestResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.DeclineWithdrawRequestResponseData}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.DeclineWithdrawRequestResponseData;
  return proto.admin_withdraw_request.DeclineWithdrawRequestResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.DeclineWithdrawRequestResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.DeclineWithdrawRequestResponseData}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.DeclineWithdrawRequestResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.DeclineWithdrawRequestResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_withdraw_request.DeclineWithdrawRequestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_withdraw_request.DeclineWithdrawRequestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_withdraw_request.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_withdraw_request.DeclineWithdrawRequestResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_withdraw_request.DeclineWithdrawRequestResponse}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_withdraw_request.DeclineWithdrawRequestResponse;
  return proto.admin_withdraw_request.DeclineWithdrawRequestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_withdraw_request.DeclineWithdrawRequestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_withdraw_request.DeclineWithdrawRequestResponse}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_withdraw_request.Meta;
      reader.readMessage(value,proto.admin_withdraw_request.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_withdraw_request.DeclineWithdrawRequestResponseData;
      reader.readMessage(value,proto.admin_withdraw_request.DeclineWithdrawRequestResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_withdraw_request.DeclineWithdrawRequestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_withdraw_request.DeclineWithdrawRequestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_withdraw_request.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_withdraw_request.DeclineWithdrawRequestResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_withdraw_request.Meta}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_withdraw_request.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_withdraw_request.Meta, 1));
};


/**
 * @param {?proto.admin_withdraw_request.Meta|undefined} value
 * @return {!proto.admin_withdraw_request.DeclineWithdrawRequestResponse} returns this
*/
proto.admin_withdraw_request.DeclineWithdrawRequestResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_withdraw_request.DeclineWithdrawRequestResponse} returns this
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeclineWithdrawRequestResponseData data = 2;
 * @return {?proto.admin_withdraw_request.DeclineWithdrawRequestResponseData}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponse.prototype.getData = function() {
  return /** @type{?proto.admin_withdraw_request.DeclineWithdrawRequestResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_withdraw_request.DeclineWithdrawRequestResponseData, 2));
};


/**
 * @param {?proto.admin_withdraw_request.DeclineWithdrawRequestResponseData|undefined} value
 * @return {!proto.admin_withdraw_request.DeclineWithdrawRequestResponse} returns this
*/
proto.admin_withdraw_request.DeclineWithdrawRequestResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_withdraw_request.DeclineWithdrawRequestResponse} returns this
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_withdraw_request.DeclineWithdrawRequestResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.admin_withdraw_request);
