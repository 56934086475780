import { ReactNode, useContext, useEffect, useState, type FC } from 'react';
import { ORDER_DONE, ORDER_PROCESSING, ORDER_REJECTED, ORDER_REVIEWED } from '../utils/contants';
import { clientOrder } from '../App';
import { GetOrderDraftListRequest, GetOrderDraftListResponseData, GetOrderListRequest, GetOrderListResponseData } from '../grpc/client/client_order_pb';
import { getToken, numberToDuration } from '../utils/helper';
import { errorToast } from '../utils/helper-ui';
import LoadingComponent from './loading_component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { OrderContext } from '../objects/order_context';
import CommentIcon from "../icon_svg/comment.svg"
import NoData from './no_data';

interface ListOrderCommentProps {
    mode: string
}

const ListOrderComment: FC<ListOrderCommentProps> = ({ mode }) => {
    const nav = useNavigate()
    const [mounted, setMounted] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [hasNext, setHasNext] = useState(false);
    const [dataOrderDrafts, setDataOrderDrafts] = useState<GetOrderDraftListResponseData.AsObject[]>([]);
    const [dataOrders, setDataOrders] = useState<GetOrderListResponseData.AsObject[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { orderCtx, setOrderCtx } = useContext(OrderContext);


    useEffect(() => {
        setMounted(true)

    }, []);


    useEffect(() => {
        getData()
    }, [page, limit, mounted, mode]);

    const getOrderDraftList = async (page: number, limit: number, isConfirmed: boolean, isDeclined: boolean) => {
        setIsLoading(true)
        let req = new GetOrderDraftListRequest()
        req.setPage(page)
        req.setLimit(limit)
        req.setIsConfirmed(isConfirmed)
        req.setIsDeclined(isDeclined)

        clientOrder.getOrderDraftList(req, getToken(), (err, resp) => {
            if (err) {
                errorToast(err.message)
                return
            }
            // console.log(resp.getDataList().map(e => e.toObject()))
            setHasNext(resp.getPagination()?.toObject().next != page)
            setDataOrderDrafts(resp.getDataList().map(e => e.toObject()))
            setIsLoading(false)
        })
    }
    const getOrderList = async (page: number, limit: number, isFinished: boolean) => {
        setIsLoading(true)
        let req = new GetOrderListRequest()
        req.setPage(page)
        req.setLimit(limit)
        req.setIsFinished(isFinished)
        clientOrder.getOrderList(req, getToken(), (err, resp) => {
            if (err) {
                errorToast(err.message)
                return
            }
            setHasNext(resp.getPagination()?.toObject().next != page)
            setDataOrders(resp.getDataList().map(e => e.toObject()))
            setIsLoading(false)
        })
    }

    useEffect(() => {
        // console.log(dataOrders)
    }, [dataOrders]);

    const getData = () => {
        // console.log("GET DATA", mode, page)
        switch (mode) {
            case ORDER_REVIEWED:
                getOrderDraftList(page, limit, false, false)
                break;
            case ORDER_REJECTED:
                getOrderDraftList(page, limit, false, true)
                break;
            case ORDER_PROCESSING:
                getOrderList(page, limit, false)
                break;
            case ORDER_DONE:
                getOrderList(page, limit, true)
                break;

            default:
                break;
        }
    }

    const noData = () => {
        if (mode == ORDER_REVIEWED) {
            return <div className='flex flex-col justify-center items-center min-h-[200px]'>
                <span className='mb-4'>
                    Kamu belum buat pesanan, nih    
                </span>
                <button onClick={() => {
                    nav('/comment-order')
                }} type="button" className="btn">Buat Pesanan</button>
            </div>
        }
        return (
            <NoData />
        );
    }

    const rendeDraftCard = (e: GetOrderDraftListResponseData.AsObject) => (<div className=' '>

    </div>)
    const renderActiveCard = (e: GetOrderListResponseData.AsObject) => (<div className=' '>

    </div>)
    const renderCard = (children: ReactNode, dataDraft?: GetOrderDraftListResponseData.AsObject | null, data?: GetOrderListResponseData.AsObject | null) => {
        let isDraft = mode == ORDER_REVIEWED || mode == ORDER_REJECTED
        let profileUrl: string | null | undefined = dataDraft?.user?.profilePictureUrl ?? data?.user?.profilePictureUrl
        let id: string | null | undefined = dataDraft?.id ?? data?.id

        if (profileUrl == "") profileUrl = null
        return (<div className=' p-4 rounded-lg bg-white shadow-md shadow-gray-100 last:mb-0' onClick={() => {
            // nav(`/order/${id}`)
            // setOrderCtx({
            //     id: id!,
            //     status: mode,
            // })
        }}>
            <div className='flex justify-between mb-4'>
                {mode == ORDER_PROCESSING ? <span className='font-semibold'>Estimasi Selesai</span> : <span className='font-semibold'>Status
                </span>}

                {mode == ORDER_REVIEWED && <span className='text-right text-green-400'>Menunggu</span>}
                {mode == ORDER_DONE && <span className='text-right text-green-400'>Selesai</span>}
                {mode == ORDER_REJECTED && <span className='text-right text-orange-400'>Ditolak</span>}
                {mode == ORDER_PROCESSING && <span className='text-right text-orange-400'>{numberToDuration(moment.utc(data?.dueDate).diff(moment.utc(), "minutes"))}</span>}

            </div>
            <div className='flex mb-4'>
                <img onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = profileUrl ?? "/assets/logo.svg";
                }} src={isDraft ? dataDraft?.thumbnailUrl : data?.thumbnailUrl} alt="" className=" object-cover w-10 h-10 rounded-lg bg-gray-50 mr-2" />
                <div className='flex flex-col whitespace-nowrap text-ellipsis overflow-hidden' onClick={() => window.open(isDraft ? dataDraft?.url : data?.url)}>
                    <h6>{isDraft ? dataDraft?.title : data?.title}</h6>
                    <p className=' text-nowrap text-ellipsis cursor-pointer'>{isDraft ? dataDraft?.url : data?.url}</p>
                </div>
            </div>
            <div className=' border-t pt-4'>
                {mode == ORDER_DONE ? 
                <div className='flex justify-between items-center'>
                <div className='relative'>
                    <small>Selesai pada {moment.utc(data?.finishedAt).format("DD MMMM YYYY")}</small>
                </div>
                <button className='btn' onClick={() => {
                    setOrderCtx({
                        id: id!,
                        status: mode,
                    })
                   
                    nav(`/order/${id}`)
                }}>Lihat Laporan</button>
            </div>
             :
                    <div className='flex justify-between items-center'>
                        <div className='relative'>
                            <img src={CommentIcon} alt="" className='w-6 text-gray-200' />
                            {data?.hasUnreadMessage && <div className='w-1.5 h-1.5 absolute top-0 right-0 bg-orange-400 rounded-full'></div>}
                        </div>
                        <button className='btn' onClick={() => {
                             if (mode == ORDER_REVIEWED) {
                                setOrderCtx({
                                    id: id!,
                                    status: mode,
                                    dataDraft: dataDraft
                                })
                                nav(`/order/draft/${id}`)
                             } else if (mode == ORDER_REJECTED) {
                                setOrderCtx({
                                    id: id!,
                                    status: mode,
                                    dataDraft: dataDraft
                                })
                                nav(`/order/declined/${id}`)
                             } else {
                                setOrderCtx({
                                    id: id!,
                                    status: mode,
                                })
                                nav(`/order/${id}`)
                             }
                          
                        }}>Lihat</button>
                    </div>}

            </div>
            {children}
        </div>);
    }
    return (<div className='py-4'>
        <LoadingComponent isLoading={isLoading} />
        {(mode == ORDER_REVIEWED || mode == ORDER_REJECTED) && dataOrderDrafts.length == 0 && !isLoading && noData()}
        {(mode == ORDER_PROCESSING || mode == ORDER_DONE) && dataOrders.length == 0 && !isLoading && noData()}
        <ul>

            {(mode == ORDER_REVIEWED || mode == ORDER_REJECTED) && dataOrderDrafts.map(e => <li className='mb-4 ' key={e.id}>
                {renderCard(rendeDraftCard(e), e, null)}
            </li>)}
            {(mode == ORDER_PROCESSING || mode == ORDER_DONE) && dataOrders.map(e => <li className='mb-4 ' key={e.id}>
                {renderCard(renderActiveCard(e), null, e)}
            </li>)}


        </ul>
    </div>);
}
export default ListOrderComment;