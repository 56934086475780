import { FC } from "react"
import { Route, Routes } from "react-router-dom"
import Home from "../pages/client/Home"
import NotFound from "../pages/NotFound"
import ClientRoutes from "./ClientRoutes"
import Invite from "../pages/Invite"
import Referal from "../pages/Referal"
import AdminRoutes from "./AdminRoutes"
import AffiliatorRoute from "./AffiliatorRoute"
import CommentatorRoute from "./CommentatorRoute"



interface PrivateRoutesProps { }

const PrivateRoutes: FC<PrivateRoutesProps> = ({ }) => {
   
    return (
        <Routes>
            <Route path='/invite' element={<Invite />} />
            <Route path='/referal' element={<Referal />} />
            <Route path="/*" element={<ClientRoutes />} />
            <Route path="/admin/*" element={<AdminRoutes />} />
            <Route path="/affiliator/*" element={<AffiliatorRoute />} />
            <Route path="/commentator/*" element={<CommentatorRoute />} />
            {/* <Route path='/profile' element={<ProfilePage />} /> */}
            {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
    )
}

export default PrivateRoutes;