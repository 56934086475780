// source: admin_user_package.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.admin_user_package.ApprovePaymentUserPackageRequest', null, global);
goog.exportSymbol('proto.admin_user_package.ApprovePaymentUserPackageResponse', null, global);
goog.exportSymbol('proto.admin_user_package.ApprovePaymentUserPackageResponseData', null, global);
goog.exportSymbol('proto.admin_user_package.DeclinePaymentUserPackageRequest', null, global);
goog.exportSymbol('proto.admin_user_package.DeclinePaymentUserPackageResponse', null, global);
goog.exportSymbol('proto.admin_user_package.DeclinePaymentUserPackageResponseData', null, global);
goog.exportSymbol('proto.admin_user_package.GetDetailUserPackageRequest', null, global);
goog.exportSymbol('proto.admin_user_package.GetDetailUserPackageResponse', null, global);
goog.exportSymbol('proto.admin_user_package.GetDetailUserPackageResponseData', null, global);
goog.exportSymbol('proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator', null, global);
goog.exportSymbol('proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer', null, global);
goog.exportSymbol('proto.admin_user_package.GetDetailUserPackageResponseDataCommentator', null, global);
goog.exportSymbol('proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer', null, global);
goog.exportSymbol('proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog', null, global);
goog.exportSymbol('proto.admin_user_package.GetDetailUserPackageResponseDataUser', null, global);
goog.exportSymbol('proto.admin_user_package.GetListUserPackageRequest', null, global);
goog.exportSymbol('proto.admin_user_package.GetListUserPackageResponse', null, global);
goog.exportSymbol('proto.admin_user_package.GetListUserPackageResponseData', null, global);
goog.exportSymbol('proto.admin_user_package.GetListUserPackageResponseDataAffiliator', null, global);
goog.exportSymbol('proto.admin_user_package.GetListUserPackageResponseDataUser', null, global);
goog.exportSymbol('proto.admin_user_package.InactivateUserPackageRequest', null, global);
goog.exportSymbol('proto.admin_user_package.InactivateUserPackageResponse', null, global);
goog.exportSymbol('proto.admin_user_package.InactivateUserPackageResponseData', null, global);
goog.exportSymbol('proto.admin_user_package.Meta', null, global);
goog.exportSymbol('proto.admin_user_package.Pagination', null, global);
goog.exportSymbol('proto.admin_user_package.UserPackageOfferToAffiliatorRequest', null, global);
goog.exportSymbol('proto.admin_user_package.UserPackageOfferToAffiliatorResponse', null, global);
goog.exportSymbol('proto.admin_user_package.UserPackageOfferToAffiliatorResponseData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.Meta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.Meta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.Meta.displayName = 'proto.admin_user_package.Meta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.Pagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.Pagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.Pagination.displayName = 'proto.admin_user_package.Pagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.GetListUserPackageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.GetListUserPackageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.GetListUserPackageRequest.displayName = 'proto.admin_user_package.GetListUserPackageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.GetListUserPackageResponseDataUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.GetListUserPackageResponseDataUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.GetListUserPackageResponseDataUser.displayName = 'proto.admin_user_package.GetListUserPackageResponseDataUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.GetListUserPackageResponseDataAffiliator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.GetListUserPackageResponseDataAffiliator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.GetListUserPackageResponseDataAffiliator.displayName = 'proto.admin_user_package.GetListUserPackageResponseDataAffiliator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.GetListUserPackageResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.GetListUserPackageResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.GetListUserPackageResponseData.displayName = 'proto.admin_user_package.GetListUserPackageResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.GetListUserPackageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin_user_package.GetListUserPackageResponse.repeatedFields_, null);
};
goog.inherits(proto.admin_user_package.GetListUserPackageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.GetListUserPackageResponse.displayName = 'proto.admin_user_package.GetListUserPackageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.ApprovePaymentUserPackageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.ApprovePaymentUserPackageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.ApprovePaymentUserPackageRequest.displayName = 'proto.admin_user_package.ApprovePaymentUserPackageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.ApprovePaymentUserPackageResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.ApprovePaymentUserPackageResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.ApprovePaymentUserPackageResponseData.displayName = 'proto.admin_user_package.ApprovePaymentUserPackageResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.ApprovePaymentUserPackageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.ApprovePaymentUserPackageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.ApprovePaymentUserPackageResponse.displayName = 'proto.admin_user_package.ApprovePaymentUserPackageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.DeclinePaymentUserPackageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.DeclinePaymentUserPackageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.DeclinePaymentUserPackageRequest.displayName = 'proto.admin_user_package.DeclinePaymentUserPackageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.DeclinePaymentUserPackageResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.DeclinePaymentUserPackageResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.DeclinePaymentUserPackageResponseData.displayName = 'proto.admin_user_package.DeclinePaymentUserPackageResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.DeclinePaymentUserPackageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.DeclinePaymentUserPackageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.DeclinePaymentUserPackageResponse.displayName = 'proto.admin_user_package.DeclinePaymentUserPackageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.GetDetailUserPackageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.GetDetailUserPackageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.GetDetailUserPackageRequest.displayName = 'proto.admin_user_package.GetDetailUserPackageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.displayName = 'proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.GetDetailUserPackageResponseDataUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.GetDetailUserPackageResponseDataUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.GetDetailUserPackageResponseDataUser.displayName = 'proto.admin_user_package.GetDetailUserPackageResponseDataUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.displayName = 'proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.GetDetailUserPackageResponseDataCommentator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.displayName = 'proto.admin_user_package.GetDetailUserPackageResponseDataCommentator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.displayName = 'proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.displayName = 'proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.GetDetailUserPackageResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin_user_package.GetDetailUserPackageResponseData.repeatedFields_, null);
};
goog.inherits(proto.admin_user_package.GetDetailUserPackageResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.GetDetailUserPackageResponseData.displayName = 'proto.admin_user_package.GetDetailUserPackageResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.GetDetailUserPackageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.GetDetailUserPackageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.GetDetailUserPackageResponse.displayName = 'proto.admin_user_package.GetDetailUserPackageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.UserPackageOfferToAffiliatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin_user_package.UserPackageOfferToAffiliatorRequest.repeatedFields_, null);
};
goog.inherits(proto.admin_user_package.UserPackageOfferToAffiliatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.UserPackageOfferToAffiliatorRequest.displayName = 'proto.admin_user_package.UserPackageOfferToAffiliatorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.UserPackageOfferToAffiliatorResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.UserPackageOfferToAffiliatorResponseData.displayName = 'proto.admin_user_package.UserPackageOfferToAffiliatorResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.UserPackageOfferToAffiliatorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.UserPackageOfferToAffiliatorResponse.displayName = 'proto.admin_user_package.UserPackageOfferToAffiliatorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.InactivateUserPackageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.InactivateUserPackageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.InactivateUserPackageRequest.displayName = 'proto.admin_user_package.InactivateUserPackageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.InactivateUserPackageResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.InactivateUserPackageResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.InactivateUserPackageResponseData.displayName = 'proto.admin_user_package.InactivateUserPackageResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_user_package.InactivateUserPackageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_user_package.InactivateUserPackageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_user_package.InactivateUserPackageResponse.displayName = 'proto.admin_user_package.InactivateUserPackageResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.Meta.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.Meta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.Meta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.Meta.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.Meta}
 */
proto.admin_user_package.Meta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.Meta;
  return proto.admin_user_package.Meta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.Meta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.Meta}
 */
proto.admin_user_package.Meta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.Meta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.Meta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.Meta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.Meta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.admin_user_package.Meta.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.Meta} returns this
 */
proto.admin_user_package.Meta.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 status = 2;
 * @return {number}
 */
proto.admin_user_package.Meta.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.Meta} returns this
 */
proto.admin_user_package.Meta.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.admin_user_package.Meta.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.Meta} returns this
 */
proto.admin_user_package.Meta.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.Pagination.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.Pagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.Pagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.Pagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    prev: jspb.Message.getFieldWithDefault(msg, 3, 0),
    next: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.Pagination}
 */
proto.admin_user_package.Pagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.Pagination;
  return proto.admin_user_package.Pagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.Pagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.Pagination}
 */
proto.admin_user_package.Pagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrev(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNext(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPages(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.Pagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.Pagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.Pagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.Pagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPrev();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNext();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.admin_user_package.Pagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.Pagination} returns this
 */
proto.admin_user_package.Pagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.admin_user_package.Pagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.Pagination} returns this
 */
proto.admin_user_package.Pagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 prev = 3;
 * @return {number}
 */
proto.admin_user_package.Pagination.prototype.getPrev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.Pagination} returns this
 */
proto.admin_user_package.Pagination.prototype.setPrev = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 next = 4;
 * @return {number}
 */
proto.admin_user_package.Pagination.prototype.getNext = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.Pagination} returns this
 */
proto.admin_user_package.Pagination.prototype.setNext = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 total_pages = 5;
 * @return {number}
 */
proto.admin_user_package.Pagination.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.Pagination} returns this
 */
proto.admin_user_package.Pagination.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 total_records = 6;
 * @return {number}
 */
proto.admin_user_package.Pagination.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.Pagination} returns this
 */
proto.admin_user_package.Pagination.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.GetListUserPackageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.GetListUserPackageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.GetListUserPackageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetListUserPackageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paymentStatus: jspb.Message.getFieldWithDefault(msg, 3, ""),
    showUserName: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    name: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.GetListUserPackageRequest}
 */
proto.admin_user_package.GetListUserPackageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.GetListUserPackageRequest;
  return proto.admin_user_package.GetListUserPackageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.GetListUserPackageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.GetListUserPackageRequest}
 */
proto.admin_user_package.GetListUserPackageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentStatus(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowUserName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.GetListUserPackageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.GetListUserPackageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.GetListUserPackageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetListUserPackageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPaymentStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShowUserName();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.admin_user_package.GetListUserPackageRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetListUserPackageRequest} returns this
 */
proto.admin_user_package.GetListUserPackageRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.admin_user_package.GetListUserPackageRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetListUserPackageRequest} returns this
 */
proto.admin_user_package.GetListUserPackageRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string payment_status = 3;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageRequest.prototype.getPaymentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageRequest} returns this
 */
proto.admin_user_package.GetListUserPackageRequest.prototype.setPaymentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool show_user_name = 4;
 * @return {boolean}
 */
proto.admin_user_package.GetListUserPackageRequest.prototype.getShowUserName = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admin_user_package.GetListUserPackageRequest} returns this
 */
proto.admin_user_package.GetListUserPackageRequest.prototype.setShowUserName = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageRequest} returns this
 */
proto.admin_user_package.GetListUserPackageRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.GetListUserPackageResponseDataUser.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.GetListUserPackageResponseDataUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.GetListUserPackageResponseDataUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetListUserPackageResponseDataUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.GetListUserPackageResponseDataUser}
 */
proto.admin_user_package.GetListUserPackageResponseDataUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.GetListUserPackageResponseDataUser;
  return proto.admin_user_package.GetListUserPackageResponseDataUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.GetListUserPackageResponseDataUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.GetListUserPackageResponseDataUser}
 */
proto.admin_user_package.GetListUserPackageResponseDataUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.GetListUserPackageResponseDataUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.GetListUserPackageResponseDataUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.GetListUserPackageResponseDataUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetListUserPackageResponseDataUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageResponseDataUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseDataUser} returns this
 */
proto.admin_user_package.GetListUserPackageResponseDataUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageResponseDataUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseDataUser} returns this
 */
proto.admin_user_package.GetListUserPackageResponseDataUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageResponseDataUser.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseDataUser} returns this
 */
proto.admin_user_package.GetListUserPackageResponseDataUser.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.GetListUserPackageResponseDataAffiliator.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.GetListUserPackageResponseDataAffiliator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.GetListUserPackageResponseDataAffiliator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetListUserPackageResponseDataAffiliator.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.GetListUserPackageResponseDataAffiliator}
 */
proto.admin_user_package.GetListUserPackageResponseDataAffiliator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.GetListUserPackageResponseDataAffiliator;
  return proto.admin_user_package.GetListUserPackageResponseDataAffiliator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.GetListUserPackageResponseDataAffiliator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.GetListUserPackageResponseDataAffiliator}
 */
proto.admin_user_package.GetListUserPackageResponseDataAffiliator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.GetListUserPackageResponseDataAffiliator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.GetListUserPackageResponseDataAffiliator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.GetListUserPackageResponseDataAffiliator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetListUserPackageResponseDataAffiliator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageResponseDataAffiliator.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseDataAffiliator} returns this
 */
proto.admin_user_package.GetListUserPackageResponseDataAffiliator.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageResponseDataAffiliator.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseDataAffiliator} returns this
 */
proto.admin_user_package.GetListUserPackageResponseDataAffiliator.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageResponseDataAffiliator.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseDataAffiliator} returns this
 */
proto.admin_user_package.GetListUserPackageResponseDataAffiliator.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.GetListUserPackageResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.GetListUserPackageResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetListUserPackageResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    packageName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    commentPerDay: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalComment: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalLikes: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalShares: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    commentatorAccountSlot: jspb.Message.getFieldWithDefault(msg, 9, 0),
    commentatorAccountRemainingSlot: jspb.Message.getFieldWithDefault(msg, 10, 0),
    expiryTime: jspb.Message.getFieldWithDefault(msg, 11, ""),
    paymentExpiryTime: jspb.Message.getFieldWithDefault(msg, 12, ""),
    confirmationExpiryTime: jspb.Message.getFieldWithDefault(msg, 13, ""),
    paymentStatus: jspb.Message.getFieldWithDefault(msg, 14, ""),
    statusRemarks: jspb.Message.getFieldWithDefault(msg, 15, ""),
    receiptUrl: jspb.Message.getFieldWithDefault(msg, 16, ""),
    confirmedAt: jspb.Message.getFieldWithDefault(msg, 17, ""),
    affiliator: (f = msg.getAffiliator()) && proto.admin_user_package.GetListUserPackageResponseDataAffiliator.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.admin_user_package.GetListUserPackageResponseDataUser.toObject(includeInstance, f),
    totalFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.GetListUserPackageResponseData}
 */
proto.admin_user_package.GetListUserPackageResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.GetListUserPackageResponseData;
  return proto.admin_user_package.GetListUserPackageResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.GetListUserPackageResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.GetListUserPackageResponseData}
 */
proto.admin_user_package.GetListUserPackageResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommentPerDay(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalComment(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalLikes(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalShares(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPrice(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommentatorAccountSlot(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommentatorAccountRemainingSlot(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiryTime(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentExpiryTime(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationExpiryTime(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentStatus(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusRemarks(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiptUrl(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmedAt(value);
      break;
    case 18:
      var value = new proto.admin_user_package.GetListUserPackageResponseDataAffiliator;
      reader.readMessage(value,proto.admin_user_package.GetListUserPackageResponseDataAffiliator.deserializeBinaryFromReader);
      msg.setAffiliator(value);
      break;
    case 19:
      var value = new proto.admin_user_package.GetListUserPackageResponseDataUser;
      reader.readMessage(value,proto.admin_user_package.GetListUserPackageResponseDataUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.GetListUserPackageResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.GetListUserPackageResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetListUserPackageResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPackageName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCommentPerDay();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTotalComment();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalLikes();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTotalShares();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getCommentatorAccountSlot();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getCommentatorAccountRemainingSlot();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getExpiryTime();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPaymentExpiryTime();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getConfirmationExpiryTime();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPaymentStatus();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getStatusRemarks();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getReceiptUrl();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getConfirmedAt();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getAffiliator();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.admin_user_package.GetListUserPackageResponseDataAffiliator.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.admin_user_package.GetListUserPackageResponseDataUser.serializeBinaryToWriter
    );
  }
  f = message.getTotalFee();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string package_name = 3;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getPackageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setPackageName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 comment_per_day = 4;
 * @return {number}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getCommentPerDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setCommentPerDay = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 total_comment = 5;
 * @return {number}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getTotalComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setTotalComment = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 total_likes = 6;
 * @return {number}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getTotalLikes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setTotalLikes = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 total_shares = 7;
 * @return {number}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getTotalShares = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setTotalShares = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional double total_price = 8;
 * @return {number}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional uint32 commentator_account_slot = 9;
 * @return {number}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getCommentatorAccountSlot = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setCommentatorAccountSlot = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 commentator_account_remaining_slot = 10;
 * @return {number}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getCommentatorAccountRemainingSlot = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setCommentatorAccountRemainingSlot = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string expiry_time = 11;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getExpiryTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setExpiryTime = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string payment_expiry_time = 12;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getPaymentExpiryTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setPaymentExpiryTime = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string confirmation_expiry_time = 13;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getConfirmationExpiryTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setConfirmationExpiryTime = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string payment_status = 14;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getPaymentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setPaymentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string status_remarks = 15;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getStatusRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setStatusRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string receipt_url = 16;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getReceiptUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setReceiptUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string confirmed_at = 17;
 * @return {string}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getConfirmedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setConfirmedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional GetListUserPackageResponseDataAffiliator affiliator = 18;
 * @return {?proto.admin_user_package.GetListUserPackageResponseDataAffiliator}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getAffiliator = function() {
  return /** @type{?proto.admin_user_package.GetListUserPackageResponseDataAffiliator} */ (
    jspb.Message.getWrapperField(this, proto.admin_user_package.GetListUserPackageResponseDataAffiliator, 18));
};


/**
 * @param {?proto.admin_user_package.GetListUserPackageResponseDataAffiliator|undefined} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
*/
proto.admin_user_package.GetListUserPackageResponseData.prototype.setAffiliator = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.clearAffiliator = function() {
  return this.setAffiliator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.hasAffiliator = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional GetListUserPackageResponseDataUser user = 19;
 * @return {?proto.admin_user_package.GetListUserPackageResponseDataUser}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getUser = function() {
  return /** @type{?proto.admin_user_package.GetListUserPackageResponseDataUser} */ (
    jspb.Message.getWrapperField(this, proto.admin_user_package.GetListUserPackageResponseDataUser, 19));
};


/**
 * @param {?proto.admin_user_package.GetListUserPackageResponseDataUser|undefined} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
*/
proto.admin_user_package.GetListUserPackageResponseData.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.hasUser = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional double total_fee = 20;
 * @return {number}
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.getTotalFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetListUserPackageResponseData} returns this
 */
proto.admin_user_package.GetListUserPackageResponseData.prototype.setTotalFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin_user_package.GetListUserPackageResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.GetListUserPackageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.GetListUserPackageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.GetListUserPackageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetListUserPackageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_user_package.Meta.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.admin_user_package.Pagination.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.admin_user_package.GetListUserPackageResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.GetListUserPackageResponse}
 */
proto.admin_user_package.GetListUserPackageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.GetListUserPackageResponse;
  return proto.admin_user_package.GetListUserPackageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.GetListUserPackageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.GetListUserPackageResponse}
 */
proto.admin_user_package.GetListUserPackageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_user_package.Meta;
      reader.readMessage(value,proto.admin_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_user_package.Pagination;
      reader.readMessage(value,proto.admin_user_package.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.admin_user_package.GetListUserPackageResponseData;
      reader.readMessage(value,proto.admin_user_package.GetListUserPackageResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.GetListUserPackageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.GetListUserPackageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.GetListUserPackageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetListUserPackageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_user_package.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.admin_user_package.GetListUserPackageResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_user_package.Meta}
 */
proto.admin_user_package.GetListUserPackageResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_user_package.Meta, 1));
};


/**
 * @param {?proto.admin_user_package.Meta|undefined} value
 * @return {!proto.admin_user_package.GetListUserPackageResponse} returns this
*/
proto.admin_user_package.GetListUserPackageResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_user_package.GetListUserPackageResponse} returns this
 */
proto.admin_user_package.GetListUserPackageResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_user_package.GetListUserPackageResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.admin_user_package.Pagination}
 */
proto.admin_user_package.GetListUserPackageResponse.prototype.getPagination = function() {
  return /** @type{?proto.admin_user_package.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.admin_user_package.Pagination, 2));
};


/**
 * @param {?proto.admin_user_package.Pagination|undefined} value
 * @return {!proto.admin_user_package.GetListUserPackageResponse} returns this
*/
proto.admin_user_package.GetListUserPackageResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_user_package.GetListUserPackageResponse} returns this
 */
proto.admin_user_package.GetListUserPackageResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_user_package.GetListUserPackageResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated GetListUserPackageResponseData data = 3;
 * @return {!Array<!proto.admin_user_package.GetListUserPackageResponseData>}
 */
proto.admin_user_package.GetListUserPackageResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.admin_user_package.GetListUserPackageResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin_user_package.GetListUserPackageResponseData, 3));
};


/**
 * @param {!Array<!proto.admin_user_package.GetListUserPackageResponseData>} value
 * @return {!proto.admin_user_package.GetListUserPackageResponse} returns this
*/
proto.admin_user_package.GetListUserPackageResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.admin_user_package.GetListUserPackageResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin_user_package.GetListUserPackageResponseData}
 */
proto.admin_user_package.GetListUserPackageResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.admin_user_package.GetListUserPackageResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin_user_package.GetListUserPackageResponse} returns this
 */
proto.admin_user_package.GetListUserPackageResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.ApprovePaymentUserPackageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.ApprovePaymentUserPackageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.ApprovePaymentUserPackageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.ApprovePaymentUserPackageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userPackageId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.ApprovePaymentUserPackageRequest}
 */
proto.admin_user_package.ApprovePaymentUserPackageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.ApprovePaymentUserPackageRequest;
  return proto.admin_user_package.ApprovePaymentUserPackageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.ApprovePaymentUserPackageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.ApprovePaymentUserPackageRequest}
 */
proto.admin_user_package.ApprovePaymentUserPackageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPackageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.ApprovePaymentUserPackageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.ApprovePaymentUserPackageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.ApprovePaymentUserPackageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.ApprovePaymentUserPackageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserPackageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_package_id = 1;
 * @return {string}
 */
proto.admin_user_package.ApprovePaymentUserPackageRequest.prototype.getUserPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.ApprovePaymentUserPackageRequest} returns this
 */
proto.admin_user_package.ApprovePaymentUserPackageRequest.prototype.setUserPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.ApprovePaymentUserPackageResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.ApprovePaymentUserPackageResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.ApprovePaymentUserPackageResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.ApprovePaymentUserPackageResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.ApprovePaymentUserPackageResponseData}
 */
proto.admin_user_package.ApprovePaymentUserPackageResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.ApprovePaymentUserPackageResponseData;
  return proto.admin_user_package.ApprovePaymentUserPackageResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.ApprovePaymentUserPackageResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.ApprovePaymentUserPackageResponseData}
 */
proto.admin_user_package.ApprovePaymentUserPackageResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.ApprovePaymentUserPackageResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.ApprovePaymentUserPackageResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.ApprovePaymentUserPackageResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.ApprovePaymentUserPackageResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.ApprovePaymentUserPackageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.ApprovePaymentUserPackageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.ApprovePaymentUserPackageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.ApprovePaymentUserPackageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_user_package.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_user_package.ApprovePaymentUserPackageResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.ApprovePaymentUserPackageResponse}
 */
proto.admin_user_package.ApprovePaymentUserPackageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.ApprovePaymentUserPackageResponse;
  return proto.admin_user_package.ApprovePaymentUserPackageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.ApprovePaymentUserPackageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.ApprovePaymentUserPackageResponse}
 */
proto.admin_user_package.ApprovePaymentUserPackageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_user_package.Meta;
      reader.readMessage(value,proto.admin_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_user_package.ApprovePaymentUserPackageResponseData;
      reader.readMessage(value,proto.admin_user_package.ApprovePaymentUserPackageResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.ApprovePaymentUserPackageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.ApprovePaymentUserPackageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.ApprovePaymentUserPackageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.ApprovePaymentUserPackageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_user_package.ApprovePaymentUserPackageResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_user_package.Meta}
 */
proto.admin_user_package.ApprovePaymentUserPackageResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_user_package.Meta, 1));
};


/**
 * @param {?proto.admin_user_package.Meta|undefined} value
 * @return {!proto.admin_user_package.ApprovePaymentUserPackageResponse} returns this
*/
proto.admin_user_package.ApprovePaymentUserPackageResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_user_package.ApprovePaymentUserPackageResponse} returns this
 */
proto.admin_user_package.ApprovePaymentUserPackageResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_user_package.ApprovePaymentUserPackageResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ApprovePaymentUserPackageResponseData data = 2;
 * @return {?proto.admin_user_package.ApprovePaymentUserPackageResponseData}
 */
proto.admin_user_package.ApprovePaymentUserPackageResponse.prototype.getData = function() {
  return /** @type{?proto.admin_user_package.ApprovePaymentUserPackageResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_user_package.ApprovePaymentUserPackageResponseData, 2));
};


/**
 * @param {?proto.admin_user_package.ApprovePaymentUserPackageResponseData|undefined} value
 * @return {!proto.admin_user_package.ApprovePaymentUserPackageResponse} returns this
*/
proto.admin_user_package.ApprovePaymentUserPackageResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_user_package.ApprovePaymentUserPackageResponse} returns this
 */
proto.admin_user_package.ApprovePaymentUserPackageResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_user_package.ApprovePaymentUserPackageResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.DeclinePaymentUserPackageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.DeclinePaymentUserPackageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.DeclinePaymentUserPackageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.DeclinePaymentUserPackageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userPackageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    remarks: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.DeclinePaymentUserPackageRequest}
 */
proto.admin_user_package.DeclinePaymentUserPackageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.DeclinePaymentUserPackageRequest;
  return proto.admin_user_package.DeclinePaymentUserPackageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.DeclinePaymentUserPackageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.DeclinePaymentUserPackageRequest}
 */
proto.admin_user_package.DeclinePaymentUserPackageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPackageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.DeclinePaymentUserPackageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.DeclinePaymentUserPackageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.DeclinePaymentUserPackageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.DeclinePaymentUserPackageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserPackageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user_package_id = 1;
 * @return {string}
 */
proto.admin_user_package.DeclinePaymentUserPackageRequest.prototype.getUserPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.DeclinePaymentUserPackageRequest} returns this
 */
proto.admin_user_package.DeclinePaymentUserPackageRequest.prototype.setUserPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string remarks = 2;
 * @return {string}
 */
proto.admin_user_package.DeclinePaymentUserPackageRequest.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.DeclinePaymentUserPackageRequest} returns this
 */
proto.admin_user_package.DeclinePaymentUserPackageRequest.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.DeclinePaymentUserPackageResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.DeclinePaymentUserPackageResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.DeclinePaymentUserPackageResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.DeclinePaymentUserPackageResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.DeclinePaymentUserPackageResponseData}
 */
proto.admin_user_package.DeclinePaymentUserPackageResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.DeclinePaymentUserPackageResponseData;
  return proto.admin_user_package.DeclinePaymentUserPackageResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.DeclinePaymentUserPackageResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.DeclinePaymentUserPackageResponseData}
 */
proto.admin_user_package.DeclinePaymentUserPackageResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.DeclinePaymentUserPackageResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.DeclinePaymentUserPackageResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.DeclinePaymentUserPackageResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.DeclinePaymentUserPackageResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.DeclinePaymentUserPackageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.DeclinePaymentUserPackageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.DeclinePaymentUserPackageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.DeclinePaymentUserPackageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_user_package.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_user_package.DeclinePaymentUserPackageResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.DeclinePaymentUserPackageResponse}
 */
proto.admin_user_package.DeclinePaymentUserPackageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.DeclinePaymentUserPackageResponse;
  return proto.admin_user_package.DeclinePaymentUserPackageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.DeclinePaymentUserPackageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.DeclinePaymentUserPackageResponse}
 */
proto.admin_user_package.DeclinePaymentUserPackageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_user_package.Meta;
      reader.readMessage(value,proto.admin_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_user_package.DeclinePaymentUserPackageResponseData;
      reader.readMessage(value,proto.admin_user_package.DeclinePaymentUserPackageResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.DeclinePaymentUserPackageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.DeclinePaymentUserPackageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.DeclinePaymentUserPackageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.DeclinePaymentUserPackageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_user_package.DeclinePaymentUserPackageResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_user_package.Meta}
 */
proto.admin_user_package.DeclinePaymentUserPackageResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_user_package.Meta, 1));
};


/**
 * @param {?proto.admin_user_package.Meta|undefined} value
 * @return {!proto.admin_user_package.DeclinePaymentUserPackageResponse} returns this
*/
proto.admin_user_package.DeclinePaymentUserPackageResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_user_package.DeclinePaymentUserPackageResponse} returns this
 */
proto.admin_user_package.DeclinePaymentUserPackageResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_user_package.DeclinePaymentUserPackageResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeclinePaymentUserPackageResponseData data = 2;
 * @return {?proto.admin_user_package.DeclinePaymentUserPackageResponseData}
 */
proto.admin_user_package.DeclinePaymentUserPackageResponse.prototype.getData = function() {
  return /** @type{?proto.admin_user_package.DeclinePaymentUserPackageResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_user_package.DeclinePaymentUserPackageResponseData, 2));
};


/**
 * @param {?proto.admin_user_package.DeclinePaymentUserPackageResponseData|undefined} value
 * @return {!proto.admin_user_package.DeclinePaymentUserPackageResponse} returns this
*/
proto.admin_user_package.DeclinePaymentUserPackageResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_user_package.DeclinePaymentUserPackageResponse} returns this
 */
proto.admin_user_package.DeclinePaymentUserPackageResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_user_package.DeclinePaymentUserPackageResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.GetDetailUserPackageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.GetDetailUserPackageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.GetDetailUserPackageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.GetDetailUserPackageRequest}
 */
proto.admin_user_package.GetDetailUserPackageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.GetDetailUserPackageRequest;
  return proto.admin_user_package.GetDetailUserPackageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.GetDetailUserPackageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.GetDetailUserPackageRequest}
 */
proto.admin_user_package.GetDetailUserPackageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.GetDetailUserPackageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.GetDetailUserPackageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.GetDetailUserPackageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageRequest} returns this
 */
proto.admin_user_package.GetDetailUserPackageRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromPaymentStatus: jspb.Message.getFieldWithDefault(msg, 2, ""),
    toPaymentStatus: jspb.Message.getFieldWithDefault(msg, 3, ""),
    remarks: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog;
  return proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromPaymentStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToPaymentStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromPaymentStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getToPaymentStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string from_payment_status = 2;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.getFromPaymentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.setFromPaymentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string to_payment_status = 3;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.getToPaymentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.setToPaymentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string remarks = 4;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string created_at = 5;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataUser.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.GetDetailUserPackageResponseDataUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageResponseDataUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataUser}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.GetDetailUserPackageResponseDataUser;
  return proto.admin_user_package.GetDetailUserPackageResponseDataUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataUser}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.GetDetailUserPackageResponseDataUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageResponseDataUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataUser} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataUser} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataUser.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataUser} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataUser.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator;
  return proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentator}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.GetDetailUserPackageResponseDataCommentator;
  return proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentator}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentator} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentator} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentator} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer;
  return proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer;
  return proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture_url = 3;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin_user_package.GetDetailUserPackageResponseData.repeatedFields_ = [21,22,23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.GetDetailUserPackageResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    confirmedAt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    paymentExpiryTime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    commentPerDay: jspb.Message.getFieldWithDefault(msg, 5, 0),
    durationDay: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalComment: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totalLikes: jspb.Message.getFieldWithDefault(msg, 8, 0),
    totalShares: jspb.Message.getFieldWithDefault(msg, 9, 0),
    pricePerComment: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    totalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    affiliatorFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    commentatorFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    adminFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    receiptUrl: jspb.Message.getFieldWithDefault(msg, 15, ""),
    commentatorAccountSlot: jspb.Message.getFieldWithDefault(msg, 16, 0),
    adminStatus: jspb.Message.getFieldWithDefault(msg, 17, ""),
    statusRemarks: jspb.Message.getFieldWithDefault(msg, 18, ""),
    user: (f = msg.getUser()) && proto.admin_user_package.GetDetailUserPackageResponseDataUser.toObject(includeInstance, f),
    affiliator: (f = msg.getAffiliator()) && proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.toObject(includeInstance, f),
    commentatorsList: jspb.Message.toObjectList(msg.getCommentatorsList(),
    proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.toObject, includeInstance),
    affiliatorOffersList: jspb.Message.toObjectList(msg.getAffiliatorOffersList(),
    proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.toObject, includeInstance),
    commentatorOffersList: jspb.Message.toObjectList(msg.getCommentatorOffersList(),
    proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.toObject, includeInstance),
    expiryTime: jspb.Message.getFieldWithDefault(msg, 24, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.GetDetailUserPackageResponseData;
  return proto.admin_user_package.GetDetailUserPackageResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentExpiryTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommentPerDay(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationDay(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalComment(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalLikes(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalShares(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPricePerComment(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPrice(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAffiliatorFee(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCommentatorFee(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAdminFee(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiptUrl(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommentatorAccountSlot(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminStatus(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusRemarks(value);
      break;
    case 19:
      var value = new proto.admin_user_package.GetDetailUserPackageResponseDataUser;
      reader.readMessage(value,proto.admin_user_package.GetDetailUserPackageResponseDataUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 20:
      var value = new proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator;
      reader.readMessage(value,proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.deserializeBinaryFromReader);
      msg.setAffiliator(value);
      break;
    case 21:
      var value = new proto.admin_user_package.GetDetailUserPackageResponseDataCommentator;
      reader.readMessage(value,proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.deserializeBinaryFromReader);
      msg.addCommentators(value);
      break;
    case 22:
      var value = new proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer;
      reader.readMessage(value,proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.deserializeBinaryFromReader);
      msg.addAffiliatorOffers(value);
      break;
    case 23:
      var value = new proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer;
      reader.readMessage(value,proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.deserializeBinaryFromReader);
      msg.addCommentatorOffers(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiryTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.GetDetailUserPackageResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConfirmedAt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPaymentExpiryTime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCommentPerDay();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getDurationDay();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTotalComment();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getTotalLikes();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getTotalShares();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getPricePerComment();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getAffiliatorFee();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getCommentatorFee();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getAdminFee();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getReceiptUrl();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCommentatorAccountSlot();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getAdminStatus();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getStatusRemarks();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.admin_user_package.GetDetailUserPackageResponseDataUser.serializeBinaryToWriter
    );
  }
  f = message.getAffiliator();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator.serializeBinaryToWriter
    );
  }
  f = message.getCommentatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.admin_user_package.GetDetailUserPackageResponseDataCommentator.serializeBinaryToWriter
    );
  }
  f = message.getAffiliatorOffersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer.serializeBinaryToWriter
    );
  }
  f = message.getCommentatorOffersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer.serializeBinaryToWriter
    );
  }
  f = message.getExpiryTime();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string confirmed_at = 3;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getConfirmedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setConfirmedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string payment_expiry_time = 4;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getPaymentExpiryTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setPaymentExpiryTime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 comment_per_day = 5;
 * @return {number}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getCommentPerDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setCommentPerDay = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 duration_day = 6;
 * @return {number}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getDurationDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setDurationDay = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 total_comment = 7;
 * @return {number}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getTotalComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setTotalComment = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 total_likes = 8;
 * @return {number}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getTotalLikes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setTotalLikes = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 total_shares = 9;
 * @return {number}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getTotalShares = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setTotalShares = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional double price_per_comment = 10;
 * @return {number}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getPricePerComment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setPricePerComment = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double total_price = 11;
 * @return {number}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double affiliator_fee = 12;
 * @return {number}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getAffiliatorFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setAffiliatorFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double commentator_fee = 13;
 * @return {number}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getCommentatorFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setCommentatorFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double admin_fee = 14;
 * @return {number}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getAdminFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setAdminFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional string receipt_url = 15;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getReceiptUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setReceiptUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional uint32 commentator_account_slot = 16;
 * @return {number}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getCommentatorAccountSlot = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setCommentatorAccountSlot = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string admin_status = 17;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getAdminStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setAdminStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string status_remarks = 18;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getStatusRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setStatusRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional GetDetailUserPackageResponseDataUser user = 19;
 * @return {?proto.admin_user_package.GetDetailUserPackageResponseDataUser}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getUser = function() {
  return /** @type{?proto.admin_user_package.GetDetailUserPackageResponseDataUser} */ (
    jspb.Message.getWrapperField(this, proto.admin_user_package.GetDetailUserPackageResponseDataUser, 19));
};


/**
 * @param {?proto.admin_user_package.GetDetailUserPackageResponseDataUser|undefined} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
*/
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.hasUser = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional GetDetailUserPackageResponseDataAffiliator affiliator = 20;
 * @return {?proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getAffiliator = function() {
  return /** @type{?proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator} */ (
    jspb.Message.getWrapperField(this, proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator, 20));
};


/**
 * @param {?proto.admin_user_package.GetDetailUserPackageResponseDataAffiliator|undefined} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
*/
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setAffiliator = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.clearAffiliator = function() {
  return this.setAffiliator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.hasAffiliator = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * repeated GetDetailUserPackageResponseDataCommentator commentators = 21;
 * @return {!Array<!proto.admin_user_package.GetDetailUserPackageResponseDataCommentator>}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getCommentatorsList = function() {
  return /** @type{!Array<!proto.admin_user_package.GetDetailUserPackageResponseDataCommentator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin_user_package.GetDetailUserPackageResponseDataCommentator, 21));
};


/**
 * @param {!Array<!proto.admin_user_package.GetDetailUserPackageResponseDataCommentator>} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
*/
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setCommentatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentator}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.addCommentators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.admin_user_package.GetDetailUserPackageResponseDataCommentator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.clearCommentatorsList = function() {
  return this.setCommentatorsList([]);
};


/**
 * repeated GetDetailUserPackageResponseDataAffiliatorOffer affiliator_offers = 22;
 * @return {!Array<!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer>}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getAffiliatorOffersList = function() {
  return /** @type{!Array<!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer, 22));
};


/**
 * @param {!Array<!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer>} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
*/
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setAffiliatorOffersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.addAffiliatorOffers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.admin_user_package.GetDetailUserPackageResponseDataAffiliatorOffer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.clearAffiliatorOffersList = function() {
  return this.setAffiliatorOffersList([]);
};


/**
 * repeated GetDetailUserPackageResponseDataCommentatorOffer commentator_offers = 23;
 * @return {!Array<!proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer>}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getCommentatorOffersList = function() {
  return /** @type{!Array<!proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer, 23));
};


/**
 * @param {!Array<!proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer>} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
*/
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setCommentatorOffersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.addCommentatorOffers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.admin_user_package.GetDetailUserPackageResponseDataCommentatorOffer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.clearCommentatorOffersList = function() {
  return this.setCommentatorOffersList([]);
};


/**
 * optional string expiry_time = 24;
 * @return {string}
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.getExpiryTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponseData.prototype.setExpiryTime = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.GetDetailUserPackageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.GetDetailUserPackageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.GetDetailUserPackageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_user_package.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_user_package.GetDetailUserPackageResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponse}
 */
proto.admin_user_package.GetDetailUserPackageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.GetDetailUserPackageResponse;
  return proto.admin_user_package.GetDetailUserPackageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.GetDetailUserPackageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponse}
 */
proto.admin_user_package.GetDetailUserPackageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_user_package.Meta;
      reader.readMessage(value,proto.admin_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_user_package.GetDetailUserPackageResponseData;
      reader.readMessage(value,proto.admin_user_package.GetDetailUserPackageResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.GetDetailUserPackageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.GetDetailUserPackageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.GetDetailUserPackageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.GetDetailUserPackageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_user_package.GetDetailUserPackageResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_user_package.Meta}
 */
proto.admin_user_package.GetDetailUserPackageResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_user_package.Meta, 1));
};


/**
 * @param {?proto.admin_user_package.Meta|undefined} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponse} returns this
*/
proto.admin_user_package.GetDetailUserPackageResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponse} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_user_package.GetDetailUserPackageResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetDetailUserPackageResponseData data = 2;
 * @return {?proto.admin_user_package.GetDetailUserPackageResponseData}
 */
proto.admin_user_package.GetDetailUserPackageResponse.prototype.getData = function() {
  return /** @type{?proto.admin_user_package.GetDetailUserPackageResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_user_package.GetDetailUserPackageResponseData, 2));
};


/**
 * @param {?proto.admin_user_package.GetDetailUserPackageResponseData|undefined} value
 * @return {!proto.admin_user_package.GetDetailUserPackageResponse} returns this
*/
proto.admin_user_package.GetDetailUserPackageResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_user_package.GetDetailUserPackageResponse} returns this
 */
proto.admin_user_package.GetDetailUserPackageResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_user_package.GetDetailUserPackageResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin_user_package.UserPackageOfferToAffiliatorRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.UserPackageOfferToAffiliatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.UserPackageOfferToAffiliatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.UserPackageOfferToAffiliatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userPackageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    affiliatorIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.UserPackageOfferToAffiliatorRequest}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.UserPackageOfferToAffiliatorRequest;
  return proto.admin_user_package.UserPackageOfferToAffiliatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.UserPackageOfferToAffiliatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.UserPackageOfferToAffiliatorRequest}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPackageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAffiliatorIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.UserPackageOfferToAffiliatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.UserPackageOfferToAffiliatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.UserPackageOfferToAffiliatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserPackageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAffiliatorIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string user_package_id = 1;
 * @return {string}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorRequest.prototype.getUserPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.UserPackageOfferToAffiliatorRequest} returns this
 */
proto.admin_user_package.UserPackageOfferToAffiliatorRequest.prototype.setUserPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string affiliator_ids = 2;
 * @return {!Array<string>}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorRequest.prototype.getAffiliatorIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.admin_user_package.UserPackageOfferToAffiliatorRequest} returns this
 */
proto.admin_user_package.UserPackageOfferToAffiliatorRequest.prototype.setAffiliatorIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.admin_user_package.UserPackageOfferToAffiliatorRequest} returns this
 */
proto.admin_user_package.UserPackageOfferToAffiliatorRequest.prototype.addAffiliatorIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin_user_package.UserPackageOfferToAffiliatorRequest} returns this
 */
proto.admin_user_package.UserPackageOfferToAffiliatorRequest.prototype.clearAffiliatorIdsList = function() {
  return this.setAffiliatorIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.UserPackageOfferToAffiliatorResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.UserPackageOfferToAffiliatorResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.UserPackageOfferToAffiliatorResponseData}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.UserPackageOfferToAffiliatorResponseData;
  return proto.admin_user_package.UserPackageOfferToAffiliatorResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.UserPackageOfferToAffiliatorResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.UserPackageOfferToAffiliatorResponseData}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.UserPackageOfferToAffiliatorResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.UserPackageOfferToAffiliatorResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.UserPackageOfferToAffiliatorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.UserPackageOfferToAffiliatorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_user_package.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_user_package.UserPackageOfferToAffiliatorResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.UserPackageOfferToAffiliatorResponse}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.UserPackageOfferToAffiliatorResponse;
  return proto.admin_user_package.UserPackageOfferToAffiliatorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.UserPackageOfferToAffiliatorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.UserPackageOfferToAffiliatorResponse}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_user_package.Meta;
      reader.readMessage(value,proto.admin_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_user_package.UserPackageOfferToAffiliatorResponseData;
      reader.readMessage(value,proto.admin_user_package.UserPackageOfferToAffiliatorResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.UserPackageOfferToAffiliatorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.UserPackageOfferToAffiliatorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_user_package.UserPackageOfferToAffiliatorResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_user_package.Meta}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_user_package.Meta, 1));
};


/**
 * @param {?proto.admin_user_package.Meta|undefined} value
 * @return {!proto.admin_user_package.UserPackageOfferToAffiliatorResponse} returns this
*/
proto.admin_user_package.UserPackageOfferToAffiliatorResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_user_package.UserPackageOfferToAffiliatorResponse} returns this
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserPackageOfferToAffiliatorResponseData data = 2;
 * @return {?proto.admin_user_package.UserPackageOfferToAffiliatorResponseData}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponse.prototype.getData = function() {
  return /** @type{?proto.admin_user_package.UserPackageOfferToAffiliatorResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_user_package.UserPackageOfferToAffiliatorResponseData, 2));
};


/**
 * @param {?proto.admin_user_package.UserPackageOfferToAffiliatorResponseData|undefined} value
 * @return {!proto.admin_user_package.UserPackageOfferToAffiliatorResponse} returns this
*/
proto.admin_user_package.UserPackageOfferToAffiliatorResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_user_package.UserPackageOfferToAffiliatorResponse} returns this
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_user_package.UserPackageOfferToAffiliatorResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.InactivateUserPackageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.InactivateUserPackageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.InactivateUserPackageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.InactivateUserPackageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userPackageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    remarks: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.InactivateUserPackageRequest}
 */
proto.admin_user_package.InactivateUserPackageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.InactivateUserPackageRequest;
  return proto.admin_user_package.InactivateUserPackageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.InactivateUserPackageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.InactivateUserPackageRequest}
 */
proto.admin_user_package.InactivateUserPackageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPackageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.InactivateUserPackageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.InactivateUserPackageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.InactivateUserPackageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.InactivateUserPackageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserPackageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user_package_id = 1;
 * @return {string}
 */
proto.admin_user_package.InactivateUserPackageRequest.prototype.getUserPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.InactivateUserPackageRequest} returns this
 */
proto.admin_user_package.InactivateUserPackageRequest.prototype.setUserPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string remarks = 2;
 * @return {string}
 */
proto.admin_user_package.InactivateUserPackageRequest.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_user_package.InactivateUserPackageRequest} returns this
 */
proto.admin_user_package.InactivateUserPackageRequest.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.InactivateUserPackageResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.InactivateUserPackageResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.InactivateUserPackageResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.InactivateUserPackageResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.InactivateUserPackageResponseData}
 */
proto.admin_user_package.InactivateUserPackageResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.InactivateUserPackageResponseData;
  return proto.admin_user_package.InactivateUserPackageResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.InactivateUserPackageResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.InactivateUserPackageResponseData}
 */
proto.admin_user_package.InactivateUserPackageResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.InactivateUserPackageResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.InactivateUserPackageResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.InactivateUserPackageResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.InactivateUserPackageResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_user_package.InactivateUserPackageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_user_package.InactivateUserPackageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_user_package.InactivateUserPackageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.InactivateUserPackageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_user_package.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_user_package.InactivateUserPackageResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_user_package.InactivateUserPackageResponse}
 */
proto.admin_user_package.InactivateUserPackageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_user_package.InactivateUserPackageResponse;
  return proto.admin_user_package.InactivateUserPackageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_user_package.InactivateUserPackageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_user_package.InactivateUserPackageResponse}
 */
proto.admin_user_package.InactivateUserPackageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_user_package.Meta;
      reader.readMessage(value,proto.admin_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_user_package.InactivateUserPackageResponseData;
      reader.readMessage(value,proto.admin_user_package.InactivateUserPackageResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_user_package.InactivateUserPackageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_user_package.InactivateUserPackageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_user_package.InactivateUserPackageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_user_package.InactivateUserPackageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_user_package.InactivateUserPackageResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_user_package.Meta}
 */
proto.admin_user_package.InactivateUserPackageResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_user_package.Meta, 1));
};


/**
 * @param {?proto.admin_user_package.Meta|undefined} value
 * @return {!proto.admin_user_package.InactivateUserPackageResponse} returns this
*/
proto.admin_user_package.InactivateUserPackageResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_user_package.InactivateUserPackageResponse} returns this
 */
proto.admin_user_package.InactivateUserPackageResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_user_package.InactivateUserPackageResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional InactivateUserPackageResponseData data = 2;
 * @return {?proto.admin_user_package.InactivateUserPackageResponseData}
 */
proto.admin_user_package.InactivateUserPackageResponse.prototype.getData = function() {
  return /** @type{?proto.admin_user_package.InactivateUserPackageResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_user_package.InactivateUserPackageResponseData, 2));
};


/**
 * @param {?proto.admin_user_package.InactivateUserPackageResponseData|undefined} value
 * @return {!proto.admin_user_package.InactivateUserPackageResponse} returns this
*/
proto.admin_user_package.InactivateUserPackageResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_user_package.InactivateUserPackageResponse} returns this
 */
proto.admin_user_package.InactivateUserPackageResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_user_package.InactivateUserPackageResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.admin_user_package);
