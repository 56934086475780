import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../pages/NotFound';
import CommentatorHome from '../pages/commentator/CommentatorHome';
import CommentatorPackageDetail from '../pages/commentator/CommentatorPackageDetail';
import CommentatorPackage from '../pages/commentator/CommentatorPackage';
import CommentatorProfile from '../pages/commentator/CommentatorProfile';
import CommentatorAccount from '../pages/commentator/CommentatorAccount';
import CommentatorAccountEdit from '../pages/commentator/CommentatorAccountEdit';
import CommentatorAccountNew from '../pages/commentator/CommentatorAccountNew';
import CommentatorClient from '../pages/commentator/CommentatorClient';
import CommentatorOrderDetail from '../pages/commentator/CommentatorOrderDetail';
import WithdrawalRequest from '../pages/WithdrawalRequest';
import HistoryPage from '../pages/History';
import WithdrawalRequestDetail from '../pages/WithdrawalRequestDetail';

interface CommentatorRouteProps { }

const CommentatorRoute: FC<CommentatorRouteProps> = ({ }) => {
    return (
        <Routes>
            <Route path='/' element={<CommentatorHome />} />
            <Route path='/package' element={<CommentatorPackage />} />
            <Route path='/package/:packageId' element={<CommentatorPackageDetail />} />
            <Route path='/account/:accountId' element={<CommentatorAccount />} />
            <Route path='/account/new' element={<CommentatorAccountNew />} />
            <Route path='/account/:accountId/edit' element={<CommentatorAccountEdit />} />
            <Route path='/account/:accountId/client' element={<CommentatorClient />} />
            <Route path='/:accountId/order/:orderId' element={<CommentatorOrderDetail />} />
            <Route path='/profile' element={<CommentatorProfile />} />
            <Route path='/withdraw' element={<WithdrawalRequest isCommentator />} />
            <Route path='/withdraw/:withdrawId' element={<WithdrawalRequestDetail isCommentator />} />
            <Route path='/history' element={<HistoryPage isCommentator />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}
export default CommentatorRoute;