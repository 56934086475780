// source: client_user_package.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.client_user_package.ConfirmPaymentUserPackageRequest', null, global);
goog.exportSymbol('proto.client_user_package.ConfirmPaymentUserPackageResponse', null, global);
goog.exportSymbol('proto.client_user_package.ConfirmPaymentUserPackageResponseData', null, global);
goog.exportSymbol('proto.client_user_package.CreateUserPackageRequest', null, global);
goog.exportSymbol('proto.client_user_package.CreateUserPackageResponse', null, global);
goog.exportSymbol('proto.client_user_package.CreateUserPackageResponseData', null, global);
goog.exportSymbol('proto.client_user_package.GetDetailUserPackageRequest', null, global);
goog.exportSymbol('proto.client_user_package.GetDetailUserPackageResponse', null, global);
goog.exportSymbol('proto.client_user_package.GetDetailUserPackageResponseData', null, global);
goog.exportSymbol('proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog', null, global);
goog.exportSymbol('proto.client_user_package.GetListPackageRequest', null, global);
goog.exportSymbol('proto.client_user_package.GetListPackageResponse', null, global);
goog.exportSymbol('proto.client_user_package.GetListPackageResponseData', null, global);
goog.exportSymbol('proto.client_user_package.GetListUserPackageRequest', null, global);
goog.exportSymbol('proto.client_user_package.GetListUserPackageResponse', null, global);
goog.exportSymbol('proto.client_user_package.GetListUserPackageResponseData', null, global);
goog.exportSymbol('proto.client_user_package.GetUserPackageStatusRequest', null, global);
goog.exportSymbol('proto.client_user_package.GetUserPackageStatusResponse', null, global);
goog.exportSymbol('proto.client_user_package.GetUserPackageStatusResponseData', null, global);
goog.exportSymbol('proto.client_user_package.Meta', null, global);
goog.exportSymbol('proto.client_user_package.Pagination', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.Meta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.Meta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.Meta.displayName = 'proto.client_user_package.Meta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.Pagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.Pagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.Pagination.displayName = 'proto.client_user_package.Pagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.GetListPackageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.GetListPackageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.GetListPackageRequest.displayName = 'proto.client_user_package.GetListPackageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.GetListPackageResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.GetListPackageResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.GetListPackageResponseData.displayName = 'proto.client_user_package.GetListPackageResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.GetListPackageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.client_user_package.GetListPackageResponse.repeatedFields_, null);
};
goog.inherits(proto.client_user_package.GetListPackageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.GetListPackageResponse.displayName = 'proto.client_user_package.GetListPackageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.CreateUserPackageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.CreateUserPackageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.CreateUserPackageRequest.displayName = 'proto.client_user_package.CreateUserPackageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.CreateUserPackageResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.CreateUserPackageResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.CreateUserPackageResponseData.displayName = 'proto.client_user_package.CreateUserPackageResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.CreateUserPackageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.CreateUserPackageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.CreateUserPackageResponse.displayName = 'proto.client_user_package.CreateUserPackageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.ConfirmPaymentUserPackageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.ConfirmPaymentUserPackageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.ConfirmPaymentUserPackageRequest.displayName = 'proto.client_user_package.ConfirmPaymentUserPackageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.ConfirmPaymentUserPackageResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.ConfirmPaymentUserPackageResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.ConfirmPaymentUserPackageResponseData.displayName = 'proto.client_user_package.ConfirmPaymentUserPackageResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.ConfirmPaymentUserPackageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.ConfirmPaymentUserPackageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.ConfirmPaymentUserPackageResponse.displayName = 'proto.client_user_package.ConfirmPaymentUserPackageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.GetListUserPackageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.GetListUserPackageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.GetListUserPackageRequest.displayName = 'proto.client_user_package.GetListUserPackageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.GetListUserPackageResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.GetListUserPackageResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.GetListUserPackageResponseData.displayName = 'proto.client_user_package.GetListUserPackageResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.GetListUserPackageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.client_user_package.GetListUserPackageResponse.repeatedFields_, null);
};
goog.inherits(proto.client_user_package.GetListUserPackageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.GetListUserPackageResponse.displayName = 'proto.client_user_package.GetListUserPackageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.GetDetailUserPackageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.GetDetailUserPackageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.GetDetailUserPackageRequest.displayName = 'proto.client_user_package.GetDetailUserPackageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.displayName = 'proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.GetDetailUserPackageResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.client_user_package.GetDetailUserPackageResponseData.repeatedFields_, null);
};
goog.inherits(proto.client_user_package.GetDetailUserPackageResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.GetDetailUserPackageResponseData.displayName = 'proto.client_user_package.GetDetailUserPackageResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.GetDetailUserPackageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.GetDetailUserPackageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.GetDetailUserPackageResponse.displayName = 'proto.client_user_package.GetDetailUserPackageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.GetUserPackageStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.GetUserPackageStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.GetUserPackageStatusRequest.displayName = 'proto.client_user_package.GetUserPackageStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.GetUserPackageStatusResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.GetUserPackageStatusResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.GetUserPackageStatusResponseData.displayName = 'proto.client_user_package.GetUserPackageStatusResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.client_user_package.GetUserPackageStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.client_user_package.GetUserPackageStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.client_user_package.GetUserPackageStatusResponse.displayName = 'proto.client_user_package.GetUserPackageStatusResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.Meta.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.Meta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.Meta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.Meta.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.Meta}
 */
proto.client_user_package.Meta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.Meta;
  return proto.client_user_package.Meta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.Meta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.Meta}
 */
proto.client_user_package.Meta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.Meta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.Meta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.Meta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.Meta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.client_user_package.Meta.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.Meta} returns this
 */
proto.client_user_package.Meta.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 status = 2;
 * @return {number}
 */
proto.client_user_package.Meta.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.Meta} returns this
 */
proto.client_user_package.Meta.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.client_user_package.Meta.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.Meta} returns this
 */
proto.client_user_package.Meta.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.Pagination.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.Pagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.Pagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.Pagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    prev: jspb.Message.getFieldWithDefault(msg, 3, 0),
    next: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.Pagination}
 */
proto.client_user_package.Pagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.Pagination;
  return proto.client_user_package.Pagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.Pagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.Pagination}
 */
proto.client_user_package.Pagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrev(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNext(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPages(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.Pagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.Pagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.Pagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.Pagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPrev();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNext();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.client_user_package.Pagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.Pagination} returns this
 */
proto.client_user_package.Pagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.client_user_package.Pagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.Pagination} returns this
 */
proto.client_user_package.Pagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 prev = 3;
 * @return {number}
 */
proto.client_user_package.Pagination.prototype.getPrev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.Pagination} returns this
 */
proto.client_user_package.Pagination.prototype.setPrev = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 next = 4;
 * @return {number}
 */
proto.client_user_package.Pagination.prototype.getNext = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.Pagination} returns this
 */
proto.client_user_package.Pagination.prototype.setNext = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 total_pages = 5;
 * @return {number}
 */
proto.client_user_package.Pagination.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.Pagination} returns this
 */
proto.client_user_package.Pagination.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 total_records = 6;
 * @return {number}
 */
proto.client_user_package.Pagination.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.Pagination} returns this
 */
proto.client_user_package.Pagination.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.GetListPackageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.GetListPackageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.GetListPackageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetListPackageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.GetListPackageRequest}
 */
proto.client_user_package.GetListPackageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.GetListPackageRequest;
  return proto.client_user_package.GetListPackageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.GetListPackageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.GetListPackageRequest}
 */
proto.client_user_package.GetListPackageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.GetListPackageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.GetListPackageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.GetListPackageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetListPackageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.GetListPackageResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.GetListPackageResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.GetListPackageResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetListPackageResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pricePerComment: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    minimumCommentPerDay: jspb.Message.getFieldWithDefault(msg, 4, 0),
    maximumCommentPerDay: jspb.Message.getFieldWithDefault(msg, 5, 0),
    durationDay: jspb.Message.getFieldWithDefault(msg, 6, 0),
    allowPositiveComment: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    allowNegativeComment: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    allowTagFriend: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    allowMultipleOrderPerDay: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    allowLikes: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    allowShares: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    orderWorkDayDuration: jspb.Message.getFieldWithDefault(msg, 13, 0),
    likesToCommentPercentage: jspb.Message.getFieldWithDefault(msg, 14, 0),
    shareToCommentPercentage: jspb.Message.getFieldWithDefault(msg, 15, 0),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 16, ""),
    allowToPurchase: jspb.Message.getBooleanFieldWithDefault(msg, 17, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.GetListPackageResponseData}
 */
proto.client_user_package.GetListPackageResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.GetListPackageResponseData;
  return proto.client_user_package.GetListPackageResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.GetListPackageResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.GetListPackageResponseData}
 */
proto.client_user_package.GetListPackageResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPricePerComment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinimumCommentPerDay(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaximumCommentPerDay(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationDay(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowPositiveComment(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowNegativeComment(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowTagFriend(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowMultipleOrderPerDay(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowLikes(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowShares(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrderWorkDayDuration(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLikesToCommentPercentage(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShareToCommentPercentage(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowToPurchase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.GetListPackageResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.GetListPackageResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.GetListPackageResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetListPackageResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPricePerComment();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMinimumCommentPerDay();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMaximumCommentPerDay();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getDurationDay();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getAllowPositiveComment();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAllowNegativeComment();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getAllowTagFriend();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getAllowMultipleOrderPerDay();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getAllowLikes();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getAllowShares();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getOrderWorkDayDuration();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getLikesToCommentPercentage();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getShareToCommentPercentage();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAllowToPurchase();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double price_per_comment = 3;
 * @return {number}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getPricePerComment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setPricePerComment = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional uint32 minimum_comment_per_day = 4;
 * @return {number}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getMinimumCommentPerDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setMinimumCommentPerDay = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 maximum_comment_per_day = 5;
 * @return {number}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getMaximumCommentPerDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setMaximumCommentPerDay = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 duration_day = 6;
 * @return {number}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getDurationDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setDurationDay = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool allow_positive_comment = 7;
 * @return {boolean}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getAllowPositiveComment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setAllowPositiveComment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool allow_negative_comment = 8;
 * @return {boolean}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getAllowNegativeComment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setAllowNegativeComment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool allow_tag_friend = 9;
 * @return {boolean}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getAllowTagFriend = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setAllowTagFriend = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool allow_multiple_order_per_day = 10;
 * @return {boolean}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getAllowMultipleOrderPerDay = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setAllowMultipleOrderPerDay = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool allow_likes = 11;
 * @return {boolean}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getAllowLikes = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setAllowLikes = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool allow_shares = 12;
 * @return {boolean}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getAllowShares = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setAllowShares = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional uint32 order_work_day_duration = 13;
 * @return {number}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getOrderWorkDayDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setOrderWorkDayDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint32 likes_to_comment_percentage = 14;
 * @return {number}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getLikesToCommentPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setLikesToCommentPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint32 share_to_comment_percentage = 15;
 * @return {number}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getShareToCommentPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setShareToCommentPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string logo_url = 16;
 * @return {string}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool allow_to_purchase = 17;
 * @return {boolean}
 */
proto.client_user_package.GetListPackageResponseData.prototype.getAllowToPurchase = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.client_user_package.GetListPackageResponseData} returns this
 */
proto.client_user_package.GetListPackageResponseData.prototype.setAllowToPurchase = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.client_user_package.GetListPackageResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.GetListPackageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.GetListPackageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.GetListPackageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetListPackageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_user_package.Meta.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.client_user_package.GetListPackageResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.GetListPackageResponse}
 */
proto.client_user_package.GetListPackageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.GetListPackageResponse;
  return proto.client_user_package.GetListPackageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.GetListPackageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.GetListPackageResponse}
 */
proto.client_user_package.GetListPackageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_user_package.Meta;
      reader.readMessage(value,proto.client_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_user_package.GetListPackageResponseData;
      reader.readMessage(value,proto.client_user_package.GetListPackageResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.GetListPackageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.GetListPackageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.GetListPackageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetListPackageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.client_user_package.GetListPackageResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_user_package.Meta}
 */
proto.client_user_package.GetListPackageResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_user_package.Meta, 1));
};


/**
 * @param {?proto.client_user_package.Meta|undefined} value
 * @return {!proto.client_user_package.GetListPackageResponse} returns this
*/
proto.client_user_package.GetListPackageResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_user_package.GetListPackageResponse} returns this
 */
proto.client_user_package.GetListPackageResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_user_package.GetListPackageResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated GetListPackageResponseData data = 2;
 * @return {!Array<!proto.client_user_package.GetListPackageResponseData>}
 */
proto.client_user_package.GetListPackageResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.client_user_package.GetListPackageResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.client_user_package.GetListPackageResponseData, 2));
};


/**
 * @param {!Array<!proto.client_user_package.GetListPackageResponseData>} value
 * @return {!proto.client_user_package.GetListPackageResponse} returns this
*/
proto.client_user_package.GetListPackageResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.client_user_package.GetListPackageResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.client_user_package.GetListPackageResponseData}
 */
proto.client_user_package.GetListPackageResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.client_user_package.GetListPackageResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.client_user_package.GetListPackageResponse} returns this
 */
proto.client_user_package.GetListPackageResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.CreateUserPackageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.CreateUserPackageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.CreateUserPackageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.CreateUserPackageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    packageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    commentPerDay: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.CreateUserPackageRequest}
 */
proto.client_user_package.CreateUserPackageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.CreateUserPackageRequest;
  return proto.client_user_package.CreateUserPackageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.CreateUserPackageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.CreateUserPackageRequest}
 */
proto.client_user_package.CreateUserPackageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommentPerDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.CreateUserPackageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.CreateUserPackageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.CreateUserPackageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.CreateUserPackageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPackageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommentPerDay();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string package_id = 1;
 * @return {string}
 */
proto.client_user_package.CreateUserPackageRequest.prototype.getPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.CreateUserPackageRequest} returns this
 */
proto.client_user_package.CreateUserPackageRequest.prototype.setPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 comment_per_day = 2;
 * @return {number}
 */
proto.client_user_package.CreateUserPackageRequest.prototype.getCommentPerDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.CreateUserPackageRequest} returns this
 */
proto.client_user_package.CreateUserPackageRequest.prototype.setCommentPerDay = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.CreateUserPackageResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.CreateUserPackageResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.CreateUserPackageResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.CreateUserPackageResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    userPackageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentExpiryTime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.CreateUserPackageResponseData}
 */
proto.client_user_package.CreateUserPackageResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.CreateUserPackageResponseData;
  return proto.client_user_package.CreateUserPackageResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.CreateUserPackageResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.CreateUserPackageResponseData}
 */
proto.client_user_package.CreateUserPackageResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPackageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentExpiryTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.CreateUserPackageResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.CreateUserPackageResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.CreateUserPackageResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.CreateUserPackageResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserPackageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentExpiryTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string user_package_id = 1;
 * @return {string}
 */
proto.client_user_package.CreateUserPackageResponseData.prototype.getUserPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.CreateUserPackageResponseData} returns this
 */
proto.client_user_package.CreateUserPackageResponseData.prototype.setUserPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string payment_expiry_time = 2;
 * @return {string}
 */
proto.client_user_package.CreateUserPackageResponseData.prototype.getPaymentExpiryTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.CreateUserPackageResponseData} returns this
 */
proto.client_user_package.CreateUserPackageResponseData.prototype.setPaymentExpiryTime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double total_price = 3;
 * @return {number}
 */
proto.client_user_package.CreateUserPackageResponseData.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.CreateUserPackageResponseData} returns this
 */
proto.client_user_package.CreateUserPackageResponseData.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.CreateUserPackageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.CreateUserPackageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.CreateUserPackageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.CreateUserPackageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_user_package.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.client_user_package.CreateUserPackageResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.CreateUserPackageResponse}
 */
proto.client_user_package.CreateUserPackageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.CreateUserPackageResponse;
  return proto.client_user_package.CreateUserPackageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.CreateUserPackageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.CreateUserPackageResponse}
 */
proto.client_user_package.CreateUserPackageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_user_package.Meta;
      reader.readMessage(value,proto.client_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_user_package.CreateUserPackageResponseData;
      reader.readMessage(value,proto.client_user_package.CreateUserPackageResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.CreateUserPackageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.CreateUserPackageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.CreateUserPackageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.CreateUserPackageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.client_user_package.CreateUserPackageResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_user_package.Meta}
 */
proto.client_user_package.CreateUserPackageResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_user_package.Meta, 1));
};


/**
 * @param {?proto.client_user_package.Meta|undefined} value
 * @return {!proto.client_user_package.CreateUserPackageResponse} returns this
*/
proto.client_user_package.CreateUserPackageResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_user_package.CreateUserPackageResponse} returns this
 */
proto.client_user_package.CreateUserPackageResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_user_package.CreateUserPackageResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateUserPackageResponseData data = 2;
 * @return {?proto.client_user_package.CreateUserPackageResponseData}
 */
proto.client_user_package.CreateUserPackageResponse.prototype.getData = function() {
  return /** @type{?proto.client_user_package.CreateUserPackageResponseData} */ (
    jspb.Message.getWrapperField(this, proto.client_user_package.CreateUserPackageResponseData, 2));
};


/**
 * @param {?proto.client_user_package.CreateUserPackageResponseData|undefined} value
 * @return {!proto.client_user_package.CreateUserPackageResponse} returns this
*/
proto.client_user_package.CreateUserPackageResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_user_package.CreateUserPackageResponse} returns this
 */
proto.client_user_package.CreateUserPackageResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_user_package.CreateUserPackageResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.ConfirmPaymentUserPackageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.ConfirmPaymentUserPackageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.ConfirmPaymentUserPackageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.ConfirmPaymentUserPackageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userPackageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    receiptPath: jspb.Message.getFieldWithDefault(msg, 2, ""),
    receiptPathType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.ConfirmPaymentUserPackageRequest}
 */
proto.client_user_package.ConfirmPaymentUserPackageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.ConfirmPaymentUserPackageRequest;
  return proto.client_user_package.ConfirmPaymentUserPackageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.ConfirmPaymentUserPackageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.ConfirmPaymentUserPackageRequest}
 */
proto.client_user_package.ConfirmPaymentUserPackageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPackageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiptPath(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiptPathType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.ConfirmPaymentUserPackageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.ConfirmPaymentUserPackageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.ConfirmPaymentUserPackageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.ConfirmPaymentUserPackageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserPackageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReceiptPath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReceiptPathType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_package_id = 1;
 * @return {string}
 */
proto.client_user_package.ConfirmPaymentUserPackageRequest.prototype.getUserPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.ConfirmPaymentUserPackageRequest} returns this
 */
proto.client_user_package.ConfirmPaymentUserPackageRequest.prototype.setUserPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string receipt_path = 2;
 * @return {string}
 */
proto.client_user_package.ConfirmPaymentUserPackageRequest.prototype.getReceiptPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.ConfirmPaymentUserPackageRequest} returns this
 */
proto.client_user_package.ConfirmPaymentUserPackageRequest.prototype.setReceiptPath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string receipt_path_type = 3;
 * @return {string}
 */
proto.client_user_package.ConfirmPaymentUserPackageRequest.prototype.getReceiptPathType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.ConfirmPaymentUserPackageRequest} returns this
 */
proto.client_user_package.ConfirmPaymentUserPackageRequest.prototype.setReceiptPathType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.ConfirmPaymentUserPackageResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.ConfirmPaymentUserPackageResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.ConfirmPaymentUserPackageResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.ConfirmPaymentUserPackageResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.ConfirmPaymentUserPackageResponseData}
 */
proto.client_user_package.ConfirmPaymentUserPackageResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.ConfirmPaymentUserPackageResponseData;
  return proto.client_user_package.ConfirmPaymentUserPackageResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.ConfirmPaymentUserPackageResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.ConfirmPaymentUserPackageResponseData}
 */
proto.client_user_package.ConfirmPaymentUserPackageResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.ConfirmPaymentUserPackageResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.ConfirmPaymentUserPackageResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.ConfirmPaymentUserPackageResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.ConfirmPaymentUserPackageResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.ConfirmPaymentUserPackageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.ConfirmPaymentUserPackageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.ConfirmPaymentUserPackageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.ConfirmPaymentUserPackageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_user_package.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.client_user_package.ConfirmPaymentUserPackageResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.ConfirmPaymentUserPackageResponse}
 */
proto.client_user_package.ConfirmPaymentUserPackageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.ConfirmPaymentUserPackageResponse;
  return proto.client_user_package.ConfirmPaymentUserPackageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.ConfirmPaymentUserPackageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.ConfirmPaymentUserPackageResponse}
 */
proto.client_user_package.ConfirmPaymentUserPackageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_user_package.Meta;
      reader.readMessage(value,proto.client_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_user_package.ConfirmPaymentUserPackageResponseData;
      reader.readMessage(value,proto.client_user_package.ConfirmPaymentUserPackageResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.ConfirmPaymentUserPackageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.ConfirmPaymentUserPackageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.ConfirmPaymentUserPackageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.ConfirmPaymentUserPackageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.client_user_package.ConfirmPaymentUserPackageResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_user_package.Meta}
 */
proto.client_user_package.ConfirmPaymentUserPackageResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_user_package.Meta, 1));
};


/**
 * @param {?proto.client_user_package.Meta|undefined} value
 * @return {!proto.client_user_package.ConfirmPaymentUserPackageResponse} returns this
*/
proto.client_user_package.ConfirmPaymentUserPackageResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_user_package.ConfirmPaymentUserPackageResponse} returns this
 */
proto.client_user_package.ConfirmPaymentUserPackageResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_user_package.ConfirmPaymentUserPackageResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ConfirmPaymentUserPackageResponseData data = 2;
 * @return {?proto.client_user_package.ConfirmPaymentUserPackageResponseData}
 */
proto.client_user_package.ConfirmPaymentUserPackageResponse.prototype.getData = function() {
  return /** @type{?proto.client_user_package.ConfirmPaymentUserPackageResponseData} */ (
    jspb.Message.getWrapperField(this, proto.client_user_package.ConfirmPaymentUserPackageResponseData, 2));
};


/**
 * @param {?proto.client_user_package.ConfirmPaymentUserPackageResponseData|undefined} value
 * @return {!proto.client_user_package.ConfirmPaymentUserPackageResponse} returns this
*/
proto.client_user_package.ConfirmPaymentUserPackageResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_user_package.ConfirmPaymentUserPackageResponse} returns this
 */
proto.client_user_package.ConfirmPaymentUserPackageResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_user_package.ConfirmPaymentUserPackageResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.GetListUserPackageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.GetListUserPackageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.GetListUserPackageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetListUserPackageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paymentStatus: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.GetListUserPackageRequest}
 */
proto.client_user_package.GetListUserPackageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.GetListUserPackageRequest;
  return proto.client_user_package.GetListUserPackageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.GetListUserPackageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.GetListUserPackageRequest}
 */
proto.client_user_package.GetListUserPackageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.GetListUserPackageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.GetListUserPackageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.GetListUserPackageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetListUserPackageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPaymentStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.client_user_package.GetListUserPackageRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetListUserPackageRequest} returns this
 */
proto.client_user_package.GetListUserPackageRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.client_user_package.GetListUserPackageRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetListUserPackageRequest} returns this
 */
proto.client_user_package.GetListUserPackageRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string payment_status = 3;
 * @return {string}
 */
proto.client_user_package.GetListUserPackageRequest.prototype.getPaymentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetListUserPackageRequest} returns this
 */
proto.client_user_package.GetListUserPackageRequest.prototype.setPaymentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.GetListUserPackageResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.GetListUserPackageResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetListUserPackageResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    packageName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    commentPerDay: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalComment: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalLikes: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalShares: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    expiryTime: jspb.Message.getFieldWithDefault(msg, 9, ""),
    paymentExpiryTime: jspb.Message.getFieldWithDefault(msg, 10, ""),
    confirmationExpiryTime: jspb.Message.getFieldWithDefault(msg, 11, ""),
    paymentStatus: jspb.Message.getFieldWithDefault(msg, 12, ""),
    paymentStatusRemarks: jspb.Message.getFieldWithDefault(msg, 13, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.GetListUserPackageResponseData}
 */
proto.client_user_package.GetListUserPackageResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.GetListUserPackageResponseData;
  return proto.client_user_package.GetListUserPackageResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.GetListUserPackageResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.GetListUserPackageResponseData}
 */
proto.client_user_package.GetListUserPackageResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommentPerDay(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalComment(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalLikes(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalShares(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPrice(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiryTime(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentExpiryTime(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationExpiryTime(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentStatusRemarks(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.GetListUserPackageResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.GetListUserPackageResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetListUserPackageResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPackageName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCommentPerDay();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTotalComment();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalLikes();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTotalShares();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getExpiryTime();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPaymentExpiryTime();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getConfirmationExpiryTime();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPaymentStatus();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPaymentStatusRemarks();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetListUserPackageResponseData} returns this
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetListUserPackageResponseData} returns this
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string package_name = 3;
 * @return {string}
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.getPackageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetListUserPackageResponseData} returns this
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.setPackageName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 comment_per_day = 4;
 * @return {number}
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.getCommentPerDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetListUserPackageResponseData} returns this
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.setCommentPerDay = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 total_comment = 5;
 * @return {number}
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.getTotalComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetListUserPackageResponseData} returns this
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.setTotalComment = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 total_likes = 6;
 * @return {number}
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.getTotalLikes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetListUserPackageResponseData} returns this
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.setTotalLikes = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 total_shares = 7;
 * @return {number}
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.getTotalShares = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetListUserPackageResponseData} returns this
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.setTotalShares = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional double total_price = 8;
 * @return {number}
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetListUserPackageResponseData} returns this
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string expiry_time = 9;
 * @return {string}
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.getExpiryTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetListUserPackageResponseData} returns this
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.setExpiryTime = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string payment_expiry_time = 10;
 * @return {string}
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.getPaymentExpiryTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetListUserPackageResponseData} returns this
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.setPaymentExpiryTime = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string confirmation_expiry_time = 11;
 * @return {string}
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.getConfirmationExpiryTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetListUserPackageResponseData} returns this
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.setConfirmationExpiryTime = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string payment_status = 12;
 * @return {string}
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.getPaymentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetListUserPackageResponseData} returns this
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.setPaymentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string payment_status_remarks = 13;
 * @return {string}
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.getPaymentStatusRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetListUserPackageResponseData} returns this
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.setPaymentStatusRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string updated_at = 14;
 * @return {string}
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetListUserPackageResponseData} returns this
 */
proto.client_user_package.GetListUserPackageResponseData.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.client_user_package.GetListUserPackageResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.GetListUserPackageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.GetListUserPackageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.GetListUserPackageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetListUserPackageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_user_package.Meta.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.client_user_package.Pagination.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.client_user_package.GetListUserPackageResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.GetListUserPackageResponse}
 */
proto.client_user_package.GetListUserPackageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.GetListUserPackageResponse;
  return proto.client_user_package.GetListUserPackageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.GetListUserPackageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.GetListUserPackageResponse}
 */
proto.client_user_package.GetListUserPackageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_user_package.Meta;
      reader.readMessage(value,proto.client_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_user_package.Pagination;
      reader.readMessage(value,proto.client_user_package.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.client_user_package.GetListUserPackageResponseData;
      reader.readMessage(value,proto.client_user_package.GetListUserPackageResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.GetListUserPackageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.GetListUserPackageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.GetListUserPackageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetListUserPackageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.client_user_package.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.client_user_package.GetListUserPackageResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_user_package.Meta}
 */
proto.client_user_package.GetListUserPackageResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_user_package.Meta, 1));
};


/**
 * @param {?proto.client_user_package.Meta|undefined} value
 * @return {!proto.client_user_package.GetListUserPackageResponse} returns this
*/
proto.client_user_package.GetListUserPackageResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_user_package.GetListUserPackageResponse} returns this
 */
proto.client_user_package.GetListUserPackageResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_user_package.GetListUserPackageResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.client_user_package.Pagination}
 */
proto.client_user_package.GetListUserPackageResponse.prototype.getPagination = function() {
  return /** @type{?proto.client_user_package.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.client_user_package.Pagination, 2));
};


/**
 * @param {?proto.client_user_package.Pagination|undefined} value
 * @return {!proto.client_user_package.GetListUserPackageResponse} returns this
*/
proto.client_user_package.GetListUserPackageResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_user_package.GetListUserPackageResponse} returns this
 */
proto.client_user_package.GetListUserPackageResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_user_package.GetListUserPackageResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated GetListUserPackageResponseData data = 3;
 * @return {!Array<!proto.client_user_package.GetListUserPackageResponseData>}
 */
proto.client_user_package.GetListUserPackageResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.client_user_package.GetListUserPackageResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.client_user_package.GetListUserPackageResponseData, 3));
};


/**
 * @param {!Array<!proto.client_user_package.GetListUserPackageResponseData>} value
 * @return {!proto.client_user_package.GetListUserPackageResponse} returns this
*/
proto.client_user_package.GetListUserPackageResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.client_user_package.GetListUserPackageResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.client_user_package.GetListUserPackageResponseData}
 */
proto.client_user_package.GetListUserPackageResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.client_user_package.GetListUserPackageResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.client_user_package.GetListUserPackageResponse} returns this
 */
proto.client_user_package.GetListUserPackageResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.GetDetailUserPackageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.GetDetailUserPackageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.GetDetailUserPackageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetDetailUserPackageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.GetDetailUserPackageRequest}
 */
proto.client_user_package.GetDetailUserPackageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.GetDetailUserPackageRequest;
  return proto.client_user_package.GetDetailUserPackageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.GetDetailUserPackageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.GetDetailUserPackageRequest}
 */
proto.client_user_package.GetDetailUserPackageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.GetDetailUserPackageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.GetDetailUserPackageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.GetDetailUserPackageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetDetailUserPackageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_user_package.GetDetailUserPackageRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetDetailUserPackageRequest} returns this
 */
proto.client_user_package.GetDetailUserPackageRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromPaymentStatus: jspb.Message.getFieldWithDefault(msg, 2, ""),
    toPaymentStatus: jspb.Message.getFieldWithDefault(msg, 3, ""),
    remarks: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog}
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog;
  return proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog}
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromPaymentStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToPaymentStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromPaymentStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getToPaymentStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string from_payment_status = 2;
 * @return {string}
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.getFromPaymentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.setFromPaymentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string to_payment_status = 3;
 * @return {string}
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.getToPaymentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.setToPaymentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string remarks = 4;
 * @return {string}
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string created_at = 5;
 * @return {string}
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.client_user_package.GetDetailUserPackageResponseData.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.GetDetailUserPackageResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.GetDetailUserPackageResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetDetailUserPackageResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentExpiryTime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    commentPerDay: jspb.Message.getFieldWithDefault(msg, 3, 0),
    durationDay: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalComment: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalLikes: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalShares: jspb.Message.getFieldWithDefault(msg, 7, 0),
    pricePerComment: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    statusLogsList: jspb.Message.toObjectList(msg.getStatusLogsList(),
    proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.toObject, includeInstance),
    paymentStatus: jspb.Message.getFieldWithDefault(msg, 11, ""),
    paymentStatusRemarks: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.GetDetailUserPackageResponseData}
 */
proto.client_user_package.GetDetailUserPackageResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.GetDetailUserPackageResponseData;
  return proto.client_user_package.GetDetailUserPackageResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.GetDetailUserPackageResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.GetDetailUserPackageResponseData}
 */
proto.client_user_package.GetDetailUserPackageResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentExpiryTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommentPerDay(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationDay(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalComment(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalLikes(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalShares(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPricePerComment(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPrice(value);
      break;
    case 10:
      var value = new proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog;
      reader.readMessage(value,proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.deserializeBinaryFromReader);
      msg.addStatusLogs(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentStatus(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentStatusRemarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.GetDetailUserPackageResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.GetDetailUserPackageResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetDetailUserPackageResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentExpiryTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCommentPerDay();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getDurationDay();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTotalComment();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalLikes();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTotalShares();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getPricePerComment();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getStatusLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog.serializeBinaryToWriter
    );
  }
  f = message.getPaymentStatus();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPaymentStatusRemarks();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string payment_expiry_time = 2;
 * @return {string}
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.getPaymentExpiryTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.setPaymentExpiryTime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 comment_per_day = 3;
 * @return {number}
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.getCommentPerDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.setCommentPerDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 duration_day = 4;
 * @return {number}
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.getDurationDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.setDurationDay = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 total_comment = 5;
 * @return {number}
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.getTotalComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.setTotalComment = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 total_likes = 6;
 * @return {number}
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.getTotalLikes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.setTotalLikes = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 total_shares = 7;
 * @return {number}
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.getTotalShares = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.setTotalShares = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional double price_per_comment = 8;
 * @return {number}
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.getPricePerComment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.setPricePerComment = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double total_price = 9;
 * @return {number}
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated GetDetailUserPackageResponseDataPaymentStatusLog status_logs = 10;
 * @return {!Array<!proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog>}
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.getStatusLogsList = function() {
  return /** @type{!Array<!proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog, 10));
};


/**
 * @param {!Array<!proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog>} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseData} returns this
*/
proto.client_user_package.GetDetailUserPackageResponseData.prototype.setStatusLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog}
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.addStatusLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.client_user_package.GetDetailUserPackageResponseDataPaymentStatusLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.client_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.clearStatusLogsList = function() {
  return this.setStatusLogsList([]);
};


/**
 * optional string payment_status = 11;
 * @return {string}
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.getPaymentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.setPaymentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string payment_status_remarks = 12;
 * @return {string}
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.getPaymentStatusRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponseData} returns this
 */
proto.client_user_package.GetDetailUserPackageResponseData.prototype.setPaymentStatusRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.GetDetailUserPackageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.GetDetailUserPackageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.GetDetailUserPackageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetDetailUserPackageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_user_package.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.client_user_package.GetDetailUserPackageResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.GetDetailUserPackageResponse}
 */
proto.client_user_package.GetDetailUserPackageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.GetDetailUserPackageResponse;
  return proto.client_user_package.GetDetailUserPackageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.GetDetailUserPackageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.GetDetailUserPackageResponse}
 */
proto.client_user_package.GetDetailUserPackageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_user_package.Meta;
      reader.readMessage(value,proto.client_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_user_package.GetDetailUserPackageResponseData;
      reader.readMessage(value,proto.client_user_package.GetDetailUserPackageResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.GetDetailUserPackageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.GetDetailUserPackageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.GetDetailUserPackageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetDetailUserPackageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.client_user_package.GetDetailUserPackageResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_user_package.Meta}
 */
proto.client_user_package.GetDetailUserPackageResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_user_package.Meta, 1));
};


/**
 * @param {?proto.client_user_package.Meta|undefined} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponse} returns this
*/
proto.client_user_package.GetDetailUserPackageResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_user_package.GetDetailUserPackageResponse} returns this
 */
proto.client_user_package.GetDetailUserPackageResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_user_package.GetDetailUserPackageResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetDetailUserPackageResponseData data = 2;
 * @return {?proto.client_user_package.GetDetailUserPackageResponseData}
 */
proto.client_user_package.GetDetailUserPackageResponse.prototype.getData = function() {
  return /** @type{?proto.client_user_package.GetDetailUserPackageResponseData} */ (
    jspb.Message.getWrapperField(this, proto.client_user_package.GetDetailUserPackageResponseData, 2));
};


/**
 * @param {?proto.client_user_package.GetDetailUserPackageResponseData|undefined} value
 * @return {!proto.client_user_package.GetDetailUserPackageResponse} returns this
*/
proto.client_user_package.GetDetailUserPackageResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_user_package.GetDetailUserPackageResponse} returns this
 */
proto.client_user_package.GetDetailUserPackageResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_user_package.GetDetailUserPackageResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.GetUserPackageStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.GetUserPackageStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.GetUserPackageStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetUserPackageStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.GetUserPackageStatusRequest}
 */
proto.client_user_package.GetUserPackageStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.GetUserPackageStatusRequest;
  return proto.client_user_package.GetUserPackageStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.GetUserPackageStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.GetUserPackageStatusRequest}
 */
proto.client_user_package.GetUserPackageStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.GetUserPackageStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.GetUserPackageStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.GetUserPackageStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetUserPackageStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.GetUserPackageStatusResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.GetUserPackageStatusResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetUserPackageStatusResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    userPackageStatus: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    packageId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    packageName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    todayRemainingComment: jspb.Message.getFieldWithDefault(msg, 5, 0),
    remainingDurationDay: jspb.Message.getFieldWithDefault(msg, 6, 0),
    allowLikes: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    allowShares: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    commentatorAccountCount: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.GetUserPackageStatusResponseData}
 */
proto.client_user_package.GetUserPackageStatusResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.GetUserPackageStatusResponseData;
  return proto.client_user_package.GetUserPackageStatusResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.GetUserPackageStatusResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.GetUserPackageStatusResponseData}
 */
proto.client_user_package.GetUserPackageStatusResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPackageStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTodayRemainingComment(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRemainingDurationDay(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowLikes(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowShares(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommentatorAccountCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.GetUserPackageStatusResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.GetUserPackageStatusResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetUserPackageStatusResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserPackageStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPackageId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPackageName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTodayRemainingComment();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getRemainingDurationDay();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getAllowLikes();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAllowShares();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCommentatorAccountCount();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * optional string user_package_status = 1;
 * @return {string}
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.getUserPackageStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetUserPackageStatusResponseData} returns this
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.setUserPackageStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetUserPackageStatusResponseData} returns this
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string package_id = 3;
 * @return {string}
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.getPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetUserPackageStatusResponseData} returns this
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.setPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string package_name = 4;
 * @return {string}
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.getPackageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.client_user_package.GetUserPackageStatusResponseData} returns this
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.setPackageName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 today_remaining_comment = 5;
 * @return {number}
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.getTodayRemainingComment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetUserPackageStatusResponseData} returns this
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.setTodayRemainingComment = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 remaining_duration_day = 6;
 * @return {number}
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.getRemainingDurationDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetUserPackageStatusResponseData} returns this
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.setRemainingDurationDay = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool allow_likes = 7;
 * @return {boolean}
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.getAllowLikes = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.client_user_package.GetUserPackageStatusResponseData} returns this
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.setAllowLikes = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool allow_shares = 8;
 * @return {boolean}
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.getAllowShares = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.client_user_package.GetUserPackageStatusResponseData} returns this
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.setAllowShares = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional uint32 commentator_account_count = 9;
 * @return {number}
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.getCommentatorAccountCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.client_user_package.GetUserPackageStatusResponseData} returns this
 */
proto.client_user_package.GetUserPackageStatusResponseData.prototype.setCommentatorAccountCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.client_user_package.GetUserPackageStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.client_user_package.GetUserPackageStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.client_user_package.GetUserPackageStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetUserPackageStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.client_user_package.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.client_user_package.GetUserPackageStatusResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.client_user_package.GetUserPackageStatusResponse}
 */
proto.client_user_package.GetUserPackageStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.client_user_package.GetUserPackageStatusResponse;
  return proto.client_user_package.GetUserPackageStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.client_user_package.GetUserPackageStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.client_user_package.GetUserPackageStatusResponse}
 */
proto.client_user_package.GetUserPackageStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.client_user_package.Meta;
      reader.readMessage(value,proto.client_user_package.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.client_user_package.GetUserPackageStatusResponseData;
      reader.readMessage(value,proto.client_user_package.GetUserPackageStatusResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.client_user_package.GetUserPackageStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.client_user_package.GetUserPackageStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.client_user_package.GetUserPackageStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.client_user_package.GetUserPackageStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.client_user_package.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.client_user_package.GetUserPackageStatusResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.client_user_package.Meta}
 */
proto.client_user_package.GetUserPackageStatusResponse.prototype.getMeta = function() {
  return /** @type{?proto.client_user_package.Meta} */ (
    jspb.Message.getWrapperField(this, proto.client_user_package.Meta, 1));
};


/**
 * @param {?proto.client_user_package.Meta|undefined} value
 * @return {!proto.client_user_package.GetUserPackageStatusResponse} returns this
*/
proto.client_user_package.GetUserPackageStatusResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_user_package.GetUserPackageStatusResponse} returns this
 */
proto.client_user_package.GetUserPackageStatusResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_user_package.GetUserPackageStatusResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetUserPackageStatusResponseData data = 2;
 * @return {?proto.client_user_package.GetUserPackageStatusResponseData}
 */
proto.client_user_package.GetUserPackageStatusResponse.prototype.getData = function() {
  return /** @type{?proto.client_user_package.GetUserPackageStatusResponseData} */ (
    jspb.Message.getWrapperField(this, proto.client_user_package.GetUserPackageStatusResponseData, 2));
};


/**
 * @param {?proto.client_user_package.GetUserPackageStatusResponseData|undefined} value
 * @return {!proto.client_user_package.GetUserPackageStatusResponse} returns this
*/
proto.client_user_package.GetUserPackageStatusResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.client_user_package.GetUserPackageStatusResponse} returns this
 */
proto.client_user_package.GetUserPackageStatusResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.client_user_package.GetUserPackageStatusResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.client_user_package);
