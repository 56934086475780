import { useContext, useEffect, useRef, useState, type FC } from "react";
import {
  HiMagnifyingGlass,
  HiOutlineHeart,
  HiOutlineInformationCircle,
} from "react-icons/hi2";
import { RiShareForwardLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "rsuite";
import { clientFile, clientOrder, fileClient } from "../../App";
import HeroPackage from "../../components/hero_package";
import ModalUpgrade from "../../components/modal_upgrade";
import UpgradePackage from "../../components/upgrade_package";
import { GetProfileResponseData } from "../../grpc/client/client_general_pb";
import {
  CreateBulkOrderDraftRequest,
  CreateBulkOrderDraftRequestData,
  GetSocialMediaPreviewRequest,
  GetSocialMediaPreviewResponseData,
} from "../../grpc/client/client_order_pb";
import { GetUserPackageStatusResponseData } from "../../grpc/client/client_user_package_pb";
import ClientMid from "../../middlewares/ClientMid";
import { LoadingContext } from "../../objects/loading_context";
import { ACTIVE, FREE, PASSIVE } from "../../utils/contants";
import { extractDomain, getToken, uuidv4 } from "../../utils/helper";
import { errorToast } from "../../utils/helper-ui";
import { FileBase64Request } from "../../grpc/service/file_pb";

interface CommentOrderProps {}

const CommentOrder: FC<CommentOrderProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const [requests, setRequests] = useState<
    CreateBulkOrderDraftRequestData.AsObject[]
  >([]);
  const [selectedRequest, setSelectedRequest] = useState<CreateBulkOrderDraftRequestData.AsObject | null>(null);
  const nav = useNavigate();
  const [mounted, setMounted] = useState(false);
  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const [userPackage, setUserPackage] =
    useState<GetUserPackageStatusResponseData.AsObject>();
  const [status, setStatus] =
    useState<GetSocialMediaPreviewResponseData.AsObject>();
  const [modalOpen, setModalOpen] = useState(false);
  const [activeUrl, setActiveUrl] = useState("");
  const [activeId, setActiveId] = useState("");
  const [modalUpgrade, setModalUpgrade] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (requests.length == 0) {
      setRequests([
        {
          platform: "",
          url: "",
          id: uuidv4(),
          positiveComment: 0,
          negativeComment: 0,
          tagFriend: 0,
          remarks: "",
          bonus: 0,
          imagePath: "",
        },
      ]);
    }
  }, []);

  useEffect(() => {
    // console.log(requests)
  }, [requests]);

  useEffect(() => {
    if (!mounted) return;
  }, [mounted]);
  useEffect(() => {
    if (requests.length == 0) return;
    // for (const req of requests) {
    //     if (userPackage?.userPackageStatus == ACTIVE) {
    //         setRequests(requests.map(s => {
    //             if (s.id == req.id) {
    //                 s.bonus = Math.ceil((req.positiveComment + req.negativeComment + req.tagFriend)/5)
    //             }
    //             return s
    //         }))
    //     }
    // }
  }, [requests]);

  const sendOrder = () => {
    if (
      requests
        .map((e) => e.positiveComment + e.negativeComment + e.tagFriend)
        .reduce((a, b) => a + b, 0) > (userPackage?.todayRemainingComment ?? 0)
    ) {
      errorToast(`Total Komentar melebihi Kuota`);
      return;
    }
    setIsLoading(true);
    let orders = new CreateBulkOrderDraftRequest();
    for (const req of requests) {
      let reqData = new CreateBulkOrderDraftRequestData();
      reqData.setPlatform(req.platform);
      reqData.setUrl(req.url);
      reqData.setPositiveComment(req.positiveComment);
      reqData.setNegativeComment(req.negativeComment);
      reqData.setTagFriend(req.tagFriend);
      reqData.setRemarks(req.remarks);
      reqData.setRemarks(req.remarks);
      reqData.setId(req.id);
      reqData.setImagePath(req.imagePath);
      orders.addData(reqData);
    }
    clientOrder.createBulkOrderDraft(orders, getToken(), (err, resp) => {
      if (err) {
        errorToast(err.message);
        setIsLoading(false);
        return;
      }
      nav("/");
      setIsLoading(false);
    });
    // setIsLoading(false)
  };

  return (
    <ClientMid onLoad={setUserPackage} onLoadProfile={setProfile}>
      <HeroPackage userPackage={userPackage} />
      {(userPackage?.userPackageStatus == PASSIVE ||
        userPackage?.userPackageStatus == FREE) && <UpgradePackage />}
      <h4 className="mb-6">Buat Pesanan Komentar</h4>

      {requests.map((e) => (
        <div className=" border-b mb-4" key={e.id}>
          <h5 className="mb-4">
            {requests.indexOf(e) > 0 && `#${requests.indexOf(e) + 1}`} Link
            Sosial Media
          </h5>
          <div className="flex rounded-lg  mb-8">
            <input
              readOnly={userPackage?.userPackageStatus == PASSIVE}
              onClick={() => {
                if (userPackage?.userPackageStatus == PASSIVE) {
                  setModalUpgrade(true);
                }
              }}
              value={e.url}
              onChange={(val) => {
                setRequests(
                  requests.map((s) => {
                    if (s.id == e.id) {
                      s.url = val.target.value;
                    }
                    return s;
                  })
                );
              }}
              placeholder="Masukan Link Konten yang Ingin Dikomentari"
              type="text"
              className="bg-white text-sm appearance-none border-2 border-gray-200 rounded-s-lg w-full px-5 py-2.5  text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-sky-500 focus:ring-1"
            />
            <span
              onClick={() => {
                let domain = extractDomain(e.url);
                // console.log(domain)
                setIsLoading(true);
                let req = new GetSocialMediaPreviewRequest();
                req.setUrl(e.url);
                // req.setPlatform("tiktok");
                clientOrder.getSocialMediaPreview(
                  req,
                  getToken(),
                  (err, resp) => {
                    if (err) {
                      errorToast(err.message);
                      setIsLoading(false);
                      return;
                    }
                    setSelectedRequest(e)
                    setStatus(resp.getData()?.toObject());
                    setActiveUrl(e.url);
                    setActiveId(e.id);
                    setIsLoading(false);
                    setTimeout(() => {
                      setModalOpen(true);
                    }, 300);
                  }
                );
              }}
              className="font-semibold text-white bg-sky-500 hover:bg-sky-700 focus:outline-none focus:ring-4 focus:ring-sky-300  rounded-e-lg text-sm px-5 py-2.5 text-center  dark:bg-sky-600 dark:hover:bg-sky-500 dark:focus:ring-sky-300"
            >
              <HiMagnifyingGlass size={18} />
            </span>
          </div>
          <h5 className="mb-4">Atur Jumlah Komentar</h5>
          <div className=" mb-8 grid grid-cols-3 gap-4 ">
            <div className=" flex flex-col justify-center items-center">
              <input
                type="number"
                className=" form-control text-center"
                style={{ borderRadius: 5 }}
                value={e.positiveComment}
                onChange={(val) => {
                  setRequests(
                    requests.map((s) => {
                      if (s.id == e.id) {
                        s.positiveComment = parseInt(val.target.value);
                        if (userPackage?.userPackageStatus == ACTIVE) {
                          s.bonus = Math.ceil(
                            (e.positiveComment +
                              e.negativeComment +
                              e.tagFriend) /
                              5
                          );
                        }
                      }
                      return s;
                    })
                  );
                }}
              />
              <p>Positif</p>
            </div>
            <div className=" flex flex-col justify-center items-center">
              <input
                type="number"
                className=" form-control text-center"
                style={{ borderRadius: 5 }}
                value={e.negativeComment}
                onChange={(val) => {
                  setRequests(
                    requests.map((s) => {
                      if (s.id == e.id) {
                        s.negativeComment = parseInt(val.target.value);
                        if (userPackage?.userPackageStatus == ACTIVE) {
                          s.bonus = Math.ceil(
                            (e.positiveComment +
                              e.negativeComment +
                              e.tagFriend) /
                              5
                          );
                        }
                      }
                      return s;
                    })
                  );
                }}
              />
              <p>Negatif</p>
            </div>
            <div className=" flex flex-col justify-center items-center">
              <input
                type="number"
                className=" form-control text-center"
                style={{ borderRadius: 5 }}
                value={e.tagFriend}
                onChange={(val) => {
                  setRequests(
                    requests.map((s) => {
                      if (s.id == e.id) {
                        s.tagFriend = parseInt(val.target.value);
                        if (userPackage?.userPackageStatus == ACTIVE) {
                          s.bonus = Math.ceil(
                            (e.positiveComment +
                              e.negativeComment +
                              e.tagFriend) /
                              5
                          );
                        }
                      }
                      return s;
                    })
                  );
                }}
              />
              <p>Tag Teman</p>
            </div>
          </div>
          <h5 className="mb-4">Catatan untuk Komentator (Opsional)</h5>
          <div className=" mb-8 ">
            <textarea
              placeholder="Beri arahan apa yang harus di komentari Contoh: Komentari bajunya"
              className="form-control"
              rows={5}
              value={e.remarks}
              name=""
              id=""
              onChange={(val) => {
                setRequests(
                  requests.map((s) => {
                    if (s.id == e.id) {
                      s.remarks = val.target.value;
                    }
                    return s;
                  })
                );
              }}
            ></textarea>
          </div>
          <h5 className="mb-4 flex justify-between w-fit  items-center">
            Bonus <HiOutlineInformationCircle className="text-sky-400 ml-2" />
          </h5>
          <div className="flex">
            <div className="flex rounded-lg  mb-8 mr-6">
              <span
                className={`font-semibold text-white ${
                  userPackage?.userPackageStatus == "free"
                    ? "bg-gray-300"
                    : "bg-sky-400"
                } hover:bg-gray-500 rounded-s-lg px-5 py-2.5 text-center  `}
              >
                <HiOutlineHeart size={18} />
              </span>
              <input
                readOnly
                placeholder="-"
                value={e.bonus}
                type="number"
                className="bg-white text-center text-sm w-16 appearance-none border-2 border-gray-200 rounded-e-lg  px-5 py-2.5  text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-sky-500 focus:ring-1"
                onChange={(val) => {
                  setRequests(
                    requests.map((s) => {
                      if (s.id == e.id) {
                        s.bonus = parseInt(val.target.value);
                      }
                      return s;
                    })
                  );
                }}
              />
            </div>
            <div className="flex rounded-lg  mb-8 mr-6">
              <span
                className={`font-semibold text-white ${
                  userPackage?.userPackageStatus == "free"
                    ? "bg-gray-300"
                    : "bg-sky-400"
                } hover:bg-gray-500 rounded-s-lg px-5 py-2.5 text-center  `}
              >
                <RiShareForwardLine size={18} />
              </span>
              <input
                readOnly
                placeholder="-"
                value={e.bonus}
                type="number"
                className="bg-white text-center text-sm w-16 appearance-none border-2 border-gray-200 rounded-e-lg  px-5 py-2.5  text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-sky-500 focus:ring-1"
                onChange={(val) => {
                  setRequests(
                    requests.map((s) => {
                      if (s.id == e.id) {
                        s.bonus = parseInt(val.target.value);
                      }
                      return s;
                    })
                  );
                }}
              />
            </div>
          </div>
          {requests.indexOf(e) > 0 && (
            <h6
              className="mb-4 text-sky-400 cursor-pointer text-center"
              onClick={() => {
                setRequests(requests.filter((s) => s.id == e.id));
              }}
            >
              Hapus Komentar
            </h6>
          )}
        </div>
      ))}
      {userPackage?.userPackageStatus == ACTIVE && (
        <h6
          className="mb-4 text-sky-400 cursor-pointer"
          onClick={() => {
            setRequests([
              ...requests,
              {
                platform: "",
                url: "",
                id: uuidv4(),
                positiveComment: 0,
                negativeComment: 0,
                tagFriend: 0,
                remarks: "",
                bonus: 0,
                imagePath: "",
              },
            ]);
          }}
        >
          + Tambah Komentar Lain
        </h6>
      )}
      {requests
        .map((e) => e.positiveComment + e.negativeComment + e.tagFriend)
        .reduce((a, b) => a + b, 0) >
        (userPackage?.todayRemainingComment ?? 0) && (
        <div className=" py-2 px-4 bg-orange-100 rounded-lg my-2 text-xs text-center">
          Total Komentar melebihi Kuota
        </div>
      )}
      <button onClick={sendOrder} type="button" className="btn w-full">
        Kirim
      </button>

      <Modal size={"sm"} open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Title>Preview</Modal.Title>
        <Modal.Body>
          <input
            accept="image/*"
            type="file"
            className="hidden"
            ref={fileRef}
            onChange={(el) => {
              let files = fileRef.current?.files;
              if (files) {
                let reader = new FileReader();

                reader.onload = function () {

                    setIsLoading(true)
                    let req = new FileBase64Request()
                    req.setFile(`${reader?.result}`.split("base64,")[1])
                    clientFile.uploadBase64Temp(req, getToken(), (err, resp) => {
                        setIsLoading(false)
                        if (err) {
                            errorToast(err.message)
                            
                            return
                        }
                        let path = resp.getData()?.toObject()
                        console.log(path)
                        setStatus({...status!, image: path!.url})
                        setRequests(
                            requests.map((s) => {
                                if (s.id == activeId) {
                                    s.imagePath = path!.path
                                }
                                return s;
                            })
                        )

                    }
                )
                  // setImgUpload(reader?.result?.toString() ?? "/assets/upload.png")
                };
                reader.readAsDataURL(files[0]);
              }
            }}
          />
          <img src={status?.image} alt="" className="w-full" />
          <h4 className=" leading-6 mt-2 mb-4">{status?.title}</h4>
          <p>{status?.description}</p>
          <small>{status?.platform}</small>

          <blockquote className=" text-center my-4 text-orange-400 p-4 bg-yellow-50 rounded-lg border border-yellow-400">
            Beberapa link tidak memunculkan preview, dikarenakan kebijakan di
            internal sosial media, pastikan link yang anda sertakan sudah benar
            <p
              className="text-sky-400 cursor-pointer"
              onClick={() => fileRef.current?.click()}
            >
              Ganti Preview Manual
            </p>
          </blockquote>
          <div className="flex flex-wrap" style={{ overflowWrap: "anywhere" }}>
            <Link
              className="my-4 text-sky-400 flex"
              to={activeUrl}
              target="_blank"
            >
              {activeUrl}
            </Link>
          </div>

          <div className="w-full  flex justify-center flex-col items-center">
            <button
              onClick={() => {
                setModalOpen(false);
              }}
              type="button"
              className="btn"
            >
              Ya, Link sudah benar
            </button>

            <h6
              className="mb-4 text-sky-400 cursor-pointer text-center"
              onClick={() => {
                setRequests(
                  requests.map((s) => {
                    if (s.id == activeId) {
                      s.url = "";
                    }
                    return s;
                  })
                );
                setModalOpen(false);
              }}
            >
              Ganti Link
            </h6>
          </div>
        </Modal.Body>
      </Modal>
      <ModalUpgrade
        open={modalUpgrade}
        onClose={() => setModalUpgrade(false)}
      />
    </ClientMid>
  );
};
export default CommentOrder;
