/**
 * @fileoverview gRPC-Web generated client stub for client_order
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: client_order.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.client_order = require('./client_order_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.client_order.ClientOrderHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.client_order.ClientOrderHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_order.GetOrderDraftListRequest,
 *   !proto.client_order.GetOrderDraftListResponse>}
 */
const methodDescriptor_ClientOrderHandler_GetOrderDraftList = new grpc.web.MethodDescriptor(
  '/client_order.ClientOrderHandler/GetOrderDraftList',
  grpc.web.MethodType.UNARY,
  proto.client_order.GetOrderDraftListRequest,
  proto.client_order.GetOrderDraftListResponse,
  /**
   * @param {!proto.client_order.GetOrderDraftListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_order.GetOrderDraftListResponse.deserializeBinary
);


/**
 * @param {!proto.client_order.GetOrderDraftListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_order.GetOrderDraftListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_order.GetOrderDraftListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_order.ClientOrderHandlerClient.prototype.getOrderDraftList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_order.ClientOrderHandler/GetOrderDraftList',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_GetOrderDraftList,
      callback);
};


/**
 * @param {!proto.client_order.GetOrderDraftListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_order.GetOrderDraftListResponse>}
 *     Promise that resolves to the response
 */
proto.client_order.ClientOrderHandlerPromiseClient.prototype.getOrderDraftList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_order.ClientOrderHandler/GetOrderDraftList',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_GetOrderDraftList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_order.GetOrderListRequest,
 *   !proto.client_order.GetOrderListResponse>}
 */
const methodDescriptor_ClientOrderHandler_GetOrderList = new grpc.web.MethodDescriptor(
  '/client_order.ClientOrderHandler/GetOrderList',
  grpc.web.MethodType.UNARY,
  proto.client_order.GetOrderListRequest,
  proto.client_order.GetOrderListResponse,
  /**
   * @param {!proto.client_order.GetOrderListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_order.GetOrderListResponse.deserializeBinary
);


/**
 * @param {!proto.client_order.GetOrderListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_order.GetOrderListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_order.GetOrderListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_order.ClientOrderHandlerClient.prototype.getOrderList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_order.ClientOrderHandler/GetOrderList',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_GetOrderList,
      callback);
};


/**
 * @param {!proto.client_order.GetOrderListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_order.GetOrderListResponse>}
 *     Promise that resolves to the response
 */
proto.client_order.ClientOrderHandlerPromiseClient.prototype.getOrderList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_order.ClientOrderHandler/GetOrderList',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_GetOrderList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_order.CreateBulkOrderDraftRequest,
 *   !proto.client_order.CreateBulkOrderDraftResponse>}
 */
const methodDescriptor_ClientOrderHandler_CreateBulkOrderDraft = new grpc.web.MethodDescriptor(
  '/client_order.ClientOrderHandler/CreateBulkOrderDraft',
  grpc.web.MethodType.UNARY,
  proto.client_order.CreateBulkOrderDraftRequest,
  proto.client_order.CreateBulkOrderDraftResponse,
  /**
   * @param {!proto.client_order.CreateBulkOrderDraftRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_order.CreateBulkOrderDraftResponse.deserializeBinary
);


/**
 * @param {!proto.client_order.CreateBulkOrderDraftRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_order.CreateBulkOrderDraftResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_order.CreateBulkOrderDraftResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_order.ClientOrderHandlerClient.prototype.createBulkOrderDraft =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_order.ClientOrderHandler/CreateBulkOrderDraft',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_CreateBulkOrderDraft,
      callback);
};


/**
 * @param {!proto.client_order.CreateBulkOrderDraftRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_order.CreateBulkOrderDraftResponse>}
 *     Promise that resolves to the response
 */
proto.client_order.ClientOrderHandlerPromiseClient.prototype.createBulkOrderDraft =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_order.ClientOrderHandler/CreateBulkOrderDraft',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_CreateBulkOrderDraft);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_order.GetOrderDetailRequest,
 *   !proto.client_order.GetOrderDetailResponse>}
 */
const methodDescriptor_ClientOrderHandler_GetOrderDetail = new grpc.web.MethodDescriptor(
  '/client_order.ClientOrderHandler/GetOrderDetail',
  grpc.web.MethodType.UNARY,
  proto.client_order.GetOrderDetailRequest,
  proto.client_order.GetOrderDetailResponse,
  /**
   * @param {!proto.client_order.GetOrderDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_order.GetOrderDetailResponse.deserializeBinary
);


/**
 * @param {!proto.client_order.GetOrderDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_order.GetOrderDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_order.GetOrderDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_order.ClientOrderHandlerClient.prototype.getOrderDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_order.ClientOrderHandler/GetOrderDetail',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_GetOrderDetail,
      callback);
};


/**
 * @param {!proto.client_order.GetOrderDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_order.GetOrderDetailResponse>}
 *     Promise that resolves to the response
 */
proto.client_order.ClientOrderHandlerPromiseClient.prototype.getOrderDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_order.ClientOrderHandler/GetOrderDetail',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_GetOrderDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_order.GetOrderDetailRequest,
 *   !proto.client_order.GetOrderDraftDetailResponse>}
 */
const methodDescriptor_ClientOrderHandler_GetOrderDraftDetail = new grpc.web.MethodDescriptor(
  '/client_order.ClientOrderHandler/GetOrderDraftDetail',
  grpc.web.MethodType.UNARY,
  proto.client_order.GetOrderDetailRequest,
  proto.client_order.GetOrderDraftDetailResponse,
  /**
   * @param {!proto.client_order.GetOrderDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_order.GetOrderDraftDetailResponse.deserializeBinary
);


/**
 * @param {!proto.client_order.GetOrderDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_order.GetOrderDraftDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_order.GetOrderDraftDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_order.ClientOrderHandlerClient.prototype.getOrderDraftDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_order.ClientOrderHandler/GetOrderDraftDetail',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_GetOrderDraftDetail,
      callback);
};


/**
 * @param {!proto.client_order.GetOrderDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_order.GetOrderDraftDetailResponse>}
 *     Promise that resolves to the response
 */
proto.client_order.ClientOrderHandlerPromiseClient.prototype.getOrderDraftDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_order.ClientOrderHandler/GetOrderDraftDetail',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_GetOrderDraftDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_order.GetOrderDiscussionRequest,
 *   !proto.client_order.GetOrderDiscussionResponse>}
 */
const methodDescriptor_ClientOrderHandler_GetOrderDiscussion = new grpc.web.MethodDescriptor(
  '/client_order.ClientOrderHandler/GetOrderDiscussion',
  grpc.web.MethodType.UNARY,
  proto.client_order.GetOrderDiscussionRequest,
  proto.client_order.GetOrderDiscussionResponse,
  /**
   * @param {!proto.client_order.GetOrderDiscussionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_order.GetOrderDiscussionResponse.deserializeBinary
);


/**
 * @param {!proto.client_order.GetOrderDiscussionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_order.GetOrderDiscussionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_order.GetOrderDiscussionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_order.ClientOrderHandlerClient.prototype.getOrderDiscussion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_order.ClientOrderHandler/GetOrderDiscussion',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_GetOrderDiscussion,
      callback);
};


/**
 * @param {!proto.client_order.GetOrderDiscussionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_order.GetOrderDiscussionResponse>}
 *     Promise that resolves to the response
 */
proto.client_order.ClientOrderHandlerPromiseClient.prototype.getOrderDiscussion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_order.ClientOrderHandler/GetOrderDiscussion',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_GetOrderDiscussion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_order.GiveOrderFeedbackRequest,
 *   !proto.client_order.GiveOrderFeedbackResponse>}
 */
const methodDescriptor_ClientOrderHandler_GiveOrderFeedback = new grpc.web.MethodDescriptor(
  '/client_order.ClientOrderHandler/GiveOrderFeedback',
  grpc.web.MethodType.UNARY,
  proto.client_order.GiveOrderFeedbackRequest,
  proto.client_order.GiveOrderFeedbackResponse,
  /**
   * @param {!proto.client_order.GiveOrderFeedbackRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_order.GiveOrderFeedbackResponse.deserializeBinary
);


/**
 * @param {!proto.client_order.GiveOrderFeedbackRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_order.GiveOrderFeedbackResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_order.GiveOrderFeedbackResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_order.ClientOrderHandlerClient.prototype.giveOrderFeedback =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_order.ClientOrderHandler/GiveOrderFeedback',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_GiveOrderFeedback,
      callback);
};


/**
 * @param {!proto.client_order.GiveOrderFeedbackRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_order.GiveOrderFeedbackResponse>}
 *     Promise that resolves to the response
 */
proto.client_order.ClientOrderHandlerPromiseClient.prototype.giveOrderFeedback =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_order.ClientOrderHandler/GiveOrderFeedback',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_GiveOrderFeedback);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_order.SendDiscussionRequest,
 *   !proto.client_order.SendDiscussionResponse>}
 */
const methodDescriptor_ClientOrderHandler_SendDiscussion = new grpc.web.MethodDescriptor(
  '/client_order.ClientOrderHandler/SendDiscussion',
  grpc.web.MethodType.UNARY,
  proto.client_order.SendDiscussionRequest,
  proto.client_order.SendDiscussionResponse,
  /**
   * @param {!proto.client_order.SendDiscussionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_order.SendDiscussionResponse.deserializeBinary
);


/**
 * @param {!proto.client_order.SendDiscussionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_order.SendDiscussionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_order.SendDiscussionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_order.ClientOrderHandlerClient.prototype.sendDiscussion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_order.ClientOrderHandler/SendDiscussion',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_SendDiscussion,
      callback);
};


/**
 * @param {!proto.client_order.SendDiscussionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_order.SendDiscussionResponse>}
 *     Promise that resolves to the response
 */
proto.client_order.ClientOrderHandlerPromiseClient.prototype.sendDiscussion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_order.ClientOrderHandler/SendDiscussion',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_SendDiscussion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_order.GetSocialMediaPreviewRequest,
 *   !proto.client_order.GetSocialMediaPreviewResponse>}
 */
const methodDescriptor_ClientOrderHandler_GetSocialMediaPreview = new grpc.web.MethodDescriptor(
  '/client_order.ClientOrderHandler/GetSocialMediaPreview',
  grpc.web.MethodType.UNARY,
  proto.client_order.GetSocialMediaPreviewRequest,
  proto.client_order.GetSocialMediaPreviewResponse,
  /**
   * @param {!proto.client_order.GetSocialMediaPreviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_order.GetSocialMediaPreviewResponse.deserializeBinary
);


/**
 * @param {!proto.client_order.GetSocialMediaPreviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_order.GetSocialMediaPreviewResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_order.GetSocialMediaPreviewResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_order.ClientOrderHandlerClient.prototype.getSocialMediaPreview =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_order.ClientOrderHandler/GetSocialMediaPreview',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_GetSocialMediaPreview,
      callback);
};


/**
 * @param {!proto.client_order.GetSocialMediaPreviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_order.GetSocialMediaPreviewResponse>}
 *     Promise that resolves to the response
 */
proto.client_order.ClientOrderHandlerPromiseClient.prototype.getSocialMediaPreview =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_order.ClientOrderHandler/GetSocialMediaPreview',
      request,
      metadata || {},
      methodDescriptor_ClientOrderHandler_GetSocialMediaPreview);
};


module.exports = proto.client_order;

