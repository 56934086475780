import { useContext, useEffect, useState, type FC } from "react";
import { GetUserPackageStatusResponseData } from "../../grpc/client/client_user_package_pb";
import { useNavigate } from "react-router-dom";
import ClientMid from "../../middlewares/ClientMid";
import { Button } from "flowbite-react";
import { asyncLocalStorage, getToken, initials } from "../../utils/helper";
import { REFRESH_TOKEN, TOKEN } from "../../utils/contants";
import { adminWithdrawClient, clientGeneral, generalClient } from "../../App";
import { LoadingContext } from "../../objects/loading_context";
import { errorToast } from "../../utils/helper-ui";
import { Avatar, Badge } from "rsuite";
import { HiChevronRight } from "react-icons/hi2";
import Help from "../../../src/icon_svg/help.svg";
import About from "../../../src/icon_svg/about_us.svg";
import Out from "../../../src/icon_svg/out.svg";
import AdminMid from "../../middlewares/AdminMid";
import {
  GetProfileRequest,
  GetProfileResponseData,
} from "../../grpc/service/general_pb";
import { FaWallet } from "react-icons/fa6";
import { HasInProgressWithdrawRequest, WithdrawRequestCountRequest } from "../../grpc/admin/admin_withdraw_request_pb";

interface AdminProfileProps {}

const AdminProfile: FC<AdminProfileProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const nav = useNavigate();
  const [mounted, setMounted] = useState(false);
  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const [userPackage, setUserPackage] =
    useState<GetUserPackageStatusResponseData.AsObject>();
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [pendingWithdrawCount,setPendingWithdrawCount ] = useState(0)

  useEffect(() => {
    if (profileLoaded) return;
    generalClient.getProfile(
      new GetProfileRequest(),
      getToken(),
      (err, resp) => {
        if (err) {
          errorToast(err.message);
          setIsLoading(false);
          return;
        }

        // console.log(resp.getData()?.toObject())
        setIsLoading(false);
        setProfileLoaded(true);
      }
    );

    adminWithdrawClient.hasInProgressWithdraw(new HasInProgressWithdrawRequest(), getToken(),
    (err, resp) => {
      if (err) {
        errorToast(err.message);
        setIsLoading(false);
        return;
      }

      setPendingWithdrawCount(resp.getData()?.toObject().inProgressWithdrawCount ?? 0)
      setIsLoading(false);
      
    })
    // adminWithdrawClient.withdrawRequestCount(
    //   new WithdrawRequestCountRequest(),
    //   getToken(),
    //   (err, resp) => {
    //     if (err) {
    //       errorToast(err.message);
    //       setIsLoading(false);
    //       return;
    //     }

    //     console.log("withdrawRequestCount",resp.getData()?.toObject())
    //     setIsLoading(false);
        
    //   }
    // );
  }, [profileLoaded]);

  return (
    <AdminMid onLoadProfile={setProfile}>
      <div className="flex flex-col justify-center items-center py-8">
        <Avatar
          alt={initials(profile?.name)}
          circle
          src={profile?.profilePictureUrl}
          size="xl"
          className="m-auto"
          color="blue"
        />
        <h4 className=" text-black py-2">{profile?.name}</h4>
        <div className=" rounded-xl bg-gray-200  px-6 ">
          <small className=" text-black py-2">
            {profile?.userPackageStatus.toUpperCase()}
          </small>
        </div>

        <div className="bg-white p-4 rounded-lg w-full mt-8">
          <div className="flex items-center justify-between mb-8 last:mb-0">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => {
                nav("/admin/withdraw");
              }}
            >
              <FaWallet size={20} className="text-black mr-4" />
              <span className=" text-sm">Penarikan Komisi</span>
            </div>
            {/* <HiChevronRight size={24} className="text-gray-400" /> */}
            {pendingWithdrawCount && <Badge content={pendingWithdrawCount} />}
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg w-full mt-2">
          <div className="flex items-center justify-between mb-8 last:mb-0">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => nav(`/profile/account-security`)}
            >
              <img src="/assets/lock.png" alt="" className="mr-4 h-6" />
              <span className=" text-sm">Akun & Keamanan</span>
            </div>
            <HiChevronRight size={24} className="text-gray-400" />
          </div>
          <div className="flex items-center cursor-pointer justify-between mb-8 last:mb-0">
            <div className="flex items-center cursor-pointer">
              <img src={Help} alt="" className="mr-4 h-6" />
              <span className=" text-sm">Pusat Bantuan</span>
            </div>
            <HiChevronRight size={24} className="text-gray-400" />
          </div>
          <div className="flex items-center cursor-pointer justify-between mb-8 last:mb-0">
            <div className="flex items-center cursor-pointer">
              <img src={About} alt="" className="mr-4 h-6" />
              <span className=" text-sm">Tentang Kami</span>
            </div>
            <HiChevronRight size={24} className="text-gray-400" />
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg w-full mt-8">
          <div
            className="flex items-center justify-between mb-8 last:mb-0"
            onClick={async () => {
              await asyncLocalStorage.removeItem(TOKEN);
              await asyncLocalStorage.removeItem(REFRESH_TOKEN);
              window.location.href = "/login";
            }}
          >
            <div className="flex items-center cursor-pointer">
              <img src={Out} alt="" className="mr-4 h-6" />
              <span className=" text-sm">Logout</span>
            </div>
            <HiChevronRight size={24} className="text-gray-400" />
          </div>
        </div>
      </div>
      {/* <Button pill className='w-full' color={"failure"} onClick={async () => {
            await asyncLocalStorage.removeItem(TOKEN)
            await asyncLocalStorage.removeItem(REFRESH_TOKEN)
            window.location.href = "/login"
        }}>Logout</Button> */}
    </AdminMid>
  );
};
export default AdminProfile;
