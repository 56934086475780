/**
 * @fileoverview gRPC-Web generated client stub for client_general
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: client_general.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.client_general = require('./client_general_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.client_general.ClientGeneralHandlerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.client_general.ClientGeneralHandlerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_general.GetProfileRequest,
 *   !proto.client_general.GetProfileResponse>}
 */
const methodDescriptor_ClientGeneralHandler_GetProfile = new grpc.web.MethodDescriptor(
  '/client_general.ClientGeneralHandler/GetProfile',
  grpc.web.MethodType.UNARY,
  proto.client_general.GetProfileRequest,
  proto.client_general.GetProfileResponse,
  /**
   * @param {!proto.client_general.GetProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_general.GetProfileResponse.deserializeBinary
);


/**
 * @param {!proto.client_general.GetProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_general.GetProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_general.GetProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_general.ClientGeneralHandlerClient.prototype.getProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_general.ClientGeneralHandler/GetProfile',
      request,
      metadata || {},
      methodDescriptor_ClientGeneralHandler_GetProfile,
      callback);
};


/**
 * @param {!proto.client_general.GetProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_general.GetProfileResponse>}
 *     Promise that resolves to the response
 */
proto.client_general.ClientGeneralHandlerPromiseClient.prototype.getProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_general.ClientGeneralHandler/GetProfile',
      request,
      metadata || {},
      methodDescriptor_ClientGeneralHandler_GetProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.client_general.DeleteUserRequest,
 *   !proto.client_general.DeleteUserResponse>}
 */
const methodDescriptor_ClientGeneralHandler_DeleteUser = new grpc.web.MethodDescriptor(
  '/client_general.ClientGeneralHandler/DeleteUser',
  grpc.web.MethodType.UNARY,
  proto.client_general.DeleteUserRequest,
  proto.client_general.DeleteUserResponse,
  /**
   * @param {!proto.client_general.DeleteUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.client_general.DeleteUserResponse.deserializeBinary
);


/**
 * @param {!proto.client_general.DeleteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.client_general.DeleteUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.client_general.DeleteUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.client_general.ClientGeneralHandlerClient.prototype.deleteUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/client_general.ClientGeneralHandler/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_ClientGeneralHandler_DeleteUser,
      callback);
};


/**
 * @param {!proto.client_general.DeleteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.client_general.DeleteUserResponse>}
 *     Promise that resolves to the response
 */
proto.client_general.ClientGeneralHandlerPromiseClient.prototype.deleteUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/client_general.ClientGeneralHandler/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_ClientGeneralHandler_DeleteUser);
};


module.exports = proto.client_general;

