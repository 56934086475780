import { useEffect, useState, type FC } from 'react';
import CommentatorMid from '../../middlewares/CommentatorMid';
import { GetProfileResponseData } from '../../grpc/service/general_pb';
import { Avatar } from 'rsuite';
import { asyncLocalStorage, initials, money } from '../../utils/helper';
import { HiChevronRight } from 'react-icons/hi2';
import { REFRESH_TOKEN, TOKEN } from '../../utils/contants';
import Help from '../../../src/icon_svg/help.svg'
import About from '../../../src/icon_svg/about_us.svg'
import Out from '../../../src/icon_svg/out.svg'
import { useNavigate } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';

interface CommentatorProfileProps { }

const CommentatorProfile: FC<CommentatorProfileProps> = ({ }) => {
    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const nav = useNavigate()
    const [offenceCount, setOffenceCount] = useState<number[]>([]);

    useEffect(() => {
        let _offenceCount : number[] = []
        if (profile) {
            for (let index = 0; index < (profile.offenseCount ?? 0); index++) {
                _offenceCount.push(index)
            }
            setOffenceCount(_offenceCount)
        }
    }, [profile]);
    return (<CommentatorMid onLoadProfile={setProfile}>
        <div className='flex flex-col justify-center items-center py-8'>
            <Avatar alt={initials(profile?.name)} circle src={profile?.profilePictureUrl} size='xl' className='m-auto' color='blue' />
            <h4 className=' text-black py-2'>{profile?.name}</h4>
            <div className='   px-6 '>

                <div className='flex items-center'>
                    {offenceCount.length != 0 && offenceCount.map(e => <img className='w-4' src='/assets/card_yellow.png' />) }
                </div>
                <div className='flex items-center'>
                    <FaStar size={12} className='text-orange-300 mr-1' />  {money((profile?.rating ?? 0) / 2)}
                </div>

            </div>

            <div className='bg-white p-4 rounded-lg w-full mt-8'>

                <div className='flex items-center justify-between mb-8 last:mb-0'>
                    <div className='flex items-center cursor-pointer' onClick={() => nav(!profile?.referralCode ? `/invite` : `/referal`)}>
                        <img src="/assets/images/icons/invite_friend.png" alt="" className='mr-4' />
                        <span className=' text-sm'>Ajak Teman</span>
                    </div>
                    <HiChevronRight size={24} className='text-gray-400' />
                </div>
                <div className='flex items-center justify-between mb-8 last:mb-0'>
                    <div className='flex items-center cursor-pointer' onClick={() => {
                        nav(`/commentator/history`)
                    }}>
                        <img src="/assets/images/icons/history_rewards.png" alt="" className='mr-4' />
                        <span className=' text-sm'>Histori Fee</span>
                    </div>
                    <HiChevronRight size={24} className='text-gray-400' />
                </div>
            </div>
            <div className='bg-white p-4 rounded-lg w-full mt-8'>
                <div className='flex items-center justify-between mb-8 last:mb-0'>
                    <div className='flex items-center cursor-pointer' onClick={() => nav(`/profile/account-security`)}>
                        <img src="/assets/lock.png" alt="" className='mr-4 h-6' />
                        <span className=' text-sm'>Akun & Keamanan</span>
                    </div>
                    <HiChevronRight size={24} className='text-gray-400' />
                </div>
                <div className='flex items-center cursor-pointer justify-between mb-8 last:mb-0'>
                    <div className='flex items-center cursor-pointer'>
                        <img src={Help} alt="" className='mr-4 h-6' />
                        <span className=' text-sm'>Pusat Bantuan</span>
                    </div>
                    <HiChevronRight size={24} className='text-gray-400' />
                </div>
                <div className='flex items-center cursor-pointer justify-between mb-8 last:mb-0'>
                    <div className='flex items-center cursor-pointer'>
                        <img src={About} alt="" className='mr-4 h-6' />
                        <span className=' text-sm'>Tentang Kami</span>
                    </div>
                    <HiChevronRight size={24} className='text-gray-400' />
                </div>

            </div>
            <div className='bg-white p-4 rounded-lg w-full mt-8'>
                <div className='flex items-center justify-between mb-8 last:mb-0' onClick={async () => {
                    await asyncLocalStorage.removeItem(TOKEN)
                    await asyncLocalStorage.removeItem(REFRESH_TOKEN)
                    window.location.href = "/login"
                }}>
                    <div className='flex items-center cursor-pointer'>
                        <img src={Out} alt="" className='mr-4 h-6' />
                        <span className=' text-sm'>Logout</span>
                    </div>
                    <HiChevronRight size={24} className='text-gray-400' />
                </div>
            </div>
        </div>
    </CommentatorMid>);
}
export default CommentatorProfile;