import { useContext, useEffect, useState, type FC } from "react";
import ProfileMid from "../middlewares/ProfileMid";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "../objects/loading_context";
import { GetProfileResponseData } from "../grpc/client/client_general_pb";
import Back from "../components/back";
import HeroReferal from "../components/HeroReferal";
import { PiCopy } from "react-icons/pi";
import RefTerm from "../components/ref_term";
import { getToken, money } from "../utils/helper";
import { generalClient } from "../App";
import { errorToast } from "../utils/helper-ui";
import {
  GetMarketingSummaryRequest,
  GetMarketingSummaryResponseData,
} from "../grpc/service/general_pb";

interface ReferalProps {}

const Referal: FC<ReferalProps> = ({}) => {
  const nav = useNavigate();
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [marketingData, setMarketingData] =
    useState<GetMarketingSummaryResponseData.AsObject | null>(null);

  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const [mode, setMode] = useState("referal");

  useEffect(() => {
    getMarketingData();
  }, []);

  const getMarketingData = () => {
    setIsLoading(true);
    let req = new GetMarketingSummaryRequest();
    generalClient.getMarketingSummary(req, getToken(), (err, resp) => {
      setIsLoading(false);
      if (err) {
        errorToast(err.message);
        return;
      }
      setMarketingData(resp.getData()?.toObject() ?? null);
      // setProfile(resp.getData()?.toObject())
    });
  };

  useEffect(() => {
    // console.log("marketingData", marketingData)
  }, [marketingData]);

  return (
    <ProfileMid onLoad={setProfile}>
      <div className="flex flex-col h-full">
        <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white">
          <Back />
          <h6>Ajak Teman</h6>
          <div className="p-2"></div>
        </div>
        <div className="p-6 flex-1 overflow-y-auto">
          <HeroReferal data={marketingData} />
          <div className="mt-4">
            <div className="referalMenu">
              <div
                onClick={() => {
                  setMode("referal");
                }}
                className={`${mode == "referal" && "active"}`}
              >
                Referal
              </div>
              <div
                onClick={() => {
                  setMode("status");
                }}
                className={`${mode == "status" && "active"}`}
              >
                Status Referal
              </div>
            </div>
            <div className="py-4">
              {mode == "referal" && (
                <div className=" w-full border rounded-lg  flex-col flex">
                  <div className="border-orange-400 rounded-t-lg p-2 bg-orange-400 text-white text-center flex">
                    <div className="flex items-center  mr-1">
                      <img
                        src="/assets/share_ref.png"
                        alt=""
                        className="w-7 h-7"
                      />
                      <p className="mx-2 text-left text-2xs font-semibold">
                        Bagikan link referralmu
                      </p>
                      <img
                        src="/assets/arrow_white.png"
                        alt=""
                        className="h-7"
                      />
                    </div>
                    <div className="flex items-center  mr-1">
                      <img
                        src="/assets/buy_premium.png"
                        alt=""
                        className="w-7 h-7"
                      />
                      <p className="mx-2 text-left text-2xs font-semibold">
                        Teman beli paket premium
                      </p>
                      <img
                        src="/assets/arrow_white.png"
                        alt=""
                        className="h-7"
                      />
                    </div>
                    <div className="flex items-center ">
                      <img
                        src="/assets/get_reward.png"
                        alt=""
                        className="w-7 h-7"
                      />
                      <p className="mx-2 text-left text-2xs font-semibold">
                        Kamu dapat Rewards 6%
                      </p>
                    </div>
                  </div>
                  <div className="border-orange-400 border rounded-b-lg text-gray-600 p-4 flex justify-center flex-col items-center mb-8">
                    <h6>Link Referralmu</h6>
                    <div className=" bg-gray-200 rounded-xl p-2 w-full flex justify-between">
                      <span className="text-xs">{`${process.env.REACT_APP_FRONTEND_URL}/join/ref/${profile?.referralCode}`}</span>
                      <PiCopy
                        className=" text-orange-600 cursor-pointer"
                        onClick={() =>
                          navigator.clipboard.writeText(
                            `${process.env.REACT_APP_FRONTEND_URL}/join/ref/${profile?.referralCode}`
                          )
                        }
                      />
                    </div>
                  </div>
                  <RefTerm />
                </div>
              )}
              {mode == "status" && (
                <div>
                  <div className="py-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="bg-white rounded-lg p-4 flex flex-col items-center">
                        <span className="font-semibold">Ref Premium</span>
                        <div className="mt-4 bg-gray-50 rounded-lg p-4 w-full flex justify-center">
                          <span className="text-2xl font-semibold text-center text-sky-800">
                            {money(marketingData?.nonFreeUser)}
                          </span>
                        </div>
                      </div>
                      <div className="bg-white rounded-lg p-4 flex flex-col items-center">
                        <span className="font-semibold">Total Reward</span>
                        <div className="mt-4 bg-gray-50 rounded-lg p-4 w-full flex justify-center">
                          <span className="text-2xl font-semibold text-center text-sky-800">
                            {money(marketingData?.totalMarketingFee)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <AffiliatorCommentatorList
                        // onClickClient={onClickClient}
                        onClickAccount={onClickAccount}
                        onClickWarning={onClickWarning}
                        onClickBlackList={onClickBlackList}
                    /> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ProfileMid>
  );
};
export default Referal;
