import moment from "moment";
import type { FC } from "react";
import { Avatar } from "rsuite";
import { GetOrderDiscussionResponseDataUser } from "../grpc/admin/admin_order_pb";
import { GetOrderDiscussionResponseDataReply } from "../grpc/client/client_order_pb";
import { initials } from "../utils/helper";
import { Link } from "react-router-dom";

interface ChatBubbleProps {
  data: GetOrderDiscussionResponseDataReply.AsObject;
  profile: GetOrderDiscussionResponseDataUser.AsObject | undefined;
}

const ChatBubble: FC<ChatBubbleProps> = ({ data, profile }) => {
  return (
    <div
      className={`flex mb-4 items-end ${
        !data.selfComment ? "justify-start" : "justify-end"
      }`}
    >
      {profile?.id != data.user?.id && (
        <Avatar
          size="xs"
          circle
          src={data.user?.profilePictureUrl}
          alt={initials(data.user?.name)}
        />
      )}
      <div
        className={`px-2 py-2 pb-4 flex flex-col ${
          data.selfComment ? "bg-sky-50" : "bg-gray-100"
        } rounded-lg mx-4 relative min-w-32`}
      >
        {profile?.id != data.user?.id && (
          <span className="text-sm font-semibold mb-4">{data.user?.name}</span>
        )}
        {data.attachmentUrl && (
          <Link target="_blank" to={data.attachmentUrl}>
            <img
              src={data.attachmentUrl}
              className="max-w-[240px] mt-2 object-cover rounded-lg border-2 border-white my-2"
            />
          </Link>
        )}
        {data.comment}

        <span className=" text-2xs absolute bottom-1 right-1">
            
          {moment.utc(data.createdAt).format("HH:mm")}
        </span>
      </div>
    </div>
  );
};
export default ChatBubble;
