import EmojiPicker from "emoji-picker-react";
import moment from "moment";
import { useContext, useEffect, useRef, useState, type FC } from "react";
import { HiOutlineClock, HiPaperClip } from "react-icons/hi2";
import { IoMdSend } from "react-icons/io";
import { PiSmiley } from "react-icons/pi";
import { useParams } from "react-router-dom";
import { Drawer } from "rsuite";
import { adminOrderClient } from "../../App";
import Back from "../../components/back";
import {
  GetOrderDetailRequest,
  GetOrderDetailResponseData,
  GetOrderDiscussionRequest,
  GetOrderDiscussionResponseData,
  SendDiscussionRequest,
} from "../../grpc/admin/admin_order_pb";
import { GetProfileResponseData } from "../../grpc/service/general_pb";
import AdminMid from "../../middlewares/AdminMid";
import { LoadingContext } from "../../objects/loading_context";
import {
  AFFILIATOR_ADMIN,
  AFFILIATOR_CLIENT,
  AFFILIATOR_COMMENTATOR,
} from "../../utils/contants";
import { getToken, money, numberToDuration } from "../../utils/helper";
import { errorToast } from "../../utils/helper-ui";
import CommentBubble from "../../components/comment_bubble";
import ChatBubble from "../../components/ChatBubble";
import ChatBox from "../../components/ChatBox";

interface AdminOrderCommentDetailProps {}

const AdminOrderCommentDetail: FC<AdminOrderCommentDetailProps> = ({}) => {
  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const { orderId } = useParams();
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const [data, setData] = useState<
    GetOrderDetailResponseData.AsObject | null | undefined
  >(null);
  const [msgClients, setMsgClients] = useState<
    GetOrderDiscussionResponseData.AsObject[]
  >([]);
  const [msgAdmins, setMsgAdmins] = useState<
    GetOrderDiscussionResponseData.AsObject[]
  >([]);
  const [msgCommentators, setMsgCommentators] = useState<
    GetOrderDiscussionResponseData.AsObject[]
  >([]);
  const [activeDiscussion, setActiveDiscussion] = useState("client");
  const [modalEmoji, setModalEmoji] = useState(false);
  const [msg, setMsg] = useState("");
  const fileRef = useRef<HTMLInputElement>(null);
  const [chatBoxEnabled, setChatBoxEnabled] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getDetail();
    getDiscussions();
  }, [mounted]);

  const getConversation = async (
    type: string
  ): Promise<GetOrderDiscussionResponseData.AsObject[]> => {
    return await new Promise((resolve, reject) => {
      let req = new GetOrderDiscussionRequest();
      req.setOrderId(orderId!);
      req.setType(type);
      adminOrderClient.getOrderDiscussion(req, getToken(), (err, resp) => {
        if (err) {
          reject(err.message);
          return;
        }
        resolve(resp.getDataList().map((e) => e.toObject()));
      });
    });
  };
  const getDiscussions = async () => {
    try {
      setIsLoading(false);
      let toClient = await getConversation(AFFILIATOR_CLIENT);
      let toAffiliator = await getConversation(AFFILIATOR_ADMIN);
      let toCommentator = await getConversation(AFFILIATOR_COMMENTATOR);
      toClient.reverse();
      toAffiliator.reverse();
      toCommentator.reverse();
      setMsgClients(toClient);
      setMsgAdmins(toAffiliator);
      setMsgCommentators(toCommentator);
      // console.log("toClient", toClient)
      // console.log("toAffiliator", toAffiliator)
      // console.log("toCommentator", toCommentator)
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };
  const getDetail = async () => {
    try {
      setIsLoading(false);

      let resp: GetOrderDetailResponseData.AsObject = await new Promise(
        (resolve, reject) => {
          let req = new GetOrderDetailRequest();
          req.setId(orderId!);
          adminOrderClient.getOrderDetail(req, getToken(), (err, resp) => {
            if (err) {
              reject(err.message);
              return;
            }
            resolve(resp.getData()?.toObject()!);
          });
        }
      );
      setData(resp);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setChatBoxEnabled(false);
    if (activeDiscussion == "client" && msgClients.length > 0) {
      // setChatBoxEnabled(true)
    }
    if (activeDiscussion == "commentator" && msgCommentators.length > 0) {
      // setChatBoxEnabled(true)
    }
    if (activeDiscussion == "affiliator") {
      setChatBoxEnabled(true);
    }
  }, [activeDiscussion]);

  const sendMessage = async (message: string, filePath?: string | null) => {
    setIsLoading(true);
    let req = new SendDiscussionRequest();
    req.setOrderId(orderId!);
    req.setComment(message);
    req.setAttachmentPath(filePath ?? "");
    req.setAttachmentPathType(filePath ? "firebase" : "");
    if (activeDiscussion == "client") {
      if (msgClients.length) {
        let last = msgClients[msgClients.length - 1];
        req.setParentOrderDiscussionId(last.id);
      }
    }
    adminOrderClient.sendDiscussion(req, getToken(), (err, resp) => {
      setIsLoading(false);
      if (err) {
        errorToast(err.message);
        return;
      }
      setMsg("");
      getDiscussions();
    });
  };

  const renderDiscussions = (data: GetOrderDiscussionResponseData.AsObject[]) =>
    data.map((e) => (
      <div className="w-full" key={e.id}>
        <ChatBubble profile={profile} data={e} />
        {e.repliesList.map((r) => (
          <div key={r.id}>
            <ChatBubble profile={profile} data={r} />
          </div>
        ))}
      </div>
    ));

  return (
    <AdminMid
      onLoadProfile={setProfile}
      noPadding
      header={
        <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white">
          <Back />
          <h6>Detail Pesanan</h6>
          <div className="p-2 "></div>
        </div>
      }
    >
      <div className="flex flex-col h-full">
        <div className="flex-1  overflow-y-auto">
          <div className="bg-white px-4 py-8 mb-2">
            <div className="flex">
              <div className="w-2/12">
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/assets/logo.svg";
                  }}
                  src={data?.thumbnailUrl}
                  alt=""
                  className=" object-cover w-10 h-10 rounded-lg bg-gray-50 mr-2"
                />
              </div>
              <div className="w-10/12 truncate">
                <h6>{data?.title}</h6>
                <p className=" truncate cursor-pointer">{data?.url}</p>
              </div>
            </div>
            <div className="flex border-b pb-4"></div>
            <div className=" flex justify-between mt-4 mb-2 items-center">
              <p className=" font-bold text-lg">Status</p>
              {!data?.finishedAt && (
                <span className=" text-sky-400 font-semibold">Diproses</span>
              )}
            </div>
            <div className=" justify-between flex mb-2">
              <span>Sisa Waktu</span>
              <span className="flex text-orange-400 items-center">
                <HiOutlineClock size={18} className="mr-2" />
                {numberToDuration(
                  moment.utc().diff(moment.utc(data?.dueDate), "minutes")
                )}
              </span>
            </div>
            <h5 className=" font-bold text-lg mt-4">Detail Pesanan</h5>
            <div className=" justify-between flex mb-2">
              <span>Client</span>
              <span> {data?.user?.name}</span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>Komentar Positif</span>
              <span>{money(data?.positiveComment)}</span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>Komentar Negatif</span>
              <span>{money(data?.negativeComment)}</span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>Tag Teman</span>
              <span>{money(data?.tagFriend)}</span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>+ Bonus Like</span>
              <span>{money(data?.like)}</span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>+ Bonus Share</span>
              <span>{money(data?.share)}</span>
            </div>
            <div className="flex justify-center mt-8">
              <button
                className="btn-white w-48 text-orange-400 border-orange-400"
                onClick={() => {
                  window.open(data?.url);
                }}
              >
                Lihat Progress
              </button>
            </div>
            {/* <div className=' justify-between flex mb-2'>
                        <span>Catatan</span>
                    </div>
                    <div className=' border border-gray-150 px-12 py-4 rounded-xl text-center'>
                        {data?.remarks}
                    </div> */}
          </div>
          <div className="bg-white px-4 py-4 mb-2 mt-2 min-h-[300px]">
            <p className=" font-bold text-lg">Pesan</p>
            <div className="  rounded-xl  justify-center grid grid-cols-3 mt-4">
              <div
                onClick={() => setActiveDiscussion("client")}
                className={`px-4 py-2 text-center text-xs border border-sky-400 rounded-s-xl ${
                  activeDiscussion == "client" && "bg-sky-400 text-white"
                }`}
              >
                CLIENT
              </div>
              <div
                onClick={() => setActiveDiscussion("affiliator")}
                className={`px-4 py-2 text-center text-xs border border-sky-400 ${
                  activeDiscussion == "affiliator" && "bg-sky-400 text-white"
                }`}
              >
                AFFILIATOR
              </div>
              <div
                onClick={() => setActiveDiscussion("commentator")}
                className={`px-4 py-2 text-center text-xs border border-sky-400 rounded-e-xl ${
                  activeDiscussion == "commentator" && "bg-sky-400 text-white"
                }`}
              >
                COMMENTATOR
              </div>
            </div>
            <div className="py-4 w-full flex flex-col">
              {activeDiscussion == "client" && renderDiscussions(msgClients)}
              {activeDiscussion == "affiliator" && renderDiscussions(msgAdmins)}
              {activeDiscussion == "commentator" &&
                renderDiscussions(msgCommentators)}
            </div>
          </div>
        </div>
        {/* CHATBOX */}
        {chatBoxEnabled && <ChatBox sendMessage={sendMessage} />}
      </div>

      <Drawer
        className="drawer-rounded"
        size={"md"}
        placement={"bottom"}
        open={modalEmoji}
        onClose={() => setModalEmoji(false)}
      >
        <Drawer.Body style={{}}>
          <EmojiPicker
            onEmojiClick={(val, ev) => {
              setMsg(msg + val.emoji);
            }}
          />
        </Drawer.Body>
      </Drawer>
    </AdminMid>
  );
};
export default AdminOrderCommentDetail;
