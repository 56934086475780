import { type FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoutes from './PrivateRutes';
import PublicRoutes from './PublicRoutes';
import Join from '../pages/Join';


interface AppRouteProps { }

export const AppRoute: FC<AppRouteProps> = ({ }) => {

    const token = localStorage.getItem("token");
    return (<BrowserRouter>
        <Routes>
            <Route path='/join/ref/:token' element={<Join />} />
            {token
                ? <Route path="/*" element={<PrivateRoutes />} />
                : <Route path="/*" element={<PublicRoutes />} />}
        </Routes>
    </BrowserRouter>);
};
