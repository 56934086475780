import { useContext, useState, type FC } from "react";
import ProfileMid from "../middlewares/ProfileMid";
import { LoadingContext } from "../objects/loading_context";
import { useNavigate } from "react-router-dom";
import { GetProfileResponseData } from "../grpc/service/general_pb";
import Back from "../components/back";
import {
  CONFIRM,
  DONE,
  IN_PROGRESS,
  MUTATION,
  PENDING_CONFIRMATION,
  PROCESSING,
} from "../utils/contants";
import Mutation from "../components/Mutation";
import WithdrawRequest from "../components/withdraw_request";

interface HistoryPageProps {
  isAffiliator?: boolean;
  isCommentator?: boolean;
  isMarketing?: boolean;
}

const HistoryPage: FC<HistoryPageProps> = ({
  isAffiliator,
  isCommentator,
  isMarketing,
}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const nav = useNavigate();
  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const [mode, setMode] = useState(MUTATION);
  return (
    <ProfileMid onLoad={(v) => setProfile(v)}>
      <div className="flex flex-col h-full">
        <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white">
          <Back />
          <h6>Histori</h6>
          <div className="p-2"></div>
        </div>
        <div className="">
          <div className="tabmenu bg-white border-b">
            <div
              onClick={() => setMode(MUTATION)}
              className={`${mode == MUTATION && "active"}`}
            >
              Mutasi
            </div>
            <div
              onClick={() => setMode(PROCESSING)}
              className={`${mode == PROCESSING && "active"}`}
            >
              Diproses
            </div>
            <div
              onClick={() => setMode(CONFIRM)}
              className={`${mode == CONFIRM && "active"}`}
            >
              Konfirmasi
            </div>
            <div
              onClick={() => setMode(DONE)}
              className={`${mode == DONE && "active"}`}
            >
              Berhasil
            </div>
          </div>
        </div>
        {mode == MUTATION && (
          <Mutation
            isAffiliator={isAffiliator}
            isCommentator={isCommentator}
            isMarketing={isMarketing}
          />
        )}
        {mode == PROCESSING && (
          <WithdrawRequest
            status={IN_PROGRESS}
            isAffiliator={isAffiliator}
            isCommentator={isCommentator}
            isMarketing={isMarketing}
          />
        )}
        {mode == CONFIRM && (
          <WithdrawRequest
            status={PENDING_CONFIRMATION}
            isAffiliator={isAffiliator}
            isCommentator={isCommentator}
            isMarketing={isMarketing}
          />
        )}
        {mode == DONE && (
          <WithdrawRequest
            status={DONE}
            isAffiliator={isAffiliator}
            isCommentator={isCommentator}
            isMarketing={isMarketing}
          />
        )}
      </div>
    </ProfileMid>
  );
};
export default HistoryPage;
