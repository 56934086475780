// source: admin_general.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.admin_general.DeleteClientRequest', null, global);
goog.exportSymbol('proto.admin_general.DeleteClientResponse', null, global);
goog.exportSymbol('proto.admin_general.DeleteClientResponseData', null, global);
goog.exportSymbol('proto.admin_general.EditAboutUsRequest', null, global);
goog.exportSymbol('proto.admin_general.EditAboutUsResponse', null, global);
goog.exportSymbol('proto.admin_general.EditAboutUsResponseData', null, global);
goog.exportSymbol('proto.admin_general.EditTermsAndConditionsRequest', null, global);
goog.exportSymbol('proto.admin_general.EditTermsAndConditionsResponse', null, global);
goog.exportSymbol('proto.admin_general.EditTermsAndConditionsResponseData', null, global);
goog.exportSymbol('proto.admin_general.GetInvitationTextRequest', null, global);
goog.exportSymbol('proto.admin_general.GetInvitationTextResponse', null, global);
goog.exportSymbol('proto.admin_general.GetInvitationTextResponseData', null, global);
goog.exportSymbol('proto.admin_general.GetSummaryRequest', null, global);
goog.exportSymbol('proto.admin_general.GetSummaryResponse', null, global);
goog.exportSymbol('proto.admin_general.GetSummaryResponseData', null, global);
goog.exportSymbol('proto.admin_general.GetSummaryRevenueRequest', null, global);
goog.exportSymbol('proto.admin_general.GetSummaryRevenueResponse', null, global);
goog.exportSymbol('proto.admin_general.GetSummaryRevenueResponseData', null, global);
goog.exportSymbol('proto.admin_general.GetSummaryUserRequest', null, global);
goog.exportSymbol('proto.admin_general.GetSummaryUserResponse', null, global);
goog.exportSymbol('proto.admin_general.GetSummaryUserResponseData', null, global);
goog.exportSymbol('proto.admin_general.Meta', null, global);
goog.exportSymbol('proto.admin_general.Pagination', null, global);
goog.exportSymbol('proto.admin_general.SendCustomNotificationRequest', null, global);
goog.exportSymbol('proto.admin_general.SendCustomNotificationResponse', null, global);
goog.exportSymbol('proto.admin_general.SendCustomNotificationResponseData', null, global);
goog.exportSymbol('proto.admin_general.UpdateInvitationTextRequest', null, global);
goog.exportSymbol('proto.admin_general.UpdateInvitationTextResponse', null, global);
goog.exportSymbol('proto.admin_general.UpdateInvitationTextResponseData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.Meta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.Meta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.Meta.displayName = 'proto.admin_general.Meta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.Pagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.Pagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.Pagination.displayName = 'proto.admin_general.Pagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.GetSummaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.GetSummaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.GetSummaryRequest.displayName = 'proto.admin_general.GetSummaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.GetSummaryResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.GetSummaryResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.GetSummaryResponseData.displayName = 'proto.admin_general.GetSummaryResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.GetSummaryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.GetSummaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.GetSummaryResponse.displayName = 'proto.admin_general.GetSummaryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.GetSummaryUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.GetSummaryUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.GetSummaryUserRequest.displayName = 'proto.admin_general.GetSummaryUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.GetSummaryUserResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.GetSummaryUserResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.GetSummaryUserResponseData.displayName = 'proto.admin_general.GetSummaryUserResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.GetSummaryUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin_general.GetSummaryUserResponse.repeatedFields_, null);
};
goog.inherits(proto.admin_general.GetSummaryUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.GetSummaryUserResponse.displayName = 'proto.admin_general.GetSummaryUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.GetSummaryRevenueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.GetSummaryRevenueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.GetSummaryRevenueRequest.displayName = 'proto.admin_general.GetSummaryRevenueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.GetSummaryRevenueResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.GetSummaryRevenueResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.GetSummaryRevenueResponseData.displayName = 'proto.admin_general.GetSummaryRevenueResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.GetSummaryRevenueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin_general.GetSummaryRevenueResponse.repeatedFields_, null);
};
goog.inherits(proto.admin_general.GetSummaryRevenueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.GetSummaryRevenueResponse.displayName = 'proto.admin_general.GetSummaryRevenueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.EditAboutUsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.EditAboutUsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.EditAboutUsRequest.displayName = 'proto.admin_general.EditAboutUsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.EditAboutUsResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.EditAboutUsResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.EditAboutUsResponseData.displayName = 'proto.admin_general.EditAboutUsResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.EditAboutUsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.EditAboutUsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.EditAboutUsResponse.displayName = 'proto.admin_general.EditAboutUsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.EditTermsAndConditionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.EditTermsAndConditionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.EditTermsAndConditionsRequest.displayName = 'proto.admin_general.EditTermsAndConditionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.EditTermsAndConditionsResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.EditTermsAndConditionsResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.EditTermsAndConditionsResponseData.displayName = 'proto.admin_general.EditTermsAndConditionsResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.EditTermsAndConditionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.EditTermsAndConditionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.EditTermsAndConditionsResponse.displayName = 'proto.admin_general.EditTermsAndConditionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.DeleteClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.DeleteClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.DeleteClientRequest.displayName = 'proto.admin_general.DeleteClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.DeleteClientResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.DeleteClientResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.DeleteClientResponseData.displayName = 'proto.admin_general.DeleteClientResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.DeleteClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.DeleteClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.DeleteClientResponse.displayName = 'proto.admin_general.DeleteClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.GetInvitationTextRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.GetInvitationTextRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.GetInvitationTextRequest.displayName = 'proto.admin_general.GetInvitationTextRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.GetInvitationTextResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.GetInvitationTextResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.GetInvitationTextResponseData.displayName = 'proto.admin_general.GetInvitationTextResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.GetInvitationTextResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.GetInvitationTextResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.GetInvitationTextResponse.displayName = 'proto.admin_general.GetInvitationTextResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.UpdateInvitationTextRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.UpdateInvitationTextRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.UpdateInvitationTextRequest.displayName = 'proto.admin_general.UpdateInvitationTextRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.UpdateInvitationTextResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.UpdateInvitationTextResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.UpdateInvitationTextResponseData.displayName = 'proto.admin_general.UpdateInvitationTextResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.UpdateInvitationTextResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.UpdateInvitationTextResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.UpdateInvitationTextResponse.displayName = 'proto.admin_general.UpdateInvitationTextResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.SendCustomNotificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admin_general.SendCustomNotificationRequest.repeatedFields_, null);
};
goog.inherits(proto.admin_general.SendCustomNotificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.SendCustomNotificationRequest.displayName = 'proto.admin_general.SendCustomNotificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.SendCustomNotificationResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.SendCustomNotificationResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.SendCustomNotificationResponseData.displayName = 'proto.admin_general.SendCustomNotificationResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admin_general.SendCustomNotificationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admin_general.SendCustomNotificationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admin_general.SendCustomNotificationResponse.displayName = 'proto.admin_general.SendCustomNotificationResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.Meta.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.Meta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.Meta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.Meta.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.Meta}
 */
proto.admin_general.Meta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.Meta;
  return proto.admin_general.Meta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.Meta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.Meta}
 */
proto.admin_general.Meta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.Meta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.Meta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.Meta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.Meta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.admin_general.Meta.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.Meta} returns this
 */
proto.admin_general.Meta.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 status = 2;
 * @return {number}
 */
proto.admin_general.Meta.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.Meta} returns this
 */
proto.admin_general.Meta.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.admin_general.Meta.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.Meta} returns this
 */
proto.admin_general.Meta.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.Pagination.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.Pagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.Pagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.Pagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    prev: jspb.Message.getFieldWithDefault(msg, 3, 0),
    next: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.Pagination}
 */
proto.admin_general.Pagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.Pagination;
  return proto.admin_general.Pagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.Pagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.Pagination}
 */
proto.admin_general.Pagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrev(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNext(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPages(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.Pagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.Pagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.Pagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.Pagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPrev();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNext();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.admin_general.Pagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.Pagination} returns this
 */
proto.admin_general.Pagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.admin_general.Pagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.Pagination} returns this
 */
proto.admin_general.Pagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 prev = 3;
 * @return {number}
 */
proto.admin_general.Pagination.prototype.getPrev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.Pagination} returns this
 */
proto.admin_general.Pagination.prototype.setPrev = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 next = 4;
 * @return {number}
 */
proto.admin_general.Pagination.prototype.getNext = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.Pagination} returns this
 */
proto.admin_general.Pagination.prototype.setNext = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 total_pages = 5;
 * @return {number}
 */
proto.admin_general.Pagination.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.Pagination} returns this
 */
proto.admin_general.Pagination.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 total_records = 6;
 * @return {number}
 */
proto.admin_general.Pagination.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.Pagination} returns this
 */
proto.admin_general.Pagination.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.GetSummaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.GetSummaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.GetSummaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    durationUnit: jspb.Message.getFieldWithDefault(msg, 2, ""),
    durationValue: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.GetSummaryRequest}
 */
proto.admin_general.GetSummaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.GetSummaryRequest;
  return proto.admin_general.GetSummaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.GetSummaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.GetSummaryRequest}
 */
proto.admin_general.GetSummaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDurationUnit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.GetSummaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.GetSummaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.GetSummaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDurationUnit();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDurationValue();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string start_date = 1;
 * @return {string}
 */
proto.admin_general.GetSummaryRequest.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.GetSummaryRequest} returns this
 */
proto.admin_general.GetSummaryRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string duration_unit = 2;
 * @return {string}
 */
proto.admin_general.GetSummaryRequest.prototype.getDurationUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.GetSummaryRequest} returns this
 */
proto.admin_general.GetSummaryRequest.prototype.setDurationUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 duration_value = 3;
 * @return {number}
 */
proto.admin_general.GetSummaryRequest.prototype.getDurationValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.GetSummaryRequest} returns this
 */
proto.admin_general.GetSummaryRequest.prototype.setDurationValue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.GetSummaryResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.GetSummaryResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.GetSummaryResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalUser: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalUserPercentageIncrease: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    revenue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    revenuePercentageIncrease: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.GetSummaryResponseData}
 */
proto.admin_general.GetSummaryResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.GetSummaryResponseData;
  return proto.admin_general.GetSummaryResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.GetSummaryResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.GetSummaryResponseData}
 */
proto.admin_general.GetSummaryResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalUser(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalUserPercentageIncrease(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRevenue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRevenuePercentageIncrease(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.GetSummaryResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.GetSummaryResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.GetSummaryResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalUser();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTotalUserPercentageIncrease();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getRevenue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getRevenuePercentageIncrease();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional uint32 total_user = 1;
 * @return {number}
 */
proto.admin_general.GetSummaryResponseData.prototype.getTotalUser = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.GetSummaryResponseData} returns this
 */
proto.admin_general.GetSummaryResponseData.prototype.setTotalUser = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float total_user_percentage_increase = 2;
 * @return {number}
 */
proto.admin_general.GetSummaryResponseData.prototype.getTotalUserPercentageIncrease = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.GetSummaryResponseData} returns this
 */
proto.admin_general.GetSummaryResponseData.prototype.setTotalUserPercentageIncrease = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double revenue = 3;
 * @return {number}
 */
proto.admin_general.GetSummaryResponseData.prototype.getRevenue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.GetSummaryResponseData} returns this
 */
proto.admin_general.GetSummaryResponseData.prototype.setRevenue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float revenue_percentage_increase = 4;
 * @return {number}
 */
proto.admin_general.GetSummaryResponseData.prototype.getRevenuePercentageIncrease = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.GetSummaryResponseData} returns this
 */
proto.admin_general.GetSummaryResponseData.prototype.setRevenuePercentageIncrease = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.GetSummaryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.GetSummaryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.GetSummaryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_general.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_general.GetSummaryResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.GetSummaryResponse}
 */
proto.admin_general.GetSummaryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.GetSummaryResponse;
  return proto.admin_general.GetSummaryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.GetSummaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.GetSummaryResponse}
 */
proto.admin_general.GetSummaryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_general.Meta;
      reader.readMessage(value,proto.admin_general.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_general.GetSummaryResponseData;
      reader.readMessage(value,proto.admin_general.GetSummaryResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.GetSummaryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.GetSummaryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.GetSummaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_general.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_general.GetSummaryResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_general.Meta}
 */
proto.admin_general.GetSummaryResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_general.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_general.Meta, 1));
};


/**
 * @param {?proto.admin_general.Meta|undefined} value
 * @return {!proto.admin_general.GetSummaryResponse} returns this
*/
proto.admin_general.GetSummaryResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_general.GetSummaryResponse} returns this
 */
proto.admin_general.GetSummaryResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_general.GetSummaryResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetSummaryResponseData data = 2;
 * @return {?proto.admin_general.GetSummaryResponseData}
 */
proto.admin_general.GetSummaryResponse.prototype.getData = function() {
  return /** @type{?proto.admin_general.GetSummaryResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_general.GetSummaryResponseData, 2));
};


/**
 * @param {?proto.admin_general.GetSummaryResponseData|undefined} value
 * @return {!proto.admin_general.GetSummaryResponse} returns this
*/
proto.admin_general.GetSummaryResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_general.GetSummaryResponse} returns this
 */
proto.admin_general.GetSummaryResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_general.GetSummaryResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.GetSummaryUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.GetSummaryUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.GetSummaryUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    durationUnit: jspb.Message.getFieldWithDefault(msg, 1, ""),
    durationValue: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.GetSummaryUserRequest}
 */
proto.admin_general.GetSummaryUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.GetSummaryUserRequest;
  return proto.admin_general.GetSummaryUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.GetSummaryUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.GetSummaryUserRequest}
 */
proto.admin_general.GetSummaryUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDurationUnit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.GetSummaryUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.GetSummaryUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.GetSummaryUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDurationUnit();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDurationValue();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string duration_unit = 1;
 * @return {string}
 */
proto.admin_general.GetSummaryUserRequest.prototype.getDurationUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.GetSummaryUserRequest} returns this
 */
proto.admin_general.GetSummaryUserRequest.prototype.setDurationUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 duration_value = 2;
 * @return {number}
 */
proto.admin_general.GetSummaryUserRequest.prototype.getDurationValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.GetSummaryUserRequest} returns this
 */
proto.admin_general.GetSummaryUserRequest.prototype.setDurationValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.GetSummaryUserResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.GetSummaryUserResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.GetSummaryUserResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryUserResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activeUser: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    passiveUser: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    freeUser: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.GetSummaryUserResponseData}
 */
proto.admin_general.GetSummaryUserResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.GetSummaryUserResponseData;
  return proto.admin_general.GetSummaryUserResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.GetSummaryUserResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.GetSummaryUserResponseData}
 */
proto.admin_general.GetSummaryUserResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setActiveUser(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPassiveUser(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFreeUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.GetSummaryUserResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.GetSummaryUserResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.GetSummaryUserResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryUserResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActiveUser();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getPassiveUser();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getFreeUser();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.admin_general.GetSummaryUserResponseData.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.GetSummaryUserResponseData} returns this
 */
proto.admin_general.GetSummaryUserResponseData.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float active_user = 2;
 * @return {number}
 */
proto.admin_general.GetSummaryUserResponseData.prototype.getActiveUser = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.GetSummaryUserResponseData} returns this
 */
proto.admin_general.GetSummaryUserResponseData.prototype.setActiveUser = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float passive_user = 3;
 * @return {number}
 */
proto.admin_general.GetSummaryUserResponseData.prototype.getPassiveUser = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.GetSummaryUserResponseData} returns this
 */
proto.admin_general.GetSummaryUserResponseData.prototype.setPassiveUser = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float free_user = 4;
 * @return {number}
 */
proto.admin_general.GetSummaryUserResponseData.prototype.getFreeUser = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.GetSummaryUserResponseData} returns this
 */
proto.admin_general.GetSummaryUserResponseData.prototype.setFreeUser = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin_general.GetSummaryUserResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.GetSummaryUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.GetSummaryUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.GetSummaryUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_general.Meta.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.admin_general.GetSummaryUserResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.GetSummaryUserResponse}
 */
proto.admin_general.GetSummaryUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.GetSummaryUserResponse;
  return proto.admin_general.GetSummaryUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.GetSummaryUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.GetSummaryUserResponse}
 */
proto.admin_general.GetSummaryUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_general.Meta;
      reader.readMessage(value,proto.admin_general.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_general.GetSummaryUserResponseData;
      reader.readMessage(value,proto.admin_general.GetSummaryUserResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.GetSummaryUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.GetSummaryUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.GetSummaryUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_general.Meta.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.admin_general.GetSummaryUserResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_general.Meta}
 */
proto.admin_general.GetSummaryUserResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_general.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_general.Meta, 1));
};


/**
 * @param {?proto.admin_general.Meta|undefined} value
 * @return {!proto.admin_general.GetSummaryUserResponse} returns this
*/
proto.admin_general.GetSummaryUserResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_general.GetSummaryUserResponse} returns this
 */
proto.admin_general.GetSummaryUserResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_general.GetSummaryUserResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated GetSummaryUserResponseData data = 2;
 * @return {!Array<!proto.admin_general.GetSummaryUserResponseData>}
 */
proto.admin_general.GetSummaryUserResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.admin_general.GetSummaryUserResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin_general.GetSummaryUserResponseData, 2));
};


/**
 * @param {!Array<!proto.admin_general.GetSummaryUserResponseData>} value
 * @return {!proto.admin_general.GetSummaryUserResponse} returns this
*/
proto.admin_general.GetSummaryUserResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.admin_general.GetSummaryUserResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin_general.GetSummaryUserResponseData}
 */
proto.admin_general.GetSummaryUserResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.admin_general.GetSummaryUserResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin_general.GetSummaryUserResponse} returns this
 */
proto.admin_general.GetSummaryUserResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.GetSummaryRevenueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.GetSummaryRevenueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.GetSummaryRevenueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryRevenueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    durationUnit: jspb.Message.getFieldWithDefault(msg, 1, ""),
    durationValue: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.GetSummaryRevenueRequest}
 */
proto.admin_general.GetSummaryRevenueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.GetSummaryRevenueRequest;
  return proto.admin_general.GetSummaryRevenueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.GetSummaryRevenueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.GetSummaryRevenueRequest}
 */
proto.admin_general.GetSummaryRevenueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDurationUnit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.GetSummaryRevenueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.GetSummaryRevenueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.GetSummaryRevenueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryRevenueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDurationUnit();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDurationValue();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string duration_unit = 1;
 * @return {string}
 */
proto.admin_general.GetSummaryRevenueRequest.prototype.getDurationUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.GetSummaryRevenueRequest} returns this
 */
proto.admin_general.GetSummaryRevenueRequest.prototype.setDurationUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 duration_value = 2;
 * @return {number}
 */
proto.admin_general.GetSummaryRevenueRequest.prototype.getDurationValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.GetSummaryRevenueRequest} returns this
 */
proto.admin_general.GetSummaryRevenueRequest.prototype.setDurationValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.GetSummaryRevenueResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.GetSummaryRevenueResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.GetSummaryRevenueResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryRevenueResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    revenue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    revenuePercentageIncrease: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.GetSummaryRevenueResponseData}
 */
proto.admin_general.GetSummaryRevenueResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.GetSummaryRevenueResponseData;
  return proto.admin_general.GetSummaryRevenueResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.GetSummaryRevenueResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.GetSummaryRevenueResponseData}
 */
proto.admin_general.GetSummaryRevenueResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRevenue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRevenuePercentageIncrease(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.GetSummaryRevenueResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.GetSummaryRevenueResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.GetSummaryRevenueResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryRevenueResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRevenue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getRevenuePercentageIncrease();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.admin_general.GetSummaryRevenueResponseData.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.GetSummaryRevenueResponseData} returns this
 */
proto.admin_general.GetSummaryRevenueResponseData.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double revenue = 2;
 * @return {number}
 */
proto.admin_general.GetSummaryRevenueResponseData.prototype.getRevenue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.GetSummaryRevenueResponseData} returns this
 */
proto.admin_general.GetSummaryRevenueResponseData.prototype.setRevenue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float revenue_percentage_increase = 3;
 * @return {number}
 */
proto.admin_general.GetSummaryRevenueResponseData.prototype.getRevenuePercentageIncrease = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.admin_general.GetSummaryRevenueResponseData} returns this
 */
proto.admin_general.GetSummaryRevenueResponseData.prototype.setRevenuePercentageIncrease = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin_general.GetSummaryRevenueResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.GetSummaryRevenueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.GetSummaryRevenueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.GetSummaryRevenueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryRevenueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_general.Meta.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.admin_general.GetSummaryRevenueResponseData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.GetSummaryRevenueResponse}
 */
proto.admin_general.GetSummaryRevenueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.GetSummaryRevenueResponse;
  return proto.admin_general.GetSummaryRevenueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.GetSummaryRevenueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.GetSummaryRevenueResponse}
 */
proto.admin_general.GetSummaryRevenueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_general.Meta;
      reader.readMessage(value,proto.admin_general.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_general.GetSummaryRevenueResponseData;
      reader.readMessage(value,proto.admin_general.GetSummaryRevenueResponseData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.GetSummaryRevenueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.GetSummaryRevenueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.GetSummaryRevenueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetSummaryRevenueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_general.Meta.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.admin_general.GetSummaryRevenueResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_general.Meta}
 */
proto.admin_general.GetSummaryRevenueResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_general.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_general.Meta, 1));
};


/**
 * @param {?proto.admin_general.Meta|undefined} value
 * @return {!proto.admin_general.GetSummaryRevenueResponse} returns this
*/
proto.admin_general.GetSummaryRevenueResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_general.GetSummaryRevenueResponse} returns this
 */
proto.admin_general.GetSummaryRevenueResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_general.GetSummaryRevenueResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated GetSummaryRevenueResponseData data = 2;
 * @return {!Array<!proto.admin_general.GetSummaryRevenueResponseData>}
 */
proto.admin_general.GetSummaryRevenueResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.admin_general.GetSummaryRevenueResponseData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admin_general.GetSummaryRevenueResponseData, 2));
};


/**
 * @param {!Array<!proto.admin_general.GetSummaryRevenueResponseData>} value
 * @return {!proto.admin_general.GetSummaryRevenueResponse} returns this
*/
proto.admin_general.GetSummaryRevenueResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.admin_general.GetSummaryRevenueResponseData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admin_general.GetSummaryRevenueResponseData}
 */
proto.admin_general.GetSummaryRevenueResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.admin_general.GetSummaryRevenueResponseData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin_general.GetSummaryRevenueResponse} returns this
 */
proto.admin_general.GetSummaryRevenueResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.EditAboutUsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.EditAboutUsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.EditAboutUsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.EditAboutUsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    aboutUsTitle: jspb.Message.getFieldWithDefault(msg, 1, ""),
    aboutUsBody: jspb.Message.getFieldWithDefault(msg, 2, ""),
    whyUsTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    whyUsBody: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.EditAboutUsRequest}
 */
proto.admin_general.EditAboutUsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.EditAboutUsRequest;
  return proto.admin_general.EditAboutUsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.EditAboutUsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.EditAboutUsRequest}
 */
proto.admin_general.EditAboutUsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAboutUsTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAboutUsBody(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhyUsTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhyUsBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.EditAboutUsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.EditAboutUsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.EditAboutUsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.EditAboutUsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAboutUsTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAboutUsBody();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWhyUsTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWhyUsBody();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string about_us_title = 1;
 * @return {string}
 */
proto.admin_general.EditAboutUsRequest.prototype.getAboutUsTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.EditAboutUsRequest} returns this
 */
proto.admin_general.EditAboutUsRequest.prototype.setAboutUsTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string about_us_body = 2;
 * @return {string}
 */
proto.admin_general.EditAboutUsRequest.prototype.getAboutUsBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.EditAboutUsRequest} returns this
 */
proto.admin_general.EditAboutUsRequest.prototype.setAboutUsBody = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string why_us_title = 3;
 * @return {string}
 */
proto.admin_general.EditAboutUsRequest.prototype.getWhyUsTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.EditAboutUsRequest} returns this
 */
proto.admin_general.EditAboutUsRequest.prototype.setWhyUsTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string why_us_body = 4;
 * @return {string}
 */
proto.admin_general.EditAboutUsRequest.prototype.getWhyUsBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.EditAboutUsRequest} returns this
 */
proto.admin_general.EditAboutUsRequest.prototype.setWhyUsBody = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.EditAboutUsResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.EditAboutUsResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.EditAboutUsResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.EditAboutUsResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.EditAboutUsResponseData}
 */
proto.admin_general.EditAboutUsResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.EditAboutUsResponseData;
  return proto.admin_general.EditAboutUsResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.EditAboutUsResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.EditAboutUsResponseData}
 */
proto.admin_general.EditAboutUsResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.EditAboutUsResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.EditAboutUsResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.EditAboutUsResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.EditAboutUsResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.EditAboutUsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.EditAboutUsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.EditAboutUsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.EditAboutUsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_general.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_general.EditAboutUsResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.EditAboutUsResponse}
 */
proto.admin_general.EditAboutUsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.EditAboutUsResponse;
  return proto.admin_general.EditAboutUsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.EditAboutUsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.EditAboutUsResponse}
 */
proto.admin_general.EditAboutUsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_general.Meta;
      reader.readMessage(value,proto.admin_general.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_general.EditAboutUsResponseData;
      reader.readMessage(value,proto.admin_general.EditAboutUsResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.EditAboutUsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.EditAboutUsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.EditAboutUsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.EditAboutUsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_general.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_general.EditAboutUsResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_general.Meta}
 */
proto.admin_general.EditAboutUsResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_general.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_general.Meta, 1));
};


/**
 * @param {?proto.admin_general.Meta|undefined} value
 * @return {!proto.admin_general.EditAboutUsResponse} returns this
*/
proto.admin_general.EditAboutUsResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_general.EditAboutUsResponse} returns this
 */
proto.admin_general.EditAboutUsResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_general.EditAboutUsResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EditAboutUsResponseData data = 2;
 * @return {?proto.admin_general.EditAboutUsResponseData}
 */
proto.admin_general.EditAboutUsResponse.prototype.getData = function() {
  return /** @type{?proto.admin_general.EditAboutUsResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_general.EditAboutUsResponseData, 2));
};


/**
 * @param {?proto.admin_general.EditAboutUsResponseData|undefined} value
 * @return {!proto.admin_general.EditAboutUsResponse} returns this
*/
proto.admin_general.EditAboutUsResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_general.EditAboutUsResponse} returns this
 */
proto.admin_general.EditAboutUsResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_general.EditAboutUsResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.EditTermsAndConditionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.EditTermsAndConditionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.EditTermsAndConditionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.EditTermsAndConditionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    body: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.EditTermsAndConditionsRequest}
 */
proto.admin_general.EditTermsAndConditionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.EditTermsAndConditionsRequest;
  return proto.admin_general.EditTermsAndConditionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.EditTermsAndConditionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.EditTermsAndConditionsRequest}
 */
proto.admin_general.EditTermsAndConditionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.EditTermsAndConditionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.EditTermsAndConditionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.EditTermsAndConditionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.EditTermsAndConditionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string body = 1;
 * @return {string}
 */
proto.admin_general.EditTermsAndConditionsRequest.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.EditTermsAndConditionsRequest} returns this
 */
proto.admin_general.EditTermsAndConditionsRequest.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.EditTermsAndConditionsResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.EditTermsAndConditionsResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.EditTermsAndConditionsResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.EditTermsAndConditionsResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.EditTermsAndConditionsResponseData}
 */
proto.admin_general.EditTermsAndConditionsResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.EditTermsAndConditionsResponseData;
  return proto.admin_general.EditTermsAndConditionsResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.EditTermsAndConditionsResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.EditTermsAndConditionsResponseData}
 */
proto.admin_general.EditTermsAndConditionsResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.EditTermsAndConditionsResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.EditTermsAndConditionsResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.EditTermsAndConditionsResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.EditTermsAndConditionsResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.EditTermsAndConditionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.EditTermsAndConditionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.EditTermsAndConditionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.EditTermsAndConditionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_general.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_general.EditTermsAndConditionsResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.EditTermsAndConditionsResponse}
 */
proto.admin_general.EditTermsAndConditionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.EditTermsAndConditionsResponse;
  return proto.admin_general.EditTermsAndConditionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.EditTermsAndConditionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.EditTermsAndConditionsResponse}
 */
proto.admin_general.EditTermsAndConditionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_general.Meta;
      reader.readMessage(value,proto.admin_general.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_general.EditTermsAndConditionsResponseData;
      reader.readMessage(value,proto.admin_general.EditTermsAndConditionsResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.EditTermsAndConditionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.EditTermsAndConditionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.EditTermsAndConditionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.EditTermsAndConditionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_general.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_general.EditTermsAndConditionsResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_general.Meta}
 */
proto.admin_general.EditTermsAndConditionsResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_general.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_general.Meta, 1));
};


/**
 * @param {?proto.admin_general.Meta|undefined} value
 * @return {!proto.admin_general.EditTermsAndConditionsResponse} returns this
*/
proto.admin_general.EditTermsAndConditionsResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_general.EditTermsAndConditionsResponse} returns this
 */
proto.admin_general.EditTermsAndConditionsResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_general.EditTermsAndConditionsResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EditTermsAndConditionsResponseData data = 2;
 * @return {?proto.admin_general.EditTermsAndConditionsResponseData}
 */
proto.admin_general.EditTermsAndConditionsResponse.prototype.getData = function() {
  return /** @type{?proto.admin_general.EditTermsAndConditionsResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_general.EditTermsAndConditionsResponseData, 2));
};


/**
 * @param {?proto.admin_general.EditTermsAndConditionsResponseData|undefined} value
 * @return {!proto.admin_general.EditTermsAndConditionsResponse} returns this
*/
proto.admin_general.EditTermsAndConditionsResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_general.EditTermsAndConditionsResponse} returns this
 */
proto.admin_general.EditTermsAndConditionsResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_general.EditTermsAndConditionsResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.DeleteClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.DeleteClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.DeleteClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.DeleteClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.DeleteClientRequest}
 */
proto.admin_general.DeleteClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.DeleteClientRequest;
  return proto.admin_general.DeleteClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.DeleteClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.DeleteClientRequest}
 */
proto.admin_general.DeleteClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.DeleteClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.DeleteClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.DeleteClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.DeleteClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.admin_general.DeleteClientRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.DeleteClientRequest} returns this
 */
proto.admin_general.DeleteClientRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.DeleteClientResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.DeleteClientResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.DeleteClientResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.DeleteClientResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.DeleteClientResponseData}
 */
proto.admin_general.DeleteClientResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.DeleteClientResponseData;
  return proto.admin_general.DeleteClientResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.DeleteClientResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.DeleteClientResponseData}
 */
proto.admin_general.DeleteClientResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.DeleteClientResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.DeleteClientResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.DeleteClientResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.DeleteClientResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.DeleteClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.DeleteClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.DeleteClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.DeleteClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_general.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_general.DeleteClientResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.DeleteClientResponse}
 */
proto.admin_general.DeleteClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.DeleteClientResponse;
  return proto.admin_general.DeleteClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.DeleteClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.DeleteClientResponse}
 */
proto.admin_general.DeleteClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_general.Meta;
      reader.readMessage(value,proto.admin_general.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_general.DeleteClientResponseData;
      reader.readMessage(value,proto.admin_general.DeleteClientResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.DeleteClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.DeleteClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.DeleteClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.DeleteClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_general.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_general.DeleteClientResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_general.Meta}
 */
proto.admin_general.DeleteClientResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_general.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_general.Meta, 1));
};


/**
 * @param {?proto.admin_general.Meta|undefined} value
 * @return {!proto.admin_general.DeleteClientResponse} returns this
*/
proto.admin_general.DeleteClientResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_general.DeleteClientResponse} returns this
 */
proto.admin_general.DeleteClientResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_general.DeleteClientResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeleteClientResponseData data = 2;
 * @return {?proto.admin_general.DeleteClientResponseData}
 */
proto.admin_general.DeleteClientResponse.prototype.getData = function() {
  return /** @type{?proto.admin_general.DeleteClientResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_general.DeleteClientResponseData, 2));
};


/**
 * @param {?proto.admin_general.DeleteClientResponseData|undefined} value
 * @return {!proto.admin_general.DeleteClientResponse} returns this
*/
proto.admin_general.DeleteClientResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_general.DeleteClientResponse} returns this
 */
proto.admin_general.DeleteClientResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_general.DeleteClientResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.GetInvitationTextRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.GetInvitationTextRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.GetInvitationTextRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetInvitationTextRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.GetInvitationTextRequest}
 */
proto.admin_general.GetInvitationTextRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.GetInvitationTextRequest;
  return proto.admin_general.GetInvitationTextRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.GetInvitationTextRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.GetInvitationTextRequest}
 */
proto.admin_general.GetInvitationTextRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.GetInvitationTextRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.GetInvitationTextRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.GetInvitationTextRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetInvitationTextRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.GetInvitationTextResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.GetInvitationTextResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.GetInvitationTextResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetInvitationTextResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.GetInvitationTextResponseData}
 */
proto.admin_general.GetInvitationTextResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.GetInvitationTextResponseData;
  return proto.admin_general.GetInvitationTextResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.GetInvitationTextResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.GetInvitationTextResponseData}
 */
proto.admin_general.GetInvitationTextResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.GetInvitationTextResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.GetInvitationTextResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.GetInvitationTextResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetInvitationTextResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.admin_general.GetInvitationTextResponseData.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.GetInvitationTextResponseData} returns this
 */
proto.admin_general.GetInvitationTextResponseData.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.GetInvitationTextResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.GetInvitationTextResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.GetInvitationTextResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetInvitationTextResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_general.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_general.GetInvitationTextResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.GetInvitationTextResponse}
 */
proto.admin_general.GetInvitationTextResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.GetInvitationTextResponse;
  return proto.admin_general.GetInvitationTextResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.GetInvitationTextResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.GetInvitationTextResponse}
 */
proto.admin_general.GetInvitationTextResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_general.Meta;
      reader.readMessage(value,proto.admin_general.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_general.GetInvitationTextResponseData;
      reader.readMessage(value,proto.admin_general.GetInvitationTextResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.GetInvitationTextResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.GetInvitationTextResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.GetInvitationTextResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.GetInvitationTextResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_general.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_general.GetInvitationTextResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_general.Meta}
 */
proto.admin_general.GetInvitationTextResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_general.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_general.Meta, 1));
};


/**
 * @param {?proto.admin_general.Meta|undefined} value
 * @return {!proto.admin_general.GetInvitationTextResponse} returns this
*/
proto.admin_general.GetInvitationTextResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_general.GetInvitationTextResponse} returns this
 */
proto.admin_general.GetInvitationTextResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_general.GetInvitationTextResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetInvitationTextResponseData data = 2;
 * @return {?proto.admin_general.GetInvitationTextResponseData}
 */
proto.admin_general.GetInvitationTextResponse.prototype.getData = function() {
  return /** @type{?proto.admin_general.GetInvitationTextResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_general.GetInvitationTextResponseData, 2));
};


/**
 * @param {?proto.admin_general.GetInvitationTextResponseData|undefined} value
 * @return {!proto.admin_general.GetInvitationTextResponse} returns this
*/
proto.admin_general.GetInvitationTextResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_general.GetInvitationTextResponse} returns this
 */
proto.admin_general.GetInvitationTextResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_general.GetInvitationTextResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.UpdateInvitationTextRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.UpdateInvitationTextRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.UpdateInvitationTextRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.UpdateInvitationTextRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.UpdateInvitationTextRequest}
 */
proto.admin_general.UpdateInvitationTextRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.UpdateInvitationTextRequest;
  return proto.admin_general.UpdateInvitationTextRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.UpdateInvitationTextRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.UpdateInvitationTextRequest}
 */
proto.admin_general.UpdateInvitationTextRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.UpdateInvitationTextRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.UpdateInvitationTextRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.UpdateInvitationTextRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.UpdateInvitationTextRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.admin_general.UpdateInvitationTextRequest.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.UpdateInvitationTextRequest} returns this
 */
proto.admin_general.UpdateInvitationTextRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.UpdateInvitationTextResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.UpdateInvitationTextResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.UpdateInvitationTextResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.UpdateInvitationTextResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.UpdateInvitationTextResponseData}
 */
proto.admin_general.UpdateInvitationTextResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.UpdateInvitationTextResponseData;
  return proto.admin_general.UpdateInvitationTextResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.UpdateInvitationTextResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.UpdateInvitationTextResponseData}
 */
proto.admin_general.UpdateInvitationTextResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.UpdateInvitationTextResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.UpdateInvitationTextResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.UpdateInvitationTextResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.UpdateInvitationTextResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.UpdateInvitationTextResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.UpdateInvitationTextResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.UpdateInvitationTextResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.UpdateInvitationTextResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_general.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_general.UpdateInvitationTextResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.UpdateInvitationTextResponse}
 */
proto.admin_general.UpdateInvitationTextResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.UpdateInvitationTextResponse;
  return proto.admin_general.UpdateInvitationTextResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.UpdateInvitationTextResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.UpdateInvitationTextResponse}
 */
proto.admin_general.UpdateInvitationTextResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_general.Meta;
      reader.readMessage(value,proto.admin_general.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_general.UpdateInvitationTextResponseData;
      reader.readMessage(value,proto.admin_general.UpdateInvitationTextResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.UpdateInvitationTextResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.UpdateInvitationTextResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.UpdateInvitationTextResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.UpdateInvitationTextResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_general.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_general.UpdateInvitationTextResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_general.Meta}
 */
proto.admin_general.UpdateInvitationTextResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_general.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_general.Meta, 1));
};


/**
 * @param {?proto.admin_general.Meta|undefined} value
 * @return {!proto.admin_general.UpdateInvitationTextResponse} returns this
*/
proto.admin_general.UpdateInvitationTextResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_general.UpdateInvitationTextResponse} returns this
 */
proto.admin_general.UpdateInvitationTextResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_general.UpdateInvitationTextResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UpdateInvitationTextResponseData data = 2;
 * @return {?proto.admin_general.UpdateInvitationTextResponseData}
 */
proto.admin_general.UpdateInvitationTextResponse.prototype.getData = function() {
  return /** @type{?proto.admin_general.UpdateInvitationTextResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_general.UpdateInvitationTextResponseData, 2));
};


/**
 * @param {?proto.admin_general.UpdateInvitationTextResponseData|undefined} value
 * @return {!proto.admin_general.UpdateInvitationTextResponse} returns this
*/
proto.admin_general.UpdateInvitationTextResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_general.UpdateInvitationTextResponse} returns this
 */
proto.admin_general.UpdateInvitationTextResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_general.UpdateInvitationTextResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admin_general.SendCustomNotificationRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.SendCustomNotificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.SendCustomNotificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.SendCustomNotificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.SendCustomNotificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    body: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.SendCustomNotificationRequest}
 */
proto.admin_general.SendCustomNotificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.SendCustomNotificationRequest;
  return proto.admin_general.SendCustomNotificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.SendCustomNotificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.SendCustomNotificationRequest}
 */
proto.admin_general.SendCustomNotificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmails(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.SendCustomNotificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.SendCustomNotificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.SendCustomNotificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.SendCustomNotificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated string emails = 1;
 * @return {!Array<string>}
 */
proto.admin_general.SendCustomNotificationRequest.prototype.getEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.admin_general.SendCustomNotificationRequest} returns this
 */
proto.admin_general.SendCustomNotificationRequest.prototype.setEmailsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.admin_general.SendCustomNotificationRequest} returns this
 */
proto.admin_general.SendCustomNotificationRequest.prototype.addEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admin_general.SendCustomNotificationRequest} returns this
 */
proto.admin_general.SendCustomNotificationRequest.prototype.clearEmailsList = function() {
  return this.setEmailsList([]);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.admin_general.SendCustomNotificationRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.SendCustomNotificationRequest} returns this
 */
proto.admin_general.SendCustomNotificationRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string body = 3;
 * @return {string}
 */
proto.admin_general.SendCustomNotificationRequest.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admin_general.SendCustomNotificationRequest} returns this
 */
proto.admin_general.SendCustomNotificationRequest.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.SendCustomNotificationResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.SendCustomNotificationResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.SendCustomNotificationResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.SendCustomNotificationResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.SendCustomNotificationResponseData}
 */
proto.admin_general.SendCustomNotificationResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.SendCustomNotificationResponseData;
  return proto.admin_general.SendCustomNotificationResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.SendCustomNotificationResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.SendCustomNotificationResponseData}
 */
proto.admin_general.SendCustomNotificationResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.SendCustomNotificationResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.SendCustomNotificationResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.SendCustomNotificationResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.SendCustomNotificationResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admin_general.SendCustomNotificationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admin_general.SendCustomNotificationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admin_general.SendCustomNotificationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.SendCustomNotificationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.admin_general.Meta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.admin_general.SendCustomNotificationResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admin_general.SendCustomNotificationResponse}
 */
proto.admin_general.SendCustomNotificationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admin_general.SendCustomNotificationResponse;
  return proto.admin_general.SendCustomNotificationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admin_general.SendCustomNotificationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admin_general.SendCustomNotificationResponse}
 */
proto.admin_general.SendCustomNotificationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admin_general.Meta;
      reader.readMessage(value,proto.admin_general.Meta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.admin_general.SendCustomNotificationResponseData;
      reader.readMessage(value,proto.admin_general.SendCustomNotificationResponseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admin_general.SendCustomNotificationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admin_general.SendCustomNotificationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admin_general.SendCustomNotificationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admin_general.SendCustomNotificationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admin_general.Meta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admin_general.SendCustomNotificationResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Meta meta = 1;
 * @return {?proto.admin_general.Meta}
 */
proto.admin_general.SendCustomNotificationResponse.prototype.getMeta = function() {
  return /** @type{?proto.admin_general.Meta} */ (
    jspb.Message.getWrapperField(this, proto.admin_general.Meta, 1));
};


/**
 * @param {?proto.admin_general.Meta|undefined} value
 * @return {!proto.admin_general.SendCustomNotificationResponse} returns this
*/
proto.admin_general.SendCustomNotificationResponse.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_general.SendCustomNotificationResponse} returns this
 */
proto.admin_general.SendCustomNotificationResponse.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_general.SendCustomNotificationResponse.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SendCustomNotificationResponseData data = 2;
 * @return {?proto.admin_general.SendCustomNotificationResponseData}
 */
proto.admin_general.SendCustomNotificationResponse.prototype.getData = function() {
  return /** @type{?proto.admin_general.SendCustomNotificationResponseData} */ (
    jspb.Message.getWrapperField(this, proto.admin_general.SendCustomNotificationResponseData, 2));
};


/**
 * @param {?proto.admin_general.SendCustomNotificationResponseData|undefined} value
 * @return {!proto.admin_general.SendCustomNotificationResponse} returns this
*/
proto.admin_general.SendCustomNotificationResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admin_general.SendCustomNotificationResponse} returns this
 */
proto.admin_general.SendCustomNotificationResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admin_general.SendCustomNotificationResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.admin_general);
